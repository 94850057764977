import React from 'react';
import UpdateRouteForm from '../UpdateRouteForm';
import { PlantInterface } from '../../plants/service.plant';
import { gridModelRoutes, useRoutesByTagFP } from '../services/service.routes';
import DeleteRoute from '../DeleteRoute'
import AssignCancelMultipleRoutes from '../components/AssignCancelMultipleRoutes';
import AnyGrid, { CarrousellActionInterface } from '../../../components/grids/AnyGrid';
import CancelRouteAllLubricationPoints from '../components/CancelRouteAllLubricationPoints';
import { RouteInterface } from './../services/service.routes';
import CreateRouteForm from '../CreateRouteForm';


interface Props{
    plant:PlantInterface
}


const ManageRoutesPage:React.FC<Props> = ({plant}) => {

    const { data:routes } = useRoutesByTagFP(plant.tagFP)

    return (
       <AnyGrid
        gridModel={gridModelRoutes}
        title='Rutas'
        height='55vh'
        items={routes || []}
        loading={routes ? false : true }
        itemActions={(item:any)=><>
                {item && <>
                {/* */}
                    <DeleteRoute  item={item}/>
                    <CancelRouteAllLubricationPoints item={item} />
                </>}
            </>}
        headerActions={(item:any)=><>
                <AssignCancelMultipleRoutes plant={plant}/>
                <CreateRouteForm plant={plant}/>
                {/* <CURouteForm tagFP={plant.tagFP} actionType={CrudFormType.create} /> */}
            </>}
       />
    );
}

export default React.memo(ManageRoutesPage);
