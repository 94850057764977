import axios,{AxiosRequestConfig} from 'axios'
import { useQuery } from 'react-query'
import { useToken } from '../session/service.session'
import { QueryResult } from 'react-query';
import { SectorInterface } from '../sectors/services/service.sectors'
import { URL } from '../../infrastructure/appConfig/appConfig';
import { useOperatorSupervisorTagFP } from '../persons/services/service.person';
import { CriticalityInterface } from '../criticalities/types/types';
import { ComponentInterface } from '../components/types/ComponentTypes';
import { ElementInterface } from '../elements/types/ElementTypes';
import { sortCriticalityByNumber } from '../criticalities/utils/sortByNumber';
import { AddedReasonInterface } from '../added-reason/types/AdedReasonTypes';
import { LubricantInterface } from './../lubricants/services/service.lubricants';



export const getAllAuxByPlant = (_:string,tagFP:string, token:string) => {
    axios.defaults.headers.get['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.get['tagFP'] = `${tagFP}`
    const config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/AuxByTagFP`,
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const getAllAux = (_:string,tagFP:string, token:string) => {
    axios.defaults.headers.get['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/AllAux`,

    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}



//----------------------------
//react-query
//---------------------------------------------

export const useAllAuxByPlant = (tagFP:string|undefined) => {
    const supOpTagFP = useOperatorSupervisorTagFP()
    const queryTagFP = tagFP || supOpTagFP
    const token = useToken()
    return useQuery(['PlantAux',queryTagFP,token],getAllAuxByPlant,{
        enabled:!!queryTagFP,
        refetchOnWindowFocus:false,
        staleTime:5000
    })
}

//----------------------------
//custom hooks
//---------------------------------------------

export const useCriticalitiesByPlant = (tagFP?:string) => {
    const query = useAllAuxByPlant(tagFP)
    const criticalities = query.data?.criticalities
    const sortedCriticalities:CriticalityInterface[] | undefined = criticalities?.sort(sortCriticalityByNumber)
    return {
        ...query,
        data:sortedCriticalities
    }
}

export const useLubricantsByPlant = (tagFP?:string):QueryResult<LubricantInterface[]> => {
    const query = useAllAuxByPlant(tagFP)
    const data:LubricantInterface[] | undefined = query.data?.lubricants
    return{
        ...query,
        data
    }
}

export const useAddedReasonsByPlant = (tagFP?:string):QueryResult<AddedReasonInterface[]> => {
    const query = useAllAuxByPlant(tagFP)
    const data:AddedReasonInterface[] | undefined = query.data?.addedReasons
    return{
        ...query,
        data
    }
}

export const useComponentsByPlant = (tagFP?:string):QueryResult<ComponentInterface[]> => {
    const query = useAllAuxByPlant(tagFP)
    const data:ComponentInterface[] | undefined = query.data?.components
    return{
        ...query,
        data
    }
}

export const useElementsByPlant = (tagFP?:string):QueryResult<ElementInterface[]> => {
    const query = useAllAuxByPlant(tagFP)
    const data:ElementInterface[] | undefined = query.data?.sectors
    return{
        ...query,
        data
    }
}

export const useSectorsByPlant = (tagFP?:string):QueryResult<SectorInterface[]> => {
    const query = useAllAuxByPlant(tagFP)
    const data:SectorInterface[] | undefined = query.data?.sectors
    return{
        ...query,
        data
    }   
}

//------------------------------
//MUTATIONS