import React from 'react';
import { Button, CircularProgress, createStyles, FormHelperText, makeStyles, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Controller, useForm } from 'react-hook-form';
import { useAllAuxByPlant } from '../auxiliar-data/service.aux';
import { EquipmentInterface, LubricationPointInterface, useCreateEquipment } from './services/service.lubricationPoints';
/* import CreateLubricantForm from '../lubricants/CreateLubricantForm';
 */import SelectSimpleEntity from '../../components/select/SelectSimpleEntity';
/* import SelectType from '../types/molecules/SelectType';
 */import { EquipmentFormRegex } from '../../infrastructure/regex/regexForms';
import SelectTypeBy from './../types/molecules/SelectTypeBy';
import { enumTgdTypes } from '../types/Types/types';
import CULubricantForm from '../lubricants/CULubricantForm';
import { CrudFormType } from '../../infrastructure/form/typescript/FormTypes';
import SelectElement from '../elements/molecules/SelectElement';
import SelectComponent from './../components/molecules/SelectComponent';
import SelectLubricant from '../lubricants/molecules/SelectLubricant';



const useStyles =  makeStyles((theme) => createStyles({
    formGrid:{
        display:'flex',
        /* gap: '16px',*/
        flexWrap:'wrap',
        flexDirection:'column'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

interface Props{
    equipment:EquipmentInterface | null | undefined
    defaultState:LubricationPointInterface
    points:LubricationPointInterface[] | undefined | null
    handleState:any
}


const UpdateEquipmentForm:React.FC<Props> = ({points,equipment,defaultState,handleState}) => {
    const classes = useStyles();
    const {data:plantAux} = useAllAuxByPlant(equipment?.tagFP)
    const elements = plantAux?.elements
    const components = plantAux?.components
    const lubricants = plantAux?.lubricants
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [CreateEquipment,{error,status,data}] = useCreateEquipment()
    const { register, handleSubmit, setValue, watch, control, errors } = useForm({
        shouldUnregister: false
      });

    const pointVerificationArray = (array:any) =>{
        var iterators:string[]=[]

        array.filter((i:LubricationPointInterface) =>i.frontID !== defaultState.frontID).forEach((i:any)=>{
            iterators.push(i.component)
            iterators.push(i.element)
        })

        if(iterators?.includes(watch('element'))){
            if(iterators?.includes(watch('component'))) return false
            else return true
        }
        
    }
   
    const onSubmit = (body:LubricationPointInterface) =>{
        if(points) {
            const values = points?.filter(i => i.frontID !== defaultState.frontID)
            handleState([...values,{
                /* equipment */
                tagTGD:equipment?.tagTGD,
                sector: equipment?.sector,
                equipment: equipment?.equipment,
                plantTag: equipment?.plantTag,
                tagFP: equipment?.tagFP,
                type: equipment?.type,
                criticality:equipment?.criticality,
                /* LubricationPoint */
                /* state: true, */
                /* egressDate: '', */
                brand: body.brand,
                model:  body.model,
                /* lowReason: body.lowReason, */
                capacity:body.capacity,
                component: body.component,
                element: body.element,
                function: body.function,
                /* labelId: body.labelId, */
                location: body.location,
                lubricant: body.lubricant,
                lubricantType: lubricants?.filter((l:any) => l.lubricant === body.lubricant)[0].type,
                measureUnit: body.measureUnit,
                observations: body.observations || 'Sin observación ',
                oilAnalysis: !!(body.oilAnalysis === 'SI'),
                /* tools: '', */
                /* admissionDate: '', */
            }])
        }
    };

 

    if(status === 'loading') return <CircularProgress size='20px' color='primary' />
    return (
            <form className={classes.formGrid} onSubmit={handleSubmit(onSubmit)}>
                {error && <Alert severity='error'>Ha ocurrido un problema. {error} </Alert>}
                {data && (status === 'success') && (data ==="Existe el equipo") && <><Alert severity='warning'>El equipo ya existe, por favor cambia los datos del equipo y vuelve a intentarlo.</Alert><br/></>}
                {data && (status === 'success') && (data !== "Ya existe") && <><Alert severity='success'>El equipo se pudo crear con éxito</Alert><br/></>}                
               
                <TextField
                    inputRef={register({
                        required:{value:true,message:'Campo Obligatorio'},
                    })} 
                    name="brand" 
                    placeholder='Marca del equipo' 
                    defaultValue={defaultState?.brand}
                    variant='outlined'
                    label={errors?.brand?.message || 'Marca del equipo'} 
                    color={errors?.brand?.message  ? 'primary' : 'secondary'}
                    size="small"
                    />
                <br/>    

                <TextField
                    inputRef={register({
                        required:{value:true,message:'Campo Obligatorio'},
                    })} 
                    name="model" 
                    placeholder='Modelo del Equipo' 
                    defaultValue={defaultState?.model}
                    variant='outlined'
                    label={errors?.model?.message || 'Modelo del Equipo'} 
                    color={errors?.model?.message ? 'primary' : 'secondary'}
                    size="small"
                    />
                <br/>    

                <TextField
                    inputRef={register(/* {
                        required:{value:true,message:'Campo Obligatorio'},
                    } */)} 
                    name="function" 
                    placeholder='Función del Equipo' 
                    defaultValue={defaultState?.function}
                    variant='outlined'
                    label={errors?.function?.message || 'Función del Equipo'} 
                    color={errors?.function?.message ? 'primary' : 'secondary'}
                    size="small"
                    />
                <br/>  

                <TextField
                    inputRef={register()} 
                    name="location" 
                    placeholder='Ubicación del Equipo' 
                    defaultValue={defaultState?.location}
                    variant='outlined'
                    label={errors?.location?.message || 'Ubicación del Equipo'} 
                    color={errors?.location?.message ? 'primary' : 'secondary'}
                    size="small"
                    />
                <br/>  

                <TextField
                    inputRef={register()} 
                    name="observations" 
                    placeholder='Ubicación del Equipo' 
                    defaultValue={defaultState?.location}
                    variant='outlined'
                    label={'observaciones'} 
                    color={'secondary'}
                    size="small"
                    />
                <br/>       

               <Controller
                    as={<SelectElement
                        error={errors.element?.message}
                        tagFP={equipment?.tagFP!}
                        onChange={(value)=>setValue('element',value)}
                        defaultValue={watch('element',defaultState?.element)}
                    />}
                    name='element'
                    defaultValue={defaultState?.element}
                    rules={{
                        required:{value:true, message:'requerido'} 
                    }}
                    control={control}
                />
                {errors?.element?.message && <FormHelperText error >{errors?.element?.message}</FormHelperText> }
                <br/>

               <Controller
                    as={<SelectComponent
                        error={errors.component?.message}
                        tagFP={equipment?.tagFP!}
                        onChange={(value:string)=>setValue('component',value)}
                        defaultValue={watch('component',defaultState?.component)}
                    />}
                    name='component'
                    defaultValue={defaultState?.component}
                    rules={{
                        required:{value:true,message:'requerido'},
                        validate:{exist: () => pointVerificationArray(points,)}
                    }}
                    control={control}
                />
                {errors?.component?.message && <FormHelperText error >{errors?.component?.message}</FormHelperText> }
                {errors?.component?.type && <FormHelperText error >Ya existe</FormHelperText> }
                <br/>

               <Controller
                    as={<SelectLubricant
                        error={errors.lubricant?.message}
                        tagFP={equipment?.tagFP!}
                        onChange={(value)=>setValue('lubricant',value)}
                        defaultValue={watch('lubricant',defaultState?.lubricant)}
                    />}
                    name='lubricant'
                    defaultValue={defaultState.lubricant}
                    rules={{required:{value:true,message:'requerido'}}}
                    control={control}
                />
                {errors?.lubricant?.message && <FormHelperText error >{errors?.lubricant?.message}</FormHelperText> }
                <br/>

                <TextField
                    inputRef={register({
                        required:{value:true,message:'Campo Obligatorio'},
                        pattern:{value:EquipmentFormRegex.float, message:'El número no es válido'},
                    })} 
                    name="capacity" 
                    placeholder='Capacidad de lubricante' 
                    defaultValue={defaultState?.capacity}
                    variant='outlined'
                    label={errors?.capacity?.message || 'Capacidad de lubricante'} 
                    color={errors?.capacity?.message ? 'primary' : 'secondary'}
                    size="small"
                    />
                <br/> 
                

                <Controller
                    as={<SelectTypeBy
                        error={errors?.measureUnit?.message}
                        by={{key:'type',value:enumTgdTypes.unity}}
                        tagFP={equipment?.tagFP!}
                        onChange={(value)=>setValue('measureUnit',value)}
                        defaultValue={watch('measureUnit')}
                        />}
                    name='measureUnit'
                    defaultValue={defaultState.measureUnit}
                    rules={{required:{value:true,message:'Campo Obligatorio'}}}
                    control={control}
                />
                {errors?.measureUnit?.message && <FormHelperText error >{errors?.measureUnit?.message}</FormHelperText> }
                <br/>

                <Controller
                    as={<SelectSimpleEntity
                        label='Análisis de aceite'
                        entityList={[{label:'SI',value:true},{label:'NO',value:false}]}
                        showTitle={'label'}
                        onChange={setValue}
                        defaultValue={watch('oilAnalysis',defaultState?.oilAnalysis)}
                        />}
                    name='oilAnalysis'
                    defaultValue={defaultState.oilAnalysis ? 'SI' : 'NO'}
                    rules={{required:{value:true,message:'Campo Obligatorio'}}}
                    control={control}
                />
                {errors?.oilAnalisis?.message && <FormHelperText error >{errors?.oilAnalisis?.message}</FormHelperText> }
                <br/>             

                <Button type='submit' variant='outlined' color='secondary'> Modificar</Button>
            </form>

    );
}

export default UpdateEquipmentForm;
