import { Button, Divider, Icon, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import DoNotDoButton from '../../../../components/buttons/DoNotDoButton';
import TgdFab from '../../../../components/buttons/TgdFab';
import PageContainer from '../../../../components/pageContainers/PageContainer';
import FlexContainer from '../../../../components/presentational/FlexContainer';
import { LubricationPointInterface } from '../../../equipments/services/service.lubricationPoints';
import CreateObservation from '../observations/AddObservation';
import { useLubricationPointSurveyController } from './controllers/useLubricationPointSurveyController';
import  ArrowForwardIcon  from '@material-ui/icons/ArrowForward';
import { TaskType } from '../tasks/interfaces/taskInterfaces';
import { QueryStatus } from 'react-query';
import CustomButton from '../../../../components/buttons/CustomButton';
import AddSupply from '../supplies/AddSupply';
import { suppliesToString } from '../../../../utils/utils';
import { ObservationInterface } from '../../../observations/types/ObservationTypes';

const SurveyClean:React.FC<{item:LubricationPointInterface, taskType:TaskType, statusController:({status,data}:{status:QueryStatus,data:any})=>void, outOfRoute:boolean}> = ({outOfRoute, statusController,item,taskType}) => {

    
    const {register, handleSubmit, errors} = useForm()

    //SURVEY DATA
    const { survey, setTaskTime, nextTaskStep, setTaskDone, createSurvey, createObservation, backTaskStep } = useLubricationPointSurveyController(item,outOfRoute)
    const observations = survey?.observations || []
    const anomalies = survey?.anomalies || []
    const taskDone = survey?.cleaning.taskDone
    const taskTime = survey?.cleaning.taskTime
    const supplies = survey?.neededSupplies || []

    //COMPONENT STATE
    const [step,setStep]  = useState<any>()
    
    //COMPONENT METHODS
    useEffect(()=>{
        register({name:'taskDone',value:taskDone})
        register({name:'taskTime',value:taskTime})
    },[taskTime, taskDone, register])

    const submit = (data?:any) => {
        statusController({ status: QueryStatus.Loading, data:undefined})
        createSurvey({
            ...survey?.inspection,
            ...survey?.lubrication,
            ...survey?.cleaning,
            //TASK DATA
            taskType:taskType,
            taskDone:!!taskDone,
            taskTime:`${taskTime || 0}`,
            endTime:new Date().toISOString(), 
            //LUBRICATION DATA
            lubricates:survey?.lubrication.lubricates,
            sampleExtraction:false,
            consumables:JSON.stringify(suppliesToString(supplies))
        })
       .then(({data})=>{
            if(observations.length < 1) return
            else {   
                createObservation([
                    ...(observations.map((o:ObservationInterface)=>({...o,surveyId:data.id }))),
                ])
            }
       })
        .then(()=>{
            if(data.error) statusController({ status: QueryStatus.Error, data })
            statusController({ status: QueryStatus.Success, data})
            nextTaskStep(taskType)
        })
        .catch((err)=>{
            statusController({ status: QueryStatus.Error, data:err })
            console.log(err)
        })
    }


    return(<>

            {outOfRoute && /* BACK BUTTON */
                <div>
                    <CustomButton 
                        action={()=>backTaskStep(taskType)} 
                        icon='before' 
                        variant='iconButton' 
                        title='Atras' 
                    />
                </div>
            }

            {taskDone === undefined &&    
                <FlexContainer justify='center' align='center' gap='24px' height='100%' width='100%'>
                    <DoNotDoButton action={submit} variant='close' title='Sin limpieza'/>
                    <DoNotDoButton action={()=>setTaskDone(taskType,true)} variant='check' title='Limpieza'/>
                </FlexContainer>
            }
            
            <>
                {taskDone && <>
                    <PageContainer>
                        <TextField
                        variant='outlined'
                        style={{width:'100%'}}
                        type='number'
                        name={'taskTime'}
                        defaultValue={taskTime}
                        onChange={(event)=>{
                            setTaskTime(taskType,JSON.parse(event.target.value))
                        }}
                        label={`Tiempo de limpieza ${errors?.taskTime?.message || ''}` || 'Tiempo de limpieza'}
                        color={errors?.taskTime?.message ? 'primary' : 'secondary'}
                        inputRef={register({
                            required:{
                                value:taskDone,
                                message:'Requerido'
                            }
                        })}
                        />
                    </PageContainer>
                    <br/>
                    <Divider/>
                    <br />

                    {/* <FlexContainer flexDirection='column' width='100%'  justify='flex-end'> */}
                        
                        <FlexContainer justify='flex-end'>
                            {(!step?.observation && (survey?.observations?.length === 0)) && 
                            <CustomButton icon='close' action={()=>setStep({observation:'Sin observaciones'})}>
                                Sin observaciones
                            </CustomButton>
                        /*  <Button size='small' variant='contained' color='secondary'  onClick={()=>setStep({observation:'Sin observaciones'})}>
                        <Icon >close</Icon> Sin observaciones
                        </Button> */}

                            <CreateObservation
                                outOfRoute={outOfRoute}
                                taskType = {taskType}
                                lubricationPoint={item}
                                type='OBSERVACION' 
                                buttonTitle='observaciones'
                                formTitle='Nueva observación'
                                variant={(step?.observation || (survey?.observations?.length !== 0)) ? 'list' : 'modal'} 
                                listTitle={(step?.observation) || 'Observaciones'}
                                buttonType='add'
                            />
                        </FlexContainer>

                            <AddSupply
                                outOfRoute={outOfRoute}
                                listTitle='Insumos' 
                                formTitle={'Nuevo insumo'}
                                buttonTitle={'Insumos'}
                                variant={'list'}
                                buttonType={'add'} 
                                lubricationPoint={item}
                            />

                        
                    {/* </FlexContainer> */} <br />

                    {(step?.observation || (survey?.observations?.length !== 0)) &&
                        <TgdFab 
                            bottom={4}
                            right={4}
                            color='primary' 
                            onClick={handleSubmit(submit)}
                            children={<ArrowForwardIcon/>}
                        />
                    }
                </>}
            </>
    </>)
}

export default React.memo(SurveyClean)