import React from 'react';
import SelectCriticality from '../../../domains/criticalities/components/SelectCriticality';
import { TgdTextField } from '../../../domains/form-config/formComponents/TgdTextField';
import {  InputComponentTypes, uiModelInterface } from '../typescript/FormTypes';
import SelectColor from '../../../domains/criticalities/components/SelectColor';
import SelectCriticalityType from '../../../domains/criticalities/components/SelectCriticalityType';
import SelectType from '../../../domains/types/atoms/SelectType';
import SelectTypeBy from '../../../domains/types/molecules/SelectTypeBy';
import SelectLubricantBy from '../../../domains/lubricants/molecules/SelectLubricantBy';
import SelectSector from './../../../domains/sectors/atom/SelectSector';
import SelectSupplyType from '../../../domains/supply/atom/SelectSuplyType';
import SelectOperator from '../../../domains/routes/components/atoms/SelectOperator';
import Selectperiodicity from '../../../domains/routes/components/atoms/SelectPeriodicity';
import SelectArea from '../../../domains/areas/molecules/SelectArea';



interface Props {
    fieldProps:uiModelInterface<any>
}

const FieldSelector:React.FC<Props> = ({fieldProps}) => {
    
    
    
    switch (fieldProps.component) {

        //Generic inputFields
        case InputComponentTypes.text:
        return <TgdTextField {...fieldProps} />  
        
        case InputComponentTypes.date:
        return <TgdTextField {...fieldProps} type='date' />  

        case InputComponentTypes.number:
        return <TgdTextField {...fieldProps} type='number' />   
    
        case InputComponentTypes.description:
        return <TgdTextField {...fieldProps} />  
        
        //Lubricants
        case InputComponentTypes.lubricant:
        return<SelectLubricantBy {...fieldProps} {...fieldProps.inputConfig} />

        //Sectors
        case InputComponentTypes.sector:
        return<SelectSector {...fieldProps} {...fieldProps.inputConfig} />
        
        //Types
        case InputComponentTypes.types:
        return <SelectType {...fieldProps}  />
        //area
        case InputComponentTypes.area:
        return <SelectArea {...fieldProps} {...fieldProps.inputConfig} />
        
        case InputComponentTypes.typeBy:
        return <SelectTypeBy {...fieldProps} {...fieldProps.inputConfig} />

        //Supply
        case InputComponentTypes.supplyType:
        return <SelectSupplyType {...fieldProps} />

        //Criticalities
        case InputComponentTypes.criticality:
        return <SelectCriticality {...fieldProps} {...fieldProps.inputConfig} />

        case InputComponentTypes.color:
        return <SelectColor {...fieldProps} />

        case InputComponentTypes.criticalityType:
        return <SelectCriticalityType {...fieldProps}/>

        //Routes
        case InputComponentTypes.operator:
        return <SelectOperator {...fieldProps} {...fieldProps.inputConfig}/>
       
        case InputComponentTypes.periodicity:
        return <Selectperiodicity {...fieldProps} />

        //not showing
        case InputComponentTypes.none:
        return null

        //Default
        default:
        return null
    }
}

export default FieldSelector;
