import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
//components
import './index.css';
import App from './App';
//material
import CssBaseline from '@material-ui/core/CssBaseline'
import {ThemeProvider} from '@material-ui/core/styles'
import theme from './infrastructure/materialUi/themeConfig'

//routing
import {BrowserRouter as Router, HashRouter} from 'react-router-dom'
//config 
import * as serviceWorker from './serviceWorker'
//context
import { SessionContextProvider } from './store/sessionContext';
import { RecoilRoot } from 'recoil';





ReactDOM.render(
    <>
      <CssBaseline />
      <SessionContextProvider>
        <HashRouter >
            <ThemeProvider theme={theme}>
              <App/>
            </ThemeProvider>
        </HashRouter>
      </SessionContextProvider>
    </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
