import React, { useState } from 'react';
import DefaultFormLayout from '../../../components/forms/DefaultFormLayout';

import FormSelector from '../../form-config/FormSelector';
import { useSTIEquipmentForm } from './services/useSTIEquipmentForm';
import { useUpdateSTIEquipment } from './services/STIEquipmentService';
import { STIEquipmentInterface } from './interfaces/STIEquipmentInterfaces';
import ModalList from '../../../components/modals/modalList/ModalList';




const UpdateSTIEquipmentForm:React.FC<{tagFP:string,item:STIEquipmentInterface,items:STIEquipmentInterface[]}> = ({tagFP,items,item}) => {

    const [current,setItem] = useState()
    const {setValue,handleSubmit,watch,errors,form} = useSTIEquipmentForm({actionType:'update',defaultValue:current},tagFP)
    const {updateSTIEquipment,data} = useUpdateSTIEquipment()
    
    const filters = ['egressDate','admissionDate','state','lowReason','tagFP']
    const fields = form.fields?.filter(field=>!filters.includes(field.name))
    
    const submit = (fields:STIEquipmentInterface) => {
        updateSTIEquipment(fields)
    }

    return (
        <ModalList icon='edit'  onChange={setItem} item={item} items={items} title={form.name}>
            <DefaultFormLayout 
            status={data.status}
            message={data.message}
            error={data.error} 
            icon={'edit'}
            mode='content'
            variant='iconButton' 
            buttonTitle={form.name} 
            popoverTitle={form.name} 
            onChange={handleSubmit(submit)} >
                {fields?.map(field=>
                    <FormSelector
                        key={field.name}
                        selector={field.formSelector}
                        fieldProps={{
                            type:field.type,
                            error:errors && errors[field.name]?.message,
                            label:field.label,
                            value:watch(field.name,field.initialValue),
                            defaultValue:watch(field.name,field.initialValue),
                            onChange:(value)=>setValue(field.name,value),
                            tagFP
                        }}
                    />    
                )}
            </DefaultFormLayout>
        </ModalList>
    );
}

export default UpdateSTIEquipmentForm;
