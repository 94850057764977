

import axios,{AxiosRequestConfig} from'axios'
//services
import { getAllResources, getAllResourcesByEmail } from '../../infrastructure/axios/service.tgd.axios'
import { useQuery, useMutation, queryCache } from 'react-query';
import {useToken} from '../session/service.session'
import { GridModelInterface, MutationQueryObject } from '../../infrastructure/Interfaces';
import { ONLINE_MODE, URL } from '../../infrastructure/appConfig/appConfig';
import { useStatusProcessor } from './../errors/services/StatusProcessor';



export interface PlantInterface{
    factory: string
    factoryId: number
    id: number
    location: string
    name: string
    plantId: number
    referrer: string
    tagFP: string
    state?:string
}

export const GridModelPlants:GridModelInterface = {
    factory:{label:'Fábrica'},
    factoryId:{label:'Id de fábrica'},
    id:{label:'Id'},
    location:{label:'ubicación'},
    name:{label:'Nombre'},
    plantId:{label:'Id de planta'},
    referrer:{label:'Referente'},
    tagFP:{label:'Planta'},
    state:{label:'Estado',options:[
        {name:'Activo',value:true},
        {name:'Inactivo',value:false},
    ]},
}

const plantCRUDDependencies =[
    'AllPlants',
    'PlantsByTagF',
    'factoryPlants',
    'SupervisorPlants',
    'OperatorPlants',
    'UserPlantsState',
]

//----------------------------
//axios Repo
//----------------------------

export const CreatePlant = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreatePlant`,
        data
    }
    return axios(config).then((res)=>{return res}).catch((err) => {return err})
}

export const assignPlantToUser = ({data,token}:MutationQueryObject) => { //get object 'cause mutation query bring it that way
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/AssignPlant`,
        data
    }
    let res = axios(config).then(res=>{return res.data}).catch(res=>{return res})
    return res
}


export const updateUserPlantState = ({data,token}:MutationQueryObject) =>{ //get object 'cause mutation query bring it that way
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdatePlant`,
        data
    }
    return axios(config).then(res => {return res.data}).then(res => {return res})
}

export const PlantsByTagF = (_:string,tagF:string,token:string) =>{ //get object 'cause mutation query bring it that way
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config:AxiosRequestConfig = {
        method: "GET",
        url:`${URL}/PlantsByTagF/${tagF}`,
    }

    return axios(config).then(res => {return res.data}).then(res => {return res})
}


//----------------------------
//react-query HOOKS
//---------------------------------------------
//---------------------------------------------
//QUERYS


export const useAllPlants = () => {
    const token = useToken()
    return useQuery(['AllPlants',token], getAllResources,{
        enabled:!!token
    })
}
export const usePlantsByFactory = (tagF?:string) => {
    const token = useToken()
    return useQuery<PlantInterface[]>(['PlantsByTagF',tagF,token],getAllResourcesByEmail,{
        enabled:!!tagF && token
    })
}

export const usePlantsByTagF = (tagF?:string) => {
    const token = useToken()
    return useQuery(['factoryPlants',tagF,token],PlantsByTagF,{
        enabled:!!tagF && !!token
    })
}

export const useEngineerPlants = (email:string) => {
    const token = useToken()
    return useQuery(['SupervisorPlants', email, token], getAllResourcesByEmail,{
        enabled:!!token
    })
}
export const useOperatorPlants = (email:string) => {
    const token = useToken()
    return useQuery(['OperatorPlants',email, token], getAllResourcesByEmail,{
        enabled:!!token
    })
}

export const useUserActivatedCancelledPlants = (email:string | undefined | null) => {
    const token = useToken()
    return useQuery(['UserPlantsState',email,token],getAllResourcesByEmail,{
        enabled:!!email && token
    })
}

export const useUserActivatedPlants = (email:string | undefined | null) => {
    const query = useUserActivatedCancelledPlants(email)
    let dataProcesed = query.data ? query.data.filter((item:PlantInterface) => item.state ==='A') : null
    if(dataProcesed) return [dataProcesed, query]
    return [dataProcesed, query]
}

export const useUserCancelledPlants = (email:string | undefined | null) => {
    const query = useUserActivatedCancelledPlants(email)
    let dataProcesed = query.data ? query.data.filter((item:PlantInterface) => item.state ==='C') : null 
    if(dataProcesed) return [dataProcesed, query]
    return [dataProcesed, query]
}




//------------------------------
//MUTATIONS

export const useUpdatePlantsStateFromUser = (refetch:()=>void) =>{//update plant states for user plants state endpoint: /UpdatePlant
    return useMutation(updateUserPlantState,{
        onSuccess:refetch,
    })
}

export const useAssignPlant = (refetch:()=>void) => {
    return useMutation(assignPlantToUser,{
        onSuccess:refetch,        
    })
}

export const useCreatePlant = () => {
    const token = useToken()
    const [mutation,query] = useMutation(CreatePlant,{
        onSuccess:()=>{
            plantCRUDDependencies.forEach((dependency) =>{
                queryCache.invalidateQueries(dependency)
            })
        }
    })

    const status =  useStatusProcessor(query)

    const createPlant = (data:PlantInterface) => {
        return mutation({
            token,
            data
        })
    }

    return {
        createPlant,
        ...query,
        ...status
    }

}




//----------------------------
//custom HOOKS
//---------------------------------------------


export const useAssignCancelUserPlant = (email:string | undefined | null) => {

    const {refetch} = useUserActivatedCancelledPlants(email)
    const token = useToken()
    const [updatePlantState] = useUpdatePlantsStateFromUser(refetch)//useMutation
    const [assignPlantToUser] = useAssignPlant(refetch)//useMutation
    const [userCanceledPlants] = useUserCancelledPlants(email)



    const handleUserPlants = (action:'assign' | 'cancel' ,data:any) => new Promise<any>(()=>{
        if(action==='assign'){
            if(userCanceledPlants){
                if((userCanceledPlants.filter((i:PlantInterface) => i.tagFP === data.tagFP).length > 0)){
                    /* console.log('user's plant activation') */
                    updatePlantState({data,token})
                }
                else{
                   /*  console.log('assign plant to an user when plant doesn´t exist for the user') */
                    assignPlantToUser({data,token})
                }
            }
            else{
                /* console.log('user's plant activation') */
                updatePlantState({data,token})
            }
        }
        if(action === 'cancel'){
            /* console.log('user's plant cancelation') */
            updatePlantState({data,token})
        }
    })

    return { handleUserPlants }
}

