import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TablePagination, CircularProgress } from '@material-ui/core';
import TgdListToolbar from './lists/TgdListToolbar';
import { Alert } from '@material-ui/lab';

import {  LubricationPointInterface } from '../domains/equipments/services/service.lubricationPoints';
import PageContainer from './pageContainers/PageContainer';
import { useSearch } from './../utils/hooks/useSearch';



interface Props{
    items:any[]
    type:'component' | 'equipment' | 'element' 
    headerAction?:React.ReactNode
    itemActions:any
    title?:string
    height?:string | undefined | null
    routeAction?:(item:LubricationPointInterface)=>void
    supplyAction?:(item:LubricationPointInterface)=>void
    permissionAction?:(item:LubricationPointInterface)=>void
}

const TgdSimpleTable:React.FC<Props> = ({height,items,type,headerAction,itemActions,title,routeAction,supplyAction,permissionAction})=> {
    
    const rows = items || []

  
    
    const lubricationPointColumns:{label:string,atribute:string}[] = [
        {label:'Tag TGD',atribute:'tagTGD'},
        {label:'Equipo',atribute:'equipment'},
        //{label:'Elemento',atribute:'element'},
        {label:'Componente',atribute:'component'},
        {label:'Lubricante',atribute:'lubricant'},
        //{label:'Permisos',atribute:'permissions'}
    ]
    
    const equipmentColumns:{label:string,atribute:string}[] = [
        {label:'Tag TGD',atribute:'tagTGD'},
        {label:'Sector',atribute:'sector',},
        {label:'Tag Planta',atribute:'plantTag'},
        {label:'Equipo',atribute:'equipment'},
        {label:'Tipo',atribute:'type'},
        {label:'Criticidad',atribute:'criticality'},
       
    ]

    const elementColumns:{label:string,atribute:string}[] = [
        {label:'Tag TGD', atribute:'tagTGD'},
        //{label:'Sector',atribute:'sector',},
        {label:'Elemento', atribute:'element'}
    ]

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(8);
    const {search,handleSearch,filteredItems} = useSearch(items,'equipment')

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };


    return (
        (!items) ? <PageContainer><CircularProgress size='24px' color='primary' /></PageContainer> :
        (items?.length < 1) ? <><br/><Alert severity='warning'>No hay Equipos en esta planta</Alert></>:
    <>
        <TgdListToolbar
        title={title ? title : ''}
        searchValue={search}
        handleSearch={handleSearch}
        actions={headerAction}
        searchBar={false}
        /> 
       
        <TableContainer style={{height:height || '45vh'}} component={Paper}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        
                            {type === 'component' &&
                            lubricationPointColumns.map((item) =>
                                <TableCell key={item.label}>{item.label}</TableCell>
                                
                            )} 

                            {type === 'equipment' &&
                            equipmentColumns.map((item) =>
                                <TableCell key={item.label}>{item.label}</TableCell>
                            )} 

                            {type === 'element' &&
                            elementColumns.map((item) =>
                                <TableCell key={item.label}>{item.label}</TableCell>
                            )} 
                           {/*  {type==='component' && <>
                                <TableCell >Rutas</TableCell>
                                <TableCell >Insumos</TableCell>
                            </>}
                            <TableCell align='right' width={160}>Más</TableCell> */}
                    
                    </TableRow>
                </TableHead>
                <TableBody>
                    {type === 'component' && !!filteredItems &&
                    filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row:any) => (
                        <TableRow key={row.tagTGD}>
                            {lubricationPointColumns.map(({label,atribute}:{label:string,atribute:string}) => 
                            <TableCell key={atribute}>{row[atribute]}</TableCell>    
                            )}

                           {/*  <TableCell width={300}>
                            <div style={{display:'flex',flexWrap:'wrap',alignItems:'center'}}>
                                {routeAction && routeAction(row)}
                                {JSON.parse(row.routes)?.map((r:string) => 
                                    <SimpleChip key={r+Date()} label={r}  type={'route'}/>
                                    )} 
                            </div>
                            </TableCell> */}

                          {/*   <TableCell width={300}>
                                <div style={{display:'flex',flexWrap:'wrap',alignItems:'center'}}>
                                    {supplyAction && supplyAction(row)}
                                    {JSON.parse(row.supplies)?.map((supply:string,index:number) => 
                                        <SimpleChip  key={supply+(index*65)} label={supply.split('|').length > 1 ? supply.split('|')[1] : supply} type={'supply'} />
                                    )}
                                </div>
                            </TableCell> */}

                           {/*  <TableCell width={300}>
                                <div style={{display:'flex',flexWrap:'wrap',alignItems:'center'}}>
                                    {permissionAction && permissionAction(row)}
                                    {row.permissions?.map((permission:string,index:number) => 
                                        <SimpleChip  key={permission} label={permission} type={'supply'} />
                                    )}
                                </div>
                            </TableCell> */}

                            <TableCell align='right'>
                                {itemActions && itemActions(row)}  
                            </TableCell>
                        </TableRow>
                    ))}
                    {type === 'equipment' &&
                    filteredItems?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row:any) => (
                        <TableRow key={row.tagTGD}>
                            {equipmentColumns.map(({atribute},index:number) => 
                            <TableCell key={index+atribute}>{row[atribute]}</TableCell>    
                            )}
                            <TableCell align='right'>
                                {itemActions && itemActions(row)}  
                            </TableCell>
                        </TableRow>
                    ))}
                    {type === 'element' &&
                    filteredItems?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row:any) => (
                        <TableRow key={row.tagTGD}>
                            {elementColumns.map((i) => 
                            <TableCell key={row[i.atribute]+i.atribute}>{row[i.atribute]}</TableCell>    
                            )}
                            <TableCell align='right'>
                            {itemActions && itemActions(row)}    
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
        rowsPerPageOptions={[8,25,100,200]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      
    </>);
    }

export default React.memo(TgdSimpleTable)

