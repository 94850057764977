import axios,{AxiosRequestConfig} from 'axios'
import { useMutation } from 'react-query';
import { URL } from '../../../infrastructure/appConfig/appConfig';
import { MutationQueryObject } from '../../../infrastructure/Interfaces';

//-------------------------
//AXIOS REPOSITORY
//-------------------------
const CreateSupportTicket = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/HelpDeskEmail`,
        headers: {}, 
        data:data
    }
    return axios(config).then(res => {return res}).catch(err =>{return err})
}

//-------------------------
//USE QUERY
//-------------------------

//-------------------------
//MUTATIONS
//-------------------------

export const useCreateSupportTicket = () =>{
    return useMutation(CreateSupportTicket)
}

//-------------------------
//CUSTOM HOOKS
//-------------------------
