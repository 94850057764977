import React from 'react';
import {TextField} from '@material-ui/core'
import { FieldProps } from '../interfaces';

export const TgdTextField:React.FC<FieldProps> = ({value,defaultValue,onChange,label,error,type,placeholder,helper,readonly,rows}) =>{
    
    const _label = `${label} ${error || ''}`
    const _color = error ? 'primary' : 'secondary'

    const multiline = rows ? true : false


    return(
        <TextField
            InputProps={{
                readOnly:readonly
            }}
            multiline={multiline}
            rows={rows}
            InputLabelProps={{ shrink: true }}
            helperText={helper}
            id='_label'
            type={type || 'text'}
            focused={error ? true : false}
            autoFocus
            style={{width:'100%'}}
            variant={readonly ? 'filled' : 'outlined'}
            size='small'
            label={_label}
            color={_color}
            onChange={(e:any)=> onChange && onChange(e.target.value || '')}
            //defaultValue={defaultValue} //console error controlled component choice must be set or default value or value
            value={value}
            placeholder={error ? error : (placeholder || '')}
        />
    )
}

export default React.memo(TgdTextField);
