import React,{useState} from 'react';
//components

//material
import {createStyles, makeStyles} from '@material-ui/core/styles'
import { Button,  Icon, Paper, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DomainIcon from '@material-ui/icons/Domain';

import UpdatePersonForm from '../../domains/persons/UpdatePersonForm';
import { useUser } from '../../domains/persons/services/service.person';
import { useLocation } from 'react-router-dom';
import { USER_ROUTES } from '../../infrastructure/routes/routes';


const useStyles = makeStyles((theme) => createStyles({
    ResourceList:{
        display:'flex',
        paddingTop:theme.spacing(0.5),
        paddingBottom:theme.spacing(0.5),
        paddingRight:theme.spacing(0.5),
        paddingLeft:theme.spacing(3),
        width:'100%',
        justifyContent:'space-between'
    },
    ResourceListContainer:{
        margin:' 0 0 16px',
        width:'100%',

    }

}))


interface Props {
    state:any
    actionEdit: ()=>void
    handleState:(item:any)=>void
    children?:(item?:any)=>React.ReactNode
    toggleButton?:React.ReactNode
    background?:'string'
}

const ResourceList:React.FC<Props> = ({state,actionEdit,handleState,children,toggleButton,background}) => {
    const classes = useStyles()
    const {data:user} = useUser()
    const [dropdown,setDropdown] = useState<any>({
        position:'relative',
        display:'none',
        backgroundColor:'#fff',
        padding:'24px',
        height:'0px',
        transition:'fade-in 300ms',
        justifyContent:'space-between',
        alignItems:'center',
        margin:'-5px 0 0 ',
    })

    const location = useLocation()

    const click = (data:any) =>{
        handleState(data)
        actionEdit()
    }

    const handleDropdown = () => {
        setDropdown({
            ...dropdown,
            display:'flex',
            flexDirection:'column',
            minHeight:'250px',
            height:'auto',
            transition:'ease-in 300ms',
        })
    }
    const Done = () => {
        setDropdown({
            display:'none',
            backgroundColor:'#fff',
            padding:'24px',
            height:'0px',
            transition:'ease-in 300ms',
            justifyContent:'space-between',
            alignItems:'center',
            margin:'-5px 0 0 ',
        })
        if(location.pathname.includes(USER_ROUTES.notifications)){
            actionEdit()
        }

    }
 



    if(state){
        return (

            <div className={classes.ResourceListContainer}>
                <Paper className={classes.ResourceList} style={{backgroundColor:background}}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        {state.title && <Typography style={{minWidth:'150px'}} variant='h6'>{state.title}</Typography>}
                        
                        {state.name && <Typography style={{minWidth:'150px'}} variant='body2'>{state.name && state.name}</Typography>}
                        {state.email && <Typography style={{minWidth:'150px'}} variant='body2'>{state.email && state.email}</Typography>}
                        {/* <Typography variant='body2'>{state.role && state.role}</Typography> */}
                    </div>
                    <div>
                        {!children && handleState && (user?.type !== 'S') &&
                            <IconButton onClick={() => click(state)} color="primary"  aria-label="Editar Gerente regional">
                                <DomainIcon/>
                            </IconButton>
                        }

                        {children &&
                            children(state)
                        }
  
                        <IconButton onClick={handleDropdown} color="primary"  aria-label="Editar Gerente regional">
                            {!!children ? toggleButton : <EditIcon/>}
                        </IconButton>

                        
                    </div>
                </Paper>
                <div style={dropdown}>
                {state.body &&
                    <div style={{borderTop:'solid 1px #d3d3d3',padding:'24px 0',marginBottom:'16px', borderBottom:'solid 1px #d3d3d3', width: '100%'}}>
                        <Typography style={{textAlign:'left',width:'100%'}} variant='body1'>{state.body}</Typography>
                    </div>
                }

                    {location.pathname.includes(USER_ROUTES.addUser) ?<>
                        <UpdatePersonForm children={null} person={state}/>
                        
                    </> : null}
                    <div style={{display:'flex',gap:'24px',justifyItems:'flex-end',width:'100%'}}>
                    {state.releaseDate && <Typography style={{minWidth:'150px'}} variant='body1'>{state.releaseDate.split('T')[0]}</Typography>}
                    <Button onClick={Done} variant='outlined' color='primary' style={{margin:'0 0 0 auto'}}><Icon>keyboard_arrow_up</Icon></Button>
                    </div>
                </div>
            
            </div>
        )
    }else{
        return(null)
    }
}

export default ResourceList;
