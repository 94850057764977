//equipments prevoisly not done
import { useMemo } from 'react';
import { LubricationPointInterface } from '../../../../equipments/services/service.lubricationPoints';
import { useOperatorLubricationNumber } from '../../../../persons/services/service.person';
import { useDailyRoutesByLubricatorNumber } from '../../routes/services/colectorServiceRoutes';
import { useLeakLevel } from './leakLevel';
import { useDayPendingPointsByDate, useGetPointsDataWithInfo } from './colector.LubricationPointsService';
import { useSupervisorSelectedLubricatorData } from '../../../pages/SupervisorColectorPage';



export const useLubricationPreviousPendingPointsByOperator = () => {
    const {data:pendingPoints} = useDayPendingPointsByDate()
    const {data:operator} = useSupervisorSelectedLubricatorData()
    const lubricatorNumber = useOperatorLubricationNumber()
    const _lubricatornumber = operator?.lubricatorNumber || lubricatorNumber
    
    return useMemo(()=>
    pendingPoints ? JSON.parse(pendingPoints.previousPending).map((point:string)=>({
            tagTGD:point.split('|')[0],
            routes:point.split('|')[1]
        })).filter((point:{routes:string})=> parseInt(point?.routes?.split('-')[0]) === _lubricatornumber) :undefined
    ,[pendingPoints,_lubricatornumber])
}

export const useDailyPreviousPendingPointsWithInfo = () => {
    const DailyPreviousPendingPoints = useLubricationPreviousPendingPointsByOperator()
    const {data:DailyPreviousPendingPointsWithInfo} = useGetPointsDataWithInfo(DailyPreviousPendingPoints)
    const {calculateLeakLevel} = useLeakLevel()
    return useMemo(()=>{
        return Array.isArray(DailyPreviousPendingPointsWithInfo) ? DailyPreviousPendingPointsWithInfo?.map((point)=>({
                ...point,
                leakLevel:calculateLeakLevel(point.info?.lubricantQuantity || 0,point.capacity)
            })) : undefined
    },[DailyPreviousPendingPointsWithInfo,calculateLeakLevel])
}

//equipments in today Routes
export const useDailyLubricationPointsWithInfo = ():LubricationPointInterface[] | undefined => {
    const {data:DailyOperatorRoutes} = useDailyRoutesByLubricatorNumber()
    const dailyLubricationPoints = useMemo(()=>DailyOperatorRoutes?.map((route)=>(JSON.parse(route?.lubricationPoints!).map((point:string)=>({tagTGD:point,routes:route.routeName})))).flat(),[DailyOperatorRoutes])
    const {data:DailyOperatorLubricationPoints} = useGetPointsDataWithInfo(dailyLubricationPoints)
    const {calculateLeakLevel} = useLeakLevel()
    return useMemo(()=>{
        return /* Array.isArray(DailyOperatorLubricationPoints) ?  */DailyOperatorLubricationPoints?.map((point)=>{
            const leakLevel = calculateLeakLevel(point.info?.lubricantQuantity || 0,point.capacity)
            return{
                ...point,
                leakLevel:leakLevel
            }}) /* :undefined */
    },[DailyOperatorLubricationPoints,calculateLeakLevel])
}

export const usePendingPoints = ():LubricationPointInterface[] | undefined => {
    
    const lubricationPointsWithInfo = useDailyLubricationPointsWithInfo()
    /* const {data:routes} = useDailyRoutes() */

    return useMemo(()=>{
        if(lubricationPointsWithInfo){
            return lubricationPointsWithInfo?.filter((point:LubricationPointInterface)=>(point.info?.pending))
        }
        return undefined
    },[lubricationPointsWithInfo])
}