import { queryCache, useMutation } from "react-query"
import { CrudFormType } from "../../../infrastructure/form/typescript/FormTypes"
import { useStatusProcessor } from "../../errors/services/StatusProcessor"
import { useToken } from "../../session/service.session"
import { axiosCallType, endpointType } from "../types/crudTypes"

export const useCreateUpdate = <T,>(axiosCall:axiosCallType,actionType:CrudFormType, updateDependencies?:endpointType[]) => {
    const token = useToken()
    
    //CREATE -------
    const [Create,createResult] = useMutation(axiosCall.create,{
        onSuccess:()=>{
            updateDependencies?.forEach((d)=>{
                queryCache.invalidateQueries(d)
            })
        }
    })
    const createStatus = useStatusProcessor(createResult)
    const create = (data:T) => {
        return Create({
            token,
            data
        })
    }

    //UPDATE -------
    const [Update,updateResult] = useMutation(axiosCall.update,{
        onSuccess:()=>{
            updateDependencies?.forEach((d)=>{
                queryCache.invalidateQueries(d)
            })
        }
    })

    const updateStatus = useStatusProcessor(updateResult)

    const update = (data:T) => {
        return Update({
            token,
            data
        })
    }

    //UPDATE -------
    const [Delete,deleteResult] = useMutation(axiosCall.delete,{
        onSuccess:()=>{
            updateDependencies?.forEach((d)=>{
                queryCache.invalidateQueries(d)
            })
        }
    })

    const deleteStatus = useStatusProcessor(updateResult)

    const deleteItem = (data:T) => {
        return Delete({
            token,
            data
        })
    }

    return (()=> {
        switch (actionType) {
            case CrudFormType.create:
                return {
                    ...createResult,
                    status:createStatus.status,
                    error:createStatus.error,
                    message:createStatus.message,
                    resetStatus:createStatus.reset,
                    action:create,
                }

            case CrudFormType.update:
                return {
                    ...updateResult,
                    status:updateStatus.status,
                    error:updateStatus.error,
                    message:updateStatus.message,
                    resetStatus:updateStatus.reset,
                    action:update,
                }
            
            case CrudFormType.delete:
                return {
                    ...deleteStatus,
                    status:deleteStatus.status,
                    error:deleteStatus.error,
                    message:deleteStatus.message,
                    resetStatus:deleteStatus.reset,
                    action:deleteItem
                }
        }
    })() /* as DbMutation<CriticalityInterface> */
}