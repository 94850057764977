import { Button, CircularProgress, LinearProgress } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import React, { useEffect, useMemo, useState } from 'react';
import { QueryStatus } from 'react-query';
import CustomButton from '../../components/buttons/CustomButton';
import { Snackbar } from '@material-ui/core';
import EaseInOut from '../../components/presentational/EaseInOut';
import FlexContainer from '../../components/presentational/FlexContainer';


interface Props{
    status:QueryStatus
    message:string | undefined
    error:any
    reset?:()=>void
    severity?:Color
}



const TimedProgress:React.FC<{time?:number}> = React.memo(({time}) => {


    const [progress,setProgress] = useState(0)
    /* const coeficient = time ? 100/time : 0 */

    useEffect(()=>{
        const pgress = setInterval(()=>{
            setProgress((p) => (p >= 100 ? 0 : p + 10))
        },200)

        return ()=>clearInterval(pgress)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <LinearProgress color='secondary' variant="determinate" value={progress} />
    )
})

const StatusAndMessage:React.FC<Props> = ({status,message,error,reset,severity}) => {

    const isTriggered = (status === QueryStatus.Success) && reset
    const time = 2000 

    useEffect(() => {
        if(isTriggered){
            const timeOut = setTimeout(()=>{
                reset && reset()
            },time)
            return () => clearTimeout(timeOut)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isTriggered]); 

    

    const action = !!reset ? <CustomButton icon={'close'} variant='iconButton' action={reset}></CustomButton> : null

    return (<>
           
            {error && 
                <EaseInOut>
                    <Alert          
                        action={action}
                        severity={severity || 'error'} 
                        onClose={reset}>
                            Ha ocurrido un problema. {error} 
                    </Alert>
                </EaseInOut>
            }

                {message && 
                <EaseInOut>
                    <Alert 
                        action={action}
                        severity={severity || (status === 'error' ? 'warning' : 'success')} 
                        onClose={reset}>
                            {message}<br/> 
                            {isTriggered &&  <TimedProgress time={time} />}
                        
                    </Alert>
                </EaseInOut>}

                {(error || message) && <br/> }  
        
    </>);
}

export default React.memo(StatusAndMessage);
