import React from 'react'
/* import CreateLubricantForm from '../CreateLubricantForm'
 */import DeleteLubricantform from '../DeleteLubricantform'
/* import UpdateLubricant from '../UpdateLubricantForm'
 */import { PlantInterface } from '../../plants/service.plant'
import { gridModelLubricants, useAllLubricantsByPlant } from '../services/service.lubricants'
import AnyGrid, { CarrousellActionInterface } from '../../../components/grids/AnyGrid'
import CULubricantForm from '../CULubricantForm'
import { CrudFormType } from '../../../infrastructure/form/typescript/FormTypes'

interface props{
    plant:PlantInterface
}

const carrousellActions:CarrousellActionInterface[] =[
    {icon:'edit',component:(item,tagFP)=> <CULubricantForm item={item} tagFP={tagFP} actionType={CrudFormType.update}/>}
]

const ManageLubricantsPage:React.FC<props> = ({plant}) => {

    const _tagFP = plant.tagFP
    const {data:lubricants} = useAllLubricantsByPlant(_tagFP) 

    return(
        <AnyGrid
            gridModel={gridModelLubricants}
            items={lubricants || []}
            loading={lubricants ? false : true}
            title={'Lubricantes'}
            height='55vh'
            carrousellActions={carrousellActions}
            itemActions={(item:any,items:any)=><>
                <DeleteLubricantform item={item} />
            </>}
            headerActions={(item:any)=>
                <CULubricantForm tagFP={_tagFP} actionType={CrudFormType.create}/>
            }
        />
    )

}
export default React.memo(ManageLubricantsPage)