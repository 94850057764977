import React from 'react';
import { List, ListItem, Typography, Divider, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import FlexContainer from '../../../../components/presentational/FlexContainer';
import { LocalStateSupply } from '../lubricationPointsInfo/colector.lubricationPointsInfoInterfaces';
import theme from '../../../../infrastructure/materialUi/themeConfig';
import { SupplyStateDimention } from './AddSupply';
import { TaskType } from '../tasks/interfaces/taskInterfaces';
import { SurveyTasksValues } from './../tasks/interfaces/taskInterfaces';
import { ComponentUIModes } from '../../../../components/ComponentUIModes';


interface Props{
    supplies:LocalStateSupply[]
    title?:string
    headerAction?:React.ReactNode
    handleDeleteItem?:(item?:any)=>void
    mode?:ComponentUIModes
    inmutableValues?:LocalStateSupply[]
    tasktype?:TaskType
}


const SupplyList:React.FC<Props> = ({ mode, headerAction, title, handleDeleteItem, supplies, inmutableValues,tasktype }) => {
    
    const _mode = mode || 'content'
    
    const supplyItem = ([supply,index]:any,undeletable?:boolean) => (
            <ListItem key={index}>
                <ListItemText 
                primary={supply.supply} 
                secondary={<span>
                    <span>{supply.type}</span> 
                    {tasktype && tasktype === SurveyTasksValues.puntuation 
                        ? null
                        : <> | <span style={{color:supply.state === SupplyStateDimention.needed ? theme.palette.info.main : theme.palette.secondary.main}}>{supply.state}</span> </>
                    }
                </span>}
                />
                <ListItemSecondaryAction>
                    <FlexContainer align='center'>
                        {handleDeleteItem && !undeletable &&
                        <IconButton onClick={()=>handleDeleteItem(index)}>
                            <DeleteIcon />
                        </IconButton>}
                    </FlexContainer>
                </ListItemSecondaryAction>
            </ListItem>
        )
    

    return(<>
        <List style={{width:'100%'}}>
            <ListItem>
                <Typography variant='h6'>{title}</Typography>
                <ListItemSecondaryAction>
                    {_mode ==='content' && headerAction && headerAction}
                </ListItemSecondaryAction>
            </ListItem>
            <Divider/> 
            {inmutableValues && inmutableValues?.map((...supply)=> supplyItem(supply,true))}
            {supplies?.map((...supply) => supplyItem(supply))}
        </List>

    </>)
}

export default React.memo(SupplyList);
