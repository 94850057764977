import React,{memo, useState} from 'react'
//Components

import EaseInOut from '../../components/presentational/EaseInOut'
//context

//material
import { makeStyles} from '@material-ui/core/styles'
import NavBar from './NavBar'
import Sidebar from './Sidebar'
import { Hidden } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline';
import { useTodayDelayedAndPendingSetter } from '../../infrastructure/tgd.config';
import Loading from '../../components/presentational/Loading'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
         ...theme.mixins.toolbar,
    },
    dashLayoutContent: {
        display:'flex',
        flexGrow:1,
        flexDirection:'column',
        padding: theme.spacing(0),
        backgroundColor:theme.palette.background.default,
        minHeight: '100vh',
    },
}));

const DashLayout:React.FC<{children?:React.ReactNode}> = ({ children }) => {

    //MATERIAL STYLES && ACTIONS
    const classes = useStyles()
    const [open, setOpen] = useState(false);
    const {status} = useTodayDelayedAndPendingSetter()

    //ACTIONS
    const handleToggle = () => {
        setOpen(!open);
    };

   
    return (
        (status !== 'success' ) ? <Loading /> :
        <EaseInOut children={
            <div className={classes.root}>
                <CssBaseline />
                {/* sidebar */}
                <Hidden smDown>
                    <NavBar 
                        open={!open}
                        action={handleToggle}
                    />
                    <Sidebar
                        variant="permanent"
                        open={!open}
                        anchor='left'
                        /* menu={props.userData.menu} */
                    />
                </Hidden>
                <Hidden mdUp>
                    <NavBar 
                        device='mobile'
                        open={open}
                        action={handleToggle}
                    />
                    <Sidebar
                        variant="temporary"
                        open={open}
                        onClose={handleToggle}
                        anchor='right'
                        /* menu={props.userData.menu} */
                    />
                </Hidden>

                {/* CONTENIDO */}
                <main className={classes.dashLayoutContent}>
                    <div className={classes.toolbar} />
                    {children}
                </main>
            </div>
        }/>
    );
}

export default memo(DashLayout)
