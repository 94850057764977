import React, { memo } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
    pageContainer:(props:any)=> ({
      width: props.width || 'auto',
      padding:props.padding || '24px',
      boxSizing:'border-box',
      backgroundColor:props.color,
      minHeight:props.minHeight,
      height:props.height,
      position:'relative'
    }),

}));

interface Props{
    children:any,
    color?:string
    width?:string
    height?:string
    minHeight?:string
    padding?:string
}

const PageContainer:React.FC<Props> = ({width,children, color,minHeight, height,padding}) => {
    const classes = useStyles({
        padding:padding,
        width:width,
        color:color,
        minHeight:minHeight,
        height:height,
    });

    return (
        <div className={classes.pageContainer}>
            {children}
        </div>
    );
}

export default memo(PageContainer);
