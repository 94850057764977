import React from 'react';
import { Alert } from '@material-ui/lab';
import PageContainer from '../../../components/pageContainers/PageContainer';
import { useLangLabels } from './../../../domains/language/services/useSetLabel';

interface ElementType {
    state: boolean
    message: string
}

interface FeaturesInterface {[key:string]:ElementType}

export const useFeatures = ():FeaturesInterface => {
    const {lang} = useLangLabels()

    return {
        collectorFeature:{
            state:false,
           message:lang.messages.features.collectorConfig
        },
        supervisorSurvey:{
            state:true,
            message:lang.messages.features.supervisorSurvey
        },
        supervisorClientVisor:{
            state:false,
            message:lang.messages.features.supervisorClientVisor 
        }
    }
}

interface Props{
    feature:ElementType
}

const FeatureStatusContainer:React.FC<Props> = ({children, feature}) => {


    const {state,message} = feature

    return (<>
        {state
            ? children
            : <PageContainer>
                <Alert severity='warning'>{message}</Alert>
            </PageContainer>
        }
    </>);
}

export default FeatureStatusContainer;
