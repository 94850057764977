import { useState } from "react"

export interface clickPositionInterface{
    positionX:number | string,
    positionY:number | string
}

export const useClickPosition = ()=> {
    
    const [position,setPosition] = useState<clickPositionInterface>({positionX:'',positionY:''})
    
    const getClickPosition = (e:any,_callback?:()=>void) =>{
        setPosition({
            positionX:e.clientX,
            positionY:e.clientY
        })
        _callback && _callback()
    }
    
    return{ 
        getClickPosition,
        position
    }
}