import React, { useEffect } from 'react';
import { CircularProgress, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDeleteAnomaly } from './services/service.anomalies';
import TgdPopover from '../../components/popovers/TgdPopover';
import { useStatusProcessor } from '../errors/services/StatusProcessor';
import { useClickPosition } from '../../utils/hooks/useClickPosition';
import { useSnackbar } from '../../utils/hooks/useSnackbar';
import { AnomalyInterface } from './types/anomaliesTypes';


interface Props{
    item:AnomalyInterface
}

const DeleteAnomalyForm:React.FC<Props> = ({item}) => {

    const { deleteAnomaly, data } = useDeleteAnomaly()
    const { position, getClickPosition } = useClickPosition()
    const { status, message } = useStatusProcessor(data)
    const { setData } = useSnackbar()

    useEffect(() => {
            if(status === 'success'){
                setData({
                    modal:true,
                    errors:message,
                    severity:status,
                    position
                })
            }
            if(status === 'error'){
                setData({
                    modal:true,
                    errors:message,
                    severity:'warning',
                    position
                })
            }

    }, [status]);

    return (
        data.isLoading 
        ? <CircularProgress size='20px' /> 
        : item ?
            <TgdPopover mode='hover' title='Eliminar anomalía'>
                <IconButton size='small' onClick={(e) => getClickPosition(e,()=>deleteAnomaly(item))}>
                    <DeleteIcon />
                </IconButton>
            </TgdPopover>
        : <></>
    );
}

export default React.memo(DeleteAnomalyForm);