import React, { useEffect, useMemo } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { PlantInterface } from '../../domains/plants/service.plant';
import { useEquipmentsTag } from '../../domains/equipments/services/service.lubricationPoints';
import CreateEquipmentPage from '../../domains/equipments/CreateEquipmentPage';
import UpdateLubricationPoint from '../../domains/equipments/UpdateLubricationPoint';
import DesactivateEquipment from '../../domains/equipments/UpdateEquipmentState';
import EditEquipmentComponent from '../../domains/equipments/EditEquipmentComponent';
import ViewLubricationPoint from '../../domains/equipments/ViewLubricationPoint';
import AssignCancelRouteForm from '../../domains/routes/components/AssignCancelRouteForm';
import { useProcesedEquipmentsByTagFPRefactor, gridModelLubricationPoints } from '../../domains/equipments/services/service.lubricationPoints';
import FlexContainer from './../../components/presentational/FlexContainer';
import TabPanel from './../../components/Tabs/TabPanel';
import AssignCancelPermissionForm from '../../domains/permisions/components/AssignCancelPermissionForm';
import AnyGrid from '../../components/grids/AnyGrid';
import IconStatus from '../../components/presentational/IconStatus';
import AssignCancelSupplyForm from '../../domains/supply/molecules/AssignCancelSupplyForm';
import CustomButton from '../../components/buttons/CustomButton';
import { useState } from 'react';
import ChangeRevision from '../../domains/equipments/ChangeRevision';
import PendingSuppliesToAssign from '../../domains/equipmentsInfo/PendingSuppliesToAssign';
import { EnumSupplyTypes } from '../../domains/supply/types/SupplyTypes';


function a11yProps(index: any) {
	return {
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `scrollable-auto-tabpanel-${index}`,
	};
}

interface Props{
	plant:PlantInterface
}

enum equipmentType {
	equipment='equipment',
	element='element',
	component='component'

}

const ManageEquipmentsPage:React.FC<Props> = ({plant}) => {

	const [draftTab, setDraftTab] = useState(false);

	const handleDraftTab = () => {
		setDraftTab(!draftTab)
	}
	
	const [value, setValue] = React.useState(0)
	const {equipments,elements,lubricationPoints} = useProcesedEquipmentsByTagFPRefactor(plant.tagFP)

	const {getTag}= useEquipmentsTag()

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
	setValue(newValue);
	};

	useEffect(() => {
		setValue(0)
		localStorage.setItem('plantConfigWorkspace',JSON.stringify(plant))
	}, [plant]);
	

	const  items = lubricationPoints?.filter(item => item.state === true) || []
	const  draftItems = lubricationPoints?.filter(item => item.state === false) || []
	
	const equipmentPageConfig = useMemo(()=>([
	{ 
		name:'Componentes',
		nameSingular:'Componente',
		items:draftTab ? draftItems : items,
		type:equipmentType.component,
		itemActions:(item:any) => item 
			? <FlexContainer gap={'16px'} align='center'>
				{item.state && <> 
				<IconStatus state={item?.state} />
				<ChangeRevision item={item}/>
				<ViewLubricationPoint item= {item} />
				<UpdateLubricationPoint item={item} />
				<PendingSuppliesToAssign lubricationPoint={item} />
				<AssignCancelRouteForm item={item} />
				<AssignCancelSupplyForm type={EnumSupplyTypes.tool} item={item}/>
				<AssignCancelSupplyForm type={EnumSupplyTypes.protection} item={item}/>
				<AssignCancelSupplyForm type={EnumSupplyTypes.consumable} item={item}/>
				<AssignCancelPermissionForm item={item}/>
				</>}
				<DesactivateEquipment type={'component'} tagTGD={getTag(item,'component')} item={item}/>
			</FlexContainer>
		:null,
	},
	{
		name:'Elementos',
		nameSingular:'Elemento',
		items:elements,
		type:equipmentType.element,
		itemActions:(item:any)=><>
			{/* <DesactivateEquipment type={'element'} tagTGD={getTag(item,'element')} item={item}/> */}
			<EditEquipmentComponent item={item} type='element'/>
		</>
	},
	{
		name:'Equipos',
		nameSingular:'Equipo',
		items:equipments,
		type:equipmentType.equipment,
		itemActions:(item:any)=><>
			{/* <DesactivateEquipment type={'equipment'} tagTGD={getTag(item,'equipment')} item={item}/> */}
			<EditEquipmentComponent item={item} type='equipment'/>
		</>
	},
	
	// eslint-disable-next-line react-hooks/exhaustive-deps
	]),[lubricationPoints, elements, equipments,getTag])


	return (
		<>
			<Tabs
			value={value}
			onChange={handleChange}
			indicatorColor="secondary"
			textColor="secondary"
			variant="scrollable"
			scrollButtons="auto"
			aria-label="scrollable auto tabs example"
			>
				{equipmentPageConfig.map((item,index:number) => 
					<Tab key={index+100} label={item?.name} {...a11yProps(index)} />
				)}
			</Tabs>

			{equipmentPageConfig.map((item,index:number) => 
				<TabPanel key={`${index+10}-${draftTab}`} value={value} index={index}>
					<AnyGrid
						gridModel={gridModelLubricationPoints}
						title={item.name}
						height='55vh' 
						loading={item.items ? false : true}
						items={item.items || []} 
						itemActions={item?.itemActions}
						headerActions={(value:any)=><> 
							{(item.type === equipmentType.equipment) && <CreateEquipmentPage plant={plant}/>}
							{!draftTab && item.type === equipmentType.component && <CustomButton icon='delete' popoverTitle='Inactivos' action={handleDraftTab} />}	
							{draftTab && item.type === equipmentType.component && <CustomButton icon='off' popoverTitle='Activos' action={handleDraftTab} />}	
						</> }		
					/>
				</TabPanel>
			)}
		</>
	);
}

export default ManageEquipmentsPage



