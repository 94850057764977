import React from 'react';
import { PurchaseRequestInterface } from './service.purchaseRequests';

import { TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import CustomButton from '../../components/buttons/CustomButton';

import { useUpdatePurchaseRequest } from './service.purchaseRequests';
import StatusAndMessage from '../errors/StatusAndMessage';
import FlexContainer from '../../components/presentational/FlexContainer';
import { useEffect } from 'react';

import ComponentMode from '../../components/ComponentMode';
import theme from '../../infrastructure/materialUi/themeConfig';
import { useStatusProcessor } from '../errors/services/StatusProcessor';
 

interface Props{
    item:PurchaseRequestInterface
}



const initialState = (state:any)=>{
    switch (state.state) {
        case 'PENDIENTE':
            return({
                ...state,
                stateColor:undefined,
                forms:[
                    {
                        name:'observations',
                        label:'Observacion',
                        title:'',
                        required:false,
                        props:{
                            variant:'outlined',
                            multiline:true,
                            minRows:8,
                            maxRows:8,
                            type:'text',
                            size:'small'
                        }
                    },
                ],
            })
        
    }
}


const UpdatePurchaseRequest:React.FC<Props> = ({item}) => {
    
    const {handleSubmit,register,errors,setValue} = useForm()
    const [updatePurchaseRequest,UpdateResult] = useUpdatePurchaseRequest()
    const { status, message,error } = useStatusProcessor(UpdateResult)
    const state = initialState({
        state:item.state,
    })

    useEffect(()=>{
        Object.entries(([key,value]:[string,any])=>{
            register('key')
            setValue(key,value)
        })
    },[item])


    const submit = (data:any)=>{
        updatePurchaseRequest({
            ...item,
            ...data
        })
    }

   
    return ( 
        item.state === 'PENDIENTE' 
        ?   <>
                <ComponentMode
                buttonColor='primary'
                mode={'modal'}
                icon='edit' 
                variant='iconButton' 
                title={'Editar solicitud'}> 
                   
                        <StatusAndMessage status={status} message={message} error={error} />
                            <FlexContainer flexDirection='column'>
                                
                                {state.forms.map((form:any,index:number)=>
                                    <TextField
                                    key={index}
                                    name={form.name}
                                    inputRef={register({
                                        required:{
                                            value:form.required,
                                            message:'Campo requerido'
                                        }
                                    })}
                                    {...form.props}
                                    label={errors.observation?.message || form.label}
                                    color={errors.observation?.message ? 'primary' : 'secondary'}
                                    />
                                    )}
                                <CustomButton status={status} action={handleSubmit(submit)}>Guardar</CustomButton>
                            </FlexContainer>
                        
                   
                </ComponentMode>
            </>
        :   null
    );
}

export default React.memo(UpdatePurchaseRequest);
