import React, { useEffect } from 'react';
import ModalButton from '../../components/buttons/ModalButton';
import { TextField } from '@material-ui/core';
import CustomButton from '../../components/buttons/CustomButton';
import FlexContainer from '../../components/presentational/FlexContainer';
import theme from '../../infrastructure/materialUi/themeConfig';
import StatusAndMessage from '../errors/StatusAndMessage';
import { useForm } from 'react-hook-form';
import { CategoriesAndSubCategoriesInterface, useUpdateCategory } from './service.category';
import { useStatusProcessor } from '../errors/services/StatusProcessor';
import { ComponentUIModes } from '../../components/ComponentUIModes';


interface Props {
    mode:ComponentUIModes
    defaultValue:CategoriesAndSubCategoriesInterface
}

const UpdateCategoryForm:React.FC<Props> = ({defaultValue,mode}) => {
    
    const [updateCategory,updateResult] = useUpdateCategory()
    const { status, message, error, reset:ClearStatus } = useStatusProcessor(updateResult)
    const {register,setValue,errors,watch,handleSubmit} = useForm()
    const title=`Editar categoría: ${defaultValue.category}`
    
    useEffect(()=>{
        if(defaultValue){
            Object.entries(defaultValue).map(([key,value]:[string,any]) => {
                if(!Array.isArray(value))
                register({ name:key },{ required: { value:true, message:'Campo requerido'}})
                setValue(key,value)
            })
        }
    },[])

    const submit = (item:any) => {
        updateCategory(item)
    }

    const form = <>
        <TextField
        inputRef={register()}
        name='category'
        size='small'
        variant='outlined'
        value={watch('category')}
        label={errors?.category?.message || 'Categoría'}
        color={errors?.category?.message ? 'primary' : 'secondary'}
        />
        <CustomButton status={status} action={handleSubmit(submit)}>Editar</CustomButton>
    </>

    const content=<> 
        <StatusAndMessage status={status} message={message} error={error} reset={ClearStatus} />
        
        {mode === 'modal' && 
        <FlexContainer flexDirection='column'>
            {form}
        </FlexContainer> }
    
        {mode === 'content' && 
        <FlexContainer padding={`${theme.spacing(3)}px 0px`}>
            {form}
        </FlexContainer>}    
    </>
    
    return (<>

        {mode === 'content' && content}
    
        {mode === 'modal' && <ModalButton icon='edit' color='primary' variant={'iconButton'} title={title} >
            {content}
        </ModalButton>}
    </>);
}

export default React.memo(UpdateCategoryForm);
