
import axios, { AxiosRequestConfig } from 'axios'
import { MutationResult, queryCache, useMutation, useQuery } from 'react-query'
import { MutationQueryObject } from '../../infrastructure/Interfaces'
import { useOperatorSupervisorTagFP } from '../persons/services/service.person'
import { useToken } from '../session/service.session'
import { SubCategoryInterface } from '../sub-categories/service.subcategories'
import { URL } from '../../infrastructure/appConfig/appConfig';


//------------------------------
//INTERFCAES--------------------
//------------------------------

export type CategoryAreaType = 'LUBRICACION'

export type CategoryType = 'category' | 'subcategory'

export interface CategoryInterface {
    id:number
    category:string
    tagFP:string
    categoryArea:CategoryAreaType
}

export interface CategoriesAndSubCategoriesInterface{
    id:number
    category:string
    tagFP:string
    categoryArea:CategoryAreaType
    subcategories?:SubCategoryInterface[]
}

//------------------------------
//AXIOS----------------------
//------------------------------


export const CategoriesAndSubcategoriesByTagFP = (_:string,tagFP:string,token:string) => {
    axios.defaults.headers.get['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.get['tagFP'] = tagFP

    const config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/CategoriesAndSubcategoriesByTagFP`,

    }

    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}
export const CreateCategory = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`

    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateCategory`,
        data
    }

    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}
export const UpdateCategory = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`

    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateCategory`,
        data
    }

    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}





//------------------------------
//QUERIES----------------------
//------------------------------


export const useCategoriesAndSubcategoriesByTagFP = (tagFP?:string) =>{
    const token  = useToken()
    const supOpTagFP = useOperatorSupervisorTagFP()
    const queryTagFP = tagFP || supOpTagFP
    return useQuery<CategoriesAndSubCategoriesInterface[]>(['CategoriesAndSubcategoriesByTagFP',queryTagFP,token],CategoriesAndSubcategoriesByTagFP,{
        enabled:!!queryTagFP && !!token,
        refetchOnWindowFocus:false,
        staleTime:900
    })
}




//------------------------------
//MUTATION----------------------
//------------------------------

export const useCreateCategory = ():[(item:CategoryInterface) => Promise<any>,MutationResult<any>] => {
    
    const token= useToken()
    const [mutation,query] = useMutation(CreateCategory,{
        onSuccess:()=>{
            queryCache.invalidateQueries('CategoriesAndSubcategoriesByTagFP')
        }
    })

    const createCategory = (data:CategoryInterface) => {
        return mutation({
            token,
            data
        })
    }



    return [createCategory,query]
}




export const useUpdateCategory = ():[(item:SubCategoryInterface) => Promise<any>,MutationResult<any>] => {
    
    const token = useToken()
    const [mutation,query] = useMutation(UpdateCategory,{
        onSuccess:()=>{
            queryCache.invalidateQueries('CategoriesAndSubcategoriesByTagFP')
        }
    })

    const updateCategory = (data:CategoryInterface) => {
        return mutation({
            token,
            data
        }) 
    }

    return [updateCategory,query]
}