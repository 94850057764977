import { useCallback } from "react"

export const useTGDsignature = ()=>{
    useCallback(()=> {
        console.log(`%c 
        ▒▒▄▀▀▀▀▀▄▒▒▒▒▒▄▄▄▄▄▄▄▒▒
        ▒▐░▄░░░▄░▌▒▒▄█▄DEV´S▄█▄▒
        ▒▐░▀▀░▀▀░▌▒▒▒▒░░░░░░░▒▒▒
        ▒▒▀▄░═░▄▀▒▒▒▒▒░░░░░░░▒▒▒
        ▒▒▐░▀▄▀░▌▒▒▒▒▒░░TGD░░▒▒▒     
        `, "font-family:monospace")
        
    },[])
}