import axios,{ AxiosRequestConfig } from'axios'
import { useMutation, useQuery,queryCache, QueryResult } from 'react-query'
import { LubricantInterface } from '../../lubricants/services/service.lubricants'
import { TypeInterface } from '../../types/services/service.types'
import { useToken } from '../../session/service.session'
import { SectorInterface } from '../../sectors/services/service.sectors'
import { RouteInterface } from '../../routes/services/service.routes'
import { GridModelInterface, MutationQueryObject } from '../../../infrastructure/Interfaces';
import { useMemo } from 'react'
import { URL } from '../../../infrastructure/appConfig/appConfig'
import { useEquipmentsPermissionsByTagFP } from '../../permisions/services/service.permission'
import { LeakLevelInterface } from '../../colector/domains/lubricationPoints/interfaces/leakLevelInterfaces'
import { useLeakLevel } from '../../colector/domains/lubricationPoints/services/leakLevel'
import { useStatusProcessor } from '../../errors/services/StatusProcessor'
import { InfoLubricationPointInterface } from '../../equipmentsInfo/service/service.info'
import { CriticalityInterface } from '../../criticalities/types/types'
import { PermissionInterface } from '../../permisions/types/permissionsTypes'
import { ComponentInterface } from '../../components/types/ComponentTypes'
import { ElementInterface } from '../../elements/types/ElementTypes'
import { SupplyInterface } from '../../supply/types/SupplyTypes'


//----------------------------
//Interfaces
//---------------------------------------------

export interface EquipmentInterface{
    tagTGD: string
    sector: string
    plantTag: string
    tagFP: string
    equipment: string
    type: string
    criticality:string
    lubricationPoints?:LubricationPointInterface[]
    elements?:[]
}





 export interface LubricationPointInterface{
    frontID:string
    tagTGD: string
    sector: string
    plantTag: string
    tagFP: string
    equipment: string
    type: string
    criticality:string

    state: boolean
    egressDate: Date
    lowReason: string

    admissionDate: string
    brand: string
    capacity: number
    component: string
    element: string
    function: string
    id: number
    labelId: number
    location: string
    lubricant: string
    lubricantType: string
    measureUnit: string
    model: string
    observations: string
    oilAnalysis: string | boolean
    review: string
    routes: string
    supplies: string[]

    //route addings to survey
    info?:InfoLubricationPointInterface
    routeName?:string
    leakLevel?:LeakLevelInterface
    permissions?:string[]
 }

 export const gridModelLubricationPoints:GridModelInterface = {
    /* 'renderOption':{label:'Acciones'}, */
    'admissionDate':{label:'Fecha de ingreso'},
    'brand':{label:'Marca'},
    'capacity':{label:'Capacidad',width:120},
    'equipment':{label:'Equipo',width:300},
    'element':{label:'Elemento',width:300},
    'component':{label:'Componente',width:300},
    'criticality':{label:'Criticidad del activo'},
    'egressDate':{label:'Fecha de baja'},
    'function':{label:'Función'},
    'location':{label:'Ubicación'},
    'lowReason':{label:'Razón de baja'},
    'lubricant':{label:'Lubricante'},
    'lubricantType':{label:'Tipo'},
    'measureUnit':{label:'Unidad'},
    'model':{label:'Modelo'},
    'observations':{label:'Observaciones'},
    'oilAnalysis':{label:'Análisis',options:[{name:'SI',value:true},{name:'NO',value:false},{name:'TODOS',value:undefined}]},
    'plantTag':{label:'Tag de planta'},
    'review':{label:'Revision',width:120},
    'routeName':{label:'Nombre de ruta',width:120},
    'routes':{label:'Rutas'},
    'permissions':{label:'Permisos'},
    'sector':{label:'Sector'},
    'state':{label:'Estado',options:[{name:'ACTIVO',value:true},{name:'INACTIVO',value:false},{name:'TODOS',value:undefined}]},
    'tagFP':{label:'Planta'},
    'tagTGD':{label:'Tag TGD'},
    'supplies':{label:'Insumos'},
    'type':{label:'Tipo'},
    'labelId':{label:'Id etiqueta',width:120},
    'id':{label:'Id',width:100},
    'info.assetCriticality':{label:'Criticidad en planta'},
    'info.id':{label:'Id info'},
    'info.inaccessible':{label:'Inaccesible',options:[{name:'SI',value:true},{name:'NO',value:false},{name:'TODOS',value:undefined}]},
    'info.labelReplacement':{label:'Cambio etiqueta',options:[{name:'SI',value:true},{name:'NO',value:false},{name:'TODOS',value:undefined}]},
    'info.lastInspectionDate':{label:'última inspección'},
    'info.lastLubrication':{label:'Ùltima lubricación'},
    'info.lastSurveyId':{label:'Ultimo relevamiento'},
    'info.leak':{label:'Perdida',options:[{name:'CON PERDIDA',value:true},{name:'SIN PERDIDA',value:false},{name:'TODOS',value:undefined}]},
    'info.lubricantQuantity':{label:'Cantidad de pérdida'},
    'info.pending':{label:'Pendiente',options:[{name:'PENDIENTE',value:true},{name:'REALIZADO',value:false},{name:'TODOS',value:undefined}]},
    'info.tagFP':{label:'Planta'},
    'info.tagTGD':{label:'Tag TGD'},
    'info.supplies':{label:'Insumos faltantes'},
    'info.temperature':{label:'Temperatura'},
    'info.updateReview':{label:'Nuevo componente',options:[{name:'SI',value:true},{name:'NO',value:false},{name:'TODOS',value:undefined}]},
    'info.updatedToday':{label:'Editado hoy',options:[{name:'SI',value:true},{name:'NO',value:false},{name:'TODOS',value:undefined}]},
    'info.user':{label:'Usuario'},
    'leakLevel.description':{label:'Color de perdida'},
    'leakLevel.leakQuantity':{label:'% de pérdida'},
    'leakLevel.max':{label:'Perdida máxima'},
    'leakLevel.min':{label:'Perdida mínima'},
    'leakLevel.neededQuantity':{label:'Cantidad necesaria por pérdida'},
}




//----------------------------
//repo
//---------------------------------------------

export const DaoEquipmentsByTagFP = (_:string,tagFP:string,token:string) => {
    axios.defaults.headers.get['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.get['tagFP'] = tagFP
    const config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/DaoEquipmentsByTagFP`
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const getAllLubricationPointsAndInfoByTagFP = (_:string,tagFP:string,token:string) => {

    interface info{
        id: number
        tagTGD: string
        supplies: string
        labelReplacement: boolean
        lastLubrication: string
        lubricantQuantity:number
        leak: boolean
        pending: boolean
        updatedToday: boolean
        user: string
        lastSurveyId: number
        assetCriticality: string
        inaccessible: boolean
        tagFP: string
        lastInspectionDate?: string
        updateReview: boolean
        temperature:number
    }

    interface LPI{
        frontID:string
        tagTGD: string
        sector: string
        plantTag: string
        tagFP: string
        equipment: string
        type: string
        criticality:string
    
        state: boolean
        egressDate: Date
        lowReason: string
    
        admissionDate: string
        brand: string
        capacity: number
        component: string
        element: string
        function: string
        id: number
        labelId: number
        location: string
        lubricant: string
        lubricantType: string
        measureUnit: string
        model: string
        observations: string
        oilAnalysis: string | boolean
        review: string
        routes: string
        supplies: string
        info:info
    }


    axios.defaults.headers.get['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.get['tagFP'] = tagFP
    const config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/AllLubricationPointsAndInfoByTagFP`
    }
    return axios(config).then((res)=>{
        //parse Text to object before using data
        return res.data?.map((d:LPI)=>{
            const eqSupplies = JSON.parse(d.supplies) 
            const infoSupplies = JSON.parse(d.supplies) 
            return{
                ...d,
                supplies:eqSupplies,
                info:{
                    ...d.info,
                    supplies:infoSupplies
            }}
        })
    }).catch((err) => {return err})
}

export const DaoEquipmentsElementsByTagFP = (_:string,tagFP:string,token:string) => {
    axios.defaults.headers.get['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.get['tagFP'] = tagFP
    const config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/DaoEquipmentsElementsByTagFP`
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const GetEquipmentsbyTagFP = (_:string,tagFP:string,token:string) => {

    axios.defaults.headers.get['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.get['tagFP'] = `${tagFP}`

    const config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/EquipmentsByTagFP`,
    }
    return axios.request<any>(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const EquipmentsByLubricantType = (_:string,type:TypeInterface,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/EquipmentsByLubricantType`,
        data:{
            tagFP:type.tagFP,
            name:type.name
        }
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}
export const EquipmentsByEquipmentType = (_:string,type:TypeInterface,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/EquipmentsByEquipmentType`,
        data:{
            tagFP:type.tagFP,
            name:type.name
        }
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}
export const EquipmentsByPermission = (_:string,permission:PermissionInterface,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/EquipmentsByPermission`,
        data:{
            tagFP:permission.tagFP,
            name:permission.permissionName
        }
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}
export const EquipmentsByMeasureUnit = (_:string,type:TypeInterface,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/EquipmentsByMeasureUnit`,
        data:{
            tagFP:type.tagFP,
            name:type.name
        }
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const EquipmentsByComponent = (_:string,component:ComponentInterface,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/EquipmentsByComponent`,
        data:{
            tagFP:component.tagFP,
            component:component.component,
        }
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const EquipmentsByElement = (_:string,element:ElementInterface,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/EquipmentsByElement`,
        data:{
            tagFP:element.tagFP,
            element:element.element,
        }
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const EquipmentsBySectorName= (_:string,sector:SectorInterface,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/EquipmentsBySectorName`,
        data:{
            tagFP:sector.tagFP,
            sectorName:sector.sectorName,
        }
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const EquipmentsByLubricant= (_:string,lubricant:LubricantInterface,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/EquipmentsByLubricant`,
        data:{
            type:lubricant.type,
            tagFP:lubricant.tagFP,
            lubricant:lubricant.lubricant,
        }
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const EquipmentsBySupply= (_:string,supply:SupplyInterface,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/EquipmentsBySupply`,
        data:{
            supply:supply.supply,
            tagFP:supply.tagFP,
        }
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const EquipmentsByCriticality= (_:string,criticality:CriticalityInterface,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/EquipmentsByCriticality`,
        data:{
            criticality:criticality.description,
            tagFP:criticality.tagFP,
        }
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}
export const EquipmentsByRoute= (_:string,route:RouteInterface,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/EquipmentsByRoute`,
        data:{
            route:route.routeName,
            tagFP:route.tagFP,
        }
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const EquipmentVerification = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/EquipmentAlgorithms`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const CreateEquipment = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateEquipment`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}
export const CreateLubricationPoint = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateLubricationPoint`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const UpdateEquipment = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateEquipmentNoImpact`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}
export const UpdateLubricationPoint = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateEquipmentsWithImpact`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}
export const UpdateHumanErrors = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateHumanErrors`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}
export const DeleteEquipment = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/DeleteEquipments`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const LubricationPointByTagTGD = (_:string,tagTGD:string,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/LubricationPointByTagTGD`,
        data:{tagTGD}
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}


export const UpdateEquipmentsState = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateEquipmentsState`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}







//----------------------------
//USE QUERY
//---------------------------------------------

export const useLubricationPointByTagTGD = (tagTGD?: string) => {
    const token = useToken()
    return useQuery(['LubricationPointByTagTGD',tagTGD,token],LubricationPointByTagTGD,{
        enabled:!!tagTGD && !!token,
    })
}

export const useDaoEquipmentsByTagFP = (tagFP: string) => {
    const token = useToken()
    const query = useQuery<LubricationPointInterface[]>(['DaoEquipmentsByTagFP',tagFP,token],DaoEquipmentsByTagFP,{
        enabled:!!tagFP && !!token,

    })

    const equipments = query.data?.map((item:any,index:number)=>({...item,id:index}))

    return {...query,data:equipments}

}

export const useDaoEquipmentsElementsByTagFP = (tagFP: string) => {
    const token = useToken()
    const query = useQuery<LubricationPointInterface[]>(['DaoEquipmentsElementsByTagFP',tagFP,token],DaoEquipmentsElementsByTagFP,{
        enabled:!!tagFP
    })    
    return {...query,data:query.data?.map((item:any,index:number)=>({...item,id:index}))}
}

export const useEquipmentsByTagFP = (tagFP:string ) => {

    const token = useToken()

    const queryEquipments = useQuery<LubricationPointInterface[]>(['EquipmentsByTagFP',tagFP,token],GetEquipmentsbyTagFP,{
        enabled:!!tagFP,        
    })

    const queryPermissions = useEquipmentsPermissionsByTagFP(tagFP)

    const result:QueryResult<LubricationPointInterface[]> = {
        ...queryEquipments,
        data:queryEquipments.data?.map((equipment)=>({
            ...equipment,
            permissions: queryPermissions.data?.filter((permission => permission.equipment === equipment.tagTGD)).map(i => i.permissionName) || ['SIN PERMISOS']
        }))
    }

    return result

}

export const useAllLubricationPointsAndInfoByTagFP = (tagFP:string ) => {
    const token = useToken()
    return useQuery<LubricationPointInterface[]>(['AllLubricationPointsAndInfoByTagFP',tagFP,token],getAllLubricationPointsAndInfoByTagFP,{
        enabled:!!tagFP,
        staleTime:500000
    })
}

export const useLubricationPointsWithLeakLevel = (lubricationPoints:  LubricationPointInterface[] | undefined) =>{
    const {calculateLeakLevel} = useLeakLevel()
     /* return Array.isArray(lubricationPoints) ?  lubricationPoints?.map((point)=>({
        ...point,
        leakLevel:calculateLeakLevel(point.info?.lubricantQuantity || 0,point.capacity)
    }))/*  : undefined */
    return useMemo(()=>{
        return lubricationPoints?.map((point)=>({
                ...point,
                leakLevel:calculateLeakLevel(point.info?.lubricantQuantity || 0,point.capacity)
            }))
    },[lubricationPoints, calculateLeakLevel])
}

export const useEquipmentsByLubricantType = (type:TypeInterface | {} | undefined ) => {
    const token = useToken()
    return useQuery(['EquipmentsByLubricantType',type,token],EquipmentsByLubricantType,{
        enabled:!!type,
        staleTime:Infinity,
        refetchOnWindowFocus:false
    })
}

export const useEquipmentsByPermission = (permission:PermissionInterface | {} | undefined ) => {
    const token = useToken()
    return useQuery(['EquipmentsByPermission',permission,token],EquipmentsByPermission,{
        enabled:!!permission,
        staleTime:Infinity,
        refetchOnWindowFocus:false

    })
}
export const useEquipmentsByEquipmentType = (type:TypeInterface | {} | undefined ) => {
    const token = useToken()
    return useQuery(['EquipmentsByEquipmentType',type,token],EquipmentsByEquipmentType,{
        enabled:!!type,
        staleTime:Infinity,
        refetchOnWindowFocus:false

    })
}
export const useEquipmentsByMeassureUnit = (type:TypeInterface | {} | undefined ) => {
    const token = useToken()
    return useQuery(['EquipmentsByMeasureUnit',type,token],EquipmentsByMeasureUnit,{
        enabled:!!type,
        staleTime:Infinity,
        refetchOnWindowFocus:false
    })
}

export const useEquipmentsByComponent = (component:ComponentInterface | {} | undefined) => {
    const token = useToken()
    return useQuery(['EquipmentsByComponent',component,token],EquipmentsByComponent,{
        enabled:!!component,
        staleTime:Infinity,
        refetchOnWindowFocus:false

    })
}

export const useEquipmentsByElement = (element:ElementInterface | {} | undefined) => {
    const token = useToken()
    return useQuery(['EquipmentsByElement',element,token],EquipmentsByElement,{
        enabled:!!element,
        staleTime:Infinity,
        refetchOnWindowFocus:false
    })
}

export const useEquipmentsBySectorName = (sector:SectorInterface  | {} | undefined ) => {
    const token = useToken()
    return useQuery(['EquipmentsBySectorName',sector,token],EquipmentsBySectorName,{
        enabled:!!sector,
        staleTime:Infinity,
        refetchOnWindowFocus:false
    })
}
export const useEquipmentsByLubricant = (lubricant:LubricantInterface | {} | undefined) => {
    const token = useToken()
    return useQuery(['EquipmentsByLubricant',lubricant,token],EquipmentsByLubricant,{
        enabled:!!lubricant,
        staleTime:Infinity,
        refetchOnWindowFocus:false
    })
}
export const useEquipmentsBySupply = (supply:SupplyInterface | {} | undefined) => {
    const token = useToken()
    return useQuery(['EquipmentsBySupply',supply,token],EquipmentsBySupply,{
        enabled:!!supply,
        staleTime:Infinity,
        refetchOnWindowFocus:false

    })
}
export const useEquipmentsByCriticality = (criticality:CriticalityInterface | {} | undefined) => {
    const token = useToken()
    return useQuery(['EquipmentsByCriticality',criticality,token],EquipmentsByCriticality,{
        enabled:!!criticality,
        staleTime:Infinity,
        refetchOnWindowFocus:false
    })
}

export const useEquipmentsByRoute = (route:RouteInterface | {} | undefined) => {
    const token = useToken()
    return useQuery(['EquipmentsByRoute',route,token],EquipmentsByRoute,{
        enabled:!!route,
        staleTime:Infinity,
        refetchOnWindowFocus:false
    })
}







//----------------------------
//USE Mutation
//---------------------------------------------

export const useUpdateEquipmentNextRevision = () => {

    const token = useToken()
    const [mutation,data] = useMutation(UpdateLubricationPoint,{
        onSuccess:()=>{
            queryCache.invalidateQueries('EquipmentsByTagFP')
            queryCache.invalidateQueries('DaoEquipmentsByTagFP')
            queryCache.invalidateQueries('DaoEquipmentsElementsByTagFP')
            queryCache.invalidateQueries('AllLubricationPointsAndInfoByTagFP')
        }
    }) 
    const status = useStatusProcessor(data)

    const updateRevision = (data:LubricationPointInterface) => {
        return mutation({
            token,
            data:{
                tagFP:data.tagFP,
                brand:data.brand,
                model:data.model,
                element:data.element,
                component:data.component,
                review:`${parseInt(data.review) + 1}`,
                observations: 'Cambio de revision automático',
                lubricantType:data.lubricantType,
                lubricant:data.lubricant,
                capacity:data.capacity,
                measureUnit:data.measureUnit,
                oilAnalysis:data.oilAnalysis,
                tagTGD:data.tagTGD
            }
        })
    }

    return {
        updateRevision,
        ...data,
        ...status
    }

}


export const useEquipmentVerification = () => {
    return useMutation(EquipmentVerification)
}

export const useCreateEquipment = () => {
    return useMutation(CreateEquipment,{
        onSuccess:()=> {
            queryCache.invalidateQueries('EquipmentsByTagFP')
            queryCache.invalidateQueries('DaoEquipmentsByTagFP')
            queryCache.invalidateQueries('DaoEquipmentsElementsByTagFP')
            queryCache.invalidateQueries('AllLubricationPointsAndInfoByTagFP')
        }
    })
}
export const useCreateLubricationPoint = () => {
    return useMutation(CreateLubricationPoint,{
        onSuccess:()=> {
            queryCache.invalidateQueries('EquipmentsByTagFP')
            queryCache.invalidateQueries('DaoEquipmentsByTagFP')
            queryCache.invalidateQueries('DaoEquipmentsElementsByTagFP')
            queryCache.invalidateQueries('AllLubricationPointsAndInfoByTagFP')
        }
    })
}

export const useUpdateEquipment = () => {
    return useMutation(UpdateEquipment,{
        onSuccess:()=> {
            queryCache.invalidateQueries('EquipmentsByTagFP')
            queryCache.invalidateQueries('DaoEquipmentsByTagFP')
            queryCache.invalidateQueries('DaoEquipmentsElementsByTagFP')
            queryCache.invalidateQueries('AllLubricationPointsAndInfoByTagFP')
        }
    })
}

export const useUpdateLubricationPoint = () => {
    return useMutation(UpdateLubricationPoint,{
        onSuccess:()=> {
            queryCache.invalidateQueries('EquipmentsByTagFP')
            queryCache.invalidateQueries('DaoEquipmentsByTagFP')
            queryCache.invalidateQueries('DaoEquipmentsElementsByTagFP')
            queryCache.invalidateQueries('AllLubricationPointsAndInfoByTagFP')
        }
    })
}
export const useUpdateHumanErrors = () => {
    return useMutation(UpdateHumanErrors,{
        onSuccess:()=> {
            queryCache.invalidateQueries('EquipmentsByTagFP')
            queryCache.invalidateQueries('DaoEquipmentsByTagFP')
            queryCache.invalidateQueries('DaoEquipmentsElementsByTagFP')
            queryCache.invalidateQueries('AllLubricationPointsAndInfoByTagFP')
        }
    })
}


export const useDeleteEquipment = () => {
    return useMutation(DeleteEquipment,{
        onSuccess:()=> {
            queryCache.invalidateQueries('EquipmentsByTagFP')
            queryCache.invalidateQueries('DaoEquipmentsByTagFP')
            queryCache.invalidateQueries('DaoEquipmentsElementsByTagFP')
            queryCache.invalidateQueries('AllLubricationPointsAndInfoByTagFP')
        }
    })
}
export const useUpdateLubricationPointState = () => {
    const token = useToken()
    const [mutation,queryData] = useMutation(UpdateEquipmentsState,{
        onSuccess:()=> {
            queryCache.invalidateQueries('EquipmentsByTagFP')
            .then(()=>queryCache.invalidateQueries('DaoEquipmentsByTagFP'))
            .then(()=>queryCache.invalidateQueries('DaoEquipmentsElementsByTagFP'))
            .then(()=>queryCache.invalidateQueries('AllLubricationPointsAndInfoByTagFP'))
        }
    })

    interface UpdateLubricationPointsInterface {
        tagFP?:string
        lowReason:string
        tagTGD:string
    }

    const activateLubricationPoint = ({lowReason,tagTGD}:UpdateLubricationPointsInterface) => {
        return mutation({
            token,
            data:{
                lowReason,
                tagTGD,
                state:true
            }
        })
    }

    const desactivateLubricationPoint = ({lowReason,tagTGD}:UpdateLubricationPointsInterface) => {
        return mutation({
            token,
            data:{
                lowReason,
                tagTGD,
                state:false
            }
        })
    }

    return{
        activateLubricationPoint,
        desactivateLubricationPoint,
        queryData
    }

}

export const lubricationPointVerificationArray = (points:any[],element:string,component:string) =>{
    
    var iterators:{[key:string]:string} = {}

    points.forEach((i:any)=>{
        iterators[i.element] = i.component
    })
    
    return (iterators[element] === component) ? 'Inválido' :undefined

}
 
export const useEquipmentsTag = (point?:any) => {

    const getTag = (item:any, type:'equipment'| 'element' | 'component') => {
        
        const tags = {
            equipment:`${item?.tagTGD.split('-')[0]}`,
            element:`${item?.tagTGD.split('-')[0]}-${item?.tagTGD.split('-')[1]}`,
            component:`${item?.tagTGD}`
        }
        return tags[type]
        
    }

    switch (!!point) {
        case true:
            return {
                getTag,
                equipmentTag:getTag(point,'equipment'),
                elementTag:getTag(point,'element'),
                componentTag:getTag(point,'component')
                }
        default:
            return {
                getTag
            }
    }
    
}


export const useProcesedEquipmentsByTagFPRefactor = (tagFP:string) => {

    const {data:lubricationPoints,status,error} = useEquipmentsByTagFP(tagFP)
    const {data:equipments} = useDaoEquipmentsByTagFP(tagFP)
    const {data:elements} = useDaoEquipmentsElementsByTagFP(tagFP)

	return{
        equipments,
        lubricationPoints,
        elements,
        status,
        error
    } 
}

