import React from 'react';
import { useUser } from '../../persons/services/service.person';
import { useAddedReasonsByPlant } from '../../auxiliar-data/service.aux';
import SimpleAutocompleteSelect from './../../../components/autocomplete/SimpleAutocompleteSelect';
import { useLangLabels } from './../../language/services/useSetLabel';
import CUAddedReasonForm from './../CUAddedReasonForm';
import { CrudFormType } from '../../../infrastructure/form/typescript/FormTypes';

interface props{
    tagFP:string
    onChange:any
    defaultValue?:string | undefined 
    dimentionType?:string
    color?:'primary'|'secondary'
    error?:string
}

const SelectAddedReason:React.FC<props> = ({tagFP, onChange, defaultValue,color,error}) => {

    const showTitle = 'reason'
    const {lang} = useLangLabels()
    const {data:addedReasons} = useAddedReasonsByPlant(tagFP)
    const {data:user} = useUser()
    const label = lang.addedReasons.singular
   
    return (<>
            <SimpleAutocompleteSelect
            {...{
                label,
                error,
                onChange: onChange,
                items:addedReasons || [],
                showTitle,
                defaultValue
            }}
            >
                {user && (user?.type === 'O' ? null : <CUAddedReasonForm tagFP={tagFP} actionType={CrudFormType.create}/>)}
            </SimpleAutocompleteSelect>

    </>);

}

export default SelectAddedReason;
