import React, { useState } from 'react';
import {  TextField } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useAllAuxByPlant } from '../auxiliar-data/service.aux';
import { EquipmentInterface, LubricationPointInterface, lubricationPointVerificationArray, useCreateEquipment } from './services/service.lubricationPoints';

import SelectSimpleEntity from '../../components/select/SelectSimpleEntity';
import { EquipmentFormRegex } from '../../infrastructure/regex/regexForms';
import SelectElement from '../elements/molecules/SelectElement';
import { PlantInterface } from '../plants/service.plant';
import SelectComponent from '../components/molecules/SelectComponent';
import SelectLubricant from '../lubricants/molecules/SelectLubricant';
import DefaultFormLayout from '../../components/forms/DefaultFormLayout';
import { QueryStatus } from 'react-query';
import SetPurchaseRequestStatus from '../purchase-requests/SetPurchaseRequestStatus';
import { ButtonVariant } from '../../components/buttons/CustomButton';
import SelectTypeBy from '../types/molecules/SelectTypeBy';
import { enumTgdTypes } from '../types/Types/types';

interface Props{
    plant:PlantInterface
    equipment:EquipmentInterface | null | undefined
    defaultState?:LubricationPointInterface
    handleLubricationPoint:any
    points:LubricationPointInterface[]
    variant?:ButtonVariant
}

const useCustomStatus = ()=> {

    const [status, setStatus] = useState<QueryStatus >(QueryStatus.Idle)
    const [error, setError] = useState<QueryStatus | boolean>(false)
    const [_message, setMessage] = useState<{error:any,success:any}>({
        error:undefined,
        success:undefined
    })

    const handleStatus = (status:'error' | 'success' | 'loading',message?:string) => {
        (status === 'error' ) &&
            setError(QueryStatus.Error);
                setStatus(QueryStatus.Error);
                setMessage({
                    ..._message,
                    error:message
                });
            
        (status === 'loading' ) &&
                setStatus(QueryStatus.Loading);

        (status === 'success' ) &&
                setStatus(QueryStatus.Success);
                setMessage({
                    ..._message,
                    error:message
                });
            
    }

    const messageExport = () => (status === QueryStatus.Success) ? _message.success : _message.error
    

    return {
        status,
        message:messageExport(),
        error,
        handleStatus
    }

}


const CreateEquipment:React.FC<Props> = ({plant,points,equipment,defaultState,handleLubricationPoint, variant}) => {
    const _tagFP = plant?.tagFP

    const {data:plantAux} = useAllAuxByPlant(equipment?.tagFP)
    const lubricants = plantAux?.lubricants

    const { register, handleSubmit, setValue, watch, control, errors,reset } = useForm({
        shouldUnregister: false
    });

    const  {handleStatus,status,message,error} = useCustomStatus()


    const onSubmit = (body:LubricationPointInterface) =>{
            handleStatus('loading')
            handleLubricationPoint([...points,{
                frontId: Date(), 
                tagTGD: equipment?.tagTGD,
                sector: equipment?.sector,
                equipment: equipment?.equipment,
                plantTag: equipment?.plantTag,
                tagFP: equipment?.tagFP,
                type: equipment?.type,
                criticality:equipment?.criticality,
                brand: body.brand,
                model:  body.model,
                capacity: body.capacity,
                component: body.component,
                element: body.element,
                function: !!body.function || 'Sin funcion definida',
                location: body.location,
                lubricant: body.lubricant,
                lubricantType: lubricants?.filter((l:any) => l.lubricant === body.lubricant)[0].type,
                measureUnit: body.measureUnit,
                observations: !!body.observations || 'Sin observación ',
                oilAnalysis: !!(body.oilAnalysis === 'SI'),
                review: 1
            }])
            handleStatus('success','Bien hecho')
            reset()
            
        };

  

   

    return (
            <DefaultFormLayout variant={variant || 'fab'} status={status} message={message} error={error} onChange={handleSubmit(onSubmit)} buttonTitle='Agregar nuevo componente'>              
               
                <TextField
                    inputRef={register({
                        required:{value:true,message:'requerida'},
                    })} 
                    name="brand" 
                    placeholder='Marca del componente' 
                    defaultValue={defaultState?.brand || ''}
                    variant='outlined'
                    label={`Marca del componente ${errors?.brand?.message || ''}`} 
                    color={errors?.brand?.message  ? 'primary' : 'secondary'}
                    size="small"
                    />

                <TextField
                    inputRef={register({
                        required:{value:true,message:'requerido'},
                    })} 
                    name="model" 
                    defaultValue={defaultState?.model || ''}
                    variant='outlined'
                    label={`Modelo del componente ${errors?.model?.message|| ''}` } 
                    color={errors?.model?.message ? 'primary' : 'secondary'}
                    size="small"
                    />

                <TextField
                    name="function" 
                    defaultValue={defaultState?.function || ''}
                    variant='outlined'
                    label={'Función del componente'} 
                    color={errors?.function?.message ? 'primary' : 'secondary'}
                    size="small"
                    />

                <TextField
                    inputRef={register()} 
                    name="location" 
                    defaultValue={defaultState?.location || ''}
                    variant='outlined'
                    label={'Ubicación del componente'} 
                    color={'secondary'}
                    size="small"
                    />

                <TextField
                    inputRef={register()} 
                    name="observations" 
                    defaultValue={defaultState?.location || ''}
                    variant='outlined'
                    label={'Observaciones'} 
                    color={'secondary'}
                    size="small"
                    />

               <Controller
                    as={<SelectElement
                        tagFP={_tagFP}
                        error={errors?.element?.message}
                        onChange={(value)=>setValue('element',value)}
                        defaultValue={watch('element')}
                        />}
                    name='element'
                    defaultValue={defaultState?.element || ''}
                    rules={{
                        required:{value:true, message:'requerido'} 
                    }}
                    control={control}
                />

               <Controller
                    as={<SelectComponent
                        tagFP={_tagFP}
                        error={errors?.component?.message}
                        onChange={(value:string)=> setValue('component',value)}
                        defaultValue={watch('component')}
                    />}
                    name='component'
                    defaultValue={defaultState?.component || ''}
                    rules={{
                        required:{value:true,message:'obligatorio'},
                        validate:{exist: () => lubricationPointVerificationArray(points,watch('element'),watch('component'))}
                    }}
                    control={control}
                />
                
               <Controller
                    as={<SelectLubricant
                        tagFP={_tagFP}
                        error={errors?.lubricant?.message}
                        onChange={(value:string)=>setValue('lubricant',value)}
                        defaultValue={watch('lubricant')}
                        />}
                    name='lubricant'
                    defaultValue={defaultState?.lubricant || ''}
                    rules={{required:{value:true,message:'Campo Obligatorio'}}}
                    control={control}
                />

                <TextField
                    inputRef={register({
                        required:{value:true,message:'Campo Obligatorio'},
                        pattern:{value:EquipmentFormRegex.float, message:'El número no es válido'},
                    })} 
                    name="capacity" 
                    placeholder='Capacidad de lubricante' 
                    defaultValue={defaultState?.capacity || ''}
                    variant='outlined'
                    label={errors?.capacity?.message || 'Capacidad de lubricante'} 
                    color={errors?.capacity?.message ? 'primary' : 'secondary'}
                    size="small"
                    />                

                <Controller
                    as={<SelectTypeBy
                        error={errors?.measureUnit?.message}
                        by={{key:'type',value:enumTgdTypes.unity}}
                        tagFP={_tagFP}
                        onChange={(value)=>setValue('measureUnit',value)}
                        defaultValue={watch('measureUnit')}
                        />}
                    name='measureUnit'
                    defaultValue={defaultState?.measureUnit || ''}
                    rules={{required:{value:true,message:'Campo Obligatorio'}}}
                    control={control}
                />

                <Controller
                    as={
                        <SelectSimpleEntity
                            error={errors?.oilAnalysis?.message}
                            label={'Análisis de aceite'} 
                            entityList={[{label:'SI',value:true},{label:'NO',value:false}]}
                            showTitle={'label'}
                            onChange={setValue}
                            defaultValue={watch('oilAnalysis')}
                        />
                    }
                    name='oilAnalysis'
                    defaultValue={defaultState?.oilAnalysis || ''}
                    rules={{required:{value:true,message:'obligatorio'}}}
                    control={control}
                />
                
            </DefaultFormLayout>

    );
}

export default CreateEquipment;
