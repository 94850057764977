import { useEffect, useState } from "react";
import { MutationResult, QueryResult, QueryStatus } from "react-query";
import { useLangLabels } from './../../language/services/useSetLabel';



export const useStatusProcessor = <TResult, TError>(queryResult:MutationResult<TResult, TError> | QueryResult<TResult, TError>):{
    reset: () => void;
    setQueryResult:React.Dispatch<React.SetStateAction<MutationResult<TResult, TError> | QueryResult<TResult, TError>>>
    status: QueryStatus;
    message: string | undefined;
    error: TError | null;
    dataStatus: TResult | null
} => {


    const {lang} = useLangLabels()
    const [_queryResult, setQueryResult] = useState(queryResult);
    const [status, setStatus] = useState<QueryStatus>(QueryStatus.Idle);
    const [message, setMessage] = useState<string | undefined>();

    useEffect(() => {
        /* if(queryResult.isIdle){
            setStatus(QueryStatus.Idle)
            setMessage(undefined)
        } */
        if(queryResult.isLoading){
            setStatus(QueryStatus.Loading)
            setMessage(undefined)
        }
        if(queryResult.isError){//error Message
            const serverResult:any = queryResult.data
            setStatus(QueryStatus.Error)
            setMessage(serverResult?.error || `Ha ocurrido un error: ${queryResult.error}`)        
        }
        if(queryResult.isSuccess){//success Message
            const serverResult:any = queryResult.data
            console.log(serverResult)
            console.log(!!serverResult.error)
            console.log(!!serverResult.success)

            
            if(typeof serverResult.error === 'string'){
                setStatus(QueryStatus.Error)
                setMessage(serverResult?.error)
                return
            }
            if(typeof serverResult.success === 'string'){
                setStatus(QueryStatus.Success)
                setMessage(serverResult?.success ) 
                return       
            }
            else{
                setStatus(QueryStatus.Success)
                setMessage(`Todo ha salido bien`)
                return
            }
        }
    },[
        queryResult.data, 
        queryResult.error, 
        queryResult.isError, 
        queryResult.isIdle, 
        queryResult.isLoading, 
        queryResult.isSuccess
    ])    


    const reset = () => {
        setStatus(QueryStatus.Idle)
        setMessage(undefined)
    }
    
    return {
        reset,
        setQueryResult,
        status,
        message,
        error:queryResult.error,
        dataStatus:queryResult.data || null
    }
}