import React, { useEffect } from 'react';
//components
import PageContainer from '../../components/pageContainers/PageContainer'
//Router
import { Route,Switch } from 'react-router-dom';
import { USER_ROUTES } from '../../infrastructure/routes/routes';
import ConfigPage from '../ConfigPage';
import ReportsPage from '../ReportsPage';
import StockPageManagment from '../StockPageManagment';
import PlantManagementPage from '../PlantManagementPage';
import { useOperatorSupervisorTagFP, usePlants } from '../../domains/persons/services/service.person';
import { useAllAuxByPlant } from '../../domains/auxiliar-data/service.aux';
import FlexContainer from '../../components/presentational/FlexContainer';
import { Grid, Paper, Typography, Divider, CircularProgress, List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction } from '@material-ui/core';
import TgdPopover from '../../components/popovers/TgdPopover';
import { useRoutesByDate, useRoutesByTagFPAndState } from '../../domains/routes/services/service.routes';
import StatusAndMessage from '../../domains/errors/StatusAndMessage';
import { QueryStatus } from 'react-query';
import { CustomIcon } from './../../components/buttons/CustomButton';
import ReScheduleRoute from '../../domains/routes/components/ReScheduleRoute';
import CenteredPageContainer from './../../components/pageContainers/CenteredPageContainer';
import NewsPage from '../NewsPage';
import RealtimeSensoringPage from '../../domains/sensoring/pages/RealtimeSensoringPage';
import VersionContainer from '../../components/pageContainers/VersionContainer';
import SensorsManagementPage from '../../domains/sensoring/pages/SensorsManagementPage';
import { useUserCompanies } from '../../domains/cluster-companies/services/clusterEnterpricesService';
import PieChart from '../../infrastructure/chart.js/charts/PieChart';
import { usePlantOperators } from './../../domains/persons/services/service.person';
import TgdRouterTabs from './../../components/Tabs/TgdRouterTabs';
import { useDate } from '../../infrastructure/date/useDate';
import SupervisorColectorPage from './../../domains/colector/pages/SupervisorColectorPage';
import FeatureStatusContainer, { useFeatures } from '../../infrastructure/appConfig/featureManagement/FeatureStatusContainer';
import ViewPlantAsClientPage from '../ViewPlantAsClientPage';


const RoutesPieChart:React.FC<{routes:any[]}> = ({routes}) => {

    const todayRoutes = routes.filter(route=>!route.state).map((item,index)=>index).length || 1
    const delayedRoutes = routes.filter(route=>route.state === 'ATRASADA').map((item,index)=>index).length || 1
    const pendingRoutes = routes.filter(route=>route.state === 'INCUMPLIDA' || route.state === 'PROGRAMADA INCUMPLIDA').map((item,index)=>index).length || 1

    const dataset = [{
        label:'Routes',
        data:[ todayRoutes, delayedRoutes, pendingRoutes ],
        backgroundColor:['green','gray','red']
    }]

    return(
        
        <FlexContainer maxWidth='30vh' align='center' margin='auto auto' padding='24px 8px 8px 8px' justify='center'>
            <PieChart
                key={routes.length}
                chartValues={dataset}
                labels={['HOY', 'ATRASADAS', 'PENDIENTES',]}
                title='Resumen de rutas'
            />
        </FlexContainer>
    )
}

const CardTitle:React.FC<{title:string,popoverTitle?:string,status?:QueryStatus,popoverChild?:React.ReactNode}> = ({popoverChild, title,popoverTitle, status}) => {

    return(
        <Typography variant='h6'>
            <FlexContainer justify='space-between' padding={'8px 24px 0px 24px'}>
                {title} 
                <div>
                    <TgdPopover mode='hover' title={popoverTitle || ''} hoverChildren={popoverChild}>
                        {(status && status === 'loading')
                        ? <CircularProgress size='24px' />
                        :<CustomIcon icon='info'/>}
                    </TgdPopover>
                </div>
            </FlexContainer>
            <Divider />
        </Typography>
    )
}

const HomeCard:React.FC<{children:React.ReactNode}> = ({children}) => {
    return(
    <Grid item md={3} lg={3} sm={6} xs={1}>
        <Paper style={{height:'35vh',overflowY:'scroll'}}>
            {children && children}
        </Paper>
    </Grid>
    )
}



const SupervisorContent:React.FC<{dimention:string}> = ({dimention}) => {

    
    const {data:plant} = usePlants()

    const features = useFeatures()
    const tagFP = useOperatorSupervisorTagFP()

    /* operator functions as an operator */
    const {data:operators} = usePlantOperators(tagFP)
    const defaultOperator = operators &&( operators.length > 0 ? operators[0] : undefined)
    const {data:companies} = useUserCompanies()
    const stiService = companies?.filter((item)=>item.company === 'STI')[0]
    useAllAuxByPlant(plant[0])
    

    const [getRoutes,{data:routes,status:dayRoutesStatus,error}] = useRoutesByDate()
/*     const {data:delayedRoutes} = useLastDelayedRoutes()
 */    const {data:routesbytagFPAndstate} = useRoutesByTagFPAndState()

    const {nowDate} = useDate()

    useEffect(()=>{
        getRoutes(nowDate())
    },[])

    return (
        <>
        
        <PageContainer height='100%'>
            <Switch>  
                
                <Route exact path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.realtimeSensors}`}>
                    <RealtimeSensoringPage tagFP={tagFP}/>
                    <VersionContainer company = 'STI'/>
                </Route>

                <Route exact path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.sensoringManagement}`}>
                    <SensorsManagementPage tagFP={tagFP}/>
                    <VersionContainer company = 'STI'/>
                </Route>

                <Route exact path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.news}`}>
                    <NewsPage tagFP={tagFP}/>
                </Route>
      
                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.config}`}>
                    <ConfigPage/>
                </Route>

                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.dailyNews}`}>
                   {tagFP && <NewsPage tagFP={tagFP}/>}
                </Route>

                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.reports}`}>
                    <ReportsPage/>
                </Route> 

                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.stock}`}>
                    <StockPageManagment defaultPath={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.stock}`} tagFP={tagFP} />
                </Route> 

                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.clientVisor}`}>
                    <ViewPlantAsClientPage tagFP={tagFP} />
                </Route> 

                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.plantManagement}`}>
                    <PlantManagementPage plant={plant[0]} />
                </Route> 
                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.clientVisor}`}>
                    <FeatureStatusContainer feature={features.supervisorClientVisor} >
                        Dashboard cliente
                    </FeatureStatusContainer>
                </Route> 

                <Route path={`/${USER_ROUTES.home}/${dimention}`}> 
                    {!stiService ? <>   
                        <Grid container spacing={2}>
                            <HomeCard>
                                <RoutesPieChart routes={[
                                    ...routes || [],
                                    ...routesbytagFPAndstate || []
                                ]} />

                            </HomeCard>

                            <HomeCard>
                                    <StatusAndMessage status={dayRoutesStatus} message='' error={error} />
                                    <CardTitle status={dayRoutesStatus} title='Rutas de hoy' popoverTitle='Rutasque tocan hoy'/>
                                    <List>
                                        {routes?.map((route,index)=>
                                            <ListItem key={index}>
                                                <ListItemIcon>
                                                    <CustomIcon icon='flag' />
                                                </ListItemIcon>
                                                <ListItemText>{route.routeName}</ListItemText>
                                                <ListItemSecondaryAction>
                                                {/*  <ModalButton fullwidth={true} variant='iconButton' icon='more'>
                                                    </ModalButton> */}
                                                </ListItemSecondaryAction>
                                                {/* <Divider/> */}
                                            </ListItem>
                                        )}
                                    </List>
                            </HomeCard>

                            <HomeCard>
                                <CardTitle 
                                title='Rutas atrasadas' 
                                popoverChild={
                                    <Typography 
                                    variant='body2' 
                                    style={{maxWidth:'250px'}}>
                                        Rutas que no hubo posibilidad de hacer, y directamente no se pudieron asignar como pendientes del dia, causalidades: Parada de planta, feriados, etc...
                                    </Typography>
                                } />
                                {!routesbytagFPAndstate && <CenteredPageContainer><CircularProgress size= '24px' /></CenteredPageContainer> }
                                <List>
                                        {routesbytagFPAndstate?.filter((route)=>route.state === 'ATRASADA').map((route,index)=>
                                            <ListItem key={index}>
                                                <ListItemIcon>
                                                    <ReScheduleRoute item={route} />
                                                </ListItemIcon>
                                                <ListItemText>{route.routeName}</ListItemText>
                                                <ListItemIcon>
                                                    {/* <ModalButton fullwidth={true} variant='iconButton' icon='more'>
                                                    </ModalButton> */}
                                                </ListItemIcon>
                                                {/* <Divider/> */}
                                            </ListItem>
                                        )}
                                </List>
                            </HomeCard>

                            <HomeCard>
                                <CardTitle 
                                title='Rutas pendientes' 
                                
                                popoverChild={
                                    <Typography 
                                    variant='body2' 
                                    style={{maxWidth:'250px'}}>
                                        Rutas que fueron asignadas a un operador como pendientes, y no fueron comenzadas el día que tocaban en ruta
                                    </Typography>
                                } />
                                
                                <List>
                                    {!routesbytagFPAndstate && <CenteredPageContainer><CircularProgress size= '24px' /></CenteredPageContainer> }
                                    {routesbytagFPAndstate?.filter((route)=>((route.state === 'INCUMPLIDA') || (route.state === 'PROGRAMADA INCUMPLIDA') )).map((route)=>
                                        <ListItem key={route.routeName} >
                                            <ListItemIcon>
                                                <ReScheduleRoute item={route} />
                                            </ListItemIcon>
                                            <ListItemText>{route.routeName}</ListItemText>
                                            <ListItemIcon>
                                                {/* <ModalButton fullwidth={true} variant='iconButton' icon='more'>
                                                </ModalButton> */}
                                            </ListItemIcon>
                                            {/* <Divider/> */}
                                        </ListItem>
                                    )}
                                </List>
                            </HomeCard>
                        </Grid>
                        <br />
                        <TgdRouterTabs
                            exact={false}
                            defaultPath={`/${USER_ROUTES.home}/${dimention}/`}
                            content={({label,component})=> <>
                                {component}
                            </>}
                            items={[
                                {label:'Reportes',component:<ReportsPage/>},
                                ...operators ? operators.map((operator)=>({
                                    label:operator.name,
                                    component:<FeatureStatusContainer feature={features.supervisorSurvey}>
                                        <SupervisorColectorPage operator={operator} tagFP={tagFP} />,
                                    </FeatureStatusContainer>,
                                    operator:operator
                                })) : [],
                            ]}
                        />
                    </> : <RealtimeSensoringPage tagFP={tagFP}/> }
                </Route> 
            </Switch>
        </PageContainer>
        </>
    );

}

export default React.memo(SupervisorContent);
