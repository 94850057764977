import React, { useState } from 'react';

//components
import HomeHero from './HomeHero';
import HomeContent from './HomeContent';
import HomeHeroImg from '../../assets/img/welcomeHeader.png'

//services


const Home = () => {

    return (
        <>  
            <HomeHero img={HomeHeroImg}/> 
            <HomeContent/>
        </>
    )
}

export default Home;
