import React, { useState } from 'react';
import { Divider, Paper, Typography, ListItemText, TextField, ListItemAvatar, ListItem } from '@material-ui/core';
import NotificationImportantOutlinedIcon from '@material-ui/icons/NotificationImportantOutlined';
import PageContainer from './../components/pageContainers/PageContainer';
import FlexContainer from './../components/presentational/FlexContainer';
import { GridModelNews, NewInterface, useNewsByTagFP } from '../domains/news/services/service.news';
import AddButton from '../components/buttons/AddButton';
import TgdModal from '../components/modals/TgdModal';
import CreateNewForm from '../domains/news/CreateNewForm';
import { useCriticalitiesByPlant } from '../domains/auxiliar-data/service.aux';
import { capitalize } from '../utils/utils';
import theme from '../infrastructure/materialUi/themeConfig';
import { CustomIcon } from './../components/buttons/CustomButton';
import TgdTabs from '../components/Tabs/TgdTabs';
import { useUser } from '../domains/persons/services/service.person';
import { useDate } from '../infrastructure/date/useDate';
import AnyGrid, { CarrousellActionInterface } from '../components/grids/AnyGrid';
import { GridCellParams } from '@material-ui/data-grid';
import { objectMapper } from './../utils/objectMapper';
import { useModal } from './../utils/hooks/useModal';
import ComponentMode from '../components/ComponentMode';
import CriticalityIndicator from '../domains/criticalities/components/CriticalityIndicator';
import { useCriticalityByTypeByTagFP } from './../domains/criticalities/services/service.criticality';
import { CriticalityTypes } from '../domains/criticalities/types/types';
import { CriticalityInterface } from './../domains/criticalities/types/types';
import { useLangLabels } from './../domains/language/services/useSetLabel';


const PlantFocusTitle:React.FC<{tagFP:string,color?:string}> = ({tagFP,color}) => {
    
    const defaultColor = theme.palette.primary.main || color
    const factoryName = capitalize(tagFP.split('|')[0])
    const plantName =  capitalize(tagFP.split('|')[1])
    
    return(
        <span style={{color:defaultColor}}>
            {factoryName} {plantName}
        </span>
    )
}


const NewView:React.FC<{item:NewInterface}> = ({item}) => {

    const {lang} = useLangLabels()
    const {data:criticalities} = useCriticalityByTypeByTagFP(item.tagFP,CriticalityTypes.observation)
    const criticality = criticalities?.find((c:CriticalityInterface)=>c.description === item.criticality) 

    return(
        <>
            <ListItem>
                <ListItemAvatar>
                    <CustomIcon icon='date' />
                </ListItemAvatar>
                <ListItemText 
                    primary={item.type}
                    secondary={item.newsDate.slice(0,10)}
                />
                <ListItemText 
                    secondary={`${lang.globalentityValues.id}: ${item.id}`}
                />
                {criticality && <CriticalityIndicator criticality={criticality} />}
                
            </ListItem>
            <Divider/>
            <ListItem>
                <FlexContainer padding='24px 0px'>
                    <Typography variant={'body1'}>
                        <strong>
                            {item.description}
                        </strong>
                    </Typography>
                </FlexContainer>
            </ListItem>
            <Divider/>
            {   
                item?.equipment &&
                <ListItem>
                    <ListItemText 
                        primary={item.equipment}
                        secondary={lang.news.equipment}
                    />
                </ListItem>
            }
            <Divider/>
            <FlexContainer>
                <ListItem>
                    <ListItemText 
                        primary={item.area}
                        secondary={lang.areas.title.singular}
                        />
                </ListItem>
                <ListItem>
                    <ListItemText 
                        primary={item.sector}
                        secondary={lang.sectors.title.singular}
                        />
                </ListItem>
            </FlexContainer>
        </>
    )
}

const carrouselActions:CarrousellActionInterface[] =[
    {icon:'view',component:(item:NewInterface)=><NewView item={item} />}
] 

const NewList:React.FC<{news?:NewInterface[],tagFP:string}> = ({news,tagFP}) => {

    const { data:criticalities } = useCriticalitiesByPlant(tagFP)
    const criticalitiesMap = objectMapper(criticalities,'description')

    const criticalityField = (item:GridCellParams)=>{
        const criticalityDescription:string | undefined = item.getValue('criticality') as string
        const color = `${criticalitiesMap && criticalityDescription ? criticalitiesMap[criticalityDescription]?.color: 'unset' }`
        return (
            <CustomIcon 
            icon='state' 
            style={{ color }} 
            />
        )
    }

    return (
        <AnyGrid
        loading={!news}
        renderOptions={criticalityField}
        gridModel={GridModelNews}
        items={news || []}
        carrousellActions={carrouselActions}
        title=''
        />
    )
}

const NewsPage:React.FC<{tagFP:string}> = ({tagFP}) => {

    const {data:user} = useUser()

    const { nowYear, nowDay, nowMonth } = useDate()

    const defaultDate = `${nowYear}-${nowMonth}-${nowDay}`
    const [date, setDate] = useState<undefined | string>(defaultDate);

    const {data:news} = useNewsByTagFP( tagFP, date )
    const {modal,handleModal} = useModal()

    const plantNews = news?.filter((n)=>n.type === 'PLANTA')
    const placeNews = news?.filter((n)=>n.type === 'ENTORNO')

    const tabs = [
        {name:'Entorno',news:placeNews,onChange:()=>setDate(defaultDate)},
        {name:'Planta',news:plantNews,onChange:()=>setDate(defaultDate)},
        {name:'Todas',news:news, onChange:()=>setDate(undefined)}
    ]

    return (<>
        <Paper>
            <ListItemText>
                <FlexContainer align='center' padding={'24px'}>
                    <FlexContainer width='100%' align='center' justify='space-between'>
                        <FlexContainer>   
                            <NotificationImportantOutlinedIcon /> 
                            <Typography variant='h6' >Novedades de planta </Typography>
                        </FlexContainer>
                        <FlexContainer flexWrap='wrap'>
                            <TextField
                                size='small'
                                variant='outlined'
                                type='date'
                                onChange={(e:any)=>setDate(e.target.value)}
                                label=''
                                color='secondary'
                                value={date}
                            />
                            <AddButton action={handleModal} title='novedad'/>
                        </FlexContainer>
                    </FlexContainer>
                </FlexContainer>
            </ListItemText>
            <br/>
            <Divider/>

            <TgdTabs 
                items={tabs}
                content={(value)=>{
                    return <NewList key={value.news + date} news={value.news} tagFP={tagFP} />
                }}
                labelIterator='name' 
            />
            
        </Paper>
    
        <TgdModal 
        fullWidth={true} 
        open={modal} 
        handleClose={handleModal} 
        header={
            <PageContainer>
                <Typography variant='h6'>Crear Novedad de Planta</Typography>
            </PageContainer>}>
            <CreateNewForm tagFP={tagFP}/>
        </TgdModal>
    
    </>);
}

export default React.memo(NewsPage);
