import React, { memo } from 'react';
//components
//Router



import { useOperatorSupervisorTagFP,  } from '../../domains/persons/services/service.person';

import HomePageR from '../../domains/colector/pages/HomePageR';
import OutOfRouteSurvey from '../../domains/colector/domains/surveys/OutOfRouteSurvey';
import { Route, Switch } from 'react-router-dom';
import ConfigPage from '../ConfigPage';
import NewsPage from '../NewsPage';
import { USER_ROUTES } from '../../infrastructure/routes/routes';
import PageContainer from '../../components/pageContainers/PageContainer';


const OperatorContent:React.FC<{dimention:string}> = ({dimention}) => { 

         const tagFP = useOperatorSupervisorTagFP()
    
    return (
        <PageContainer>
            <Switch>
                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.config}`}>
                    <ConfigPage/>
                </Route>
                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.dailyNews}`}>
                    {tagFP && <NewsPage tagFP={tagFP}/>}
                </Route>
                <Route path={`/${USER_ROUTES.home}/${dimention}/`}>
                    <HomePageR/>
                </Route>
            </Switch> 
            <OutOfRouteSurvey />         
        </PageContainer>
  
        );
}

export default memo(OperatorContent);
