import React from 'react'
import SelectSimpleEntity from '../../../components/select/SelectSimpleEntity';
import { useAllAuxByPlant } from '../../auxiliar-data/service.aux';
import { useUser } from '../../persons/services/service.person';
import { PlantInterface } from '../../plants/service.plant';
import CreateAnomalyForm from '../CreateAnomalyForm';
import { useLangLabels } from './../../language/services/useSetLabel';
import SimpleAutocompleteSelect from './../../../components/autocomplete/SimpleAutocompleteSelect';
import CUAnomalyForm from '../CUAnomalyForm';
import { CrudFormType } from '../../../infrastructure/form/typescript/FormTypes';

interface Props{
    onChange:(value:string)=>void
    tagFP:string
    defaultValue?:string|undefined
    error?:string
    color?:'primary'|'secondary'
}

const SelectAnomaly:React.FC<Props> = ({tagFP, onChange, defaultValue, color,error}) => {

    const showTitle = 'anomaly'
    const {lang} = useLangLabels()
    const label = lang.anomalies.singular
    const {data:plantAux} = useAllAuxByPlant(tagFP)
    const anomalies = plantAux && plantAux.anomalies
    const {data:user} = useUser()
    
    return (
        <SimpleAutocompleteSelect
                {...{
                error,
                label,
                onChange,
                items:anomalies || [],
                showTitle,
                defaultValue
                }}
            >
            {user && (user?.type === 'O' ? null : <CUAnomalyForm tagFP={tagFP} actionType={CrudFormType.create} />)}
        </SimpleAutocompleteSelect>
    );
}

export default SelectAnomaly;
