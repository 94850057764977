import axios, { AxiosRequestConfig } from "axios"
import { QueryResult, useQuery } from "react-query"
import { URL } from "../../../../../infrastructure/appConfig/appConfig"
import { LubricationPointInterface } from "../../../../equipments/services/service.lubricationPoints"
import { useOperatorSupervisorTagFP } from "../../../../persons/services/service.person"
import { useDefaultQuery } from "../../../../../infrastructure/reactQuery.tsx/useDefaultQuery"
import { useToken } from "../../../../session/service.session"
import { useDate } from "../../../../../infrastructure/date/useDate"



const getLubricationPointsAndInfo = (_:string,data:any,token:string) => {

    interface info{
        id: number
        tagTGD: string
        supplies: string
        labelReplacement: boolean
        lastLubrication: string
        lubricantQuantity:number
        leak: boolean
        pending: boolean
        updatedToday: boolean
        user: string
        lastSurveyId: number
        assetCriticality: string
        inaccessible: boolean
        tagFP: string
        lastInspectionDate?: string
        updateReview: boolean
        temperature:number
    }

    interface LubricationPointInterface{
        frontID:string
        tagTGD: string
        sector: string
        plantTag: string
        tagFP: string
        equipment: string
        type: string
        criticality:string
    
        state: boolean
        egressDate: Date
        lowReason: string
    
        admissionDate: string
        brand: string
        capacity: number
        component: string
        element: string
        function: string
        id: number
        labelId: number
        location: string
        lubricant: string
        lubricantType: string
        measureUnit: string
        model: string
        observations: string
        oilAnalysis: string | boolean
        review: string
        routes: string
        supplies: string
        info:info
    }

    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`

    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/EquipmentsAndInfo`,
        data
    }
    return axios(config).then((res)=>{
        const eqSup =res.data.supplies ? JSON.parse(res.data.supplies) : []
        const infoSup = res.data.info?.supplies ? JSON.parse(res.data.info.supplies):[]
        return res.data?.map((d:LubricationPointInterface)=>({
            ...d,
            supplies:eqSup,
            info:{
                ...d.info,
                supplies:infoSup
        }}))
    })
    .catch((err) => {return err})
}

const getDayPendingLubricationPoints = (_:string,data:any,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`

    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/DayPending`,
        data
    }
    return axios(config).then((res)=>{
        return res.data
    })
    .catch((err) => {return err})
}


/* ------------------------
query
--------------------------- */

export const useDayPendingPointsByDate = ():QueryResult<any> => {
    const token = useToken()
    const {nowDay,nowMonth,nowYear} = useDate()
    const tagFP = useOperatorSupervisorTagFP()
    const data = {
        date:`${nowYear}-${nowMonth}-${nowDay}`,
        tagFP
    }
    return useDefaultQuery(['dayPendingLubricationPoints',data,token],getDayPendingLubricationPoints,{
        enabled:!!nowDay && !!tagFP,
        refetchOnWindowFocus:false
    })
}


export const useGetPointsDataWithInfo = (pointsbyTagTgdAndRoute:{tagTGD:string,routes?:string}[] | undefined) => {
    const token = useToken()
    return useQuery<LubricationPointInterface[]>(['DailyOperatorLubricationPoints',pointsbyTagTgdAndRoute,token],getLubricationPointsAndInfo,{
        enabled:!!pointsbyTagTgdAndRoute,
        refetchOnWindowFocus:false,
        staleTime:120000
    })
}



