import React from 'react';
import DefaultFormLayout from '../../../components/forms/DefaultFormLayout';
import FormSelector from '../../form-config/FormSelector';
import { STISensorsInterface } from './services/STIsensorsInterfaces';
import { useSTISensorForm } from './services/useSTISensorForm';
import { useCreateSTISensor } from './services/STIsensorsService';
import { ConfigFieldsInterface } from '../../form-config/interfaces';




const CreateSTISensorForm:React.FC<{tagFP:string}> = ({tagFP}) => {

    const {setValue,handleSubmit,watch,errors,form,reset} = useSTISensorForm({actionType:'create'},tagFP)
    const {createSTISensor,data} = useCreateSTISensor()
    
    const submit = (fields:STISensorsInterface) => {
        createSTISensor(fields).then(()=>reset())
    }

    const onChange = (field:ConfigFieldsInterface<STISensorsInterface>,value:any) => {
        if(field.name === 'sensorType'){
            setValue(field.name,value?.sensorType)
            setValue('sensorTypeCode',value?.sensorTypeCode)
        }
        else setValue(field.name,value)
    }

    return (
        <DefaultFormLayout 
        status={data.status}
        message={data.message}
        error={data.error} 
        icon={'add'} 
        buttonTitle={form.name} 
        popoverTitle={form.name} 
        onChange={handleSubmit(submit)} >
            {form.fields?.map(field=>
                <FormSelector
                    key={field.name}
                    selector={field.formSelector}
                    fieldProps={{
                        type:field.type,
                        error:errors && errors[field.name]?.message,
                        label:field.label,
                        value:watch(field.name),
                        defaultValue:watch(field.name),
                        onChange:(value)=>onChange(field,value),
                        tagFP
                    }}
                />    
            )}
        </DefaultFormLayout>
    );
}

export default React.memo(CreateSTISensorForm);
