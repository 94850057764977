
import axios ,{ AxiosRequestConfig } from 'axios';
import { useQuery, QueryResult, MutationResult, useMutation } from 'react-query';
import { URL } from '../../../../../infrastructure/appConfig/appConfig';
import { MutationQueryObject } from '../../../../../infrastructure/Interfaces';
import { useOperatorLubricationNumber, useOperatorSupervisorTagFP, useUser } from '../../../../persons/services/service.person';
import { RouteInterface } from '../../../../routes/services/service.routes';
import {  } from '../../../../../infrastructure/tgd.config';
import { useToken } from '../../../../session/service.session';
import { useDailyLubricationPointsWithInfo } from '../../lubricationPoints/services/daoLubricationPoints';
import { getOperatorsByPlant } from './../../../../../infrastructure/axios/axiosTgd';
import { useOperatorPlants } from '../../../../plants/service.plant';
import { usePlantOperators } from './../../../../persons/services/service.person';
import { PersonInterface } from '../../../../persons/interfaces/userInterfaces';
import { useDate } from '../../../../../infrastructure/date/useDate';
import { useSupervisorSelectedLubricatorData } from '../../../pages/SupervisorColectorPage';



 const getOperatorDailyRoutes = (_:string,lubricatorNumber:string,tagFP:string,token:string) => {
    axios.defaults.headers.get['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.get['tagFP'] = tagFP
    const config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/TodayScheduledRoutesByOperator/${lubricatorNumber}`,
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}


 const setStartedRoute = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/StartingRoute`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

 const gDalayedRoutesByOperator = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/LastScheduledRoutesByOperator`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

 const RoutesInfo = (_:string,date:string,tagFP:string,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/DayDelayedRoutes`,
        data:{
            tagFP,
            date
        }
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}




/*----------------
query
----------------*/


export const useDailyRoutesByLubricatorNumber = ():QueryResult<RouteInterface[]> => {

    const token = useToken()
    const {data:operator} = useSupervisorSelectedLubricatorData()
    const tagFP = useOperatorSupervisorTagFP()

    const autoLubricatorNumber =  useOperatorLubricationNumber()
    const _lubricatorNumber = operator?.lubricatorNumber || autoLubricatorNumber


    return useQuery([
        'OperatorDailyRoutes',
        _lubricatorNumber,
        tagFP,
        token
    ],getOperatorDailyRoutes,{
        enabled:!!_lubricatorNumber && !!tagFP && token,
        refetchOnWindowFocus:false,
        staleTime:5000
    })
    
}

export const useRoutesInfo = (date?:string,tagFP?:string):QueryResult<RouteInterface[]> => {
     //ROUTE STATE ADDING
     const token = useToken()
     const {data:operator} = useSupervisorSelectedLubricatorData()
     const oplubricatorNumber = useOperatorLubricationNumber()
     const lubricatorNumber = operator?.lubricatorNumber || oplubricatorNumber

     const supOptagFP = useOperatorSupervisorTagFP()
     const {nowDay,nowMonth,nowYear} = useDate()

     const QueryDate = date || `${nowYear}-${nowMonth}-${nowDay}`
     const QueryTagFP = tagFP || supOptagFP
     return useQuery<RouteInterface[]>(['routesInfo',QueryDate,QueryTagFP,token], RoutesInfo,{
        enabled:!!lubricatorNumber && !!QueryTagFP,
        refetchOnWindowFocus:false,
       /*  staleTime:5000 */
     })
}

export const useDailyRoutes = () => {
    const dailyOperatorLubricationPoints = useDailyLubricationPointsWithInfo()
    const query = useDailyRoutesByLubricatorNumber()
    const {data:routesInfo} = useRoutesInfo()
   
    return useQuery('DailyRoutes',()=>{
        return query.data?.map((route) =>{
           

            const routePoints = dailyOperatorLubricationPoints ? dailyOperatorLubricationPoints.filter((point:any) => point.routeName === route.routeName) : []
            const routeState = routesInfo?.filter((r:any)=>(r.route === route.routeName))[0]?.state || 'PENDIENTE'
            
            return {
                ...route,
                state:routeState,
                lubricationPoints:[
                    ...routePoints
                ]
            }
        })
    },{
        enabled:!!dailyOperatorLubricationPoints && !!query.data && !!routesInfo,
        refetchOnWindowFocus:false,
        staleTime:120000
    })
}



/*----------------
mutations
----------------*/

export const useSetStartedRoute = ():[(data:{date:string,route:string,tagFP:string})=>void,MutationResult<any>] =>{

    const token = useToken()
    const [mutation,data] = useMutation(setStartedRoute)

    const startRoute = (data:{date:string,route:string,tagFP:string}):void => {
        mutation({
            token,
            data
        })
    }
    return [startRoute,data]
}