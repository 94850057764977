import Axios, { AxiosRequestConfig } from "axios"
import { queryCache, QueryResult, useMutation } from "react-query"
import { URL } from "../../../infrastructure/appConfig/appConfig"
import { GridModelInterface, MutationQueryObject } from "../../../infrastructure/Interfaces"
import { useAllAuxByPlant } from "../../auxiliar-data/service.aux"
import { useOperatorSupervisorTagFP } from "../../persons/services/service.person"
import { useToken } from "../../session/service.session"
import { AnomalyInterface } from "../types/anomaliesTypes"


export const gridModelAnomaly:GridModelInterface = {
    id:{label:'Id'}, 
    anomaly:{label:'Anomalia'}, 
    tagFP:{label:'Planta'}, 
    date:{label:'Fecha'}, 
}

export const AnomalyCRUDDEpendencies = [
    'PlantAux'
]


//----------------------------
//Axios
//----------------------------

export const CreateAnomaly = ({data,token}:MutationQueryObject) => {
    Axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateAnomaly`,
        data:data
    }
    return Axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}
export const UpdateAnomaly = ({data,token}:MutationQueryObject) => {
    Axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateAnomaly`,
        data:data
    }
    return Axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}
export const DeleteAnomaly = ({data,token}:MutationQueryObject) => {
    Axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/DeleteAnomaly`,
        data:data
    }
    return Axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

//----------------------------
//ReactQuery
//----------------------------

export const usePlantAnomalies = (tagFP?:string) => {
    const supOpTagFP = useOperatorSupervisorTagFP()
    const queryTagFP = tagFP || supOpTagFP
    const query = useAllAuxByPlant(queryTagFP)


    const anomalies:QueryResult<AnomalyInterface[]> = {
        ...query,
        data:query.data?.anomalies
    }

    return anomalies

}

//----------------------------
//Mutation
//----------------------------

export const useCreateAnomaly = () => {
    const token = useToken()
    const [mutation,data] = useMutation(CreateAnomaly,{
        onSuccess:()=> {
            queryCache.invalidateQueries('PlantAux')
        }
    })

    const createAnomaly = (data:AnomalyInterface) => {
        mutation({
            data,
            token
        })
    }

    return{
        createAnomaly,
        data
    }
}

export const useUpdateAnomaly = () => {
    const token = useToken()
    const [mutation,data] = useMutation(UpdateAnomaly,{
        onSuccess:()=> {
            queryCache.invalidateQueries('PlantAux')
        }
    })

    const updateAnomaly = (data:AnomalyInterface) => {
        mutation({
            data,
            token
        })
    }

    return{
        updateAnomaly,
        data
    }
}

export const useDeleteAnomaly = () => {
    const token = useToken()
    const [mutation,data] = useMutation(DeleteAnomaly,{
        onSuccess:()=> {
            queryCache.invalidateQueries('PlantAux')
        }
    })

    const deleteAnomaly = (data:AnomalyInterface) => {
        mutation({
            data,
            token
        })
    }

    return{
        deleteAnomaly,
        data
    }
}




//----------------------------
//CustomHooks
//----------------------------
