
import axios, { AxiosRequestConfig } from 'axios'
import { SensorzedInfoSpanish } from '../interfaces/sensorRealtimeInterface';
import { useQuery } from 'react-query'
import { verifyModifyMacAdress } from '../../../utils/utils';
import { ONLINE_MODE } from '../../../infrastructure/appConfig/appConfig';
import { useToken } from '../../session/service.session';
import { SENSORING_URL } from '../../../infrastructure/appConfig/appConfig';
import { useAllSTISensorizedEquipments } from '../STISensorizedEquipments/services/STISensorizedEquipmentService';
import { objectMapper } from './../../../utils/objectMapper';

//-----------------------------
//----------------------HAXIOS
//-----------------------------

export const GetResource = (endpoint:string,tagFP:string,token:string) => {
    /* axios.defaults.headers.get['TagFP'] = tagFP */

    let config:AxiosRequestConfig = {
        headers:{
            Authorization:`Bearer ${token}`
        },
        method: "get",
        url:`${SENSORING_URL}/${endpoint}/`,
    }

    return(
        axios(config)
        .then(res => {
            return res.data
        })
        .catch(err =>{
            return err
        })
    )
}

export const SensorizedInfoByAddress = (_:string,address:string,token:string) => {
    const config:AxiosRequestConfig = {
        method:'GET',
        headers:{
            Authorization:`Bearer ${token}`,
            address,
        },
        url:`${SENSORING_URL}/SensorizedInfoByAddress`
    }

    return(
        axios(config)
        .then((res)=>res.data)
        .catch((err)=>{
            console.log(err)
            return err
        })
    )
}



//-----------------------------
//----------Translate Notations
//-----------------------------



const translateSensorizedInfoNotations = (item:SensorzedInfoSpanish,rest?:any) => {
    
    const sensorAddress = item.sensorSurvey ? Object.keys(item.sensorSurvey)[0] : ''
    const cleanAddress = verifyModifyMacAdress(sensorAddress)

    return {
        ...item,
        sensorAddress,
        cleanAddress:cleanAddress,
        sensorizedEquipment: /* rest ?  */ rest[sensorAddress] /*  : {
            sensorAddress:item.sensorizedEquipment?.AddressSensor, 
            deviceIoTCode:item.sensorizedEquipment?.CodigoDispositivoIoT, 
            criticality:item.sensorizedEquipment?.Criticidad, 
            element:item.sensorizedEquipment?.Elemento, 
            equipment:item.sensorizedEquipment?.Equipo, 
            state:item.sensorizedEquipment?.Estado, 
            date:item.sensorizedEquipment?.Fecha, 
            egressDate:item.sensorizedEquipment?.FechaEgreso,
            admissionDate:item.sensorizedEquipment?.FechaIngreso, 
            id:item.sensorizedEquipment?.Id, 
            lowReason:item.sensorizedEquipment?.MotivoDeBaja, 
            observations:item.sensorizedEquipment?.Observaciones, 
            sector:item.sensorizedEquipment?.Sector, 
            tagFP:item.sensorizedEquipment?.TagFP, 
            plantTag:item.sensorizedEquipment?.TagPlanta, 
            STItag:item.sensorizedEquipment?.TagSTI, 
            sensorType:item.sensorizedEquipment?.TipoSensor, 
            location:item.sensorizedEquipment?.Ubicacion, 
            channels:objParserFromServer(item.sensorizedEquipment?.Canales), 
            pressure:objParserFromServer(item.sensorizedEquipment?.Presion), 
            temperature:objParserFromServer(item.sensorizedEquipment?.Temperatura), 
            vibration:objParserFromServer(item.sensorizedEquipment?.Vibracion),
        } */
    }
    
}


//-----------------------------
//----------------------QUERIES
//-----------------------------

export const useSensorizedInfoByTagFP = (tagFP:string) => {
    const token = useToken()
    const query = useQuery<SensorzedInfoSpanish[], []>(['SensorizedInfo',tagFP,token],GetResource,{
        enabled:!!token && ONLINE_MODE,
        staleTime:30000,
        refetchInterval:20000,
        refetchOnWindowFocus:false
    })

    const {data:SensorizedEquipments} = useAllSTISensorizedEquipments()

    const mapped = SensorizedEquipments && objectMapper(SensorizedEquipments,'sensorAddress')


    console.log(query)
    return {
        ...query,
        data:(query.data && query.data.length > 0) 
            ? query.data?.map((item)=>translateSensorizedInfoNotations(item,mapped)) 
            : undefined
    }
}

export const useSensorizedInfoByAddress = (address:string)=>{
    const token = useToken()
    const query = useQuery<SensorzedInfoSpanish>(['sensorizedInfoByAddress',address,token],SensorizedInfoByAddress,{
        enabled:!!address && ONLINE_MODE,
        refetchOnWindowFocus:false,
    })

    const {data:SensorizedEquipments} = useAllSTISensorizedEquipments()

    const mapped = SensorizedEquipments && objectMapper(SensorizedEquipments,'sensorAddress')
   
    return{
        ...query,
        data:query.data && translateSensorizedInfoNotations(query.data,mapped)
    }
}

