import React from 'react';
import { PlantInterface } from '../../plants/service.plant';
import DeleteSectorFrom from '../DeleteSectorFrom';
import AnyGrid, { CarrousellActionInterface } from '../../../components/grids/AnyGrid';
import { gridModelSector, SectorInterface, usePlantSectors } from '../services/service.sectors';
import { useLangLabels } from '../../language/services/useSetLabel';
import CUSectorForm from './../CUSectorForm';
import { CrudFormType } from '../../../infrastructure/form/typescript/FormTypes';

interface Props {
    plant:PlantInterface
}

const carrousellActions:CarrousellActionInterface[] = [
    {icon:'edit',component:(item:SectorInterface)=><CUSectorForm actionType={CrudFormType.update} item={item} tagFP={item.tagFP}/>}
]

const ManageSectorPage:React.FC<Props> = ({plant}) => {

    const {lang} = useLangLabels()
    const {data:sectors,isLoading} = usePlantSectors(plant.tagFP)

    return(
        <AnyGrid
        gridModel={gridModelSector}
        title={lang.sectors.title.plural}
        items={sectors || []}
        loading={isLoading}
        height='55vh'
        carrousellActions={carrousellActions}
        itemActions={(item:any,items:any)=><>
{/*             <UpdateSector item={item} items={items} />
 */}            <DeleteSectorFrom item={item} />
        </>}
        headerActions={(item:any)=> <CUSectorForm actionType={CrudFormType.create} tagFP={plant.tagFP}/> }
        /> 
    )
}

export default React.memo(ManageSectorPage);
