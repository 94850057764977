import axios, { AxiosRequestConfig } from "axios";
import { queryCache, useMutation, useQuery } from "react-query";
import { URL } from "../../../../infrastructure/appConfig/appConfig";
import { MutationQueryObject } from "../../../../infrastructure/Interfaces";
import { useStatusProcessor } from "../../../errors/services/StatusProcessor";
import { useToken } from "../../../session/service.session";
import { STIEquipmentInterface } from "../interfaces/STIEquipmentInterfaces";


export const CreateSTIEquipment = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateSTIEquipment`,
        data
    }
    return axios(config).then(res => {return res.data}).catch(err =>{return err})
}

export const UpdateSTIEquipment = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateSTIEquipment`,
        data
    }
    return axios(config).then(res => {return res.data}).catch(err =>{return err})
}

export const STIEquipmentsByTagFP = (_:string,tagFP:string,token:String) => {
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    axios.defaults.headers.get['tagFP'] = tagFP;
    
    let config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/STIEquipmentsByTagFP`,
    }
    return axios(config).then(res => {return res.data}).catch(err =>{return err})
}



//----------------------------
//----------Query-------------
//----------------------------


export const useSTIEquipmentsByTagFP = (tagFP:string) =>{
    const token = useToken()
    return useQuery(['STIEquipmentsByTagFP',tagFP,token],STIEquipmentsByTagFP,{
        refetchOnWindowFocus:false,
        enabled:!!token || !!tagFP
    })
}


//----------------------------
//----------Mutation----------
//----------------------------

export const useCreateSTIEquipment = () => {
    const token = useToken()
    const [mutation,data] = useMutation(CreateSTIEquipment,{
        onSuccess:()=>{
            queryCache.invalidateQueries('STIEquipmentsByTagFP')
        }
    })
    const {status,message,error} = useStatusProcessor(data)

    const createSTIEquipment = (data:STIEquipmentInterface) => {
        return mutation({
            token,
            data
        })
    }

    return {createSTIEquipment,data:{...data,status,message,error}}
}


export const useUpdateSTIEquipment = () => {
    const token = useToken()
    const [mutation,data] = useMutation(UpdateSTIEquipment,{
        onSuccess:()=>{
            queryCache.invalidateQueries('STIEquipmentsByTagFP')
        }
    })
    const {status,message,error} = useStatusProcessor(data)

    const updateSTIEquipment = (data:STIEquipmentInterface) => {
        return mutation({
            token,
            data
        })
    }

    return {updateSTIEquipment,data:{...data,status,message,error}}
}