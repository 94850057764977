import React,{ useContext } from 'react';

//material
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { SessionContext } from '../../store/sessionContext';
import PageContainer from '../pageContainers/PageContainer';
import { usePerson, useUser } from '../../domains/persons/services/service.person';


const useStyles = makeStyles((theme) => createStyles({

  /*   root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent:'center',
      margin: theme.spacing(3),
      width:'auto',
      '& > *': {
        margin: theme.spacing(3),
        flex:'1',
        minWidth: theme.spacing(30),
        height: theme.spacing(24),
        padding:theme.spacing(2),
        background: 'rgb(228,224,222)',
        background: 'linear-gradient(0deg, rgba(255,255,255,1) 100%, rgba(228,224,222,1) 0%)',         
        '&:hover':{
            boxShadow: '0px 0px 47px -16px rgba(0,0,0,0.75)',
            transition: 'all 300ms ease',
            cursor:'pointer',
            color:'#fff',
        }
        
        },
    }, */
    heroNavPlant:{
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(-8) ,
          },
          [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(-10) ,
            marginBottom: theme.spacing(0)
          },
          '& > *': {
            width:'calc(100% - 48px)',
            marginTop: theme.spacing(-10),
            marginLeft: theme.spacing(3),
            marginBottom: theme.spacing(2),
            color:'#f3f3f3',
            textTransform:'uppercase',
            fontWeight:'900',
            letterSpacing: '1px',
            },
    },
    heroNavTitles:{
        width:'160px',
        textTransform:'uppercase',
        fontWeight:700,
        color:'#333',
    },
    hero:{
        [theme.breakpoints.down('sm')]:{
            height:'15vh',
            width:'100%',
            backgroundPosition:'center',
        },
        height:'20vh',
        width:'100%',
        backgroundPosition:'center',
    },
  }));

  interface Props{
    img:string
    title?:string
    date?:string
  }
const HomeHero:React.FC<Props> = ({img,date,title}) => {
    const classes = useStyles();
    const [auth] = useContext<any>(SessionContext)
    const {data:user} = useUser()
    const {data:person} = usePerson()

    return (
        <>
            <PageContainer>
                <Paper className={classes.hero} style={{backgroundColor:'#f3f3f3',backgroundImage:`url(${img})`,}} elevation={4}/>
                <div className={classes.heroNavPlant}>
                    <Typography variant="h5" component="h5">
                        {auth.workspace}
                    </Typography>

                    {user?.type === 'O' && <>
                            <Typography variant="body1">
                                {person?.name}
                            </Typography>
                            <Typography  variant='body1'>{`${date}`}</Typography>
                    </>}

                </div>
            </PageContainer>
        </>
    );
}

export default React.memo(HomeHero);
