import React from 'react'
//Components
import './App.css'
import LoginPage from './domains/login/pageLogin'
import EaseInOut from './components/presentational/EaseInOut'
import UserRole from './layouts/components/UserRole'
import LoginBackground from './components/presentational/LoginBackground'
import LogOutPage from './pages/LogOutPage';
import Loading from './components/presentational/Loading';

//services
import { useValidateToken, useToken } from './domains/session/service.session'
import {USER_ROUTES} from './infrastructure/routes/routes'

//REACT ROUTER
import {Route, Redirect} from 'react-router-dom'
//RQ
import { ReactQueryDevtools } from 'react-query-devtools'
import VersionContainer from './components/pageContainers/VersionContainer'
import { useTGDsignature } from './utils/hooks/useTGDsignature'


const LogValidation:React.FC<{path:string,children?:React.ReactNode}> = React.memo(({path, children}) => {
    const [data,status] = useValidateToken()

    if(status === 'loading'){
        return (<> 
            <Redirect to={path}/> 
            <Loading /> 
        </>)}

    if(data) return(<Route path={path} >{children} </Route>)

    if(status === 'error' || !data){
        sessionStorage.clear()
    }

    return <LoginPage></LoginPage> //modal para reloguearse
})



const App = ()=>{
    useTGDsignature()
    const token = useToken()
    return(
        <>
                <ReactQueryDevtools></ReactQueryDevtools>
                
                <LoginBackground bgColor={token ? '002F6C' : 'f3f3f3'} />
                

            {!!token ? <>
                <LogValidation path={`/${USER_ROUTES.home}`}>                    
                    <UserRole />
                </LogValidation> 
                <Route path={`/${USER_ROUTES.login}`}>
                    <LogOutPage />
                </Route>
            </>:<>
                <EaseInOut children={ <LoginPage/> } />
                <VersionContainer company='TGD' justify='center' />
            </>}
        </>
    )

}


export default React.memo(App)