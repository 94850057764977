import React from 'react';
import CustomButton from '../../components/buttons/CustomButton';
import { LubricationPointInterface, useUpdateEquipmentNextRevision } from './services/service.lubricationPoints';
import { useEffect } from 'react';
import { useClickPosition } from '../../utils/hooks/useClickPosition';
import { useSnackbar } from '../../utils/hooks/useSnackbar';



interface Props{
    item:LubricationPointInterface
}



const ChangeRevision:React.FC<Props> = ({item}) => {

    const {updateRevision, status,error,message} = useUpdateEquipmentNextRevision()

    const info ='Está cambiando a la siguiente revision de este componente, está seguro?. No se podrá volver a una revision anterior, deberá contactar al equipo de soporte para realizarlo.'

    const handleNextRevision = () => {
        const alert = window.confirm(`${info}`)
        if(alert){   
            updateRevision(item)
        }
    }

    const { getClickPosition, position } = useClickPosition()

    const {setData} = useSnackbar()

    useEffect(()=>{
        if(status === 'success'){
            setData({
                modal:true,
                errors:message || 'Todo salió bien' ,
                severity:'success',
                position:position
            })
        }
        if(status === 'error'){
            setData({
                modal:true,
                errors:message || 'Ha ocurrido un error inesperado',
                severity:'warning',
                position:position
            })
        }

    },[status])



    return (
        <CustomButton status={status} variant='iconButton' color='primary' popoverTitle='Cambio automático de revision' icon='nextState' action={(e)=>getClickPosition(e,handleNextRevision)}/>
    );
}

export default ChangeRevision;
