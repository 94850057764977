import { createStyles, makeStyles } from '@material-ui/core';
import React, { CSSProperties, memo } from 'react';

const useStyles = makeStyles((theme) => createStyles({
    flex:(props:any) => ({
        color:props.color || '',
        height:props.height,
        display: 'flex',
        gap: props.gap,
        justifyContent: props.justifyContent,
        alignContent: props.alignContent,
        width:props.width || 'auto',
        alignItems:props.alignItems,
        flexWrap:props.flexWrap,
        padding:props.padding || '0',
        margin:props.margin,
        backgroundColor:props.background || 'unset',
        borderRadius:props.borderRadius,
        flexDirection:props.flexDirection || 'row',
        overflowX:props.overflowX,
        overflowY:props.overflowY,
        overflow:props.overflow,
        maxWidth:props.maxWidth,
        border:props.border || 'none',
    })
  }));

interface Props{
    border?:string
    style?:CSSProperties
    children?:React.ReactNode
    justify?:'center' | 'space-between' | 'space-arround' | 'flex-start' | 'flex-end'
    align?:'center' |  'start' | 'end' 
    gap?:string
    flexWrap?:'nowrap' | 'wrap' | 'wrap-reverse'
    height?:string
    padding?:string
    margin?:string
    width?:string
    background?:string
    color?:string
    borderRadius?:string
    flexDirection?:'column'
    overflowX?:'visible' | 'hidden' | 'clip' | 'scroll' | 'auto'
    overflowY?:'visible' | 'hidden' | 'clip' | 'scroll' | 'auto'
    overflow?: 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto' 
    maxWidth?:string
    onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
}

const FlexContainer:React.FC<Props> = ({style,onClick,border,maxWidth, overflow, overflowX, overflowY, borderRadius,color,background,width,padding, children,justify,align,gap,flexWrap,height,margin,flexDirection}) => {
    
    const styles:CSSProperties = {
        borderRadius:borderRadius,
        border,
        color:color,
        background:background,
        width:width,
        padding:padding,
        height:height,
        overflowX,
        overflowY,
        flexWrap,
        gap: gap || '12px',
        justifyContent: justify,
        alignContent: align,
        alignItems:align,
        margin:margin,
        flexDirection:flexDirection,
        overflow,
        maxWidth
    }

    const classes = useStyles(styles)
    
    
    return (
        <div onClick={onClick} style={style} className={classes.flex}>
            {children}
        </div>
    );
}

export default memo(FlexContainer);
