import React, {  memo } from 'react';
import { Typography } from '@material-ui/core';
import TgdModal from '../../components/modals/TgdModal';
import FlexContainer from '../../components/presentational/FlexContainer';
import EquipmentsInfo from '../equipmentsInfo/EquipmentsInfo';
import { LubricationPointInterface } from '../equipments/services/service.lubricationPoints';
import { useLubricationPointSurveyController } from './domains/surveys/controllers/useLubricationPointSurveyController';
import SurveyTasks from './domains/surveys/SurveyTasks';
import CustomButton from '../../components/buttons/CustomButton';

interface SurveyHeaderProps {
    children:React.ReactNode
    prevNextValidations?:{
        prev:boolean,
        next:boolean
    }
    prevNextActions?:{
        prev:()=>void
        next:()=>void
    }
    currentItem:LubricationPointInterface
    background:string
    exitButton?:React.ReactNode
}

const SurveyHeader:React.FC<SurveyHeaderProps> = ({children, exitButton, prevNextActions, prevNextValidations, currentItem, background}) => {
    return(
        <FlexContainer 
        background={background} 
        color='white'
        align='center'
        justify='space-between'
        padding='8px 24px'>
            {exitButton && exitButton}
            {prevNextActions && <CustomButton /* outlined */ variant='iconButton' styleProps={{color:prevNextValidations?.prev ? '#FFFFFF50' :'#fff',borderColor:prevNextValidations?.prev? '#FFFFFF50': '#FFF'}}  disabled={prevNextValidations?.prev} icon='before' action={prevNextActions?.prev}/>}
            <FlexContainer width='100%' align='center'>
                <FlexContainer width='100%'  justify='space-between'>
                    {children && children}
                </FlexContainer>
                <EquipmentsInfo type='resume' item={currentItem}/>
            </FlexContainer>
            {prevNextActions && <CustomButton /* outlined */ variant='iconButton'  styleProps={{color:prevNextValidations?.next ? '#FFFFFF50' :'#fff',borderColor:prevNextValidations?.next? '#FFFFFF50': '#FFF'}} disabled={prevNextValidations?.next} icon='next' action={prevNextActions?.next}/>}
        </FlexContainer>
    )
}

interface Props{
    open:boolean
    handleClose:()=>void
    tasks:any
    currentLubricationPoint:LubricationPointInterface
    prevNextButton?:React.ReactNode | undefined
    children?:React.ReactNode
    outOfRoute:boolean
    exitButton?:React.ReactNode
    prevNextValidations?:{
        prev:boolean,
        next:boolean
    }
    prevNextActions?:{
        prev:()=>void
        next:()=>void
    }    
}

const SurveyModal:React.FC<Props> = ({children, open, handleClose, exitButton, tasks, currentLubricationPoint, outOfRoute, prevNextButton, prevNextActions, prevNextValidations}) => {
    
    
    const {survey} = useLubricationPointSurveyController(currentLubricationPoint,outOfRoute)
    const background = tasks && survey && tasks[survey.taskStep]?.color
    const currentTask = tasks && survey && tasks[survey.taskStep]?.type
    
    return (
        <TgdModal
            open={open} 
            handleClose={handleClose} 
            fullWidth={true} 
            defaultButton={<></>}
            header={<>
            
                <SurveyHeader {...{prevNextActions, prevNextValidations, currentItem:currentLubricationPoint,background,exitButton}} >
                    <Typography color='inherit' variant='h6'>{currentTask}</Typography>
                </SurveyHeader>
                {outOfRoute &&
                    <FlexContainer
                    /* flexDirection={'column'} */
                    align='center'
                    background={'black'}
                    color='white'
                    padding='4px 24px'
                    height='3vh'
                    >
                        <Typography variant='body2'>(FUERA DE RUTA)</Typography>
                    </FlexContainer>
                }
                <SurveyTasks outOfRoute={outOfRoute} lubricationPoint={currentLubricationPoint}/>
        </>}>
            

           {(survey?.taskStep !== 0 || outOfRoute === false) && 
                <FlexContainer height='60vh'>
                    {children && children}
                </FlexContainer>
            }
        </TgdModal>

    );
}

export default memo(SurveyModal);
