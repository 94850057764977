import React from 'react';
import TgdListToolbar from '../lists/TgdListToolbar';
import { DataGrid,  GridColumns } from '@material-ui/data-grid';
import { useSearch } from '../../utils/hooks/useSearch';

const TgdSelectableTable:React.FC<{items:any,columns:GridColumns,headerActions:React.ReactNode,title:string,handleChange:(item:any)=>void}> = ({items,columns,headerActions,title,handleChange}) => {
    
    const {search,handleSearch,filteredItems} = useSearch(items,'routeName')
    
    const handleSelect = (selectedElements:any) => {
        const SItems = items?.filter((i:any)=>selectedElements.includes(JSON.stringify(i.id)))
        handleChange(SItems)
    }


    return (
        <>
                <TgdListToolbar
                    title={title}
                    searchValue={search}
                    handleSearch={handleSearch}
                    actions={headerActions}
                    searchBar={true}
                />
{/*                 <br/>
 */}                <DataGrid
                    autoHeight
                    pageSize={6}
                    rowsPerPageOptions={[6,12,24,48,96,200]}
                    checkboxSelection 
                    rows={filteredItems || []}
                    columns={columns}
                    onSelectionModelChange={(item)=>handleSelect(item.selectionModel)}
                />
        </>
    );
}

export default TgdSelectableTable;
