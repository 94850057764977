import axios, { AxiosRequestConfig } from "axios";
import { queryCache, useMutation, useQuery } from "react-query";
import { URL } from "../../../../infrastructure/appConfig/appConfig";
import { objParserFromServer, objStringifyToServer, verifyModifyMacAdress } from "../../../../utils/utils";
import { MutationQueryObject } from "../../../../infrastructure/Interfaces";
import { useStatusProcessor } from "../../../errors/services/StatusProcessor";
import { useToken } from "../../../session/service.session";
import { STIEquipmentInterface } from "../../STIEquipments/interfaces/STIEquipmentInterfaces";
import { STISensorizedEquipmentInterface } from "./STISensorizedEquipmentsInterfaces";
import { useSensorizedInfoByTagFP } from './../../api/SensoringService';



export const CreateSTISensorizedEquipment = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateSTISensorizedEquipment`,
        data
    }
    
    return axios(config).then(res => {return res.data}).catch(err =>{return err})
}

export const UpdateSTISensorizedEquipment = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateSensorizedSTIEquipment`,
        data
    }
    return axios(config).then(res => {return res.data}).catch(err =>{return err})
}

export const STISensorizedEquipmentByTagFP = (_:string,tagFP:string,token:String) => {
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    axios.defaults.headers.get['tagFP'] = tagFP;
    
    let config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/STISensorizedEquipmentsByTagFP`,
    }
    return axios(config).then(res => {
        return res.data
    }).catch(err =>{return err})
}



//----------------------------
//----------Query-------------
//----------------------------


export const useSTISensorizedEquipmentsByTagFP = (tagFP:string) =>{
    const token = useToken()
    const query = useQuery<STISensorizedEquipmentInterface[]>(['STISensorizedEquipmentsByTagFP',tagFP,token],STISensorizedEquipmentByTagFP,{
        refetchOnWindowFocus:false,
        enabled:!!token || !!tagFP
    })

    return {
        ...query,
        data: query.data?.map((item)=>{
            return {
                ...item,
                iotDeviceCode:verifyModifyMacAdress(item?.iotDeviceCode),
                sensorAddress:verifyModifyMacAdress(item?.sensorAddress),
                vibration: item.vibration && objParserFromServer(item?.vibration),
                temperature: item.temperature && objParserFromServer(item?.temperature),
                pressure: item.pressure && objParserFromServer(item?.pressure),
                channels: item.channels && objParserFromServer(item?.channels)
            }
        })
    }
}


export const useSensorizedInfoByEquipment = (equipment:STIEquipmentInterface) => {


    const tagFP = equipment.tagFP
    const query = useSensorizedInfoByTagFP(tagFP)
    
    const filteredEquipmentSensors = query.data?.filter((survey:any)=>survey.sensorizedEquipment?.equipment === equipment.equipment)
   
    return {
        ...query,
        data:filteredEquipmentSensors
    }

}


//----------------------------
//----------Mutation----------
//----------------------------



export const useCreateSTISensorizedEquipment = () => {
    const token = useToken()
    const [mutation,data] = useMutation(CreateSTISensorizedEquipment,{
        onSuccess:()=>{
            queryCache.invalidateQueries('STISensorizedEquipmentsByTagFP')
        }
    })
    const {status,message,error} = useStatusProcessor(data)

    const create = (data:STISensorizedEquipmentInterface) => {

        return mutation({
            token,
            data:{
                ...data,
                iotDeviceCode: verifyModifyMacAdress(data.iotDeviceCode),
                sensorAddress: verifyModifyMacAdress(data.sensorAddress),
                vibration: data.vibration && objStringifyToServer(data.vibration),
                temperature: data.temperature && objStringifyToServer(data.temperature),
                pressure: data.pressure && objStringifyToServer(data.pressure),
                channels: data.channels && objStringifyToServer(data.channels)
            },
        })
    }

    return {create,data:{...data,status,message,error}}
}


export const useUpdateSTISensorizedEquipment = () => {
    const token = useToken()

    const [mutation,data] = useMutation(UpdateSTISensorizedEquipment,{
        onSuccess:()=>{
            queryCache.invalidateQueries('STISensorizedEquipmentsByTagFP')
        }
    })

    const {status,message,error} = useStatusProcessor(data)

    const update = (data:STISensorizedEquipmentInterface) => {
        return mutation({
            token,
            data:{
                ...data,
                iotDeviceCode:verifyModifyMacAdress(data.iotDeviceCode),
                sensorAddress:verifyModifyMacAdress(data.sensorAddress),
                vibration:data.vibration && JSON.stringify(data.vibration),
                temperature:data.temperature && JSON.stringify(data.temperature),
                pressure:data.pressure && JSON.stringify(data.pressure),
                channels:data.channels && JSON.stringify(data.channels)
            },
        })
    }

    return {update,...data,status,message,error}
}

export const useAllSTISensorizedEquipments = () => {

    const token = useToken()

    const AllSTISensorizedEquipments = () => {

        return axios({
            url:`${URL}/AllSTISensorizedEquipments`,
            headers:{
                Authorization:`Bearer ${token}`
            }
        })
        .then((res)=> res.data)
        .catch((err)=>{
            console.log(err)
            return err
        })
    }

    return useQuery<STISensorizedEquipmentInterface[]>('AllSTISensorizedEquipment',AllSTISensorizedEquipments,{
        enabled:!!token
    })
} 



//-------------------
//--------------utils
//-------------------


