import axios, { AxiosRequestConfig } from 'axios'
import { HexBase64BinaryEncoding } from 'crypto';
import { URL } from '../../../infrastructure/appConfig/appConfig';
import { MutationQueryObject } from '../../../infrastructure/Interfaces';
import { MutationResult, useMutation } from 'react-query';
import { useToken } from '../../session/service.session';


//--------------------------------------------
//INTERFACES
//--------------------------------------------

interface EmailWithAttachmentInterface{
    emailAddress:string
    htmlEmailBody:string
    fileName:string
    attachment:string | ArrayBuffer | null    
}

//--------------------------------------------
//AXIOS
//--------------------------------------------


export const SendEmailWithAttachment = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/SendEmailWithAttachment`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

//--------------------------------------------
//QUERIES
//--------------------------------------------




//--------------------------------------------
//MUTATIONS
//--------------------------------------------

export const useSendEmailWithAttachment = ():[(data:EmailWithAttachmentInterface)=>Promise<any>,MutationResult<any>] => {

    const token = useToken()
    const [mutation,data] = useMutation(SendEmailWithAttachment)

    const sendEmailWithAttachment = (data:EmailWithAttachmentInterface) => {
        return mutation({
            token,
            data
        })
    }

    return[sendEmailWithAttachment,data]
}