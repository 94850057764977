import React from 'react';
import { PlantInterface } from '../../plants/service.plant';
import { gridModelAnomaly, usePlantAnomalies } from '../services/service.anomalies';
import DeleteAnomalyForm from '../DeleteAnomalyForm'
import AnyGrid, { CarrousellActionInterface } from '../../../components/grids/AnyGrid';
import { AnomalyInterface } from '../types/anomaliesTypes';
import CUAnomalyForm from '../CUAnomalyForm';
import { CrudFormType } from '../../../infrastructure/form/typescript/FormTypes';


interface Props{
    plant:PlantInterface
}

const carrousellActions:CarrousellActionInterface[] =[
    {icon:'edit',component:(item:AnomalyInterface) => <CUAnomalyForm tagFP={item.tagFP} item={item} actionType={CrudFormType.update} />}
]

const ManageAnomaliesPage:React.FC<Props> = ({plant}) => {

    const {data:anomalies} = usePlantAnomalies(plant.tagFP)
    
    return(
        <AnyGrid 
            gridModel={gridModelAnomaly}
            title='Anomalía'
            items={anomalies || []}
            loading={anomalies ? false : true}
            height='55vh'
            carrousellActions={carrousellActions}
            itemActions={(item:any)=><>
                <DeleteAnomalyForm item={item}/>
            </>}
            headerActions={(item:any)=> <CUAnomalyForm tagFP={plant.tagFP} actionType={CrudFormType.create} /> }
        />  
    )
}


export default React.memo(ManageAnomaliesPage);
