import React, { useEffect } from 'react';
import { Divider, Grid, Paper, Typography } from '@material-ui/core';
import FlexContainer from '../../../components/presentational/FlexContainer';
import { useSTISensorsByTagFP } from '../STISensors/services/STIsensorsService';
import PointerContainer from '../../../components/pageContainers/PointerContainer'
import TgdPopover from '../../../components/popovers/TgdPopover';

import LineChart from '../../../infrastructure/chart.js/charts/LineChart';

import { SensorType } from '../interfaces/interfaces';
import { useSensorStatus } from '../services/useSensorStatus';
import { useQueryState } from './../../../infrastructure/reactQuery.tsx/useQueryState';

import ComponentMode from '../../../components/ComponentMode';
import { SensorPrev } from '../STISensors/molecules/EnterSensorAddress';
import { objectMapper } from './../../../utils/objectMapper';


interface sensorCardProps { 
    type:React.ReactNode
    batteryStatus:string[]
    sensorStatus:SensorStatusInterface[] | undefined
}  

interface SensorCardWrapperProps {
    globalStatus:string | undefined
    sensorSurvey:any, 
    sensor:any,  
    sensorType:string,  
    cleanAddress:string,
    sensorAddress:string,
    realtimeChart?:boolean
}

interface SensorStatusInterface {
    batteryLevel:string[],
    status: {
        name: string
        status: string
        sensorValue: number
    }[]
    topic: string
    sensorType: SensorType
    limits: {
        [key: string]: {
            max: number
            min: number
        }
    }
    surveyValues: {
        [key: string]: number
    }
}

const label = (label:string) =>(
    label === 'rmsX' 
    ? 'X' 
    : label === 'rmsY' 
    ? 'Y'
    : label === 'rmsZ' 
    ? 'Z' 
    : label === 'celsius' 
    ? 'º'
    : label === 'TEMPERATURA Y VIBRACION'
    ? 'TEMP. y VIBR.'
    : label === 'channel_1'
    ? 'ch1:'
    : label === 'channel_2'
    ? 'ch2:'
    : label === 'channel_3'
    ? 'ch3:'
    : label === 'channel_4'
    ? 'ch4:'
    : label === 'channel_5'
    ? 'ch5:'
    : label === 'channel_6'
    ? 'ch6:'
    : ''
)

const BatteryLevel:React.FC<{batteryStatus?:string[]}> = ({batteryStatus}) => {

    return(<>
        {batteryStatus &&
        <Grid item  xs={12} >
            <Typography style={{fontSize:'10px'}} >
                {batteryStatus?.map((color,index)=>
                    <span key={index} style={{color}}> &#10687; </span>
                )}    
            </Typography>
        </Grid>}
    </>)
}

const getSurveyDate = (miliseconds:number) => {
    const date = new Date(miliseconds).toLocaleString().split(' ')[1] 
    return date
}


const SensorStatusWrapper:React.FC<SensorCardWrapperProps> = ({ sensorSurvey, sensor, sensorAddress, cleanAddress, children, sensorType, globalStatus,realtimeChart}) => {

   const {xLabels,chartData} = useCachedSensorRealtimeInfo(cleanAddress)
    const _realTimeChart = realtimeChart === false ? false : true

    const content =
    <Paper style={{borderLeft:`4px solid ${globalStatus}`,/* maxWidth:'140px' */}} >
        <FlexContainer   padding='8px 8px 8px 12px' height={'90px'}>
            {sensor ? <>
                {sensorSurvey
                    ?  children
                    : <>Consultando..</>
                }
            </> : <>Desconocido..</>}
        </FlexContainer>
        <Divider/>
        <ComponentMode mode={'popover'} variant={'iconButton'} icon='info' popoverTitle='Más información'>
            <SensorPrev {...{sensorAddress,sensorSurvey}} />
        </ComponentMode>
    </Paper>

    return(
        !_realTimeChart 
        ? content
        : <TgdPopover mode='hover' title='' hoverChildren={
            (chartData && xLabels) &&
                <LineChart
                chartValues={chartData}
                xAxis={xLabels}
            />
        }>
            {content}
        </TgdPopover>
    )
}

const TempVibrationSensorCard:React.FC<sensorCardProps> = ({sensorStatus,batteryStatus,type}) => {
   
    const {temperature:{status:temperatureStatus},vibration:{status:vibrationStatus}} = objectMapper(sensorStatus,'topic') 

    const axis = vibrationStatus?.map((status:any)=>
        <Grid item  xs={12}  >
            <Typography style={{fontSize:'12px'}}>
                {label(status.name)}<strong style={{color:status.status}}> {status.sensorValue}</strong>
            </Typography>
        </Grid>
    )

    const _temp = temperatureStatus?.map((status:any) =>
        <Grid item  xs={12}  >
            <Typography style={{fontSize:'16px'}} > 
                <strong style={{color:status.status}}> {status.sensorValue}</strong>{label(status.name)} 
            </Typography>
        </Grid>
    )

    return(
        <>
            <Grid container  >
                {axis}
            </Grid>
            <Grid container   >
                {_temp} 
                <BatteryLevel batteryStatus={batteryStatus}/>
                {type} 
            </Grid>
        </>
    )
}

const TempSensorCard:React.FC<sensorCardProps> = ({sensorStatus,batteryStatus,type}) => {

    const{temperature:{status}}= objectMapper(sensorStatus,'topic')

    const _temp = status.map((topic:any)=>
        <Grid key={topic.name} item  xs={12}   > 
            <Typography style={{fontSize:'16px'}}>
                <strong style={{color:topic.status}}> 
                    <span> {topic.sensorValue} </span> 
                    <span style={{fontSize:'12px'}}> {label(topic.name)} </span>
                </strong>
            </Typography>
        </Grid>
    )

    return(
        <Grid container >
            {_temp}
            <BatteryLevel batteryStatus={batteryStatus}/>
            {type} 
        </Grid>
    )
}


const PressureSensorCard:React.FC<sensorCardProps> = ({sensorStatus,batteryStatus,type}) => {
    
    const {temperature, pressure } = objectMapper(sensorStatus,'topic')
    
    //console.log(sensorStatus)
    
    const _temp = temperature.status.map((topic:any)=>
        <Grid key={topic.name} item  xs={12}   > 
            <Typography style={{fontSize:'16px'}}>
                <strong style={{color:topic.status}}> 
                    {topic.sensorValue} 
                    <span style={{fontSize:'12px'}}>{label(topic.name)}</span>
                </strong>
            </Typography>
        </Grid>
    )

    const _pressure = pressure.status.map((topic:any)=>
        <Grid item key={topic.name} xs={12}   >
            <Typography style={{fontSize:'16px'}} > 
                <strong style={{color:topic.status}}>
                    {topic.sensorValue}
                </strong>
                <Typography variant='body2'>{topic.name}</Typography> 
            </Typography>
        </Grid>
    ) 

    return(<>
        <Grid container >
            {_pressure}
        </Grid>
        <Grid container >
            {_temp} 
            <BatteryLevel batteryStatus={batteryStatus}/>
            {type}
        </Grid>
    </>)
}





const ElectricitySensorCard:React.FC<sensorCardProps> = ({sensorStatus,batteryStatus,type}) => {

  

    const {channels} = objectMapper(sensorStatus,'topic')

    const _channels = channels.status.map((topic:any)=>
        <Grid key={topic.name} item xs={12}>
            <span style={{fontSize:'12px'}}>{label(topic.name)}</span>
            <span style={{color:topic.status}}>
                    {topic.sensorValue} 
            </span>
        </Grid>
    )

    return(<>
        <Grid container >
            { _channels}
        </Grid>
        <Grid container >
            <Grid item xs={12} > <span>amps.</span> </Grid>
            <BatteryLevel batteryStatus={batteryStatus}/>
            {type}
        </Grid>
    </>)
}

interface CachedSensorInterface {
    chartData:{[key:string]:number[]}[],
    xLabels:string[] 
}

export const useCachedSensorRealtimeInfo = (sensorAddress:string,config?:any) => {
    const queryKey = `sensorSurveys-${sensorAddress}`
    const {data:state,setQueryState} = useQueryState<CachedSensorInterface>(queryKey,{
        ...config
    })

    const addSurvey = ({dataItem,labelItem}:{dataItem:{[key:string]:number[]},labelItem:string}) => {
        /* verifying survey existance */
        !state?.xLabels.includes(labelItem) &&
        /* add new survey value */
        setQueryState({
            ...state,
            chartData:[
                ...(state?.chartData || []),
                dataItem
            ],
            xLabels:[
                ...(state?.xLabels || []),
                labelItem
            ]
        })
    }


    const chartData =  
    state?.chartData?.reduce((acc:any,item:any) => {
            let result = { ...acc }
            Object.entries(item).forEach(([name,value])=>{
            result = {
                ...result,
                [name]:[
                    ...(result[name] ? result[name] : []),
                    value
                ]
            }
        })
        return result
    })

    return { 
        addSurvey,
        chartData, 
        xLabels:state?.xLabels}
}



interface Props{
    sensorData:any
    tagFP:string
    onClick?:(item?:any)=>void
    realtimeChart?:boolean
}

const SensorCard:React.FC<Props> = ({sensorData,tagFP,onClick,realtimeChart}) => {


    const {surveyChartData,sensorType,cleanAddress,sensorSurvey,lastTime,sensorStatus,batteryStatus,globalStatus,sensorAddress} = useSensorStatus(sensorData)

    const {addSurvey} = useCachedSensorRealtimeInfo(cleanAddress,{
        initialValue:{chartData:[surveyChartData], xLabels:[lastTime]},
        enabled:!!surveyChartData && !!sensorData.sensorSurvey,
    })

    useEffect(()=>{
        surveyChartData && lastTime && sensorData.sensorSurvey &&
        addSurvey({
            dataItem:surveyChartData,
            labelItem:getSurveyDate(lastTime)
        })
    },[sensorData])

    const {data:sensors} = useSTISensorsByTagFP(tagFP)

    const sensor = sensorData && sensors?.filter(s => s.sensorAddress === cleanAddress )[0] 

    const type = 
    <Grid item  xs={12}  >
        <Typography style={{fontSize:'8px'}}> 
            {sensorType === 'TEMPERATURA Y VIBRACION' ? 'TEMP. Y VIBR.': sensorType} 
        </Typography>
    </Grid>


    return (
        <PointerContainer {... onClick ? {onClick:()=>onClick(sensorAddress)} : {} } >
            <SensorStatusWrapper {...{realtimeChart, sensorAddress, sensor, sensorSurvey, cleanAddress, sensorType, globalStatus}} >
    
            {sensorStatus &&<> 
                {sensorType === 'TEMPERATURA Y VIBRACION' &&
                <TempVibrationSensorCard {...{sensorStatus,batteryStatus,type}} />
                }

                {sensorType === 'TEMPERATURA' &&
                    <TempSensorCard {...{sensorStatus,batteryStatus,type}} />
                }

                {sensorType === 'PRESION' &&
                    <PressureSensorCard {...{sensorStatus,batteryStatus,type}} />
                }

                {sensorType === 'CORRIENTE' &&
                    <ElectricitySensorCard {...{sensorStatus,batteryStatus,type}} />
                }
            </>}
            
            </SensorStatusWrapper>
        </PointerContainer>
    );
};





export default SensorCard;
 