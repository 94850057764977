import React from 'react';
import {Icon,makeStyles, List, ListItem,ListItemText, ListItemSecondaryAction, IconButton, Divider, CircularProgress} from '@material-ui/core'
import PageContainer from '../pageContainers/PageContainer';
import { Alert } from '@material-ui/lab';
import { Accordion } from '@material-ui/core';

import AccordionSummary from '@material-ui/core/AccordionSummary';

import TgdListToolbar from './TgdListToolbar';
import { useSearch } from './../../utils/hooks/useSearch';


const useStyles = makeStyles((theme) => ({
    MuiAccordionRoot:{
        overflow:'hidden',
        boxShadow:'none',
        margin:0,
        padding:0,
        '&:hover':{
            backgroundColor:'#f3f3f3'
        }
    },
    MuiAccordionSummaryRoot:{
        
    },
    icon:{
        margin:'0 16px'
    }
}));


interface Props{
    type:string 
    displaySubNameHandler?: any
    alternativeColumn?:(item?:any)=>React.ReactNode
    items:any[] | undefined | null
    status:string | null | undefined
    customButtons?:(item:any) => React.ReactNode
    icon?: (item:any) => React.ReactNode
    children?: (item:any) => React.ReactNode
    toggleDisabled?:Boolean 
    title?:string
    toolbarActions?:React.ReactNode
    searchBar?:boolean
}

const TgdAuxList:React.FC<Props> = ({searchBar,items,status,customButtons,icon,children,toggleDisabled,type,displaySubNameHandler,title,toolbarActions,alternativeColumn}) => {
    const toggleDefault = toggleDisabled ? false : true
    const classes = useStyles()
    const {search,handleSearch,filteredItems} = useSearch(items,type)

    const EditIcon = 
        toggleDefault && <>
        <IconButton size='small' color={'primary'} edge="end" aria-label="editar" >
            <Icon>edit</Icon>
        </IconButton>
        </>
    
    if(status === 'error' ) return <PageContainer><Alert severity={status} >{status}</Alert></PageContainer>

    return(<>



        <div style={{overflowY:'auto',overflowX:'hidden',maxHeight:'calc(60vh)',minHeight:'230px',}}>
            
            
            <TgdListToolbar 
            searchBar= {!searchBar ? searchBar : true}
            searchValue={search}
            handleSearch={handleSearch}
            title={title}
            actions={toolbarActions}
            />
          {/*   {errorHandler || null} */}
            <br/>
            {(status === 'loading') 
            ? <PageContainer><CircularProgress></CircularProgress></PageContainer>
            :<>
                {(items && (items.length > 0)) ?
                filteredItems && filteredItems.map((item:any,index:number) => 
                <div key={index}>
                    <Accordion className={classes.MuiAccordionRoot}>
                        <AccordionSummary
                            style={{maxHeight:'48px'}}
                            expandIcon={EditIcon}
                            aria-label="Expand"
                            aria-controls="panel1c-content"
                            id="panel1bh-header"
                        >
                            <List dense={true} style={{width:'100%'}} disablePadding={true} >
                                <ListItem >
                                    {icon && icon(item)} 
                                    <ListItemText
                                        style={{width:'250px'}}
                                        primary={(item[type]) ? item[type] : ''}
                                        secondary={!!displaySubNameHandler ? item[displaySubNameHandler] : ''
                                            /* displaySubNameHandler && displaySubNameHandler[0]
                                                ? displaySubNameHandler[0] + item[displaySubNameHandler[1]]
                                                : displaySubNameHandler
                                                    ?item[displaySubNameHandler]
                                                    :'' */
                                        }
                                    />
                                    {item['type'] && <ListItemText style={{textAlign:'left'}} primary={item['type']}/> }
                                    {alternativeColumn && alternativeColumn(item)}
                                </ListItem> 
                                <ListItemSecondaryAction 
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                    aria-label="Acknowledge">
                                    {customButtons && customButtons(item)}
                                </ListItemSecondaryAction>
                            </List>
                        </AccordionSummary>
                            <PageContainer color='#f3f3f3' >
                                <PageContainer color='white'>
                                    {children && children(item)}
                                </PageContainer>
                            </PageContainer>
                    </Accordion>
                    <Divider variant='middle'/>
                </div>)
                : <Alert severity={'warning'} >Aun no Existe nada que mostrar </Alert> 
                }
            </>}
           
            
        </div>

 </>       
    )   
     
}

export default React.memo(TgdAuxList);
