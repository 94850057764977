import { Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import FlexContainer from '../../../components/presentational/FlexContainer';
import CustomButton, { CustomIconType } from './../../../components/buttons/CustomButton';
import { useLangLabels } from '../../language/services/useSetLabel';
import { useSensorizedInfoByTagFP } from '../api/SensoringService'
import { ListViewType } from '../interfaces/interfaces';
import SensorCard from '../Components/SensorCard';
import { statusValues } from './../services/useSensorStatus';
import SelectSimpleEntity from '../../../components/select/SelectSimpleEntity';
import { useQuery, queryCache } from 'react-query';
import SensorizedEquipmentCard from '../Components/SensorizedEquipmentCard';
import { useSTIEquipmentsByTagFP } from './../STIEquipments/services/STIEquipmentService';
import VersionContainer from '../../../components/pageContainers/VersionContainer';


interface ViewProps {
    children?:React.ReactNode
    toolbar?:(children?:React.ReactNode)=>React.ReactNode
}

interface ViewTypesModelInterface{
    type:ListViewType,
    icon:CustomIconType
}

const typesModel:ViewTypesModelInterface[] = [
    {
        type:'interactive',
        icon:'view'
    },
    {
        type:'grid',
        icon:'list'
    },
]

const ViewMode:React.FC<ViewProps> = ({children,toolbar})=>{

    const {lang} = useLangLabels()
    const [{type},setTypeState] = useState<ViewTypesModelInterface>(typesModel[0])

    const viewSwitch = 
        <FlexContainer align='center' padding='8px' >
        {typesModel.map(({type:itemType,icon})=>
            <CustomButton 
                key={itemType}
                popoverTitle={lang.ui.components.viewMode.title}
                color={(type === itemType) ? 'primary' : undefined } action={()=>setTypeState({type:itemType,icon})} 
                variant='iconButton' 
                icon={icon}
            />
        )}
        </FlexContainer>

    return (
        <>

            {toolbar && toolbar(viewSwitch)}
            {type === 'grid' &&
                <>Grid</>
            }

            {type === 'interactive' &&
                children && children
            }

        </>
    )

}

interface Props {
    tagFP:string
}

const status = [
    {
        label:'Peligro',
        value:statusValues.danger
    },
    {
        label:'Atención',
        value:statusValues.warning
    },
    {
        label:'Normal',
        value:statusValues.normal
    },
    
]


const filtersNotation = {
    byStatus:'bystatus',
    byEquipment:'byEquipment',
} 




const SensorFilters:React.FC<{type:'order' | 'filter', handleChange:(item:{[key:string]:any})=>void}> = ({type,handleChange}) => {

    const [filters,setFilters] = useState<any>(undefined)

    const handleFilters = (type:string,value:string) => {
        setFilters({
            ...filters,
            [type]:value
        })
    }

    const [orderby,setOrderby] = useState<string | undefined>(undefined)

    useEffect(()=>{
        handleChange({
            orderby,
            filters
        })
    },[orderby,filters])

    return(
    <>

        {type === 'filter' &&
            <SelectSimpleEntity
                width='200px'
                label='filtrar'
                entityList={status}
                onChange={(value:string)=>handleFilters(filtersNotation.byStatus,value)}
                showTitle='label'
            />
        }

        {type === 'order' &&
            <SelectSimpleEntity
                width='200px'
                label='ordenar'
                entityList={status}
                showTitle='label'
                onChange={(value:string) => setOrderby(value)}
            />
        }

    </>)
}

type SensoringViewTypes = {label:string,value:string}

interface SensoringStoreInterface {
    viewTypes: SensoringViewTypes[];
    selectedViewType: SensoringViewTypes;
}

const sensoringViewTypesValues = {
    sensoring:'sensoring',
    equipments:'equipments'
} 

const useSensoringStore = () => {

    const {lang} = useLangLabels()

    const viewTypes:SensoringViewTypes[] = [
        {label:lang.pages.realTime.equipments, value:sensoringViewTypesValues.equipments},
        {label:lang.pages.realTime.sensors, value:sensoringViewTypesValues.sensoring},
    ]

    const storeKey = 'SensoringStore'
    const sensoringStore = useQuery<SensoringStoreInterface>(storeKey,()=>{
        return queryCache.getQueryData(storeKey) || {
            viewTypes,
            selectedViewType:viewTypes[0],
        }
    },{
        refetchOnWindowFocus:false
    }).data

    const setStore = (value:any) =>{
        queryCache.setQueryData(storeKey,value,{
            onSuccess:()=>{
                queryCache.invalidateQueries(storeKey)
            }
        })
    }

    const setViewType = (viewType:SensoringViewTypes)=>{
        return setStore({
            ...sensoringStore,
            selectedViewType:viewType
        })
    }

    const resetViewType = ()=>{
        setViewType(viewTypes[0])
    }

    return {
        sensoringStore,
        setViewType,
        resetViewType
    }
}

export const SensorsCardGrid:React.FC<{tagFP:string,defaultSensorSurveys?:any,onChange?:(item?:any)=>void, realtimeChart?:boolean, gridConfig?:{lg:number, md:number, sm:number, xs:number}}> = ({gridConfig, tagFP,defaultSensorSurveys,onChange,realtimeChart}) => {

    const { data:sensorizedInfo } = useSensorizedInfoByTagFP(tagFP)
    const cardsArray = defaultSensorSurveys || sensorizedInfo

    const _gridConfig:any = gridConfig || {lg:2, md:3, sm:4, xs:6}
    
    return(
        <Grid container spacing={3} >
            {cardsArray?.map((sensor:any)=>
                <Grid key={sensor.sensorAddress} item {..._gridConfig}  >
                    <SensorCard 
                    onClick={onChange} 
                    sensorData={sensor} 
                    tagFP={tagFP}
                    realtimeChart={realtimeChart}
                    />
                </Grid>
            )}
        </Grid>
    )
}

const EquipmentsCardGrid:React.FC<{tagFP:string}> = ({tagFP}) => {

    const {data:equipments} = useSTIEquipmentsByTagFP(tagFP)

    
    return(
        <Grid container spacing={3}>
            {(equipments && equipments.length > 0) ? equipments.map((equipment:any)=>
                <Grid key={equipment.id} item lg={4} md={6} sm={12} xs={12} >
                    <SensorizedEquipmentCard  key={equipment.id} {...{equipment}} />
                </Grid>
            ) : null}
        </Grid>
    )
}


const RealtimeSensoringPage:React.FC<Props> = ({tagFP}) => {

    const {sensoringStore,setViewType,resetViewType} = useSensoringStore()

    const Toolbar = 
        (ButtonMode:React.ReactNode) => <>
            <FlexContainer flexWrap='wrap' margin='0px 0px 24px 0px'>
                <Paper style={{width:'100%'}} elevation={3} >
                    <FlexContainer padding='0px 24px' justify='space-between' align='center'>
                        <FlexContainer justify='space-between' width='100%'>

                            {/* <Typography variant='h6'>{setLabel('pages.realTime.title')}</Typography> */}
                            <SelectSimpleEntity
                                disableClearable
                                label={'Set de datos'}
                                defaultValue={sensoringStore?.selectedViewType}
                                entityList={sensoringStore?.viewTypes}
                                showTitle='label'
                                onChange={setViewType}
                                complete={true}
                                width={'200px'}
                            />

                          {/*   <FlexContainer>
                                <SensorFilters type='filter' handleChange={(value)=>console.log(value)}/>
                                <SensorFilters type='order' handleChange={(value)=>console.log(value)}/>
                            </FlexContainer> */}
                        
                        </FlexContainer>
                        {ButtonMode}
                    </FlexContainer>
                </Paper>
            </FlexContainer>
        </>

    return (

            <ViewMode toolbar={(value) => Toolbar(value)}>
                {(sensoringStore?.selectedViewType.value === sensoringViewTypesValues.equipments) &&
                    <EquipmentsCardGrid  {...{tagFP}} />
                }
                {(sensoringStore?.selectedViewType.value === sensoringViewTypesValues.sensoring) &&
                    <SensorsCardGrid {...{tagFP}} />
                }
            </ViewMode>
    
   );
}

export default React.memo(RealtimeSensoringPage);
