import React from 'react';
import { useForm } from 'react-hook-form';
import SelectSimpleEntity from '../../components/select/SelectSimpleEntity';
import { useFactoriesByUser } from './services/service.factory';
import { useEffect } from 'react';
import { FactoryInterface } from './services/service.factory';
import { CircularProgress } from '@material-ui/core';


interface Props{
    onChange:(item:FactoryInterface)=>void
    label:string
    value:string
}

const SelectFactory:React.FC<Props> = ({label, onChange, value}) => {

    const key = 'factory'
    const factories = useFactoriesByUser()
    const {watch,setValue,register} = useForm()
    const selection = watch(key)

    useEffect(()=>{
        if(factories.data){
            console.log(key,factories.data[0].tagF)
        }
    },[factories.data])

    useEffect(()=>{
        if(!!selection){
            const result:FactoryInterface = factories?.data?.find((f:FactoryInterface)=> f.tagF === selection)
            onChange(result)
        } 
    },[selection])

    useEffect(()=>{
        register({name:key})
        value && setValue(key,value)
    },[])

    return (
        (factories?.status === 'loading') 
        ? <CircularProgress size='24px' /> 
        :   <SelectSimpleEntity
            width='250px'
            label={label}
            showTitle={'tagF'}
            entityList={factories?.data}
            /* children={<>
                <ModalButton icon='add' popoverTitle='Crear una nueva fábrica' >
                    <CreateFactoryFormRefactor color='red' />
                </ModalButton>
            </>} */
            onChange={(value:string)=>setValue(key,value)}
            defaultValue={watch(key)}
            />
    );
}

export default React.memo(SelectFactory);
