import React, { useEffect, useState } from 'react';
import '../../assets/css/EaseInOut.css'


const EaseInOut:React.FC<{children:React.ReactNode}> = ({children}) => {

    const [animation,setanimation] = useState('animation')

    return (
        <div className={animation}>
            {children}
        </div>
    );
}

export default EaseInOut;
