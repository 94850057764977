
import axios, {AxiosRequestConfig} from 'axios'
import { useEffect, useState } from 'react';
import { MutationResult, queryCache, QueryResult, QueryStatus, useMutation, useQuery } from 'react-query';
import { GridModelInterface, MutationQueryObject } from '../../../infrastructure/Interfaces';
import { useEquipmentsByLubricant, EquipmentInterface } from '../../equipments/services/service.lubricationPoints';
import { TypeInterface } from '../../types/services/service.types';
import { useToken } from '../../session/service.session';
import { URL } from '../../../infrastructure/appConfig/appConfig';
import { useAllAuxByPlant } from '../../auxiliar-data/service.aux';
import { useOperatorSupervisorTagFP } from '../../persons/services/service.person';
import { ContainersInterface } from '../../containers/types/ContainersInterfaces';
import { useContainersByLubricantTypeAndLubricant } from '../../containers/services/service.containers';
import { useStatusProcessor } from '../../errors/services/StatusProcessor';

//----------------------------
//interfaces
//----------------------------



export interface LubricantInterface {
    id:number
    lubricant:string 
    type:string 
    measureUnit:string
    requiresObservation:boolean
    brand:string 
    tagFP:string 
}

export const gridModelLubricants:GridModelInterface = {
    id:{label:'Id'}, 
    type:{label:'Tipo'},
    brand:{label:'Marca'}, 
    lubricant:{label:'Nombre'}, 
    measureUnit:{label:'Unidad'}, 
    tagFP:{label:'Planta'}, 
    date:{label:'Fecha'}, 
}

//----------------------------
//Axios
//----------------------------


export const CreateLubricant = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateLubricant`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const UpdateLubricant = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateLubricant`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}
export const DeleteLubricant = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/DeleteLubricant`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const getAllLubricants = (_:string,tagFP:string,token:string) => {
    axios.defaults.headers.get['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/AllLubricans`,
        data:{
            tagFP
        }
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const getLubricantsByType = (_:string,type:TypeInterface,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/LubricantByType`,
        data:{
            tagFP:type.tagFP,
            name:type.name
        }
    }

    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}


//----------------------------
//react-query
//---------------------------------------------

export const useAllLubricants = () => {
    const token= useToken()
    return useQuery(['Lubricants',token],getAllLubricants)
}

export const useAllLubricantsByPlant = (tagFP?:string) => {
    
    const SupOpTagFP = useOperatorSupervisorTagFP()
    const query = useAllAuxByPlant(tagFP || SupOpTagFP)
    const result:QueryResult<LubricantInterface[]> = {
        ...query,
        data:query.data?.lubricants
    }

   return result
}

export const useLubricantsByType = (type:TypeInterface | {} | undefined ) => {
    const token= useToken()
    return useQuery(['LubricantsByType',type,token],getLubricantsByType,{
        enabled:!!type
    })
}


//------------------------------
//MUTATIONS


export const useCreateLubricant = () :[(data:LubricantInterface)=> Promise<any>,MutationResult<any> | any] =>{
    const token = useToken()
    const [mutation,data] = useMutation(CreateLubricant,{
        onSuccess:()=>{
            queryCache.invalidateQueries('PlantAux')
            queryCache.invalidateQueries('Lubricants')
        }
    })

    const createLubricant = (data:LubricantInterface) =>{
        return mutation({
            data,
            token
        })
    }

    const status = useStatusProcessor(data)

    return [createLubricant,{...data,...status}]
}

export const useUpdateLubricant = () :[(data:LubricantInterface)=> Promise<any>,MutationResult<any> | any] =>{
    const token = useToken()

    const [mutation,data] = useMutation(UpdateLubricant,{
        onSuccess:()=>{
            queryCache.invalidateQueries('PlantAux')
            queryCache.invalidateQueries('Lubricants')
        }
    })

    const updateLubricant = (data:LubricantInterface) =>{
        return mutation({
            data,
            token
        })
    }

    const status = useStatusProcessor(data)

    return [updateLubricant,{...data,...status}]
}

export const useDeleteLubricant = () :[(data:LubricantInterface)=> Promise<any>,MutationResult<any>] =>{

    const token = useToken()

    const[mutation,data] = useMutation(DeleteLubricant,{
        onSuccess:()=>{
            queryCache.invalidateQueries('PlantAux')
            queryCache.invalidateQueries('Lubricants')
        }
    })

    const deleteLubricant = (data:LubricantInterface) =>{
        return mutation({
            data,
            token
        })
    }

   

    return [deleteLubricant,{...data}]
}


interface DeleteLubricantErrors {
    equipments?:EquipmentInterface[] | undefined | null
    containers?:ContainersInterface[] | undefined | null
}




export const useDeleteLubricantVerification = () => {

    const [deleteItem,{ status:deleteStatus, data}] = useDeleteLubricant()
    const [status, setStatus] = useState<QueryStatus>(QueryStatus.Idle)
    const [validationElement,setValidationElement] = useState<LubricantInterface | undefined >()
    //validations
    const {data:equipmentsWithLubricants,status:equipmentsWithLubricantsStatus} = useEquipmentsByLubricant(validationElement)
    const {data:containersWithLubricants,status:containersWithLubricantsStatus} = useContainersByLubricantTypeAndLubricant(validationElement)
    //errorStatus
    const [errors, setErrors] = useState<DeleteLubricantErrors>({})

    const validate = (item:LubricantInterface) => {
        setStatus(QueryStatus.Loading)
        if(!equipmentsWithLubricants && !containersWithLubricants)setValidationElement(item)
        else handleDelete()
    }

    const handleDelete= () =>{
        if((equipmentsWithLubricantsStatus === 'success') && (containersWithLubricantsStatus === 'success')){
            if((equipmentsWithLubricants?.length > 0) || (containersWithLubricants?.lenght > 0)) {
                setErrors({
                    equipments:equipmentsWithLubricants,
                    containers:containersWithLubricants
                })
                setStatus(QueryStatus.Error)
                setValidationElement(undefined)
            }   
            else{
                if(validationElement){
                    deleteItem(validationElement)
                    .then(data=>{
                        setValidationElement(undefined)
                        setStatus(QueryStatus.Success)
                    })
                    .catch(err=>console.log(err))
                }
            }
        }
    }
    
    useEffect(() => {
        handleDelete()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[equipmentsWithLubricantsStatus,containersWithLubricantsStatus])

    return  {
        errors,
        status,
        validate,
        data
    }
}

