
import { makeStyles } from '@material-ui/core';
import { cssDrawerAtributesInterface } from '../types/TgdDrawerTypes';



const backgroud = (props:cssDrawerAtributesInterface) => {

    const { backgroundColor, backgroundImg } = props

    return{
        backgroundColor:backgroundColor,
        backgroundImage:backgroundImg ? `url(${props.backgroundImg})` : undefined,
        backgroundSize:backgroundImg ? 'cover' : undefined,
        backgroundPosition: backgroundImg ? 'center' : undefined,
        overflow: backgroundImg ? 'hidden' : undefined
    }
}

export const useTgdDrawerStyles = makeStyles((theme) => ({
    drawer: {
        width: (props:any)=>props.drawerWidth,
        flexShrink: 0,
        zIndex:0
    },
    drawerPaper:{
        width: (props:any)=>props.drawerWidth,
        color:(props:any)=>props.color || '#f3f3f3',
        border:'none',
        ...backgroud
    },
}))