import React, { useMemo, useState } from 'react';
import { Button, Icon, List, Divider, TextField } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { LubricationPointInterface } from '../../../equipments/services/service.lubricationPoints';
import { usePerson } from '../../../persons/services/service.person';
import { useForm } from 'react-hook-form';
import { errorsLog, suppliesToString } from '../../../../utils/utils';
import { useSetStartedRoute } from '../routes/services/colectorServiceRoutes';
import FlexContainer from '../../../../components/presentational/FlexContainer';
import DoNotDoButton from '../../../../components/buttons/DoNotDoButton';
import { useLubricationPointSurveyController } from './controllers/useLubricationPointSurveyController';
import ClickableForm from '../../ClickableForm';
import { TypeFormRegex } from '../../../../infrastructure/regex/regexForms';
import CreateObservation from '../observations/AddObservation';
import TgdFab from '../../../../components/buttons/TgdFab';
import { SurveyTasksValues, TaskType } from '../tasks/interfaces/taskInterfaces';
import { useEffect } from 'react';
import CustomButton from '../../../../components/buttons/CustomButton';
import { useDate } from '../../../../infrastructure/date/useDate';
import { ObservationInterface } from '../../../observations/types/ObservationTypes';

const SurveyInspection:React.FC<{item:LubricationPointInterface, onFinish:()=>void,taskType:TaskType,outOfRoute:boolean}> = ({outOfRoute,taskType,item,onFinish}) => {

    //SURVEY DATA
    const {nowDay,nowMonth,nowYear} = useDate()
    const {data:person} = usePerson()
    const startTime = new Date().toISOString()

    const { survey, backTaskStep, setInaccessible, nextTaskStep, setNewComponent, setNeedsLubrication, setLeak, setLabelReplacement, setTemperature, createSurvey, updateEquipmentInfo, createObservation } = useLubricationPointSurveyController(item,outOfRoute)

    const inaccessible= survey?.inspection?.inaccessible
    const newComponent = survey?.inspection?.newComponent
    const needsLubrication = survey?.inspection?.needsLubrication
    const leak = survey?.inspection?.leak
    const labelReplacement = survey?.inspection?.labelReplacement
    const temperature = survey?.inspection?.temperature
    const anomalies = survey?.anomalies || []
    const observations = survey?.observations || []
    const supplies = survey?.neededSupplies || []
    const currentEquipmentinfoSupplies = item.info?.supplies?.filter(s=> s !== 'SIN INSUMOS') || []


    //APLICATION STATE
    const [step,setStep] = useState<any>({})
    const {register,handleSubmit,errors} = useForm()

    useEffect(()=>{
        register({name:'newComponent',value:newComponent})
        register({name:'needsLubrication',value:needsLubrication},{
            required:{
                value:(needsLubrication === undefined),
                message:'Requerido'
            }
        })
        register({name:'leak',value:leak})
        register({name:'labelReplacement', value:labelReplacement})
        register({name:'temperature', value:temperature})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[newComponent, needsLubrication, leak, labelReplacement, temperature])

    errorsLog(errors)    

    //ACTIONS
    const [startRoute] = useSetStartedRoute()


    //APLICATION STATE ACTIONS
    const handleFinish = () => {
        if(survey?.inspection.inaccessible){
            return
        }else{
            onFinish()
        }
    }

    //COMPONENT METHODS
    const handleAccesibility = (accesibility:boolean) => {
        //SetAtom inaccesible to proced on survey process
        setInaccessible(accesibility)
        //startRoute to set pending equipments and started routes 
        if(item.routeName){
            startRoute({
                date:`${nowYear}-${nowMonth}-${nowDay}`,
                tagFP:item.tagFP,
                route:item.routeName
            })
        }
    }

    const submit = () =>{
       nextTaskStep(taskType)
    }

    const handleInaccesiblePoint = () =>{
        createSurvey({
            taskTime:'0',
            taskDone:false,
            impossibleToLubricate:false,
            needsLubrication:false,
            inaccessible:true,
            lubricates:false,
            lubricantType:item.lubricantType,
            lubricant:item.lubricant,
            taskType:SurveyTasksValues.lubrication,
            sampleExtraction:false,
            startTime:startTime,
            endTime:new Date().toISOString(),
            tagTGD:item.tagTGD,
            equipment:item.equipment,
            sector:item.sector,
            measureUnit:item.measureUnit,
            tagFP:item.tagFP,
            review:item.review,
            criticality:item.criticality,
            manualRegistration:true,             
            outOfRoute:outOfRoute || false,
            route:outOfRoute ? '' : item?.routeName!,
            routeDate:new Date().toISOString(),
            quantity:0, 
            reason:'', 
            cleaning:false, 
            consumables:JSON.stringify(suppliesToString(supplies))
        })
        .then(({data})=>{
            updateEquipmentInfo({
                ...item.info,
                ...survey?.inspection,
                //Data To Modify
                tagTGD:data.tagTGD,
                tagFP:data.tagFP,
                temperature:data.temperature,
                lastSurveyId:data.id,
                updatedToday:true, 
                user:data.user,
                lastInspectionDate:new Date().toISOString(),
                inaccessible:data.inaccessible,
                labelReplacement:!item?.info?.labelReplacement,
                updateReview:!item?.info?.updateReview, 

                //Inmutable data
                supplies:currentEquipmentinfoSupplies.length === 0 ? ["SIN INSUMOS"] : currentEquipmentinfoSupplies,
                lubricantQuantity:item?.info?.lubricantQuantity!,
                leak:item?.info?.leak!,
                assetCriticality:item?.info?.assetCriticality!,
                lastLubrication:item?.info?.lastLubrication!,

            })
            createObservation([
                ...anomalies.map((o:ObservationInterface)=>({...o,surveyId:data.id})),
                ...observations.map((o:ObservationInterface)=>({...o,surveyId:data.id})),
            ])
        })
        .then(res=>{
            nextTaskStep(taskType)
            handleFinish()
        })
        .catch(err=> console.log(err))
    }

   errorsLog(errors)
   
    return(<>

        {<div>
            {outOfRoute 
                ?   <CustomButton 
                    action={()=>{
                        backTaskStep(taskType)    
                    }} 
                    icon='before' 
                    variant='iconButton' 
                    title='Atras' />
            
                : inaccessible !== undefined &&
                    <CustomButton 
                    action={()=>{
                        backTaskStep(taskType)    
                    }} 
                    icon='before' 
                    variant='iconButton' 
                    title='Atras' />

            }

        </div>}
        
        {(inaccessible === undefined) && 
        <FlexContainer justify='center' align='center' height='100%' width='100%'>
                <DoNotDoButton 
                    action={()=>handleAccesibility(true)} 
                    variant='close' 
                    title='Inaccesible'
                    />
                <DoNotDoButton 
                    action={()=>handleAccesibility(false)} 
                    variant='check' 
                    title='Accesible'
                    />
        </FlexContainer>}

        {(inaccessible === false) && <>
            <List> 
                <ClickableForm
                    title={'Cambio de Componente'}
                    value={newComponent}
                    handleValue={(item)=>{
                        setNewComponent(item)
                    }}
                //validationError={errors.newComponent}
                />
                <ClickableForm 
                    title={'¿Necesita lubricante?'}
                    value={needsLubrication}
                    handleValue={(item)=>{
                        setNeedsLubrication(item)
                    }}
                    validationError={errors.needsLubrication}
                />
                <ClickableForm 
                    title={'¿Se identifica Pérdida?'}
                    value={leak}
                    handleValue={(item)=>{
                        setLeak(item)
                    }}
                    validationError={errors.leak}
                />

                <ClickableForm 
                    title={'Reemplazar Etiqueta'}
                    value={labelReplacement}
                    handleValue={(item)=>{
                        setLabelReplacement(item)
                    }}
                //validationError={errors.needsLubrication}
                />
                <br/>

                <FlexContainer padding='0px 16px'justify ='space-between' >
                    <TextField
                        name='temperature'
                        value={temperature}
                        inputRef={register({
                            pattern:{
                                value:TypeFormRegex.float,
                                message:'Numeros/Decimales con punto'
                            }
                        })}
                        variant='outlined'
                        size='small'
                        color={errors.temperature?.message ? 'primary' :'secondary'}
                        label={errors.temperature?.message || 'Temperatura'}
                        onChange={(e)=>setTemperature(e.target.value)}
                    />
                </FlexContainer>
                <br/>
            </List>
            <Divider/>    
            <br/>
            <FlexContainer  width='100%' align='center' justify='flex-end'>
                {(!step?.anomaly && (anomalies?.length === 0))&& <Button size='small' variant='contained' color='secondary'  onClick={()=>setStep({anomaly:'Sin Anomalías'})}>
                    <Icon >close</Icon> Sin anomalía
                </Button>}
                <CreateObservation
                    outOfRoute={outOfRoute}
                   /*  handleDelete={handleDeleteObservationAnomaly}
                    onChange={setAnomalies}
                    defaultState={anomalies} */
                    taskType = {SurveyTasksValues.lubrication}
                    lubricationPoint={item}
                    type='ANOMALIA' 
                    buttonTitle='Anomalías'
                    formTitle='Nueva anomalía'
                    variant={((step?.anomaly) || (anomalies && anomalies.length > 0)) ? 'list' : 'modal'} 
                    listTitle={(step?.anomaly) || 'Anomalías'}
                    buttonType='add'
                />
            </FlexContainer>
                
            <TgdFab
                disabled={!(step?.anomaly || (anomalies?.length !== 0))}
                bottom={3}
                right={4.5}
                color='primary' 
                onClick={handleSubmit(submit)}
                children={<ArrowForwardIcon/>}
            />
                
        </>}


        {(inaccessible === true) && <>
            <CreateObservation
                outOfRoute={outOfRoute}
                taskType = {SurveyTasksValues.lubrication}
                type='INACCESIBLE' 
                lubricationPoint={item}
                listTitle='Observaciones'
                variant='content' 
                formTitle='¿Porqué el equipo estaba inaccesible?'
                buttonType='add'
            />
            
            <TgdFab
                disabled={!(observations && observations.length > 0)}
                bottom={4}
                right={4}
                color='primary' 
                onClick={handleInaccesiblePoint}
                children={<ArrowForwardIcon/>}
            />
            
        </>}
    </>)
}

export default React.memo(SurveyInspection);
