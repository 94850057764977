
import React from 'react'
import { PlantInterface } from '../../plants/service.plant';
import { useState } from 'react';
import { useEquipmentsByTagFP } from '../../equipments/services/service.lubricationPoints';
import { useAssignMultipleRoutesToMultipleEquipments, useRoutesByTagFP } from '../services/service.routes';

import TgdSelectableTable from '../../../components/grids/TgdSelectableTable';
import { Alert } from '@material-ui/lab';
import { GridColumns } from '@material-ui/data-grid';
import CustomButton from '../../../components/buttons/CustomButton';
import ComponentMode from '../../../components/ComponentMode';
import FlexContainer from '../../../components/presentational/FlexContainer';
import { useModal } from '../../../utils/hooks/useModal';

export const equipmentColumns:GridColumns = [
    {headerName:'Tag TGD',field:'tagTGD',width:170},
    {headerName:'Sector',field:'sector',width:170},
    {headerName:'Tag Planta',field:'plantTag',width:170},
    {headerName:'Equipo',field:'equipment',width:170},
    {headerName:'Tipo',field:'type',width:170},
    {headerName:'Criticidad',field:'criticality',width:170},
]

const AssignCancelMultipleRoutes:React.FC<{plant:PlantInterface}> = ({plant}) => {

    const [step,setStep] = useState(1)
    const {modal,handleModal} = useModal()
    const {data:equipments} = useEquipmentsByTagFP(plant.tagFP)
    const {data:routes} = useRoutesByTagFP(plant.tagFP)
    const [selectedEquipments, setSelectedEquipments ] = useState([])
    const [selectedRoutes, setSelectedRoutes ] = useState([])

    const { handleAssigCancel,status,reset } = useAssignMultipleRoutesToMultipleEquipments()

    const routeColumns:GridColumns= [
        {headerName:'ID',field:'id'},
        {headerName:'Operador',field:'operator'},
        {headerName:'Periodicidad',field:'periodicity'},
        {headerName:'Ruta',field:'route'},
        {headerName:'Nombre',field:'routeName'},
        {headerName:'Fecha de inicio',field:'startDate',width:170}
    ]
    
    const handleAssign = () =>{
        handleAssigCancel(selectedEquipments,selectedRoutes,'A')  
    }
    
    const handleReset = () => {
        reset()
        setSelectedEquipments([])
        setSelectedRoutes([])
        setStep(1)
    }

    const handleClose = () => {
        handleReset()
        handleModal()
    }

    return(
        <ComponentMode icon='map' popoverTitle='Asignar múltiples rutas a múltiples equipos'>

            {status === 'error' && <><Alert severity='error'>Ha ocurrido un error</Alert></>}
            {status === 'success' && <><Alert>Asignaciones realizadas</Alert></>}
            {step === 1 &&
                <TgdSelectableTable
                    title='Seleccionar equipos'
                    items={equipments} 
                    columns={equipmentColumns}
                    headerActions={<CustomButton icon='next'popoverTitle='Próximo paso' {...(selectedEquipments?.length > 0) ? {disabled:false} : {disabled:true}}  action={()=>setStep(2)}/>}
                    handleChange={(items)=>setSelectedEquipments(items)}
                />
            }
               
            {step === 2 && 
                <TgdSelectableTable
                    title='Rutas a asignar'
                    items={routes} 
                    columns={routeColumns}
                    headerActions={<FlexContainer>
                    <CustomButton icon='before' popoverTitle='Atras(resetear)' action={handleReset}></CustomButton>
                    <CustomButton icon='save' popoverTitle='Guardar asignaciones' action={handleAssign} {...(selectedRoutes?.length > 0) ? {disabled:false} : {disabled:true}} status={status} ></CustomButton>
                    </FlexContainer>}
                    handleChange={(items)=>setSelectedRoutes(items)}
                />

            }

        </ComponentMode>)
}
export default React.memo(AssignCancelMultipleRoutes)