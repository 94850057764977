import React from 'react';
//components
import AdminContent from '../../pages/rolePages/AdminContent';
import SuperAdminContent from '../../pages/rolePages/SuperAdminContent';
import AccountManagerContent from '../../pages/rolePages/AccountManagerContent';
import SupervisorContent from '../../pages/rolePages/SupervisorContent';
import OperatorContent from '../../pages/rolePages/OperatorContent';
import DashLayout from './DashLayout'
import Loading from '../../components/presentational/Loading';
import ClientContent from '../../pages/rolePages/ClientContent';

//Router
import {Redirect,Route,  Switch} from 'react-router-dom'

//services
import { useUser, useDimention } from '../../domains/persons/services/service.person';
import { USER_ROUTES} from '../../infrastructure/routes/routes';
import { usePbiAccessToken } from '../../domains/powerBi/service.pbi';

import { useActivityLog } from '../../domains/session/service.session';

import SimpleSnackbar from '../../components/snackbar/SimpleSnackbar'
import OnlineOfflineMode from '../../infrastructure/online/offline Mode/OnlineOfflineMode';
import TgdModal from '../../components/modals/TgdModal';
import LogOutButton from '../../domains/login/LogOutButton';
import { Alert } from '@material-ui/lab';



const UserRole:React.FC = () => {
    
    const {updater} = useActivityLog()

    const {data:user, status:userStatus} = useUser()
    const {data:aadToken, status:pbiTokenstatus} = usePbiAccessToken()
    const dimention = useDimention()

    if((userStatus !== 'success') || (pbiTokenstatus === 'loading') ) return <><Loading/></>
    //if(!user || !aadToken ) return <TgdModal open={true}  fullWidth={true} defaultButton={<LogOutButton title={'reintentar'}width={'240px'}/>}><Alert severity={'warning'}>Estamos mejorando Tgd Soft! Disculpe las molestias. Vuelva a intentarlo en unos minutos</Alert></TgdModal>
    if(user && (user?.type !== 'C') /* && aadToken  */) {
        return(
            <div onClick={updater}>
                <OnlineOfflineMode warning={false}>
                    <SimpleSnackbar status={!!aadToken} message='no se pudo establecer una conexión con los servidores de Microsoft, si esta en una version local de tgd soft omita este mensaje' />
                </OnlineOfflineMode>
                <Redirect to={`/${USER_ROUTES.home}/${dimention}/`}/>
                <Switch >
                    <DashLayout>
                        <Switch>
                            <Route path={`/${USER_ROUTES.home}/${USER_ROUTES.superAdmin}`}><SuperAdminContent dimention={dimention} /></Route>{/* SA */}
                            <Route path={`/${USER_ROUTES.home}/${USER_ROUTES.administrator}`}><AdminContent dimention={dimention} /></Route>{/* A */}
                            <Route path={`/${USER_ROUTES.home}/${USER_ROUTES.accountManager}`}><AccountManagerContent dimention={dimention} /></Route>{/* AC */}
                            <Route path={`/${USER_ROUTES.home}/${USER_ROUTES.engineer}`}><SupervisorContent dimention={dimention}/></Route>{/* I */}
                            <Route path={`/${USER_ROUTES.home}/${USER_ROUTES.operator}/`}><OperatorContent dimention={dimention}/></Route>{/* O */}
                        </Switch>
                    </DashLayout>
                </Switch>
            </div>
        )
    }
    if(!user || !aadToken ) return <TgdModal open={true}  fullWidth={true} defaultButton={<LogOutButton title={'reintentar'}width={'240px'}/>}><Alert severity={'warning'}>Estamos mejorando Tgd Soft! Disculpe las molestias. Vuelva a intentarlo en unos minutos</Alert></TgdModal>
    if(user && (user?.type === 'C') && aadToken){
        return (
            <>
                <Redirect to={USER_ROUTES.home}/>
                <ClientContent/>
            </>
        )
    }
    return <><Loading /> </>
           
    
}

export default React.memo(UserRole);