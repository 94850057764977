import { Paper } from '@material-ui/core'
import React from 'react'
import UpdatePersonForm from '../domains/persons/UpdatePersonForm'
import TgdListToolbar from '../components/lists/TgdListToolbar'
import PageContainer from '../components/pageContainers/PageContainer'
import { usePerson, useUser } from '../domains/persons/services/service.person'

const ConfigPage:React.FC = () => {
    const {data:person} = usePerson()

    return(
        person ?
        <PageContainer>
            <Paper>
                <TgdListToolbar 
                    title={'Configuraciones de la cuenta'}
                    />
                <PageContainer>
                    <UpdatePersonForm person={person}/>
                </PageContainer>
            </Paper>
        </PageContainer>
        :null
    )
}

export default React.memo(ConfigPage)