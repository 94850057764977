import React, { useEffect, useState } from 'react';
import ModalButton from '../../components/buttons/ModalButton';
import { useForm, Controller } from 'react-hook-form';
import { StockInterface, useUpdateStock } from './services/service.stock';
import { Typography, TextField } from '@material-ui/core';
import CustomButton from '../../components/buttons/CustomButton';
import FlexContainer from '../../components/presentational/FlexContainer';
import theme from '../../infrastructure/materialUi/themeConfig';
import StatusAndMessage from '../errors/StatusAndMessage';
/* import SelectType from '../types/molecules/SelectType';
 */import SelectArea from '../areas/molecules/SelectArea';
import SelectSimpleEntity from '../../components/select/SelectSimpleEntity';
import { useStatusProcessor } from '../errors/services/StatusProcessor';
import SelectTypeBy from '../types/molecules/SelectTypeBy';
import { enumTgdTypes } from '../types/Types/types';
import { ComponentUIModes } from '../../components/ComponentUIModes';



const SelectStockComplexity:React.FC<{
    handleChange:(item:any)=>void,
    error:string,
    defaultValue?:any
}> = ({handleChange,error,defaultValue}) => {

    const [state,setState] = useState()
    const complexities = [
        {
            label:'COMPLEJO',
            value:true
        },
        {
            label:'SIMPLE',
            value:false
        }
    ]

    const selecteditem = complexities.filter((item) => item.label === state)[0]

    useEffect(()=>{
        if(state){
           handleChange(selecteditem.value) 
        }
    },[state])

    return(
        <SelectSimpleEntity
            onChange={(value:any)=> setState(value)}
            label={error ||'abastecimiento'}
            entityList={complexities}
            showTitle={'label'}
            color={error ? 'primary' : 'secondary'}
            defaultValue={defaultValue}
        />
    )
}




interface Props{
    mode?:ComponentUIModes
    defaultValue:StockInterface
}

const UpdateStockForm:React.FC<Props> = ({mode,defaultValue}) => {
    
    const tagFP = defaultValue?.tagFP
    const componentMode = mode || 'modal'
    const [updateStock,updateResult] = useUpdateStock()
    const { status, message, error } = useStatusProcessor(updateResult)
    const {register,setValue,errors,watch,handleSubmit,control} = useForm()
    const title='Editar item de stock'

    useEffect(()=>{
        defaultValue &&
        Object.entries(defaultValue).map(([key,value]:[string,any])=>{
            register(key,{required:{
                value:!!value,
                message:'Campo Requerido'
            }})
            setValue(key,value)
        })
    },[defaultValue])

    const submit = (item:StockInterface) => {
        updateStock({
            ...item
        })
    }

    const content=<> 
        <Typography variant='h6'>{componentMode === 'content' && title}</Typography>
        <StatusAndMessage status={status} message={message} error={error} />
        <FlexContainer padding={`${theme.spacing(3)}px 0px`} flexDirection={'column'}>
            
                <TextField
                inputRef={register()}
                name='product'
                size='small'
                variant='outlined'
                defaultValue={watch('product')}
                label={errors?.product?.message || 'Nombre del producto'}
                color={errors?.product?.message ? 'primary' : 'secondary'}
                />

                <TextField
                inputRef={register()}
                name='brand'
                size='small'
                variant='outlined'
                label={errors?.brand?.message || 'Marca'}
                color={errors?.brand?.message ? 'primary' : 'secondary'}
                defaultValue={watch('brand')}
                />
                
                <TextField
                inputRef={register()}
                defaultValue={watch('actualStock')}
                name='actualStock'
                size='small'
                variant='outlined'
                label={errors?.actualStock?.message || 'Stock actual'}
                color={errors?.actualStock?.message ? 'primary' : 'secondary'}
                />

                <TextField
                inputRef={register()}
                name='minimumRequired'
                defaultValue={watch('minimumRequired')}
                size='small'
                variant='outlined'
                label={errors?.minimumRequired?.message || 'Cantidad mínima'}
                color={errors?.minimumRequired?.message ? 'primary' : 'secondary'}
                />

                <Controller
                    as={<SelectTypeBy
                        error={errors?.measureUnit?.message}
                        by={{key:'type',value:enumTgdTypes.unity}}
                        tagFP={tagFP}
                        onChange={(value)=>setValue('measureUnit',value)}
                        defaultValue={watch('measureUnit')}
                        />}
                    name='measureUnit'
                    defaultValue={watch('measureUnit')}
                    rules={{required:{value:true,message:'Campo Obligatorio'}}}
                    control={control}
                />

                <Controller
                    as={<SelectArea
                        error={errors?.providerArea?.message }
                        tagFP={tagFP}
                        onChange={(value:any) => setValue('providerArea',value)}
                        defaultValue={watch('providerArea')}
                        />}
                    name='providerArea'
                    defaultValue={watch('providerArea')} 
                    rules={{required:{value:true,message:'Campo Obligatorio'}}}
                    control={control}
                />

                <Controller
                    as={<SelectStockComplexity
                        handleChange={(value:any) => setValue('complex',value)}
                        error={errors?.complex?.message}
                        defaultValue={watch('complex') ? 'COMPLEJO' : 'SIMPLE'}
                    />}
                    name='complex'
                    control={control}
                />

                <CustomButton status={status} action={handleSubmit(submit)}>Guardar</CustomButton>
            
        </FlexContainer>
    </>
    
    return (<>

        {componentMode === 'content' && content}
    
        {componentMode === 'modal' && <ModalButton icon='edit' color='primary' variant='iconButton' title={title} >
            {content}
        </ModalButton>}
    </>);
}

export default React.memo(UpdateStockForm);
