import React, { useEffect, useState } from 'react';
import DefaultFormLayout from '../../../components/forms/DefaultFormLayout';
import { IotDeviceInterface } from './interfaces/IotDeviceInterfaces';
import {  useArchiveIotDevice } from './services/IotDeviceService';
import { useIotForm } from './services/useIotForm';
import FormSelector from '../../form-config/FormSelector';
import { useLangLabels } from '../../language/services/useSetLabel';

interface Props{
    item:IotDeviceInterface
    tagFP:string
}

const DesactivateActivateIoTDeviceForm:React.FC<Props> = ({item,tagFP}) => {


    const { lang } = useLangLabels()

    const actionType = 'desactivate'

   

   const {form,handleSubmit,setValue,errors} = useIotForm({
        actionType,
        defaultValue:{
            ...item,
            lowReason:''
        }
    },item.tagFP)

    const [lowReason,setLowReason] = useState('')
    const active = item.state

    const { archiveUnArchive ,status, message, error,  } = useArchiveIotDevice()

    const submit = (data:IotDeviceInterface)=>{
        archiveUnArchive(data)
    }

    useEffect(()=>{
        lowReason && setValue('lowReason',lowReason)
    },[lowReason])

    const title = active 
    ? lang.iotDevices.actions.desactivate 
    : lang.iotDevices.actions.activate 

    return (
        <DefaultFormLayout 
        onChange={handleSubmit(submit)}
        buttonTitle={title} 
        popoverTitle={title}
        status={status}
        message={message}
        error={error}
        variant='iconButton' 
        icon={active ? 'delete' : 'power' }
        >
             {form.fields?.map(field=>
                    <FormSelector
                        key={field.name}
                        selector={field.formSelector}
                        fieldProps={{
                            type:field.type,
                            error:errors && errors[field.name]?.message,
                            label:field.label,
                            value:lowReason,
                            onChange:(value)=>setLowReason(value),
                            tagFP
                        }}
                    />    
                )}

        </DefaultFormLayout> 
        );
       
}

export default DesactivateActivateIoTDeviceForm
