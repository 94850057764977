import React from 'react';
import { QueryStatus } from 'react-query';
import DefaultFormLayout from '../../../components/forms/DefaultFormLayout';
import { useIotForm } from './services/useIotForm';
import { TextField } from '@material-ui/core';
import { FieldValues } from 'react-hook-form';
import FormSelector from '../../form-config/FormSelector';
import { useCreateIotDevice } from './services/IotDeviceService';
import { IotDeviceInterface } from './interfaces/IotDeviceInterfaces';




const CreateIotDeviceForm:React.FC<{tagFP:string}> = ({tagFP}) => {

    const {setValue,handleSubmit,watch,errors,form} = useIotForm({actionType:'create'},tagFP)
    const {createIotDevice,data} = useCreateIotDevice()
    
    const submit = (fields:IotDeviceInterface) => {
        createIotDevice(fields)
    }

    return (
        <DefaultFormLayout 
        status={data.status}
        message={data.message}
        error={data.error} 
        icon={'add'} 
        buttonTitle={form.name} 
        popoverTitle={form.name} 
        onChange={handleSubmit(submit)} >
            {form.fields?.map(field=>
                <FormSelector
                    key={field.name}
                    selector={field.formSelector}
                    fieldProps={{
                        type:field.type,
                        error:errors && errors[field.name]?.message,
                        label:field.label,
                        value:watch(field.name),
                        defaultValue:watch(field.name),
                        onChange:(value)=>setValue(field.name,value),
                        tagFP
                    }}
                />    
            )}
        </DefaultFormLayout>
    );
}

export default CreateIotDeviceForm;
