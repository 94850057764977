

/* ------------------
interfaces
------------------- */

import axios, { AxiosRequestConfig } from "axios"
import { useMemo } from "react"
import { QueryResult, useQuery } from "react-query"
import { URL } from "../../../../../infrastructure/appConfig/appConfig"
import { LubricationPointInterface } from "../../../../equipments/services/service.lubricationPoints"
import { useOperatorSupervisorTagFP } from "../../../../persons/services/service.person"
import { useDefaultQuery } from "../../../../../infrastructure/reactQuery.tsx/useDefaultQuery"
import { useToken } from "../../../../session/service.session"
import { useDailyLubricationPointsWithInfo } from "../../lubricationPoints/services/daoLubricationPoints"
import { SupplyInterface, SupplyType } from "../../../../supply/types/SupplyTypes"



/* ------------------
Axios
------------------- */

const getDailySupplies = (_:string,data:LubricationPointInterface[],tagFP:string,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.post['tagFP'] = tagFP
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/SuppliesAndLubricationPoints`,
        data
    }
    return axios(config)
    .then((res)=>{return res.data})
    .catch((err) => {return err})
}


/* ------------------
USE QUERY
------------------- */



export const useDailySupplies = (type?:SupplyType):QueryResult<SupplyInterface[]> =>{
    const token = useToken()
    const dailyOperatorLubricationPoints = useDailyLubricationPointsWithInfo()
    const tagFP = useOperatorSupervisorTagFP()

    const _dailyOperatorLubricationPoints = dailyOperatorLubricationPoints?.map(i=>({
        ...i,
        supplies:JSON.stringify(i.supplies)
    }))
    const query =  useDefaultQuery<SupplyInterface[],unknown>(['DailySupplies',_dailyOperatorLubricationPoints,tagFP,token],getDailySupplies,{
        enabled:!!tagFP && !!_dailyOperatorLubricationPoints,
    })

    return useMemo(()=>({ 
        ...query,
        data: type ? query.data?.filter((supply:any)=> (supply.supply.type === type)) : query.data
    }),[query,type])

}
