export const USER_ROUTES = {
    login:'login',
    home:'admin',
    notifications:'notificaciones',
    verification:'verification',
    superAdmin:'Super-Admin',
    administrator:'Administrador',
    accountManager:'Administrador-de-Cuentas',
    client:'Cliente',
    engineer:'Supervisor',
    operator:'Operario',
    addUser:'usuarios',
    plantSelector:'plant-selector',
    factories:'fabricas',
    plants:'plantas',
    addClient:'agregar-cliente',
    config:'configuracion',
    demoUsers:'prospectos',
    RejectedDemoUsers:'archivados',
    demoRequest:'demo',
    sensoringManagement:'gestionar-sensores-STI',
    factoryManagement:'gestionar-fábricas',
    plantManagement:'gestionar-planta',
    reports:'reportes',
    stock:'stock',
    realtimeSensors:'sensores-en-tiempo-real',
    news:'novedades',
    clientVisor:'visor-de-cliente',
    discover:'descubrir',

    //colector
    delayedPoints:'puntos-atrasados',
    dailyTools:'herramientas',
    dailyConsumables:'consumibles',
    dailyProtection:'elementos-de-proteccion',
    dailyLubricants:'lubricantes',
    usedConsumables:'consumibles',
    dailyPermissions:'permisos',
    dailyNews:'novedades',
    dailyTasks:'tareas',
    dailyRoutes:'rutas',
    DailyLubricantPoints:'activos',
    desktopDataCollect:'carga-de-datos'

}