import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import CustomButton from '../../components/buttons/CustomButton';
import StatusAndMessage from '../errors/StatusAndMessage';
import { useForm } from 'react-hook-form';
import { SubCategoryInterface, useCreateSubCategory } from './service.subcategories';
import { useStatusProcessor } from '../errors/services/StatusProcessor';
import ComponentMode from '../../components/ComponentMode';
import { ComponentUIModes } from '../../components/ComponentUIModes';


interface Props{
    tagFP:string | undefined
    mode:ComponentUIModes
    parentCategory:SubCategoryInterface
}

const CreateSubCategoryForm:React.FC<Props> = ({tagFP,mode,parentCategory}) => {
    
    const [CreateSubCategory,createResult] = useCreateSubCategory()
    const { status, message, error } = useStatusProcessor(createResult)
    const {register,setValue,errors,watch,handleSubmit} = useForm()
    const title='Nueva subcategoria'
    
    useEffect(()=>{
        register({name:'id',type:'number',value:0})
        register({name:'subcategory',value:''},{required:{value:true,message:'Campo requerido'}})
        register({name:'tagFP',value:tagFP})
        register({name:'categoryArea',type:'string',value:'LUBRICACION'})
        register({name:'category',value:parentCategory})
    })

    const submit = (item:SubCategoryInterface) => {
        CreateSubCategory(item)
    }

    return (<>
        <ComponentMode  mode={mode} title={title} >
            <StatusAndMessage status={status} message={message} error={error} />
            <TextField
                inputRef={register()}
                name='subcategory'
                size='small'
                variant='outlined'
                label={errors?.subcategory?.message || title}
                color={errors?.subcategory?.message ? 'primary' : 'secondary'}
                />
            <CustomButton status={status} action={handleSubmit(submit)}>Crear</CustomButton>
        </ComponentMode>
    </>);
}

export default React.memo(CreateSubCategoryForm);
