import React, { useEffect } from 'react';
import {  createStyles,  makeStyles, TextField } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { EquipmentFormRegex } from '../../infrastructure/regex/regexForms';
import {  useLubricantsByPlant } from '../auxiliar-data/service.aux';
import { LubricationPointInterface, useUpdateLubricationPoint } from './services/service.lubricationPoints';
import { useToken } from '../session/service.session';

import SelectSimpleEntity from '../../components/select/SelectSimpleEntity';
/* import SelectType from '../types/molecules/SelectType';
 */
import { errorsLog } from '../../utils/utils';
import { useStatusProcessor } from '../errors/services/StatusProcessor';
import DefaultFormLayout from '../../components/forms/DefaultFormLayout';
import SelectLubricant from '../lubricants/molecules/SelectLubricant';
import SelectComponent from '../components/molecules/SelectComponent';
import SelectElement from '../elements/molecules/SelectElement';
import { LubricantInterface } from './../lubricants/services/service.lubricants';
import { enumTgdTypes } from '../types/Types/types';
import SelectTypeBy from '../types/molecules/SelectTypeBy';


interface Props{
    item?:LubricationPointInterface
    color?: "inherit" | "primary" | "secondary" | "default" | undefined
}



const UpdateLubricationPoint:React.FC<Props> = ({item,color}) => {

    const _tagFP = item?.tagFP!
    const token = useToken()
    const {register,setValue,handleSubmit,watch,errors,control}= useForm()
    const [updateEquipment,data] = useUpdateLubricationPoint()
    const {status,message,error} = useStatusProcessor(data)
    const {data:lubricants} = useLubricantsByPlant(_tagFP)
    const _lubricant=lubricants?.filter((l:LubricantInterface) => l.lubricant === watch('lubricant'))[0]
    const _lubricantType = _lubricant?.type
    const _measureUnit = _lubricant?.measureUnit

    const submit = (data:LubricationPointInterface) => {
        updateEquipment({
            data:{
                ...data,
                tagFP:item?.tagFP,
                tagTGD:item?.tagTGD,
                element: item?.element,
                review: `${parseInt(item?.review || '') + 1}`  ,
                oilAnalysis:((data.oilAnalysis === 'SI' ) ? true : false),
            },
            token
        })
    }
    

    errorsLog(errors)

    useEffect(()=> {
       if(item){
            Object.entries(item).forEach(([key,value])=>
                setValue(`${key}`,value)
            );
        }
        register('lubricantType')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[item])

    useEffect(()=>{
        if(lubricants){
            
            setValue('lubricantType', _lubricantType )
            setValue('measureUnit',_measureUnit)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[_lubricant])

    
    return ( <>
       
                
            <DefaultFormLayout icon='edit' buttonTitle='Guardar' status={status} message={message} error={error}  variant='iconButton' popoverTitle='Editar punto' onChange={handleSubmit(submit)}>

                <TextField
                    inputRef={register({
                        required:{value:true,message:'obligatorio'},
                    })} 
                    name="brand" 
                    placeholder='Marca del equipo' 
                    defaultValue={watch('brand',item?.brand)}
                    variant='outlined'
                    label={`Marca del equipo ${errors?.brand?.message || ''}`} 
                    color={errors?.brand?.message  ? 'primary' : 'secondary'}
                    size="small"
                    />   

                <TextField
                    inputRef={register({
                        required:{value:true,message:'obligatorio'},
                    })} 
                    name="model" 
                    placeholder='Modelo del Equipo' 
                    defaultValue={watch('model', item?.model)}
                    variant='outlined'
                    label={`Modelo del Equipo ${errors?.model?.message || ''}`} 
                    color={errors?.model?.message ? 'primary' : 'secondary'}
                    size="small"
                    />

               <TextField
                    inputRef={register({
                        required:{value:true, message:'obligatorio'} 
                    })} 
                    label={`Observaciones ${errors?.observations?.message || ''}` } 
                    color={errors?.observations?.message ? 'primary' : 'secondary'}
                    name="observations" 
                    placeholder='¿Porqué estas editando este punto?' 
                    /* defaultValue={''} */
                    variant='outlined'
                    size="small"
                    />


                <Controller
                   as={<SelectElement
                       tagFP={_tagFP}
                       error={errors?.element?.message}
                       onChange={(value:any) => setValue('element',value)}
                       defaultValue={watch('element',item?.element)}
                       />}
                   name='element'
                   defaultValue={watch('element',item?.element)}
                   rules={{
                       required:{value:true, message:'requerido'} 
                   }}
                   control={control}
               />

              <Controller
                   as={<SelectComponent
                       tagFP={_tagFP}
                       error={errors?.component?.message}
                       onChange={(value:string)=> setValue('component',value)}
                       defaultValue={watch('component',item?.component)}
                   />}
                   name='component'
                   defaultValue={watch('component',item?.component)}
                   rules={{
                       required:{value:true,message:'requerido'},
                       //validate:{exist: () => lubricationPointVerificationArray(item.lubricationPoints || [],watch('element'),watch('component'))}
                   }}
                   control={control}
               />
               
              <Controller
                   as={<SelectLubricant
                       tagFP={_tagFP}
                       error={errors?.lubricant?.message}
                       onChange={(value:string)=>setValue('lubricant',value)}
                       defaultValue={watch('lubricant',item?.lubricant)}
                       />}
                   name='lubricant'
                   defaultValue={watch('lubricant',item?.lubricant)}
                   rules={{required:{value:true,message:'requerido'}}}
                   control={control}
               />

               <TextField
                   inputRef={register({
                       required:{value:true,message:'requerido'},
                       pattern:{value:EquipmentFormRegex.float, message:'El número no es válido'},
                   })} 

                   name="capacity" 
                   variant='outlined'
                   value={watch('capacity')}
                   defaultValue={watch('capacity',item?.capacity)}
                   label={ `Capacidad ${errors?.capacity?.message || ''}`} 
                   color={errors?.capacity?.message ? 'primary' : 'secondary'}
                   size="small"

                   />                

               <Controller
                   as={<SelectTypeBy
                    error={errors?.measureUnit?.message}
                    by={{key:'type',value:enumTgdTypes.unity}}
                    tagFP={item?.tagFP!}
                    onChange={(value)=>setValue('measureUnit',value)}
                    defaultValue={watch('measureUnit')}
                    />}
                   name='measureUnit'
                   defaultValue={watch('measureUnit',item?.measureUnit)}
                   rules={{required:{value:true,message:'Campo Obligatorio'}}}
                   control={control}
               />

               <Controller
                   as={
                       <SelectSimpleEntity
                           error={errors?.oilAnalysis?.message}
                           label={'Análisis de aceite'} 
                           entityList={[{label:'SI',value:true},{label:'NO',value:false}]}
                           showTitle={'label'}
                           onChange={(value:any)=>setValue('oilAnalysiss',value)}
                           defaultValue={watch('oilAnalysis',item?.oilAnalysis ? 'SI' : 'NO') }
                       />
                   }
                   name='oilAnalysis'
                   defaultValue={watch('oilAnalysis',item?.oilAnalysis) ? 'SI' : 'NO' }
                   rules={{required:{value:true,message:'obligatorio'}}}
                   control={control}
               />
                
            </DefaultFormLayout>
    </>);
}


export default UpdateLubricationPoint 