import React from 'react'
import { Autocomplete, AutocompleteRenderInputParams } from "@material-ui/lab";
import { useEffect, useState } from "react";
import FlexContainer from "../presentational/FlexContainer";
import { useSimpleAutocompleteProps } from "./services/useSimpleAutocompleteProps";
import { CustomIcon, CustomIconType } from '../buttons/CustomButton';
import { useIsFocusVisible } from '@material-ui/core';
import { SignalCellularOffOutlined } from '@material-ui/icons';
import { TgdTextField } from '../../domains/form-config/formComponents/TgdTextField';
import { TextField } from '@material-ui/core';


interface Props {
    items:any[],
    error?:string,
    label:string,
    defaultValue:any,
    renderOption?:(option:any)=>any
    onChange:(value?:any)=>void,
    extendInput?:boolean
    showTitle:string
    icon?:CustomIconType
    iconColor?:string
    disabled?:boolean
    helper?:string
}
const SimpleAutocompleteSelect:React.FC<Props> = ({helper, children, items,error,label,defaultValue,onChange,showTitle,icon,iconColor,extendInput,disabled,renderOption}) =>{
    
    const [ableToSetDefaultValue,setAbleToSetDefaultValue] = useState(true)
    const [extendedValue,setExtendedValue] = useState<string | null>(null)
    const [value, setValue] = useState<any | null >(null);

    const autocomplete = useSimpleAutocompleteProps<any>({
        items,
        value,
        error,
        label,
        helper,
    })

    const handleReset = () => {
        (!!!defaultValue || defaultValue === '' ) &&
        setValue(null)
        setExtendedValue(null)
    }
    
    const handleInputChange = (e:any) => {
        const inputValue = e?.target?.value || null
        if(value){
            if(extendInput){
                if(inputValue){
                    const sizetoSplit:number = value[showTitle]?.split('').length
                    const inputValueSplitted:string[] = inputValue?.split('') || []
                    const result = inputValueSplitted?.slice(sizetoSplit)?.join('')
                    setExtendedValue(result)
                }
            }   
        }else{
            if(extendInput){
                setExtendedValue(inputValue)
            }
        }
    }
    
    const handleAutocompleteChange = (value:any) => {
        setValue(value)
    }

    const handleChange = () => {
        if(value){
            if(extendInput){
                onChange(value[showTitle])
            }
            onChange(value[showTitle])
        }
    }

    const haandleDefaultValue = () => {
        if(!!defaultValue && ableToSetDefaultValue) {
            setValue(items?.find((c:any)=> 
                c[showTitle] === defaultValue) || null
            )
            setAbleToSetDefaultValue(false)
        }
    }

    useEffect(()=>{
        handleChange()
        if(extendedValue){
            setExtendedValue(null)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[value])

    useEffect(()=>{
        if(defaultValue === '' || !defaultValue || defaultValue === 0){
            handleReset()
            haandleDefaultValue()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[defaultValue])

    const onBlur = () => {
        if(extendInput){
            if(value && extendInput && extendedValue){  
                setValue({
                    ...value,
                    [showTitle]:`${value[showTitle]}${extendedValue ? ' - ' + extendedValue : ''}` 
                })
            }
            else if (!value && extendInput && extendedValue){
                setValue({
                    ...value,
                    [showTitle]:extendedValue
                })
            }
        }
    }

  /*   const input = (params:AutocompleteRenderInputParams) => <TextField
        {...params}
        {...{
            label,
            placeholder,
            helper,
            focused:error ? true : false,
            variant:'outlined',
            size:'small'
        }}
    /> */
    
    return (
        <FlexContainer style={{minWidth:'250px'}} >
            <Autocomplete
            
            {...autocomplete}
            onChange={(e,value)=>handleAutocompleteChange(value)}
            getOptionLabel={option=>option[showTitle]}
            onBlur={onBlur}
            renderOption={renderOption ? renderOption : (option)=>icon ? <CustomIcon icon={icon} style={{color:iconColor || option[showTitle]}} /> : option[showTitle]}
/*             renderInput={input}
 */            {...extendInput ? {onInputChange:handleInputChange} : null }
            disabled={disabled}
            />
            {children && children}
        </FlexContainer>
    );
}

export default SimpleAutocompleteSelect