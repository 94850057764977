
import axios,{ AxiosRequestConfig } from'axios'
import { URL } from '../appConfig/appConfig';

//services
import { AuthInterface } from '../../domains/session/service.session';



//--------------------------------------
//GET
//---------------------------------------------

export const getAuth = (data:AuthInterface) => {
    let res = axios.post(`${URL}/authenticate`, data)
    .then(res => {
        sessionStorage.setItem('token',JSON.stringify(res.data.token))
        return res.data.token
    }).catch()
    return res
}

export const  getTokenVerification = (endpoint:string,token:string) => {
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    let config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/${endpoint}`
    }

    let res = axios(config).then(res => {return res.data}).catch(res =>{return res.data})
    return res
}

export const  getAllResources = (endpoint:string,token:string) => {
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    let config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/${endpoint}`
    }
    let res = axios(config).then(res => {return res.data}).catch(res =>{return res.data})
    return res
}

export const  getAllResourcesByEmail = (endpoint:string,email:string,token:string) => {
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    let config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/${endpoint}/${email}`
    }
    let res = axios(config).then(res => {return res.data}).catch(res =>{return res.data})
    return res
}

export const getResourceBytagFP = (endpoint:string,tagFP:string,token:string) =>{

    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    axios.defaults.headers.get['tagFP'] = `${tagFP}`;
    
    let config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/${endpoint}`,
    }

    return axios(config)

}

//--------------------------------------
//POST
//---------------------------------------------






