import { makeStyles, Paper, createStyles, Typography, Divider, Tab, Tabs, } from '@material-ui/core';
import React from 'react';
import PageContainer from './pageContainers/PageContainer';
import { CustomIcon } from './buttons/CustomButton';
import { CustomIconType } from './buttons/CustomButton'

import FlexContainer from './presentational/FlexContainer';
import TgdPopover from './popovers/TgdPopover';


const useStyles = makeStyles((theme) => createStyles({
    verticalTabsContainer: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 'calc(100vh - 17vh - 64px)',
        width:'100%',
    },
    tabs: {
        //padding:'20px 0px',
        borderRight: `1px solid ${theme.palette.divider}`,
    },
  }));

const a11yProps = (index: any) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

export const TabPanel:React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {

    return (
        <div 
        {...other} 
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        style={{width:'100%'}}> 
            {value === index && (
                children    
            )}
        </div> 
    );
}


interface Props{
    items:any[]
    icon?:CustomIconType
    headerIcon?:CustomIconType
    title?:string
    content:(item?:any)=>React.ReactNode
    labelIterator:string
    customTabComponent?:React.ReactNode
    popoverTitle?:string
    headerContent?:React.ReactNode
}

const TgdVerticalTabs:React.FC<Props> = ({ headerContent, items, customTabComponent, icon, headerIcon, title, content, labelIterator, popoverTitle }) => {

    const  classes = useStyles()
    const defaultPosition = customTabComponent ? 1 : 0
    const [value, setValue] = React.useState(defaultPosition);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };


    return (
        <Paper >
            {headerContent && <>
                <PageContainer >
                    <Typography variant='h5'>
                        <FlexContainer align='center'>
                            {headerIcon && <CustomIcon icon={headerIcon}/>}
                            <FlexContainer width='400px' align='center'>
                            {title}
                            {headerContent}
                            </FlexContainer>
                        </FlexContainer>
                    </Typography>
                </PageContainer>
                <Divider /><br/>
            </>}
            <div className={classes.verticalTabsContainer}>
                <Tabs
                    style={{overflow:'unset'}}
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    className={classes.tabs}
                >
                    {/* Tab Menu */}
                    {customTabComponent &&<Tab key={'CustomTab'} icon={
                        <TgdPopover mode='hover' title={popoverTitle ||''}>
                            <CustomIcon icon={icon ? icon :'add'} />
                        </TgdPopover>
                    } {...a11yProps(0)}/>}

                    {items && items.map((item,index:number) =>
                        <Tab key={index} label={item[labelIterator]} {...a11yProps(customTabComponent ? index+1 :index)}/>
                    )} 
                </Tabs>
                
                {/* components */} 
                {customTabComponent && <TabPanel key={'CustomTabPanel'} value={value} index={0}>{customTabComponent}</TabPanel>}
                
                {items && items.map((item,index:number) =>
                    <TabPanel key={index} value={value} index={customTabComponent ? index+1 :index}>{content(item)}</TabPanel>
                )}
                

            </div>
        </Paper>
    );
}

export default React.memo(TgdVerticalTabs);
