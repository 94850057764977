import Axios,{ AxiosRequestConfig } from "axios"
import { queryCache, QueryResult, useMutation } from "react-query"
import { URL } from "../../../infrastructure/appConfig/appConfig"
import { GridModelInterface, MutationQueryObject } from "../../../infrastructure/Interfaces"
import { useAllAuxByPlant } from "../../auxiliar-data/service.aux"
import { useOperatorSupervisorTagFP } from "../../persons/services/service.person"
import { useToken } from "../../session/service.session"
import { AddedReasonInterface } from "../types/AdedReasonTypes"



export const gridModelAddedReason:GridModelInterface = {
    id:{label:'Id'}, 
    reason:{label:'Nombre motivo'}, 
    tagFP:{label:'Planta'}, 
    date:{label:'Fecha'}, 
}


export const AddedReasonCRUDDependencies = [
    'PlantAux'
]
//----------------------------
//Axios
//----------------------------


export const CreateAddedReason = ({data,token}:MutationQueryObject) => {
    Axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateAddedReason`,
        data
    }
    return Axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const UpdateAddedReason = ({data,token}:MutationQueryObject) => {
    Axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateAddedReason`,
        data:data
    }
    return Axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const DeleteAddedReason = ({data,token}:MutationQueryObject) => {
    Axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/DeleteAddedReason`,
        data:data
    }
    return Axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}


//----------------------------
//ReactQuery
//----------------------------

export const usePlantAddedReasons = (tagFP?:string) => {
    const supOpTagFP = useOperatorSupervisorTagFP()
    const queryTagFP = tagFP || supOpTagFP

    const query = useAllAuxByPlant(queryTagFP)
    const addedReasons:QueryResult<AddedReasonInterface[]> = {
        ...query,
        data:query.data?.addedReasons
    }

    return addedReasons 
}


//----------------------------
//Mutation
//----------------------------

export const useCreateAddedReason = () => {
    const token = useToken()
    const [mutation,data] = useMutation(CreateAddedReason,{
        onSuccess:()=> {
            queryCache.invalidateQueries('PlantAux')
        }
    })

    const createAddedReason = (data:AddedReasonInterface) => {
        return mutation({
            data,
            token
        })
    }

    return{
        createAddedReason,
        data
    }
    
}


export const useUpdateAddedReason = () => {
    const token = useToken()
    const [mutation,data] = useMutation(UpdateAddedReason,{
        onSuccess:()=> {
            queryCache.invalidateQueries('PlantAux')
        }
    })

    const updateAddedReason = (data:AddedReasonInterface) => {
        return mutation({
            data,
            token
        })
    }

    return{
        updateAddedReason,
        data
    }

}

export const useDeleteAddedReason = () => {
    const token = useToken()
    const [mutation,data] = useMutation(DeleteAddedReason,{
        onSuccess:()=> {
            queryCache.invalidateQueries('PlantAux')
        }
    })

    const deleteAddedReason = (data:AddedReasonInterface) => {
        return mutation({
            data,
            token
        })
    }

    return{
        deleteAddedReason,
        data
    }

}




//----------------------------
//CustomHooks
//----------------------------
