import React from 'react'
import SimpleAutocompleteSelect from '../../../components/autocomplete/SimpleAutocompleteSelect';
import { TypeInterface, usePlantType } from '../services/service.types';
import { useLangLabels } from '../../language/services/useSetLabel';
import { enumTgdTypes } from '../Types/types';
import { useUser } from '../../persons/services/service.person';
import CUtypeForm from '../CUtypeForm';
import { CrudFormType } from '../../../infrastructure/form/typescript/FormTypes';

type Props = {
    tagFP:string
    defaultValue?:string
    onChange:(value:any)=>void,
    extendInput?:boolean,
    by?:{
        key:string,
        value:string 
    }
    error?:string
    disabled?:boolean
}

const SelectTypeBy:React.FC<Props> = ({tagFP,defaultValue,onChange,by,error,extendInput,disabled}) => {
    
    const showTitle ='name'
    const {data:user} = useUser()
    const {lang} = useLangLabels()
    const {data:plantType} = usePlantType(tagFP,by?.value || enumTgdTypes.equipment)
    const label = by?.value!
    
    return(
        <SimpleAutocompleteSelect
            {...{
                error,
                items:plantType || [],
                defaultValue,
                label,
                onChange,
                extendInput,
                showTitle,
                disabled
            }}
        >
            {user?.type !== 'O' && 
            <CUtypeForm
            defaultType={by?.value as enumTgdTypes} 
            actionType={CrudFormType.create} 
            tagFP={tagFP}
            />}
        </SimpleAutocompleteSelect>
    )
}

export default SelectTypeBy