import axios, { AxiosRequestConfig } from "axios";
import { queryCache, useMutation, useQuery } from "react-query";
import { URL } from "../../../../infrastructure/appConfig/appConfig";
import { verifyModifyMacAdress } from "../../../../utils/utils";
import { MutationQueryObject } from "../../../../infrastructure/Interfaces";
import { useStatusProcessor } from "../../../errors/services/StatusProcessor";
import { useToken } from "../../../session/service.session";
import { STISensorsInterface } from "./STIsensorsInterfaces";


export const CreateSensor = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateSTISensor`,
        data
    }
    
    return axios(config).then(res => {return res.data}).catch(err =>{return err})
}

export const UpdateSensor = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateSTISensor`,
        data
    }
    return axios(config).then(res => {return res.data}).catch(err =>{return err})
}

export const STISensorsByTagFP = (_:string,tagFP:string,token:String) => {
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    axios.defaults.headers.get['tagFP'] = tagFP;
    
    let config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/STISensorsByTagFP`,
    }
    return axios(config).then(res => {return res.data}).catch(err =>{return err})
}



//----------------------------
//----------Query-------------
//----------------------------


export const useSTISensorsByTagFP = (tagFP:string) =>{
    const token = useToken()
    const query = useQuery<STISensorsInterface[]>(['STISensorsByTagFP',tagFP,token],STISensorsByTagFP,{
        refetchOnWindowFocus:false,
        enabled:!!token || !!tagFP
    })
    
    return ({
        ...query,
        data: query.data?.map((item)=>({
            ...item,
            sensorAddress:verifyModifyMacAdress(item.sensorAddress)
        })) 
    })
}


//----------------------------
//----------Mutation----------
//----------------------------



export const useCreateSTISensor = () => {
    const token = useToken()
    const [mutation,data] = useMutation(CreateSensor,{
        onSuccess:()=>{
            queryCache.invalidateQueries('STISensorsByTagFP')
        }
    })
    const {status,message,error} = useStatusProcessor(data)

    const createSTISensor = (data:STISensorsInterface) => {


        return mutation({
            token,
            data:{
                ...data,
                sensorAddress:verifyModifyMacAdress(data.sensorAddress)
            }
        })
    }

    return {createSTISensor,data:{...data,status,message,error}}
}


export const useUpdateSTISensor = () => {
    const token = useToken()
    const [mutation,data] = useMutation(UpdateSensor,{
        onSuccess:()=>{
            queryCache.invalidateQueries('STISensorsByTagFP')
        }
    })
    const {status,message,error} = useStatusProcessor(data)

    const updateSTISensor = (data:STISensorsInterface) => {
        return mutation({
            token,
            data:{
                ...data,
                sensorAddress:verifyModifyMacAdress(data.sensorAddress)
            }
        })
    }

    return {updateSTISensor,...data,status,message,error}
}




//-------------------
//--------------utils
//-------------------
