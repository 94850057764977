import React from 'react';
import { useUser } from '../../persons/services/service.person';
import { CrudFormType } from '../../../infrastructure/form/typescript/FormTypes';
import { useCriticalitiesByPlant } from '../../auxiliar-data/service.aux';
import SimpleAutocompleteSelect from '../../../components/autocomplete/SimpleAutocompleteSelect';
import CUCriticalityForm from '../CUCriticalityForm';
import { useLangLabels } from '../../language/services/useSetLabel';
import FlexContainer from '../../../components/presentational/FlexContainer';
import { CustomIcon } from './../../../components/buttons/CustomButton';
import { CriticalityInterface } from './../types/types';

interface props{
    tagFP: string
    onChange:(item:string)=>void
    defaultValue?:string | undefined 
    color?:'primary'|'secondary'
    error?:string
    by?:{
        key:string,
        value:string
    },
    disabled?:boolean
}


const SelectCriticality:React.FC<props> = ({tagFP, onChange, defaultValue, error, by, disabled }) => {

    const showTitle = 'description'
    const {lang} = useLangLabels()
    const label = lang.criticalities.title.singular
    const {data:criticalities} = useCriticalitiesByPlant(tagFP)
    const {data:user} = useUser()

    const criticalityFiltered = by 
        ? criticalities?.filter((c:CriticalityInterface)=> c[by.key as keyof CriticalityInterface] === by.value) 
        : criticalities
   

    return (<>
            <SimpleAutocompleteSelect
                {...{
                    error,
                    label,
                    items:criticalityFiltered || [],
                    onChange,
                    defaultValue,
                    showTitle,
                    disabled,
                    renderOption:(option:CriticalityInterface)=><FlexContainer align='center'>
                        <CustomIcon icon='state' style={{color:option.color}}/> {option.description}
                    </FlexContainer>
                }}
            >
                {user?.type!== 'O' && <CUCriticalityForm {...{tagFP,actionType:CrudFormType.create}} />}
            </SimpleAutocompleteSelect>

    </>);

}

export default SelectCriticality;
