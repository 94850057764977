import React from 'react';
//assets
import LoginBackgroundImg from '../../assets/img/login.png' 
//material
import { createStyles, makeStyles } from '@material-ui/core/styles';
import VersionContainer from '../pageContainers/VersionContainer';
import PageContainer from '../pageContainers/PageContainer';
import { Route } from 'react-router-dom';
import { URL } from '../../infrastructure/appConfig/appConfig';
import { USER_ROUTES } from '../../infrastructure/routes/routes';

const useStyles = makeStyles((theme) => createStyles({
    paperImg: {
      top:'0',
      left:'0',
      margin:'0',
      width: '100%',
      height: '100vh',
      backgroundImage:`url(${LoginBackgroundImg})`,
      backgroundPosition:'center',
      backgroundBlendMode: 'luminosity', 
      backgroundSize:'cover',
      filter: 'grayscale',
      marginBottom:'-100vh',
      transition: 'background-color 0.5s ease',

  },

pageBack:{
    backgroundColor:'#EFEBE9',
    height:'100vh',
    width:'100%',
    psition:'relative',
    marginBottom:'-100vh',
  

  }

}));

const LoginBackground:React.FC<{bgColor:string}> = ({bgColor}) => {
    const classes = useStyles();
    return (
        <>
                <div className={classes.pageBack}></div>
                <div className={classes.paperImg} style={{backgroundColor:'#' + bgColor}} >
                </div> 
        </>
    );
}

export default LoginBackground;
