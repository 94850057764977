import { Chart, ChartConfiguration, registerables } from 'chart.js';
import React, { useState } from 'react';
import { useCanvas } from '../useCanvas';


Chart.register(...registerables)

interface LineChartInterface{
    chartValues:{
        label:string,
        data:number[],
        backgroundColor:string[]
    }[] 
    title?:string
    xOperation?:(item:any)=>string,
    labels:string[]
}

const PieChart:React.FC<LineChartInterface> = ({chartValues,xOperation,labels,title})=>{


    const chartConfig:ChartConfiguration = {
        type: "doughnut",
        data:{
            labels,
            datasets: chartValues
            
        },
        options: {
            responsive: true,
            plugins: {
              legend: {
                position: 'bottom',
              },
              title: {
                display: !!title,
                text: title
              }
            }
          },
    }
    
    const [chart,setChart] = useState<any>(null);
    
    const makeChart = (ctx:any) => {
        const chartInstance = new Chart(ctx,chartConfig)
        setChart(chartInstance)
    }

    const {canvas} = useCanvas(makeChart)

    return(
        <canvas 
            ref={canvas} 
            height={'200px'} 
            width={'700px'}
        />
    )
}

export default PieChart