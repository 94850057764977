import { Button, Fab, Grid, Icon,  makeStyles } from '@material-ui/core';
import React, {  useState } from 'react';
import TgdModal from '../../components/modals/TgdModal';
//material
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PageContainer from '../../components/pageContainers/PageContainer';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useCreateSupportTicket } from './services/service.support';
import { usePerson, useUser, useWorkspace } from '../persons/services/service.person';
import { useToken } from '../session/service.session';

import ColorRating from './components/ColorRating';
import FaceRating from './components/FaceRating';
import HearthRating from './components/HearthRating';
import QueryStatus from '../../components/status/QueryStatus';
import OnlineOfflineMode from '../../infrastructure/online/offline Mode/OnlineOfflineMode';



const useStyles = makeStyles(theme => ({
    flexButton:{
        width:'100%',
        backgroundColor:'rgba(255, 255, 255, 0.3)',
        borderRadius:'5px',
        padding:'5px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        '&:hover':{
            backgroundColor:'rgba(255, 255, 255, 0.6)',
            cursor:'pointer'
        }
    },
    form:{
        width:'100%',
    }
   }))


const SupportComponent = () => {
    const classes=useStyles()
    const token = useToken()
    const {data:user} = useUser()
    const {data:person} = usePerson()
    const workspace = useWorkspace()
    const [open,setOpen] = useState(false)
    const [createTicket,query]=useCreateSupportTicket()
    const [data,setData] = useState({
        criticity:'Baja',
        priority:'baja',
        like:1,
        sentiment:'Muy Disconforme',
        bodyContent:''
    })
    



    const handleModal = () => {
        setOpen(!open)
    }
    const handleData = (type,value) => {
            if(type === 'priority')setData({...data,priority:value})
            if(type === 'criticity')setData({...data,criticity:value})
            if(type === 'sentiment')setData({...data,sentiment:value})
            if(type === 'like')setData({...data,like:value})
    }

    const handlechange = (e) => {
        setData({...data,[e.target.name]:e.target.value})
    }


    const handleSubmit = () => {
        createTicket({
            token:token,
            data:{
                criticality:`Criticidad: ${data.criticity} - Prioridad: ${data.priority}`,
                emailBody:`
                    <strong>Tipo de usuario</strong>: ${person && person.role}<br/>  
                    ${person && (person.role === 'Cliente') 
                    ? 
                        (`<strong>Planta desde la cual se levanto el ticket</strong>: ${workspace && workspace} <br/>
                        <strong>Puntaje en Likes a TGD Soft</strong>: ${data.like}   <br/>  
                        <strong>Nivel de disconformidad</strong>: ${data.sentiment}   <br/> `)
                    : ''}  
                    ${person && (person.role !== 'Cliente')
                    ?
                        `<strong>Prioridad</strong>: ${data.priority}<br/>
                        <strong>Gravedad / criticidad</strong>: ${data.criticity}   <br/>` 
                    :''}   

                    <br/>   
                    <hr/>   
                    <br/>   
                    <strong>Contenido del Ticket</strong>: <br/>     
                    ${data.bodyContent}   <br/>  <br/>  
                    <br/>   
                    <hr/>   
                `
            }
        })
    }

    return (
        <>
            <Button className={classes.flexButton} onClick={handleModal}>
                <ErrorOutlineIcon style={{color:'#fff'}} />
            </Button>
                <TgdModal 
                open={open}
                handleClose={()=>handleModal()}
                title={'Soporte - Ayúdanos a mejorar!'}
                fullWidth={true}
                footerActions={true}>
                    <PageContainer>
                        <QueryStatus
                            status={query.status}
                            errorMessage={'No se ha podido notificar el problema'}
                            successMessage={'Muchas gracias por notificar el problema'}
                            children={<>
                                <ValidatorForm onSubmit={handleSubmit}>
                            <br/><br/>
                            <Grid container spacing={3}>
                                {user?.type !== 'C' &&<>
                                    <ColorRating
                                        type={'criticity'}
                                        max={3}
                                        title={'Criticidad'}
                                        handleState={handleData}
                                    /> 
                                    <ColorRating
                                        type={'priority'}
                                        max={4}
                                        title={'Prioridad'}
                                        handleState={handleData}
                                    />
           
                                </>}
                                {user?.type === 'C' &&
                                <>
                                    <FaceRating
                                        type={'sentiment'}
                                        max={5}
                                        title={'Disgusto del Problema'}
                                        handleState={handleData}
                                    />   
                                    <HearthRating
                                        type={'like'}
                                        max={5}
                                        title={'¿Te gusta TGD Soft?'}
                                        handleState={handleData}
                                    /> 
                                </>}               
                            </Grid>
                            <br/>
                            <TextValidator 
                            name='bodyContent'
                            onChange={handlechange}
                            className={classes.form} 
                            id="outlined-multiline"
                            label="¿Cual fue el problema?"
                            multiline
                            rows={10}
                            value={data.bodyContent}
                            /* onChange={handleChange} */
                            variant="outlined"
                            validators={['required']}
                            errorMessages={['Este campo es requerido']}
                            />
                            <br/>

                            <div style={{display:'flex',justifyContent:'flex-end'}}>
                                <Fab type='submit' style={{textAlign:'right'}} color="primary" aria-label="add" size={'small'} >
                                    {/* status === 'loading' ? <CircularProgress color='light'/> : */ <Icon>check</Icon> }
                                </Fab>
                            </div>
                            
                        </ValidatorForm>
                        </>}
                        />
                    </PageContainer>
            </TgdModal>
        </>
    );
}

export default SupportComponent;
