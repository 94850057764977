import Axios, { AxiosRequestConfig } from "axios"
import { MutationResult, QueryResult, QueryStatus, useMutation, useQuery } from "react-query"
import { URL } from "../../../infrastructure/appConfig/appConfig"
import { GridModelInterface, MutationQueryObject } from "../../../infrastructure/Interfaces"
import { useToken } from "../../session/service.session"
import { EquipmentInterface, useEquipmentsByPermission } from '../../equipments/services/service.lubricationPoints';
import { useEffect, useMemo, useState } from "react"
import { useAllAuxByPlant } from "../../auxiliar-data/service.aux"
import { useOperatorSupervisorTagFP } from '../../persons/services/service.person';
import { PermissionInterface, PermissionsEquipments } from "../types/permissionsTypes"
import { updateDependencies } from "../../../infrastructure/reactQuery.tsx/utils/invalidateQueries"



export const gridModelPermission:GridModelInterface = {
    area:{label:'Área'}, 
    code:{label:'Código'}, 
    date:{label:'Fecha'}, 
    description:{label:'Descripción'}, 
    id:{label:'Id'}, 
    permissionName:{label:'Nombre del permiso'}, 
    sector:{label:'Sector'}, 
    tagFP:{label:'Planta'},
}

export const PermissionCRUDDependencies = [
    'EquipmentsPermissionsByTagFP',
    'PermissionsByEquipment',
    'PlantAux',
]

//----------------------------
//Axios
//----------------------------

export const CreatePermission = ({data,token}:MutationQueryObject) => {
    Axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreatePermission`,
        data:data
    }
    return Axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}
export const UpdatePermission = ({data,token}:MutationQueryObject) => {
    Axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdatePermission`,
        data:data
    }
    return Axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}
export const DeletePermission = ({data,token}:MutationQueryObject) => {
    Axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/DeletePermission`,
        data
    }
    return Axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const AssignCancelPermission = ({data,token}:MutationQueryObject) => {
    Axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/AssignCancelPermission`,
        data
    }
    return Axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const EquipmentsPermissionsByTagFP = (_:string,tagFP:string,token:string) => {
    Axios.defaults.headers.get['Authorization'] = `Bearer ${token}`
    Axios.defaults.headers.get['tagFP'] = tagFP
    const config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/EquipmentsPermissionsByTagFP`,

    }
    return Axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}


export const EquipmentsByAssignedPermission = (_:string,data:any,token:string) => {
    Axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/EquipmentsByAssignedPermission`,
        data
    }
    return Axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const PermissionsByEquipment = (_:string,data:any,token:string) => {
    Axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/PermissionsByEquipment`,
        data
    }
    return Axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

//----------------------------
//ReactQuery
//----------------------------

export const useEquipmentsByAssignedPermission = (permission:PermissionInterface | any ) =>{
    const token = useToken()
    const data = {
        name:permission?.permissionName,
        tagFP:permission?.tagFP
    }

    return useQuery(['EquipmentsByAssignedPermission',data,token],EquipmentsByAssignedPermission,{
        enabled:!!token && !!permission
    })
}

export const usePermissionsByTagFP = (tagFP?:string) => {
    const spOpTagFP = useOperatorSupervisorTagFP()
    const query = useAllAuxByPlant(tagFP || spOpTagFP)
    const permissions:QueryResult<PermissionInterface[]> = useMemo(()=> ({
        ...query,
        data:query.data?.permissions
    }),[query])

    return permissions
}

export const useEquipmentsPermissionsByTagFP = (tagFP?:string | undefined) => {
    const token = useToken()
    const SupOpTagFP = useOperatorSupervisorTagFP()
    const queryTagFP = tagFP || SupOpTagFP
    return useQuery<PermissionsEquipments[]>(['EquipmentsPermissionsByTagFP',queryTagFP,token],EquipmentsPermissionsByTagFP,{
        enabled:!!queryTagFP
    })
}

export const usePermisionsByEquipment = (tagFP?:string,tagTGD?:string) => {
    const token = useToken()
    const queryData= {
        tagFP,
        tagTGD
    }
    return useQuery<PermissionsEquipments[]>(['PermissionsByEquipment',queryData,token],PermissionsByEquipment,{
        enabled:!!tagFP && !!tagTGD
    })
}



//----------------------------
//Mutation
//----------------------------

export const useAssignCancelPermission = ():[(data:any)=>Promise<any>, MutationResult<any>] => {
    const token = useToken()
    const [mutation,queryData] = useMutation(AssignCancelPermission,{
        onSuccess:()=>updateDependencies(PermissionCRUDDependencies)
    })

    const assignCancelPermission =  (data:PermissionsEquipments) => {
        return mutation({
            token,
            data
        })
    }

    return [assignCancelPermission,queryData]
}



export const useCreatePermission = () => {
    const token = useToken()
    const [mutation,data] = useMutation(CreatePermission,{
        onSuccess:()=>updateDependencies(PermissionCRUDDependencies)
    })

    const createPermision = (data:PermissionInterface) => {
        mutation({
            data,
            token
        })
    }

    return{
        createPermision,
        data
    }
}


export const useUpdatePermission = () => {
    const token = useToken()
    const [mutation,data] = useMutation(UpdatePermission,{
        onSuccess:()=>updateDependencies(PermissionCRUDDependencies)
    })

    const updatePermission = (data:PermissionInterface) => {
        mutation({
            data,
            token
        })
    }

    return{
        updatePermission,
        data
    }
}


export const useAssignCancelMultiplePermissions = () => {

    const [assignCancel,{status,data,reset}] = useAssignCancelPermission()
    const [assignationItems,setAssignationItems] = useState<PermissionsEquipments[]>([])
  
    const handleAssigCancel = (equipments:EquipmentInterface[],permissions:PermissionsEquipments[],state:'A'|'C') => {
        setAssignationItems([])
        equipments.forEach(e => {
            permissions.forEach(p => {
                setAssignationItems((assignationItems) =>[
                        ...assignationItems,
                        {
                            id:p.id,
                            permissionName:p.permissionName,
                            equipment:e.tagTGD,
                            state:state,
                            tagFP:e.tagFP
                        }
                ])
            })
        })
    }

    useEffect(() => {
        assignCancel(assignationItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[assignationItems])
    
    return{
        handleAssigCancel,
        status,
        objectQuery:assignationItems,
        data,
        reset
    }
}



//----------------------------
//CustomHooks
//----------------------------

//----------------------------
//delete
//---------------------------------------------
interface DeletePermissionErrors {
    equipments?:EquipmentInterface[] | undefined | null
}

export const useDeletePermission = () => {
    return useMutation(DeletePermission,{
        onSuccess:()=>updateDependencies(PermissionCRUDDependencies)
    })
}



export const useDeletePermissionVerification = () => {

    const token = useToken()
    
    const [deleteItem,{status:deleteStatus,data}] = useDeletePermission()
    const [status, setStatus] = useState<QueryStatus>(QueryStatus.Idle)
    const [validationElement,setValidationElement] = useState<PermissionInterface | {} | undefined >()
    const {data:equipmentsByPermissions,status:equipmentsByPermissionsStatus} = useEquipmentsByAssignedPermission(validationElement)
    const [errors, setErrors] = useState<DeletePermissionErrors>({})

    const validate = (item:PermissionInterface) => {
        setStatus(QueryStatus.Loading)
        if(!equipmentsByPermissions)setValidationElement(item)
        else handleDelete()
    }

    const handleDelete= () =>{
        if(equipmentsByPermissionsStatus === 'success'){
            if((equipmentsByPermissions && equipmentsByPermissions.length > 0)) {
                setErrors({equipments:equipmentsByPermissions})
                setStatus(QueryStatus.Error)
                setValidationElement(undefined)
            }
            else{
                deleteItem({
                    data:validationElement,
                    token
                })
                setStatus(QueryStatus.Success)
                setValidationElement(undefined)
            }
        }
    }

    useEffect(()=> {
        if(deleteStatus === 'success'){
            setStatus(QueryStatus.Success)
        }
    },[deleteStatus])
    
    useEffect(() => {
        handleDelete()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[equipmentsByPermissionsStatus])

    return  {
        errors,
        status,
        validate,
        data
    }
}
