import React from 'react';
import { PurchaseRequestInterface } from './service.purchaseRequests';

import { TextField } from '@material-ui/core';

import { useForm } from 'react-hook-form';
import CustomButton from '../../components/buttons/CustomButton';

import theme from '../../infrastructure/materialUi/themeConfig';
import { useUpdatePurchaseRequest } from './service.purchaseRequests';
import StatusAndMessage from '../errors/StatusAndMessage';
import FlexContainer from '../../components/presentational/FlexContainer';
import { useEffect } from 'react';

import ComponentMode from '../../components/ComponentMode';
import { useStatusProcessor } from '../errors/services/StatusProcessor';
import TgdTabs from '../../components/Tabs/TgdTabs';
import { watch } from 'fs';
import { TgdTextField } from '../form-config/formComponents/TgdTextField';
 


interface Props{
    item:PurchaseRequestInterface
}



const initialState = (state:any)=>{
    switch (state.state) {
        case 'PENDIENTE':
            return{
                ...state,
                stateColor:undefined,
                actions:[
                    {
                        name:'SOLICITADO',
                        label:'Solicitada',
                        forms:[
                            {
                                name:'observations',
                                label:'Observacion',
                                title:'',
                                required:false,
                                props:{
                                    variant:'outlined',
                                    multiline:true,
                                    minRows:8,
                                    maxRows:8,
                                    type:'text',
                                    size:'small'
                                }
                            },
                            {
                                name:'requestedAmount',
                                label:'Cantidad a solicitar',
                                title:'',
                                required:false,
                                props:{
                                    variant:'outlined',
                                    type:'number',
                                    size:'small'
                                }
                            }
                        ],
                    },{
                        name:'APROBADO',
                        label:'Aprobada',
                        forms:[
                            {
                                
                                name:'observations',
                                label:'Observacion',
                                title:'',
                                required:false,
                                props:{
                                    variant:'outlined',
                                    multiline:true,
                                    inputRows:8,
                                    type:'text',
                                    size:'small'
                                }
                            },
                            {
                                name:'expirationDate',
                                label:'fecha de entrega',
                                title:'',
                                stateColor:'',
                                required:false,
                                props:{
                                    variant:'outlined',
                                    type:'date',
                                    size:'small'
                                }
                            }
                        ],
                    },{
                        name:'RECHAZADO',
                        label:'Rechazada',
                        forms:[
                            {
                                name:'observations',
                                label:'Observacion',
                                title:'',
                                required:false,
                                props:{
                                    variant:'outlined',
                                    type:'text',
                                    size:'small'
                                }
                            }
                        ],
                    }
                ]
            }

        case 'SOLICITADO':
            return{
                ...state,
                stateColor:theme.palette.warning.light,
                actions:[{
                    name:'APROBADO',
                    label:'Aprobada',
                    forms:[
                        {
                            
                            name:'observations',
                            label:'Observacion',
                            title:'',
                            required:false,
                            props:{
                                variant:'outlined',
                                multiline:true,
                                minRows:8,
                                maxRows:8,
                                type:'text',
                                size:'small'
                            }
                        },
                        {
                            name:'expirationDate',
                            label:'fecha de entrega',
                            title:'',
                            stateColor:'',
                            required:true,
                            props:{
                                variant:'outlined',
                                type:'date',
                                size:'small'
                            }
                        }
                    ],
                },{
                    name:'RECHAZADO',
                    label:'Rechazada',
                    forms:[
                        {
                            name:'observations',
                            label:'Observacion',
                            title:'',
                            required:false,
                            props:{
                                variant:'outlined',
                                multiline:true,
                                minRows:8,
                                maxRows:8,
                                type:'text',
                                size:'small'
                            }
                        }
                    ],
                }
            ]
            }

        case 'APROBADO':
            return{
                ...state,
                stateColor:theme.palette.success.dark,
                actions:[
                    {
                        name:'ENTREGADO',
                        label:'Entregado',
                        forms:[
                            {
                                name:'observations',
                                label:'Observacion',
                                title:'',
                                required:false,
                                props:{
                                    variant:'outlined',
                                    multiline:true,
                                    inputRows:8,
                                    type:'text',
                                    size:'small'
                                }
                            },
                            {
                                name:'receivedAmount',
                                label:'Cantidad recibida',
                                title:'',
                                required:false,
                                props:{
                                    variant:'outlined',
                                    type:'number',
                                    size:'small'
                                }
                            }
                        ],
                    }
                ]
            }

        case 'RECHAZADO':
            return{
                ...state,
                stateColor:theme.palette.error.main
            }

        case 'ENTREGADO':
            return{
                ...state,
                stateColor:theme.palette.success.light
            }
    }
}

const stateReducer = (state:any,action:{type:string,payload:{data:any,callback:(value?:any)=>any}}) => {
    switch (action.type) {
        default:
            return state
    }
}

const SetPurchaseRequestStatus:React.FC<Props> = ({item}) => {
    
    const {handleSubmit,register,errors,setValue, watch} = useForm()
    const [updatePurchaseRequest,updateResult] = useUpdatePurchaseRequest()
    const { status, message, error } = useStatusProcessor(updateResult)
    const state = initialState({
        state:item.state,
    })

    useEffect(()=>{
        item &&
        Object.entries(item).map(([key,value]:[string,any])=>{
            register('key')
            setValue(key,value)
        })
    },[item])


    const submit = (data:any)=>{
        updatePurchaseRequest({
            ...item,
            ...data
        })
    }


    return (
        <>
            <ComponentMode 
            buttonStyle={{color:state.stateColor}}
            popoverTitle={item.state}
            mode={'popover'}
            icon={state.state !== 'ENTREGADO' ? 'label' : 'checkedCircle'} 
            variant='iconButton' 
            title={state.title}> 
                {(state.state !== 'RECHAZADO' || state.state !== 'ENTREGADO') && <>
                    <StatusAndMessage status={status} message={message} error={error} />
                        <TgdTabs
                        labelIterator='label'
                        items={state.actions}
                        content={(action:any)=><FlexContainer flexDirection='column'>
                            
                            {action.forms.map(({name,required,props,label}:{name:keyof PurchaseRequestInterface,label:string, required:boolean,props:any})=>
                                <TextField
                                name={name}
                                inputRef={register({
                                    required:{
                                        value:required,
                                        message:`Campo ${label} requerido` 
                                    }
                                })}
                                focused
                                defaultValue={ item[name]}
                                {...props}
                                label={errors[name]?.message || label}
                                color={errors[name]?.message ? 'primary' : 'secondary' }
                                />
                                )}
                            <CustomButton status={status} action={handleSubmit((data)=>submit({...data,state:action.name}))}>{`Marcar como ${action.label}`} </CustomButton>
                        </FlexContainer>}
                    />
                </>}
            </ComponentMode>
        </>
    );
}

export default React.memo(SetPurchaseRequestStatus);
