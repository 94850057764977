
import axios,{AxiosRequestConfig} from 'axios';
import { useMutation, useQuery, QueryResult, queryCache } from 'react-query';
import { URL } from '../../../infrastructure/appConfig/appConfig';
import { GridModelInterface, MutationQueryObject } from '../../../infrastructure/Interfaces';
import { useToken } from '../../session/service.session';
import { useStatusProcessor } from './../../errors/services/StatusProcessor';
import { useDate } from './../../../infrastructure/date/useDate';


//----------------------------
//Interfaces
//---------------------------

type NewType = 'ENTORNO' |'PLANTA'

export interface NewInterface {
    id?:number
    type:NewType
    criticality:string
    equipment:string
    sector:string
    area:string
    description:string
    newsDate:string
    user:string
    tagFP:string
}

export const GridModelNews:GridModelInterface = {
    renderOption:{label:'Info'},
    id:{label:'Id'},
    type:{label:'Tipo'},
    criticality:{label:'Criticidad'},
    equipment:{label:'Equipo'},
    sector:{label:'Sector'},
    area:{label:'Area'},
    description:{label:'Descripción'},
    newsDate:{label:'Fecha de novedad'},
    user:{label:'Usuario'},
    tagFP:{label:'Planta'}
}

//----------------------------
//axios
//---------------------------

export const NewsByTagFP = (_:string,tagFP:string,token:string) => {
    axios.defaults.headers.get['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.get['tagFP'] = tagFP
    const config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/NewsByTagFP`,
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const CreateNew = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateNews`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const UpdateNew = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateNew`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

//----------------------------
//useQuery
//---------------------------

export const useNewsByTagFP = (tagFP?:string,byDate?:string):QueryResult<NewInterface[]> => {
    /*console.log(byDate)*/
    const token = useToken()
    const news = useQuery<NewInterface[]>(['PlantNews',tagFP,token],NewsByTagFP,{
        enabled:!!tagFP,
        staleTime:900,
        refetchOnWindowFocus:false
    })
    const filteredNews = news.data?.filter((n) => n.newsDate?.slice(0,10) === byDate)

    if(!!byDate && !!news.data) return {...news,data:filteredNews}
    return news
}

export const useTodayNewsbyTagFP = (tagFP?:string) => {

    const news = useNewsByTagFP(tagFP)
    const { nowYear, nowDay, nowMonth } = useDate()
    const date = `${nowYear}-${nowMonth}-${nowDay}`
    const filteredNews = news.data?.filter((n) => n.newsDate?.slice(0,10) === date)

    /* console.log(filteredNews) */
    return{
        ...news,
        data:filteredNews
    }

}

//----------------------------
//Mutation
//---------------------------

export const useCreateNew = ()/* :{createNew:(data:NewInterface)=>void,data:any} */ => {
    const token = useToken()
    const [mutation,data] = useMutation(CreateNew,{
        onSuccess:()=>{
            queryCache.invalidateQueries('PlantNews')
        }
    })

    const status = useStatusProcessor(data)

    const createNew = (New:NewInterface) => {
        mutation({
            data:{
                ...New,
                equipment:New.equipment || ''
            },
            token
        })
    }

    return{
        createNew,
        ...data,
        ...status
    }
}

export const useUpdateNew= () => {
    const token = useToken()
    const [mutation,data] = useMutation(UpdateNew)

    const update = (New:NewInterface) => {
        mutation({
            data:New,
            token
        })
    }

    return{
        update,
        data
    }
}

//----------------------------
//custom hooks
//---------------------------