import React from 'react';
import { PlantInterface } from '../../plants/service.plant';
import CustomSidebarContentCard from '../../../components/CustomSidebarContentCard'
import IotDevices from '../iotDevices/pages/IotDevicesPage';
import StiEquipment from '../STIEquipments/pages/StiEquipmentPage';
import SensorTypes from '../sensorTypes/pages/SensorTypesPage';
import STISensorsPage from '../STISensors/pages/STISensorsPage';
import STISensorizedEquipmentsPage from '../STISensorizedEquipments/pages/STISensorizedEquipmentsPage';
import VersionContainer from '../../../components/pageContainers/VersionContainer';
  

interface Props{
    tagFP:string
}

const SensorsManagmentPage:React.FC<Props> = ({tagFP}) => {

    const PlantManagementConfig = {
        entities:[
            {
                label:"Dispositivos",
                component:<IotDevices tagFP={tagFP}/>
            },
            {
                label:"Tipos de sensores",
                component:<SensorTypes tagFP={tagFP}/>
            },
            {
                label:"Sensores",
                component:<STISensorsPage tagFP={tagFP}/>
            },
            {
                label:"Equipos STI",
                component:<StiEquipment tagFP={tagFP}/>
            },
            {
                label:"Sensorización",
                component:<STISensorizedEquipmentsPage tagFP={tagFP}/>
            }
        ]
    }

    return (
        <>
            {!!tagFP && 
            <VersionContainer company='STI'>
                <CustomSidebarContentCard
                    items={PlantManagementConfig.entities}
                    title={'STI'}
                    content={(value)=>value.component}
                    labelIterator={'label'}
                    headerIcon='config'
                    headerContent={<></>}
                    />
            </VersionContainer>
            }
        </>
    );
}

export default React.memo(SensorsManagmentPage);
