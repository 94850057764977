import { Alert } from '@material-ui/lab';
import React from 'react';
import { ONLINE_MODE } from '../../appConfig/appConfig';
import { useLangLabels } from '../../../domains/language/services/useSetLabel';

interface Props {
    children:any,
    message?:string,
    warning?:boolean,
    reverse?:boolean
}

const OnlineOfflineMode:React.FC<Props> = ({children,message,warning, reverse}) => {

    const  {lang}  = useLangLabels()
    const _mode = reverse ? !ONLINE_MODE : ONLINE_MODE
    return (
        _mode 
        ?  children 
            ? children 
            : null 
        : (message)
            ? <Alert severity='warning'> {message} </Alert>
            : warning !== false
                ? <Alert severity='warning'>{lang.messages.featureNotAvailableOffline}</Alert>
                : null
    );
}

export default OnlineOfflineMode;
