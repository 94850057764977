import { TextField } from '@material-ui/core';

import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { EquipmentFormRegex } from '../../infrastructure/regex/regexForms';
import { useAllAuxByPlant } from '../auxiliar-data/service.aux';
import { EquipmentInterface, LubricationPointInterface, lubricationPointVerificationArray, useCreateLubricationPoint, useEquipmentsTag } from './services/service.lubricationPoints';
import { useToken } from '../session/service.session';
import SelectSimpleEntity from '../../components/select/SelectSimpleEntity';
/* import SelectType from '../types/molecules/SelectType';
 */import { useStatusProcessor } from '../errors/services/StatusProcessor';
import DefaultFormLayout from '../../components/forms/DefaultFormLayout';
import SelectElement from '../elements/molecules/SelectElement';
import SelectComponent from '../components/molecules/SelectComponent';
import SelectLubricant from '../lubricants/molecules/SelectLubricant';
import { useLubricationPointByTagTGD } from './services/service.lubricationPoints';
import { ButtonVariant } from '../../components/buttons/CustomButton';
import SelectTypeBy from '../types/molecules/SelectTypeBy';
import { enumTgdTypes } from '../types/Types/types';


interface Props{
    item:EquipmentInterface
    variant?:ButtonVariant
}

const CreateLubricationPoint:React.FC<Props> = ({item,variant}) => {
    const token = useToken()
    const _tagFP = item.tagFP
    const {data:plantAux} = useAllAuxByPlant(item?.tagFP)
    const lubricants = plantAux?.lubricants
   
    const {register,setValue,handleSubmit,watch,errors,control}= useForm()
    const [createLubricationPoint,data] = useCreateLubricationPoint()
    const {status,message,error} = useStatusProcessor(data)
    const {getTag} = useEquipmentsTag()

    const submit = (data:LubricationPointInterface) => {
        createLubricationPoint({
            data:{
                tagTGD:`${getTag(item,'equipment')}`,
                tagFP:item?.tagFP,
                sector: item?.sector.toUpperCase(),
                plantTag: item?.plantTag.toUpperCase(),
                equipment: item?.equipment.toUpperCase(),
                type: item?.type.toUpperCase(),
                criticality:item?.criticality.toUpperCase(),
                brand: data.brand,
                model:  data.model,
                capacity: data.capacity,
                component: data.component,
                element: data.element,
                function: data.function || 'sin funcion',
                location: data.location,
                lubricant: data.lubricant,
                lubricantType: lubricants?.filter((l:any) => l.lubricant === data.lubricant)[0].type,
                measureUnit: data.measureUnit,
                observations: data.observations || 'sin observación',
                oilAnalysis: !!(data.oilAnalysis === 'SI'),
                review: 1
            },
            token
        })
    }


    
    
    return (<>

        <DefaultFormLayout variant={variant} status={status} message={message} error={error} onChange={handleSubmit(submit)} buttonTitle='Agregar'>              
               
               <TextField
                   inputRef={register({
                       required:{value:true,message:'requerida'},
                   })} 
                   value={watch('brand','GNÉRICO')}
                   name="brand"
                   variant='outlined'
                   label={`Marca del componente ${errors?.brand?.message || ''}`} 
                   color={errors?.brand?.message  ? 'primary' : 'secondary'}
                   size="small" 
                   />

               <TextField
                   inputRef={register({
                       required:{value:true,message:'requerido'},
                   })} 
                   name="model" 
                   variant='outlined'
                   label={`Modelo del componente ${errors?.model?.message || ''}` } 
                   color={errors?.model?.message ? 'primary' : 'secondary'}
                   size="small"
                   />

               <TextField
                   name="function" 
                   variant='outlined'
                   label={'Función del componente'} 
                   color={errors?.function?.message ? 'primary' : 'secondary'}
                   size="small"
                   />

               <TextField
                   inputRef={register()} 
                   name="location" 
                   variant='outlined'
                   label={'Ubicación del componente'} 
                   color={'secondary'}
                   size="small"
                   />

               <TextField
                   inputRef={register()} 
                   name="observations" 
                   variant='outlined'
                   label={'Observaciones'} 
                   color={'secondary'}
                   size="small"
                   />

              <Controller
                   as={<SelectElement
                       tagFP={_tagFP}
                       error={errors?.element?.message}
                       onChange={(value)=>setValue('element',value)}
                       defaultValue={watch('element')}
                       />}
                   name='element'
                   rules={{
                       required:{value:true, message:'requerido'} 
                   }}
                   control={control}
               />

              <Controller
                   as={<SelectComponent
                       tagFP={_tagFP}
                       error={errors?.component?.message}
                       onChange={(value:string)=> setValue('component',value)}
                       defaultValue={watch('component')}
                   />}
                   name='component'
                   rules={{
                       required:{value:true,message:'obligatorio'},
                       validate:{exist: () => lubricationPointVerificationArray(item.lubricationPoints || [],watch('element'),watch('component'))}
                   }}
                   control={control}
               />
               
              <Controller
                   as={<SelectLubricant
                       tagFP={_tagFP}
                       error={errors?.lubricant?.message}
                       onChange={(value:string)=>setValue('lubricant',value)}
                       defaultValue={watch('lubricant')}
                       />}
                   name='lubricant'
                   rules={{required:{value:true,message:'Campo Obligatorio'}}}
                   control={control}
               />

               <TextField
                   inputRef={register({
                       required:{value:true,message:'Campo Obligatorio'},
                       pattern:{value:EquipmentFormRegex.float, message:'El número no es válido'},
                   })} 
                   name="capacity" 
                   placeholder='Capacidad de lubricante' 
                   variant='outlined'
                   label={errors?.capacity?.message || 'Capacidad de lubricante'} 
                   color={errors?.capacity?.message ? 'primary' : 'secondary'}
                   size="small"
                   />                

               <Controller
                   as={<SelectTypeBy
                    error={errors?.measureUnit?.message}
                    by={{key:'type',value:enumTgdTypes.unity}}
                    tagFP={_tagFP}
                    onChange={(value)=>setValue('measureUnit',value)}
                    defaultValue={watch('measureUnit')}
                    />}
                   name='measureUnit'
                   rules={{required:{value:true,message:'Campo Obligatorio'}}}
                   control={control}
               />

               <Controller
                   as={
                       <SelectSimpleEntity
                           error={errors?.oilAnalysis?.message}
                           label={'Análisis de aceite'} 
                           entityList={[{label:'SI',value:true},{label:'NO',value:false}]}
                           showTitle={'label'}
                           onChange={setValue}
                           defaultValue={watch('oilAnalysis')}
                       />
                   }
                   name='oilAnalysis'
                   rules={{required:{value:true,message:'obligatorio'}}}
                   control={control}
               />
               
           </DefaultFormLayout>

    </>);
}

export default CreateLubricationPoint;
