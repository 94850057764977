
export enum enumTgdTypes {
    unity='UNIDAD',
    equipment='EQUIPO',
    lubricant='LUBRICANTE',
    observation='OBSERVACION'
}

export const TgdTypes = [
    {label:enumTgdTypes.unity},
    {label:enumTgdTypes.equipment},
    {label:enumTgdTypes.lubricant},
    {label:enumTgdTypes.observation}
]