import React from 'react';
import {PlantInterface} from '../../plants/service.plant'
import { gridModelArea } from '../services/service.areas';
import DeleteAreaForm from '../DeleteAreaForm';
import { usePlantAreas } from '../services/service.areas';
import AnyGrid from '../../../components/grids/AnyGrid';
import { useLangLabels } from '../../language/services/useSetLabel';
import { CarrousellActionInterface } from './../../../components/grids/AnyGrid';
import CUAreaForm from '../CUAreaForm';
import { CrudFormType } from '../../../infrastructure/form/typescript/FormTypes';
import { AreaInterface } from '../types/AreaTypes';

interface Props{
    plant:PlantInterface
}

const carrousellActions:CarrousellActionInterface[] = [
    {icon:'edit',component:(item:AreaInterface)=><CUAreaForm tagFP={item.tagFP} item={item} actionType={CrudFormType.update} />}
]

const ManageAreaPage:React.FC<Props> = ({plant}) => {
    
    const {lang} = useLangLabels()
    const {data:areas,isLoading} = usePlantAreas(plant.tagFP)


    return(<>
        <AnyGrid
        gridModel={gridModelArea}
        title={lang.areas.title.plural}
        items={areas || []}
        loading={isLoading}
        height='55vh'
        carrousellActions={carrousellActions}
        itemActions={(item:any,items:any)=> <>
                <DeleteAreaForm item={item}/>
            </>
        }
        headerActions={(item:any)=> <CUAreaForm tagFP={plant.tagFP} actionType={CrudFormType.create} />}
        />
    </>)
    
}

export default React.memo(ManageAreaPage);
