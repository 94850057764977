import axios, { AxiosRequestConfig } from "axios";
import { queryCache, useMutation, useQuery } from "react-query";
import { URL } from "../../../../infrastructure/appConfig/appConfig";
import { verifyModifyMacAdress } from "../../../../utils/utils";
import { MutationQueryObject } from "../../../../infrastructure/Interfaces";
import { useStatusProcessor } from "../../../errors/services/StatusProcessor";
import { useToken } from "../../../session/service.session";
import { IotDeviceInterface } from "../interfaces/IotDeviceInterfaces";


export const CreateIotDevice = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateIoTDevice`,
        data
    }
    return axios(config).then(res => {return res.data}).catch(err =>{return err})
}

export const UpdateIoTDevice = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateIoTDevice`,
        data
    }
    return axios(config).then(res => {return res.data}).catch(err =>{return err})
}

export const IotDevicesByTagFP = (_:string,tagFP:string,token:String) => {
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    axios.defaults.headers.get['tagFP'] = tagFP;
    
    let config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/IoTDevicesByTagFP`,
    }
    return axios(config).then(res => {return res.data}).catch(err =>{return err})
}



//----------------------------
//----------Query-------------
//----------------------------


export const useIotDevicesByTagFP = (tagFP:string) =>{
    const token = useToken()
    const query = useQuery<IotDeviceInterface[]>(['IoTDevicesByTagFP',tagFP,token],IotDevicesByTagFP,{
        refetchOnWindowFocus:false,
        enabled:!!token || !!tagFP
    })

    return ({
        ...query,
        data: query.data?.map((item)=>({
            ...item,
            deviceCode:verifyModifyMacAdress(item.deviceCode)
        })) 
    })
}


//----------------------------
//----------Mutation----------
//----------------------------



export const useCreateIotDevice = () => {
    const token = useToken()
    const [mutation,data] = useMutation(CreateIotDevice,{
        onSuccess:()=>{
            queryCache.invalidateQueries('IoTDevicesByTagFP')
        }
    })
    const {status,message,error} = useStatusProcessor(data)

    const createIotDevice = (data:IotDeviceInterface) => {


        return mutation({
            token,
            data:{
                ...data,
                deviceCode:verifyModifyMacAdress(data.deviceCode)
            }
        })
    }

    return {createIotDevice,data:{...data,status,message,error}}
}


export const useUpdateIotDevice = () => {
    const token = useToken()
    const [mutation,data] = useMutation(UpdateIoTDevice,{
        onSuccess:()=>{
            queryCache.invalidateQueries('IoTDevicesByTagFP')
        }
    })
    const {status,message,error} = useStatusProcessor(data)

    const updateIotDevice = (data:IotDeviceInterface) => {
        return mutation({
            token,
            data:{
                ...data,
                deviceCode:verifyModifyMacAdress(data.deviceCode)
            }
        })
    }

    return {updateIotDevice,...data,status,message,error}
}


export const useArchiveIotDevice = () => {
    const token = useToken()
    const [mutation,data] = useMutation(UpdateIoTDevice,{
        onSuccess:()=>{
            queryCache.invalidateQueries('IoTDevicesByTagFP')
        }
    })
    const {status,message,error} = useStatusProcessor(data)

    const archive = (data:IotDeviceInterface) => {
        return mutation({
            token,
            data:{
                ...data,
                state:false,
                deviceCode:verifyModifyMacAdress(data.deviceCode)
            }
        })
    }
    const unArchive = (data:IotDeviceInterface) => {
        return mutation({
            token,
            data:{
                ...data,
                state:true,
                deviceCode:verifyModifyMacAdress(data.deviceCode)
            }
        })
    }

    const archiveUnArchive = (data:IotDeviceInterface)=>{
        if(!data.state){
            return unArchive(data)
        }
        else {
            return archive(data)
        }
    }

    return { archiveUnArchive, ...data, status, message, error }
}


//-------------------
//--------------utils
//-------------------


