

//----------------------------------------
//AXIOS
//----------------------------------------

import axios, { AxiosRequestConfig } from "axios"
import { useMemo } from "react"
import { QueryResult, useQuery } from "react-query"
import { URL } from "../../../../../infrastructure/appConfig/appConfig"
import { LubricantInterface } from "../../../../lubricants/services/service.lubricants"
import { useOperatorSupervisorTagFP } from "../../../../persons/services/service.person"
import { useDefaultQuery } from "../../../../../infrastructure/reactQuery.tsx/useDefaultQuery"
import { useToken } from "../../../../session/service.session"
import { useDailyLubricationPointsWithInfo } from "../../lubricationPoints/services/daoLubricationPoints"
import { objectMapper } from './../../../../../utils/objectMapper';
import { LubricationPointInterface } from "../../../../equipments/services/service.lubricationPoints"
import { PermissionsEquipments } from "../../../../permisions/types/permissionsTypes"

export const PermissionsForEquipments = (_:string,data:any[],token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/PermissionsForEquipments`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const EquipmentsPermissionsByTagFP = (_:string,tagFP:string[],token:string) => {
    axios.defaults.headers.get['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.get['tagFP'] = tagFP
    const config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/EquipmentsPermissionsByTagFP`,
        
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}


//---------------------------------------- 
//QUERIES
//----------------------------------------

export const usePermissionsForEquipments= (lubricationPoints?:any[]) => {
    const token = useToken()
    return useDefaultQuery<PermissionsEquipments[],unknown>(['PermissionsForEquipments',lubricationPoints,token],PermissionsForEquipments,{
        enabled:!!lubricationPoints && !!(lubricationPoints.length > 0 ) && !!token
    })
}

export const useEquipmentsPermitionsByTagFP = (tagFP?:string) => {
    const token = useToken()
    const supOpTagFP = useOperatorSupervisorTagFP()
    const queryTagFP = tagFP || supOpTagFP

    return useQuery(['EquipmentsPermissionsByTagFP',queryTagFP,token],EquipmentsPermissionsByTagFP,{
        enabled:!!queryTagFP && !!token,
        refetchOnWindowFocus:false
    })
}

export const useDailyPermissions = () => {
    const lubricationPoints = useDailyLubricationPointsWithInfo() 
    const lubricationPointsPermissions = usePermissionsForEquipments(lubricationPoints?.map((item)=>({
        ...item,
        supplies:JSON.stringify(item.supplies),
        info:undefined,
        leakLevel:undefined
    })))
    const {data:equipmentsPermissions} = useEquipmentsPermitionsByTagFP()
    const equipmentsPermissionsMapp = objectMapper(equipmentsPermissions,'permissionName')
    
    const dailyPermissions:QueryResult<any> = useMemo(()=> ({
        ...lubricationPointsPermissions,
        data:lubricationPointsPermissions?.data?.map((permission)=>({
            permission,
            lubricationPoints:lubricationPoints?.filter((point)=>point.tagTGD === (equipmentsPermissionsMapp[permission.permissionName]?.equipment))
        }))
    }),[lubricationPoints,lubricationPointsPermissions])  

    return dailyPermissions

}
