import axios, { AxiosRequestConfig } from "axios";
import { URL } from "../../../../../infrastructure/appConfig/appConfig";
import { GridModelInterface } from "../../../../../infrastructure/Interfaces";
import { useToken } from "../../../../session/service.session";
import { useOperatorSupervisorTagFP } from '../../../../persons/services/service.person';
import { useQuery } from "react-query";
import { useObservationsByTagFP } from "../../../../observations/service/service.observations";
import { PeriodInterface } from "../../../../../components/filters/AnyFiltering";




//---------------------------------------
//INTERFACES
//---------------------------------------


export interface SurveyInterface{
    sector:string,
    equipment:string,
    tagTGD:string,
    tagFP:string,
    review:string,
    impossibleToLubricate:boolean,
    lubricates:boolean,
    routeDate:string,
    startTime:string,         
    sampleExtraction:boolean,
    consumables:string,
    lubricantType:string,
    lubricant:string,
    quantity:number,
    measureUnit:string,
    reason:string,
    anomalies:string,
    outOfRoute:boolean,
    inaccessible:boolean,
    cleaning:boolean,
    otNumber:string,
    news:string,
    criticality:number,
    operator:string,
    route:string,
    manualRegistration:boolean,
    areas:string,
    taskType:string,
    taskDone:boolean
    endtime:string
    assetCriticality: string,
    needsLubrication: boolean,
    loadingDate:string
    modified:boolean
    modifiedDate:string
 }

 export const GridModelSurvey:GridModelInterface = {
    "id":{label:'Id'},
    "sector":{label:"Sector"},
    "equipment":{label:"Activo"},
    "tagTGD":{label:"Tag TGD"},
    "tagFP":{label:"Planta"},
    "review":{label:"Revision"},
    "impossibleToLubricate":{
        label:"Imposible de Lubricar",
        options:[
            {
                name:'IMPOSIBLE LUBRICAR',
                value:true
            },
            {
                name:'NORMAL',
                value:false
            },
            {
                name:'TODOS',
                value:undefined
            }
        ]
    },
    "lubricates":{
        label:"Se Lubricó",
        options:[
            {
                name:'LUBRICADOS',
                value:true
            },
            {
                name:'SIN LUBRICAR',
                value:false
            },
            {
                name:'TODOS',
                value:undefined
            }
        ]
    },
    "routeDate":{label:"Fecha de Ruta"},
    "loadingDate":{label:"Fecha de Carga"},
    "startTime":{label:"Inicio"}, 
    "endTime": {label:"Fin"},        
    "sampleExtraction":{
        label:"Extracción de Muestra",
        options:[
            {
                name:'SI',
                value:true
            },
            {
                name:'NO',
                value:false
            },
            {
                name:'TODOS',
                value:undefined
            }
        ]
    },
    "consumables":{label:"Consumibles"},
    "lubricantType":{label:"Tipo de Lubricante"},
    "lubricant":{label:"Lubricante"},
    "quantity":{label:"Cantidad"},
    "measureUnit":{label:"Unidad de medida"},
    "reason":{label:"Motivo"},
    "anomalies":{label:"Anomalías"},
    "outOfRoute":{
        label:"Fuera de ruta",
        options:[
            {
                name:'FUERA DE RUTA',
                value:true
            },
            {
                name:'FUERA DE RUTA',
                value:false
            },
            {
                name:'TODOS',
                value:undefined
            }
        ]
    },
    "inaccessible":{
        label:"Inaccesible",
        options:[
            {
                name:'INACCESIBLE',
                value:true
            },
            {
                name:'ACCESIBLE',
                value:false
            },
            {
                name:'TODOS',
                value:undefined
            }
        ]
},
    "cleaning":{label:"Limpieza del punto"},
    "otNumber":{label:"Nº de OT"},
    "news":{label:"Novedades"},
    "criticality":{label:"Criticidad"},
    "user":{label:"Usuario"},
    "route":{label:"Ruta"},
    "manualRegistration":{
        label:"Registro manual",
        options:[
            {
                name:'MANUAL',
                value:true
            },
            {
                name:'COLECTOR',
                value:false
            },
            {
                name:'TODOS',
                value:undefined
            }
        ]
    },
    "areas":{label:"Areas"},
    "taskType":{label:"Tipo de Tarea"},
    "taskDone":{
        label:"Tarea realizada",
        options:[
            {
                name:'REALIZADA',
                value:true
            },
            {
                name:'SIN REALIZAR',
                value:false
            },
            {
                name:'TODOS',
                value:undefined
            }
        ]
    },
    "assetCriticality":{label:"Criticidad del activo"},
    "needsLubrication":{
        label:"Necesitó Lubricación",
        options:[
            {
                name:'NECESITÓ LUBRICANTE',
                value:true
            },
            {
                name:'NO NECESITÓ LUBRICANTE',
                value:false
            },
            {
                name:'TODOS',
                value:undefined
            }
        ]
    },
    "modified":{
        label:'Modificado',
        options:[
            {
                name:'EDITADO',
                value:true
            },
            {
                name:'NO EDITADO',
                value:false
            },
            {
                name:'TODOS',
                value:undefined
            }
        ]
    },
    "modifiedDate": {label:"Fecha de Edición"}
 }

 
//---------------------------------------
//Axios
//---------------------------------------


export const SurveysByTagFP = (_:string,tagFP:string,token:string) => {
    axios.defaults.headers.get['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.get['tagFP'] = tagFP

    const config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/SurveysByTagFP`,

    }

    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}
export const SurveysByEquipment = (_:string,data:any,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`

    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/SurveysByLubricationPoint`,
        data:data
    }

    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const SurveysByPeriodAndTagFP = (_:string,data:any,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/SurveysByPeriodAndTagFP`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}



//---------------------------------------
//QUERIES
//---------------------------------------

export const useSurveysByPeriodAndTagFP = (period:PeriodInterface | undefined,tagFP:string | undefined) => {

    const token = useToken()
    const supOpTagFP = useOperatorSupervisorTagFP()
    const _tagFP = tagFP || supOpTagFP

    const queryConfig = {
        ...period,
        tagFP:_tagFP
    }

    const queryAll = useSurveysByTagFP(tagFP)

    const queryPeriod = useQuery(['SurveysByPeriodAndTagFP',queryConfig,token],SurveysByPeriodAndTagFP,{
        enabled:!!_tagFP && !!token && !!period
    })

    if(period) return queryPeriod
    else return queryAll

}

export const useSurveysByTagFP = (tagFP?:string) => {
    const token = useToken()
    const supOpTagFP = useOperatorSupervisorTagFP()
    const queryTagFP = tagFP || supOpTagFP

    return useQuery(['SurveysByTagFP',queryTagFP,token],SurveysByTagFP,{
        enabled:!!token && !!queryTagFP,
        refetchOnWindowFocus:false
    })
}

export const useSurveysByLubricationPoint = (tagTGD:string,page?:number) =>{
    const token = useToken()
    return useQuery(['SurveysByLubricationPoint',{tagTGD},token],SurveysByEquipment,{
        enabled:!!tagTGD && !!token,
        refetchOnWindowFocus:false
    })
}


//---------------------------------------
//MUTATIONS
//---------------------------------------
