import axios,{AxiosRequestConfig} from 'axios'
import { useEffect, useState } from 'react'
import { queryCache, QueryResult, QueryStatus, useMutation, useQuery } from 'react-query'
import { URL } from '../../../infrastructure/appConfig/appConfig'
import { useEquipmentsBySectorName } from '../../equipments/services/service.lubricationPoints'
import { useToken } from '../../session/service.session'
import { useAllAuxByPlant } from '../../auxiliar-data/service.aux';
import { GridModelInterface, MutationQueryObject } from '../../../infrastructure/Interfaces'
import { useStatusProcessor } from '../../errors/services/StatusProcessor';

//--------------
//Interfaces
//--------------

export interface SectorInterface{
    id?: number
    sector: string
    sectorName: string
    code: string
    tagFP:string
}



export const gridModelSector:GridModelInterface = {
    code:{label:'Código'}, 
    date:{label:'Fecha'}, 
    id:{label:'Id'}, 
    sector:{label:'Sector'}, 
    sectorName:{label:'Nombre del Sector'}, 
    tagFP:{label:'Planta'}, 
}

export const SectorCRUDDependencies = [
    'PlantAux',
    'sectorsByPlant'
]

//--------------
//REPO
//--------------

export const CreateSector = ({data,token}:MutationQueryObject)=> {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateSector`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const UpdateSector = ({data,token}:MutationQueryObject)=> {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateSector`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const DeleteSector = ({data,token}:MutationQueryObject)=> {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/DeleteSector`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}


//--------------
//Query
//--------------

export const usePlantSectors = (tagFP:string)=> {
    const query = useAllAuxByPlant(tagFP)

   const result:QueryResult<SectorInterface[]> = {
       ...query,
       data:query.data?.sectors
   } 

   return result
}

//--------------
//MUTATIONS
//--------------

export const useCreateSector = () => {
    const token = useToken()
    const [mutation,data] = useMutation(CreateSector,{
        onSuccess:() => {
            queryCache.invalidateQueries('PlantAux')
        }
    })

    const createSector = (data:SectorInterface) => {
        return mutation({
            data,
            token
        })
    }
    
    const status = useStatusProcessor(data)

    return {createSector,...data,...status}
}

export const useUpdateSector = () => {
    const token = useToken()
    const [mutation,data] = useMutation(UpdateSector,{
        onSuccess:() => {
            queryCache.invalidateQueries('PlantAux')
        }
    })

    const updateSector = (data:SectorInterface) => {
        return mutation({
            data,
            token
        })
    }
    
    const status = useStatusProcessor(data)

    return {updateSector,...data,...status}
}

export const useDeleteSector = () => {
    const token = useToken()
    const [mutation,data] = useMutation(DeleteSector,{
        onSuccess:() => {
            queryCache.invalidateQueries('PlantAux')
        }
    })

    const deleteSector = (data:any) => {
        return mutation({
            data,
            token
        })
    }
    
    const status = useStatusProcessor(data)

    return {deleteSector,data:{...data,...status}}
}

interface DeleteSectorErrors {
    equipments?:SectorInterface[] | undefined 
}

export const useDeleteSectorVerification = () => {


    const token = useToken()
    const {deleteSector,data} = useDeleteSector()
    const [status, setStatus] = useState<QueryStatus>(QueryStatus.Idle)
    const [validationElement,setValidationElement] = useState<SectorInterface | {} |undefined >()

    const {data:equipmentsBySectors,status:equipmentsBySectorStatus} = useEquipmentsBySectorName(validationElement)

    const [errors, setErrors] = useState<DeleteSectorErrors>({})

    const validate = (item:SectorInterface) => {
        setStatus(QueryStatus.Loading)
        if(!equipmentsBySectors)setValidationElement(item)
        else handleDelete()
    }

    const handleDelete= () =>{
        if(equipmentsBySectorStatus === 'success'){
            if((equipmentsBySectors && equipmentsBySectors.length > 0)) {
                setErrors({equipments:equipmentsBySectors})
                setStatus(QueryStatus.Error)
                setValidationElement(undefined)
            }
            else{
                deleteSector(validationElement)
                .then((res)=>{
                    setStatus(QueryStatus.Success)
                    setValidationElement(undefined)
                    
                })
                .catch(err=>console.log(err))
            
            }
        }
    }
    
    useEffect(() => {
        handleDelete()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[equipmentsBySectorStatus])

    return  {
        errors,
        status,
        validate,
        data
    }
}


