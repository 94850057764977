import React, { useEffect, useState } from 'react';
import { useCategoriesAndSubcategoriesByTagFP } from '../categories/service.category';
import { SubCategoryInterface } from './service.subcategories';
import SelectSimpleEntity from '../../components/select/SelectSimpleEntity';

interface Props{
    parentCategory:string
    handleSelect:(value:SubCategoryInterface) => void
    errors:string
}

const SelectCategory:React.FC<Props> = ({ handleSelect, errors, parentCategory }) => {
    
    const {data:categories} = useCategoriesAndSubcategoriesByTagFP()
    const subCategories = categories?.filter((category)=> category.subcategories && category.category === parentCategory)[0]?.subcategories
    const [state, setstate] = useState('');
    const selectedSubcategory = subCategories?.filter(subcategory => subcategory.subcategory === state)[0]

    useEffect(()=>{
        if(selectedSubcategory){
            handleSelect(selectedSubcategory)
        }
    },[selectedSubcategory])


    return (
        <SelectSimpleEntity
        onChange={setstate}
        label={errors || 'Seleccionar subcategoría'}
        color={errors ? 'primary' : 'secondary' }
        entityList={subCategories || []}
        showTitle='subcategory'
        />
    );
}

export default React.memo(SelectCategory);
