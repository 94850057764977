import { uiDictionaryInterface } from "./interfaces";


export const uiDictionary:uiDictionaryInterface = {
    'Temperature_Celsius':['celsius','temperature'],
    'temperature_celsius':['celsius','temperature'],
    'temperature':['celsius','temperature'],
    'Pressure_PSI':['psi','pressure'],
    'rms_x':['rmsX','vibration'],
    'rms_y':['rmsY','vibration'],
    'rms_z':['rmsZ','vibration'],
    'channel_1_amps':['channel_1','channels'],
    'channel_2_amps':['channel_2','channels'],
    'channel_3_amps':['channel_3','channels'],
} 

export const uiDictionaryArray = Object.entries(uiDictionary).map(([key,value])=>({[key]:value}))

export const uiDictionaryKeyTranslate = (key:string):string => {
    return uiDictionary[key][0]
}

export const uiDictionaryKeyType = (key:string):string => {
    return uiDictionary[key][1]
}