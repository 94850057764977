//data

import { useUser } from '../domains/persons/services/service.person';
import { PlantInterface } from '../domains/plants/service.plant';
import { useQuery } from 'react-query';
import { FactoryInterface } from '../domains/factories/services/service.factory';
import { useMutation } from 'react-query';
import Axios, { AxiosRequestConfig } from 'axios';
import { useToken } from '../domains/session/service.session';
import { MutationQueryObject } from './Interfaces';
import { useEffect, useState } from 'react';
import { useOperatorSupervisorTagFP } from '../domains/persons/services/service.person';
import { URL } from './appConfig/appConfig';
import { FRONTURL } from './appConfig/appConfig';
import { queryCache } from 'react-query';
import { useDate } from './date/useDate'


const getTodayDelayedAndPendingSetter = ({data,token}:MutationQueryObject) => {
    Axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/TodayDelayedAndPendingSetter`,
        data
    }
    return Axios(config).then((res)=>{return res}).catch((err) => {return err})
}

const getDelayedRoutes = ({data,token}:MutationQueryObject) => {
    Axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/DelayedRoutes`,
        data
    }
    return Axios(config).then((res)=>{return res}).catch((err) => {return err})
}



export const usePopoverEvent = () => {

    const [currentTarget, setCurrentTarget] = useState<HTMLButtonElement | HTMLDivElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
        setCurrentTarget(event.currentTarget);
    };

    const handleClosePopover = () => {
        setCurrentTarget(null)
    }

    return {
        id:'simple-popover',
        currentTarget,
        setPopoverTarget:handleClick,
        handleClosePopover
    }
}




export const useLocalStorage = (key:string, initialState:any,date:string) => {
    
    const [storedValue, setStoredValue] = useState(()=>{
            const localstorageState = localStorage.getItem(key)
            if(localstorageState){
                const data = JSON.parse(localstorageState)
                if(data.date === date){
                    localStorage.setItem(key,JSON.stringify(data))
                    return data
                }
                else {
                    localStorage.setItem(key,initialState)
                    return initialState
                }
            }
            else {
                localStorage.setItem(key,JSON.stringify(initialState))
                return initialState
            }
        }
    );
   
    const setValue = (value:any) => {
        setStoredValue(value)
        localStorage.setItem(key,JSON.stringify(value))
    };
  
    return [storedValue, setValue];

}

export const useTodayDelayedAndPendingSetter = () => { //this set necesary pending routes of the operator, daily once
    const token = useToken()   
    const [mutation,query] = useMutation(getTodayDelayedAndPendingSetter)
    const [DelayedRoutes,delayedQuery] = useMutation(getDelayedRoutes)
    const {data:user}= useUser()
    const {nowYear,nowMonth,nowDay} = useDate()
    const tagFP = useOperatorSupervisorTagFP()
    const status = ((user?.type === 'O') || (user?.type === 'S')) ? query.status : 'success'


    const setDelayedRoutes = () =>{ //set routes that wasn't started and adds to today routes
        if(tagFP){
            DelayedRoutes({
                token,
                data:{
                   /*  date:`${nowYear}-${nowMonth}-${nowDay}`, */
                    tagFP
                },
            })
        }
    }

    const setPendingRoutes = () => { //set today routes to pending status
        if((FRONTURL === 'localhost:3000') && (token) && (tagFP) && ((user?.type === 'O') || (user?.type === 'S'))){
            if(query.status === 'idle'){
                mutation({
                    token,
                    data:{
                        date:`${nowYear}-${nowMonth}-${nowDay}`,
                        tagFP
                    },
                }).then(()=>{
                    setDelayedRoutes()
                })
            }
        }
    }

    useEffect(()=>{
        setPendingRoutes()
    },[tagFP])

    return {...query,status}
}




//--------------------------------------
//CUSTOM HOOKS
//--------------------------------------




export const getErrorhandlerState = () => {
    const data:string= localStorage.getItem('errorHandlerState') || ''
        if (data === '') {
            localStorage.setItem('errorHandlerState',(JSON.stringify({modal:false,errors:'',severity:'',position:{positionX:'',positionY:''}})))
            return localStorage.getItem('errorHandlerState')
        }
    return /* !!data ?  */ JSON.parse(data) /* : '{modal:true,errors:"",severity:""}' */
    
}


//--------------------------------------
//SERVICE
//--------------------------------------

    


export const usePlantConfigWorkspace = () => {
    const queryKey = 'plantConfigWorkspace'
/* 
    const [plantConfigWorkspace,setPlantConfigWorkspace] = useState<PlantInterface>() 

    useEffect(() => {
        if(!!plantConfigWorkspace){
            localStorage.setItem(queryKey,JSON.stringify(plantConfigWorkspace))
        }
        queryCache.invalidateQueries(queryKey)
    },[plantConfigWorkspace]) */
    
    const {data,status} = useQuery<PlantInterface>([queryKey],()=>{
        var res = localStorage.getItem(queryKey)
        if(res) return JSON.parse(res)
        else return undefined
    })

    const setPlantConfigWorkspace = (value:PlantInterface) => {
        localStorage.setItem(queryKey,JSON.stringify(value))
        queryCache.setQueryData(queryKey,value,{
            onSuccess:(plant)=>{
                queryCache.invalidateQueries(queryKey)
            }
        })
    }

    return {
        setPlantConfigWorkspace,
        data,
        status
    }
}

export const useFactoryConfigWorkspace = () => {

    const queryKey = 'FactoryConfigWorkspace'

   /*  const [FactoryConfigWorkspace,setFactoryConfigWorkspace] = useState<FactoryInterface>() 

    useEffect(() => {
        if(!!FactoryConfigWorkspace){
            localStorage.setItem(queryKey,JSON.stringify(FactoryConfigWorkspace))
        }
        queryCache.invalidateQueries(queryKey)
    },[FactoryConfigWorkspace]) */
    
    const {data,status} = useQuery([queryKey],()=>{
        var res = localStorage.getItem(queryKey)
        if(res) return JSON.parse(res)
        else return undefined
    })

    const setFactoryConfigWorkspace = (value:FactoryInterface) => {
        localStorage.setItem(queryKey,JSON.stringify(value))
        queryCache.setQueryData(queryKey,value,{
            onSuccess:(factory)=>{
                queryCache.invalidateQueries(queryKey)
            }
        })
    }

    return {
        setFactoryConfigWorkspace,
        data,
        status
    }
}


export const setNewMenu = (NewMenuItems:any,defaultMenuItems:any) => {
    let newMenu:any =[]
    const {data} =getIndicators(NewMenuItems)

    defaultMenuItems.forEach((item:any) => newMenu.push(item))
    data.forEach((fMitem:string,i:number) =>{
        let subItems = NewMenuItems.filter((item:any)=> item.name.toLowerCase().includes(fMitem.toLowerCase()))
        newMenu.push(newMenuItemWithSubMenu(i,subItems,fMitem,'bubble_chart'))
    })

    let filters = ['control','privado','usage']
    let filteredNewMenu = newMenu.filter((item:any) => {
            if(item.name.toLowerCase().includes(filters[0]))return false
            if(item.name.toLowerCase().includes(filters[1]))return false
            if(item.name.toLowerCase().includes(filters[2]))return false
            else return true
    })

    /* console.log(filteredNewMenu) */
    return filteredNewMenu
}



//new menu item with sub items.
export const newMenuItemWithSubMenu = (itemId:number,subItems:any[],fMitem:string,itemIcon:string) =>{
    let AM:any = [] //new ItemArray to Create sub Items  
    const splitNameOn = (string:string,caracter:string) => {
        if(string.includes(caracter)){
            return string.split('.')
        }
        return string
    }

    subItems.forEach(item => { //create sub Items
        AM.push({
            name:splitNameOn(item.name,'.').slice(1),
            route:item.name,
            icon:"donut_large"
        })
        return AM
    })

    const isMultyMenu = () => {
        /* console.log(subItems) */
        const res = subItems.filter(item => item.name.includes('.'))
        /* console.log(res) */
        
        if(res.length > 0) return true
        if(res.length === 0) return false
    }

    
    
    
    const newMenuItemFiltered = () => {
        let newMenuItem = null

        if(isMultyMenu()){
            return newMenuItem = {//item to add
                id:itemId,
                name:splitNameOn(fMitem,'.'),
                route:fMitem.toLowerCase(),
                icon:itemIcon,
                actionMenu:AM,
            }
        }else{
            return newMenuItem = {//item to add
                name:fMitem,
                route:fMitem.toLowerCase(),
                icon:itemIcon
            }
        }
        
    }
    
    

    return newMenuItemFiltered()
}

export const stringProcesor = (stringArray:string[]) => {
    let keys:any =[]
    
    stringArray.forEach(string=> {
            keys.push(string.split('.'))
    })
    //console.log(keys)
    return keys
}

//
export const getIndicators = (reportsArray:any) => {
    let data:any = []
    let names:any = []

    
    reportsArray.forEach((report:any) =>{
        names.push(report.name)
    })
    
    reportsArray.forEach((report:any) =>{
        stringProcesor(names).forEach((indicatorsArray:any) =>{
            if(data.filter((dataItem:any) => dataItem === indicatorsArray[0]).length === 0){
                data.push(indicatorsArray[0])
            }
        })
    })
    
    let respuesta ={
        data,
        names
    }

    
    return respuesta
}


//return objects that user have access filtering an array of objects
export const filterAccessTo = (role:string,arrayObject:any) => {

       
        let range:any = []
        let accessTo:any = []

          switch(role){ //specific filter
            case'SA':
                range = roleAccesCombinations(100)
                break
            case'A':
                range = roleAccesCombinations(10)
                break
            case'AC':
                range = roleAccesCombinations(50)
                break
            case'S':
                range = roleAccesCombinations(4)
                break
            case'C':
                range = roleAccesCombinations(3)
                break
            case'O':
                range = roleAccesCombinations(5)
                break
        } 
        
        arrayObject.forEach((object:any)=>{
            if(range.filter((i:any) => i === object.access).length > 0){
                accessTo.push(object)
            }
        })
    
        return accessTo
    
}

//Calculate all numbers posible by role (return an array of numbers)
export const roleAccesCombinations = (role:number) => {

    let roleValues:any = [100,10,50,3,4,5]
    let n:number = 0
    let allPosibleAccess:any = []
    let roleResult:any = [] 

    //all simple level variants
    for(n;n<6;n+1){
        if(role !== roleValues[n] ){
            roleResult.push(role + roleValues[n])
        }
        n = n+1
    }

    //all Complex level variants
    mixedResoults(role).forEach((i:any)=>{
        roleResult.push(i)
    })

    //set Acces by role in an Array
    allPosibleAccess = roleResult
    
    return allPosibleAccess
}

//Part of roleAccesCombinations make the  bidimentional array calculations
export const mixedResoults = (position:any) => {

    let roleValues:any = [100,10,50,3,4,5]
    let roleAccess:any = []
    let mixedRoleValues = position
    
   
    let counter= 0
    roleValues.forEach((role:number) => {

        if(position !== role){
            
            mixedRoleValues = mixedRoleValues + role
            roleAccess.push(mixedRoleValues)
            let n = counter

            for(n=counter;n<6;n++){
                if((position !== roleValues[n])){
                    if(role !== roleValues[n]){
                        let result = mixedRoleValues + roleValues[n]
                        roleAccess.push(result)
                    }
                }
            }

        }

        counter++
    })
    return(roleAccess)
}

export const calcDate = (date:any) => {
    let date2 = new Date(date);
    let date1 = new Date()
    let substract = date2.getTime() - date1.getTime()
    if((Math.round(substract/ (1000*60*60*24))) === -1) return 0
    return(Math.round(substract/ (1000*60*60*24)))
}

