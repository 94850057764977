import axios, { AxiosRequestConfig } from 'axios'
import { MutationResult, queryCache, useMutation } from 'react-query'
import { URL } from '../../infrastructure/appConfig/appConfig'
import { MutationQueryObject } from '../../infrastructure/Interfaces'
import { CategoryAreaType } from '../categories/service.category'
import { useToken } from '../session/service.session'

 
//--------------------------
//INTERFACES
//--------------------------

export interface SubCategoryInterface {
    id:number
    subcategory:string
    category:string
    tagFP:string
    categoryArea:CategoryAreaType

}



//--------------------------
//axios
//--------------------------


export const CreateSubcategory = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`

    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateSubcategory`,
        data
    }

    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const UpdateSubcategory = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`

    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateSubcategory`,
        data
    }

    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const useCreateSubCategory = ():[(item:SubCategoryInterface) => Promise<any>,MutationResult<any>] => {
    
    const token = useToken()
    const [mutation,query] = useMutation(CreateSubcategory,{
        onSuccess:()=>{
            queryCache.invalidateQueries('CategoriesAndSubcategoriesByTagFP')
        }
    })

    const createSubcategory = (data:SubCategoryInterface) => {
        return mutation({
            token,
            data
        })
    }

    return [createSubcategory,query]
}



//--------------------------
//queries
//--------------------------




//--------------------------
//mutations
//--------------------------
export const useUpdateSubCategory = ():[(item:SubCategoryInterface) => Promise<any>,MutationResult<any>] => {
    
    const token = useToken()
    const [mutation,query] = useMutation(UpdateSubcategory,{
        onSuccess:()=>{
            queryCache.invalidateQueries('CategoriesAndSubcategoriesByTagFP')
        }
    })

    const updateSubcategory = (data:SubCategoryInterface) => {
        return mutation({
            token,
            data
        })
    }

    return [updateSubcategory,query]
}