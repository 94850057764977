
import { useQuery } from 'react-query';
import { QueryResult } from 'react-query';
import { useAllAuxByPlant } from '../../../../auxiliar-data/service.aux';
import { CriticalityInterface } from '../../../../criticalities/types/types';
import { useOperatorSupervisorTagFP } from '../../../../persons/services/service.person';


export const useOperatorCriticalities = ():QueryResult<CriticalityInterface[]> => {
    const tagFP = useOperatorSupervisorTagFP()
    const {data:allAux} = useAllAuxByPlant(tagFP)

    return useQuery('OperatorCriticalities',()=>{
        return allAux.criticalities
    },{
        enabled:!!allAux,
        refetchOnWindowFocus:false,
        staleTime:5000
    })   
}