import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Typography, IconButton } from '@material-ui/core';
import CustomButton, { ButtonVariant, CustomIconType } from '../../buttons/CustomButton';
import FlexContainer from '../../presentational/FlexContainer';
import Divider from '@material-ui/core/Divider';
import { useModal } from './../../../utils/hooks/useModal';



export interface ModalActionProps{
    /* items:{[key:string]:any}[]
    item:{[key:string]:any} */
    onChange?:(item?:any)=>void
    items?:any[]
    item:any
    content?:(props:any)=>React.ReactNode
    title?:string
    icon?:CustomIconType
    children?: React.ReactNode
    fullWidth?:boolean
    variant?:ButtonVariant
}




const arrayItemPossition = (item:{[key:string]:any},array?:{[key:string]:any}[],term?:string) => {

    var possition = 0
    if(item && !!term && array) {   
        array?.forEach((i:any,index:number) => {
            if(i[term] === item[term]){
                possition = index
            }
        })
    }

    return possition
}


const ModalList:React.FC<ModalActionProps> = ({items, item, title, icon, children, content, onChange, fullWidth, variant}) => {
    
    const {modal,handleModal,setModal} = useModal()
    const itemProperties = (items && items[0]) ? Object.entries(items[0]) : []

    const mapperTerm =
        (itemProperties?.length > 0) 
            ? item 
                ? item.plantTag
                    ? 'plantTag' 
                    : item.equipment 
                        ? 'equipment' 
                            : item.name 
                                ? 'name'
                                :( item.code) 
                                    ? 'code' 
                                    : item.id
                                        ? 'id'
                                        : itemProperties[0][0] 
                : undefined
            : undefined

    const _possition = arrayItemPossition( item, items, mapperTerm)

    const [possition,setPosition] = useState(_possition)

    useEffect(()=>{
        setPosition(_possition)
    },[_possition])
    
    const possibleIncremet = items && possition < (items.length - 1)
    const possibleDecrement = possition > 0

    const currentItem = items && items.length > 0 && items[possition]

    const handleNextItem = () =>{
        if(possibleIncremet){
            setPosition(possition + 1)
        }
    }

    const handlePrevItem = () =>{
        if(possibleDecrement){
            setPosition(possition - 1)
        }
    }

    useEffect(()=>{
        if(currentItem){
            onChange && onChange(currentItem)
        }
    },[currentItem])



    //magage keyboard events
 /*    const { keyPress } = useKeyPress( [
        {
            name:'SHIFT',
            code:16,
            callback:handleNextItem
        },
        {
            name:'CONTROL',
            code:17,
            callback: handlePrevItem
        }
    ]) */
    

    const button = <CustomButton disabled={!!!item} action={handleModal} icon={icon} variant={variant || 'iconButton'} color={variant ? 'inherit' : 'secondary'} popoverTitle={title} />

    return (item ?
        <>
            {button}
            <Dialog /* maxWidth='sm' */ open={modal} onClose={handleModal} fullWidth={fullWidth}>
                <DialogTitle >
                    <FlexContainer align='center'>
                        {<CustomButton disabled={!possibleDecrement} action={handlePrevItem} variant='iconButton' icon='before'></CustomButton>}
                        <FlexContainer width='100%' justify='space-between' align='center'>
                            {`${title}: ${!!mapperTerm && !!currentItem ? currentItem[mapperTerm]:''} `}
                            <Typography variant='h6'>
                                {`${possition + 1} / ${items?.length}`}
                            </Typography>
                        </FlexContainer>
                        {  <CustomButton disabled={!possibleIncremet} action={handleNextItem} variant='iconButton' icon='next'></CustomButton>}
                    </FlexContainer>
                </DialogTitle>
                <Divider/>

                <DialogContent>
                    {children && children}
                    {(content && currentItem) && content(currentItem)}
                </DialogContent>
                
            </Dialog>
        </>
        : button
    );
}

export default ModalList;
