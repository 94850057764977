import React from 'react';
import AnyGrid from '../../../../components/grids/AnyGrid';
import CreateSTISensorizedEquipmentForm from '../CreateSTISensorizedEquipmentForm';
import { useSTISensorizedEquipmentsByTagFP } from '../services/STISensorizedEquipmentService';
import UpdateSTISensorizedEquipmentForm from '../UpdateSTISensorizedEquipmentForm';
import { useLangLabels } from './../../../language/services/useSetLabel';

interface Props {
    tagFP:string
}

const STISensorizedEquipmentsPage:React.FC<Props> = ({tagFP}) => {



    const { lang } = useLangLabels()
    const { data:sensorizedEquipments, isLoading } = useSTISensorizedEquipmentsByTagFP(tagFP)

    return (
        <AnyGrid
            documentFiltering={false}
            title={lang.STISensorizedEquipment.title.plural}
            loading={isLoading}
            items={sensorizedEquipments || []}
            headerActions={()=><CreateSTISensorizedEquipmentForm tagFP={tagFP} />}
            height='55vh'
            itemActions={(item,devices:any)=><>
                <UpdateSTISensorizedEquipmentForm key={item.sensorAddress} item={item} tagFP={tagFP} items={devices} />
            </>}
        />
    );
}

export default STISensorizedEquipmentsPage;
