import React, { memo } from 'react';
//components
import ConfigPage from '../ConfigPage';
import PageContainer from '../../components/pageContainers/PageContainer';
//components
import AddUser from '../AddPages/AddUser';
//Router
import {  Route, Switch } from 'react-router-dom';
import {  USER_ROUTES } from '../../infrastructure/routes/routes';
import NotificationsPage from '../NotificationsPage';
import HomePage from '../HomePage';
import FactoryList from '../../domains/factories/FactoryList';
import PlantsList from '../../components/lists/PlantsList';
import AddDemoUserPage from '../AddPages/User/AddDemoUserPage';
import PlantManagementSelector from '../DiscoverPage';
import PlantManagementPage from '../PlantManagementPage';
import StockPageManagment from '../StockPageManagment';
import NewsPage from '../NewsPage';
import ReportsPage from '../ReportsPage';




const SuperAdminContent:React.FC<{dimention:string}> = ({dimention}) => {
    return (
        <PageContainer>
            
            <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.demoUsers}`}>
                <AddDemoUserPage />
            </Route>

            <Switch>

                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.addUser}`}>
                    <AddUser />
                </Route>

                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.notifications}`}>
                    <NotificationsPage />
                </Route>

                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.reports}`}>
                    <PlantManagementSelector path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.reports}`}>
                        {(plant)=><ReportsPage plant={plant}/>}
                    </PlantManagementSelector>
                </Route> 
                
                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.dailyNews}`}>
                    <PlantManagementSelector path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.dailyNews}`} >
                        {(plant)=><NewsPage tagFP={plant.tagFP}/>}
                    </PlantManagementSelector>
                </Route> 

                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.stock}`}>
                    <PlantManagementSelector path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.stock}`}>
                        {(plant)=><></>}
                    </PlantManagementSelector>
                </Route> 

                <Route exact path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.discover}`}>
                        <PlantManagementSelector path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.discover}`}> 
                            {(plant)=><PlantManagementPage plant={plant}/>}
                        </PlantManagementSelector>
                </Route>

                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.config}`}>
                    <ConfigPage/>
                </Route>

                <Route exact path={`/${USER_ROUTES.home}/${dimention}`}>
                    <HomePage dimention ={dimention}/>
                    <NotificationsPage />
                </Route>

            </Switch>
        </PageContainer>
    );
}

export default memo(SuperAdminContent);