import React from 'react'
import { Paper, Typography } from "@material-ui/core"
import ModalList from "../../components/modals/modalList/ModalList"
import FlexContainer from "../../components/presentational/FlexContainer"
import theme from "../../infrastructure/materialUi/themeConfig"
import { useLangLabels } from "../language/services/useSetLabel"
import { CollectorInterface } from "./services/collectors.service"
import PageContainer from '../../components/pageContainers/PageContainer'
import QRCode from 'react-qr-code'

const ViewQrCode:React.FC<{item:CollectorInterface, items:CollectorInterface[]}> = ({item, items}) => {
    
    const {lang} = useLangLabels()
    
    const qrValue = (...values:any[]) => {
        const result = values.join('|')
        return result
    }

    return(
        <ModalList
            fullWidth = {true}
            items={items}
            title={lang.actionTypes.view}
            icon='view'
            item={item}
            content={(value:CollectorInterface)=>
                <FlexContainer padding={`${theme.spacing(3)}px`}>
                    <Paper elevation={3}>
                        <PageContainer>
                            <QRCode value={qrValue(
                                value.name,
                                value.collectorCode,
                                value.serialNumber,
                                value.state
                                )}/>
                        </PageContainer>
                    </Paper>
                    <FlexContainer padding={`${theme.spacing(3)}`} align='center' >
                        <FlexContainer flexDirection='column' padding={`${theme.spacing(3)}`}>
                            <Typography>ID: {item.id}</Typography>
                            <Typography>NOMBRE: <strong>{item.name}</strong></Typography>
                            <Typography>CÓDIGO: <strong>{item.collectorCode}</strong></Typography>
                            <Typography>Nº SERIE: <strong>{item.serialNumber}</strong></Typography>
                            <Typography>ESTADO: <strong>{item.state ? 'ACTIVO' : 'INACTIVO'}</strong></Typography>
                        </FlexContainer>
                    </FlexContainer>
                </FlexContainer>
            }
        >
            <></>
        </ModalList>
    )
}

export default ViewQrCode