import { Button, Chip, CircularProgress, Icon, IconButton, Fab } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import FilterListIcon from '@material-ui/icons/FilterList';
import EventIcon from '@material-ui/icons/Event';
import theme from '../../infrastructure/materialUi/themeConfig';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { QueryStatus } from 'react-query';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SaveIcon from '@material-ui/icons/Save';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import BuildIcon from '@material-ui/icons/Build';
import DeleteIcon from '@material-ui/icons/Delete';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import MapIcon from '@material-ui/icons/Map';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import CreateIcon from '@material-ui/icons/Create';
import CropFreeOutlinedIcon from '@material-ui/icons/CropFreeOutlined';
import TgdPopover from '../popovers/TgdPopover';
import SpeakerNotesOutlinedIcon from '@material-ui/icons/SpeakerNotesOutlined';
import ToggleOffOutlinedIcon from '@material-ui/icons/ToggleOffOutlined';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PowerIcon from '@material-ui/icons/Power';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import LabelIcon from '@material-ui/icons/Label';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SendIcon from '@material-ui/icons/Send';
import LanguageIcon from '@material-ui/icons/Language';
import WifiIcon from '@material-ui/icons/Wifi';
import InventoryIcon from './../../assets/icons/InventoryIcon';
import HelmetIcon from '../../assets/icons/HelmetIcon';
import PermissionsIcon from '../../assets/icons/PermissionsIcon';
import OilCanIcon from '../../assets/icons/OilCanIcon';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LabelImportantOutlined, Opacity } from '@material-ui/icons';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ScienceIcon from '../../assets/icons/LabIcon'
import CleaningIcon from '../../assets/icons/CleaningIcon'
import CachedIcon from '@material-ui/icons/Cached';
import OutOfRouteIcon from '../../assets/icons/OutOfRouteIcon';
import RefreshIcon from '@material-ui/icons/Refresh';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { GridSearchIcon } from '@material-ui/data-grid';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import TodayIcon from '@material-ui/icons/Today';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

export type ButtonVariant = 'button' | 'iconButton' | 'chip' | 'fab'
export type CustomIconType = 'add' 
| 'search'
| 'nextState'
| 'outOfRouteWhite' 
| 'resync' 
| 'cancelOutlined' 
| 'refresh' 
| 'add-circle' 
| 'leak'
| 'lab' 
| 'cleaning' 
| 'pending' 
| 'checkedCircle' 
| 'temperature' 
| 'arrowDown' 
| 'arrowUp' 
| 'oilCan' 
| 'helmet' 
| 'permission' 
| 'inventory' 
| 'wifi' 
| 'language' 
| 'send' 
| 'list' 
| 'email' 
| 'label' 
| 'flag' 
| 'info' 
| 'view' 
| 'power' 
| 'survey' 
| 'more' 
| 'surveyPlay' 
| 'state' 
| 'observations' 
| 'toggleOff' 
| 'map' 
| 'tool' 
| 'off' 
| 'columns' 
| 'delete' 
| 'close' 
| 'edit' 
| 'download'
| 'filter' 
| 'calendar' 
| 'error' 
| 'success' 
| 'config' 
| 'stock'
| 'save' 
| 'next' 
| 'before' 
| 'check' 
| 'addList'
| 'capacity'
| 'date'
| 'user'

interface CustomIconProps { 
    icon:CustomIconType | undefined
    style?:CSSProperties
}


export const CustomIcon:React.FC<CustomIconProps> = ({style, icon}) => {

    const componentStyle = {
        margin:'4px',
        ...style
    }
    return(<>
        {(icon === 'add' ) && <Icon  style={ componentStyle} > add</Icon>}
        {(icon === 'add-circle' ) && <Icon style={ componentStyle} > add-circle</Icon>}
        {(icon === 'edit' ) && <CreateIcon style={ componentStyle} />}
        {(icon === 'delete' ) && <DeleteIcon style={ componentStyle} /> }
        {(icon === 'close' ) && <Icon style={ componentStyle} > close</Icon>}
        {(icon === 'config' ) && <SettingsOutlinedIcon style={ componentStyle} /> }
        {(icon === 'check' ) && <Icon style={ componentStyle} > check</Icon>}
        {(icon === 'download') && <GetAppIcon style={ componentStyle} />}  
        {(icon === 'filter') && <FilterListIcon style={ componentStyle} />}  
        {(icon === 'calendar') && <EventIcon style={ componentStyle} />}  
        {(icon === 'error') && <ErrorOutlineIcon style={ componentStyle} />}  
        {(icon === 'success') && <CheckCircleOutlineIcon style={ componentStyle} />}  
        {(icon === 'stock') && <WidgetsOutlinedIcon style={ componentStyle} />}  
        {(icon === 'save') && <SaveIcon style={ componentStyle} />}  
        {(icon === 'next') && <NavigateNextIcon style={ componentStyle} />}  
        {(icon === 'before') && <NavigateBeforeIcon style={ componentStyle} />}  
        {(icon === 'addList') && <PlaylistAddIcon style={ componentStyle} />}  
        {(icon === 'map') && <MapIcon style={ componentStyle} />}  
        {(icon === 'tool') && <BuildIcon style={ componentStyle} />}  
        {(icon === 'columns') && <ViewWeekIcon style={ componentStyle} />}  
        {(icon === 'observations') && <SpeakerNotesOutlinedIcon style={ componentStyle} />}  
        {(icon === 'survey') && <CropFreeOutlinedIcon style={ componentStyle} />}  
        {(icon === 'toggleOff') && <ToggleOffOutlinedIcon style={componentStyle} />}  
        {(icon === 'off') && <PowerSettingsNewOutlinedIcon style={componentStyle} />}  
        {(icon === 'state') && <FiberManualRecordIcon style={componentStyle} />}  
        {(icon === 'surveyPlay') && <PlayCircleFilledWhiteIcon style={componentStyle} />}  
        {(icon === 'more') && <MoreVertIcon style={componentStyle} />}  
        {(icon === 'checkedCircle') && <CheckCircleOutlineIcon style={componentStyle} />}  
        {(icon === 'power') && <PowerIcon style={componentStyle} />}  
        {(icon === 'view') && <VisibilityIcon style={componentStyle} />}  
        {(icon === 'info') && <InfoOutlinedIcon style={componentStyle} />}  
        {(icon === 'flag') && <FlagOutlinedIcon style={componentStyle} />}  
        {(icon === 'label') && <LabelIcon style={componentStyle} />} 
        {(icon === 'email') && <MailOutlineIcon style={componentStyle} />} 
        {(icon === 'send') && <SendIcon style={componentStyle} />} 
        {(icon === 'language') && <LanguageIcon style={componentStyle} />} 
        {(icon === 'list') && <ListAltIcon style={componentStyle} />} 
        {(icon === 'wifi') && <WifiIcon style={componentStyle} />} 
        {(icon === 'inventory') && <InventoryIcon style={componentStyle} />} 
        {(icon === 'helmet') && <HelmetIcon style={componentStyle} />} 
        {(icon === 'permission') && <PermissionsIcon style={componentStyle} />} 
        {(icon === 'oilCan') && <OilCanIcon style={componentStyle} />} 
        {(icon === 'arrowUp') && <ExpandLessIcon style={componentStyle} />} 
        {(icon === 'arrowDown') && <ExpandMoreIcon style={componentStyle} />} 
        {(icon === 'leak') && <Opacity style={componentStyle} />} 
        {(icon === 'temperature') && <BlurOnIcon style={componentStyle} />} 
        {(icon === 'pending') && <HourglassEmptyIcon style={componentStyle} />} 
        {(icon === 'lab') && <ScienceIcon style={componentStyle} />} 
        {(icon === 'cleaning') && <CleaningIcon style={componentStyle} />} 
        {(icon === 'cleaning') && <OutOfRouteIcon style={{...componentStyle,fill:'#fff'}}/>} 
        {(icon === 'resync') && <CachedIcon style={componentStyle} />} 
        {(icon === 'refresh') && <RefreshIcon style={componentStyle} />} 
        {(icon === 'cancelOutlined') && <CancelOutlinedIcon style={componentStyle} />} 
        {(icon === 'outOfRouteWhite') && <OutOfRouteIcon style={componentStyle} />} 
        {(icon === 'nextState') && <LabelImportantOutlined style={componentStyle} />} 
        {(icon === 'search') && <GridSearchIcon style={componentStyle} />} 
        {(icon === 'capacity') && <ZoomOutMapIcon style={componentStyle} />} 
        {(icon === 'date') && <TodayIcon style={componentStyle} />} 
        {(icon === 'user') && <AccountCircleIcon style={componentStyle} />} 

    </>)
}

export type ButtonColorType = 'primary' | 'secondary' | 'inherit' | undefined

interface Props{
    color?:ButtonColorType 
    variant?:ButtonVariant
    action?:(item?:any)=>void
    title?:string
    type?:'submit'
    icon?:CustomIconType
    status?: QueryStatus
    disabled?:boolean
    popoverTitle?:string
    styleProps?:CSSProperties
    outlined?:boolean
}


const CustomButton:React.FC<Props> = ({action, title, type, children, icon, status, disabled, variant, color, popoverTitle, styleProps, outlined}) => {

    const defaultVariant:ButtonVariant = variant || 'button'

    const style:CSSProperties | undefined = status 
        ?   (status === 'loading'

                ?   { backgroundColor:theme.palette.info.main }

                :   (status === 'success'

                        ? { backgroundColor:theme.palette.success.dark }

                        :undefined
                    )

            )
        :   undefined


    const iconStatus = status 
    ?   (((status !== 'error' && status !== 'success' ))
            ? icon
            : status
        )
    :   icon


    const buttonContent =
    <>
        {icon && <CustomIcon icon={iconStatus} /> }
        {title && title} {children && children}
    </>

    const buttonStatus =
    <>
        {
        status 
            ?   ((status === 'loading') 
                    ?   <CircularProgress color='inherit' size='20px' />
                    :   buttonContent
                ) 
            :   buttonContent
        }


    </>

    const fabVariant =  <> 
        {defaultVariant === 'fab' &&
            <Fab color={(color || 'primary')} onClick={action} >
                <CustomIcon icon={icon}/>
            </Fab>
        }
    </>

    const chipVariant = <>
        {defaultVariant==='chip' &&
            <Chip disabled={disabled} color={(color==='inherit') ? 'default' : (color || 'default')} size='small' icon={<CustomIcon icon={icon}/>} label={title || children || ''} onClick={action} />
        }
    </>

    const IconVariant = <>
        {defaultVariant === 'iconButton' && 
            <IconButton color={color} type={type} style={styleProps} size='small' onClick={action} disabled={ disabled }>
                { status === 'loading' ? <CircularProgress color='inherit' size='20px'/> : <CustomIcon icon={icon} /> }
            </IconButton>
        }
    </>

    const ButtonVariant =<>
        {defaultVariant === 'button' && 
            <Button color={status ==='error' ? 'primary' : 'secondary'} style={{...style,...styleProps}} type={type} variant={outlined ? 'outlined' : 'contained'} size='small' onClick={action} disabled={disabled}>
                {buttonStatus}
            </Button>        
        }
    </>
    
    return (<>     

        {popoverTitle 
        ?   <TgdPopover mode='hover' title={popoverTitle}>
                {IconVariant}
                {ButtonVariant}
                {chipVariant}
                {fabVariant}
            </TgdPopover>

        :   <>
                {IconVariant}
                {ButtonVariant}
                {chipVariant}
                {fabVariant}
            </>
        }

    </>);
}

export default CustomButton;
