import { makeStyles } from '@material-ui/core';
import React from 'react';

export interface PointerContainerInterface {
	children:React.ReactNode
	onClick?: (item?:any)=>void
}


const useStyless = makeStyles((theme) => ({
	pointer: (props:any)=>({
		cursor:'pointer',
	})
  }));

const PointerContainer:React.FC<PointerContainerInterface> = ({children,onClick}) =>{
	
	const classes = useStyless()
	
	return(
		<div {...{onClick}} className={classes.pointer} >
			{children}
		</div>
	)
}

export default React.memo(PointerContainer);
