import axios,{AxiosRequestConfig} from "axios";
import { useEffect, useState } from "react";
import { queryCache,QueryResult, QueryStatus, useMutation, useQuery } from "react-query";
import { URL } from "../../../infrastructure/appConfig/appConfig";
import {  MutationQueryObject } from "../../../infrastructure/Interfaces";
import { EquipmentInterface, useEquipmentsByLubricantType, useEquipmentsByEquipmentType, useEquipmentsByMeassureUnit } from "../../equipments/services/service.lubricationPoints";
import { LubricantInterface, useLubricantsByType } from "../../lubricants/services/service.lubricants";
import { useToken } from "../../session/service.session";
import { useAllAuxByPlant } from '../../auxiliar-data/service.aux';
import { useStatusProcessor } from '../../errors/services/StatusProcessor';
import { enumTgdTypes, TgdTypes } from "../Types/types";

//---------------------------
//interfaces
//---------------------------

export const entityTypes:{name:string}[] = [
    {
        name:"LUBRICANTE"
    },
    {
        name:"EQUIPO"
    },
    {
        name:"UNIDAD"
    },
    {
        name:"OBSERVACION"
    }
]

/* export type entityTypeTypes = 'UNIDAD' | 'EQUIPO' | 'LUBRICANTE' | 'OBSERVACION'
 */

export interface TypeInterface{
    id: number
    type: enumTgdTypes
    name: string
    tagFP: string
}

//---------------------------
//Repo
//---------------------------


export const getTypes = (_:string,token:string) => {
    axios.defaults.headers.get['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/AllTypes`,
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const CreateType = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateType`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const updateType = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateType`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const deleteType = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/DeleteType`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}




//----------------------------
//ReactQuery
//---------------------------------------------

export const useType = (type:enumTgdTypes) => {
    const token = useToken()
    const {data:types} = useQuery(['AllTypes',token],getTypes)
    return types?.filter((item:{type:string}) => item.type === type) 
}

export const usePlantType = (tagFP?:string,type?:string) => {
    const query = useTypesByPlant(tagFP)
    const result:QueryResult<TypeInterface[]> = {
        ...query,
        data:type ? query.data?.filter((item:{type:string}) => item.type === type?.toUpperCase()) : undefined
    }
    return result
}

export const useTypesByPlant = (tagFP?:string) => {
    const query = useAllAuxByPlant(tagFP)
    const result: QueryResult<TypeInterface[]> = {
        ...query,
        data:query.data?.types
    } 
    return result
}

export const useLubricantTypesByTagFP = (tagFP?:string):QueryResult<TypeInterface[]> =>{
    const lubricantTypes = useType(enumTgdTypes.lubricant)?.filter((item:{tagFP:string}) => item.tagFP === tagFP)
    return useQuery<TypeInterface[]>([],()=>lubricantTypes,{
        enabled:!!lubricantTypes
    })
}

export const useMeasureUnitByTagFP = (tagFP?:string):QueryResult<TypeInterface[]> =>{
    const measureUnit = useType(enumTgdTypes.unity)?.filter((item:{tagFP:string}) => item.tagFP === tagFP)
    return useQuery<TypeInterface[]>([],()=>measureUnit,{
        enabled:!!measureUnit
    })
}

export const useObservationTypesByTagFP = (tagFP?:string):QueryResult<TypeInterface[]> =>{
    const measureUnit = useType(enumTgdTypes.observation)?.filter((item:{tagFP:string}) => item.tagFP === tagFP)
    return useQuery<TypeInterface[]>([],()=>measureUnit,{
        enabled:!!measureUnit
    })
}


//----------------------------
//MUTATIONS
//---------------------------------------------

export const useCreateType = ():[(data:any)=>Promise<any>,any] =>{
    const token = useToken()
    const [mutation,data] = useMutation(CreateType,{
        onSuccess:() => {
            queryCache.invalidateQueries('AllTypes')
            queryCache.invalidateQueries('PlantAux')
            queryCache.invalidateQueries('EquipmentsByLubricantType')
            queryCache.invalidateQueries('LubricantByType')
        }
    })

    const createType = (data:any) => {
        return mutation({
            data,
            token,
        })
    }

    const {status,message,error} = useStatusProcessor(data)

    return [createType,{...data,status,error,message}]
}

export const useUpdateType = ():[(data:any)=>Promise<any>,any] =>{
    const token = useToken()

    const [mutation,data] = useMutation(updateType,{
        onSuccess:() => {
            queryCache.invalidateQueries('AllTypes')
            queryCache.invalidateQueries('PlantAux')
        }
    })

    const createType = (data:TypeInterface) => {
        return mutation({
            data,
            token,
        })
    }

    const {status,message,error} = useStatusProcessor(data)

    return [createType,{...data,status,error,message}]
}


export const useDeleteType = () => {
    return useMutation(deleteType,{
        onSuccess:()=> {
            queryCache.invalidateQueries('AllTypes')
            queryCache.invalidateQueries('PlantAux')
        }
    })
}


interface DeleteTypeErrors {
    lubricants?:LubricantInterface[] | undefined 
    equipments?:EquipmentInterface[] | undefined 
}

export const useDeleteTypeVerification = () => {
 
    const token = useToken()
    const [deleteItem,{status:deleteStatus,data}] = useDeleteType()
    const [status, setStatus] = useState<QueryStatus>(QueryStatus.Idle)
    const [validationElement,setValidationElement] = useState<TypeInterface | {} | undefined >()


    const {data:lubricantsByType,status:lubricantsByTypeStatus} = useLubricantsByType(validationElement)
    const {data:equipmentsByLubricant,status:equipmentsByLubricantStatus} = useEquipmentsByLubricantType(validationElement)
    const {data:equipmentsByEquipment,status:equipmentsByEquipmentStatus} = useEquipmentsByEquipmentType(validationElement)
    const {data:equipmentsByMeasureUnit,status:equipmentsByMeasureUnitStatus} = useEquipmentsByMeassureUnit(validationElement)

    const [errors, setErrors] = useState<DeleteTypeErrors>({})

    const validate = (item:TypeInterface) => {
        setStatus(QueryStatus.Loading)
        if(!lubricantsByType && !equipmentsByLubricant  && !equipmentsByEquipment  && !equipmentsByMeasureUnit )setValidationElement(item)
        else handleDelete()
    }

    const handleDelete= () =>{
        if((lubricantsByTypeStatus === 'success') && (equipmentsByLubricantStatus === 'success') && (equipmentsByEquipmentStatus === 'success') && (equipmentsByMeasureUnitStatus === 'success')){
            if((lubricantsByType?.length > 0) || ((equipmentsByLubricant?.length > 0) || ((equipmentsByEquipment?.length > 0)) || ((equipmentsByMeasureUnit?.length > 0)))){
                if((lubricantsByType.length > 0) && (equipmentsByLubricant.length > 0)){
                    setErrors({...errors,lubricants:lubricantsByType,equipments:equipmentsByLubricant})
                    setStatus(QueryStatus.Error)
                }else if(lubricantsByType.length >0){
                    setErrors({...errors,lubricants:lubricantsByType})
                    setStatus(QueryStatus.Error)
                }else if(equipmentsByLubricant.length > 0){
                    setErrors({...errors,equipments:equipmentsByLubricant})
                    setStatus(QueryStatus.Error)
                }else if((equipmentsByEquipment.length > 0) && (equipmentsByMeasureUnit.length > 0)){
                    setErrors({...errors,equipments:equipmentsByEquipment+equipmentsByMeasureUnit})
                    setStatus(QueryStatus.Error)
                }else if(equipmentsByEquipment.length > 0){
                    setErrors({...errors,equipments:equipmentsByEquipment})
                    setStatus(QueryStatus.Error)
                }else if(equipmentsByMeasureUnit.length > 0){
                    setErrors({...errors,equipments:equipmentsByMeasureUnit})
                    setStatus(QueryStatus.Error)
                    
                }
                setValidationElement(undefined)
            }
            else{
                deleteItem({
                    data:validationElement,
                    token
                })
                .then((res)=>{
                    setStatus(QueryStatus.Success)
                    setValidationElement(undefined)
                })
                .catch((err)=>console.log(err))
            }
            
        }
    }
  
    useEffect(() => {
        handleDelete()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[lubricantsByTypeStatus, equipmentsByLubricantStatus, equipmentsByEquipmentStatus, equipmentsByMeasureUnitStatus])

    return  {
        errors,
        status,
        validate,
        data
    }

}


//----------------------------
//Custom HOOKS
//---------------------------------------------







