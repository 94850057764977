


 export enum TaskType {
    inspection='INSPECCION',
    lubrication='LUBRICACION',
    cleaning='LIMPIEZA',
    repairment='REPARACION',
    puntuation='PUNTUACION',
    intervention='INTERVENCION',
    control='CONTROL',
 }

/* export type TaskType = 'INSPECCION' | 'LUBRICACION' | 'LIMPIEZA' | 'REPARACION' | 'PUNTUACION' | 'INTERVENCION' | 'CONTROL'
 */
export const SurveyTasksValues = {
    inspection:TaskType.inspection,
    lubrication:TaskType.lubrication,
    cleaning:TaskType.cleaning,
    repairment:TaskType.repairment,
    puntuation:TaskType.puntuation,
    intervention:TaskType.intervention,
    control:TaskType.control
} 
