import React from 'react'
import { useUser } from '../../persons/services/service.person';
import SimpleAutocompleteSelect from '../../../components/autocomplete/SimpleAutocompleteSelect';
import CUAreaForm from './../CUAreaForm';
import { CrudFormType } from '../../../infrastructure/form/typescript/FormTypes';
import { useLangLabels } from './../../language/services/useSetLabel';
import { usePlantAreas } from '../services/service.areas';
import { AreaInterface } from '../types/AreaTypes';

interface Props{
    onChange:(value:any)=>void
    tagFP:string
    defaultValue?:string|undefined
    error?:string
    complete?:boolean
    helper?:string
}

const SelectArea:React.FC<Props> = ({tagFP, onChange, defaultValue, complete, error, helper}) => {

    const showTitle = 'area'
    const {lang} = useLangLabels()
    const {data:areas} = usePlantAreas(tagFP)
    const {data:user} = useUser()
    const label = lang.areas.title.singular

    const handleChange = (value:string) => {
        if(complete){
            const resultComplete = areas?.find((a)=>a[showTitle] === value) as AreaInterface
            onChange(resultComplete)
        }else{
            const result = value as string 
            onChange(result)
        }
    }

    return (<> 
            {/* <SelectSimpleEntity
                error={error}
                onChange={handleChange}
                label={'Área '+( error ? error : '')}
                color={error ? 'primary' : 'secondary'}
                entityList={areas}
                showTitle={'area'}
                defaultValue={defaultValue}
            >
                {user && (user?.type === 'O' ? null : <CreateArea tagFP={tagFP!}/>)}
            </SelectSimpleEntity> */}

            <SimpleAutocompleteSelect
                {...{
                    error,
                    onChange:handleChange,
                    defaultValue,
                    label,
                    items:areas || [],
                    showTitle,
                    helper
                }}
            >
                {user?.type !== 'O' && <CUAreaForm actionType={CrudFormType.create} tagFP={tagFP} />}
            </SimpleAutocompleteSelect>
    </>);

}

export default React.memo(SelectArea);
