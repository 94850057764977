import axios,{AxiosRequestConfig} from'axios'
//services
import { getAllResources, getAllResourcesByEmail } from '../../../infrastructure/axios/service.tgd.axios'
import { useMutation, useQuery, queryCache, QueryStatus } from 'react-query';
import {useToken} from '../../session/service.session'
import { useUser } from '../../persons/services/service.person';
import { GridModelInterface, MutationQueryObject } from '../../../infrastructure/Interfaces';
import { URL } from '../../../infrastructure/appConfig/appConfig';
import { useStatusProcessor } from './../../errors/services/StatusProcessor';



export interface FactoryInterface{
    id:number,
    factoryId:number,
    location:string,
    name:string,
    businessName:string,
    referrer:string,
    tagF:string,
}

export const FactoryGridModel:GridModelInterface = {
    id:{label:'Id'},
    location:{label:'Ubicacion'},
    name:{label:'Nombre'},
    businessName:{label:'Razón social'},
    referrer:{label:'Referente'},
    tagF:{label:'Tag de fábrica'}
}

const factoriesCRUDDependencies = [
    'AllFactoriesWithPlants',
    'AccountManagerFactories',
    'AllFactories',
]


//axios repo

export const CreateFactory = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateFactory`,
        data
    }
    return axios(config).then(res => {return res}).catch(err =>{return err})
}

export const assignCancelClientAccount = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/AssignCancelClientAccount`,
        data
    }
    return axios(config).then(res => {return res}).catch(err =>{return err})
}




//react-query HOOKS
//---------------------------------------------

export const useAllFactoriesWithPlants = () => { //get factories that contain at list one plant
    const token = useToken()
    return useQuery(['AllFactoriesWithPlants',token], getAllResources,{
        refetchOnWindowFocus:false
    })
}

export const useAccountManagerFactories = (email:string) =>{
    const token = useToken()
    return useQuery(['AccountManagerFactories', email, token], getAllResourcesByEmail,{
        refetchOnWindowFocus:false
    })
}

export const useAllFactories  = () => {
    const token = useToken()
    return useQuery(['AllFactories',token],getAllResources,{
        refetchOnWindowFocus:false
    })
}

export const useFactoriesByUser = () =>{
    const {data:user} = useUser()
    const factories = useAccountManagerFactories(user?.email!)
    const allFactories = useAllFactories()

    
    switch (user?.type) {
        case 'SA':
            if(allFactories)return allFactories
            else return{data:[],status:'idle'}
        case 'A':
            if(allFactories)return allFactories
            else return{data:[],status:'idle'}
        case 'AC':
            if(factories)return factories
            else return{data:[],status:'idle'}
        case 'S': 
            if(factories)return factories
            else return{data:[],status:'idle'}
        default:
            return{data:[],status:'idle'}
    }
}



//react-query Mutations
//---------------------------------------------


 const useAssignCancelClientAccount = (config:any) =>{
    return useMutation(assignCancelClientAccount,config)
}

export const useCreateFactory = (config?:{}) => {

    const token = useToken()

    const [mutation,data] = useMutation(CreateFactory,{
        onSuccess:()=> factoriesCRUDDependencies.forEach((dependency)=>{
            queryCache.invalidateQueries(dependency)
        })
    })
    const createFactory = (data:FactoryInterface) => {
        return mutation({
            token,
            data
        })
    }
    const status = useStatusProcessor(data)

    return{
        createFactory,
        ...data,
        ...status
    }
}

//custom HOOKS
//---------------------------------------------

 export const useAssignCancelFactory = () => {
    const token = useToken()
    const [handleClientAccount,query] = useAssignCancelClientAccount({
        onSuccess:()=> factoriesCRUDDependencies.forEach((dependency)=>{
            queryCache.invalidateQueries(dependency)
        })
    })

    const assignFactory = ({email,tagF}:{email:string,tagF:string}) => {
        const data = {
            email,
            tagF,
            state:'A'
        }
        /* console.log('entra') */
        return handleClientAccount({data,token})
    }

    const cancelFactory = ({email,tagF}:{email:string,tagF:string})=>{
        const data = {
            email,
            tagF,
            state:'C'
        }
        return handleClientAccount({data,token})
    }

    

    return {
        assignFactory,
        cancelFactory,
        query
    }

    
}

//pbi.service



