import axios, { AxiosRequestConfig } from 'axios'
import { useQuery } from 'react-query'
import { URL } from '../../../infrastructure/appConfig/appConfig'
import { useToken } from '../../session/service.session'
import { useStatusProcessor } from './../../errors/services/StatusProcessor';
import { useMutation } from 'react-query';
import { MutationQueryObject } from '../../../infrastructure/Interfaces'
import { useMemo, useState } from 'react'
import { LubricationPointInterface } from '../../equipments/services/service.lubricationPoints'
import { queryCache } from 'react-query';
import { CriticalityInterface } from '../../criticalities/types/types';

//-----------------------------
//TYPESCRIPT---------------
//-----------------------------

export interface InfoLubricationPointInterface{
    id?: number
    tagTGD: string
    supplies: string[]
    labelReplacement: boolean
    lastLubrication: string
    lubricantQuantity:number
    leak: boolean
    pending: boolean
    updatedToday: boolean
    user: string
    lastSurveyId: number
    assetCriticality: string
    inaccessible: boolean
    tagFP: string
    lastInspectionDate: string
    updateReview: boolean
    temperature:number
}

//-----------------------------
//axios---------------
//-----------------------------


export const EquipmentsInfoByCriticality = (_:string,data:any,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/EquipmentsInfoByCriticality`,
        data

    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const AssignCanceInfoEquipmentSupplies = ({token,data}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/AssignCancelInfoEquipmentSupplies`,
        data

    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

//-----------------------------
//Queries---------------
//-----------------------------

export const useEquipmentsInfoByCriticality = (criticality?:CriticalityInterface) => {
    const token = useToken()

    const dataQuery = {
        tagFP:criticality?.tagFP,
        criticality:criticality?.description
    }

    return useQuery(['EquipmentsInfoByCriticality',dataQuery,token],EquipmentsInfoByCriticality,{
        enabled:!!token || !!criticality,
        refetchOnWindowFocus:false
    })

}




//-----------------------------
//MUTATIONS---------------
//-----------------------------


export const useAssignCanceInfoEquipmentSupplies = (lubricationPoint:LubricationPointInterface) => {

    //types
    const types = {
        assign:'A',
        cancel:'C'
    }

    //hookState
    const [,setType] = useState<any>()
    
    //mutate
    const [mutationAssign,dataAssign] = useMutation(AssignCanceInfoEquipmentSupplies,{
        onSuccess:()=>{
            queryCache.invalidateQueries('EquipmentsInfoByCriticality')
            queryCache.invalidateQueries('DailyOperatorLubricationPoints')
            queryCache.invalidateQueries('AllLubricationPointsAndInfoByTagFP')
        }   
    })

    const [mutationCancel,dataCancel] = useMutation(AssignCanceInfoEquipmentSupplies,{
        onSuccess:()=>{
            queryCache.invalidateQueries('EquipmentsInfoByCriticality')
            queryCache.invalidateQueries('DailyOperatorLubricationPoints')
            queryCache.invalidateQueries('AllLubricationPointsAndInfoByTagFP')
        }   
    })
    
    //needed data
    const token = useToken()
    const _assignStatus = useStatusProcessor(dataAssign)
    const _cancelStatus = useStatusProcessor(dataCancel)

    const status = useMemo(()=>({
        assignStatus:_assignStatus,
        cancelStatus:_cancelStatus
    }),[_assignStatus, _cancelStatus])

    const tagTGD = lubricationPoint.tagTGD
    const tagFP = lubricationPoint.tagFP

    //Methods
    const assignAll = (supplies:string[]) => {
        supplies.forEach(item => {
            console.log({
                token,
                data:item
            })
        })
    }

    const assignSupply = (supply:string) => {
        setType(types.assign)
        mutationAssign({
            token,
            data:{
                tagTGD,
                tagFP,
                supply,
                state:types.assign
            }
        })
    }

    const deleteSupply = (supply:string) => {
        setType(types.cancel)
        mutationCancel({
            token,
            data:{
                tagTGD,
                tagFP,
                supply,
                state:types.cancel
            }
        })
    }

    //status cofigurations


    //Public methods
    return {
        assignSupply,
        deleteSupply,
        assignAll,
        dataAssign,
        dataCancel,
        ...status

    }
}

