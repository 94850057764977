import React from 'react';
import DeleteElementForm from '../DeleteElementForm';
import { PlantInterface } from '../../plants/service.plant';
import {  gridModelElement, usePlantElements } from '../services/service.elements';
import AnyGrid, { CarrousellActionInterface } from '../../../components/grids/AnyGrid';
import { useLangLabels } from '../../language/services/useSetLabel';
import CUElementForm from './../CUElementForm';
import { CrudFormType } from '../../../infrastructure/form/typescript/FormTypes';
import { ElementInterface } from '../types/ElementTypes';



interface Props {
    plant:PlantInterface
}

const carrousellActions:CarrousellActionInterface[] = [
    {icon:'edit',component:(item:ElementInterface)=><CUElementForm tagFP={item.tagFP} actionType={CrudFormType.update} item={item}/>}
]

const ManageElementsPage:React.FC<Props>  = ({plant}) => {
    
    const {data:elements} = usePlantElements(plant.tagFP)
    const {lang} = useLangLabels()


    const renderOption = (item:ElementInterface,items:any) => <>
        <DeleteElementForm item={item} />
    </>
    
    return(     
        <AnyGrid
        gridModel={gridModelElement}
        title={lang.elements.title.plural}
        height='55vh'
        carrousellActions={carrousellActions}
        items={elements || []}
        loading={elements ? false : true}
        itemActions={renderOption}
        headerActions={(item:any)=><CUElementForm tagFP={plant.tagFP} actionType={CrudFormType.create} />}
        />
    )
}

export default React.memo(ManageElementsPage);
