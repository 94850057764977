import React from 'react';
import { useUser } from '../../persons/services/service.person';
import { useLangLabels } from './../../language/services/useSetLabel';
import SimpleAutocompleteSelect from '../../../components/autocomplete/SimpleAutocompleteSelect';
import CUElementForm from '../CUElementForm';
import { CrudFormType } from '../../../infrastructure/form/typescript/FormTypes';
import { useElementsByPlant } from './../../auxiliar-data/service.aux';
import { ElementInterface } from '../types/ElementTypes';

interface props{
    error?:string | undefined 
    tagFP:string
    onChange:(value:any)=>void
    defaultValue?:string | undefined | null
    complete?:boolean
}

const SelectElement:React.FC<props> = ({error, tagFP, onChange, defaultValue, complete}) => {

    const {lang} = useLangLabels()
    const showTitle = 'element'
    const label = lang.elements.title.singular
    const {data:user} = useUser()
    const {data:elements} = useElementsByPlant(tagFP)
    
 
    const handleChange = (value:string) => {
        if(complete){
            const resultComplete = elements?.find((a)=>a[showTitle] === value) as ElementInterface
            onChange(resultComplete)
        }else{
            const result = value as string 
            onChange(result)
        }
    }
    
   
    return (<>
            {/* <SelectSimpleEntity
            defaultValue={defaultValue}
            error={error}
            entityList={elements}
            label={label || lang.elements.title.singular}
            onChange={onSelect}
            showTitle={lang.elements.title.plural}
            >
                {user?.type !== 'O' && <CreateElementForm tagFP={tagFP!}/> }
            </SelectSimpleEntity> */}
            <SimpleAutocompleteSelect
                {...{
                    error,
                    label,
                    showTitle,
                    defaultValue,
                    items:elements || [],
                    onChange:handleChange,
                }}
            >
                {user?.type !== 'O' && <CUElementForm actionType={CrudFormType.create} tagFP={tagFP}/>}
            </SimpleAutocompleteSelect>
    </>);

}

export default React.memo(SelectElement);
