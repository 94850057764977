
export enum CrudFormType{
    create='create',
    update='update',
    delete='delete',
}

export enum InputComponentTypes {
    none='none',
    number='number',
    text='text',
    date = 'date',
    description = 'description',
    lubricant='lubricant',
    criticality = 'criticality',
    color = 'color',
    criticalityType = 'criticalityType',
    types = 'types',
    typeBy = 'typeBy',
    sector = 'sector',
    area = 'area',
    component ='component',
    supply ='supply',
    supplyType ='SupplyType',
    operator = 'operator',
    periodicity = 'periodicity',
} 

type FormValueRequired = {
    value:boolean
    message:string
}

type FormValuePattern = {
    value:RegExp
    message:string
}

type FormValueValidate = (formValue?:any) => true | string


type FormValueMaxMin = {
    value:number
    message:string
}

export type FormErrors<T> = {
    [k in keyof T]?:string
} /* | {} */

export type FormField<T> = {
    label?:string,
    placeholder?:string,
    value?:any
    helper?:string
    component:InputComponentTypes.none
    validations?:{
        required?:FormValueRequired
        pattern?:FormValuePattern
        validate?:FormValueValidate
        min?:FormValueMaxMin
        max?:FormValueMaxMin
    }
    error?:string
    name?:T
} | {
    label:string,
    placeholder:string,
    helper?:string
    value:any
    disabled?:boolean
    component:InputComponentTypes.lubricant 
        | InputComponentTypes.criticality 
        | InputComponentTypes.typeBy 
        | InputComponentTypes.sector 
        | InputComponentTypes.area 
        | InputComponentTypes.component 
        | InputComponentTypes.supply 
        | InputComponentTypes.operator 
    inputConfig:{
        complete?:boolean,
        tagFP:string
        by?:{
            value:string
            key:string
        }
    }
    validations?:{
        required?:FormValueRequired
        pattern?:FormValuePattern
        validate?:FormValueValidate
        min?:FormValueMaxMin
        max?:FormValueMaxMin
    }
    error?:string
    name?:T
} | {
    label:string,
    placeholder:string,
    value:any
    helper?:string
    disabled?:boolean
    component:InputComponentTypes.color 
    | InputComponentTypes.criticalityType 
    | InputComponentTypes.types 
    | InputComponentTypes.supplyType
    | InputComponentTypes.periodicity
    validations?:{
        required?:FormValueRequired
        pattern?:FormValuePattern
        validate?:FormValueValidate
        min?:FormValueMaxMin
        max?:FormValueMaxMin
    }
    error?:string
    name?:T
} | {
    helper?:string
    label:string,
    placeholder:string,
    value:any
    disabled?:boolean
    readonly?:boolean
    component:InputComponentTypes.text | InputComponentTypes.number | InputComponentTypes.date
    validations?:{
        required?:FormValueRequired
        pattern?:FormValuePattern
        validate?:FormValueValidate
        min?:FormValueMaxMin
        max?:FormValueMaxMin
    }
    error?:string
    name?:T
} | {
    helper?:string
    label:string,
    placeholder:string,
    value:any
    disabled?:boolean
    readonly?:boolean
    component:InputComponentTypes.description
    rows:number
    validations?:{
        required?:FormValueRequired
        pattern?:FormValuePattern
        validate?:FormValueValidate
        min?:FormValueMaxMin
        max?:FormValueMaxMin
    }
    error?:string
    name?:T
    
}

export type FormFields<T> ={
    [k in keyof T]:FormField<k>
}

export type InitialState<T> = {
    fields:FormFields<T>
    current?:number
    items?:T[]
}

export type FormDataResult<T> = T

type onSuccesCallback<T> = (formData:T)=>void
type onErrorCallback<T> = (formerrors:FormErrors<T> )=>void 

export type FormConfigCallbacks<T> = [
    onSuccesCallback<T>,
    onErrorCallback<T> | undefined
]

export type FormInitialState<T> = InitialState<T> & {
    errors: FormErrors<T> 
    isValidated:boolean,
    callbacks:{
        callbackSuccess:onSuccesCallback<T>
        callbackError:onErrorCallback<T>
    }
}

export type FormReducerType<T> = (state:FormInitialState<T>,action:FORM_ACTION_TYPES<T>) => FormInitialState<T> 


export type uiModelInterface<T> = FormField<T> & {
    onChange:(value?:any) => void
}



export type FORM_ACTION_TYPES<T> = {
    type:'SET_VALUE',
    payload:{
        name:keyof T,
        value:T[keyof T]
    }
} | {
    type:'VALIDATE_ALL',
    payload:FormConfigCallbacks<T>
} | {
    type:'RESET_ALL',
    payload:FormInitialState<T>
} | {
    type:'CONFIG_CALLBACKS',
    payload:FormConfigCallbacks<T>
} | {
    type:'NEXT',
} | {
    type:'PREV',
}