
import React from 'react'
import { PlantInterface } from '../../plants/service.plant';
import { useState } from 'react';
import { useEquipmentsByTagFP } from '../../equipments/services/service.lubricationPoints';
import TgdSelectableTable from '../../../components/grids/TgdSelectableTable';
import { Alert } from '@material-ui/lab';
import { GridColumns } from '@material-ui/data-grid';
import { useAssignMultipleSuppliesToMultipleEquipments,useSuppliesByType } from '../services/service.supplies';
import CustomButton from '../../../components/buttons/CustomButton';
import ModalButton from '../../../components/buttons/ModalButton';
import FlexContainer from '../../../components/presentational/FlexContainer';
import { useModal } from './../../../utils/hooks/useModal';
import { SupplyType } from '../types/SupplyTypes';

const AssignCancelMultipleSupplies:React.FC<{plant:PlantInterface,type:SupplyType}> = ({plant,type}) => {

    const [step,setStep] = useState(1)
    const {modal,handleModal} = useModal()
    const {data:equipments} = useEquipmentsByTagFP(plant.tagFP)
    const {data:supplies} = useSuppliesByType(type)
    const [selectedEquipments, setSelectedEquipments ] = useState([])
    const [selectedSupplies, setSelectedSupplies ] = useState([])


    const { handleAssigCancel,status,reset } = useAssignMultipleSuppliesToMultipleEquipments()

    const equipmentColumns:GridColumns = [
        {headerName:'Tag TGD',field:'tagTGD',width:170},
        {headerName:'Sector',field:'sector',width:170},
        {headerName:'Tag Planta',field:'plantTag',width:170},
        {headerName:'Equipo',field:'equipment',width:170},
        {headerName:'Tipo',field:'type',width:170},
        {headerName:'Criticidad',field:'criticality',width:170},
    ]
    
    const suppliesColumns:GridColumns = [
        {headerName:'ID',field:'id'},
        {headerName:'Herramienta',field:'supply'},
        {headerName:'Descripción',field:'description',width:300},
      
    ]
    
    const handleAssign = () =>{
            handleAssigCancel(selectedEquipments,selectedSupplies,'A')
    }
    
  const handleReset = () => {
    reset()
    setSelectedEquipments([])
    setSelectedSupplies([])
    setStep(1)
  }

  const handleClose = () => {
    handleReset()
    handleModal()
}

    return(
        <ModalButton icon='tool' title='Asignar insumos a equipos' onClick={reset} onClose={handleClose}>
            {status === 'error' && <><Alert severity='error'>Ha ocurrido un error</Alert></>}
            {status === 'success' && <><Alert>Asignaciones realizadas</Alert></>}
            {step === 1 &&
                <TgdSelectableTable
                    title='Seleccionar equipos a asignar'
                    items={equipments} 
                    columns={equipmentColumns}
                    headerActions={
                        <CustomButton icon='next' popoverTitle='Próximo paso' {...(selectedEquipments?.length > 0) ? {disabled:false} : {disabled:true}} action={()=>setStep(2)} />
                    }
                    handleChange={(items)=>setSelectedEquipments(items)}
                />
            }
            
            {step === 2 && 
                <TgdSelectableTable
                    title='Rutas a asignar'
                    items={supplies} 
                    columns={suppliesColumns}
                    headerActions={<FlexContainer>
                        <CustomButton icon='before' popoverTitle='Atras(resetear)' action={handleReset}/>
                        <CustomButton icon='save' popoverTitle='Guardar asiganciones' status={status} {...(selectedSupplies?.length > 0) ? {disabled:false} : {disabled:true}} action={handleAssign} />
                    </FlexContainer>}
                    handleChange={(items)=>setSelectedSupplies(items)}
                />

            }
        </ModalButton>  

    )
}
export default React.memo(AssignCancelMultipleSupplies)