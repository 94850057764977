import React from 'react';
import { Paper, Typography, Divider, ListItemText, Box, TextField } from '@material-ui/core';
import FlexContainer from '../../../components/presentational/FlexContainer';
import ComponentMode from '../../../components/ComponentMode';
import { SensorsCardGrid } from '../pages/RealtimeSensoringPage';
import { STIEquipmentInterface } from './../STIEquipments/interfaces/STIEquipmentInterfaces';
import { useSensorizedInfoByEquipment } from './../STISensorizedEquipments/services/STISensorizedEquipmentService';
import { useState } from 'react';
import { useFilteredSensorizedSurveys, useSensorizationSurveysByTagFP } from '../api/stiHystoricSurveysService';
import LineChart from '../../../infrastructure/chart.js/charts/LineChart';
import { useCriticalitiesByPlant } from '../../auxiliar-data/service.aux';
import TgdPopover from '../../../components/popovers/TgdPopover';
import { useSensorizedInfoByAddress } from '../api/SensoringService';
import SensorCard from './SensorCard';
import { usePlantConfigWorkspace } from '../../../infrastructure/tgd.config';
import TgdTabs from './../../../components/Tabs/TgdTabs';
import { useDate } from '../../../infrastructure/date/useDate';
import { CriticalityInterface } from '../../criticalities/types/types';

const FeaturedSensor:React.FC<{address:string,tagFP:string}> = ({address,tagFP}) => {
    
    usePlantConfigWorkspace()
    const {data} = useSensorizedInfoByAddress(address)
    const enabled = !!data?.sensorSurvey

    return(<>
        {enabled && 
            <SensorCard realtimeChart={false} sensorData={data} tagFP={tagFP}/>
        }
    </>)
}

export const AssetImportance:React.FC<{criticality:CriticalityInterface | undefined}> = ({criticality}) => {
    return(
        <TgdPopover
        mode='hover' title={criticality?.description!}>
            <FlexContainer 
            margin='4px' 
            borderRadius='8px' 
            padding='8px' 
            height='48px' 
            width='150px'  
            background={criticality?.color} 
            align='center' 
            justify='center' 
            color='white'>
                <div style={{width:'80px'}}>Importancia</div>
                <Typography color='inherit' align='left' variant='h6'>{criticality?.number} </Typography>
            </FlexContainer>
        </TgdPopover>
    )
}



//const Last100 = (tagFP:string) => {
//    
//    const {data:prueba} = useSensorizationSurveysByTagFP({
//        tagFP,
//        surveysQuantity:100
//    })
//
//    const chart = prueba ? prueba[0] : undefined
//
//
//    const yData = chart && {
//        temperature:chart.celsiusDegrees,
//        rmsX:chart.rmsX,
//        rmsY:chart.rmsY,
//        rmsZ:chart.rmsZ,
//    }
//
//    const xData = chart?.dateTime
//
//    
//    return(
//        <FlexContainer >
//            {yData && xData &&
//            <LineChart
//                chartValues={yData}
//                xAxis={xData}
//                xOperation={(value)=>value.slice(0,10)}
//            />}
//        </FlexContainer>
//    )
//}



const SensorHistoryData:React.FC<{period:number,address:string,tagFP:string}> = ({period, address, tagFP}) => {

    const {nowDate} = useDate()
    const [date,setDate] = useState(period === 0 ? nowDate : '')

    const {data:chartData} = useFilteredSensorizedSurveys({
        period,
        date,
        address,
        tagFP
    })

    

    const channels:any = chartData?.channels?.map(item => JSON.parse(item)).reduce((acc,item,index)=>{
        return{
            channel_1:[...acc.channel_1,item.channel_1],
            channel_2:[...acc.channel_2,item.channel_2],
            channel_3:[...acc.channel_3,item.channel_3],
        }
    }, Object({
        channel_1:[],
        channel_2:[],
        channel_3:[]
    }))

   

    const yData = {
        ...(chartData?.sensorType === 'TEMPERATURA Y VIBRACION'
            ?{
                rmsX:chartData.rmsX,
                rmsY:chartData.rmsY,
                rmsZ:chartData.rmsZ,
                maxX:chartData.maxX,
                maxY:chartData.maxY,
                maxZ:chartData.maxZ,
                minX:chartData.minX,
                minY:chartData.minY,
                minZ:chartData.minZ,
                temperature:chartData?.celsiusDegrees,
            }

        :  chartData?.sensorType === 'PRESION'
            ?{
                psi:chartData.psiPressure,
                temperature:chartData?.celsiusDegrees,
            }

        : chartData?.sensorType === 'CORRIENTE'
            ?{
                channel_1:channels.channel_1,
                channel_2:channels.channel_2,
                channel_3:channels.channel_3,
            }
        : chartData?.sensorType === 'TEMPERATURA'
            ?{
                temperature:chartData?.celsiusDegrees,
            }
        :undefined),
        battery:chartData?.sensorBattery,
        

    }

    const xData = chartData?.dateTime

    return(<>
        {period === 0 && <TextField type='date' value={date} onChange={(value)=>setDate(value.target.value)} /> }
        <FlexContainer >
            {yData && xData &&
            <LineChart
                key={date}
                chartValues={yData}
                xAxis={xData}
                xOperation={(value)=>value.slice(0,10)}
            />}
        </FlexContainer>
        </>)
}



interface SensorizedLayautProps{
    equipment:STIEquipmentInterface
}

const SensorizedLayaut:React.FC<SensorizedLayautProps> = ({equipment}) => {

    
    const tagFP = equipment.tagFP
    const {data:sensorsInfoByEquipment} = useSensorizedInfoByEquipment(equipment)
    const {data:criticalities} = useCriticalitiesByPlant(tagFP)
    const criticality = criticalities?.filter((item:CriticalityInterface) => item.description === equipment.criticality)[0]

    const [selectedSensorAddress,setSelectedSensor] = useState<string>('')

    const featuredSensor = sensorsInfoByEquipment?.find((sensor)=>
        sensor.sensorAddress === selectedSensorAddress
    )

    const tabContent = [
        {
            label:'7 días atras',
            period:7
        },
        {
            label:'15 días atras',
            period:15
        },
        {
            label:'30 días atras',
            period:30
        },
        {
            label:'Personalizado',
            period:0
        }
    ]

    return(
        <ComponentMode
            
            variant='iconButton'
            icon='info'
        >
            {/* header */}
            <FlexContainer align='center' justify='space-between'>
                <ListItemText primary={equipment.equipment} secondary={equipment.tagSTI} />    
                <AssetImportance {...{criticality}} />
            </FlexContainer>
            <Divider/>

            

            {/* content - selectedsensor  */}
            {featuredSensor &&
                <FlexContainer key={selectedSensorAddress} padding='16px 0px' gap='24px'>
                    <FlexContainer  height='250px' width='100%' padding='16px' border='solid 1px #e6e6e6' flexDirection='column'>
                        <Typography variant='button'>{featuredSensor?.sensorizedEquipment.sensorType}</Typography>
                        <Divider/>
                        <FeaturedSensor tagFP={tagFP} address={selectedSensorAddress} />
                    </FlexContainer>
                    <Box  style ={{width:'calc(100% - 140px)'}}>
                        <TgdTabs {...{
                            items:tabContent,
                            labelIterator:'label',
                            content:(item)=><SensorHistoryData period={item.period} address={selectedSensorAddress} tagFP={tagFP} />,
                        }}/>
                    </Box>
                </FlexContainer>
            }

            {/* Footer - Sensor List */}
            <Divider/>
            <SensorsCardGrid 
            onChange={(item)=>setSelectedSensor(item)} 
            tagFP={tagFP} 
            defaultSensorSurveys={sensorsInfoByEquipment} 
            realtimeChart={false}
            />

        </ComponentMode>
    )
}




interface Props {
    equipment:STIEquipmentInterface 
}

const SensorizedEquipmentCard:React.FC<Props> = ({equipment}) => {

    const {data:sensorsInfoByEquipment} = useSensorizedInfoByEquipment(equipment)
   
    return (
        <Paper style={{borderLeft:'solod 4px red'}}>
            {/* toolbar */}
            <FlexContainer align='center'  padding={'0px 8px'} justify='space-between'>
                <Typography>
                    {equipment.equipment} - {equipment.tagSTI}
                </Typography>
                <SensorizedLayaut {...{equipment}}/>
            </FlexContainer>
            <Divider/>
            {/* content */}
            <FlexContainer padding={'8px'} >
                <SensorsCardGrid 
                gridConfig={{
                    lg:4,
                    md:6,
                    sm:6,
                    xs:6
                }}
                tagFP={equipment.tagFP} 
                defaultSensorSurveys={sensorsInfoByEquipment} 
                realtimeChart={true}
                />
            </FlexContainer>
        </Paper>
    );
}

export default SensorizedEquipmentCard;
