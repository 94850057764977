
import {getTokenVerification, getAuth} from '../../infrastructure/axios/service.tgd.axios'
import { useQuery,useMutation, queryCache} from 'react-query';
import { useHistory } from 'react-router-dom';
import { USER_ROUTES } from '../../infrastructure/routes/routes';
import {  useCallback } from 'react';

import axios, { AxiosRequestConfig } from 'axios';
import { URL } from '../../infrastructure/appConfig/appConfig';
import { usePlantConfigWorkspace } from '../../infrastructure/tgd.config';




export interface AuthInterface{
    email:string
    password:string
}

//----------------------------
//AXIOS REPO
//----------------------------

export const RefreshSession = (token:string) => {
    axios.defaults.headers.get['Authorization'] = `Bearer ${token}`

    const config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/RefreshSession`,
    }

    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}


//----------------------------
//react-query HOOKS
//----------------------------

export const useRefreshSession = ()=> {
    const token = useToken()
    const [mutation,query] = useMutation(RefreshSession,{
        onSuccess:(data)=>{
            sessionStorage.setItem('token',data.token)
            queryCache.setQueryData('token',data.token)
        }
    })

    const refreshToken = ()=> {
        return mutation(token)
    }

    return {refreshToken,query}
}




export const useActivityLog = () => {

    const {refreshToken} = useRefreshSession()
    const {isStale} = useQuery('token',{
        initialStale:false,
    })

    const updater = useCallback(()=>{
        if(isStale){
            //vencido
            console.log('Renovando sesion')
            //renew
            refreshToken()
        }
     },[isStale,refreshToken])

     return { updater }

}

export const useLocalstorageStaleTime = () => {
    //monitoring localstorage staleTime
    const date = new Date().toLocaleDateString()
    const localDate= localStorage.getItem('localStorageStaleTime')
    if(localDate !== date) {
        localStorage.clear()
        localStorage.setItem('localStorageStaleTime',date)
    }
}

export const useToken = ():string =>{
    useLocalstorageStaleTime()
    const {data:token} =  useQuery('token',()=>{
        return sessionStorage.getItem('token') 
    },{
        staleTime:9000000
    })
    return token || ''
}

export const useValidateToken = () => {
    const token = useToken()
    const {data,status} = useQuery(['ValidateJwtToken',token],getTokenVerification,{
        enabled:token,
        retry:0,
        refetchOnWindowFocus:false,
        onError:(data:any)=>{
            console.log(data)
        },
        
    })
    return [data,status,token]
}

//----------------------------
//CUSTOM HOOKS
//----------------------------

export const useLogin = () => {
    const [mutate,data] = useMutation(getAuth,{
        onSuccess:(data)=>{
            sessionStorage.setItem('token',data)
            queryCache.invalidateQueries('token')
        }
    })
    const history = useHistory()


    const login = (values:AuthInterface) =>{
        mutate(values)
    }
    return {data,login}
}

export const useLogout = () => {
    const history = useHistory()
    const logout = () =>{
        sessionStorage.clear()
        history.push(USER_ROUTES.login)
    }

    return [logout]
}