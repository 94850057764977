import React,{ useState } from 'react';
import { Button/*,  ListItem, ListItemText */ } from '@material-ui/core';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
/* import TgdRadioButton from '../../TgdRadioButton';
import SelectSimpleEntity from '../../../../components/select/SelectSimpleEntity';
import FlexContainer from '../../../../components/presentational/FlexContainer'; */
import { gridModelLubricationPoints, LubricationPointInterface, useAllLubricationPointsAndInfoByTagFP } from '../../../equipments/services/service.lubricationPoints';
import { useOperatorSupervisorTagFP } from '../../../persons/services/service.person';
import TgdModal from '../../../../components/modals/TgdModal';
import PageContainer from '../../../../components/pageContainers/PageContainer';
import { useOutOfRouteModal } from './services/colector.surveyService';
import AnyGrid from '../../../../components/grids/AnyGrid';
import CustomButton from '../../../../components/buttons/CustomButton';
import OutfRouteListSurvey from './OutfRouteListSurvey';
import { objectMapper } from './../../../../utils/objectMapper';

//const SelectLubricationPointToSurvey:React.FC<{onChange:(item:any) =>void,items:LubricationPointInterface[] | undefined}> = ({onChange,items}) => {
//
//    const [filter,setFilter] = useState('plantTag')
//
//    return(<>
//        
//
//        <TgdRadioButton
//        fieldsFilter={['plantTag', 'component', 'element', 'sector']}
//        handleChange={setFilter} 
//        defaultOption={filter} 
//        items={(items && Object.keys(items[0]) ) || []}/>
//        {/* <CheckBoxAutoItems 
//            fieldsFilter={fieldRestrictionByUser} 
//            items={items} 
//            handleChange={setFilter} 
//            defaultOption={filter}
//        /> */}
//
//        <SelectSimpleEntity
//            color={'primary'}
//            label={ filter}
//            onChange={onChange}
//            entityList={items || []}
//            showTitle={filter}
//            selector='tagTGD'
//            renderOption={(option:LubricationPointInterface)=>
//                <ListItem dense >
//                    <FlexContainer>
//                        <ListItemText style={{width:'100px'}}>{option.plantTag}</ListItemText> 
//                        <ListItemText style={{width:'100px'}}>{option.component}</ListItemText> 
//                        <ListItemText style={{width:'100px'}}>{option.element}</ListItemText> 
//                        <ListItemText style={{width:'100px'}}>{option.sector}</ListItemText>  
//                    </FlexContainer>
//                </ListItem>
//            }
//        />
//    </>)
//}



interface Props{
}
const OutOfRouteSurvey:React.FC<Props> = () => {

   const outOfRoute = true
    const [modal,setModal] = useOutOfRouteModal()

    //OTHERS
    const [pointToSurvey,setPointToSurvey] = useState<LubricationPointInterface | undefined>(undefined)
    const [pointListToSurvey,setPointListToSurvey] = useState< any[] | undefined >(undefined)
    const tagFP = useOperatorSupervisorTagFP()
    const {data:LubricationPointsAndInfo} = useAllLubricationPointsAndInfoByTagFP(tagFP)
    const pointsMapped = objectMapper(LubricationPointsAndInfo,'tagTGD')


    const handleClose = () => {
        setModal(!modal)
    }

    const handleFinish = () => {
        //handleClose()
        setPointToSurvey(undefined)
    }

    
    if(!pointsMapped) return <>cargando...</>
    return (
        pointsMapped 
            ? <>{!pointToSurvey 
                ?   <TgdModal
                        fullWidth={true}
                       /*  title='Eligue un punto lubricacion fuera de ruta' */
                        open = {!!modal} 
                        handleClose = {handleClose} 
                        children={<div style={{height:'60vh'}}>

                            <AnyGrid
                                gridModel={gridModelLubricationPoints}
                                items={LubricationPointsAndInfo || []}
                                loading={!LubricationPointsAndInfo}
                                title='Selecciona los puntos fuera de ruta a relevar'
                                itemActions={(item,items)=><CustomButton color='secondary' popoverTitle='Relevar todos los equipos en la lista' icon='surveyPlay' variant='iconButton' action={()=>{
                                    setPointToSurvey(item)
                                    setPointListToSurvey(items)
                                }}/>}
                            />
                            {/* <SelectLubricationPointToSurvey onChange={setPointToSurvey} items={LubricationPointsAndInfo} />  */}
                        </div>
                    }/> 
            
                :   <OutfRouteListSurvey
                        currentItem={pointToSurvey}
                        items={pointListToSurvey || []} 
                        open={modal} 
                        handleClose={handleClose}
                        handleFinish={handleFinish} 
                        mode='content'
                        outOfRoute={outOfRoute}
                        exitButton={<Button color='inherit' variant='outlined' onClick={()=>setPointToSurvey(undefined)} ><SwapHorizIcon/></Button>}
                    />
            }</> 

        :   <>
                <PageContainer>
                    cargando...
                </PageContainer>
            </>
    );
}


export default React.memo(OutOfRouteSurvey);


