
import React from 'react'

import  {ReactComponent as InventoryIcon1} from '../../../../../assets/icons/svg/inventoryIcon.svg'
import  {ReactComponent as HelmetIcon1} from '../../../../../assets/icons/svg/Helmet.svg'
import  {ReactComponent as OilCanIcon1} from '../../../../../assets/icons/svg/oilCanIcon.svg'
import  {ReactComponent as Permissions1} from '../../../../../assets/icons/svg/Permissions.svg'

import { useDailySupplies } from '../../supplies/service/serviceSupplies'
import { USER_ROUTES } from '../../../../../infrastructure/routes/routes'
import { useDailyPermissions } from '../../permissions/services/servicePermissions'
import { useLocalStorage } from '../../../../../infrastructure/localStorage/useLocalStorage'
import { CheckListItemsType } from '../interfaces/checkListInterfaces'
import { CustomIcon } from '../../../../../components/buttons/CustomButton'
import { useDailyLubricants } from '../../lubricants/services/colector.lubricantsService'
import { usePerson } from './../../../../persons/services/service.person';
import { useSupervisorSelectedLubricatorData } from './../../../pages/SupervisorColectorPage';
import { EnumSupplyTypes } from '../../../../supply/types/SupplyTypes'


const checkItems:{[key:string]:CheckListItemsType} = {
    consumable:EnumSupplyTypes.consumable,
    tool:EnumSupplyTypes.tool,
    protection:EnumSupplyTypes.protection,
    lubricant:'LUBRICANTE',
    permission:'PERMISO',
}


export const colectorHomeSupplies = ():CheckListItemsType[] =>{
    return [
        checkItems.consumable,
        checkItems.tool,
        checkItems.protection,
        checkItems.lubricant,
        checkItems.permission
    ]
} 

export const usecheckedItemsIcons = () => ({
    [checkItems.tool]: <CustomIcon icon='tool'/>,
    [checkItems.consumable]: <CustomIcon icon='inventory' style={{width:'80px'}} />,
    [checkItems.protection]: <CustomIcon icon='helmet' />,
    [checkItems.permission]: <CustomIcon icon='permission' />,
    [checkItems.lubricant]: <CustomIcon icon='oilCan' />
})



export const colectorHomeRoutes = () => ({
    [checkItems.tool]: USER_ROUTES.dailyTools,
    [checkItems.consumable]: USER_ROUTES.dailyConsumables,
    [checkItems.protection]: USER_ROUTES.dailyProtection,
    [checkItems.permission]: USER_ROUTES.dailyPermissions,
    [checkItems.lubricant]: USER_ROUTES.dailyLubricants
})

export const checkListsColectorListConfig = () => ({
    [checkItems.tool]: {
        iterator:'supply',
        primaryText:['supply'],
        secondaryText:[''],
    },
    [checkItems.consumable]: {
        iterator:'supply',
        primaryText:['supply'],
        secondaryText:[''],
    },
    [checkItems.protection]: {
        iterator:'supply',
        primaryText:['supply'],
        secondaryText:[''],
    },
    [checkItems.permission]: {
        iterator:'permission',
        primaryText:['permissionName'],
        secondaryText:[''],
    },
    [checkItems.lubricant]: {
        iterator:'lubricant',
        primaryText:['lubricant',],
        secondaryText:['type',],
    }
})

export const useCheckListData = () => {

    const icons = usecheckedItemsIcons()
    const ColectorHomeSupplies = colectorHomeSupplies()
    const ColectorHomeRoutes = colectorHomeRoutes()
    const list = checkListsColectorListConfig()
    
    const {data:permissions} = useDailyPermissions()
    const lubricants = useDailyLubricants()
    const {data:tools} = useDailySupplies(EnumSupplyTypes.tool)
    const {data:consumables} = useDailySupplies(EnumSupplyTypes.consumable)
    const {data:protections} = useDailySupplies(EnumSupplyTypes.protection)

    const {data:person} = usePerson()
    const {data:LubricatorData} = useSupervisorSelectedLubricatorData()


    //constructing data key to diferentiate concerns on high level users
    const lubricatorNumber = person?.role === 'Operario'
        ? person.lubricatorNumber
        : LubricatorData?.lubricatorNumber

    const LocalStateKey = `CollectorCollectedSupplies-${lubricatorNumber}`

    const [ colectedColectedSupplies, setColectedColectedSupplies ] = useLocalStorage<{[key:string]:{value:any,picked:any[]}}>(LocalStateKey,{
            [checkItems.tool]: {
                value:tools || [],
                picked:[]
            },
            [checkItems.consumable]:{
                value:consumables || [],
                picked:[]
            },
            [checkItems.protection]:{
                value:protections || [],
                picked:[]
            },
            [checkItems.permission]:{
                value:permissions || [],
                picked:[]
            },
            [checkItems.lubricant]:{
                value:lubricants || [],
                picked:[]
            }
        },{
            enabled:(!!tools && !!consumables && !!protections && !!permissions && !!lubricants && !!lubricatorNumber),
            unique:true
        })

    const formModel = {
        [checkItems.tool]:{
            name:{
                singular:{
                    key:'',
                    default:'Herramienta'
                },
                plural:{
                    key:'',
                    default:'Herramientas'
                }
            },
            icon:<CustomIcon icon={'add'} />,

        },
        [checkItems.consumable]:{
            name:{
                singular:{
                    key:'',
                    default:'Consumible'
                },
                plural:{
                    key:'',
                    default:'Consumibles'
                }
            },
            icon:<InventoryIcon1/>
        },
        [checkItems.protection]:{
            name:{
                singular:{
                    key:'',
                    default:'Proteiccción'
                },
                plural:{
                    key:'',
                    default:'Proteccciones'
                }
            },
            icon:<HelmetIcon1 height='90%'/>,
        },
        [checkItems.lubricant]:{
            name:{
                singular:{
                    key:'',
                    default:'Lubricante'
                },
                plural:{
                    key:'',
                    default:'Lubricantes'
                }
            },
            icon:<OilCanIcon1/>,
        },
        [checkItems.permission]:{
            name:{
                singular:{
                    key:'',
                    default:'Permiso'
                },
                plural:{
                    key:'',
                    default:'Permisos'
                }
            },
            icon:<Permissions1/>,
        },
    }
    
    const disabled = {
        [checkItems.tool]:false,
        [checkItems.consumable]: false,
        [checkItems.protection]: false,
        [checkItems.permission]: false,
        [checkItems.lubricant]: false
    }

     const notification = (type:CheckListItemsType) => 
        colectedColectedSupplies 
            ?   ((colectedColectedSupplies[type]?.value?.length || 0) - colectedColectedSupplies[type]?.picked?.length || 0) 
            :   0
    
    const handleCheckUncheck = (itemId:any,type:CheckListItemsType) => {
        colectedColectedSupplies && 
            (colectedColectedSupplies[type].picked.includes(itemId)
                ?   setColectedColectedSupplies({
                    ...colectedColectedSupplies,
                    [type]:{
                        ...colectedColectedSupplies[type],
                        picked:[
                            ...(colectedColectedSupplies[type].picked.filter(id => id !== itemId))
                        ]
                    }
                })
                
                :   setColectedColectedSupplies({
                        ...colectedColectedSupplies,
                        [type]:{
                            ...colectedColectedSupplies[type],
                            picked:[
                                ...(colectedColectedSupplies[type].picked),
                                itemId
                            ]
                        }
                    })
            )
        }

    return{
        formModel,
        data:colectedColectedSupplies,
        routes:ColectorHomeRoutes,
        setState:setColectedColectedSupplies,
        disabled: disabled,
        list:list,
        notification:notification,
        action:handleCheckUncheck,
        icons
    }
}

export const useCheckListController = () => {

    const data = useCheckListData()

    return({...data})
}