import axios, { AxiosRequestConfig } from "axios";
import { queryCache, useMutation, useQuery } from "react-query";
import { URL } from "../../../../infrastructure/appConfig/appConfig";
import { MutationQueryObject } from "../../../../infrastructure/Interfaces";
import { useStatusProcessor } from "../../../errors/services/StatusProcessor";
import { useToken } from "../../../session/service.session";
import { SensorTypeInterface } from "../interfaces/SensorTypeInterfaces";


export const CreateSensorType = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateSTISensorType`,
        data
    }
    return axios(config).then(res => {return res.data}).catch(err =>{return err})
}

export const UpdateSensorType = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateSTISensorType`,
        data
    }
    return axios(config).then(res => {return res.data}).catch(err =>{return err})
}

export const SensorTypesByTagFP = (_:string,tagFP:string,token:String) => {
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    axios.defaults.headers.get['tagFP'] = tagFP;
    
    let config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/STISensorTypesByTagFP`,
    }
    return axios(config).then(res => {return res.data}).catch(err =>{return err})
}



//----------------------------
//----------Query-------------
//----------------------------


export const useSensorTypesByTagFP = (tagFP:string) =>{
    const token = useToken()
    return useQuery<SensorTypeInterface[] | []>(['SensorTypesByTagFP',tagFP,token],SensorTypesByTagFP,{
        refetchOnWindowFocus:false,
        enabled:!!token || !!tagFP
    })
}


//----------------------------
//----------Mutation----------
//----------------------------

export const useCreateSensorType = () => {
    const token = useToken()
    const [mutation,data] = useMutation(CreateSensorType,{
        onSuccess:()=>{
            queryCache.invalidateQueries('SensorTypesByTagFP')
        }
    })
    const {status,message,error} = useStatusProcessor(data)

    const createSensorType = (data:SensorTypeInterface) => {
        return mutation({
            token,
            data
        })
    }

    return {createSensorType,data:{...data,status,message,error}}
}


export const useUpdateSensorType = () => {
    const token = useToken()
    const [mutation,data] = useMutation(UpdateSensorType,{
        onSuccess:()=>{
            queryCache.invalidateQueries('SensorTypesByTagFP')
        }
    })
    const {status,message,error} = useStatusProcessor(data)

    const updateSensorType = (data:SensorTypeInterface) => {
        return mutation({
            token,
            data
        })
    }

    return {updateSensorType,data:{...data,status,message,error}}
}