import axios,{ AxiosRequestConfig } from 'axios'
import { MutationResult, useMutation, useQuery } from 'react-query'
import { URL } from "../../infrastructure/appConfig/appConfig"
import { MutationQueryObject } from '../../infrastructure/Interfaces'
import { useToken } from '../session/service.session'
import { useOperatorSupervisorTagFP } from '../persons/services/service.person';
import { queryCache } from 'react-query';
import { GridModelInterface } from '../../infrastructure/Interfaces';



//-------------------------------
//---------INTERFACES------------
//-------------------------------

export type PurchaseRequestStateType = 

'PENDIENTE' | 
/* - Se puede editar cantidad solicitada 
- Editar observación
- Pasar al siguiente estado(SOLICITADO, PENDIENTE) */

'SOLICITADO' |
/* - Hay que editar la requestedDate,
- Editar observación en solicitado
- pasar al siguiente estado 
- Editar expirationDate(aprobado o rechazado) */

'APROBADO' |
/* - Se puede editar una observación 
- Pasar al siguiente estado(ENTREGADO) */

'RECHAZADO' |
/* - No se puede editar nada */

'ENTREGADO'
/* - No se puede editar nada */



export interface PurchaseRequestInterface{
    id:string // back
    dateLoaded:string // back
    requestedDate:string // back
    dateDelivered:string // back
    date:string // back
    category:string
    subcategory:string
    brand:string
    requestedAmount:number
    measureUnit:string
    user:string
    observations:string
    tagFP:string
    product:string
    categoryArea:string
    receivedAmount:number
    providerArea:string
    complex:boolean
    state:PurchaseRequestStateType
    expirationDate:string
}

export const PurchaseGridModel:GridModelInterface = {
    id:{label:'Id'},
    dateLoaded:{label:'Fecha de carga'},
    requestedDate:{label:'Fecha de solicitud'},
    dateDelivered:{label:'Fecha de entrega'},
    date:{label:'Fecha'},
    category:{label:'Categoría'},
    subcategory:{label:'Sub categoría'},
    brand:{label:'Marca'},
    requestedAmount:{label:'Cantidad pedida'},
    measureUnit:{label:'Unidad de medida'},
    user:{label:'Usuario'},
    observations:{label:'Observaciones'},
    tagFP:{label:'Planta'},
    product:{label:'Producto'},
    categoryArea:{label:'Área'},
    receivedQuantity:{label:'Cantidad recibida'},
    providerArea:{label:'Área proveedora'},
    complex:{label:'Reposición Compleja',options:[{name:'Simple',value:false},{name:'compleja',value:true}, {name:'Todos', value:undefined}]},
    state:{label:'Estado'},
    expirationDate:{label:'Fecha de vencimiento'},
    receivedAmount:{label:'Cantidad recibida'}
}


export interface PurchaseRequestByStockInfoInterface{
    category:string
    subcategory:string
    categoryArea:string
    tagFP:string
    brand:string
    product:string
}

//-------------------------------
//---------AXIOS------------
//-------------------------------


export const PurchaseRequestsByTagFP = (_:string,tagFP:string,token:string) => {
    axios.defaults.headers.get['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.get['tagFP'] = tagFP
    const config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/PurchaseRequestsByTagFP`,
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const PurchaseRequestByStockInfo = (_:string,data:PurchaseRequestByStockInfoInterface,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/PurchaseRequestByStockInfo`,
        data:data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const CreatePurchaseRequest = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreatePurchaseRequest`,
        data:data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const UpdatePurchaseRequest = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdatePurchaseRequest`,
        data:data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

//-------------------------------
//---------QUERIES------------
//-------------------------------

export const usePurchaseRequestByStockInfo = (queryData:PurchaseRequestByStockInfoInterface) => {
    const token = useToken()
    return useQuery(['PurchaseRequestByStockInfo',queryData,token],PurchaseRequestByStockInfo,{
        enabled:!!token && !!queryData
    })
}

export const usePurchaseRequestsByTagFP = (tagFP?:string) => {
    const supOpTagFP = useOperatorSupervisorTagFP()
    const queryTagFP = tagFP || supOpTagFP
    
    const token = useToken()
    return useQuery<PurchaseRequestInterface[]>(['PurchaseRequestsByTagFP',queryTagFP,token],PurchaseRequestsByTagFP,{
        enabled:!!token && !!queryTagFP
    })
}

//-------------------------------
//---------MUTATIONs------------
//-------------------------------

export const useCreatePurchaseRequest = ():[(data:PurchaseRequestInterface)=>Promise<any>,MutationResult<any>] => {

    const token = useToken()
    const [mutation,query] = useMutation(CreatePurchaseRequest,{
        onSuccess:()=>{
            queryCache.invalidateQueries('PurchaseRequestByStockInfo')
            queryCache.invalidateQueries('PurchaseRequestsByTagFP')
        }
    })

    const createPurchaseRequest = (data:PurchaseRequestInterface) => {
        return mutation({
            token,
            data
        })
    }

    return [createPurchaseRequest,query]
}


export const useUpdatePurchaseRequest = ():[(data:any)=>Promise<any>,MutationResult<any>] => {

    const token = useToken()
    const [mutation,query] = useMutation(UpdatePurchaseRequest,{
        onSuccess:()=>{
            queryCache.invalidateQueries('PurchaseRequestByStockInfo')
            queryCache.invalidateQueries('PurchaseRequestsByTagFP')
        }
    })

    const createPurchaseRequest = (data:any) => {
        return mutation({
            token,
            data
        })
    }

    return [createPurchaseRequest,query]
}
