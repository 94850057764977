import React, {  useEffect, useMemo, useState } from 'react';
import { TextField, FormGroup, Typography } from '@material-ui/core';
import FlexContainer from '../presentational/FlexContainer';
import { Autocomplete } from '@material-ui/lab';
import { usePlantConfigWorkspace } from '../../infrastructure/tgd.config';
import { groupByKeyOptions, multipleSearch, search } from '../../utils/dataFiltering';
import CustomButton from '../buttons/CustomButton';
import ColumnFiltering from './ColumnFiltering';
import SendEmailWithAttachment from '../../domains/email-with-atachment/SendEmailWithAttachment';
import { useLangLabels } from '../../domains/language/services/useSetLabel';
import { useOperatorSupervisorTagFP, useUser } from '../../domains/persons/services/service.person';
import { useExportXlsx } from '../../infrastructure/xlsx/useExportXlsx';
import { errorsLog } from '../../utils/utils';
import { useForm } from 'react-hook-form';
import { useModal } from '../../utils/hooks/useModal';
import { objectMapper } from '../../utils/objectMapper';
import { useAutocompleteStyles } from '../autocomplete/styles/useAutocompleteStyle';
import { CarrousellActionInterface } from '../grids/AnyGrid';
import CarrousellModal from './../carrousell/components/CarrouselModal';
import { ComponentUIModes } from '../ComponentUIModes';


export interface itemActionsInterface {
    disabled?:boolean
    mode?:ComponentUIModes
}

export interface PeriodInterface {firstDate:string,secondDate:string}

interface Props {
    
    handleDataFiltered:(item:any)=>void
    title:string
    items:any[]
    gridModel?:any
    documentExportTitle?:string
    headerActions?:(item:any)=>React.ReactNode
    itemActions?:(item:any,items?:any[])=>React.ReactNode | ((item:any,items?:any[])=>React.ReactNode[])
    rowSelected?:any
    documentExport?:boolean
    documentFiltering?:boolean
    documentSendEmail?:boolean
    periodChange?: (period:undefined | PeriodInterface )=>void
    carrousellActions?: CarrousellActionInterface[]
}


const filterSelected = (id?:string,items?:any[]) => {
    const result = items && (id !== undefined) && items.filter((item)=>item.id === id)    
    return result && result[0]
}

const AnyFiltering:React.FC<Props>  = ({documentSendEmail,periodChange, handleDataFiltered, title, items, gridModel, documentExportTitle, headerActions, itemActions, carrousellActions, rowSelected, documentExport, documentFiltering}) => {


    const classes = useAutocompleteStyles({width:'150px'})
    const {data:user} = useUser()
    const { data:plant } = usePlantConfigWorkspace()
    const opSupTagFP = useOperatorSupervisorTagFP()
    const _tagFP = plant?.tagFP || opSupTagFP || ''
    const { lang } = useLangLabels()
    
    //Period
    const today = new Date().getTime()

    const {watch,register,setValue, handleSubmit,errors} = useForm()

    const handlePeriod = (e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(`${e.target.name}`,e.target.value)
    }

    const periodsubmit = (data:PeriodInterface) => {
        periodChange && periodChange(data)
    }
    const handleReset = () =>{
        setValue('firstDate','')
        setValue('secondDate','')
        periodChange && periodChange(undefined)
    }

    errorsLog(errors)
    useEffect(()=>{
        register({name:'firstDate'},{
            validate:(value)=>{
                const selectedDate = new Date(value).getTime()
                if(selectedDate > today) return 'La fecha no puede ser futura'
                if(selectedDate > today) return true
            },
            required:!!periodChange
        })
        register({name:'secondDate'},{
            validate:(value)=>{
                const selectedDate = new Date(value).getTime()
                if(selectedDate > today) return 'La fecha no puede ser futura'
                if(selectedDate > today) return true
            },
            required:!!periodChange
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    //component State
    const defaultOption = lang.filters.all 
    const documentExportComp = (documentExport === undefined ) ? true : documentExport
    const documentFilteringComp = documentFiltering === undefined ? true : documentFiltering
    const documentSendEmailComp = documentSendEmail === undefined ? true : documentSendEmail

    const [itemsCol,setItemsCol] = useState<any>(items) //items with filtered columns
    const [filters,setFilters] = useState<any>()//filters config 
    const [searchValue,setSearchValue] = useState<string>('')//search state
    const {modal:addNewFilter,handleModal:handleFilter} = useModal()
    const {modal:open,handleModal} = useModal()
       
    //downloas xlsx file
    const {downloadFile} = useExportXlsx(itemsCol,documentExportTitle || title || '', gridModel )

    //config entities to show React Components
    const entities = gridModel 
        ? Object.entries<any[]>(groupByKeyOptions(itemsCol))
        //filter gridModel inexistance of attributes
        .filter(([key])=> !!gridModel[key]?.label)
        .map(([key,values])=>({
            key,
            label:gridModel[key]?.label || key,
            options:(gridModel[key]?.options || 
                    values.map(value => ({
                        name:value,
                        value:value
                    }))
                )
        }))
        : Object.entries<any[]>(groupByKeyOptions(itemsCol))
        .map(([key,values])=>({
            key,
            label:key,
            options:values.map(value => ({
                name:value,
                value:value
            }))
        }))
        .filter((item)=> !(item.label === ''))
    
    const [selectedEntitiestoFilter, setSelectedEntitiestoFilter] = useState<any[]>([])

    //Components Methods
    const deleteFilters = () => {
        setSelectedEntitiestoFilter([])
        setSearchValue('')
        setFilters([])
    }

    const handleCloseFilters = () => {
        handleModal()
        deleteFilters()
    }

    const handleChange = (field:string,value:any) => {
        setFilters({...filters,[field]:(value === defaultOption) ? undefined : value})
    }

    const addEntityToFilters = (item:any) => {
        setSelectedEntitiestoFilter([
            ...selectedEntitiestoFilter,
            item
        ])
        handleFilter()
    }

    /* const deleteEntityOfFilter = (item:any) => {
        setSelectedEntitiestoFilter([])
    } */

    const filtersResult = useMemo(()=>multipleSearch((itemsCol || []),filters),[itemsCol,filters])
    const searchResult = useMemo(()=>search((filtersResult || []),searchValue),[filtersResult,searchValue])
    const {handleModal:handleAction} = useModal()

    useEffect(()=>setItemsCol(items),[items]) 
    useEffect(()=>{
        handleDataFiltered && handleDataFiltered(searchResult)
        handleAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchResult])

    const rowItemsFilteredComleteColumns = useMemo (()=> searchResult.map(item => {
        const itemsMapped = objectMapper(items,'id')
        return {...itemsMapped[item?.id] || []}
    }),[items, searchResult])

    const current = rowSelected && filterSelected(rowSelected.id,items)

    return(<>
            <FlexContainer justify='space-between'>
                <FlexContainer flexDirection='column'>
                    {title && <Typography variant='h6' >{title}</Typography>}
                </FlexContainer>

                {open
                    ?   <FlexContainer height='32px'>
                            <CustomButton icon='close' popoverTitle={lang.filters.closeFilters} action={handleCloseFilters}></CustomButton>
                            {headerActions && headerActions(current)} 
                            {searchResult && user?.type !== 'O' && <CustomButton popoverTitle={lang.filters.downloadData} action={downloadFile} icon='download'></CustomButton>}
                            {documentSendEmailComp && user?.type !== 'O' &&  <SendEmailWithAttachment  tagFP={_tagFP} items={itemsCol} attachmentTitle={documentExportTitle || title || ''} />}
                        </FlexContainer> 
                    :   <FlexContainer height='32px'>
                            {documentFilteringComp  && searchResult && <CustomButton icon='filter' popoverTitle={lang.filters.filterData} action={handleModal}></CustomButton>}
                            {documentExportComp && user?.type !== 'O'  && <CustomButton popoverTitle={lang.filters.downloadData} action={downloadFile} icon='download'></CustomButton>}
                            {documentSendEmailComp && user?.type !== 'O' && <SendEmailWithAttachment tagFP={_tagFP}  items={itemsCol} attachmentTitle={documentExportTitle || title || ''} />}
                            {headerActions && headerActions(current)} 
                            
                        </FlexContainer>
                }

            </FlexContainer>
            <br/>
            <FlexContainer>
                {carrousellActions  && rowSelected && current && carrousellActions.map(({icon,component},index)=>
                    <CarrousellModal key={index + icon} icon={icon} items={items} item={current}>
                        {(value)=>component(value,_tagFP)}
                    </CarrousellModal>
                )}
                {(itemActions && rowSelected) &&  current && itemActions(current,rowItemsFilteredComleteColumns)}
            </FlexContainer>
            {!!open &&
                <FormGroup>
                    <br/>
                    <FlexContainer flexWrap='wrap' align='center'>
                            <ColumnFiltering gridModel={gridModel} handleFilteredColumns={setItemsCol} items={items}/>
                            <TextField
                                label={lang.filters.searchByTerm}
                                type='search'
                                variant='outlined'
                                size='small'
                                value={searchValue}
                                onChange={(e:any) => setSearchValue(e.target.value)}
                            />
                            {selectedEntitiestoFilter?.map((entity:any,index:number)=>
                                
                                <Autocomplete
                                key={entity.key}
                                openOnFocus
                                color='Primary'
                                size='small'
                                id={entity.key}
                                options={entity.options}
                                onChange={(e,value:any)=>handleChange(`${entity.key}`,value?.value)}
                                getOptionLabel={(option:any) => `${option.name}`}
                                style={{ width: 150 }}
                                classes={{
                                    option:classes.option
                                }}
                                renderInput={(params) => <TextField autoFocus {...params} label={entity.label} variant="outlined" />}
                                />
                            )}

                            {periodChange &&<FlexContainer>
                                <TextField
                                    focused={errors?.firstDate}
                                    error={errors?.firstDate}
                                    InputLabelProps={{ shrink: true }}
                                    placeholder=''
                                    size='small'
                                    variant='outlined'
                                    name='firstDate'
                                    label={errors?.firstDate?.message || 'Desde'}
                                    value={watch('firstDate')}
                                    type='date'
                                    onChange={handlePeriod}
                                />
                                <TextField
                                    focused={errors?.secondDate}
                                    error={errors?.secondDate}
                                    InputLabelProps={{ shrink: true }}
                                    placeholder=''
                                    size='small'
                                    variant='outlined'
                                    name='secondDate'
                                    label={errors?.secondDate?.message || 'Hasta'}
                                    value={watch('secondDate')}
                                    type='date'
                                    onChange={handlePeriod}
                                />
                                <CustomButton icon='search' action={handleSubmit(periodsubmit)} popoverTitle={'Consultar'} />
                                {!!periodChange && <CustomButton icon='delete' action={handleReset} popoverTitle={'Resetear Período'} />}
                            </FlexContainer>}

                        
                            {/* select filters */}
                            {addNewFilter 
                            ? <Autocomplete
                                openOnFocus
                                color='Primary'
                                size='small'
                                options={entities}
                                onChange={(e,value)=>addEntityToFilters(value)}
                                getOptionLabel={(option:any) => option.label}
                                style={{ width: 150 }}
                                classes={{
                                    option:classes.option
                                }}
                                renderInput={(params) => <TextField autoFocus {...params} label={'Agregar'} variant="outlined" />}
                            />
                            : <CustomButton popoverTitle={lang.filters.addFilter} icon='add' action={handleFilter}/> }
                            {(selectedEntitiestoFilter.length > 0) && <CustomButton popoverTitle={lang.filters.deleteFilters} action={deleteFilters} icon='delete'/>}
                    </FlexContainer>
                </FormGroup>
            }
    </>)
}

export default React.memo(AnyFiltering)
