import React from 'react';
import { useAllAuxByPlant, useSectorsByPlant } from '../../auxiliar-data/service.aux';
import SimpleAutocompleteSelect from '../../../components/autocomplete/SimpleAutocompleteSelect';
import CUSectorForm from '../CUSectorForm';
import { CrudFormType } from '../../../infrastructure/form/typescript/FormTypes';
import { useLangLabels } from './../../language/services/useSetLabel';
import { SectorInterface } from '../services/service.sectors';


interface props{
    onChange:any
    tagFP:string
    defaultValue?:any
    error?:string
    complete?:boolean
    disabled?:boolean
    helper?:string

}

const SelectSector:React.FC<props> = ({tagFP, onChange, defaultValue, error, complete, disabled, helper}) => {

    const showTitle = 'sectorName'
    const {lang} = useLangLabels()
    const {data:sectors} = useSectorsByPlant(tagFP)
    const label = lang.sectors.title.singular

    const handleChange = (value:any) => {
        console.log(value)
        if(complete){
            const selected = sectors?.find((s:SectorInterface)=>s[showTitle] === value) as SectorInterface
            onChange(selected)
        }else{
            onChange(value)
        }
    }

    return (
        <SimpleAutocompleteSelect
            {...{
                helper,
                error,
                onChange:handleChange,
                label,
                items:sectors || [],
                defaultValue,
                showTitle,
                disabled
            }}
        >
            <CUSectorForm tagFP={tagFP} actionType={CrudFormType.create} />
        </SimpleAutocompleteSelect>
    );

}

export default SelectSector
