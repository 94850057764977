import { Divider, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import DoNotDoButton from '../../../../components/buttons/DoNotDoButton';
import TgdFab from '../../../../components/buttons/TgdFab';
import PageContainer from '../../../../components/pageContainers/PageContainer';
import FlexContainer from '../../../../components/presentational/FlexContainer';
import { LubricationPointInterface } from '../../../equipments/services/service.lubricationPoints';
import CreateObservation from '../observations/AddObservation';
import { useLubricationPointSurveyController } from './controllers/useLubricationPointSurveyController';
import  ArrowForwardIcon  from '@material-ui/icons/ArrowForward';
import { errorsLog, suppliesToString } from '../../../../utils/utils';
import { TaskType } from '../tasks/interfaces/taskInterfaces';
import { QueryStatus } from 'react-query';
import CustomButton from '../../../../components/buttons/CustomButton';
import AddSupply from '../supplies/AddSupply';
import { ObservationInterface } from '../../../observations/types/ObservationTypes';




const SurveyRepairment:React.FC<{item:LubricationPointInterface,taskType:TaskType,statusController:({status,data}:{status:QueryStatus,data:any})=>void, outOfRoute:boolean}> = ({outOfRoute, statusController,taskType,item}) => {
    
    const {register, handleSubmit, errors} = useForm()
    
    //SURVEY DATA
    const { survey,setTaskDone, setTaskTime, createSurvey, createObservation, nextTaskStep, backTaskStep } = useLubricationPointSurveyController(item,outOfRoute)
    const observations = survey?.observations  || []   
    const taskDone = survey?.repairment.taskDone
    const taskTime = survey?.repairment.taskTime || 0
    const supplies= survey?.neededSupplies || []
    //COMPONENT STATE
    const [step]  = useState<any>()
    
    //COMPONENT METHODS

    useEffect(()=> {
        register({name:'taskTime',value:taskTime})
    },[taskType, taskDone, register, taskTime])
   

    const submit = (data?:any) => {
        statusController({ status: QueryStatus.Loading, data:undefined})
        createSurvey({
            ...survey?.inspection,
            ...survey?.lubrication,
            ...survey?.repairment,
            //TASK DATA
            taskType:taskType,
            taskDone:!!taskDone,
            taskTime:`${taskTime}`,
            endTime:new Date().toISOString(), 
            //LUBRICATION DATA
            lubricates:survey?.lubrication.lubricates,
            sampleExtraction:false,
            consumables:JSON.stringify(suppliesToString(supplies))
        }).then(({data})=>{
            if( observations.length < 1 ) return
            else{
                createObservation([
                    ...(observations.map((o:ObservationInterface)=>({...o,surveyId:data.id}))),
                ])
            }
        }).then(()=>{
            if(data.error) statusController({ status: QueryStatus.Error, data })
            statusController({ status: QueryStatus.Success, data})
            nextTaskStep(taskType)
        })
        .catch(e=>{
            statusController({ status: QueryStatus.Error, data:e })
            console.log(e)
        })
    }
    
    errorsLog(errors)


    return(<>

            {outOfRoute && /* BACK BUTTON */
                <div>
                    <CustomButton 
                        action={()=>backTaskStep(taskType)} 
                        icon='before' 
                        variant='iconButton' 
                        title='Atras' 
                    />
                </div>
            }
        
            { taskDone === undefined &&
                <FlexContainer justify='center' align='center' gap='24px' height='100%' width='100%'>
                    <DoNotDoButton action={submit} variant='close' title='Sin Reparación'/>
                    <DoNotDoButton action={()=>setTaskDone(taskType,true)} variant='check' title='Reparación'/>
                </FlexContainer>
            }
            
            <>
                {taskDone && <>
                    <PageContainer>
                        <TextField
                        size='small'
                        variant='outlined'
                        style={{width:'100%'}}
                        type='number'
                        name={'taskTime'}
                        onChange={(event)=>{
                            setTaskTime(taskType,JSON.parse(event.target.value))
                        }}
                        label={`Tiempo de reparación ${errors?.taskTime?.message || ''}` || 'Tiempo de Reparación'}
                        color={errors?.taskTime?.message ? 'primary' : 'secondary'}
                        inputRef={register({
                            required:{
                                value:!!taskDone,
                                message:'Requerido'
                            }
                        })}
                        />
                    </PageContainer>
                    <br/>
                    <Divider/>

                        <CreateObservation
                            outOfRoute={outOfRoute}
                            taskType = {taskType}
                            lubricationPoint={item}
                            type='OBSERVACION' 
                            buttonTitle='observaciones'
                            formTitle='Nueva observación'
                            variant={(survey?.observations && survey.observations.length > 0 ) ? 'list' : 'form'} 
                            listTitle={(step?.observation) || 'Observaciones'}
                            buttonType='add'
                        />

                        <AddSupply
                            outOfRoute={outOfRoute}
                            listTitle='Insumos' 
                            formTitle={'Nuevo insumo'}
                            buttonTitle={'Insumos'}
                            variant={'list'}
                            buttonType={'add'} 
                            lubricationPoint={item}
                        />
                    
                        <TgdFab 
                            disabled={(survey?.observations && survey.observations.length > 0 ) ? false : true}
                            bottom={4}
                            right={4}
                            color='primary' 
                            onClick={handleSubmit(submit)}
                            children={<ArrowForwardIcon/>}
                        />
                    
                </>}
            </>
    </>)
}

export default React.memo(SurveyRepairment)