import { Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import React from 'react';
import theme from '../../../../infrastructure/materialUi/themeConfig';
import PageContainer from '../../../../components/pageContainers/PageContainer';
import FlexContainer from '../../../../components/presentational/FlexContainer';
import { SurveyTasksValues, TaskType } from '../tasks/interfaces/taskInterfaces';
import CustomButton, { CustomIcon } from '../../../../components/buttons/CustomButton';
import { LubricationPointInterface } from '../../../equipments/services/service.lubricationPoints';
import { LubricationSurveyControlerInterface, useLubricationPointSurveyController } from './controllers/useLubricationPointSurveyController';
import TgdFab from './../../../../components/buttons/TgdFab';
import { Alert } from '@material-ui/lab';


const tasks = (survey:LubricationSurveyControlerInterface) => {

    const step = (survey.loop > 0) 
        ? survey.puntuation.assetCriticality !== '' ? 3 : 2
        : 0

    return {
        step,
        tasks:[
            'INSPECCIÓN',
            'TRABAJO SOBRE EL EQUIPO',
            'PUNTUACION DEL ACTIVO'
        ]
    }
}

interface TaskStepperProps {
    tasks:any[]
    iterator:string
    step:number
}


const TaskStepper:React.FC<TaskStepperProps> = ({tasks, step, iterator})=> {

    const [activeStep, setActiveStep] = React.useState(step);

    return(
            <Stepper nonLinear activeStep={activeStep} >
                {tasks.map((label,index) => (
                    <Step 
                        color={'default'} 
                        completed={index < step ? true : false} 
                        active={index < step ? true : false} 
                        key={label[iterator] || label }
                    >
                        <StepLabel>{label[iterator] || label }</StepLabel>
                    </Step>
                ))}
            </Stepper>
    )

}



const SurveyTasks:React.FC<{lubricationPoint:LubricationPointInterface,outOfRoute:boolean}> = ({lubricationPoint,outOfRoute}) => {

    const taskType:TaskType = SurveyTasksValues.intervention
    const {survey, nextTaskStep,handleFinishOutOfRoute, } = useLubricationPointSurveyController(lubricationPoint,outOfRoute)

    const inaccesible = survey?.inspection.inaccessible
    const inspectionComplete = survey?.inspectionComplete

    return (<>
        {outOfRoute && survey?.taskStep === 0 && <>
            <FlexContainer height='15vh' padding='24px' align='center'  flexWrap='wrap' background={theme.palette.secondary.main}>
                <CustomButton disabled={inaccesible} action={()=>nextTaskStep(taskType,2)} popoverTitle='Inspeccionar y lubricar' styleProps={{backgroundColor:'#fff',margin:'0px 12px',color:'black'}} icon='oilCan' title='Lubricacion' />   
                <CustomButton disabled={inaccesible} action={()=>nextTaskStep(taskType,3)} popoverTitle='Inspeccionar y limpiar' styleProps={{backgroundColor:'#fff' ,padding:'8px',margin:'0px 12px',color:'black'}} icon='cleaning' title='Limpieza' />   
                <CustomButton disabled={inaccesible} action={()=>nextTaskStep(taskType,4)} popoverTitle='Inspeccionar y reparar' styleProps={{backgroundColor:'#fff' ,margin:'0px 12px',color:'black'}} icon='tool' title='Reparación' />   
{/*                 <CustomButton action={()=>nextTaskStep(taskStep,)}  styleProps={{backgroundColor:'#fff', margin:'0px 12px',color:'black'}} icon='lab' title='Muestra' />   
                    {/* <FlexContainer height='2vh' background={theme.palette.info.main}/> */}           
            </FlexContainer>
            
            {!inaccesible ? <>
                <TaskStepper
                        tasks={tasks(survey).tasks}
                        step={tasks(survey).step}
                        iterator='type'
                        />
                <FlexContainer height='40vh' >
                    <PageContainer>
                        <Alert severity='warning'>
                            Próximamente podrá contar con otras tareas personalizadas de Puntos de lubricación
                        </Alert>
                    </PageContainer>
                </FlexContainer>
            </> : <PageContainer>
                <Typography variant='h4' style={{color:'#e6e6e6'}}>EQUIPO INACCESIBLE</Typography>
            </PageContainer>}
            
                <TgdFab 
                    bottom={4}
                    right={4}
                    color='primary' 
                    onClick={handleFinishOutOfRoute}
                    children={<CustomIcon icon='next' />}
                />
            
        </>}
    </>);
}

export default SurveyTasks;
