import React,{useEffect} from 'react';
import { CircularProgress, IconButton, } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDeletePermissionVerification } from './services/service.permission';
import TgdPopover from '../../components/popovers/TgdPopover';
import { useStatusProcessor } from '../errors/services/StatusProcessor';
import { useClickPosition } from '../../utils/hooks/useClickPosition';
import { useSnackbar } from '../../utils/hooks/useSnackbar';
import { PermissionInterface } from './types/permissionsTypes';

interface Props{
    item:PermissionInterface    
}

const DeletePermissionForm:React.FC<Props> = ({item}) => {

    const {status:deleteStatus,validate,errors,data} = useDeletePermissionVerification()
    const {position,getClickPosition} = useClickPosition()
    const {status,message,error} = useStatusProcessor({...data,status:deleteStatus})
    const { setData } = useSnackbar()

    useEffect(()=>{
        if(deleteStatus === 'error'){
            const permissionsErrors = (
                (errors?.equipments?.length 
                    ? (errors.equipments.length > 1 
                        ? `${errors.equipments.length} Equipos` 
                        : `${errors.equipments.length} Equipo`) 
                    : '')
            )
            setData({
                modal:true,
                errors:`Este Permiso no se puede eliminar porque está siendo usado en: ${permissionsErrors}` ,
                severity:'warning',
                position:position
            })
        }

        if(deleteStatus === 'success'){
            setData({
                modal:true,
                errors:message ,
                severity:'success',
                position:position
            })
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[status,errors,message])

    return (
        (status === 'loading')  
        ?<CircularProgress size='20px' /> 
        :<TgdPopover mode='hover' title='Eliminar permiso'>
            <IconButton  size='small' onClick={(e:any)=>getClickPosition(e,()=>validate(item))}>
                <DeleteIcon />
            </IconButton>
        </TgdPopover>
    );
}

export default React.memo(DeletePermissionForm);
