import { useCallback } from "react"
import theme from "../../../../../infrastructure/materialUi/themeConfig"
import { LubricationPointInterface } from "../../../../equipments/services/service.lubricationPoints"
import { useUser } from "../../../../persons/services/service.person"
import { useLocalStorage } from "../../../../../infrastructure/localStorage/useLocalStorage"
import { useDailyPreviousPendingPointsWithInfo, usePendingPoints } from "../../lubricationPoints/services/daoLubricationPoints"
import { SurveyTasksValues, TaskType } from "../../tasks/interfaces/taskInterfaces"
import { objectMapper } from './../../../../../utils/objectMapper';
import { useSupervisorSelectedLubricatorData } from './../../../pages/SupervisorColectorPage';


type SurveyTasks = {
    type:TaskType,
    color:string
}
interface SurveyStateInterface {
    modal:boolean
    tasks:SurveyTasks[],
    backlogStep:number
    currentPoint:LubricationPointInterface | undefined,
    dailyPoints:any[]
}


export const surveyTasks:SurveyTasks[] = [
    {
        type:SurveyTasksValues.intervention,
        color:theme.palette.secondary.main
    },
    {
        type:SurveyTasksValues.inspection,
        color:theme.palette.secondary.main
    },
    {
        type:SurveyTasksValues.lubrication,
        color:theme.palette.primary.main
    },
    {
        type:SurveyTasksValues.cleaning,
        color:theme.palette.info.light
    },
    {
        type:SurveyTasksValues.repairment,
        color:theme.palette.info.main
    },
    {
        type:SurveyTasksValues.puntuation,
        color:theme.palette.success.main
    }
]

export const useSurveyController = () => {

    

    const dailyPendingPoints = usePendingPoints()
    const lasDelayedPendingPoints = useDailyPreviousPendingPointsWithInfo()
    const totalDailyPoints = 
        (dailyPendingPoints && lasDelayedPendingPoints)
            ? [...dailyPendingPoints, ...lasDelayedPendingPoints] 
            : undefined

    const todayDailyPointsMap = objectMapper(totalDailyPoints,'tagTGD')
    const totalDailyPointsTagsTGD = totalDailyPoints?.map((item)=>item.tagTGD) 


    //config supervisor current lubricator data or default behavior for lubricators
    const {data:user} = useUser()
    const {data:lubricatorData} = useSupervisorSelectedLubricatorData()
    const defaultUserEmail = user?.type === 'S' ? lubricatorData?.email  : undefined
    const [ survey, setSurvey ] = useLocalStorage<SurveyStateInterface>(`surveystate`,{
            modal:false,
            tasks:surveyTasks,
            backlogStep:0,
            currentPoint:totalDailyPoints && totalDailyPoints[0] ,
            dailyPoints:totalDailyPointsTagsTGD || []
    },{
        enabled:(!!user?.email && !!dailyPendingPoints && !!totalDailyPoints && !!totalDailyPointsTagsTGD),
        unique:true,
        defaultUserEmail
    })


    const setBacklogStep = useCallback((value:number) => {
        setSurvey({
            ...survey,
            dailyPoints:value
        })
    },[survey,setSurvey])

    const nextPrevBacklogStep = useCallback((action:'PREV' | 'NEXT')=>{

        const backlogStep = survey 
            ?(action ==='NEXT')
                ? survey.backlogStep + 1
                : survey.backlogStep - 1
            :0

        const currentPointTagTGD = survey?.dailyPoints[backlogStep]
        const currentPoint = todayDailyPointsMap ? todayDailyPointsMap[currentPointTagTGD] : undefined
        
        setSurvey({
            ...survey,
            backlogStep,
            currentPoint
        })
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[survey,setBacklogStep])

    
    const finishEquipment = () => {
        const currentPoint = survey?.currentPoint?.tagTGD
        /* console.log(currentPoint) */

        const newLubricationPointsBacklog = survey?.dailyPoints.filter((point)=>point !== currentPoint) || []
        const backlogStep = survey?.backlogStep || 0

        const newSurvey = {
            ...survey,
            currentPoint:todayDailyPointsMap[newLubricationPointsBacklog[backlogStep]],
            dailyPoints:newLubricationPointsBacklog
        }

        setSurvey(newSurvey)

    }

    const handleModal = () => {
        setSurvey({
            ...survey,
            modal:!survey?.modal
        })
    }




    return{
        surveyState:survey,
        nextPrevBacklogStep,
        finishEquipment,
        handleModal,
        dailyPoints:dailyPendingPoints,
        modal:!!survey?.modal,
        surveyTasks
    }

}