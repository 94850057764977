import React, { useState,  } from 'react'
import {  useForm } from 'react-hook-form';
import {   Typography} from '@material-ui/core';
import { useEffect } from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { SurveyTasksValues, TaskType } from '../tasks/interfaces/taskInterfaces';
import { LubricationPointInterface } from '../../../equipments/services/service.lubricationPoints';
import { usePlantConfigWorkspace } from '../../../../infrastructure/tgd.config';
import { useLubricationPointSurveyController } from '../surveys/controllers/useLubricationPointSurveyController';
import PageContainer from '../../../../components/pageContainers/PageContainer';
import FlexContainer from '../../../../components/presentational/FlexContainer';
import TgdFab from '../../../../components/buttons/TgdFab';
import TgdModal from '../../../../components/modals/TgdModal';
import { LocalStateSupply } from '../lubricationPointsInfo/colector.lubricationPointsInfoInterfaces';
import CustomButton from '../../../../components/buttons/CustomButton';
import { errorsLog } from '../../../../utils/utils';
import SelectSimpleEntity from '../../../../components/select/SelectSimpleEntity';
import SupplyList from './SupplyList';
import { suppliesToObject } from './../../../../utils/utils';
import { useModal } from './../../../../utils/hooks/useModal';
import SelectSupply from './../../../supply/atom/SelectSupply';
import { ComponentUIModes } from '../../../../components/ComponentUIModes';

interface Props {
    outOfRoute:boolean
    listTitle?:string
    buttonTitle?:string
    formTitle?:string
    variant?: 'modal' | 'content' | 'form' | 'list'
    buttonType:'next' | 'add'
    lubricationPoint:LubricationPointInterface
    freesolo?:boolean
    disableUsedSupplies?:boolean
    taskType?:TaskType
    inmutableValues?:string[]
}

export enum SupplyStateDimention {
    needed='FALTANTE',
    used='UTILIZADO',
}

const AddSupply:React.FC<Props> = ({variant,listTitle,buttonTitle,formTitle,buttonType,lubricationPoint,taskType,freesolo,disableUsedSupplies,inmutableValues,outOfRoute}) => {
    
    const {register,watch, handleSubmit, errors, setValue } = useForm()
    const {modal,handleModal} = useModal()
    const {data:workspace} = usePlantConfigWorkspace()
    const [supplyState, setSupplyState] = useState<SupplyStateDimention>(SupplyStateDimention.needed)

    const { survey, deleteSupply, setNeededSupplies } = useLubricationPointSurveyController(lubricationPoint,outOfRoute)
    const supplies = survey?.neededSupplies || []


    useEffect(() => {
        if(supplyState){
            setValue('type','CONSUMIBLE')
        }
    }, [setValue, supplyState]);

    const onSubmit = (data:LocalStateSupply) => {   
      setNeededSupplies({
          ...data,
          state:supplyState
      }) 
      /* console.log(data) */
    }

    errorsLog(errors)

    useEffect(()=>{
        register({name:'supply',value:''},{required:{value:true,message:'Nombre Requerido'}})
        register({name:'type',value:''},{required:{value:true,message:' Tipo Requerido'}})
    },[register, survey])

    const form =  
        <PageContainer> 
            <Typography variant='h6'>{formTitle} {supplyState === SupplyStateDimention.needed ? ' faltante' : ' utilizado'}</Typography>       
            <br/>
            <br/>               
            <FlexContainer flexDirection='column' width='100%'>

                {supplyState === SupplyStateDimention.needed &&
                <SelectSimpleEntity
                    width={'100%'}
                    entityList={[{type:'HERRAMIENTA'},{type:'CONSUMIBLE'},{type:'PROTECCION'}]}
                    onChange={(value:string)=>setValue('type',value)}
                    defaultValue={watch('type')}
                    label={'Tipo'}
                    showTitle={'type'}
                    error={errors?.type?.message}
                />}

                <SelectSupply
                    error={errors?.type?.supply}
                    by={{key:'type',value:watch('type')}}
                    onChange={(value?:string)=>setValue('supply',value?.toUpperCase())}
                    tagFP={workspace?.tagFP!}
                    extendInput={true}
                />

                <CustomButton icon='save' action={handleSubmit(onSubmit)}/>
            
            </FlexContainer>
          
        </PageContainer>

    const handleTaskSupplies = (supplyState:SupplyStateDimention) => {
        setSupplyState(supplyState)
        handleModal()
    }

    const neededSuppliesButton = <CustomButton popoverTitle={taskType === SurveyTasksValues.puntuation ? 'Solicitar agregado de insumo a este equipo' : 'insumos faltantes para la tarea'} icon='add' action={()=>handleTaskSupplies(SupplyStateDimention.needed)} title={taskType === SurveyTasksValues.puntuation ? 'SOLICITAR' : 'Faltantes'} />
    const usedSuppliesButton = <CustomButton popoverTitle='insumos usados en la tarea' icon='add' action={()=>handleTaskSupplies(SupplyStateDimention.used)} title={'Utilizados'} />

    const headerActions = <FlexContainer>
        { neededSuppliesButton}
        {(taskType !== SurveyTasksValues.puntuation && !disableUsedSupplies) && usedSuppliesButton}
    </FlexContainer>

    const list = (mode?:ComponentUIModes) =>(
        <SupplyList
        tasktype={taskType}
        handleDeleteItem={deleteSupply}
        supplies={supplies} 
        headerAction={variant === 'list' ? headerActions : <></>} 
        title={listTitle} 
        mode={mode}
        inmutableValues={inmutableValues ? suppliesToObject(inmutableValues): undefined }
        />
    ) 




    return(<>
        {(variant === 'form') && form}
        {(variant === 'content') && <>{form}{list()}</>}
        {(variant === 'list') && list()}
        {(variant === 'modal') && <>
            {buttonType === 'next' &&
                <TgdFab
                bottom={4}
                right={4}
                color='primary' 
                onClick={handleModal}
                children={<ArrowForwardIcon/>}
                />
            }
        </>}

        <TgdModal
            open={modal}
            handleClose={handleModal}
        >
            <>{form}{list('modal')}</>
        </TgdModal>
    </>)

}
export default React.memo(AddSupply)