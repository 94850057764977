
import React from 'react'
import { QueryStatus } from "react-query"
import CustomButton, { ButtonVariant, CustomIconType } from "../buttons/CustomButton"
import ComponentMode from "../ComponentMode"
import StatusAndMessage from "../../domains/errors/StatusAndMessage"
import { ComponentUIModes } from '../ComponentUIModes'

 interface Props{
     onChange:(item?:any)=>void, 
     children:React.ReactNode, 
     status:QueryStatus, 
     error?:any,
     buttonTitle?:string, 
     message?:string | undefined,
     mode?:ComponentUIModes,
     icon?:CustomIconType,
     variant?:ButtonVariant
     popoverTitle?:string
     disabled?:boolean
     fullwidth?:boolean
     onClose?:()=>void
     extraActions?:React.ReactNode
     severity?:any
    }



const DefaultFormLayout:React.FC<Props > = ({severity, extraActions, onChange,onClose, mode,buttonTitle, icon, variant,  children, status, error, message,popoverTitle,disabled,fullwidth}) => {
    
  

    return(
        <ComponentMode onClose={onClose} fullwidth={fullwidth} title={buttonTitle} mode={mode} icon={icon} variant={variant} popoverTitle={popoverTitle}>
            <StatusAndMessage status={status} error={error} message={message} reset={onClose} severity={severity} />
            {children}
                <CustomButton  action={onChange} status={status} title={buttonTitle} />
                {extraActions && extraActions}
        </ComponentMode>
    )
}

export default DefaultFormLayout