import React,{useContext} from 'react'
//context
import {SessionContext} from '../store/sessionContext'
//material
import Chip from '@material-ui/core/Chip'
import Icon from '@material-ui/core/Icon'



const MenuPlantSelector:React.FC<{classes?:any,color?:"primary" | "secondary" | "default" | undefined, icon?:string}> = ({classes,color,icon}) => {
    const [auth,setAuth] = useContext<any>(SessionContext)
    

    const handleClick = () => {
        setAuth({
            ...auth,
            multyPlant:true,
            workspace:null
        })
     };

    return (
        <>
            <Chip
                className={classes}
                variant="outlined"
                style={{textTransform:'uppercase',margin:'0 16px',maxWidth:'200px'}}
                size="small"
                label={auth.workspace.split(' ')[1]}
                clickable
                color={color}
                icon={<Icon className={icon}>more_horiz</Icon>}
                aria-controls="simple-menu" 
                aria-haspopup="true" 
                onClick={handleClick}
            />  
        </>       
    );   
    
}

export default MenuPlantSelector