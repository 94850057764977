import React from 'react';
import {PurchaseGridModel, usePurchaseRequestsByTagFP} from './service.purchaseRequests'
import AnyGrid from '../../components/grids/AnyGrid';
import CreatePurchaseRequestForm from './CreatePurchaseRequestForm';
import SetPurchaseRequestStatus from './SetPurchaseRequestStatus';
import UpdatePurchaseRequestForm from './UpdatePurchaseRequestForm';
import { Divider } from '@material-ui/core';



interface Props{
    tagFP:string
}

const PurchaseRequestsGrid:React.FC<Props> = ({tagFP}) => {

    const {data:purchaseRequests} = usePurchaseRequestsByTagFP(tagFP)
    const title = 'Solicitudes de compra'
 
    return (<> 
        <Divider/>
        <AnyGrid 
            rowsPerPage={12}
            gridModel={PurchaseGridModel}
            title={title}
            height={'65vh'}
            items={purchaseRequests || []}
            loading={purchaseRequests ? false : true}
            headerActions={(item)=><CreatePurchaseRequestForm mode='modal'/>}
            itemActions={(item)=><>
                <SetPurchaseRequestStatus item={item} />
                <UpdatePurchaseRequestForm item={item}/>     
            </>}
        /> 
    </>);
}

export default React.memo(PurchaseRequestsGrid);
