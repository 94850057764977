import { CircularProgress, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TgdFab from '../../../../components/buttons/TgdFab';
import FlexContainer from '../../../../components/presentational/FlexContainer';
import { LubricationPointInterface } from '../../../equipments/services/service.lubricationPoints';
import { useLubricationPointSurveyController } from './controllers/useLubricationPointSurveyController';
import { errorsLog, suppliesToString } from '../../../../utils/utils';
import CustomButton, { CustomIcon } from './../../../../components/buttons/CustomButton';
import theme from '../../../../infrastructure/materialUi/themeConfig';
import PointerContainer from '../../../../components/pageContainers/PointerContainer';
import { QueryStatus } from 'react-query';
import { SurveyTasksValues, TaskType } from '../tasks/interfaces/taskInterfaces';
import { useCriticalityByTypeByTagFP } from '../../../criticalities/services/service.criticality';
import AddSupply from '../supplies/AddSupply';
import { CriticalityInterface, CriticalityTypes } from '../../../criticalities/types/types';
import { ObservationInterface } from '../../../observations/types/ObservationTypes';

const SurveyPuntuation:React.FC<{item:LubricationPointInterface, onFinish:()=>void, taskType:TaskType,statusController:({status,data}:{status:QueryStatus,data:any})=>void, outOfRoute:boolean}> = ({outOfRoute, statusController, taskType,item,onFinish}) => {

    const {register, handleSubmit, errors, setValue, watch} = useForm()
    
    //SURVEY DATA
    const { survey, createSurvey, setAssetCriticality ,updateEquipmentInfo, finishPointOnRoute, backTaskStep, createObservation, resetSurvey } = useLubricationPointSurveyController(item,outOfRoute)
   
    const anomalies = survey?.anomalies || []
    const selectedCriticality = survey?.puntuation?.assetCriticality
    const supplies = survey?.neededSupplies?.map(({type,supply})=>({
        type,
        supply,
        state:undefined
    })) || []
    const currentEquipmentinfoSupplies = item.info?.supplies?.filter(s=> s !== 'SIN INSUMOS') || []
 
    const infoSupplies = [...(new Set([
        ...currentEquipmentinfoSupplies,
        ...suppliesToString(supplies)
    ]))]

    useEffect(()=>{
        register({name:'assetCriticality',value:selectedCriticality},{required:{value:true,message:'Seleccionar una criticidad es requerido'}})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const finish = () => {
        onFinish()
        if(!outOfRoute){
            finishPointOnRoute() 
        }
    }

    const {data:criticalities, status} = useCriticalityByTypeByTagFP(item.tagFP, CriticalityTypes.puntuation)
    //COMPONENT METHODS

    const submit = () => {
        statusController({ status: QueryStatus.Loading, data:undefined})
        if(!(survey?.lubrication.taskDone || survey?.cleaning.taskDone || survey?.repairment.taskDone)){
             createSurvey({
                ...survey?.inspection,
                taskDone:true,
                taskTime:'0',
                quantity:0,
                outOfRoute:outOfRoute,
                taskType:SurveyTasksValues.control,
                manualRegistration:true,
                review:item.review,
                lubricant:item.lubricant,
                measureUnit:item.measureUnit,
                lubricantType:item.lubricantType,
                impossibleToLubricate:false,
                sampleExtraction:false,
                lubricates:false,
                reason:'',
                route:'',
                endTime:new Date().toISOString(),
                consumables:JSON.stringify([]),
            }).then(({data})=>{
                createObservation([
                    ...anomalies.map((o:ObservationInterface)=>({
                        ...o,
                        surveyId:data.id,
                        taskType:SurveyTasksValues.control
                    })),
                ])
                updateEquipmentInfo({
                    ...item.info,
                    supplies:infoSupplies.length === 0 ? ["SIN INSUMOS"] : infoSupplies,
                    lastInspectionDate: new Date().toISOString(),
                    assetCriticality:selectedCriticality,
                    pending:outOfRoute ? item.info?.pending : false
                }).then((data)=>{
                    if(data.error) statusController({ status: QueryStatus.Error, data })
                    finish()
                    statusController({ status: QueryStatus.Success, data})
                    resetSurvey()
                })
            }).catch((err)=>{
                statusController({ status: QueryStatus.Error, data:err })
                console.log(err)
            })
        }
        else{
            updateEquipmentInfo({
                ...item.info,
                supplies:infoSupplies.length === 0 ? ["SIN INSUMOS"] : infoSupplies,
                lastInspectionDate: new Date().toISOString(),
                assetCriticality:selectedCriticality,
                pending:outOfRoute ? item.info?.pending : false
            }).then((data)=>{
                if(data.error) statusController({ status: QueryStatus.Error, data })
                statusController({ status: QueryStatus.Success, data})
                finish()
            })
            .catch((err)=>{
                statusController({ status: QueryStatus.Error, data:err })
            })
        }
        
    } 
    
    errorsLog(errors)

    const defaultColor = (item:CriticalityInterface) => (
        selectedCriticality
            ? selectedCriticality === item.description 
                ? item.color
                : theme.palette.grey[500]
            : item.color
    )

    return(<>
            
        {outOfRoute && /* BACK BUTTON */
            <div>
                <CustomButton 
                    action={()=>backTaskStep(taskType)} 
                    icon='before' 
                    variant='iconButton' 
                    title='Atras' 
                />
            </div>
        }
        <FlexContainer height='100%' flexDirection='column' padding='24px' align='center' justify='center'>
            <Typography variant='h6' >Estado general del punto </Typography>
                <br />
                <FlexContainer justify='center'>
                    <FlexContainer flexWrap='wrap' justify='center'>
                        {status === 'loading' 
                            ? <CircularProgress size='40px'/>
                            : criticalities?.map((item,index)=>
                                    <FlexContainer 
                                    key={index} 
                                    align='center'
                                    >
                                        <PointerContainer>
                                            <FlexContainer 
                                            onClick={()=>{
                                                setAssetCriticality(item)
                                                setValue('assetCriticality',item)
                                            }} 
                                            borderRadius='10px' 
                                            width='290px' 
                                            height='50px' 
                                            background={defaultColor(item)} 
                                            color='#FFF'
                                            style={{textAlign:'center'}} 
                                            justify='center' 
                                            align='center' 
                                            children={item.description} />
                                        </PointerContainer>
                                    </FlexContainer>

                            )
                        }
                </FlexContainer>
            
            </FlexContainer>
            <br/>
            <AddSupply
                outOfRoute={outOfRoute}
                listTitle='Insumos del equipo' 
                formTitle={'Nuevo insumo'}
                buttonTitle={'Nuevo insumo'}
                variant={'list'}
                buttonType={'add'} 
                lubricationPoint={item}
                freesolo
                taskType={taskType}
                inmutableValues= {item.supplies}
            />

            <TgdFab 
                disabled={!watch('assetCriticality')}
                bottom={4}
                right={4}
                color={watch('assetCriticality') ? 'primary' : 'inherit'} 
                onClick={handleSubmit(submit) }
                children={<CustomIcon icon='check'/>}
            />

        </FlexContainer>

    </>)
}

export default React.memo(SurveyPuntuation)