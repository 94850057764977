import { createStyles, Divider, Icon, makeStyles, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import React from 'react';

import PageContainer from '../components/pageContainers/PageContainer';
import { PlantInterface } from '../domains/plants/service.plant';
import ManageAnomaliesPage from '../domains/anomalies/pages/ManageAnomaliesPage';
import ManageAreaPage from '../domains/areas/pages/ManageAreaPage';
import ManageComponentPage from '../domains/components/pages/ManageComponentPage';
import ManageCriticityPage from '../domains/criticalities/pages/ManageCriticalityPage';
import ManageElementsPage from '../domains/elements/pages/ManageElementsPage';
import ManageEquipmentsPage from './plantConfigPages/ManageEquipmentsPage';
import ManageLubricantsPage from '../domains/lubricants/pages/ManageLubricantsPage';
import ManagePermissionsPage from '../domains/permisions/pages/ManagePermissionsPage';
import ManageRoutesPage from '../domains/routes/pages/ManageRoutesPage';
import ManageSectorPage from '../domains/sectors/pages/ManageSectorPage';
import ManageTypesPage from '../domains/types/pages/ManageTypesPage';
import ManageAddedReasonsPage from '../domains/added-reason/pages/ManageAddedReasonsPage';
import TgdSnackbar from '../components/snackbar/TgdSnackbar';
import ManageUsersPage from '../domains/users/pages/ManageUsersPage';
import ManageSuppliesPage from '../domains/supply/pages/ManageSuppliesPage';
import ManageObservationsPage from '../domains/observations/pages/ManageObservationsPage';
import ManageContainersPage from '../domains/containers/pages/ManageContainersPage';
import ManageCollectorsPage from '../domains/collectors/pages/ManageCollectorsPage';
import FeatureStatusContainer, { useFeatures }  from '../infrastructure/appConfig/featureManagement/FeatureStatusContainer';
import { EnumSupplyTypes } from '../domains/supply/types/SupplyTypes';


const useStyles = makeStyles((theme) => createStyles({
    verticalTabsContainer: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        /* height: 'calc(100vh - 15vh - 64px)', */
        height: 'auto',
        width:'100%',
    },
    tabs: {
        //padding:'20px 0px',
        borderRight: `1px solid ${theme.palette.divider}`,
    },
  }));

const a11yProps = (index: any) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }
  
export const TabPanel:React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {

    return (
        <div 
        {...other} 
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        style={{width:'100%'}}> 
            {value === index && (
                children   
            )}
        </div> 
    );
}

interface Props{
    plant:PlantInterface
}

const PlantManagementPage:React.FC<Props> = ({plant}) => {

    const features = useFeatures()
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const PlantManagementConfig = {
        entities:[
            {
                label:"Usuarios",
                component:<ManageUsersPage plant={plant}/>
            },
            {
                label:"Colectores",
                component: <FeatureStatusContainer feature={features.collectorFeature}>
                    <ManageCollectorsPage plant={plant}/>
                </FeatureStatusContainer>
            },
            {
                label:"Tipos",
                component:<ManageTypesPage plant={plant}/>
            },
            {
                label:"Lubricantes",
                component:<ManageLubricantsPage plant={plant}/>
            },
            {
                label:"Recipientes",
                component:<ManageContainersPage plant={plant}/>
            },
            {
                label:'Sectores',
                component:<ManageSectorPage plant={plant}/>
            },
            {
                label:'Áreas',
                component:<ManageAreaPage plant={plant}/>
            },
            {
                label:'Componentes',
                component:<ManageComponentPage plant={plant}/>
            },
            {
                label:'Elementos',
                component:<ManageElementsPage plant={plant}/>
            },
            {
                label:'Herramientas',
                component:<ManageSuppliesPage type={EnumSupplyTypes.tool} plant={plant}/>
            },
            {
                label:'Consumibles',
                component:<ManageSuppliesPage type={EnumSupplyTypes.consumable} plant={plant}/>
            },
            {
                label:'Protecciones',
                component:<ManageSuppliesPage type={EnumSupplyTypes.protection} plant={plant}/>
            },
            {
                label:'Permisos',
                component:<ManagePermissionsPage plant={plant}/>
            },
            {
                label:'Criticidades',
                component:<ManageCriticityPage plant={plant}/>
            },
            {
                label:'Activos',
                component:<ManageEquipmentsPage plant={plant}/>
            },
            {
                label:'Rutas',
                component:<ManageRoutesPage plant={plant}/>
            },
            {
                label:'Anomalías',
                component:<ManageAnomaliesPage plant={plant}/>
            },
            {
                label:'Motivos Agregados',
                component:<ManageAddedReasonsPage plant={plant}/>
            },
            {
                label:'Observaciones',
                component:<ManageObservationsPage plant={plant}/>
            },
            
            
        ]
    }

    return (
        <>
            
            {!!plant && <>
                <TgdSnackbar />
                <Paper elevation={3}>
                    <PageContainer>
                        <Typography variant='h5'><Icon style={{margin:'0px 10px -5px 0'}}>settings</Icon>Configuraciones</Typography>
                    </PageContainer>
                    <Divider /><br/>
                    <div className={classes.verticalTabsContainer}>
                        <Tabs
                            style={{overflow:'unset'}}
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            className={classes.tabs}
                        >
                            {/* Tab Menu */}
                            {PlantManagementConfig && PlantManagementConfig.entities.map((item:{label:string},index:number) =>
                                <Tab key={index+63} label={item.label} {...a11yProps(index)}/>
                            )} 
                        </Tabs>
                        {/* components */}
                        
                        {PlantManagementConfig && PlantManagementConfig.entities.map((item:{component:React.ReactNode},index:number) =>
                            <TabPanel key={index} value={value} index={index}>{item.component}</TabPanel>
                        )}
                    </div>
                </Paper>
            </>}
        </>
    );
}

export default React.memo(PlantManagementPage);
