import axios,{AxiosRequestConfig} from 'axios';
import { queryCache, useMutation } from 'react-query';
import { URL } from '../../../../../infrastructure/appConfig/appConfig';
import { MutationQueryObject } from '../../../../../infrastructure/Interfaces';
import { InfoLubricationPointInterface } from '../../../../equipmentsInfo/service/service.info';
import { useToken } from '../../../../session/service.session';
import { suppliesToObject } from './../../../../../utils/utils';

export interface LeakLevelInterface{
    leakQuantity:number
    min:number
    max:number
    description:string,
    neededQuantity:number
}




/*---------------
Axios
-----------------*/ 

export const UpdateLubricationPointInfo = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateEquipmentInfo`,
        data
    }
    return axios(config).then((res)=>{return res}).catch((err) => {return err})
}


/*---------------
Queries
-----------------*/ 




/*---------------
MUTATIONS
-----------------*/ 

export const useUpdateEquipmentInfo = ():[(data:any)=>Promise<any>,any] => {

    const token = useToken()
    const [mutation,data] = useMutation(UpdateLubricationPointInfo)
    
    const updateEquipmentInfo = (data:InfoLubricationPointInterface) => {

        return mutation({
            data:{
                ...data,
                supplies:JSON.stringify(data.supplies),
                updatedToday:true
            },
            token
        }).then((res)=>{
            queryCache.invalidateQueries('pendingPoints')
            queryCache.invalidateQueries('DailyOperatorLubricationPoints')
            queryCache.invalidateQueries('AllLubricationPointsAndInfoByTagFP')
            queryCache.invalidateQueries('DailyOperatorLubricationPoints')
            return res
        })
    }

    return[
        updateEquipmentInfo,
        data
    ]
}
