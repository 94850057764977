//--------------------------------------
//TGD CONFIG
//--------------------------------------

export const ONLINE_MODE = true

//--------------------------------------
//PowerBI
//--------------------------------------


export const PBI_URL = `https://api.powerbi.com/v1.0/myorg` //powerBi Api endpoints


//--------------------------------------
//Development
//--------------------------------------

//export const URL =  `http://192.168.1.3:5050` //DEVELOPMENT BACKEND

export const FRONTURL = 'localhost:3000' //DEVELOPMENT FRONT


//--------------------------------------
//Testing back
//--------------------------------------

//export const URL = 'https://backtesting.tgdcompany.com' // TESTING BACKEND  

//export const URL = 'https://backofficebacktesting.tgdcompany.com' //TESTING BACKOFFICE BACKEND 

export const URL = 'https://stibacktesting.tgdcompany.com' // STI BACKEND 


//--------------------------------------
// SENSORING
//--------------------------------------

export const SENSORING_URL = 'https://basso.tgdcompany.com' //BASSO PRODUCTION NODE RED

//export const SENSORING_URL = 'http://192.168.1.3:1880' //DEVELOPMENT NODE RED

//--------------------------------------
//Production 
//--------------------------------------

//export const URL = 'https://java.tgdcompany.com' //PRODUCTION BACKEND 

//export const URL = 'http://localhost:8000' // PRODUCTION LOCAL BACKEND 


//--------------------------------------
//Vertion config
//--------------------------------------


type company = string
type version = string
type serviceDescription = string

/* ----------------------------------------------------
------Versioning arrangement:--------------------------
------------------------------------------------------*/

/* versionProd.versionTesting.versionDev.versionLocal */

export const SOFT_VERSION:[company,version,serviceDescription][] = [
    ['TGD',`2.9.2`,'TGD SOFT'],
    ['STI','1.3.1','STI SENSORING']
]