import { queryCache, useQuery } from "react-query";
import { getLocalStorage, setLocalStorage } from "./utils/localStorage";
import { useUser } from '../../domains/persons/services/service.person';
import { getDate } from "../../utils/utils";

type LocalStorageConfig = {
    unique?:boolean
    enabled?:boolean
    defaultUserEmail?:string

} 
export const useLocalStorage = <TResult,>(key:string,initialState?:TResult,config?:LocalStorageConfig):[TResult | undefined,(item:any)=>void] => {
    
    //instance to set enable timing to fetch 
    const _enabled = config?.enabled
    const _unique = config?.unique
    //user that localstorage data belongs to
    const {data:user} = useUser()
    
    //Current date
    const date = getDate().nowDate()

    //Key with User belongning
    const _user = config?.defaultUserEmail ? config.defaultUserEmail : user?.email
    const _Key = _unique 
        ? (user && key && `${key}-${_user}`)
        : key

    //localData
    const localData = getLocalStorage<TResult>(_Key!);
 
    const setValue = (value:TResult) => {
        setLocalStorage(_Key!,value)
        queryCache.setQueryData(_Key,value)
    }

    const {data} = useQuery(_Key,()=>{
        if (initialState){
            if(date != localData?.date){
                setLocalStorage(_Key!,initialState)
                return initialState
            }else{
                return localData.value
            }
        }else{
            return localData.value
        }
    },{
        enabled:!!date && !!_enabled ,
        refetchOnWindowFocus:false
    })


    return [data, setValue];
}
