import React from 'react';
import { capitalize, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';


import AssignCancelSupply from './AssignCancelSupply';
import { useAllSupplies } from '../services/service.supplies';
import { LubricationPointInterface, useLubricationPointByTagTGD } from '../../equipments/services/service.lubricationPoints';
import { supplyParser } from '../../../utils/utils';
import TgdTable, { TgdTableInterface } from '../../../components/TgdTable'
import ComponentMode from '../../../components/ComponentMode';
import { SupplyInterface, SupplyType } from '../types/SupplyTypes';

interface Props{
    item?:LubricationPointInterface
    type:SupplyType
}

const AssignCancelSupplyPage:React.FC<Props> = ({item,type}) => {

    const {data:allSupplies, status} = useAllSupplies()
    const filteredSupplies = allSupplies?.filter((item)=>item.type === type)
   
    const {data:lubricationPoint} = useLubricationPointByTagTGD(item?.tagTGD)

    const { tools, consumables, securityItems } = supplyParser(lubricationPoint?.supplies)

    const title = 
        (type === 'CONSUMIBLE' && 'consumibles') ||
        (type === 'HERRAMIENTA' && 'Herrramientas')||
        (type === 'PROTECCION' && 'Protecciones') || ''

    const componentSupplies = 
        (type === 'CONSUMIBLE' && consumables) ||
        (type === 'HERRAMIENTA' && tools) ||
        (type === 'PROTECCION' && securityItems) || []
    
    const columns:TgdTableInterface[] = [ 
        {label:'ID',atribute:'id'},
        {label:'Tipo',atribute:'type'},
        {label:capitalize(type),atribute:'supply'},
        {label:'Descripción',atribute:'description'},
    ]

    return (

            <ComponentMode popoverTitle={`Gestionar ${title} del Equipo`} icon='config' variant='chip' title={`Gestionando ${title} del punto:${lubricationPoint?.plantTag}`}>

            {status === 'error' && <><Alert severity='error' > Ha ocurrido un error </Alert><br/></>}
            {status === 'loading' ?<CircularProgress size='small'/>:
                <TgdTable
                searchFilters={['supply']}
                title={title}
                items={filteredSupplies}
                columns={columns}
                type={'supply'} 
                itemActions={(supply:SupplyInterface)=><AssignCancelSupply equipment={{...lubricationPoint,supplies:componentSupplies}} supply={supply}/>}
                />   
            }
                </ComponentMode>
    );
}

export default React.memo(AssignCancelSupplyPage);
