import { CircularProgress, IconButton } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDeleteAddedReason } from './services/service.addedReasons';
import TgdPopover from '../../components/popovers/TgdPopover';
import { CustomIcon } from '../../components/buttons/CustomButton';
import { useStatusProcessor } from '../errors/services/StatusProcessor';
import { useClickPosition } from '../../utils/hooks/useClickPosition';
import { useSnackbar } from '../../utils/hooks/useSnackbar';
import { AddedReasonInterface } from './types/AdedReasonTypes';


interface Props{
    item:AddedReasonInterface
}

const DeleteAddedReasonForm:React.FC<Props> = ({item}) => {

    const { deleteAddedReason, data} = useDeleteAddedReason()
    const { position, getClickPosition } = useClickPosition()
    const { status, message, error } = useStatusProcessor(data)
    const { setData } = useSnackbar()

    useEffect(() => {
            if(status === 'success'){
                setData({
                    modal:true,
                    errors:message,
                    severity:status,
                    position
                })
            }
            if(status === 'error'){
                setData({
                    modal:true,
                    errors:message,
                    severity:'warning',
                    position
                })
            }

    }, [status]);


    return (
        data.isLoading ? <CircularProgress size='20px' /> :
        <TgdPopover mode='hover' title='Eliminar motivo agregado'>
            <IconButton size='small' onClick={(e) => getClickPosition(e,()=>deleteAddedReason(item))}>
                <CustomIcon icon='delete' />
            </IconButton>
        </TgdPopover>
    );
}

export default React.memo(DeleteAddedReasonForm);
