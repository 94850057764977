import React, { useState } from 'react';

//Materia
import { useTheme } from '@material-ui/core';

//components
import AddPersonsForm from '../../../domains/persons/AddPersonsForm'
import UserList from '../../../components/lists/EntityList';
import SidebarContainer from '../../../components/pageContainers/SidebarContainer';


//services
import { useAdministators } from '../../../domains/persons/services/service.person';
import OnlineOfflineMode from '../../../infrastructure/online/offline Mode/OnlineOfflineMode';


const AddAdminPageRefactor: React.FC = () => {
    const theme = useTheme()
    const [admins,{status:adminStatus}] = useAdministators()
    const [open,setOpen] = useState(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [focusPersonData,setFocusPersonData] = useState<any>()

    const handleModal = () =>{
        setOpen(!open)
    }

    const newPersonEmail = (e:any) => {
        /* console.log(e) */
        setFocusPersonData({email:e})
    }

    return (
        <OnlineOfflineMode>
            <SidebarContainer>
                <UserList
                    title='Administradores'
                    icon={'person'}
                    status={adminStatus}
                    items={admins}
                    />
                <AddPersonsForm
                    color={theme.palette.primary.main}
                    handleEmail={newPersonEmail}
                    handleNext={handleModal}
                    title={'Agrega un Cliente'}
                    endpoint={'CreateAdmin'}
                    />
            </SidebarContainer>
            
        </OnlineOfflineMode>
    );
}

export default AddAdminPageRefactor;
