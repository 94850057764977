import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { StockInterface, useUpdateStock } from './services/service.stock';
import { useEffect } from 'react';
import ComponentMode from '../../components/ComponentMode';
import FlexContainer from '../../components/presentational/FlexContainer';
import StatusAndMessage from '../errors/StatusAndMessage';
import { TextField } from '@material-ui/core';
import { TypeFormRegex } from '../../infrastructure/regex/regexForms';
import CustomButton from '../../components/buttons/CustomButton';
import { useStatusProcessor } from '../errors/services/StatusProcessor';
import { QueryStatus } from 'react-query';

interface Props{
    item:StockInterface
}

const NewStockQuantityForm:React.FC<Props> = ({item}) => {
    
    const  { register, setValue, errors, watch, handleSubmit, reset } = useForm()
    const  [updateStock,updateResult] = useUpdateStock()
    const {status,message,error} = useStatusProcessor(updateResult) 

    const title = 'Gestionar el stock'
    
    useEffect(()=>{
        if(item){
            Object.entries(item).map(([key,value]:[string,any])=>{
                register({
                    name:key,
                    value
                },{
                    required:{
                        value:!!value,
                        message:'Campo requerido'
                    }
                })
            })
        }
    },[item, register])


    const submit = (data:any) => {
        updateStock(data)
    }
    
    return (
        <>
        <ComponentMode key={item.id} variant='iconButton' mode='popover' title={title} icon='stock'>
            <StatusAndMessage status={status} message={message} error={error} />
            <FlexContainer flexDirection={'column'}>
                    <TextField
                    inputRef={register({
                        pattern:{
                            value:TypeFormRegex.integer,
                            message:'Solo numeros'
                        }
                    })}
                    name='actualStock'
                    size='small'
                    variant='outlined'
                    defaultValue={watch('actualStock')}
                    label={errors?.actualStock?.message || 'Cantidad actual'}
                    color={errors?.actualStock?.message ? 'primary' : 'secondary'}
                    />

                    <CustomButton status={status} action={handleSubmit(submit)} >Guardar</CustomButton>
            </FlexContainer>
        </ComponentMode>
        
        </>
    );
}

export default NewStockQuantityForm;
