import React, { useEffect } from 'react';
import ModalButton from '../../components/buttons/ModalButton';
import { useForm } from 'react-hook-form';
import { StockInterface, useCreateStock } from './services/service.stock';
import { Typography } from '@material-ui/core';
import CustomButton from '../../components/buttons/CustomButton';
import FlexContainer from '../../components/presentational/FlexContainer';
import theme from '../../infrastructure/materialUi/themeConfig';
import StatusAndMessage from '../errors/StatusAndMessage';
import SelectArea from '../areas/molecules/SelectArea';
import SelectSimpleEntity from '../../components/select/SelectSimpleEntity';
import { SubCategoryInterface } from '../sub-categories/service.subcategories';
import { useStatusProcessor } from '../errors/services/StatusProcessor';
import { TgdTextField } from './../form-config/formComponents/TgdTextField';
import { errorsLog } from '../../utils/utils';
import SelectTypeBy from '../types/molecules/SelectTypeBy';
import { enumTgdTypes } from '../types/Types/types';
import { ComponentUIModes } from '../../components/ComponentUIModes';


interface Props{
    mode:ComponentUIModes
    subcategory:SubCategoryInterface
}

const CreateStockForm:React.FC<Props> = ({mode,subcategory}) => {
    
    const tagFP = subcategory.tagFP
    const [createStock, createResult] = useCreateStock()
    const { status, message,error } = useStatusProcessor(createResult)
    const {register,setValue,errors,watch,handleSubmit,control,reset} = useForm()
    const title='Nuevo producto de stock'
    
    useEffect(()=>{
        register({name:'id',type:'number',value:0})
        register({name:'tagFP',value:tagFP})
        register({name:'categoryArea',type:'string',value:'LUBRICACION'})
        register({name:'category',value:subcategory.category})
        register({name:'subcategory',value:subcategory.subcategory})
        
        register({name:'measureUnit',type:'string',value:''}, {required:{value:true,message:'requerido'}})
        register({name:'brand',type:'string',value:''}, {required:{value:true,message:'requerida'}})
        register({name:'product',type:'string',value:''}, {required:{value:true,message:'requerido'}})
        register({name:'minimumRequired',type:'string',value:''}, {required:{value:true,message:'requerido'}})
        register({name:'actualStock',type:'string',value:''}, {required:{value:true,message:'requerido'}})
        register({name:'providerArea',type:'string',value:''}, {required:{value:true,message:'requerida'}})
        register({name:'complex',type:'string',value:''}, {required:{value:true,message:'requerido'}})
    })

    errorsLog(errors)

    const submit = (item:StockInterface) => {
        createStock(item).then(()=>{
            reset()
        })
    }

    const content=<> 
        <Typography variant='h6'>{mode === 'content' && title}</Typography>
        <StatusAndMessage status={status} message={message} error={error} />
        <FlexContainer padding={`${theme.spacing(3)}px 0px`} flexDirection={'column'}>
            
                <TgdTextField
                onChange={(value) => setValue('product',value)}
                value={watch('product','')}
                name='product'
                label={'Nombre del producto'}
                error={errors?.product?.message}
                />

                <TgdTextField
                onChange={(value) => setValue('brand',value)}
                value={watch('brand','')}
                name='brand'
                label={'Marca'}
                error={errors?.brand?.message }
                />
                
                <TgdTextField
                onChange={(value) => setValue('actualStock',parseInt(value))}
                value={watch('actualStock','')}
                name='actualStock'
                label={`Stock actual`}
                error={errors?.actualStock?.message}
                />

                <TgdTextField
                onChange={(value) => setValue('minimumRequired',parseInt(value))}
                value={watch('minimumRequired','')}
                name='minimumRequired'
                label={`Cantidad mínima`}
                error={errors?.minimumRequired?.message}
                />

                    <SelectTypeBy
                        error={errors?.measureUnit?.message}
                        by={{key:'type',value:enumTgdTypes.unity}}
                        tagFP={tagFP}
                        onChange={(value)=>setValue('measureUnit',value)}
                        defaultValue={watch('measureUnit')}
                        />
                  
                <SelectArea
                error={errors?.providerArea?.message}
                tagFP={tagFP}
                onChange={(value)=>setValue('providerArea',value)}
                defaultValue={watch('providerArea')}
                />

               <SelectSimpleEntity
                    error={errors?.complex?.message}
                    complete
                    onChange={(value:any) => setValue('complex',value.value)}
                    label={'Abastecimiento'}
                    entityList={[{label:'COMPLEJO',value:true},{label:'SIMPLE',value:false}]}
                    showTitle={'label'}
                    color={errors?.complex?.message ? 'primary' : 'secondary'}
               />

                <CustomButton status={status} action={handleSubmit(submit)}>Crear</CustomButton>
            
        </FlexContainer>
    </>
    
    return (<>

        {mode === 'content' && content}
    
        {mode === 'modal' && <ModalButton icon='add' title={title} >
            {content}
        </ModalButton>}
    </>);
}

export default React.memo(CreateStockForm);
