import React from 'react';
import { PlantInterface } from '../../plants/service.plant';
import AnyGrid, { CarrousellActionInterface } from '../../../components/grids/AnyGrid';
import DeletePermitionForm from '../DeletePermitionForm';
import { gridModelPermission, usePermissionsByTagFP } from '../services/service.permission';
import AssignCancellPermissionForm from '../components/AssignCancelMultiplePermissionForm';
import { PermissionInterface } from '../types/permissionsTypes';
import CUPermissionForm from '../CUPermissionsForm';
import { CrudFormType } from '../../../infrastructure/form/typescript/FormTypes';

interface Props{
    plant:PlantInterface
}

const carrousellActions:CarrousellActionInterface[] = [
    {icon:'edit',component:(item:PermissionInterface)=><CUPermissionForm {...{item,tagFP:item.tagFP,actionType:CrudFormType.update}} />}
] 

const ManagePermissionsPage:React.FC<Props> = ({plant}) => {
    
    const {data:permissions} = usePermissionsByTagFP(plant.tagFP)
    const tagFP = plant.tagFP

    return(
        <AnyGrid 
            gridModel={gridModelPermission}
            title='Permisos'
            items={permissions || []}
            loading={permissions ? false : true}
            height='55vh'
            carrousellActions={carrousellActions}
            itemActions={(item:any)=><>
                    <DeletePermitionForm item={item}/>
                </>}
            headerActions={(item:any) =><>
                <CUPermissionForm {...{tagFP,actionType:CrudFormType.create}} />
                <AssignCancellPermissionForm tagFP={plant.tagFP}/>
            </>}
            
        />
    )
}

export default React.memo(ManagePermissionsPage);
