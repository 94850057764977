import React from 'react'
import { Typography } from "@material-ui/core"
import { GridModelInterface } from "../../infrastructure/Interfaces"
import FlexContainer from "./FlexContainer"

const EntityProperties:React.FC<{entity:any,gridModel:GridModelInterface}> = ({entity,gridModel}) => {

    const keyValues = entity && Object.entries(entity).map(([key,value]) => {
        return[gridModel[key]?.label,value]
    })


    return(
        <FlexContainer flexDirection='column' padding='12px'>
            {keyValues && keyValues.map(([key,value]:any)=>
                <Typography key={key}>
                    {key}: {value}
                </Typography>
            )}
        </FlexContainer>
    )

}
export default EntityProperties