import { CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { LubricationPointInterface, useLubricationPointByTagTGD } from '../../equipments/services/service.lubricationPoints';
import { RouteInterface, useRoutesByTagFP } from '../services/service.routes';
import TgdTable, { TgdTableInterface } from '../../../components/TgdTable';
import AssignCancelRoute from './AssignCancelRoute';
import ModalButton from '../../../components/buttons/ModalButton';



interface Props{
    item:LubricationPointInterface
}


const AssignCancelRouteForm:React.FC<Props> = ({item}) => {

    const {data:allRoutes,status:allRoutesStatus,error:allRoutesError}= useRoutesByTagFP(item?.tagFP)
    const {data:LubricationPoint} = useLubricationPointByTagTGD(item.tagTGD)
    


    const columns:TgdTableInterface[] = [
        {label:'ID',atribute:'id'},
        {label:'Operador',atribute:'operator'},
        {label:'Periodicidad',atribute:'periodicity'},
        {label:'Ruta',atribute:'route'},
        {label:'Nombre',atribute:'routeName'},
        {label:'Fecha de inicio',atribute:'startDate'},
    ]

    return (
        
        <ModalButton 
        variant='chip'
        popoverTitle='Gestionar rutas del equipo' 
        icon='config' 
        buttonText='Rutas'
        title={`Asignar Rutas al Equipo: ${LubricationPoint?.plantTag}`} 
        > 
            <>
                {allRoutesError && <><Alert severity='error' > Ha ocurrido un error </Alert><br/></>}
                {allRoutesStatus === 'loading' ? <CircularProgress size='small'/> :
                
                    <TgdTable
                    searchFilters={['route']}
                    title='Rutas'
                    items={allRoutes}
                    columns={columns}
                    type={'route'}  
                    itemActions={(route:RouteInterface)=><AssignCancelRoute equipment={LubricationPoint} route={route}/>}
                    />
                }
            </>
        </ModalButton>
    );
}

export default React.memo(AssignCancelRouteForm);
