import { Badge, Paper, Typography } from '@material-ui/core';
import { FlagOutlined } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router';
import PageContainer from '../../../components/pageContainers/PageContainer';
import PointerContainer from '../../../components/pageContainers/PointerContainer';
import TgdPopover from '../../../components/popovers/TgdPopover';
import FlexContainer from '../../../components/presentational/FlexContainer';
import { USER_ROUTES } from '../../../infrastructure/routes/routes';
import { PersonInterface, UserInterface } from '../../persons/interfaces/userInterfaces';
import { useDimention } from '../../persons/services/service.person';
import CheckList from '../domains/checkList/CheckList';
import { colectorHomeSupplies, useCheckListController } from '../domains/checkList/controllers/useCheckListController';
import { useDailyPreviousPendingPointsWithInfo, usePendingPoints } from '../domains/lubricationPoints/services/daoLubricationPoints';
import { useSurveyController } from '../domains/surveys/controllers/surveyController';
import StartRoute from '../domains/surveys/SurveyCarrousell';
import ColectorRoutesPage from './ColectorRoutesPage';
import HomePageR from './HomePageR';
import { useLocalStorage } from './../../../infrastructure/localStorage/useLocalStorage';
import { queryCache, useQuery } from 'react-query';
import { useDailyRoutes } from './../domains/routes/services/colectorServiceRoutes';


interface Props {
    operator:PersonInterface
    tagFP:string
}


export const useSupervisorSelectedLubricatorData = (operator?:PersonInterface) => {

    const key = 'SupervisorSelectedLubricatorData'

    const lubricatorData = useQuery([key],()=>{
        return operator
    },{
        enabled:!!operator,
        refetchOnWindowFocus:false
    })

    return{
        ...lubricatorData
    }
}


const SupervisorColectorPage:React.FC<Props> = ({operator,tagFP}) => {
    
    useSupervisorSelectedLubricatorData(operator)
    const defaultPath = operator.name.split(' ').join('-')
    const checkListTypes = colectorHomeSupplies()
    const { notification, routes, icons } = useCheckListController()
    const { data:dailyRoutes } = useDailyRoutes()
    const dailyPendingPoints = usePendingPoints()
    const lasDelayedPendingPoints = useDailyPreviousPendingPointsWithInfo()
    const lubricationPointsBadge = (dailyPendingPoints && lasDelayedPendingPoints) && (dailyPendingPoints.length + lasDelayedPendingPoints.length)
    const { push }= useHistory()
    const dimention =  useDimention()
    useSurveyController()



    return(<>
             
            <FlexContainer gap='24px' flexWrap='wrap' padding='24px'>
                {checkListTypes.map((type,index)=>
                <TgdPopover key={type} mode='hover' title={type}>
                    <PointerContainer key={type}>
                        <Badge  style={{display:'block'}} color="primary" badgeContent={notification(type) || undefined}>
                            <Paper elevation={3}>
                                <FlexContainer onClick={()=>push(`${routes[type]}`)} height='80px' width='120px' align={'center'} justify='center'  padding='16px'>
                                    {icons[type]} {/* {formModel[type].name.plural.default} */}
                                </FlexContainer>
                            </Paper>
                        </Badge>
                    </PointerContainer>
                </TgdPopover>
                )}
                <TgdPopover mode='hover' title={'RUTAS'}>
                    <PointerContainer >
                        <Badge  style={{display:'block'}} color="primary" badgeContent={dailyRoutes?.length || undefined}>
                             <Paper elevation={3}>
                                <FlexContainer onClick={()=>push(`${USER_ROUTES.dailyRoutes}`)} height='80px' width='120px' align={'center'} justify='center'  padding='16px'>
                                    <FlagOutlined/>
                                </FlexContainer>
                            </Paper>
                        </Badge>
                    </PointerContainer>
                </TgdPopover>

                <TgdPopover mode='hover' title={'RELEVAR TODO'}>
                    <PointerContainer>
                        <Badge  style={{display:'block'}} color="primary" badgeContent={lubricationPointsBadge}>
                            <Paper elevation={3}>
                                <StartRoute />
                            </Paper>
                        </Badge>
                    </PointerContainer>
                </TgdPopover>

        
            </FlexContainer>
{/*         
            <FlexContainer gap='24px' padding='48px 28px 0px 40px'>
                {checkListTypes.map((type)=>
                    <Route key={type} path={`/${USER_ROUTES.home}/${dimention}/${routes[type]}`}>
                        <Typography color='secondary' variant='body1'>
                            <strong>
                                {`${data ? data[type]?.picked?.length : '0'}/${data ? data[type]?.value?.length : '0'}`}
                            </strong>
                        </Typography>
                    </Route>
                )}
            </FlexContainer>
            
        </FlexContainer> */}
        

        {checkListTypes?.map((type)=>
            <Route key={type} path={`/${USER_ROUTES.home}/${dimention}/${defaultPath}/${routes[type]}`} >
                <PageContainer padding={'24px'} key={type}>
                    <CheckList checkListType={type} />
                </PageContainer>
            </Route>
        )}
        
        <PageContainer  padding={'0px 24px'} >
            <Route  path={`/${USER_ROUTES.home}/${dimention}/${defaultPath}/`} >
                <ColectorRoutesPage defaultPath={defaultPath} />
            </Route>
        </PageContainer>
        

    </>);
}

export default SupervisorColectorPage;
