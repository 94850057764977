import React, { useState } from 'react';
import FlexContainer from '../../components/presentational/FlexContainer';
import SelectSimpleEntity from '../../components/select/SelectSimpleEntity';
import CustomButton from '../../components/buttons/CustomButton';
import { useAssignCancelClusterCompany, AllClusterCompanies, useAllClusterCompanies } from './services/clusterEnterpricesService';
import { PersonInterface, UserInterface } from '../persons/interfaces/userInterfaces';
import { ClusterCompanyInterface } from './interfaces/clusterCompaniesInterfces';
import StatusAndMessage from '../errors/StatusAndMessage';
import {PersonRoleTypes} from '../persons/interfaces/PersonRoleTypes';

interface Props{
    person:PersonInterface
}

const SelectCompanyService:React.FC<Props> = ({person}) => {

    const { assignCompany, cancelCompany, status, error, message } = useAssignCancelClusterCompany()
    const { data:companies } = useAllClusterCompanies()

    const [companyToAssign, setcompanies] = useState<any>();

    const handleSubmit = (type:'A'|'C') =>{ 

        type === 'A' && companyToAssign.map((company:any)=>
        assignCompany({
                email:person.email,
                company:company.company
            })
        )

        type === 'C' && companyToAssign.map((company:any)=>
        cancelCompany({
                email:person.email,
                company:company.company
            })
        )
    }

    return (person.role === PersonRoleTypes.admin  || person.role === PersonRoleTypes.accountMannager || person.role === PersonRoleTypes.superAdmin ? <div>

        <StatusAndMessage {...{status,message,error}} />
        <FlexContainer >
            <SelectSimpleEntity
                width='250px'
                label='Gestionar Servicios'
                entityList={companies || []}
                showTitle='businessName'
                onChange={(value:any)=>setcompanies(value)}
                multiple={true}
                complete
                />
            <CustomButton {...{status,error}} popoverTitle='Asignar' action={() => handleSubmit('A')} icon='check' variant='iconButton' />
            <CustomButton {...{status,error}} popoverTitle='Desasignar' action={() => handleSubmit('C')} icon='close' variant='iconButton' />
        </FlexContainer>
    </div> : null);
}

export default SelectCompanyService;
