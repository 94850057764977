import axios, { AxiosRequestConfig } from "axios";
import { useMutation, useQuery } from "react-query";
import { URL } from "../../../infrastructure/appConfig/appConfig";
import { MutationQueryObject } from "../../../infrastructure/Interfaces";
import { useToken } from "../../session/service.session";
import { ClusterCompanyInterface } from "../interfaces/clusterCompaniesInterfces";
import { useStatusProcessor } from '../../errors/services/StatusProcessor';



interface AssignCancelInterface {company:string,email:string,state?:string}

//---------------------------
//----------------------axios
//---------------------------



export const AllClusterCompanies = (_:String,token:string) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config:AxiosRequestConfig = {
        method: "GET",
        url:`${URL}/AllClusterCompanies`,
    }
    return axios(config).then(res => {return res.data}).then(res => {return res})
}

export const UserCompanies = (_:String,token:string) => {
    return axios({
        method: "get",
        headers:{
            Authorization: `Bearer ${token}`
        },
        url:`${URL}/UserCompanies`,
    })
    .then(res => {return res.data})
    .catch(err => {
        console.log(err)
        return err
    })
}


export const CreateClusterCompany = ({data, token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateClusterCompany`,
        data
    }
    return axios(config).then(res => {return res.data}).then(res => {return res})
}

export const AssignCancelClusterCompany = ({data, token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/AssignCancelClusterCompany`,
        data
    }
    return axios(config).then(res => {return res.data}).then(res => {return res})
}




//---------------------------
//----------------------Query
//---------------------------

export const useUserCompanies = () => {
    const token = useToken()
    return useQuery<ClusterCompanyInterface[]>(['UserCompanies',token],UserCompanies,{
        refetchOnWindowFocus:false,
        staleTime:5000000,
        enabled:!!token
    })
}
export const useAllClusterCompanies = () => {
    const token = useToken()
    return useQuery<ClusterCompanyInterface[]>(['AllClusterCompanies',token],AllClusterCompanies,{
        refetchOnWindowFocus:false,
        staleTime:5000000,
        enabled:!!token
    })
}

//---------------------------
//------------------Mutations
//---------------------------

export const useAssignCancelClusterCompany = () => {

    const token = useToken()
    const [mutation,query] = useMutation(AssignCancelClusterCompany)
    const status = useStatusProcessor(query)

    const assignCompany = (data:AssignCancelInterface) => {
        mutation({
            token,
            data:{
                ...data,
                state:'A'
            }
        })
    }

    const cancelCompany = (data:AssignCancelInterface) => {
        mutation({
            token,
            data:{
                ...data,
                state:'C'
            }
        })
    }

    return {
        ...status,
        assignCompany,
        cancelCompany
    }

}