import React from 'react';
/* import CreateTypeForm from '../CreateTypeForm';
import UpdateType from '../UpdateTypeForm'; */
import { PlantInterface } from '../../plants/service.plant';
import { useTypesByPlant } from '../services/service.types';
import DeleteType from '../DeleteTypeForm';
import AnyGrid from '../../../components/grids/AnyGrid';
import { gridModelTypes } from '../services/GridModelTypes';
import { CrudFormType } from '../../../infrastructure/form/typescript/FormTypes';
import CUtypeForm from '../CUtypeForm';

interface Props{
    plant:PlantInterface
}

const ManageTypesPage:React.FC<Props> = ({plant}) => {

    const _tagFP = plant.tagFP
    const { data:types, isLoading } = useTypesByPlant(_tagFP)

    return(
        <AnyGrid
        gridModel={gridModelTypes}
            title='Tipos'
            items={types || []}
            loading={isLoading}
            height='55vh'
            carrousellActions={[
                {icon:'edit',component:(item,tagFP)=><CUtypeForm {...{item,tagFP}} actionType={CrudFormType.update} />}
            ]}
            itemActions={(item:any,items:any) =><> 
                    <DeleteType item={item} />
                </>
            }
            headerActions={(item:any)=>
                <CUtypeForm {...{tagFP:_tagFP}} actionType={CrudFormType.create} />
            }
        />
    )
}

export default React.memo(ManageTypesPage);
