import axios, { AxiosRequestConfig } from 'axios'
import { MutationResult, queryCache, useMutation, useQuery } from 'react-query'
import { URL } from '../../../infrastructure/appConfig/appConfig'
import { useToken } from '../../session/service.session'
import { useOperatorSupervisorTagFP } from '../../persons/services/service.person';
import { GridModelInterface, MutationQueryObject } from '../../../infrastructure/Interfaces';
import { CategoriesAndSubCategoriesInterface } from '../../categories/service.category'
import { useStatusProcessor } from '../../errors/services/StatusProcessor';
import { useTodayNewPurchaseRequest } from './hooks/useTodayNewPurchaseRequest';

//------------------------------
//INTERFACES--------------------
//------------------------------






export interface StockInterface{
    id:number
    tagFP:string
    category:string
    subcategory:string
    brand:string
    product:string
    minimumRequired:number
    actualStock:number
    measureUnit:string
    categoryArea:string
    providerArea:string
    complex:boolean
    hidden:boolean

}


export const gridModelStock:GridModelInterface = {
    product:{label:'Producto'},
    brand:{label:'Marca'},
    actualStock:{label:'Stock actual'},
    minimumRequired:{label:'Mínimo'},
    category:{label:'Categoría'},
    subcategory:{label:'Subcategoría'},
    measureUnit:{label:'Unidad de medida'},
    categoryArea:{label:'Area de la categoría'},
    providerArea:{label:'Area del proveedor'},
    complex:{label:'Reposición Compleja',options:[{name:'Simple',value:false},{name:'compleja',value:true}]},
    hidden:{options:[{name:'Archivados',value:true},{name:'Sin Archivar',value:false}],label:'Archivado'},
    tagFP:{label:'Planta'},
    id:{label:'Id'},
}


//------------------------------
//axios--------------------
//------------------------------



export const StockByTagFP = (_:string,tagFP:string,token:string) => {
    axios.defaults.headers.get['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.get['tagFP'] = tagFP

    const config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/StockByTagFP`,

    }

    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const StockAreaCategorySubcategory = (_:string,data:any,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`

    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/StockAreaCategorySubcategory`,
        data
    }

    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}
export const UpdateStockHiddenState = ({token,data}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`

    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateStockHiddenState`,
        data
    }

    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}






export const CreateStock = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`

    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateStock`,
        data
    }

    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const UpdateStock = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`

    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateStock`,
        data
    }

    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}






//------------------------------
//QUERY-------------------------
//------------------------------






export const useStockByTagFP = (tagFP?:string) =>{
    const token  = useToken()
    const supOpTagFP = useOperatorSupervisorTagFP()
    const queryTagFP = tagFP || supOpTagFP
    return useQuery<CategoriesAndSubCategoriesInterface[]>(['StockByTagFP',queryTagFP,token],StockByTagFP,{
        enabled:!!queryTagFP && !!token,
        staleTime:900
    })
}

interface stockAreaCategorySubcategoryInterface{
    categoryArea:string
    category:string
    subcategory:string
    tagFP:string
}
export const useStockAreaCategorySubcategory= (queryData?:stockAreaCategorySubcategoryInterface) => {
    const token = useToken()
    return useQuery<StockInterface[]>(['StockAreaCategorySubcategory',queryData,token],StockAreaCategorySubcategory,{
        enabled:!!queryData && !!token,
    })
}




//------------------------------
//MUTATION----------------------
//------------------------------


export const useCreateStock = ():[(item:StockInterface) => Promise<any>,MutationResult<any>] => {
    
    const token= useToken()
    const [mutation,query] = useMutation(CreateStock,{
        onSuccess:()=>{
            queryCache.invalidateQueries('StockAreaCategorySubcategory')
            queryCache.invalidateQueries('StockByTagFP')
        }
    })

    const createStock = (data:StockInterface) => {
        return mutation({
            token,
            data
        })
    }

    return [createStock,query]
}

export const useUpdateStock = ():[(item:StockInterface) => Promise<any>,MutationResult<any>] => {
    
    const token= useToken()
    const {varifyNewStockPurchase} = useTodayNewPurchaseRequest()

    const [mutation,query] = useMutation(UpdateStock,{
        onSuccess:()=>{
            queryCache.invalidateQueries('StockByTagFP')
            queryCache.invalidateQueries('StockAreaCategorySubcategory')
        }
    })

    const updateStock = (data:StockInterface) => {
        return mutation({
            token,
            data
        }).then(()=>{
            varifyNewStockPurchase(data)  
        })
    }

    return [updateStock,query]
}


export const useUpdateStockHiddenState = ():[(item:StockInterface) => Promise<any>,any] => {
    
    const token= useToken()
    const [mutation,query] = useMutation(UpdateStockHiddenState,{
        onSuccess:()=>{
            queryCache.invalidateQueries('StockByTagFP')
            queryCache.invalidateQueries('StockAreaCategorySubcategory')
        }
    })

    const {status,message,error} =useStatusProcessor(query)

    const archiveStock = (data:StockInterface) => {
        return mutation({
            token,
            data
        })
    }

    return [archiveStock,{...query,status,message,error}]
}










