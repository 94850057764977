import React from 'react';
//components

//material
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { NavLink, Route, Switch } from 'react-router-dom';
import { USER_ROUTES } from '../../infrastructure/routes/routes';
import {Chip} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useLangLabels } from '../language/services/useSetLabel';
import OnlineOfflineMode from '../../infrastructure/online/offline Mode/OnlineOfflineMode';
import LoginForm from './loginForm';

const useStyles = makeStyles((theme) => createStyles({

  loginContainer:{
    marginTop:'-100vh',
    width:'100vw',
    height:'100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

},
  container:{
    justifyContent:'center',

  },
  tagLine:{
    fontSize:'12px',
    marginTop:'100px',
    color:theme.palette.secondary.main,
    textAlign:'center'
  },

}));


const LoginPage:React.FC = () => {
    
	const {lang} =useLangLabels()
  	const classes = useStyles();
    
    return (
		<>
			{/* <Redirect from='/' to={`/${USER_ROUTES.login}`}/> */}
			<div className={classes.loginContainer}>
				<Grid container className={classes.container} >
					<Grid justify="center" container style={{maxWidth:'350px',}} item lg={3} md={4} sm={8} xs={10}>
							<LoginForm >
								<Switch>
										<Route path={`/${USER_ROUTES.demoRequest}`}>
									
											<Chip
											onDelete={()=>(true)}
											deleteIcon={<ArrowBackIcon/>}
											size="small"
											label={lang.actionTypes.login}
											clickable
											color="secondary"
											component={NavLink}
											to={`/${USER_ROUTES.login}`}
											/>
									
										</Route>
									<Route path={`/`}>
										<OnlineOfflineMode warning={false}>
											<Chip
											onDelete={()=>(true)}
											deleteIcon={<ArrowForwardIcon/>}
											size="small"
											label={lang.login.navigation.name}
											clickable
											color="secondary"
											component={NavLink}
											to={`/${USER_ROUTES.demoRequest}`}
											/>
										</OnlineOfflineMode>
									</Route>
								</Switch>
							</LoginForm>
					</Grid>
				</Grid>
			</div>
		</>
	)
}

export default LoginPage;
          