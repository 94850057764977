import React from 'react';
import DefaultFormLayout from '../../../components/forms/DefaultFormLayout';
import { CriticalityTypes } from '../../criticalities/types/types';
import FormSelector from '../../form-config/FormSelector';
import { STIEquipmentInterface } from './interfaces/STIEquipmentInterfaces';
import { useCreateSTIEquipment } from './services/STIEquipmentService';
import { useSTIEquipmentForm } from './services/useSTIEquipmentForm';





const CreateSTIEquipmentForm:React.FC<{tagFP:string}> = ({tagFP}) => {

    const {setValue,handleSubmit,watch,errors,form,reset} = useSTIEquipmentForm({actionType:'create',},tagFP)
    const {createSTIEquipment,data} = useCreateSTIEquipment()
    
    const filters = ['egressDate','admissionDate','state','lowReason','tagFP']
    const fields = form.fields?.filter((field:any)=>!filters.includes(field.name))
    
    const submit = (fields:STIEquipmentInterface) => {
        createSTIEquipment(fields).then(()=>reset())
    }

    return (
        <DefaultFormLayout 
        status={data.status}
        message={data.message}
        error={data.error} 

        icon={'add'} 
        buttonTitle={form.name} 
        popoverTitle={form.name} 
        onChange={handleSubmit(submit)} >
            {fields?.map((field:any)=>
                <FormSelector
                    key={field.name}
                    selector={field.formSelector}
                    fieldProps={{
                        type:field.type,
                        error:errors && errors[field.name]?.message,
                        label:field.label,
                        value:watch(field.name),
                        onChange:(value)=>setValue(field.name,value),
                        tagFP,
                        criticalityType:CriticalityTypes.equipment
                    }}
                />    
            )}
        </DefaultFormLayout>
    );
}

export default CreateSTIEquipmentForm;
