import { useCallback } from "react"
import { trunc } from './../../../../../utils/trunc';

const coeficients = [
    {
        min:-1,
        max:3,
        description:'green'
    },
    {
        min:3,
        max:30,
        description:'orange'
    },
    {
        min:30,
        max:100,
        description:'red'
    },
    {
        min:100,
        max:1000,
        description:'black'
    }
]


export const useLeakLevel = (params?:{lastlubricantCuantity:number,pointCapacity:number}) => {

    const calculateLeakLevel = useCallback((_lastlubricantCuantity:number | undefined ,_pointCapacity:number | undefined ) => {

        const lastlubricantCuantity= _lastlubricantCuantity || 0
        const pointCapacity= _pointCapacity || 0

        const leak:number = Math.round((lastlubricantCuantity < 1)
            ? 0 
            : (pointCapacity && ((lastlubricantCuantity * 100)/pointCapacity)))

            trunc((leak/100) * pointCapacity,2)
        return{
            ...coeficients.filter((c:any)=> ((leak <= c.max) && (leak > c.min)) )[0],
            leakQuantity:leak,
            neededQuantity: /* parseFloat(((leak/100) * pointCapacity).toFixed(2)) */ trunc((leak/100) * pointCapacity,2)
        }

    },[])

    const results = calculateLeakLevel(params?.lastlubricantCuantity,params?.pointCapacity)

    return {
        ...results,
        calculateLeakLevel
    }
}
