import React from 'react'
import { GridModelInterface } from '../../../infrastructure/Interfaces'
import { LubricationPointInterface } from '../../../domains/equipments/services/service.lubricationPoints'
import { GridModelSurvey, useSurveysByLubricationPoint } from '../../../domains/colector/domains/surveys/services/service.survey'
import { CustomIconType } from '../../buttons/CustomButton'
import ModalButton from '../../buttons/ModalButton'
import AnyGrid from '../AnyGrid'
import CustomObservationsList from './CustomObservationsList'

interface Props{
    mode:'modal' | 'content'
    lubricationPoint:LubricationPointInterface
}

const EquipmentSurveysList:React.FC<Props> = ({mode,lubricationPoint})=> {

    const entity = useSurveysByLubricationPoint(lubricationPoint.tagTGD).data
    const title =  'Relevamientos'
    const icon:CustomIconType = 'survey'
    const gridModel:GridModelInterface = GridModelSurvey

    const content = 
    <AnyGrid
        loading={entity ? false : true}
        items={entity || []}
        gridModel={gridModel}
        title={`${title} del equipo ${lubricationPoint?.plantTag}`}
        itemActions={(item:any)=> <CustomObservationsList mode='modal' queryType='survey' data={item} />}
        documentExportTitle={`Reporte_${title}_${lubricationPoint?.plantTag}`}
        /* renderOptions={item.renderOption} */
    />

    return(<>
        {mode === 'modal' && 
        <ModalButton fullwidth={true} icon={icon} variant='iconButton' popoverTitle={`${title} de este punto de lubricación`} >
            {content}
        </ModalButton>}

        {mode === 'content' &&
            content
        }
    </>)
}

export default React.memo(EquipmentSurveysList)