import React from 'react'
import CustomButton from '../../../components/buttons/CustomButton'
import { LubricationPointInterface } from '../../equipments/services/service.lubricationPoints'
import { useAssignCancelPermission } from '../services/service.permission'
import { PermissionInterface } from '../types/permissionsTypes'


const AssignCancelPermission:React.FC<{equipment:LubricationPointInterface | undefined,permission:PermissionInterface }> = ({equipment,permission}) =>{

    const [assignCancel,{status}] = useAssignCancelPermission()

    const assign = () => {
        assignCancel([{
            //wrong permisson, this is permisson id not equipmentpermission id
            id:permission.id,
            permissionName:permission.permissionName,
            state:'A',
            equipment:equipment?.tagTGD,
            tagFP:equipment?.tagFP
        }])
    }

    const cancel = () => {
        assignCancel([{
            //wrong permisson, this is permisson id not equipmentpermission id
            id:permission.id,
            permissionName:permission.permissionName,
            state:'C',
            equipment:equipment?.tagTGD,
            tagFP:equipment?.tagFP
        }])
    }

    return(
        equipment?.permissions?.includes(permission.permissionName) 
        ?   <>
                <CustomButton variant='iconButton' disabled icon='check' styleProps={{color:'green'}}/>
                <CustomButton variant='iconButton' status={status} action={cancel} icon='delete' color='primary' />
            </> : <CustomButton variant='iconButton' status={status} action={assign} icon='add'  />
    )
}    

export default React.memo(AssignCancelPermission)