
import { CriticalityInterface } from './../types/types';


export const sortCriticalityByNumber= ( a:CriticalityInterface,b:CriticalityInterface)=>{
    const _a = a.number 
    const _b = b.number 
    if (_a < _b) return -1
    if (_a > _b) return 1
    return 0
}