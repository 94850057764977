import React from 'react';
import { Fab, Theme, createStyles, makeStyles, CircularProgress } from '@material-ui/core';
import { ReactQueryStatusType } from '../../infrastructure/Interfaces';
import Alert  from '@material-ui/lab/Alert';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: (props:any)=>({
        position: 'absolute',
        bottom: theme.spacing(props.bottom || 2),
        right: theme.spacing(props.right || 2),
        //left:theme.spacing(props.left),
        //top:theme.spacing(props.top)
    })
  }),
);

interface Props{
    disabled?:boolean
    onClick:()=>void,
    left?:number,
    top?:number,
    right?:number,
    bottom?:number,
    children:any
    color:'primary'|'secondary' | 'inherit'
    onClickStatus?: ReactQueryStatusType
}

const TgdFab:React.FC<Props> = ({left, top, right, bottom, onClick, children,color,onClickStatus,disabled})=> {

    const classes = useStyles({
        bottom:bottom,
        right:right,
        left:left,
        top:top
    })

    const _color = disabled ? 'inherit' : color

    const handleClick = () => {
        disabled ? console.log('campos requeridos') : onClick()   
    }

    return(
        <Fab className={classes.fab} color={_color} onClick={handleClick}>
            {onClickStatus 
            ?<>
                {onClickStatus === 'idle' && children}
                {onClickStatus === 'loading' && <CircularProgress color='inherit' size='24px' />}
                {onClickStatus === 'error' && <Alert severity="error">ha ocurrido un error</Alert>}
                {onClickStatus === 'success' && children}
            </>
            :<>{children}</>}
        </Fab>
    )
}
export default TgdFab;