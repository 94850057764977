import React from 'react';
import { PlantInterface } from '../../../plants/service.plant';
import { useUser } from '../../../persons/services/service.person';
import SelectSimpleEntity from '../../../../components/select/SelectSimpleEntity'
import CreateSTISensorForm from '../../STISensors/CreateSTISensorForm';
import { useSensorTypesByTagFP } from '../services/SensorTypeService';
import { useLangLabels } from './../../../language/services/useSetLabel';

interface props{
    tagFP?:string
    plant?:PlantInterface | undefined
    onChange:(value:string) => void
    defaultValue?:string | undefined 
    dimentionType?:string
    color?:'primary'|'secondary'
    error?:string
}

const SelectSTISensorType:React.FC<props> = ({ onChange, defaultValue, color,error,tagFP}) => {

    const {lang} = useLangLabels()
    const {data:sensorType} = useSensorTypesByTagFP(tagFP!)
    const {data:user} = useUser()
    
    return (<>
            <SelectSimpleEntity
                error={error}
                label={lang.iotSensorTypes.title.singular }
                onChange={(value:string)=>onChange(value)}
                entityList={sensorType || []}
                showTitle={'sensorType'}
                defaultValue={defaultValue}
            >
                {user && (user?.type === 'O' ? null : <CreateSTISensorForm tagFP={tagFP!}/>)}
            </SelectSimpleEntity>

    </>);

}

export default SelectSTISensorType
