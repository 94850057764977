
import axios, { AxiosRequestConfig } from 'axios';
import { useQuery, QueryResult, queryCache } from 'react-query';
import { useMutation } from 'react-query';
import { useDefaultMutation } from '../../../infrastructure/reactQuery.tsx/useMutation';
import { ContainersInterface } from '../types/ContainersInterfaces';
import { useStatusProcessor } from '../../errors/services/StatusProcessor';
import { Create } from '../../../infrastructure/axios/axiosCreate';
import { GridModelInterface, MutationQueryObject, useEntityMutationType } from '../../../infrastructure/Interfaces';
import { useToken } from '../../session/service.session';
import { useOperatorSupervisorTagFP } from '../../persons/services/service.person';
import { LubricantInterface } from '../../lubricants/services/service.lubricants';
import { URL } from '../../../infrastructure/appConfig/appConfig';




export const ContainersGridModel:GridModelInterface = {
    id:{label:'Id'},
    name:{label:'Nombre'},
    capacity:{label:'Capacidad'},
    coefficient:{label:'Coeficiente'},
    code:{label:'Código'},
    lubricantType:{label:'Tipo de lubricante'},
    lubricant:{label:'Lubricante'},
    tagFP:{label:'Planta'},
    type:{label:'Tipo'},
}

export const ContainerCRUDDependencies = ['PlantAux', 'AllContainersByTagFP', 'ContainersByLubricantTypeAndLubricant']

//--------------------------
//AXIOS
//--------------------------


export const CreateContainer = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateContainer`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const DeleteContainer = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/DeleteContainer`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}


export const UpdateContainer = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateContainer`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const ContainersByTagFP = (_:string,tagFP:string,token:string) => {
    axios.defaults.headers.get['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.get['tagFP'] = tagFP
    const config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/ContainersByTagFP`,
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const ContainersByLubricantTypeAndLubricant = (_:string,data:any,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/ContainersByLubricantTypeAndLubricant`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}



/* 
--------------------------
QUERYS
--------------------------
*/

export const useAllContainersByTagFP = (tagFP?:string):QueryResult<ContainersInterface[]> => {
    const token = useToken()
    const operatorSupervisorTagFP = useOperatorSupervisorTagFP()
    const queryTagFP = tagFP || operatorSupervisorTagFP
    return useQuery<ContainersInterface[]>(['AllContainersByTagFP',queryTagFP,token],ContainersByTagFP,{
        enabled:!!token && !!queryTagFP,
        refetchOnWindowFocus:false
    })
}

export const useContainersByLubricantTypeAndLubricant = (lubricant?:LubricantInterface) => {
    const token = useToken()
    const queryDAta = {
        lubricant:lubricant?.lubricant,
        tagFP:lubricant?.tagFP,
        lubricantType:lubricant?.type
    }
    
    return useQuery(['ContainersByLubricantTypeAndLubricant',queryDAta,token,],ContainersByLubricantTypeAndLubricant,{
        enabled:!!token && !!lubricant,
        refetchOnWindowFocus:false
    })
}


/* 
--------------------------
Mutations
--------------------------
*/

export const useCreateContainer = () => {
    const token = useToken()

    const [mutationCreate,mutationResult] = useDefaultMutation(Create,{
        onSuccess:()=>{
            ContainerCRUDDependencies.forEach((dependency)=>{
                queryCache.invalidateQueries(dependency)
            })
        }
    })

    const status = useStatusProcessor(mutationResult)
    
    const createContainer = (data:ContainersInterface) => {
       return mutationCreate({
           endpoint:'CreateContainer',
           token,
           data
       })
    }

    return{
        ...mutationResult,
        ...status,
        createContainer,
    }
}

export const useUpdateContainer = ():[ (data:ContainersInterface)=>Promise<any>,any ] => {
    const token = useToken()
    const [mutation,data] = useMutation(UpdateContainer,{
        onSuccess:()=>{
            ContainerCRUDDependencies.forEach((dependency)=>{
                queryCache.invalidateQueries(dependency)
            })
        }
    })

    const status = useStatusProcessor(data)

    const updateContainer = (data:ContainersInterface) => {
        return  mutation({
            data,
            token
        })
    }
        
    return [updateContainer,{...data, ...status}]
}

export const useDeleteContainer = (): useEntityMutationType => {
    const token = useToken()
    const [mutation,data] = useMutation(DeleteContainer,{
        onSuccess:()=>{
            ContainerCRUDDependencies.forEach((dependency)=>{
                queryCache.invalidateQueries(dependency)
            })
        }
    })

    
    const deleteContainer = (data:ContainersInterface) => {
        return  mutation({
                data,
                token
            })
    }
        
    return [deleteContainer,data]

}