import React, { useEffect, useState } from 'react';
import SetMinMax from '../atoms/SetMinMax'
import FlexContainer from '../../../../components/presentational/FlexContainer'
import { Typography } from '@material-ui/core';
import CustomButton from '../../../../components/buttons/CustomButton';
import { convertToObject } from 'typescript';

interface Props{
    label?:string
    defaultValue?:any
    onChange:(item:any)=>void
    name?:string
    value?:any
}

const SetParametters:React.FC<Props> = ({defaultValue, onChange, label, name}) => {

    const [stateValue,setValue] = useState(defaultValue)

    const keys = stateValue ? Object.entries(stateValue) : []

    const handleChange = (value:any) =>{
        setValue(value);
        onChange(value);
    }
        
    const deleteItem = (value:string) =>{
        let object = { ...stateValue }
        delete object[value]
        const size = Object.keys(object).length

        handleChange(  size === 0 ? undefined : object )
    }

    const reset = ()=> {
        handleChange(undefined)
    }

    const addChannel = () => {
        handleChange({
            ...stateValue,
            [`channel_${keys.length+1}`]:{min:0, max:0}
        })
    }
    
    const addTemperature = () => {
        handleChange({
            ...stateValue,
            [`celsius`]:{min:0, max:0}
        })
    }
    const addPressure = () => {
        handleChange({
            ...stateValue,
            [`psi`]:{min:0, max:0}
        })
    }
    const addVibration = () => {
        handleChange({
            ...stateValue,
            [`rmsX`]:{min:0, max:0},
            [`rmsY`]:{min:0, max:0},
            [`rmsZ`]:{min:0, max:0}
        })
    }


    const handleDelete = (item:any) => {
        switch (name) {
            case 'vibration':
                reset()
                break 
                
            case 'temperature':
                reset()
                break  

            case 'channels':
                deleteItem(item)
                break 

            case 'pressure':
                reset()
                break 

        }
    }

    const handleCreate = () => {
        switch (name) {
            case 'vibration':
                addVibration()
                break

            case 'temperature':
                addTemperature()
                break

            case 'channels':
                addChannel()
                break

            case 'pressure':
                addPressure()
                break
        }
    }

    const deletable = (index:number):boolean => name === 'cannels' 
        ?   index + 1 === keys.length ? true : false
        :   true
   

    return (
        <>
            <FlexContainer justify='space-between' align='center' width='min-width'>
                <Typography style ={{marginBottom:'-8px', paddingLeft:'4px'}} variant='button'>{label}</Typography>
                <CustomButton icon={'add-circle'} variant='iconButton' action={handleCreate}/>
            </FlexContainer>
    
            {stateValue && <FlexContainer flexDirection='column' padding='16px' border='1px solid #e1e1e155' borderRadius='8px' background='#e1e1e155'>
                {keys?.map((item:any,index:number)=> 
                    <SetMinMax
                    key={`${label} ${item[0]}`}
                    handleDelete={handleDelete}
                    name={name}
                    title={item[0]}
                    defaultValue={item[1]}
                    onChange={(value) => handleChange({
                            ...stateValue,
                            [item[0]]:value
                        })
                    }
                    deletable={deletable(index)}
                    />
                )}
            </FlexContainer>}

        </>
    );
}

export default SetParametters;
