import React from 'react';
import CustomButton from '../../../../components/buttons/CustomButton';
import SurveyModal from '../../SurveyModal';
import { useSurveyController } from './controllers/surveyController';
import CreateSurveyForm from './CreateSurveyForm';
import { useModal } from './../../../../utils/hooks/useModal';


interface Props {
    lubricationPoint: any /* LubricationPointInterface */
    outOfRoute:boolean
    mode?: 'content'
    open?: boolean
    handleClose?:()=>void
    /* handleFinish?:()=>void */
    exitButton?:React.ReactNode 
}


const IndividualLubricationPointSurvey:React.FC<Props> = ({lubricationPoint, exitButton, outOfRoute, mode, open, handleClose}) => {


    const {modal,handleModal} = useModal()
    
    const {surveyTasks,finishEquipment} = useSurveyController()

    const handleFinishComponent = () => {
        handleModal()
        finishEquipment() //terminar el equipo en el carrousell aunque sea relevado individualmente
    }

    const button = <CustomButton action={handleModal} color='secondary' popoverTitle='Relevar Equipo' icon='surveyPlay' variant='iconButton' />

    return (

        <>   
                {(mode === 'content') ? null :button}
            
                <SurveyModal
                open={open || modal}
                handleClose={handleClose || handleModal}
                currentLubricationPoint={lubricationPoint}
                tasks={surveyTasks}
                outOfRoute={outOfRoute}
                exitButton={exitButton}
                > 
                    <CreateSurveyForm
                        item={lubricationPoint} 
                        tasks={surveyTasks} 
                        outOfRoute={outOfRoute}
                        onFinish={ handleFinishComponent} 
                    />
                </SurveyModal>
            {/*  */}
       </>

    );
}

export default React.memo(IndividualLubricationPointSurvey);
