
import { useQuery } from "react-query";
import  axios,{AxiosRequestConfig} from 'axios';
import { useMemo } from 'react';
import { LubricantInterface } from "../../../../lubricants/services/service.lubricants";
import { LubricationPointInterface } from "../../../../equipments/services/service.lubricationPoints";
import { useDailyLubricationPointsWithInfo } from "../../lubricationPoints/services/daoLubricationPoints";
import { useOperatorSupervisorTagFP } from "../../../../persons/services/service.person";
import { useToken } from "../../../../session/service.session";
import { URL } from "../../../../../infrastructure/appConfig/appConfig";




export interface ColectorDailyLubricantsInterface {
    lubricant:LubricantInterface
    lubricationPoints:LubricationPointInterface[]
}

const getDailyLubricants = (_:string,data:LubricationPointInterface[],tagFP:string,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.post['tagFP'] = tagFP
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/LubricantsAndLubricationPoints`,
        data
    }
    return axios(config)
    .then((res)=>{return res.data})
    .catch((err) => {return err})
}



export const useDailyLubricants = ():ColectorDailyLubricantsInterface[] | undefined =>{
    const token = useToken()
    const dailyEquipments = useDailyLubricationPointsWithInfo()
    const tagFP = useOperatorSupervisorTagFP()
    const _dailyEquipments = dailyEquipments?.map(i => ({
        ...i,
        supplies:JSON.stringify(i.supplies)
    }))
    
    const dailyLubricants = useQuery<ColectorDailyLubricantsInterface[] >(['DailyOperatorLubricants',_dailyEquipments,tagFP,token],getDailyLubricants,{
        enabled:!!_dailyEquipments,
        refetchOnWindowFocus:false,
        staleTime:500000
    })

    return useMemo(()=>dailyLubricants.data?.map((l)=>{
        return {
            lubricant:l.lubricant,
            lubricationPoints: dailyEquipments?.filter((d)=>d.lubricant === l.lubricant.lubricant)!
        }
    }),[dailyEquipments,dailyLubricants.data])  
}

