import React from 'react';
import { ListItem, TextField, makeStyles, createStyles, Icon } from '@material-ui/core';
import FlexContainer from '../../components/presentational/FlexContainer';
import ProgressButton from '../../components/buttons/ProgressButton';
import { useForm } from 'react-hook-form';
import { useAssignLubricatorNumber } from './services/service.person';
import { queryCache } from 'react-query';
import { useToken } from '../session/service.session';
import { PersonsFormRegex } from '../../infrastructure/regex/regexForms';
import { Alert } from '@material-ui/lab';
import { PersonInterface } from './interfaces/userInterfaces';

const useStyles =  makeStyles((theme) => createStyles({
    formGrid:{
        display:'flex',
        flexDirection:'column'
    },
    input: {
        width:'300px,'
    },
}));

interface Props{
    person:PersonInterface
    tagFP:string
}

const AssignOperatorNumber:React.FC<Props> = ({person,tagFP}) => {
    const classes = useStyles()
    const token=useToken()
    const {register,watch,errors,handleSubmit:handleLubricatorNumber} = useForm()
    const [updatePerson,{status:updatePersonStatus,data}] = useAssignLubricatorNumber({
        onSuccess:()=>queryCache.invalidateQueries('plantOperators')
    })

    const muatatePerson = () =>{
        updatePerson({
            data:{
                email:person.email,
                tagFP:tagFP,
                lubricatorNumber:watch('lubricatorNumber')
            },
            token
        })
    }


    return (
        <ListItem>
            <form onSubmit={handleLubricatorNumber(muatatePerson)}>
            {(data === 'Ya existe') && (updatePersonStatus==='success')  ? <><Alert severity="warning">No puede asignar este numero porque otro operador ya lo tiene asignado</Alert><br/></> : null}
                <FlexContainer align='center' gap='16px'>
                    <TextField
                        inputRef={register({
                            required:{value:true,message:'Requerido'},
                            pattern:{value:PersonsFormRegex.integer,message:'Ingresa solo un numero'},
                        })}
                        className={classes.input}
                        size='small'
                        variant='outlined'
                        name={'lubricatorNumber'}
                        defaultValue={watch('lubricatorNumber',person.lubricatorNumber)}
                        label={errors?.lubricatorNumber?.message || 'Numero de Lubricador'}
                        color={errors?.lubricatorNumber?.message ? 'primary' : 'secondary'}
                    />
                    <ProgressButton status={updatePersonStatus} color='secondary' variant='contained' type='submit' ><Icon>save</Icon></ProgressButton>
                </FlexContainer>
            </form>
        </ListItem>
    );
}

export default AssignOperatorNumber;
