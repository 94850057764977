import React from 'react';
import AnyGrid from '../../../../components/grids/AnyGrid';
import CreateSTISensorForm from '../CreateSTISensorForm';
import { useSTISensorsByTagFP } from '../services/STIsensorsService';
import { useSTISensorForm } from '../services/useSTISensorForm';
import UpdateSTISensorForm from '../UpdateSTISensorForm';

interface Props {
    tagFP:string
}

const STISensorsPage:React.FC<Props> = ({tagFP}) => {

    const {form} = useSTISensorForm({actionType:'create'},tagFP);
    const {data:sensors,isLoading} = useSTISensorsByTagFP(tagFP)

    return (
        <AnyGrid
            title={form.pluralName}
            loading={isLoading}
            items={sensors || []}
            headerActions={()=><CreateSTISensorForm tagFP={tagFP} />}
            height='55vh'
            itemActions={(item,devices:any)=><>
                <UpdateSTISensorForm item={item} tagFP={tagFP} items={devices} />
            </>}
        />
    );
}

export default STISensorsPage;
