import { useCallback, useEffect, useState } from 'react'
import { LubricationPointInterface } from "../../../../equipments/services/service.lubricationPoints"
import { useUser } from "../../../../persons/services/service.person"
import { useLocalStorage } from "../../../../../infrastructure/localStorage/useLocalStorage"
import { useUpdateEquipmentInfo } from '../../lubricationPoints/services/infoLubricationPointsService'
import { useCreateObservations } from '../../observations/services/colector.observationsService'
import { SurveyTasksValues, TaskType } from '../../tasks/interfaces/taskInterfaces'
import { useCreateSurvey } from '../services/colector.surveyService'
import { LocalStateSupply } from './../../lubricationPointsInfo/colector.lubricationPointsInfoInterfaces';
import { InfoSurveyInterface } from '../interfaces/surveyInterfaces'
import { CriticalityInterface } from '../../../../criticalities/types/types'
import { ObservationInterface, ObservationType } from '../../../../observations/types/ObservationTypes'


export interface LubricationSurveyControlerInterface {
    taskStep:number
    customStep:number,
    loop:number
    point:(LubricationPointInterface | undefined)
    inspectionComplete:boolean
    inspection:InfoSurveyInterface
    lubrication:{
        sampleExtraction:boolean
        lubricantType:string
        impossibleToLubricate:(boolean | undefined)
        lubricates:(boolean | undefined)
        lubricant:string
        taskType:TaskType
        taskTime:number
        taskDone:boolean |undefined
        addedReason:string
        quantity:number
    }
    cleaning:{
        taskType:TaskType
        taskTime:number
        taskDone:boolean |undefined    
    }
    repairment:{
        taskType:TaskType
        taskTime:number
        taskDone:boolean |undefined    
    },
    puntuation:{
        assetCriticality:string,
    },
    fetchStatus: 'idle' | 'loading' | 'error' | 'success'
    neededSupplies:LocalStateSupply[]
    observations:(ObservationInterface[] | [])
    anomalies:(ObservationInterface[] | [])
    pending:boolean | undefined
}

const surveyTask = (task:TaskType)=> 
        (task === SurveyTasksValues.lubrication )
            ? 'lubrication'
            : (task === SurveyTasksValues.cleaning)
                ?'cleaning'
                : (task === SurveyTasksValues.repairment) 
                    ? 'repairment'
                    : task === SurveyTasksValues.puntuation
                        ? 'puntuation'
                        :'lubrication'

export const useLubricationPointSurveyController = (item:LubricationPointInterface,outOfRoute:boolean)=>{

    const {data:user} = useUser()
    const _outOfRoute = outOfRoute || false
    

    const initialState:LubricationSurveyControlerInterface = {
        taskStep:_outOfRoute ? 0 : 1,
        customStep:0,
        loop:0,
        fetchStatus:'idle',
        point:item,
        inspectionComplete:false,
        inspection:{
            updateReview:false,
            tagTGD:item?.tagTGD! || '',
            tagFP:item?.tagFP! || '',
            sector:item?.sector!,
            equipment:item?.equipment!,
            measureUnit:item?.measureUnit!,
            needsLubrication:undefined,
            inaccessible:undefined,
            manualRegistration:true,
            user:'',
            startTime:new Date().toISOString(),
            review:item?.review!,
            routeDate:new Date().toISOString(),
            outOfRoute:_outOfRoute,
            route:item?.routeName!,
            temperature:0,
            leak:false,
            labelReplacement:false,
            newComponent:false,
        },
        lubrication:{
            addedReason:'',
            sampleExtraction:false,
            lubricantType:item?.lubricantType!,
            impossibleToLubricate:undefined,
            lubricates:undefined,
            lubricant:item?.lubricant!,
            taskType:SurveyTasksValues.lubrication,
            taskTime:0,
            taskDone:undefined,
            quantity:0
        },
        cleaning:{
            taskType:SurveyTasksValues.cleaning,
            taskTime:0,
            taskDone:undefined
        },
        repairment:{
            taskType:SurveyTasksValues.repairment,
            taskTime:0,
            taskDone:undefined
        },
        puntuation:{
            assetCriticality:'',
        },
        neededSupplies:[],
        observations:[],
        anomalies:[],
        pending:item?.info?.pending
    }
  

    const [ survey, setSurvey ] = useLocalStorage<LubricationSurveyControlerInterface>(`${item?.tagTGD}-${_outOfRoute}`,initialState,{
        enabled:(!!user?.email && !!item)
    })


    const _loop = survey?.loop || 0

    const [createSurvey] = useCreateSurvey()
    const [updateEquipmentInfo] = useUpdateEquipmentInfo()
    const [createObservation] = useCreateObservations()
     
    const submit = (promise:(item?:any)=>Promise<any>) =>{
        
        setSurvey({
            ...survey,
            fetchStatus:'loading'
        })

        promise()
        .then(()=>{
            setSurvey({
                ...survey,
                fetchStatus:'success'
            })
        })
        .catch(()=>{
            setSurvey({
                ...survey,
                fetchStatus:'error'
            })
        })
    }



    const finishPointOnRoute = useCallback(()=>{
        setSurvey({
            ...survey,
            taskStep:0,
            customStep:0
        })
    },[survey,setSurvey])

    const setInaccessible = useCallback((inaccessible:boolean)=>{
        setSurvey({
            ...survey,
            inspection:{
                ...survey?.inspection,
                inaccessible:inaccessible
            }
        })
    },[survey,setSurvey])

    const handleFinishOutOfRoute = useCallback(()=>{
        survey?.inspectionComplete ?
        setSurvey({
            ...survey,
            taskStep:5,
        })
        : setSurvey({
            ...survey,
            taskStep:1,
            customStep:5
        })
    },[survey,setSurvey])


    const nextTaskStep = useCallback((task:TaskType,customStep?:number)=>{

        if(_outOfRoute){
            switch (task) {
                case SurveyTasksValues.intervention:
                        setSurvey({
                            ...survey, 
                            /* inspectionComplete: true, */
                            taskStep: (_loop > 0) ? customStep : 1,
                            customStep
                        })
                    break;

                case SurveyTasksValues.inspection:
                    setSurvey({
                        ...survey,
                        inspectionComplete: true, 
                        taskStep: survey?.inspection.inaccessible ? 0 : survey?.customStep,
                        loop:1
                    })
                    break;

                case SurveyTasksValues.lubrication:
                    setSurvey({
                        ...survey,
                        observations:[],
                        neededSupplies:[],
                        taskStep:0,
                        loop:1
                    })
                    break;

                case SurveyTasksValues.cleaning:
                    setSurvey({
                        ...survey,
                        observations:[],
                        neededSupplies:[],
                        taskStep:0,
                        loop:1
                    })
                    break;

                case SurveyTasksValues.repairment:
                    setSurvey({
                        ...survey,
                        observations:[],
                        neededSupplies:[],
                        taskStep:0,
                        loop:1
                    })
                    break;
            }
            
        }else{
            switch (task) {
                case SurveyTasksValues.inspection:
                    if(survey?.inspection.inaccessible){
                        setSurvey({
                            ...survey,
                            inspection:{
                                ...survey.inspection,
                                inaccessible:undefined
                            },
                            taskStep:1
                        })
                    }else{
                        setSurvey({
                            ...survey,
                            inspectionComplete: true,
                            taskStep:(survey?.taskStep || 1 ) + 1
                        })
                    }
                    break;

                case SurveyTasksValues.lubrication:
                    console.log(survey?.taskStep)
                    setSurvey({
                        ...survey,
                        observations:[],
                        neededSupplies:[],
                        taskStep:(survey?.taskStep || 1 ) + 1
                    })
                    break;

                case SurveyTasksValues.cleaning:
                    setSurvey({
                        ...survey,
                        observations:[],
                        neededSupplies:[],
                        taskStep:(survey?.taskStep || 1 ) + 1
                    })
                    break;

                case SurveyTasksValues.repairment:
                    setSurvey({
                        ...survey,
                        observations:[],
                        neededSupplies:[],
                        taskStep:(survey?.taskStep || 1 ) + 1
                    })
                    break;
            }

        }

    

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[survey,setSurvey])

    const backTaskStep = useCallback((task?:TaskType)=>{


        if(_outOfRoute){
            switch(task){

                case SurveyTasksValues.inspection:

                    const _inaccesible = survey?.inspection.inaccessible 
                    setSurvey({
                        ...survey,
                        inspection:initialState.inspection,
                        taskStep:_inaccesible === undefined ? 0 : 1
                    })
                    break

                case SurveyTasksValues.lubrication:
                    
                    setSurvey({
                        ...survey,
                        taskStep:_loop > 0 ? 0 : 1,
                    })
                    break
                
                case SurveyTasksValues.cleaning:
                    setSurvey({
                        ...survey,
                        taskStep:_loop > 0 ? 0 : 1,
                    })
                    break

                case SurveyTasksValues.repairment:
                    setSurvey({
                        ...survey,
                        taskStep:_loop > 0 ? 0 : 1,
                    })
                    break
                
                case SurveyTasksValues.puntuation:
                    setSurvey({
                        ...survey,
                        taskStep:_loop > 0 ? 0 : 1,
                    })
                    break
            }
        }
        else{
            switch(task){
                case SurveyTasksValues.inspection:
                    setSurvey(initialState)
                    break
                case SurveyTasksValues.lubrication:
                    setSurvey({
                        ...survey,
                        taskStep:survey ? (survey.taskStep - 1) : 0,
                        setInspectionComplete:false,
                        lubrication:{
                            ...initialState.lubrication
                        },
                        observations:[]
                    })
                    break
                
                case SurveyTasksValues.cleaning:
                    //debe editar la survey lubricacion
                    break

                case SurveyTasksValues.repairment:
                    //debe editar la survey limpieza
                    break
            }
        }
                                      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[survey,setSurvey])
                    
                    
    const setAssetCriticality = (value:CriticalityInterface) => {
        setSurvey({
            ...survey,
            puntuation:{
                assetCriticality:value.description
            }
        })

    }

    const setInspectionComplete = useCallback((value:boolean)=>{
        setSurvey({
            ...survey,
            inspectionComplete:value
        })
    },[survey,setSurvey])

    const setNewComponent = useCallback((value:boolean)=>{
        /* console.log(value) */
        setSurvey({
            ...survey,
            inspection:{
                ...survey?.inspection,
                newComponent:value
            }
        })
    },[survey,setSurvey])
    
    const setNeedsLubrication = useCallback((value:boolean)=>{
        setSurvey({
            ...survey,
            inspection:{
                ...survey?.inspection,
                needsLubrication:value
            }
        })
    },[survey,setSurvey])

    const setLabelReplacement = useCallback((value:boolean)=>{
        setSurvey({
            ...survey,
            inspection:{
                ...survey?.inspection,
                labelReplacement:value
            }
        })
    },[survey,setSurvey])

    const setLeak = useCallback((value:boolean)=>{
        setSurvey({
            ...survey,
            inspection:{
                ...survey?.inspection,
                leak:value
            }
        })
    },[survey,setSurvey])

    const setTemperature = useCallback((value:string)=>{
        setSurvey({
            ...survey,
            inspection:{
                ...survey?.inspection,
                temperature:value
            }
        })
    },[survey,setSurvey])

    const addObservation = useCallback((observation:ObservationInterface)=>{
        setSurvey({
            ...survey,
            observations:[
                ...(survey ? survey.observations : []),
                observation
            ]
        })
    },[survey,setSurvey])
    

    const addAnomaly = useCallback((anomaly:ObservationInterface)=>{
        setSurvey({
            ...survey,
            anomalies:[
                ...(survey ? survey.anomalies : []),
                anomaly
            ]
        })
    },[survey,setSurvey])

    const deleteObservation = useCallback(({type, deleteIndex}:{type:ObservationType, deleteIndex:number})=>{
        
        const anomalies = survey?.anomalies.filter((item,index)=>index !== deleteIndex)
        const observations = survey?.observations.filter((item,index)=>index !== deleteIndex)

        switch (type) {
			case 'ANOMALIA':
				setSurvey({
                    ...survey,
                    anomalies
                })
				break
            default: 
                setSurvey({
                    ...survey,
                    observations
                })
                break
		}

    },[setSurvey, survey])

  /*   const setLubricates = useCallback((value:boolean)=>{
        setSurvey({
            ...survey,
            lubrication:{
                ...survey?.lubrication,
                lubricates:value
            }
        })
    },[survey,setSurvey]) */

    const setLubricant = useCallback((value:string)=>{
        setSurvey({
            ...survey,
            lubrication:{
                ...survey?.lubrication,
                lubricant:value
            }
        })
    },[survey,setSurvey])

    const resetObservations = () => {
        setSurvey({
            ...survey,
            observtions:[],
            anomalies:[]
        })
    }

    const setPending = (value:boolean) => {
        setSurvey({
            ...survey,
            pending:value
        })
    }

    const setQuantity = (value:number)=> {
        setSurvey({
            ...survey,
            lubrication:{
                ...survey?.lubrication,
                quantity:value
            }
        })
    }

    const setTaskDone = useCallback((task:TaskType,value:boolean)=>{
        const taskSelector = surveyTask(task)
        const nextTaskStem = value === true ? {} : {taskStep:(survey ? survey?.taskStep + 1 : 0) }

        setSurvey({
            ...survey,
            ...nextTaskStem,
            [taskSelector]:{
                ...(survey ?  survey[taskSelector] : {}),
                taskDone:value
            }
        })

    },[survey,setSurvey])

    const setTaskTime = (task:TaskType,taskTime:number) => {
        const taskSelector = surveyTask(task)
        setSurvey({
            ...survey,
            [taskSelector]:{
                ...(survey ?  survey[taskSelector] : {}),
                taskTime
            }
        })

    }

    const setAddedReason = (value:string) => {
        setSurvey({
            ...survey,
            lubrication:{
                ...survey?.lubrication,
                addedReason:value
            }
        })
    }

    const setNeededSupplies = (value:LocalStateSupply ) => {
        setSurvey({
            ...survey,
            neededSupplies:[
                ...(survey ? survey?.neededSupplies : []),
                value
            ]
        })
    }

    const deleteSupply = (deleteIndex:number)=>{
        setSurvey({
            ...survey,
            neededSupplies:survey?.neededSupplies.filter((item,index)=>index !== deleteIndex)
        })
    }
 

    const setControlled = (value:boolean) => {
        setSurvey({
            ...survey,
            controlled:value,
            observations:[],
            anomalies:[]
        })
    }

    const setLubricate = (value:boolean) =>{
        switch (value) {
            case false:
                setSurvey({
                    ...survey,
                    lubrication:{
                        ...survey?.lubrication,
                        impossibleToLubricate:false,
                        taskDone:false,
                        taskTime:0,
                        quantity:0,
                        lubricates:false,
                    },
                    observations:[],
                })
                break;
        
            case true:
                setSurvey({
                    ...survey,
                    lubrication:{
                        ...survey?.lubrication,
                        impossibleToLubricate:false,
                        taskDone:true,
                        taskTime:0,
                        quantity:0,
                        lubricates:true,
                    },
                    observations:[],
                })
                break;
        }
    }

    

    const setImpossibleToLubricate = () => {
        setSurvey({
            ...survey,
            lubrication:{
                ...survey?.lubrication,
                impossibleToLubricate:true,
                taskDone:true,
                lubricates:false,
            },
            observations:[],
        })
    }

    const resetSurvey = () => {
        if(_outOfRoute){
            setSurvey(initialState)
        }
    }

    //Inaccesible Point


    return{
        survey,
        setLubricate,
        setAssetCriticality,
        backTaskStep,
        setPending,
        setInaccessible,
        setInspectionComplete,
        setNewComponent,
        setNeedsLubrication,
        setLabelReplacement,
        setLeak,
        setTemperature,
        addObservation,
        addAnomaly,
        deleteObservation,
        nextTaskStep,
        setTaskDone,
        setLubricant,
        resetObservations,
        setImpossibleToLubricate,
        setQuantity,
        setTaskTime,
        setAddedReason,
        setControlled,
        setNeededSupplies,
        deleteSupply,
        createSurvey,
        updateEquipmentInfo,
        createObservation,
        submit,
        handleFinishOutOfRoute,
        finishPointOnRoute,
        resetSurvey,
    }

}