import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SelectSimpleEntity from '../../../components/select/SelectSimpleEntity';
import { useAllLubricationPointsAndInfoByTagFP, useEquipmentsByTagFP, useLubricationPointByTagTGD } from '../services/service.lubricationPoints';


interface Props{
    tagFP:string
    onChange:(item?:any)=>void
    complete?:boolean
    equipmentName?:boolean
}

const SelectLubricationPoint:React.FC<Props> = ({tagFP,onChange,complete,equipmentName}) => {

    const {data:LubricationPoints} = useEquipmentsByTagFP(tagFP)
const separator = ` >> `
    const  [state, setstate] = useState<any>();
    const customName = `${state?.equipment}${separator}${state?.element}${separator}${state?.component}`

    useEffect(() => {
        complete 
        ? equipmentName 
            ? onChange(customName) 
            : onChange({...state,customName})
        : onChange(state)
    }, [state]);

    return (
        <SelectSimpleEntity
            complete={complete}
            onChange={(lubricationPoint:any) => setstate(lubricationPoint)}
            entityList={LubricationPoints || []}
            label='Seleccionar equipo'
            showTitle={'tagTGD'}
            selector={complete ? 'tagTGD' : undefined}
            renderOption={(option)=><>
                <Typography>{option.equipment}{separator}{option.element}{separator}{option.component} </Typography> 
            </>}
        />
    );
}

export default SelectLubricationPoint;
