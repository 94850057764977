import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const TgdSvgIcon:React.FC<any> = (props:SvgIconProps) => {
    return (
        <SvgIcon  style={{color:props.color ? props.color : 'inherit'}} {...props} >
            {props.children}
        </SvgIcon>
    );
}

export default React.memo(TgdSvgIcon)