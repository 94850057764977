import React from 'react'
import { TextField } from "@material-ui/core"
import { AutocompleteProps } from "@material-ui/lab"
import { useAutocompleteStyles } from "../styles/useAutocompleteStyle"
import theme from "../../../infrastructure/materialUi/themeConfig"
import { useLangLabels } from "../../../domains/language/services/useSetLabel"

export const useSimpleAutocompleteProps = <T,> (params:{items:T[],value:T | null,error?:string, label:string,helper?:string}) => {
    const classes = useAutocompleteStyles()
    const {items, value, error, label,helper} = params
    const {lang} = useLangLabels()

    const props:AutocompleteProps<T, false, false, false> = {
        style:{width:'100%',flexGrow:1},
        size:'small',
        options:items,
        value:value,
        loadingText:lang.inputFields.autocomplete.loadingText,
        clearText:lang.inputFields.autocomplete.clearText,
        openText:lang.inputFields.autocomplete.openText,
        closeText:lang.inputFields.autocomplete.closeText,
        classes:{
            option:classes.option
        },
        renderInput:(params) => (
            <TextField
                {...params} 
                helperText={helper}
                variant="outlined"
                focused={error ? true : false}
                label={error ? `${label} ${error}` : label}
                style={{color: error ? theme.palette.error.main : 'unset' }} 
            />
        )
    }

    return props
}