import React, { useState } from 'react';
import { useEquipmentsByRoute } from '../../equipments/services/service.lubricationPoints';
import ComponentMode from '../../../components/ComponentMode';
import TgdSelectableTable from '../../../components/grids/TgdSelectableTable';
import { RouteInterface, useAssignMultipleRoutesToMultipleEquipments } from '../services/service.routes';
import { equipmentColumns } from './AssignCancelMultipleRoutes';
import CustomButton from '../../../components/buttons/CustomButton';
import StatusAndMessage from '../../errors/StatusAndMessage';
import { useStatusProcessor } from '../../errors/services/StatusProcessor';

interface Props{
    item:RouteInterface
}

const CancelRouteAllLubricationPoints:React.FC<Props> = ({item}) => {

    const [selectedEquipments, setSelectedEquipments] = useState([]);
    const {data:EquipmentsByRoute } = useEquipmentsByRoute(item)
    const title = `cancelar ruta: ${item.routeName}, en multiples equipos`

    const { handleAssigCancel, status:cancelStatus, data, error } = useAssignMultipleRoutesToMultipleEquipments()
    const {status,message} = useStatusProcessor({...data,status:cancelStatus})

    const handleAssign = () =>{
        handleAssigCancel(selectedEquipments,[item],'C')       
    }
    
    return (
        <ComponentMode mode='modal' variant='iconButton' icon='close' popoverTitle={title}>
            <StatusAndMessage status={status} message={message} error={error} />
            <TgdSelectableTable 
            title={'Eliminar ruta de equipos'}
            items={EquipmentsByRoute}
            columns={equipmentColumns}
            handleChange={setSelectedEquipments}
            headerActions={<CustomButton status={status} icon='delete' action ={handleAssign}></CustomButton>}
            />
        </ComponentMode>
    );
}

export default React.memo(CancelRouteAllLubricationPoints);
