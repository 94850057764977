import React from 'react';
import FlexContainer from './../../components/presentational/FlexContainer';
import { NavLink } from 'react-router-dom';
import { makeStyles, createStyles, Typography, Box, Badge } from '@material-ui/core';
import theme from '../../infrastructure/materialUi/themeConfig';


const useStyles = makeStyles((theme) => createStyles({
    tab:{
        padding:theme.spacing(2),
        textDecoration:'none',
        color:theme.palette.secondary.main,
/*         boxSizing:'border-box',
 */    },
    activeTab:{
        backgroundColor:'white'
   },
  
  }));


interface Props{
    items:TabsInterface[]
    headerComponent?:React.ReactNode 
    badgeContent?: number | undefined

}

export interface TabsInterface {
    route:string
    title?:string
    icon?:React.ReactNode
    listIcon?:(item:any)=>React.ReactElement 
    iterator?:string
    items?:any[]
    primaryText?:string[]
    secondaryText?:string[]
    thirdText?:string
    headerComponent?:React.ReactNode 
    buttonActions?:(item:any)=>React.ReactElement 
    customItemList?:(item:any)=>React.ReactNode 
    badgeContent?:number
}

const ColectorTabs:React.FC<Props> = ({items,headerComponent}) => {

    const classes= useStyles()

    return (
        <FlexContainer  margin={'0 16px'} gap={`${theme.spacing(1)}`} justify='space-between'>
                <Box >
                    <FlexContainer  margin={'0 16px'} padding='0' gap={`${theme.spacing(1)}`}>
                        {items.map((item,index) =>
                            <NavLink  key={index}  exact style={{maxHeight:'40px',maxWidth:'200px',padding:'4px 16px',display:'block',alignContent:'center'}} className={classes.tab} activeClassName={classes.activeTab} to={item.route} >
                                {item.badgeContent} {item?.icon || item?.title}
                            </NavLink>
                        )}
                    </FlexContainer>        
                </Box>
            {/* <Box width='100%'> */}
                {headerComponent && headerComponent}
            {/* </Box> */}
        </FlexContainer>
    );
}

export default React.memo(ColectorTabs);
