import axios, { AxiosRequestConfig } from "axios"
import { URL } from "../../../infrastructure/appConfig/appConfig"
import { useToken } from "../../session/service.session"
import { useOperatorSupervisorTagFP } from '../../persons/services/service.person';
import {  MutationResult, queryCache, useMutation, useQuery } from "react-query";
import { GridModelInterface, MutationQueryObject } from "../../../infrastructure/Interfaces";
import { PeriodInterface } from "../../../components/filters/AnyFiltering";
import { ObservationInterface } from "../types/ObservationTypes";

//----------------------------
//interfaces
//---------------------------------------------

export const ObservationCRUDDependencies = [
    'ObservationsByPeriodAndTagFP',
    'ObservationsByTagFP',
    'ObservationsByLubricationPoint',
    'ObservationsBysurveyId',
]


export const gridModelObservations:GridModelInterface = {
    "id":{label:'Id'},
    "tagFP":{label:'Planta'},
    "tagTGD":{label:'Tag TGD'},
    "anomaly":{label:'Anomalía'},
    "observation":{label:'Observación'},
    "criticality":{label:'Criticidad'},
    "area":{label:'Área'},
    "surveyId":{label:'Id relevamiento'},
    "equipment":{label:'Equipo'},
    "modified":{label:'Modificado'},
    "modifiedDate":{label:'Fecha modificado'},
    "observationDate":{label:'Fecha observación'},
    "OTnumber":{label:'Nº OT'},
    "state":{label:'Estado'},
    "taskId":{label:'Id tarea'},
    "taskRequestId":{label:'Solicitud de tarea'},
    "taskType":{label:'Tipo de tarea'}, 
    "type":{label:'Tipo'},
}

//----------------------------
//Axios
//---------------------------------------------

export const ObservationsByTagFP = (_:string,tagFP:string,token:string) => {
    axios.defaults.headers.get['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.get['tagFP'] = tagFP

    const config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/ObservationsByTagFP`,

    }

    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}


export const ObservationsByEquipment = (_:string,data:any,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/ObservationsByLubricationPoint`,
        data:data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const ObservationsBySurveyId = (_:string,data:any,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/ObservationsBySurveyId`,
        data:data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const UpdateObservation = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateObservation`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}
export const DeleteObservation = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/DeleteObservation`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const ObservationsByPeriodAndTagFP = (_:string,data:any,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/ObservationsByPeriodAndTagFP`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}


//----------------------------
//QUERY
//---------------------------------------------

export const useObservationsByPeriodAndTagFP = (period:PeriodInterface | undefined,tagFP:string | undefined) => {

    const token = useToken()
    const supOpTagFP = useOperatorSupervisorTagFP()
    const _tagFP = tagFP || supOpTagFP

    const queryConfig = {
        ...period,
        tagFP:_tagFP
    }

    const queryAll = useObservationsByTagFP(tagFP)

    const queryPeriod = useQuery(['ObservationsByPeriodAndTagFP',queryConfig,token],ObservationsByPeriodAndTagFP,{
        enabled:!!_tagFP && !!token && !!period
    })

    if(period) return queryPeriod
    else return queryAll

}

export const useObservationsByTagFP = (tagFP?:string) => {
    const token = useToken()
    const supOpTagFP = useOperatorSupervisorTagFP()
    const queryTagFP = tagFP || supOpTagFP
    
    return useQuery<ObservationInterface[]>(['ObservationsByTagFP',queryTagFP,token],ObservationsByTagFP,{
        enabled:!!queryTagFP 
    })

}



export const useObservationByIdentifier = (entity:'lubricationPoint' | 'survey', data:any) =>{

    const token = useToken()

    const byTagTGD = useQuery(['ObservationsByLubricationPoint',{tagTGD:data.tagTGD},token],ObservationsByEquipment,{
        enabled:(entity === 'lubricationPoint') && !!data && token 
    })    

    const bySurveyId = useQuery(['ObservationsBysurveyId',{surveyId:data.id,tagFP:data.tagFP},token],ObservationsBySurveyId,{
        enabled:(entity === 'survey') && !!data && token 
    })    


    switch (entity) {
        case 'survey':
            return bySurveyId
            
        case 'lubricationPoint':
            return byTagTGD
    
    }

}

//----------------------------
//MUTATIONS
//---------------------------------------------


export const useUpdateObservation = ():[(data:any) => Promise<any>,MutationResult<any>] => {
    const token = useToken()
    const [mutation,queryData] = useMutation(UpdateObservation,{
        onSuccess:()=>{
            queryCache.invalidateQueries('ObservationsByTagFP')
            queryCache.invalidateQueries('ObservationsByPeriodAndTagFP')
        }
    })
    const update = (data:any) => {
        return mutation({
            token,
            data
        })
    } 

    return[update, queryData]
}

export const useDeleteObservation = ():[(data:any) => Promise<any>,MutationResult<any>] => {
    const token = useToken()
    const [mutation,queryData] = useMutation(DeleteObservation,{
        onSuccess:()=>{
            queryCache.invalidateQueries('ObservationsByTagFP')
            queryCache.invalidateQueries('ObservationsByPeriodAndTagFP')
        }
    })

    const deleteObs = (data:any) => {
        return mutation({
            token,
            data
        })
    } 

    return [deleteObs, queryData]
}
