import { List, ListItem, ListItemText, ListItemSecondaryAction, Divider, ListItemIcon, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import PageContainer from '../../components/pageContainers/PageContainer';




interface Props{
    items?:any[]
    primaryText?:string[]
    secondaryText?:string[]
    buttonActions?:(item:any)=>React.ReactNode
    listIcon?:(item:any)=>React.ReactNode | React.ReactNode
    listItemText?:(item:any)=>React.ReactNode 
    iterator?:string //access itermediate level for multiple level json
    customItemList?: (item:any)=>React.ReactNode
}
const ColectorList:React.FC<Props> = ({items,primaryText,secondaryText,buttonActions,listIcon,listItemText,iterator,customItemList}) => {
    return ( items ? <>
        <PageContainer height='60vh'>
        <List dense >
            {(items === []) && <Alert severity='warning'>No hay items que mostrar</Alert>}
            {!items ? <CircularProgress size='24px'/> :
            items.map((item,index)=><div  key={index + `${primaryText}`}>
                <ListItem>

                    {listIcon &&
                    <ListItemIcon style={{width:'150px'}} key={'static1'}>
                        {listIcon(item)}    
                    </ListItemIcon>}

                    {listItemText ? listItemText(item) : 
                    (primaryText?.map((p:string,index:number)=>
                    <ListItemText
                    style={{width:'180px'}}
                    key={index} 
                    primary={iterator && primaryText ? (item[iterator][primaryText[index]]) : (primaryText ? item[primaryText[index]] :item)} 
                    secondary={iterator && secondaryText ? (item[iterator][secondaryText[index]]) : (secondaryText ? (item[secondaryText[index]] ? item[secondaryText[index]] : secondaryText[index]) : (undefined))}/>
                    ))}
                    {customItemList && customItemList(item)}

                    {buttonActions && 
                    <ListItemSecondaryAction key={'static2'}>
                        {buttonActions(item)}
                    </ListItemSecondaryAction>}
                </ListItem>
                <Divider/>
            </div>)}
        </List>
    </PageContainer>
    </> : null);
}

export default React.memo(ColectorList);
