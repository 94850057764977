import React from 'react';
import DefaultFormLayout from '../../../components/forms/DefaultFormLayout';
import { useSensorTypeForm } from './services/useSensorTypeForm';
import FormSelector from '../../form-config/FormSelector';
import { useCreateSensorType } from './services/SensorTypeService';
import { SensorTypeInterface } from './interfaces/SensorTypeInterfaces';




const CreateSensorTypeForm:React.FC<{tagFP:string}> = ({tagFP}) => {

    const {setValue,handleSubmit,watch,errors,form} = useSensorTypeForm({actionType:'create'},tagFP)
    const {createSensorType,data} = useCreateSensorType()

    const submit = (fields:SensorTypeInterface) => {
        createSensorType(fields)
    }

    return (
        <DefaultFormLayout 
        status={data.status}
        message={data.message}
        error={data.error} 

        icon={'add'} 
        buttonTitle={form.name} 
        popoverTitle={form.name} 
        onChange={handleSubmit(submit)} >
            {form.fields?.map(field=>
                <FormSelector
                    key={field.name}
                    selector={field.formSelector}
                    fieldProps={{
                        type:field.type,
                        error:errors && errors[field.name]?.message,
                        label:field.label,
                        value:watch(field.name),
                        onChange:(value)=>setValue(field.name,value),
                        tagFP
                    }}
                />    
            )}
        </DefaultFormLayout>
    );
}

export default React.memo(CreateSensorTypeForm);
