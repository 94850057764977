import React from 'react';
import AnyGrid from '../../../../components/grids/AnyGrid';
import CreateSTIEquipmentForm from '../CreateSTIEquipmentForm';
import { STIEquipmentInterface } from '../interfaces/STIEquipmentInterfaces';
import { useSTIEquipmentsByTagFP } from '../services/STIEquipmentService';
import { useSTIEquipmentForm } from '../services/useSTIEquipmentForm'
import UpdateSTIEquipmentForm from '../UpdateSTIEquipmentForm';

interface Props{
    tagFP:string
}

const StiEquipmentPage:React.FC<Props> = ({tagFP}) => {
    
    const {form} = useSTIEquipmentForm({actionType:'create'},tagFP)
    const {data:devices,isLoading} = useSTIEquipmentsByTagFP(tagFP)

    return (
        <AnyGrid
            title={form.pluralName}
            loading={isLoading}
            items={devices || []}
            headerActions={()=><CreateSTIEquipmentForm tagFP={tagFP} />}
            itemActions={(device,items:any)=><>
                <UpdateSTIEquipmentForm item={device} items={items} tagFP={tagFP} />
            </>}
        />
    );
}

export default StiEquipmentPage;
