import { Chip, Typography } from '@material-ui/core';
import React from 'react';
import ComponentMode from '../../../../components/ComponentMode';
import PointerContainer from '../../../../components/pageContainers/PointerContainer';
import FlexContainer from '../../../../components/presentational/FlexContainer';
import TgdPopover from '../../../../components/popovers/TgdPopover';
import TgdTextField from '../../../form-config/formComponents/TgdTextField';
import { useSensorizedInfoByTagFP } from '../../api/SensoringService';
import { useSensorTypesByTagFP } from '../../sensorTypes/services/SensorTypeService';
import { useSTISensorsByTagFP } from '../services/STIsensorsService';
import CustomButton, { CustomIcon } from './../../../../components/buttons/CustomButton';

interface Props{
    tagFP?:string
    label?:string
    error?:string
    value?:string | number
    defaultValue?:string 
    onChange:(value:string)=>void
}

export const SensorPrev:React.FC<{sensorAddress:string, sensorSurvey?:any}> = ({sensorAddress, sensorSurvey})=>{

    const survey = sensorAddress && sensorSurvey && (sensorSurvey[sensorAddress] || sensorSurvey)
    const keys = survey && Object.entries(survey)
    return (
        <FlexContainer flexDirection='column'>
            <Typography><strong>macAddress</strong>:{sensorAddress}</Typography>
            {keys?.map(([key,value]:any)=>
                <Typography key={key}><strong>{key}</strong>:{value}</Typography>
            )}
        </FlexContainer>
    )
}


const EnterSensorAddress:React.FC<Props> = ({tagFP,label,error,value,defaultValue,onChange}) => {

    const { data:sensorizedInfo } = useSensorizedInfoByTagFP(tagFP!)
    const {data:sensors} = useSTISensorsByTagFP(tagFP!)

    
    const SensorChip:React.FC<{sensor:any}> = ({sensor}) => {
        const sensorSurvey = sensor.sensorSurvey
        const sensorAddress = Object.keys(sensorSurvey)[0]
        const cleanAddress = sensor.cleanAddress
        const _sensor = sensors?.filter(s => s.sensorAddress === cleanAddress )[0] 
        const sensorType = _sensor?.sensorType 
        const typeTitle= sensorType  || 'Desconocido'

        return(
                <FlexContainer justify='space-between'>
                    <Chip icon={<CustomIcon icon='wifi' />} label={typeTitle} />
                    <FlexContainer>
                        {!sensorType && <CustomButton popoverTitle='Usar código' variant='iconButton' icon='refresh' action={()=>onChange(cleanAddress)}></CustomButton>}
                        <TgdPopover 
                            mode='hover' 
                            title='' 
                            positionAnchorOrigin={{vertical: 'center', horizontal: 'right'}} 
                            hoverChildren={<SensorPrev {...{sensorAddress,sensorSurvey}} />}
                        >
                            <CustomIcon icon='info'/> 
                        </TgdPopover>
                    </FlexContainer>
                </FlexContainer>
        )
    }

    return (
        <FlexContainer >
            <TgdTextField
                {...{label,error,value,defaultValue,onChange}}
            />
            <ComponentMode
                icon='wifi' 
                popoverTitle='Conectados'
            >
               {sensors ? sensorizedInfo?.map((item,index)=>
                    <SensorChip key={index} sensor = {item} /> 
                ):'Escuchando..'}

            </ComponentMode>
        </FlexContainer>
    );
}

export default EnterSensorAddress;
