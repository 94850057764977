import { Crudfields } from '../../../form-config/interfaces'
import { useFormConfigs } from '../../../form-config/useFormConfigs'
import { STIEquipmentInterface } from '../interfaces/STIEquipmentInterfaces'


export const useSTIEquipmentForm = ({actionType,defaultValue}:Crudfields,tagFP:string) => {
    
    return useFormConfigs<STIEquipmentInterface>(actionType,{
        singularName:'equipo',
        pluralName:'equipos',
        fieldFilters:{},
        fields:[
            {
                label:'Id',
                name:'id',
                formSelector:'none',
                type:'number',
                initialValue:0,
            },
            {
                label:'Equipo',
                name:'equipment',
                formSelector:'text',
                type:'string',
                initialValue:'',
                validations:{
                    required:{value:true,message:'requerido'}
                }
            },
            {
                label:'Nº etiqueta',
                name:'labelId',
                type:'string',
                formSelector:'text',
                initialValue:'',
                validations:{
                    required:{
                        value:true,
                        message:'requerido'
                    }
                }
            },
            {
                label:'Codigo de planta',
                type:'string',
                name:'plantTag',
                formSelector:'text',
                initialValue:'',
                validations:{
                    required:{
                        value:true,
                        message:'requerido'
                    }
                }
            },
            {
                label:'Tag STI',
                type:'string',
                name:'tagSTI',
                formSelector:'none',
                initialValue:''
            },
            {
                label:'Tag Planta',
                name:'tagFP',
                type:'string',
                formSelector:'none',
                initialValue:tagFP,
                validations:{
                    required:{
                        value:true,
                        message:'requerido'
                    }
                }
            },
            {
                label:'Marca',
                name:'brand',
                type:'string',
                formSelector:'text',
                initialValue:'',
                validations:{
                    required:{
                        value:true,
                        message:'requerido'
                    }
                }
            },
            {
                label:'Modelo',
                name:'model',
                type:'string',
                formSelector:'text',
                initialValue:'',
                validations:{
                    required:{
                        value:true,
                        message:'requerido'
                    }
                }
            },
            {
                label:'Función',
                name:'function',
                type:'string',
                formSelector:'text',
                initialValue:'Sin funcion',
            },
            {
                label:'Ubicación',
                name:'location',
                type:'string',
                formSelector:'text',
                initialValue:'Sin ubicación',
            },
            {
                label:'Observaciones',
                name:'observations',
                type:'string',
                formSelector:'text',
                initialValue:'Sin observaciones',
            },
            {
                label:'Fecha de admision',
                name:'admissionDate',
                type:'string',
                formSelector:'none',
                initialValue:'',
            },
            {
                label:'Fecha de baja',
                name:'egressDate',
                type:'string',
                formSelector:'none',
                initialValue:'',
            },
            {
                label:'Archivado',
                name:'state',
                type:'boolean',
                formSelector:'none',
                initialValue:true,
            },
            {
                label:'Archivado',
                name:'lowReason',
                type:'string',
                formSelector:'text',
                initialValue:'Nunca dado de baja',
            },
            {
                label:'Tipo de Equipo',
                name:'type',
                type:'string',
                formSelector:'selectEquipment',
                initialValue:'',
                validations:{
                    required:{
                        value:true,
                        message:'requerido'
                    }
                }
            },
            {
                label:'Sector',
                type:'string',
                name:'sector',
                formSelector:'selectSector',
                initialValue:'',
                validations:{
                    required:{
                        value:true,
                        message:'requerido'
                    }
                }
            },
            {
                label:'Criticidad',
                name:'criticality',
                type:'string',
                formSelector:'selectCriticality',
                initialValue:'',
                validations:{
                    required:{
                        value:true,
                        message:'requerido'
                    }
                }
            },
        ]
    },defaultValue)
}