import React, { useEffect, useState } from 'react';
import { SubCategoryInterface } from '../sub-categories/service.subcategories';
import SelectSimpleEntity from '../../components/select/SelectSimpleEntity';
import { StockInterface, useStockAreaCategorySubcategory } from './services/service.stock';

interface Props{
    subcategory:SubCategoryInterface
    error:string
    handleSelect:(item:StockInterface)=>void
}

const SelectStockProduct:React.FC<Props> = ({subcategory,error,handleSelect}) => {
    
    const queryData = {
        categoryArea:subcategory?.categoryArea,
        category:subcategory?.category,
        subcategory:subcategory?.subcategory,
        tagFP:subcategory?.tagFP
    }
    const [state, setstate] = useState()
    const {data:stockItems} = useStockAreaCategorySubcategory(subcategory ? queryData : undefined)
    const seletedOption = stockItems?.filter(item => item.product === state)[0]


    useEffect(()=> {
        if(seletedOption){
            handleSelect(seletedOption)
        }
    },[state])


    return (
        <SelectSimpleEntity
            entityList={stockItems || []}
            showTitle='product'
            onChange={setstate}
            label={error || 'Seleccionar items'}
            color={error ? 'primary' : 'secondary'}
        />
    );
}

export default React.memo(SelectStockProduct);
