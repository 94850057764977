import axios from "axios"
import { useQuery } from "react-query"
import { URL } from "../../../infrastructure/appConfig/appConfig"
import { useToken } from "../../session/service.session"
import { SensorType } from "../interfaces/interfaces"

interface SensorReportsInterface {
    iotDeviceCode: string
    sensorAddress: string
    tagFP: string
    tagSTI: string
    sensorType: SensorType
    celsiusDegrees: number[]
    channels: []
    dateTime: string[]
    equipment: string
    fahrenheitDegrees:number[]
    id: number[]
    maxX: number[]
    maxY: number[]
    maxZ: number[]
    minX: number[]
    minY: number[]
    minZ: number[]
    psiPressure: number[]
    rmsX: number[]
    rmsY: number[]
    rmsZ: number[]
    rssi: number[]
    sensorBattery: number[]
    
}
//--------------------------------
//---------------------------aixos
//--------------------------------


export const LastSensorizationSurveysByTagFP = (_:string,data:any,token:string) => {
    return(
        axios({
            method:'POST',
            headers:{
                Authorization:`Bearer ${token}`,
                
            },
            url:`${URL}/LastSensorizationSurveysByTagFP`,
            data
        })
        .then((res)=>res.data)
        .catch((err)=>{
            console.log(err)
            return err
        })
    )

}
export const FilteredSensorizedSurveys = (_:string,data:any,token:string) => {
    return(
        axios({
            method:'POST',
            headers:{
                Authorization:`Bearer ${token}`,
                
            },
            url:`${URL}/FilteredSensorizedSurveys`,
            data
        })
        .then((res)=>res.data)
        .catch((err)=>{
            console.log(err)
            return err
        })
    )

}




//--------------------------------
//---------------------------Query
//--------------------------------


export const useSensorizationSurveysByTagFP = (queryData:{
    tagFP:string,
    surveysQuantity:number
}) => {
    const token = useToken()
    return useQuery<SensorReportsInterface[]>(['LastSensorizationSurveysByTagFP',queryData,token],LastSensorizationSurveysByTagFP,{
        enabled:!!queryData.tagFP || queryData.surveysQuantity <= 100
    })
}



export interface SensorizedSurveyFiltersInterface {
    period:number
    date:string
    address:string
    tagFP:string
}

export const useFilteredSensorizedSurveys = (data:SensorizedSurveyFiltersInterface) => {
    const token = useToken()
    return useQuery<SensorReportsInterface>(['FilteredSensorizedSurveys',data,token],FilteredSensorizedSurveys,{
        enabled:!!token && !!data
    })
}

