import { ActionType } from 'react-query/types/core/query'
import { FormRegExp } from '../../../../infrastructure/regex/regexForms'
import { Crudfields } from '../../../form-config/interfaces'
import { useFormConfigs } from '../../../form-config/useFormConfigs'
import { useLangLabels } from '../../../language/services/useSetLabel'
import { IotDeviceInterface } from '../interfaces/IotDeviceInterfaces'


export const useIotForm = ({actionType,defaultValue}:Crudfields,tagFP:string) => {

    const {lang} = useLangLabels()

    return useFormConfigs<IotDeviceInterface>(actionType,{
        singularName:lang.iotDevices.title.singular,
        pluralName:lang.iotDevices.title.plural,
        fieldFilters:{
            update:['lowReason'],
            desactivate:['device','deviceCode', 'admissionDate', 'egressDate', 'state', 'location', 'batteryCapacity', 'lowBatteryAlarm']
        },
        fields:[
            {
                label:'Id',
                name:'id',
                formSelector:'none',
                type:'number',
                initialValue:0,
            },
            {
                label:lang.iotDevices.form.label.device,
                name:'device',
                formSelector:'text',
                type:'string',
                initialValue:'',
                validations:{
                    required:{value:true,message:'requerido'},
                    pattern:{value:FormRegExp.name},
                    maxLength:{value:50}
                }
            },
            {
                label:lang.iotDevices.form.label.deviceCode,
                name:'deviceCode',
                type:'string',
                formSelector:'text',
                initialValue:'',
                validations:{
                    required:{value:true},
                    pattern:{value:FormRegExp.macAddress,message:lang.validations.messages.macAddress},
                    /* minLength:{value:  12 },
                    maxLength:{value: 12 }, */
                }
            },
            {
                label:lang.iotDevices.form.label.admissionDate,
                name:'admissionDate',
                type:'string',
                formSelector:'none',
                initialValue:'',
            },
            {
                label:lang.iotDevices.form.label.egressDate,
                name:'egressDate',
                type:'string',
                formSelector:'none',
                initialValue:''
            },
            {
                label:lang.iotDevices.form.label.state,
                name:'state',
                type:'boolean',
                formSelector:'none',
                initialValue:false
            },
            {
                label:lang.iotDevices.form.label.location,
                name:'location',
                type:'string',
                formSelector:'text',
                initialValue:'Sin ubicación',
                validations:{
                    required:{value:true},
                    maxLength:{value:50}
                }
            },
            {
                label:lang.iotDevices.form.label.lowReason,
                name:'lowReason',
                type:'string',
                formSelector:'text',
                initialValue:'',
                validations:{
                    required:{value:actionType === 'desactivate' ? true : false},
                    maxLength:{value:100}
                }
            },
            {
                label:lang.iotDevices.form.label.batteryCapacity,
                name:'batteryCapacity',
                type:'number',
                formSelector:'text',
                initialValue:0
            },
            {
                label:lang.iotDevices.form.label.lowBatteryAlarm,
                name:'lowBatteryAlarm',
                type:'number',
                formSelector:'text',
                initialValue:0
            },
            {
                label:lang.globalentityValues.tagFP,
                name:'tagFP',
                type:'string',
                formSelector:'none',
                initialValue:tagFP,
            }
        ]
    },defaultValue)
}