import React, { useEffect, useMemo, useState } from 'react';
import FlexContainer from '../presentational/FlexContainer';
import TgdPopover from '../popovers/TgdPopover';
import CustomButton from '../buttons/CustomButton';
import { usePopoverEvent } from '../../infrastructure/tgd.config';
import { FormControlLabel, Switch } from '@material-ui/core';
import { forEachTrailingCommentRange } from 'typescript';
import { GridModelInterface } from '../../infrastructure/Interfaces';
import { flattenObj } from '../../utils/dataFiltering';


interface Props{
    items:any[] | undefined
    handleFilteredColumns:(items:any)=>void
    gridModel?:GridModelInterface
}

const constructFlattenObj = (flattenObj:object) => {

    var constructObject:any = {}

    const keys = Object.entries(flattenObj)
    const result = keys.forEach(([key,value]:[string,any]) => {
        if(key.includes('.')){
            const trueKey = key.split('.')
            constructObject = {
                ...constructObject,
                [trueKey[0]]:{
                    ...(constructObject[trueKey[0]]),
                    [trueKey[1]]:value
                }
            }
        }
        else{
            constructObject = {
                ...constructObject,
                [key]:value
            }
        }
        
    })

    return constructObject

}


const notHiddenRows = [
    'tagTGD',
    'tagFP',
    'routeName',
    'id',
    'scheduledDate',
    'state'
]

const ColumnsFiltering:React.FC<Props> = ({ items, handleFilteredColumns, gridModel }) => {

    const keys = useMemo(()=>items && items[0] && Object.keys(flattenObj(items[0])).filter((key)=>((!notHiddenRows.includes(key)) && (gridModel ? gridModel[key] : true))),[items,gridModel])

    
    const [selectedKeys,setSelectedKeys] = useState<any>({})
    const {id,currentTarget,setPopoverTarget,handleClosePopover} = usePopoverEvent()

    //FilterColumns
   /*  const filteredObjects = items?.map((item)=>{
        const keyValues = Object.entries(item).filter(([key,value]:[string,any])=>(!selectedKeys[key]))
        return Object.fromEntries(keyValues)
    })
 */


const filteredObjects = items?.map((item)=>{
    const flattedItem = flattenObj(item)
    const filterItemKeyValues =  Object.fromEntries(Object.entries(flattedItem).filter(([key,value]:[string,any])=>(
        !selectedKeys[key]
    )))


    return constructFlattenObj(filterItemKeyValues)
})

    const [nextAction,setNextAction] = useState(true)
    const handleDesactivateAllColumns = () => {
        keys.forEach((key:string) => {
            setSelectedKeys((old:any)=>({...old,[key]: nextAction/* invert state !selectedKeys[key] */}))
            setNextAction(!nextAction)
        });
    }

    useEffect(()=> {
        handleFilteredColumns(filteredObjects) 
    },[selectedKeys])

    return (<FlexContainer>
        <TgdPopover aria-describedby={id} mode='hover' title='Ocultar columnas'>
            <CustomButton action={(e)=>setPopoverTarget(e)} icon='columns' />
        </TgdPopover>
        <TgdPopover 
        handleClose={handleClosePopover}
        title={'Gestionar Columnas'} 
        typography={{variant:'h6'}} 
        currentTargetEvent={currentTarget}
        >
            <br/>
            <CustomButton action={handleDesactivateAllColumns} variant='chip' color='secondary' icon='toggleOff' title='Desactivar todo'/>
            <FlexContainer flexDirection='column'>
                <br/>
                {keys?.map((key:string,index:number)=>
                <FormControlLabel
                    key={index}
                    control={
                        <Switch
                            onChange={()=>setSelectedKeys({...selectedKeys,[key]:!selectedKeys[key]})}
                            size='small'
                            name={key}
                            checked={!selectedKeys[key]} 
                            color='primary'
                        />}
                    label={gridModel ? gridModel[key]?.label || key : key}
                />
                )}
            </FlexContainer>
        </TgdPopover>
    </FlexContainer>);
}

export default React.memo(ColumnsFiltering)
