import React,{useMemo} from 'react';
import { IconButton, Paper, Typography } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { Route, Switch } from 'react-router-dom';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useDimention } from '../../persons/services/service.person';
import { useDailyRoutes } from '../domains/routes/services/colectorServiceRoutes';
import { useDailyLubricationPointsWithInfo, useDailyPreviousPendingPointsWithInfo, usePendingPoints } from '../domains/lubricationPoints/services/daoLubricationPoints';
import ColectorTabs, { TabsInterface } from '../ColectorTabs';
import FlexContainer from '../../../components/presentational/FlexContainer';
import { USER_ROUTES } from '../../../infrastructure/routes/routes';
import EquipmentsInfo from '../../equipmentsInfo/EquipmentsInfo';
import LubricationPointsList from '../domains/lubricationPoints/LubricationPointsList';
import ColectorList from '../ColectorList';
import LubricationPointsGrid from '../domains/lubricationPoints/LubricationPointsGrid';
import { useLubricationPointFieldRestrictions } from '../../../infrastructure/user-agent/permision/Permissions';
import IndividualLubricationPointSurvey from '../domains/surveys/IndividualLubricationPointSurvey';


const useRouteData = () => {
    const fieldRestrictionByUser = useLubricationPointFieldRestrictions()
    const dimention = useDimention()
    const {data:DailyRoutes} = useDailyRoutes()
    const DailyLubricationPoints = useDailyLubricationPointsWithInfo()
    const pendingPoints = usePendingPoints()
    const prevoiusLubricationPoints = useDailyPreviousPendingPointsWithInfo()

    return useMemo(()=>({
        fieldRestrictionByUser,
        dimention,
        DailyRoutes,
        DailyLubricationPoints,
        pendingPoints,
        prevoiusLubricationPoints,
    }),[fieldRestrictionByUser,dimention,DailyRoutes,DailyLubricationPoints,pendingPoints,prevoiusLubricationPoints,])

}

const ColectorRoutesPage:React.FC<{defaultPath?:string}> = ({defaultPath}) => {
   
    const {fieldRestrictionByUser,dimention,DailyRoutes,DailyLubricationPoints,pendingPoints,prevoiusLubricationPoints} = useRouteData()
    
    const defaultRoute = defaultPath ? `/${USER_ROUTES.home}/${dimention}/${defaultPath}` : `/${USER_ROUTES.home}/${dimention}` 

    const tabsMenuConfig:TabsInterface[]=useMemo(()=>[
        {
            route:`${defaultRoute}/${USER_ROUTES.dailyRoutes}`,
            title:'Rutas',
            items:DailyRoutes || [],
            primaryText:['routeName'],
            secondaryText:['state'],
            buttonActions:(item)=><FlexContainer align='center'>
                <LubricationPointsList type ='modal' item={item}/>
                <IconButton size={'small'} color={'secondary'}  edge="end" aria-label="Estado del equipo">
                    {item.state === 'PENDIENTE' ? <RadioButtonUncheckedIcon/> : <CheckCircleOutlineIcon style={{color:'green'}}/>}
                </IconButton>
            </FlexContainer>,
            badgeConbtent:undefined,
        },
        {
            route:`${defaultRoute}/${USER_ROUTES.delayedPoints}`,
            title:'Puntos Atrasados',
            items:prevoiusLubricationPoints || [],
            primaryText:['tagTGD','component', 'sector','lubricant'],
            secondaryText:['plantTag','element','Sector en planta','lubricantType'],
            badgeContent:prevoiusLubricationPoints?.length || 0,
            buttonActions: (item)=><>
                {item.info?.pending && <IndividualLubricationPointSurvey outOfRoute={false} lubricationPoint={item} />}
                <EquipmentsInfo type='modal' item={item}/>
            </>,
            
        },
        {
            route:`${defaultRoute}/`,
            title:'Puntos de hoy',
            items:DailyLubricationPoints,
            primaryText:['tagTGD','component', 'sector','lubricant'],
            secondaryText:['plantTag','element','Sector en planta','lubricantType'],
            buttonActions: (item)=><>
            {item.info?.pending && <IndividualLubricationPointSurvey outOfRoute={false} lubricationPoint={item} />}
            <EquipmentsInfo type='modal' item={item}/>
            <IconButton size={'small'} color={'secondary'}  edge="end" aria-label="Estado del equipo">
               {item.info?.pending && <RadioButtonUncheckedIcon/>}
               {item.info?.pending ? (item.info?.inaccessible ? <HighlightOffIcon style={{color:'red'}}/> : null ) :  <CheckCircleOutlineIcon  style={{color:'green'}}/> }
            </IconButton>
            </>,
            badgeContent:undefined
            
        },
    ],[DailyRoutes,DailyLubricationPoints,dimention,prevoiusLubricationPoints,pendingPoints])

    return (<>
        
        <ColectorTabs 
            items={tabsMenuConfig}
            headerComponent={<>
                <Route exact path={`${defaultRoute}`}>
                    <Typography color='secondary' variant='body1'><strong>{`${((DailyLubricationPoints?.length || 0) - (pendingPoints?.length || 0)) || '0'}/${DailyLubricationPoints?.length || '0'}`}</strong></Typography>
                </Route>
                <Route  path={`${defaultRoute}/${USER_ROUTES.dailyRoutes}`}>
                    <Typography color='secondary' variant='body1'><strong>{`${DailyRoutes?.length || '0'}`}</strong></Typography>
                </Route>
                <Route  path={`${defaultRoute}/${USER_ROUTES.delayedPoints}`}>
                    <Typography color='secondary' variant='body1'><strong>{`${prevoiusLubricationPoints?.length || '0'}`}</strong></Typography>
                </Route>
            </>}
        />
        <Switch>
            {tabsMenuConfig.map((i:TabsInterface,index:number)=>
            <Route key={index} path={i.route}>
                <Paper>

                {i.title=== 'Rutas' 
                
                ?   <ColectorList
                    items={i.items}
                    iterator={i.iterator}
                    primaryText={i.primaryText }
                    secondaryText={i.secondaryText}
                    buttonActions={i.buttonActions}
                    />

                :   /*  <AnyGrid
                        items={i.items || []}
                        title={i.title || ''}
                        loading={i.items ? false : true}
                        documentExport={false}
                        renderOptions={i.buttonActions}
                    /> */

                 <LubricationPointsGrid
                    enableExport={false}
                    title={i.title}
                    items={i.items}
                    colExcludes={fieldRestrictionByUser}
                /> } 
                </Paper>
            </Route>
            )}
        </Switch>
        
   </>);
}

export default React.memo(ColectorRoutesPage);
