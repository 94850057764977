import axios,{AxiosRequestConfig} from'axios'
import { useState } from 'react'
import { MutationResult, queryCache, QueryResult, useMutation } from 'react-query'
import { URL } from '../../../infrastructure/appConfig/appConfig'
import { useToken } from '../../session/service.session'
import { useAllAuxByPlant } from '../../auxiliar-data/service.aux';
import { GridModelInterface, MutationQueryObject } from '../../../infrastructure/Interfaces'
import { useStatusProcessor } from '../../errors/services/StatusProcessor';
import { useModal } from './../../../utils/hooks/useModal';
import { AreaInterface } from '../types/AreaTypes'

//------------------------
//Interfaces
//------------------------



export const gridModelArea:GridModelInterface = {
    id:{label:'Id'},
    area:{label:'Área'},
    email:{label:'Email referente'},
    referrer:{label:'Referente'},
    tagFP:{label:'Planta'},
    date:{label:'Fecha'},
}

export const AreasCRUDDependencies = [
    'PlantAux'
]

//------------------------
//AXIOS
//------------------------

export const CreateArea = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateArea`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const UpdateArea = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateArea`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const DeleteArea = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/DeleteArea`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

//------------------------
//AXIOS
//------------------------


export const usePlantAreas = (tagFP:string) => {
    const query = useAllAuxByPlant(tagFP)
    const result:QueryResult<AreaInterface[]> ={
        ...query,  
        data:query.data?.areas
    }
    return result
}


//------------------------
//MUTATIONS
//------------------------

export const useCreateArea = () => {
    const token = useToken()
    const [mutation, data] = useMutation(CreateArea,{
        onSuccess:()=> {
            queryCache.invalidateQueries('PlantAux')
        }
    })

    const createArea = (data:AreaInterface)=> {
        return mutation({
            data,
            token
        })
    }

    const status = useStatusProcessor(data)

    return {createArea,...data,...status}

}

export const useUpdateArea = () => {
    const token = useToken()

    const [mutation,data] = useMutation(DeleteArea,{
        onSuccess:()=> {
            queryCache.invalidateQueries('PlantAux')
        }
    })

    const updateArea = (data:AreaInterface) => {
        return mutation({
            data,
            token
        })
    }
    const status = useStatusProcessor(data)

    return {updateArea,...data,...status}
}

export const useDeleteArea = ():[(item:AreaInterface)=> Promise<any>,MutationResult<any>] => {
    const token = useToken()
    const [mutation,data] = useMutation(DeleteArea,{
        onSuccess:()=> {
            queryCache.invalidateQueries('PlantAux')
        }
    })

    const deleteArea = (data:AreaInterface) => {
        return mutation({
            data,
            token
        })
    }

    return [deleteArea,data]
}



export const useDeleteAreaVerification = () => {

    const [deleteItem,{status:deleteStatus}] = useDeleteArea()
    const [tries,setTries] = useState<boolean>(false)
    const [status, setStatus] = useState<string>('iddle')
    const {modal,handleModal} = useModal()

/*     const {data:elementsByEquipments,status:elementsByEquipmentsStatus} = useEquipmentsByElement(validationElement) */
    const [errors] = useState({}) /* <DeleteElementsErrors> */

    const validate = (item:AreaInterface) => {
        setTries(!tries)
        setStatus('loading')
        deleteItem(item)
        setStatus(deleteStatus)
    }

    return{
        errors,
        status,
        validate,
        modal,
        handleModal
    }
}


