import React from 'react';
import { PlantInterface } from '../../../plants/service.plant';
import { useUser } from '../../../persons/services/service.person';
import SelectSimpleEntity from '../../../../components/select/SelectSimpleEntity'
import { STIEquipmentInterface } from '../interfaces/STIEquipmentInterfaces';
import { useSTIEquipmentsByTagFP } from '../services/STIEquipmentService';
import CreateSTIEquipmentForm from '../CreateSTIEquipmentForm';
import { useLangLabels } from './../../../language/services/useSetLabel';

interface props{
    tagFP?:string
    plant?:PlantInterface | undefined
    onChange:(value:any) => void
    defaultValue?:string | undefined 
    dimentionType?:string
    error?:string
    complete?:true
}

const SelectSTIEquipment:React.FC<props> = ({ onChange, defaultValue, error, tagFP, complete}) => {

    const {lang} = useLangLabels()
    const {data:STIEquipments} = useSTIEquipmentsByTagFP(tagFP || '')
    const {data:user} = useUser()

    const onSelect = (selected:string) =>{
        if(complete){
            const result = STIEquipments && STIEquipments.filter((item:STIEquipmentInterface)=> item.equipment === selected)[0]
            onChange(result)
        }
        else {
            onChange(selected)
        }
    }
    
    return (<>
            {<SelectSimpleEntity
                error={error}
                label={lang.STIEquipments.title.singular}
                onChange={onSelect}
                entityList={STIEquipments || []}
                showTitle={'equipment'}
                defaultValue={defaultValue}
            >
                {user && (user?.type === 'O' ? null : <CreateSTIEquipmentForm tagFP={tagFP!}/>)}
            </SelectSimpleEntity>}

    </>);

}

export default React.memo(SelectSTIEquipment)
