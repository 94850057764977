import React, { useEffect, useState } from 'react';
import DefaultFormLayout from '../../../components/forms/DefaultFormLayout';
import { useIotForm } from './services/useIotForm';
import FormSelector from '../../form-config/FormSelector';
import { useUpdateIotDevice } from './services/IotDeviceService';
import { IotDeviceInterface } from './interfaces/IotDeviceInterfaces';
import ModalList from '../../../components/modals/modalList/ModalList';





const UpdateIotDeviceForm:React.FC<{tagFP:string,item:IotDeviceInterface,items:IotDeviceInterface[]}> = ({tagFP,item,items}) => {

    const [_item,setItem] = useState<IotDeviceInterface>()
    const {setValue,handleSubmit,watch,errors,form} = useIotForm({actionType:'update',defaultValue:_item},tagFP)
    const {updateIotDevice,status,message,error} = useUpdateIotDevice()
    
    const submit = (fields:IotDeviceInterface) => {
        updateIotDevice(fields)
    }

    return(
        <ModalList icon='edit'  onChange={setItem} item={item} items={items} title={form.name}>
            <DefaultFormLayout 
            status={status}
            message={message}
            error={error} 
            icon={'edit'}
            mode='content'
            buttonTitle={form.name} 
            popoverTitle={form.name} 
            onChange={handleSubmit(submit)} >
                {form.fields?.map(field=>
                    <FormSelector
                        key={field.name}
                        selector={field.formSelector}
                        fieldProps={{
                            type:field.type,
                            error:errors && errors[field.name]?.message,
                            label:field.label,
                            value:watch(field.name,field.initialValue),
                            onChange:(value)=>setValue(field.name,value),
                        }}
                    />    
                )}
            </DefaultFormLayout>
        </ModalList>
    );
}

export default UpdateIotDeviceForm
