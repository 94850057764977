import React, { useEffect, useState } from 'react';
import { Button, Icon, TextField, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import CreateObservations from '../observations/AddObservation';
import  ArrowForwardIcon  from '@material-ui/icons/ArrowForward';
import LubricantQuantityCalculator from './LubricantQuantityCalculator';
import { LubricationPointInterface } from '../../../equipments/services/service.lubricationPoints';
import PageContainer from '../../../../components/pageContainers/PageContainer';
import SelectLubricant from '../../../lubricants/molecules/SelectLubricant';
import FlexContainer from '../../../../components/presentational/FlexContainer';
import { EquipmentFormRegex, TypeFormRegex } from '../../../../infrastructure/regex/regexForms';
import SelectAddedReason from '../../../added-reason/components/SelectAddedReason';
import TgdFab from '../../../../components/buttons/TgdFab';
import { useLubricationPointSurveyController } from './controllers/useLubricationPointSurveyController';
import { TaskType } from '../tasks/interfaces/taskInterfaces';
import { errorsLog, suppliesToString } from '../../../../utils/utils';
import AddSupply from '../supplies/AddSupply';
import CustomButton from './../../../../components/buttons/CustomButton';

import { QueryStatus } from 'react-query';
import { useLubricantsByPlant } from '../../../auxiliar-data/service.aux';
import { LubricantInterface } from '../../../lubricants/services/service.lubricants';
import SelectLubricantBy from '../../../lubricants/molecules/SelectLubricantBy';
import { ObservationInterface } from '../../../observations/types/ObservationTypes';


const SurveyLubrication:React.FC<{item:LubricationPointInterface,taskType:TaskType,statusController:({status,data}:{status:QueryStatus,data:any})=>void,outOfRoute:boolean}> = ({statusController,item,taskType,outOfRoute}) => {

    //COMPONENT STATE
    const plant:any = item.tagFP
    const [step,setStep] = useState<any>()
    const { register, handleSubmit, errors, watch, setValue } = useForm()
    
    //SURVEY INFO
    const { survey,backTaskStep, nextTaskStep, setLubricate, setImpossibleToLubricate, setAddedReason,setLubricant, setQuantity, setTaskTime, createSurvey, updateEquipmentInfo, createObservation } = useLubricationPointSurveyController(item,outOfRoute)
    const observations = survey?.observations || []
    const anomalies = survey?.anomalies || []
    const taskTime = survey?.lubrication.taskTime
    const addedReason = survey?.lubrication.addedReason 
    const lubricant = survey?.lubrication.lubricant
    const quantity = survey?.lubrication.quantity 
    const needsLubrication = survey?.inspection.needsLubrication
    const impossibleToLubricate = survey?.lubrication.impossibleToLubricate
    const supplies = survey?.neededSupplies || []
    const lubricates = survey?.lubrication.lubricates
    const currentEquipmentinfoSupplies = item.info?.supplies?.filter(s=> s !== 'SIN INSUMOS') || []

    const requiredObservation = useLubricantsByPlant(item.tagFP).data?.find((l:LubricantInterface)=>l.lubricant === (lubricant || ''))?.requiresObservation
 
    //ACTIONS

    useEffect(()=>{

            register('taskTime',{ 
                required:{value:!!lubricates,message:'requerido'},
                pattern:{value:EquipmentFormRegex.integer,message:'Solo números enteros'},
                validate: !!lubricates ? (value)=>value > 0 ? true : 'requerida' : undefined
            })
            register('addedReason',{
                required:{value:!!lubricates ,message:'requerido'}
            })
            register('lubricant',{
                required:{value:!!lubricates ,message:'Lubricante requerido'}
            })
            register({name:'quantity',type:'number'},{
                required:{value:!!lubricates ,message:'requerida'},
                validate: !!lubricates ? (value)=>value > 0 ? true : 'requerida' : undefined
            })
        
            setValue('taskTime',taskTime)
            setValue('addedReason',addedReason)
            setValue('lubricant',lubricant)
            setValue('quantity',quantity)
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[taskTime, addedReason, lubricant, quantity, lubricates])

    const submit = () => {
        /* statusController({ status: QueryStatus.Loading, data:undefined}) */
        createSurvey({
            ...survey?.inspection,
            ...survey?.lubrication, 
            taskTime:taskTime,
            taskDone:needsLubrication === false ? true : lubricates === false ? false : impossibleToLubricate === true ? true : false,
            reason:addedReason, 
            endTime:new Date().toISOString(),
            lubricant:lubricant,
            quantity:quantity,
            consumables:JSON.stringify(suppliesToString(supplies))
        })
        .then(({data})=>{ 
            updateEquipmentInfo({
                ...item.info,
                ...survey?.inspection,
                supplies:currentEquipmentinfoSupplies.length === 0 ? ["SIN INSUMOS"] : currentEquipmentinfoSupplies,
                user:data.user,
                lubricantQuantity:data.quantity,
                updatedToday:true,
                lastSurveyId:data.id,
                lastLubrication:data.lubricates ? new Date().toISOString() : item?.info?.lastLubrication!,
                lastInspectionDate: new Date().toISOString(),
                pending:true,
            })
            createObservation([
                ...anomalies.map((o:ObservationInterface)=>({...o,surveyId:data.id})),
                ...observations.map((o:ObservationInterface)=>({...o,surveyId:data.id})),
            ]).then((data)=>{
                    console.log(taskType)
                 /*    if(data.error) statusController({ status: QueryStatus.Error, data })
                    statusController({status: QueryStatus.Success, data}) */
                    nextTaskStep(taskType) 
            }) 
        })
        .catch(err=> {
          /*   statusController({ status: QueryStatus.Error, data:err }) */
            console.log(err)
        })
    }


    errorsLog(errors)

    return(<>
        
        <div>
            <CustomButton action={()=>backTaskStep(taskType)} icon='before' variant='iconButton' title='Atras' />
        </div>

        {(needsLubrication === true) && <>

                <PageContainer>
                    <FlexContainer>
                      
                            <Button color={lubricates ? 'secondary' : 'inherit' } variant='contained' onClick={() => {
                                setLubricate(true)
                                setStep(undefined)
                            }}>
                                Lubrica <Icon>check</Icon>
                            </Button>

                            <Button color={((lubricates === false) && (!impossibleToLubricate)) ? 'secondary' :'inherit'} variant='contained' onClick={() => {
                                setLubricate(false)
                                setStep(undefined)
                            }}>
                                No lubrica <Icon>close</Icon>
                            </Button>

                            <Button color={impossibleToLubricate ? 'secondary' : 'inherit' } variant='contained' onClick={()=>{
                                setImpossibleToLubricate()
                                setStep(undefined)
                                }}>
                                Imposible de lubricar<Icon>close</Icon>
                            </Button>

                    </FlexContainer>
                </PageContainer>

                {(lubricates === true) && <>

                    {/* maneja la tarea específica de lubricación */}
                    <PageContainer>
                        {/* <SelectLubricant
                            lubricantType={item.lubricantType}
                            plant={plant}
                            onChange={(value)=>setLubricant(value)}
                            error={errors?.lubricant?.message}
                            color={errors?.lubricant?.message ? 'primary' : 'secondary'}
                            defaultValue={lubricant}
                        /> */}
                        <SelectLubricantBy
                        error={errors?.lubricant?.message}
                        tagFP={item.tagFP}
                        onChange={(value:string)=>setLubricant(value)}
                        defaultValue={lubricant || ''}
                        by={{
                            key:'type',
                            value:item.lubricantType
                        }}
                        />
                           
                    
                        <br/>
                        <FlexContainer gap={'12px'}>
                            <TextField
                                name='quantity'
                                variant='outlined'
                                defaultValue={quantity}
                                onChange={(e)=>setQuantity(e.target.value === '' ? 0 : parseFloat(e.target.value))}
                                size='small'
                                style={{width:'100%'}}
                                color={errors?.quantity?.message ? 'primary' : 'secondary'}
                                label={'Cantidad '}
                                inputRef={register({
                                    required:{value:true ,message:'Cantidad requerida'},
                                    pattern:{value:TypeFormRegex.float,message:'Solo números'}
                                })}
                            />
                            
                            <LubricantQuantityCalculator 
                            unit={survey?.inspection.measureUnit!} 
                            quantity={watch('quantity') || 0} 
                            onChange={setQuantity} 
                            lubricant={survey?.lubrication.lubricant!} 
                            />

                        </FlexContainer>
                        <br/>

                        <TextField
                            name='taskTime'
                            variant='outlined'
                            defaultValue={taskTime}
                            size='small'
                            onChange ={(event)=> {
                                setTaskTime(taskType,JSON.parse(event.target.value !== ''? event.target.value : '0'))
                            }}
                            style={{width:'100%'}}
                            color={errors?.taskTime?.message ? 'primary' : 'secondary'}
                            label={'Tiempo empleado'}
                            inputRef={register()}
                        />

                        <br/>
                        <br/>
                        <SelectAddedReason
                            tagFP={plant.tagFP}
                            onChange={(value:any)=>{
                                setAddedReason(value)
                            }}
                            error={errors?.addedReason?.message}
                            defaultValue={addedReason}
                        />
                    </PageContainer>
                
                    <AddSupply
                        outOfRoute={outOfRoute}
                        listTitle='Insumos' 
                        formTitle={'Nuevo insumo'}
                        buttonTitle={'Insumos'}
                        variant={'list'}
                        buttonType={'add'} 
                        lubricationPoint={item}
                    />

                        {!requiredObservation ? 
                        <FlexContainer  width='100%' align='center' justify='flex-end'>
                            {(!step?.observation && (observations?.length === 0)) && 
                            <Button size='small' variant='contained' color='secondary'  onClick={()=>setStep({observation:'Sin Observaciones'})}>
                                <Icon >close</Icon>  Sin observaciones
                            </Button>}
                        <CreateObservations
                            outOfRoute={outOfRoute}
                            taskType = {taskType}
                            lubricationPoint={item}
                            type='OBSERVACION'
                            buttonTitle='observaciones'
                            formTitle='Nueva observación'
                            variant={(step?.observation || (observations?.length !== 0))  ? 'list' : 'modal'} 
                            listTitle={(step?.observation) || 'Observaciones'}
                            buttonType='add'
                            />
                        </FlexContainer>

                        :<CreateObservations
                            outOfRoute={outOfRoute}
                            taskType={taskType}
                            lubricationPoint={item}
                            type='OBSERVACION'
                            buttonTitle='observaciones'
                            formTitle='Observación requerida'
                            variant={/* (step?.observation || (observations?.length !== 0)) */'content' } 
/*                             listTitle={(step?.observation) || 'Observaciones'}*/
                            buttonType='add'
                        />
                    }
                    
                   {/*  <TgdFab
                        disabled={!(step?.observation || (observations?.length !== 0))}
                        bottom={4}
                        right={4}
                        color='primary' 
                        onClick={handleSubmit(submit)}
                        children={<ArrowForwardIcon/>}
                    /> */}
                    
                </>}
                {(lubricates === false) && (!impossibleToLubricate) && <>
                    
                    <AddSupply
                        outOfRoute={outOfRoute}
                        listTitle='Insumos' 
                        formTitle={'Nuevo insumo'}
                        buttonTitle={'Insumos'}
                        variant={'list'}
                        buttonType={'add'} 
                        lubricationPoint={item}
                        freesolo
                        disableUsedSupplies={true}
                    />
                    
                    <CreateObservations 
                        outOfRoute={outOfRoute}
                        taskType = {taskType}
                        type = 'NO LUBRICA'
                        lubricationPoint={item} 
                        variant='content' 
                        formTitle='¿Porqué no lubrica?'
                        buttonType='add'
                    />
                </>}

                <TgdFab 
                    disabled={!(step?.observation || (observations?.length !== 0))}
                    bottom={4}
                    right={4}
                    color='primary' 
                    onClick={handleSubmit(submit)}
                    children={<ArrowForwardIcon/>}
                />

                {(impossibleToLubricate === true) && <>
                    
                    <CreateObservations 
                        outOfRoute={outOfRoute}
                        taskType = {taskType}
                        type = 'IMPOSIBLE DE LUBRICAR'
                        lubricationPoint={item} 
                        variant='content' 
                        formTitle='¿Porqué fue imposible de lubricar?'
                        buttonType='add'
                    />
                    
                    <PageContainer>
                        <AddSupply
                            outOfRoute={outOfRoute}
                            listTitle='Insumos faltantes' 
                            formTitle={'Nuevo Insumo'}
                            buttonTitle={'Insumos'}
                            variant={(supplies?.length > 0) ? 'list' : 'modal'}
                            buttonType={'add'} 
                            lubricationPoint={item}
                        />
                    </PageContainer>
                    
                    <TgdFab 
                        disabled={!(step?.observation || (observations?.length !== 0))}
                        bottom={4}
                        right={4}
                        color='primary' 
                        onClick={handleSubmit(submit)}
                        children={<ArrowForwardIcon/>}
                    />

                </>}
               
                
        </>}
        {(needsLubrication === false) && <>
                <PageContainer>
                    <Typography>El punto no necesita ser Lubricado <br/></Typography>
                </PageContainer>
                <FlexContainer  width='100%' align='center' justify='flex-end'>
                    <br/>
                    {(!step?.observation && (observations?.length === 0)) && 
                    <Button size='small' variant='contained' color='secondary'  onClick={()=>setStep({observation:'Sin Observaciones'})}>
                        <Icon >close</Icon>  Sin observaciones
                    </Button>}

                    <CreateObservations
                        outOfRoute={outOfRoute}
                        taskType = {taskType}
                        lubricationPoint={item}
                        type='OBSERVACION'
                        buttonTitle='observaciones'
                        formTitle='Nueva observación'
                        variant={(step?.observation || (observations?.length !== 0)) ? 'list' : 'modal'} 
                        listTitle={(step?.observation) || 'Observaciones'}
                        buttonType='add'
                    />

                    {(step?.observation || (observations?.length !== 0)) &&
                        <TgdFab 
                            bottom={3}
                            right={4.5}
                            color='primary' 
                            onClick={submit}
                            children={<ArrowForwardIcon/>}
                        />
                    }
                </FlexContainer>
        </>}
    </>)
}

export default React.memo(SurveyLubrication)