import React, { useEffect } from "react"
import CustomButton from "../../components/buttons/CustomButton"
import { useClickPosition } from "../../utils/hooks/useClickPosition"
import { useSnackbar } from "../../utils/hooks/useSnackbar"
import { useStatusProcessor } from "../errors/services/StatusProcessor"
import { useDeleteObservation } from "./service/service.observations"
import { ObservationInterface } from "./types/ObservationTypes"


const DeleteObservation:React.FC<{observation:ObservationInterface}> = ({observation}) => {

    const [deleteObservation,{data, status:deleteStatus}] = useDeleteObservation()
    const {position,getClickPosition} = useClickPosition()
    const {setData} = useSnackbar()
    const {status,message} = useStatusProcessor({...data,status:deleteStatus})

    useEffect(()=>{
        if(deleteStatus === 'error'){
            setData({
                position:position,
                modal:true,
                errors:'ha ocurrido un error',
                severity:'error'    
            })
        }
        if(deleteStatus === 'success'){
            
            setData({
                position:position,
                modal:true,
                errors:message,
                severity:'success'   
            })
        }
    },[status,deleteStatus,data,message])

    const handleDelete = () => {
        deleteObservation({
            tagTGD:observation.tagTGD,
            tagFP:observation.tagFP,
            id:observation.id
        })
    }

    return(
        <CustomButton status={status} action={(e)=>getClickPosition(e,handleDelete)} icon='delete' popoverTitle='Eliminar Observación' variant='iconButton'/>
    )
}

export default DeleteObservation
