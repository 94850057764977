import { InputComponentTypes } from "../../../infrastructure/form/typescript/FormTypes";
import { useForm } from "../../../infrastructure/form/useForm";
import { useLangLabels } from "../../language/services/useSetLabel";
import { PermissionInterface } from "../types/permissionsTypes";

export const usePermissionForm = (tagFP:string,item?:PermissionInterface) =>{
    const {lang} = useLangLabels()
    return useForm<PermissionInterface>({fields:{
        id:{
            label:lang.globalentityValues.id,
            placeholder:lang.globalentityValues.id,
            value:item?.id || 0,
            component:InputComponentTypes.none
        },
        permissionName:{
            label:lang.permissions.permissionName,
            placeholder:lang.permissions.permissionName,
            value:item?.permissionName || '',
            component: InputComponentTypes.text,
            validations:{
                required:{value: true, message: lang.validations.messages.required},
                min:{value: 1, message: lang.validations.messages.minLength},
                max:{value: 50, message: lang.validations.messages.maxLength}
            }
        },
        code:{
            label:lang.permissions.code,
            placeholder:lang.permissions.code,
            value:item?.code || '',
            component: item ? InputComponentTypes.none :InputComponentTypes.text,
            validations:{
                required:{value: true, message: lang.validations.messages.required},
                min:{value: 1, message: lang.validations.messages.minLength},
                max:{value: 50, message: lang.validations.messages.maxLength}
            }
        },
        sector:{
            label:lang.permissions.sector,
            placeholder:lang.permissions.sector,
            helper:lang.permissions.sectorHelper,
            value:item?.sector || 'SIN SECTOR',//si no selecciona el sector se asigna a todos los equipos de ese sector
            component: InputComponentTypes.sector,
            inputConfig:{
                tagFP,
            }
        },
        area:{
            label:lang.permissions.area,
            placeholder:lang.permissions.area,
            value:item?.area || '',
            component:InputComponentTypes.area,
            helper:lang.permissions.areaHelper,
            validations:{
                required:{value: true, message: lang.validations.messages.required}
            },
            inputConfig:{
                tagFP
            }
        },
        description:{
            label:lang.permissions.description,
            placeholder:lang.permissions.description,
            value:item?.description || '',
            component: InputComponentTypes.description,
            rows:4,
        },
        tagFP:{
            label:lang.globalentityValues.tagFP,
            placeholder:lang.globalentityValues.tagFP,
            value:tagFP,
            component:InputComponentTypes.none
        },
    }})
}