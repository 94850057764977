import React, { useEffect } from 'react'
import { Dialog, DialogContent, DialogTitle, Divider, Typography } from "@material-ui/core"
import CustomButton, { CustomIconType } from "../../buttons/CustomButton"
import FlexContainer from "../../presentational/FlexContainer"
import { useCarrousell } from "../hooks/useCarrousell";
import { useModal } from './../../../utils/hooks/useModal';

const CarrousellModal:React.FC<{item:any, items:any[], children:(value:any,key:number)=>React.ReactNode, icon:CustomIconType}> = ({item, items,children,icon}) => {
    const {modal, handleModal} = useModal()
    const {current, handleNext, handlePrev, handleReset, possibleIncremet, possibleDecrement, position, term} = useCarrousell(item, items)
    

    useEffect(()=>{
       ( modal === false) && handleReset() 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[modal,item])

    return(<>
        <CustomButton variant='iconButton' icon={icon} action ={handleModal} />
        <Dialog /* maxWidth='sm' */ open={modal} onClose={handleModal} fullWidth >
            <DialogTitle >
                <FlexContainer align='center'>
                    {<CustomButton disabled={!possibleDecrement} action={handlePrev} variant='iconButton' icon='before'></CustomButton>}
                    <FlexContainer width='100%' justify='space-between' align='center'>
                        <Typography variant='h6'>
                            {`${position + 1} / ${items?.length}`}
                        </Typography>
                    </FlexContainer>
                    {  <CustomButton disabled={!possibleIncremet} action={handleNext} variant='iconButton' icon='next'></CustomButton>}
                </FlexContainer>
            </DialogTitle>
            <Divider/>

            <DialogContent>
                {term && items.map((i,key)=>
                    i[term] === current[term] &&
                    children && children(current,key)
                )}
            </DialogContent>
            
        </Dialog>
    </>)
}

export default React.memo(CarrousellModal)