import { FormDataConfigInterface } from './interfaces';
import { useLangLabels } from './../language/services/useSetLabel';

type MessageType = 'required' | 'minLength' | 'maxLength'

export const useSetValidationMessage = <T,>(_formData:FormDataConfigInterface<T>) => {
    
    const { lang } = useLangLabels()

    const messages = {
        required:lang.validations.messages.required,
        minLength:lang.validations.messages.minLength,
        maxLength:lang.validations.messages.maxLength,
    }
    
    const setMessage =  (type:MessageType ) =>{
        return messages[type]
    }

    const formData:FormDataConfigInterface<T> = {
        ..._formData,
        fields:_formData.fields.map((field)=>({
            ...field,
            validations:{
                ...field.validations,
                required:field?.validations?.required && {
                    value:field.validations.required.value, 
                    message:setMessage('required')
                },
                minLength:field?.validations?.minLength && {
                    value:field?.validations?.minLength?.value ,
                    message:setMessage('minLength')
                },
                maxLength: field?.validations?.maxLength && {
                    value:field?.validations?.maxLength?.value,
                    message:setMessage('maxLength')
                },
            }
        }))
    }
 
    return {setMessage, formData}

}