import React from 'react';
import {PlantInterface} from '../../plants/service.plant'
import AnyGrid from '../../../components/grids/AnyGrid';
import { useLangLabels } from '../../language/services/useSetLabel';
import { GridModelCollector, useCollectorsByTagFP } from '../services/collectors.service';
import ViewQrCode from '../ViewQrCode';
import CreateUpdateCollectorForm from './../CreateUpdateCollectorForm';
import { CrudFormType } from '../../../infrastructure/form/typescript/FormTypes';


interface Props{
    plant:PlantInterface
}



const ManageCollectorsPage:React.FC<Props> = ({plant}) => {
    
    const {lang} = useLangLabels()
    const {data:collectors,isLoading} = useCollectorsByTagFP(plant.tagFP)
    const _tagFP = plant.tagFP
    
    return(
            <AnyGrid
            gridModel={GridModelCollector}
            title={lang.collectors.plural}
            items={collectors || []}
            loading={isLoading}
            itemActions={(item:any,items:any)=> <>
                <ViewQrCode {...{item,items}}/>
                <CreateUpdateCollectorForm tagFP={_tagFP} type={CrudFormType.update} {...{item,items}}/>
                </>
            }
            headerActions={(item:any)=> <> 
                <CreateUpdateCollectorForm tagFP={_tagFP} type={CrudFormType.create} {...{item}}/>
            </>}
            />
    )
    
}

export default ManageCollectorsPage
