import React, { Fragment, useState } from 'react';

import { Badge, CircularProgress, Divider, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import CustomButton from '../../components/buttons/CustomButton';
import ComponentMode from '../../components/ComponentMode';
import FlexContainer from '../../components/presentational/FlexContainer';
import { suppliesToObject } from '../../utils/utils';
import { LocalStateSupply } from '../colector/domains/lubricationPointsInfo/colector.lubricationPointsInfoInterfaces';
import { LubricationPointInterface } from '../equipments/services/service.lubricationPoints';
import StatusAndMessage from '../errors/StatusAndMessage';
import { useUser } from '../persons/services/service.person';
import { useAssignCanceInfoEquipmentSupplies } from './service/service.info';
import { QueryStatus } from 'react-query';
import CUSupplyForm from '../supply/CUSupplyForm';
import { CrudFormType } from '../../infrastructure/form/typescript/FormTypes';


interface Props{
    lubricationPoint:LubricationPointInterface
}

const AssignCancelSupply:React.FC<{lubricationPoint:LubricationPointInterface,supply:string, disableStatus:boolean}> = ({lubricationPoint,supply,disableStatus}) => {

    const {assignSupply,deleteSupply,dataAssign,dataCancel,cancelStatus,assignStatus} = useAssignCanceInfoEquipmentSupplies(lubricationPoint)

    const error = (assignStatus.status === QueryStatus.Error || cancelStatus.status === QueryStatus.Error) && (cancelStatus.message || assignStatus.message)
    const data:any = cancelStatus.dataStatus || assignStatus.dataStatus
    const reset = (assignStatus.status === QueryStatus.Error && assignStatus.reset) || (cancelStatus.status === QueryStatus.Error && assignStatus.reset)  || (()=>console.log('Supply created'))
    const _supply = suppliesToObject(data)[0]
    const type = _supply?.type 

    const handleAssign = () => {
        assignSupply(supply)
    }

    const handleDelete = () => {
        deleteSupply(supply)
    }

    return(
        <FlexContainer>
            {error 
                ? <>
                    <CustomButton icon='error' color='primary' variant='iconButton' popoverTitle={error || ''} />
                    <CUSupplyForm onSuccess={reset} variant='iconButton' supplyType={type} tagFP={lubricationPoint.tagFP} actionType={CrudFormType.create} />
                </>
                : <>
                    {dataAssign.isLoading ? <CircularProgress /> : <CustomButton action={handleAssign} {...assignStatus} color='secondary' variant='iconButton' icon={'check'} popoverTitle='Asignar' />}
                    {dataCancel.isLoading ? <CircularProgress /> : <CustomButton action={handleDelete} {...cancelStatus} color='primary' variant='iconButton' icon={'close'} popoverTitle='Eliminar' />}
                  </>
            }
        </FlexContainer>
    )
}

const PendingSuppliesToAssign:React.FC<Props> = ({lubricationPoint}) => {

    const {data:user} = useUser()
    const info = lubricationPoint?.info
    const supplies = info?.supplies || []
    const suppliesQuantity = supplies?.filter((s)=>(s !== 'SIN INSUMOS') && (s !== '')).length
    const parsedSupplies:any = suppliesToObject(supplies)
    const [generalAction, setGeneralAction] = useState(false);

    const {assignStatus,assignAll} = useAssignCanceInfoEquipmentSupplies(lubricationPoint)

    const handleAll = () => {
        setGeneralAction(true)
        supplies && assignAll(supplies)
    }

    const handleReset = () => {
        assignStatus.reset()
        setGeneralAction(false)
    }

    return (
        <Badge color='primary'  badgeContent={suppliesQuantity}>
            <ComponentMode width='350px' mode='popover' icon='tool' variant='iconButton' title='Insumos solicitados' >
                {suppliesQuantity === 0
                ?'No hay insumos para asignar'
                :<Fragment>
                    <List>
                        <StatusAndMessage {...{...assignStatus,reset:handleReset}} />
                        {parsedSupplies.map((item:LocalStateSupply,index:number)=><Fragment key={index}>
                            <ListItem >
                                <ListItemText primary={item.supply} secondary={item.type}>
                                </ListItemText>
                                {user?.type !== 'O' && <ListItemSecondaryAction>
                                    <AssignCancelSupply lubricationPoint={lubricationPoint} supply={supplies[index]} disableStatus={generalAction} />
                                </ListItemSecondaryAction>}
                            </ListItem>
                            <Divider/>
                        </Fragment>)}
                    </List>
                    <CustomButton disabled={true} {...assignStatus} action={handleAll} variant='chip' color='secondary' title='Asigar todos'/>
                </Fragment>}
            </ComponentMode>
         </Badge>
    );
}

export default PendingSuppliesToAssign;
