import React, { memo } from 'react';
import { Route,Switch } from 'react-router-dom';
//components
import AddUser from '../AddPages/AddUser';
import PageContainer from '../../components/pageContainers/PageContainer';
// MATERIAL
import HomePage from '../HomePage';
import { USER_ROUTES } from '../../infrastructure/routes/routes';
import NotificationsPage from '../NotificationsPage';
import ConfigPage from '../ConfigPage';
import AddDemoUserPage from '../AddPages/User/AddDemoUserPage';
import PlantManagementSelector from '../DiscoverPage';
import ReportsPage from '../ReportsPage';
import StockPageManagment from '../StockPageManagment';
import PlantManagementPage from '../PlantManagementPage';
import NewsPage from '../NewsPage';
import SensorsManagementPage from '../../domains/sensoring/pages/SensorsManagementPage';
import RealtimeSensoringPage from '../../domains/sensoring/pages/RealtimeSensoringPage'
import VersionContainer from '../../components/pageContainers/VersionContainer';
import FactoryList from '../../domains/factories/FactoryList';

const AdminContent:React.FC<{dimention:string}> = ({dimention}) => {

  
    return (
        <PageContainer height='100%'>

            <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.demoUsers}`}>
                <AddDemoUserPage />
            </Route>

            <Switch>

                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.addUser}`}>
                    <AddUser />
                </Route>

                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.notifications}`}>
                    <NotificationsPage />
                </Route>

{/* 
                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.sensoringManagement}`}>
                    <PlantManagementSelector path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.sensoringManagement}`}>
                        {(plant) => <SensorsManagementPage tagFP={plant.tagFP} />}
                    </PlantManagementSelector>
                </Route> */}

               {/*  <Route exact path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.realtimeSensors}`}>
                    <PlantManagementSelector path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.realtimeSensors}`}>
                        {(plant) => <>
                            <RealtimeSensoringPage tagFP={plant.tagFP}/>
                            <VersionContainer company = 'STI'/>
                        </>}
                    </PlantManagementSelector>
                </Route> */}

               {/*  <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.reports}`}>
                    <PlantManagementSelector path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.reports}`}>
                        {(plant)=><ReportsPage plant={plant}/>}
                    </PlantManagementSelector>
                </Route>  */}
                
              {/*   <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.dailyNews}`}>
                    <PlantManagementSelector path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.dailyNews}`} >
                        {(plant)=><NewsPage tagFP={plant.tagFP}/>}
                    </PlantManagementSelector>
                </Route>  */}
{/* 
                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.stock}`}>
                    <PlantManagementSelector path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.stock}`}>
                        {(plant)=><StockPageManagment tagFP={plant.tagFP}/>}
                    </PlantManagementSelector>
                </Route> 
 */}
                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.discover}`}>
                        <PlantManagementSelector path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.discover}`}> 
                            {(plant)=><PlantManagementPage plant={plant}/>}
                        </PlantManagementSelector>
                </Route>

                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.factories}`}>
                    <FactoryList/>
                </Route>


                <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.config}`}>
                    <ConfigPage/>
                </Route>

                <Route exact path={`/${USER_ROUTES.home}/${dimention}`}>
                    <HomePage dimention ={dimention}/>
                    <NotificationsPage />
                </Route>

            </Switch>
        </PageContainer>
    );
}

export default memo(AdminContent);
