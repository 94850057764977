import React, { useEffect, useState } from 'react';

import { LubricationPointInterface } from '../../../equipments/services/service.lubricationPoints';
import { SurveyTasksValues, TaskType } from '../tasks/interfaces/taskInterfaces';

import SurveyInspection from './SurveyInspection';
import SurveyLubrication from './SurveyLubrication';
import SurveyRepairment from './SurveyRepairment';
import SurveyClean from './SurveyClean';

import { useLubricationPointSurveyController } from './controllers/useLubricationPointSurveyController';
import SurveyPuntuation from './SurveyPuntuation';
import { QueryStatus } from 'react-query';
import FlexContainer from '../../../../components/presentational/FlexContainer';
import { CircularProgress } from '@material-ui/core';


interface Props{
    item:LubricationPointInterface
    outOfRoute:boolean
    onFinish:()=> void
    tasks:any
}


const CreateSurveyForm:React.FC<Props> = ({tasks, item, outOfRoute, onFinish}) => {

    const { survey } = useLubricationPointSurveyController(item,outOfRoute)
    const taskType:TaskType = (survey && tasks ) && tasks[survey.taskStep].type 
    const [ status, setStatus ] = useState({status:QueryStatus.Idle,data:undefined})

    return( status.status === 'loading' 
    ? <FlexContainer align='center' justify='center' height='100%'>
            <CircularProgress size='40px'/>
      </FlexContainer> 
    :<FlexContainer flexDirection='column' width='100%'>

        {(taskType === SurveyTasksValues.inspection) && 
            <SurveyInspection
            taskType={taskType} 
            item={item} 
            outOfRoute={outOfRoute}
            onFinish={onFinish} 
            />
        }

        {(taskType === SurveyTasksValues.lubrication) && 
            <SurveyLubrication 
            taskType={taskType}
            item={item}
            outOfRoute={outOfRoute}
            statusController={setStatus}
            />
        }

        {(taskType === SurveyTasksValues.cleaning) && 
            <SurveyClean
            taskType={taskType}
            item={item}
            outOfRoute={outOfRoute}
            statusController={setStatus}
            />
        }

        {(taskType === SurveyTasksValues.repairment) && 
            <SurveyRepairment
            taskType={taskType}
            item={item}
            outOfRoute={outOfRoute}
            statusController={setStatus}
            />
        }

        {(taskType === SurveyTasksValues.puntuation) && 
            <SurveyPuntuation
            taskType={taskType}
            item={item}
            onFinish={onFinish}
            statusController={setStatus}
            outOfRoute={outOfRoute}
            />
        }
    
    </FlexContainer>)
}


export default React.memo(CreateSurveyForm);
