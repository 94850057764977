import React , { useEffect } from "react"
import { Snackbar } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { useModal } from './../../utils/hooks/useModal';

interface PbiSnackbarProps{
    status:boolean,
    severity?:'warning' | 'error' | 'success' | 'info'
    message?:string
}

const SimpleSnackbar:React.FC<PbiSnackbarProps> = ({status, severity, message}) => {

    const {modal,setModal} = useModal(false)
    const _severity = severity || 'warning'

    useEffect(()=>{
        !status &&
        setModal(true)
    },[status])

    return(
        !status ?
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                open={modal}
                onClose={()=>setModal(false)}
                >
                    <Alert onClose={()=>setModal(false)} severity={_severity}>
                        {message}
                    </Alert>
                </Snackbar>
        : null
    )
}
export default SimpleSnackbar