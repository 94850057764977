import { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { ConfigFieldsInterface, Crudfields, FormDataConfigInterface, FormTypes } from './interfaces'
import { useSetValidationMessage } from './useSetValidationMessage';
import { errorsLog } from '../../utils/utils';
import { useLangLabels } from '../language/services/useSetLabel';


const crudFields = <T,>({actionType,defaultValue}:Crudfields,form:FormDataConfigInterface<T> ):ConfigFieldsInterface<T>[] | undefined  =>{
    
    const formFields = form.fields
    const filters = form.fieldFilters ? (form.fieldFilters[actionType]  ? (form.fieldFilters[actionType] as string[]) : []) : []
    
    if(formFields){
        if(defaultValue) {
            return formFields.map(f=>({
                ...f,
                formSelector: filters.includes(f.name as string) ? 'none' : f.formSelector,
                initialValue:defaultValue[f.name]
            }))
        }
        return formFields.map(f=>({
            ...f,
            formSelector: filters.includes(f.name as string) ? 'none' : f.formSelector,
        }))
    }
    return undefined
}

export const useFormConfigs = <T,>(actionType:FormTypes, _formData:FormDataConfigInterface<T>,defaultValue?:FormDataConfigInterface<T>) =>{
  
    const { formData } = useSetValidationMessage<T>(_formData) 
    const {lang} = useLangLabels()

    const {errors,register,setValue,handleSubmit,watch,reset} = useForm()
    
    errorsLog(errors)

    const name = useMemo(()=>({
        create:`${lang.crudActions.create} ${formData.singularName}`,
        update:`${lang.crudActions.update} ${formData.singularName}`,
        delete:`${lang.crudActions.delete} ${formData.singularName}`,
        archive:`${lang.crudActions.archive} ${formData.singularName}`,
        desactivate:`${lang.crudActions.desactivate} ${formData.singularName}`
    }),[formData.singularName])

    const formName = useCallback((actionType:FormTypes) => {
        return name[actionType]
    },[name])

    const _form = useMemo(()=>({
        ...formData,
        name:formName(actionType),
        singularName:formData.singularName,
        pluralName:formData.pluralName,
        fields: crudFields<T>({actionType,defaultValue},formData),
    }),[actionType, defaultValue, formData, formName])


    useEffect(()=>{
        if(_form.fields){
            _form.fields.forEach(({name,initialValue,validations}:any)=>{
                register({name,value:initialValue},validations)
            })
        }
    },[_form, /* defaultValue, */ register])

    useEffect(()=>{ 
        const keys = defaultValue && Object.entries(defaultValue)
        keys?.forEach(([key,value])=>{
            setValue(key, value)
        })
    },[defaultValue, setValue])

    

    return {
        form:_form,
        errors,
        setValue,
        handleSubmit,
        watch,
        reset
    }
}
