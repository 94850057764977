import React from 'react';
//components
//material
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

//services
import { usePbiTilesInDashboard, usePbiHomeDashboard } from '../../domains/powerBi/service.pbi'

//powerbi
import { Tile  } from 'powerbi-report-component'
import NotificationsPage from '../../pages/NotificationsPage';
import { CircularProgress } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({

    content: {
      display: 'flex',
      gap:'24px',
      flexWrap: 'wrap',
      justifyContent:'center',
      alignItems:'flex-start',
      /* height:'500px', */
      margin: theme.spacing(3),
      width:'auto',
      '& > *': {
        margin: theme.spacing(0),
        flex:'1',
        minWidth: theme.spacing(30),
        /* maxHeight: theme.spacing(30),
        minHeight: theme.spacing(18), */
        padding:theme.spacing(2),
        },
    },
    root: {
        display: 'flex',
        flexDirection:"column",
        /* height:'fit-content', */
        '& > *': {
        flex:'1',
          margin: theme.spacing(1),
          /* height: 'fit-content', */
        },
    }
  }));

const HomeContent = () => {
    const classes = useStyles();
    const dashboardId = usePbiHomeDashboard()
    const {data:tiles, isFetching , isSuccess:tilesSuccess,error:tileError} = usePbiTilesInDashboard()
    const aadToken = sessionStorage.getItem('aadToken')
   
    return (
    <>
        <div className={classes.content}>
            {tilesSuccess && aadToken
            ?tiles.map((item:any)=>
                <Paper elevation={3} key={item.id} style={{height:'max-content',padding:'16px'}} >
                    <Tile
                        tokenType='Aad'
                        accessToken={aadToken}
                        embedUrl={item.embedUrl}
                        embedId={item.id}
                        dashboardId={dashboardId}
                        style={{height:'300px', position:'relative',border:'none',}} // style tile for report component
                        /* onClick={(data) => {
                            console.log('Data from tile', data);
                        }}
                        onLoad={(data) => {
                            console.log('Tile loaded', data);
                        }} */
                    />
                </Paper>
                )
            :<div>{isFetching ? <CircularProgress/>:<Alert severity="error">'No posee graficos destacados, para consultar gráficos destacados, deberá verificar con un administrador que su servicio de trazabilidad de lubricacion esté activo'</Alert>}</div>
            }
        </div>
        <NotificationsPage />
    </>
    )
}

export default HomeContent;
