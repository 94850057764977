import React, { useEffect, useState } from 'react';
import { useUser } from '../../../persons/services/service.person';
import { SensorTypeInterface } from '../interfaces/SensorTypeInterfaces';
import { useSensorTypesByTagFP } from '../services/SensorTypeService';
import SelectSimpleEntity from '../../../../components/select/SelectSimpleEntity'
import CreateSensorTypeForm from '../CreateSensorTypeForm';
import { useLangLabels } from '../../../language/services/useSetLabel';


interface props{
    tagFP?:string
    onChange:any
    defaultValue?:string | undefined 
    error?:string
}

const SelectSTISensorTypeComplete:React.FC<props> = ({onChange, defaultValue, error, tagFP}) => {

    const {lang} = useLangLabels()
    const {data:user} = useUser()
    const [selectedSensorType, setLubricant] = useState(defaultValue);
    const {data:sensorTypes} = useSensorTypesByTagFP(tagFP!)

    useEffect(()=>{
        onChange(sensorTypes && sensorTypes.filter((type:SensorTypeInterface)=> type.sensorType === selectedSensorType)[0])
    },[selectedSensorType])
    
    return (<>
            <SelectSimpleEntity
                error={error}
                label={ lang.iotSensorTypes.title.singular}
                onChange={setLubricant}
                entityList={sensorTypes || []}
                showTitle={'sensorType'}
                defaultValue={defaultValue}
            >
                {user && (user?.type === 'O' ? null : <CreateSensorTypeForm tagFP={tagFP!}/>)}
            </SelectSimpleEntity>

    </>);

}

export default React.memo(SelectSTISensorTypeComplete);
