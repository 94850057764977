import React from 'react';
import { gridModelStock, useStockByTagFP } from './services/service.stock';
import AnyGrid from '../../components/grids/AnyGrid';
import CreatePurchaseRequestForm from '../purchase-requests/CreatePurchaseRequestForm';
import NewStockQuantityForm from './NewStockQuantityForm';

import UpdateStockForm from './UpdateStockForm';
import { Divider } from '@material-ui/core';





interface Props{
    tagFP:string
}
const StockGrid:React.FC<Props> = ({tagFP}) => {
    
    const {data:stockItems} = useStockByTagFP(tagFP)
    
    
    return ( <>
        <Divider/>
        <AnyGrid
            rowsPerPage={12}
            gridModel={gridModelStock}
            height='50vh'
            items={stockItems || []}
            loading={stockItems ? false : true}
            title={`Todos los items de stock`}
           /*  headerActions={(item:StockInterface)=><>
            <UpdateSubCategoryForm defaultValue={subcategory} mode='modal'/>
            <CreateStockForm mode='modal' subcategory={subcategory}/>
            </>} */
            itemActions={(item:any)=><>
                <UpdateStockForm defaultValue={item}/> 
                <NewStockQuantityForm item={item}/>
                <CreatePurchaseRequestForm mode='popover' variant='iconButton' stock={item}/>
            </>}
        />
    </>);
}

export default React.memo(StockGrid);
