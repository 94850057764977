import React from 'react';
import { useEffect,useState } from 'react';
import ResourceList from './ResourceList';
//services
import {getAllResources,getResourceBytagFP, getAllResourcesByEmail } from '../../infrastructure/axios/service.tgd.axios'
//material
import {createStyles, makeStyles} from '@material-ui/core/styles'
import { TextField, Toolbar, Paper, Typography } from '@material-ui/core';
import { useUser } from '../../domains/persons/services/service.person';
import { useToken } from '../../domains/session/service.session';

const useStyles = makeStyles((theme) => createStyles({
    ResourceListContainer:{
        padding:'24px 0 0',
        position:'relative',
        minHeight:'calc(100vh - 25vh)',
        maxHeight:'calc(100vh - 25vh)',
    }
}))


interface Props{
    roleFilter?:string
    endpoint:string
    onLoad:React.ReactNode
    handleNext:()=>void
    handleState:(item:any)=>void
    searchBarTitle:string
    reload?:string
}

const ResourceListContainer:React.FC<Props> = ({roleFilter,endpoint,onLoad,handleNext,handleState,searchBarTitle,reload}) => {
    const classes = useStyles()
    const [resources,setResources] = useState<any>()
    const [filteredResources, setFilteredResources] = useState<any>();
    const token = useToken()
    const [search,setSearch] = useState('')
    const {data:user} = useUser()

    //query
    useEffect(()=>{
        if((endpoint)){
            if((endpoint !== 'FactoryOperators')){
                if(roleFilter){
                    getAllResources(endpoint,token)
                    .then(res => {
                        /* console.log(res.data) */
                        setResources(res?.filter((item:any) => item.role === roleFilter))             
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    
                }else{
                    
                    getAllResources(endpoint,token)
                    .then(res => {
                        setResources(res)
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                }
            }

            if((endpoint === 'FactoryOperators')){
                getAllResourcesByEmail('AccountManagerFactories',user?.email!,token)
                .then(res=> {
                    getResourceBytagFP('OperatorsPlant',res.data[0].tagF,token)
                    .then(res=> {
                        setResources(res.data)
                    })
                })
                .catch(err => console.log(err))
                
            } 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[endpoint,reload])


    useEffect(()=>{//default data
        if(resources != null){
            setFilteredResources(resources)
        }

    },[resources])

    useEffect(() => {//Search logic
        if(resources != null){
            if(search.length > 10){
                let searched = resources.filter((item:any) => item.name.toLowerCase().includes(search.trim()))
                if(searched.length /* > 10 */){
                    let tenElements = searched.slice(0,10)
                    setFilteredResources(tenElements)
                }
                setFilteredResources(searched)
             }
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);



    const entity = (data:any) => {
        handleState(data)
    }
    const handleSearch = (e:any) =>{
            setSearch(e.target.value.toLowerCase())
    }

    if(filteredResources){
        return (
            <div className={classes.ResourceListContainer}>
                <Paper elevation={1}>
                    <Toolbar style={{display:'flex', justifyContent:'space-between'}} >
                        <Typography variant='button' >
                            {searchBarTitle}
                        </Typography>
                        <TextField 
                            style={{width:'350px'}}
                            value={search}
                            type='search' 
                            onChange={(e) => handleSearch(e)} 
                            placeholder='buscar...' 
                            />
                    </Toolbar>
                </Paper>
                <br/>
                <div style={{ overflowX:'hidden',height:'592px'}}>

                    {filteredResources?.map((item:any,index:number) =>(
                        
                        <ResourceList
                        key={index}                       
                        state={item}
                        actionEdit={handleNext} 
                        handleState={entity}
                        />
                        ))}
                    
                </div>
            </div>
        )
    }else{
        return(
            <div className={classes.ResourceListContainer} >
                <br/>
                <Paper elevation={1}>
                    <Toolbar style={{display:'flex', justifyContent:'space-between'}}>
                        <Typography variant='button' >
                        {onLoad}
                        </Typography>
                    </Toolbar>
                </Paper>
            </div>
        )
    }
    
}

export default ResourceListContainer;
