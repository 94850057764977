import { Badge, Paper } from '@material-ui/core';
import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { FlagOutlined } from '@material-ui/icons';

import PageContainer from '../../../components/pageContainers/PageContainer';
import { USER_ROUTES } from '../../../infrastructure/routes/routes';
import { useDimention } from '../../persons/services/service.person';
import { useCheckListController, colectorHomeSupplies } from './../domains/checkList/controllers/useCheckListController';

import FlexContainer from '../../../components/presentational/FlexContainer';
import PointerContainer from '../../../components/pageContainers/PointerContainer';
import {useDailyPreviousPendingPointsWithInfo, usePendingPoints} from '../domains/lubricationPoints/services/daoLubricationPoints'
import CheckList from '../domains/checkList/CheckList';
import { useDailyRoutes } from '../domains/routes/services/colectorServiceRoutes';
import StartRoute from '../domains/surveys/SurveyCarrousell';
import ColectorRoutesPage from './ColectorRoutesPage';
import { useSurveyController } from '../domains/surveys/controllers/surveyController';
import TgdPopover from '../../../components/popovers/TgdPopover'; 

const HomePageR:React.FC<{lubricatorNumber?:number}> = ({lubricatorNumber}) => {

    const checkListTypes = colectorHomeSupplies()
    const { notification, routes, icons } = useCheckListController()
    const { data:dailyRoutes } = useDailyRoutes()
    const dailyPendingPoints = usePendingPoints()
    const lasDelayedPendingPoints = useDailyPreviousPendingPointsWithInfo()
    const lubricationPointsBadge = (dailyPendingPoints && lasDelayedPendingPoints) && (dailyPendingPoints.length + lasDelayedPendingPoints.length)
    const { push }= useHistory()
    const dimention =  useDimention()
    useSurveyController()



    return(<>
      
     
            <FlexContainer gap='24px' flexWrap='wrap'>
                {checkListTypes.map((type,index)=>
                    <TgdPopover key={type} mode='hover' title={type}>
                        <PointerContainer key={type}>
                            <Badge  style={{display:'block'}} color="primary" badgeContent={notification(type) || undefined}>
                                <Paper elevation={3}>
                                    <FlexContainer onClick={()=>push(routes[type])} height='80px' width='120px' align={'center'} justify='center'  padding='16px'>
                                        {icons[type]} {/* {formModel[type].name.plural.default} */}
                                    </FlexContainer>
                                </Paper>
                            </Badge>
                        </PointerContainer>
                    </TgdPopover>
                )}

                <TgdPopover mode='hover' title={'RUTAS'}>
                    <PointerContainer >
                        <Badge  style={{display:'block'}} color="primary" badgeContent={dailyRoutes?.length || undefined}>
                             <Paper elevation={3}>
                                <FlexContainer onClick={()=>push(USER_ROUTES.dailyRoutes)} height='80px' width='120px' align={'center'} justify='center'  padding='16px'>
                                    <FlagOutlined/>
                                </FlexContainer>
                            </Paper>
                        </Badge>
                    </PointerContainer>
                </TgdPopover>

                <TgdPopover mode='hover' title={'RELEVAR TODO'}>
                    <PointerContainer>
                        <Badge  style={{display:'block'}} color="primary" badgeContent={lubricationPointsBadge}>
                            <Paper elevation={3}>
                                {/* <PlayCircleOutline/> */}
                                <StartRoute />
                            </Paper>
                        </Badge>
                    </PointerContainer>
                </TgdPopover>
        
            </FlexContainer>

        <br/>

        {checkListTypes?.map((type)=>
            <Route key={type} path={`/${USER_ROUTES.home}/${dimention}/${routes[type]}`} >
                <PageContainer padding={'24px'} key={type}>
                    <CheckList checkListType={type} />
                </PageContainer>
            </Route>
        )}
        
       
        <Route  path={`/${USER_ROUTES.home}/${dimention}/`} >
            <ColectorRoutesPage/>
        </Route>
    

    </>);
}

export default React.memo(HomePageR);
