import React, { useMemo, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Typography} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { TaskType } from '../tasks/interfaces/taskInterfaces';
import { LubricationPointInterface } from '../../../equipments/services/service.lubricationPoints';
import { usePlants } from '../../../persons/services/service.person';
import { useLubricationPointSurveyController } from '../surveys/controllers/useLubricationPointSurveyController';
import PageContainer from '../../../../components/pageContainers/PageContainer';
import SelectAnomaly from '../../../anomalies/components.tsx/SelectAnomaly';
import FlexContainer from '../../../../components/presentational/FlexContainer';
import SelectArea from '../../../areas/molecules/SelectArea';
import SelectCriticality from '../../../criticalities/components/SelectCriticality';
import AddButton from '../../../../components/buttons/AddButton';
import TgdFab from '../../../../components/buttons/TgdFab';
import TgdModal from '../../../../components/modals/TgdModal';
import ObservationsList from './ObservationsList'
import CustomButton from '../../../../components/buttons/CustomButton';
import { capitalize } from '../../../../utils/utils';
import { useObservationTypesByTagFP } from './../../../types/services/service.types';
import SelectTabulatedObservation from '../../../types/molecules/SelectTabulatedObservation';
import { useModal } from './../../../../utils/hooks/useModal';
import { CriticalityTypes } from '../../../criticalities/types/types';
import { ObservationInterface, ObservationType } from '../../../observations/types/ObservationTypes';

interface Props {
    outOfRoute:boolean
    taskType:TaskType
    type:ObservationType 
    listTitle?:string
    buttonTitle?:string
    formTitle?:string
    variant?: 'modal' | 'content' | 'form' | 'list'
    buttonType:'next' | 'add'
    lubricationPoint:LubricationPointInterface
    onCreate?:()=>void
}

const CreateObservations:React.FC<Props> = ({type,variant,listTitle,buttonTitle,formTitle,buttonType,lubricationPoint,taskType,onCreate,outOfRoute}) => {
    
    const {data:plants} = usePlants()
    const {register, handleSubmit, errors, setValue, control,reset, watch} = useForm()
    const {modal,handleModal} = useModal()
    const tagFP = lubricationPoint.tagFP

    const { survey, addObservation, deleteObservation:handleDelete ,addAnomaly } = useLubricationPointSurveyController(lubricationPoint,outOfRoute)
    const observations = survey?.observations || []
    const anomalies = survey?.anomalies || []

    const {data:TabObservations} = useObservationTypesByTagFP(lubricationPoint.tagFP)

    const onSubmit = (data:ObservationInterface) => {   
        switch(type){
            case 'ANOMALIA':
                /*console.log(data)*/
                addAnomaly(data)
                break
            
            default:    
            addObservation({
                    ...data,
                    anomaly:JSON.stringify(anomalies?.map((a)=>a.anomaly) || [])
                })
                break
        }
        onCreate && onCreate()
    }
    
    useEffect(()=>{
        setValue('observation',undefined)
        setValue('criticality',undefined)
        setValue('area',undefined)
    },[observations,anomalies])


    /*console.log(watch('anomaly'))*/

    useEffect(()=>{
        register({name:'type',value:type})
        register({name:'anomaly',value:type},{ required:'requerido' })
        register({name:'taskType',value:taskType})
        register({name:'equipment',value:lubricationPoint.equipment})
        register({name:'tagTGD',value:lubricationPoint.tagTGD})
        register({name:'surveyId',value:''})
        register({name:'tagFP',value:lubricationPoint.tagFP})
        register({name:'date',value:new Date()})
        register({name:'state',value:''})
        register({name:'criticality',value:''},{required:{value:true,message:'requerida'}})
        register({name:'observation',value:''},{required:{value:true,message:'requerida'}})
        register({name:'area',value:''},{required:{value:true,message:'requerida'}})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
 


    const form =  
        <PageContainer > 
            <Typography variant='h6'>{formTitle}</Typography>       
            <br/>
            <br/>    
            {type === 'ANOMALIA' &&<>
                <SelectAnomaly
                    tagFP={plants[0].tagFP}
                    onChange={(value)=>setValue('anomaly',value)}
                    error={errors?.anomaly?.message } 
                    color={errors?.anomaly?.message ? 'primary' : 'secondary'}
                />
                <br/>
            </>}
            
            <FlexContainer flexDirection='column' width='100%'>

                <SelectTabulatedObservation
                    tagFP={tagFP}
                    error={errors?.observation?.message}        
                    onChange={(value?:string)=>setValue('observation', value)}    
/*                     defaultValue={watch('observation')}
 */                />

                <SelectArea
                    tagFP={tagFP}
                    onChange={(value)=>setValue('area', value)}
                    error={errors?.area?.message}
                    defaultValue={watch('area')}
                />
            
                <SelectCriticality
                    by={{key:'criticalityType', value:CriticalityTypes.observation}}
                    tagFP={tagFP}
                    onChange={(value)=>setValue('criticality', value)}
                    defaultValue={watch('criticality')}
                    error={errors?.criticality?.message} 
                />

                <CustomButton  popoverTitle={`Crear ${capitalize(type)}`} icon='save' action={handleSubmit(onSubmit)}/>

            </FlexContainer>
           
        </PageContainer>

    const addButton = <AddButton action={handleModal} title={buttonTitle} />
    
    const list = useMemo(()=>(<ObservationsList
                    handleDeleteItem={handleDelete}
                    type={type}
                    observations={(type === 'ANOMALIA') ? anomalies : observations} 
                    headerAction={variant === 'list' ? addButton : <></>} 
                    title={listTitle} 
                />),[addButton, anomalies, handleDelete, listTitle, observations, type, variant])
 


    return(<>
        {(variant === 'form') && form}
        {(variant === 'content') && <>{form}{list}</>}
        {(variant === 'list') && list}
        {(variant === 'modal') && <>
            {buttonType === 'add' && addButton}
            {buttonType === 'next' &&
                <TgdFab
                bottom={4}
                right={4}
                color='primary' 
                onClick={handleModal}
                children={<ArrowForwardIcon/>}
                />
            }
        </>}

        <TgdModal
            open={modal}
            handleClose={handleModal}
        >
            <>{form}{list}</>
        </TgdModal>
    </>)

}
export default React.memo(CreateObservations)