import {  queryCache, QueryResult, useMutation, useQuery } from "react-query"
import axios, { AxiosRequestConfig } from 'axios';
import { LubricationPointInterface } from "../../../../equipments/services/service.lubricationPoints";
import { MutationQueryObject } from "../../../../../infrastructure/Interfaces";
import { SurveyInterface } from "./service.survey";
import { useDailyLubricationPointsWithInfo } from "../../lubricationPoints/services/daoLubricationPoints";
import { useOperatorSupervisorTagFP, useUser } from "../../../../persons/services/service.person";
import { CreateSurveyInterface } from "../interfaces/surveyInterfaces";
import { URL } from "../../../../../infrastructure/appConfig/appConfig";
import { useToken } from "../../../../session/service.session";
import { useLocalStorage } from "../../../../../infrastructure/localStorage/useLocalStorage";
import { InfoLubricationPointInterface } from '../../../../equipmentsInfo/service/service.info';
import { ObservationInterface } from "../../../../observations/types/ObservationTypes";




/*------------------
--READ
-------------------*/


export const LubricationPointsAndLastsSurveys = (_:string,data:LubricationPointInterface,tagFP:string,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.post['tagFP'] = tagFP
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/LubricationPointsAndLastsSurveys`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}



/*------------------
--create
-------------------*/

export const CreateSurvey = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateSurvey`,
        data
    }
    return axios(config).then((res)=>{return res}).catch((err) => {return err})
}



/*------------------
--custom hooks
-------------------*/

export interface SurveyCollectStatusInterface{
    onRoute:boolean
    date:string
    equipmentTag:string
    equipmentFlag:number
    inspectionDone:boolean
    step:number
    tasks:string[] | []
    taskColors:string [] | []
    equipmentsDone:string[] | []
    equipments:string[] | []
    observations:ObservationInterface[]
    anomalies:ObservationInterface[]
    survey:SurveyInterface | {}
    equipmentInfo:InfoLubricationPointInterface | {}
}

export const useOutOfRouteModal = () => {
    return useLocalStorage('outOfRouteSurvey',false)
}

/*------------------
-- Queries
-------------------*/


export const useLubricationPointsLastSurveys = ():QueryResult<SurveyInterface> => {

    const token = useToken()
    const DailyOperatorLubricationPoints = useDailyLubricationPointsWithInfo()
    const tagFP = useOperatorSupervisorTagFP()

    return useQuery(['LubricationPointsAndLastsSurveys', DailyOperatorLubricationPoints, tagFP ,token ],LubricationPointsAndLastsSurveys,{
        enabled:!!DailyOperatorLubricationPoints && !!tagFP,
        refetchOnWindowFocus:false
    })

}



/*------------------
-- Mutations Queries
-------------------*/




export const useCreateSurvey = ():[(value:any | CreateSurveyInterface)=>Promise<any>,QueryResult<any>] => {

    const token = useToken()
    const {data:user} = useUser()
    const [mutation,{data}] = useMutation(CreateSurvey,{
        onSuccess:({data})=>{
            queryCache.invalidateQueries('LubricationPointsAndLastsSurveys')
            queryCache.invalidateQueries('DailyOperatorLubricationPoints')
            queryCache.invalidateQueries('dayPendingLubricationPoints')
            queryCache.invalidateQueries('SurveysByTagFP')
        }
    })
    
    const  createSurvey =  async (data: CreateSurveyInterface) => {
        return mutation({
            data:{
                ...data,
                user:user?.email,
            },
            token
        })
    }

    return [
        createSurvey,
        {...data}
    ]
} 