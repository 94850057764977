import React from 'react';
import DeleteItemForm from '../../crud-forms/DeleteItemForm';
import AnyGrid from '../../../components/grids/AnyGrid';
import { PlantInterface } from '../../plants/service.plant';
import { ContainersGridModel, useAllContainersByTagFP, useDeleteContainer } from '../services/service.containers';
import { ContainersInterface } from '../types/ContainersInterfaces';
import { useLangLabels } from '../../language/services/useSetLabel';
import CUContainerForm from './../CUContainerForm';
import { CrudFormType } from '../../../infrastructure/form/typescript/FormTypes';
import { CarrousellActionInterface } from './../../../components/grids/AnyGrid';

interface Props {
    plant:PlantInterface
} 

const carrouselActions:CarrousellActionInterface[] = [
    {icon:'edit',component:(item:ContainersInterface)=><CUContainerForm tagFP={item.tagFP} actionType={CrudFormType.update} item={item} />}
]

const ManageContainersPage:React.FC<Props> = ({plant}) => {
    
    const {lang} = useLangLabels()
    const tagFP = plant?.tagFP
    const {data:containers} = useAllContainersByTagFP(tagFP)
    const deleteContainer = useDeleteContainer()
    
    return (
        <AnyGrid
            gridModel={ContainersGridModel}
            items={containers || []}
            loading={containers ? false: true}
            title={'Recipientes'}
            carrousellActions={carrouselActions}
            headerActions={()=><>
                <CUContainerForm tagFP={plant.tagFP} actionType={CrudFormType.create} />
            </>}
            itemActions={(item:ContainersInterface,items:any)=><>
                {/* <UpdateContainerForm items={items} item={item} /> */}
                <DeleteItemForm typeLabel={lang.containers.title.singular} item={item} deleteHookControler={deleteContainer} />
            </>}
        />
    );
}

export default React.memo(ManageContainersPage);
