
import { useForm } from '../../../infrastructure/form/useForm';
import { useLangLabels } from './../../language/services/useSetLabel';
import { InputComponentTypes } from '../../../infrastructure/form/typescript/FormTypes';
import { ComponentInterface } from '../types/ComponentTypes';

export const useComponentForm = (tagFP:string, item?:ComponentInterface) => {

    const { lang } = useLangLabels()
    return useForm<ComponentInterface>({fields:{
        id:{
            label:lang.globalentityValues.id,
            placeholder:lang.globalentityValues.id,
            value:item?.id || '',
            component:InputComponentTypes.none,
        },
        component:{
            label:lang.components.form.label.component,
            placeholder:lang.components.form.label.component,
            value:item?.component ||'',
            component:InputComponentTypes.text,
            validations:{
                required:{value:true, message:lang.validations.messages.required},
                min:{value:2,message:lang.validations.messages.minLength},
                max:{value:50,message:lang.validations.messages.maxLength},
            }
        },
        code:{
            label:lang.components.form.label.code,
            placeholder:lang.components.form.label.code,
            value:item?.code ||'',
            component: InputComponentTypes.none,
            validations:{
                required:{value:item ? true :false, message:lang.validations.messages.required},
            }
        },
        tagFP:{
            label:lang.globalentityValues.tagFP,
            placeholder:lang.globalentityValues.tagFP,
            value:tagFP,
            component:InputComponentTypes.none,
        },
    }})
}