import React, { useState,useMemo } from 'react'
import { makeStyles, createStyles, Popper, Paper, Typography } from '@material-ui/core';
import AnyFiltering, { itemActionsInterface } from '../filters/AnyFiltering';
import { flattenObj, flattenObjAllLevels } from '../../utils/dataFiltering';
import { GridCellParams, isOverflown, DataGrid, GridColumns, GridRowId, GridRowSelectedParams, GridColDef } from '@material-ui/data-grid';
import PageContainer from '../pageContainers/PageContainer';
import theme from '../../infrastructure/materialUi/themeConfig';
import { ExpandMoreOutlined } from '@material-ui/icons';
import { useLangLabels } from '../../domains/language/services/useSetLabel';
import { CustomIconType } from '../buttons/CustomButton';


const useStyles = makeStyles(() =>
    createStyles({
        root: {
            alignItems: 'center',
            lineHeight: '24px',
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            '& .cellValue': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            },
        },
    }),
);

interface GridCellExpandProps {
    value: string;
    width: number;
}

const GridCellExpand = React.memo(function GridCellExpand(
    props: GridCellExpandProps,
) {
    const { width, value } = props;
    const wrapper = React.useRef<HTMLDivElement | null>(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const classes = useStyles();
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);

    const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current!);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
    };

    const handleMouseLeave = () => {
    setShowFullCell(false);
    };

    React.useEffect(() => {
    if (!showFullCell) {
        return undefined;
    }

    function handleKeyDown(nativeEvent: KeyboardEvent) {
        // IE11, Edge (prior to using Bink?) use 'Esc'
        if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
        }
    }
    document.addEventListener('keydown', handleKeyDown);
    return () => {
        document.removeEventListener('keydown', handleKeyDown);
    };
    }, [setShowFullCell, showFullCell]);

    return (
    <div
        ref={wrapper}
        className={classes.root}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
    >
        <div
        ref={cellDiv}
        style={{
            height: 1,
            width,
            display: 'block',
            position: 'absolute',
            top: 0,
        }}
        />
        <div ref={cellValue} className="cellValue">
        {value}
        </div>
        {showPopper && (
        <Popper
            open={showFullCell && anchorEl !== null}
            anchorEl={anchorEl}
            style={{ width, marginLeft: -17 }}
        >
            <Paper
            elevation={1}
            style={{ minHeight: wrapper.current!.offsetHeight - 3 }}
            >
            <Typography variant="body2" style={{ padding: 8 }}>
                {value}
            </Typography>
            </Paper>
        </Popper>
        )}
    </div>
    );
});

const  renderCellExpand = (params: GridCellParams) => {
    return (
    <GridCellExpand
        value={params.value ? params.value.toString() : ''}
        width={params.colDef.width}
    />
    );
}

export interface CarrousellActionInterface{
    icon: CustomIconType;
    component: (value: any, tagFP: string) => React.ReactNode;
}

interface Propss{
    rowsPerPage?:number
    headerActions?:(item:any)=>React.ReactElement,
    itemActions?:(item:any,items?:any[])=>React.ReactNode
    carrousellActions?:CarrousellActionInterface[]
    loading:boolean,
    height?:string, 
    items:any[],
    title:string,
    documentExport?:boolean
    documentFiltering?:boolean
    documentSendEmail?:boolean
    documentExportTitle?:string,
    gridModel?:any,
    renderOptions?:(item:GridCellParams)=>JSX.Element
    periodChange?: (period:undefined | {firstDate:string,secondDate:string} )=>void
    justGrid?: boolean
}

const AnyGrid:React.FC<Propss> = ({documentSendEmail, rowsPerPage, justGrid, height,periodChange, loading, items, renderOptions, title, documentExportTitle, headerActions, carrousellActions, gridModel, itemActions, documentExport, documentFiltering }) => {


    const {lang} = useLangLabels()
    //handle filtered items
    const [filteredEntity,setFilteredEntity] = useState<any>(items)
    const [rowSelected, setRowSelected] = useState<any>();
    const [selectionModel,setSelectionModel] = useState<GridRowId[]>([])
    const rendeOptionExistance = (gridModel && gridModel['renderOption']) || renderOptions ? ['renderOption'] : []

    //Generating Columns
    const columns:GridColDef[] = useMemo(()=>(filteredEntity || items) 
    ?   [ ...(rendeOptionExistance) , ...Object.keys(flattenObj(filteredEntity[0] || items[0]))]
        .map((column) => ({
            field:column,
            headerName: (gridModel && gridModel[column]?.label) || column,
            renderCell: (gridModel && gridModel[column]?.renderOption) || ((column === 'renderOption' && (!gridModel || renderOptions)) ? renderOptions : renderCellExpand ), 
            width: (gridModel && gridModel[column]?.width) || 180,
            /* hide:true */
        }))
        .filter((item) => gridModel ? !!gridModel[item.field] : true ) 
    : [] ,[filteredEntity, items, rendeOptionExistance, gridModel, renderOptions])

    //constructing custom rows with render options ebeded
    const rows = useMemo(()=> 
    filteredEntity?.map((item:any,index:number)=>{

        let result = {}

        const flattedItem = flattenObjAllLevels(item)
    
           result = {
               ...flattedItem
           }
        

        return {
            renderOption:item,
            ...(!!item?.id ? {id:item.id} : {id:index}),
            ...result
        } || []
    }),[filteredEntity])


    const handleSelection = (item:GridRowSelectedParams) => {
        setRowSelected(item)
        setSelectionModel([item.data.id])
    }


    return (<>
    {!justGrid ?
    <PageContainer padding={`${theme.spacing(3)}px ${theme.spacing(3)}px 0px `}>
        <AnyFiltering
            periodChange={periodChange}
            documentExport={documentExport}
            documentFiltering={documentFiltering}
            documentSendEmail={documentSendEmail}
            headerActions={headerActions}
            itemActions={itemActions}
            documentExportTitle={documentExportTitle || ''}
            gridModel={gridModel || undefined}
            items={items || []} 
            title={title}
            handleDataFiltered={setFilteredEntity}
            rowSelected={rowSelected?.data?.renderOption}
            carrousellActions={carrousellActions}
        />
    </PageContainer> 
    : null}
    <PageContainer width='100%' height={'580px'}>
        <DataGrid 
            /* autoHeight */
            filterMode={'client'}
            checkboxSelection={false}
            onRowSelected={(item)=>handleSelection(item)}
            selectionModel={selectionModel}
            loading={loading}
            disableSelectionOnClick={false}
            pageSize={rowsPerPage || 50}
            rowsPerPageOptions={[12,50,100,200,300,400,500,1000]}
            rowHeight={32} 
            rows={rows} 
            columns={columns} 
            /* filterModel={filterModel} */
            components={{
                ColumnMenuIcon: () => null,
                ColumnMenu: () => null,
            }}
            localeText={{
                // spanish traductions
                //rootGridLabel: 'grid',

                noRowsLabel: lang.grid.noRowsLabel,
                errorOverlayDefaultLabel: lang.grid.errorOverlayDefaultLabel,

                // Columns selector toolbar button text
                toolbarColumns: lang.grid.toolbarColumns,
                toolbarColumnsLabel: lang.grid.toolbarColumnsLabel,

                //filters
                toolbarFilters: lang.grid.toolbarFilters,
                toolbarFiltersLabel: lang.grid.toolbarFiltersLabel,
                toolbarFiltersTooltipHide: lang.grid.toolbarFiltersTooltipHide,
                toolbarFiltersTooltipShow: lang.grid.toolbarFiltersTooltipShow,
                
                // Columns panel text
                columnsPanelTextFieldLabel: lang.grid.columnsPanelTextFieldLabel,
                columnsPanelTextFieldPlaceholder: lang.grid.columnsPanelTextFieldPlaceholder,
                columnsPanelDragIconLabel: lang.grid.columnsPanelDragIconLabel,
                columnsPanelShowAllButton: lang.grid.columnsPanelShowAllButton,
                columnsPanelHideAllButton: lang.grid.columnsPanelHideAllButton,

                // Export selector toolbar button text
                toolbarExport: lang.grid.toolbarExport,
                toolbarExportLabel: lang.grid.toolbarExportLabel,
                toolbarExportCSV: lang.grid.toolbarExportCSV,
                
            
            }}/>
        </PageContainer>
    </>);
    
}

export default React.memo(AnyGrid)