import React from 'react';
import TgdModal from '../modals/TgdModal';
import CustomButton, { ButtonColorType, ButtonVariant, CustomIconType } from './CustomButton';
import { useModal } from './../../utils/hooks/useModal';

interface Props{
    onClick?: ()=>void
    children:React.ReactNode
    variant?: ButtonVariant
    icon:CustomIconType
    buttonText?:string
    title?:string
    color?: ButtonColorType
    popoverTitle?:string
    onClose?:(item?:any)=>void
    fullwidth?:boolean,
    seze?:'small' | 'default' | 'large'
    disabled?:boolean
}
const ModalButton:React.FC<Props> = ({onClose,fullwidth,variant,children,icon,buttonText,color,title,popoverTitle,onClick,disabled}) => {
    
    const {modal, handleModal} = useModal()

    const defaultVariant:ButtonVariant = variant || 'button' 

    const handleClose = ()=>{
        onClose && onClose()
        handleModal()
    }
  
    return (<>
            <CustomButton
            disabled={disabled || false} 
            variant={defaultVariant} 
            action={handleModal} 
            popoverTitle={popoverTitle || title} 
            icon={icon} 
            title={buttonText} 
            color={color} />

            <TgdModal fullWidth={fullwidth || true} title={title} open={modal} handleClose={handleClose}>
                {children}
            </TgdModal>
    </>);
}

export default React.memo(ModalButton);
