import { AppBar, createStyles, IconButton, makeStyles, Toolbar, Typography, Box } from '@material-ui/core';
import React from 'react';
import UpdateLubricationPoint from './UpdateLubricationPoint';
import FullScreenSurface from '../../components/modals/FullscreenSurface';
import EquipmentFileTemplate from './EquipmentFileTemplate';
import CloseIcon from '@material-ui/icons/Close';
import {LubricationPointInterface} from './services/service.lubricationPoints'
import FlexContainer from '../../components/presentational/FlexContainer';
import CustomButton from '../../components/buttons/CustomButton';
import { useModal } from './../../utils/hooks/useModal';

const useStyles = makeStyles((theme) => createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
	},
	root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
  }))

interface Props{
    item:LubricationPointInterface
}
const ViewLubricationPoint:React.FC<Props> = ({item}) => {
    const classes = useStyles()  
    const {modal,handleModal} = useModal()
    return (
        <>
        <CustomButton variant='iconButton' icon='view' action={handleModal} popoverTitle='Ver'/>

            <FullScreenSurface  modal={modal}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleModal} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Ver componente
                        </Typography>
                    </Toolbar>
                </AppBar>
                <br/>
                <FlexContainer justify='center' >
                    <EquipmentFileTemplate item={item} type='component'/>
                    <Box>
                        <UpdateLubricationPoint color={'primary'} item={item} />
                    </Box>
                </FlexContainer>
                
            </FullScreenSurface>
        </>
    );
}

export default React.memo(ViewLubricationPoint);
