import React,{useState,useEffect} from 'react'
//components
import MenuPlantSelector from '../../components/MenuPlantSelector'

//assets
import sidebarImg from '../../assets/img/sidebar.png'
import Logo from '../../assets/img/TGD-blanco.png'

//Material
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Icon from '@material-ui/core/Icon'
import {Typography,Hidden, Button, Badge, ListItemSecondaryAction} from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import Toolbar from '@material-ui/core/Toolbar'

//Router
import { Link, NavLink, useHistory,  } from 'react-router-dom';
import LogOutButton from '../../domains/login/LogOutButton';
import { useUser, usePlants,  useDimention, useWorkspace } from '../../domains/persons/services/service.person'
import { setNewMenu  } from '../../infrastructure/tgd.config'
import { usePbiReportsInGroup } from '../../domains/powerBi/service.pbi'

import NotificationsNavBar from '../../components/notifications/NotificationsNavBar'
import SupportComponent from '../../domains/tickets/CreateTicketsPage'
import { USER_ROUTES } from '../../infrastructure/routes/routes'
import { useOutOfRouteModal } from '../../domains/colector/domains/surveys/services/colector.surveyService'
import { CustomIcon, CustomIconType } from '../../components/buttons/CustomButton'
import { UserType } from '../../domains/persons/interfaces/userInterfaces'
import { useUserCompanies } from '../../domains/cluster-companies/services/clusterEnterpricesService'
import { useMenu } from '../services/menu/useMenu'
import { useTodayNewsbyTagFP } from './../../domains/news/services/service.news';



const useStyles = makeStyles(theme => ({

    drawer: {
        width: (props:any)=>props.drawerWidth,
        flexShrink: 0,
        zIndex:0
    },
    drawerPaper:{
        width: (props:any)=>props.drawerWidth,
        color:'#f3f3f3',
        border:'none',
        backgroundColor:'#002F6C',
        backgroundImage:'url('+ sidebarImg +')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow:'hidden'
    },
    iconDrawer:{
        color:'#f3f3f3',
        pointerEvents: 'none',
        minWidth: '32px'

    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        ...theme.mixins.toolbar,
        
    },
    activeLink: {
        backgroundColor: '#ED8B00'
    },
    spacing:{
        margin: '0 24px'
    },
    logOut:{
        position:'absolute',
        display:'flex',
        gap:'8px',
        margin:'16px',
        bottom:'16px',
        width:'218px'
    },
    listItem:{
        padding:'0 16px',
        margin:'8px 0 0',
    },
    listItemHover:{
        backgroundColor:'rgba(255, 255, 255, 0.15)',
        borderRadius:'5px',
        '&:hover':{
            backgroundColor:'rgba(255, 255, 255, 0.65)',
            cursor:'pointer'
        }
    },
    menuButton:{
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        borderRadius:'5px',
        width:'100%',
    },
    colorSecondary:{
        borderColor: '#fff',
        color: 'white',
        },
    pointerEvents:{
        pointerEvents: 'none'
    },
    flexButton:{
        width:'100%',
        backgroundColor:'rgba(255, 255, 255, 0.3)',
        borderRadius:'5px',
        padding:'5px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        '&:hover':{
            backgroundColor:'rgba(255, 255, 255, 0.6)',
            cursor:'pointer'
        }
    }
   }))

   interface SideBarButtonProps{
       ownerService:string
       allowedUserTypes:UserType[] | undefined ,
       title:string,
       icon:CustomIconType,
       onClick:()=>void
    }

   const SidebarSimpleMenuButton:React.FC<SideBarButtonProps> = React.memo(({ ownerService,allowedUserTypes,title,icon,onClick}) => {
       
        const {data:user} = useUser()
        const classes = useStyles()
        const _userType = user?.type!
        const {data:userCompanies} = useUserCompanies()
        const _userCompanies = userCompanies?.map(uc => uc.company)
        const companyPermission = _userCompanies?.includes(ownerService)
    
        return(    
        (companyPermission && allowedUserTypes?.includes(_userType)) ?
            <div className={classes.listItem} onClick={onClick}>
                <div className={classes.listItemHover}>
                    <ListItem component={Button} className={classes.menuButton}>
                        <ListItemIcon slot='start' className={classes.iconDrawer}> 
                            <CustomIcon  icon={icon} style={{margin:'0px',color:'white'}} />
                        </ListItemIcon>
                        <ListItemText className={classes.pointerEvents}>
                            <Typography component={'span'} style={{fontSize:'12px',color:'#fff'}} variant="button">{title}</Typography>
                        </ListItemText>
                    </ListItem>
                </div>
            </div>
        : <></>
       )
   })
 

interface sidebarProps {
    variant?: "permanent" | "temporary" | "persistent" | undefined
    open:boolean
    onClose?:()=>void | null | undefined
    anchor:'left' | 'right'
}

export const DefaultSidebarWidth = 250

const Sidebar:React.FC<sidebarProps> = ({ variant, open, onClose, anchor}) => {

    const [menu,setMenu] = useState<any>(useMenu())
    const classes = useStyles({drawerWidth: open ? DefaultSidebarWidth : 0});
    const {data:plants} = usePlants()
    
    const {data:user} = useUser()
    const {data:todayNews} = useTodayNewsbyTagFP((user?.type === 'O'|| user?.type === 'S') && plants ? plants[0]?.tagFP : undefined)
    const dimention = useDimention()
    const workspace = useWorkspace()
    const reports = usePbiReportsInGroup()
    const history = useHistory()
    const [dropState,setDropstate] = useState<any>(null)
    
    // localStorage
    const [modal,setModal] = useOutOfRouteModal()

    useEffect(() => {
        if((reports) && (reports.length > 0)){
            setMenu(setNewMenu(reports,menu))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reports]);
   

    const userDimention = () =>{
        if(user){
            if(user?.type === 'C') return workspace
            else return dimention
        }
    }

    useEffect(() =>{
        if(menu){
            menu?.forEach((element:any) => {
                setDropstate((dropState:any) => ({...dropState,[element.id]: false}))
            })
        }
    },[menu])

    

    //drop menu
    const drop = (e:any) =>{   
        let i = e.target.id
        let state = !dropState[i] 
        setDropstate({...dropState,[i]:state})
        
    }

    return (  
        <Drawer
            className={classes.drawer}
            classes={{
                paper: classes.drawerPaper
            }}
            anchor={anchor}
            variant={variant}
            open={open}
            onClose={onClose && onClose}
        >

            {<div className={classes.toolbar}>
                <Hidden mdUp>
                    <ListItem>
                        <AccountCircle   />
                        {user && <Typography variant="button" component={'span'} noWrap className={classes.spacing} >{user.name} </Typography>}
                    </ListItem>
                </Hidden>
                <Hidden mdUp>
                        <NotificationsNavBar color={'#fff'}/>
                    </Hidden>
                <Hidden smDown>
                    <Toolbar>
                        <img src={Logo} style={{height:'40px',left:'24px'}}alt=""/>
                        {((user) && (user?.type === 'C') && (plants) && (plants.length > 1)) ?  <MenuPlantSelector icon={classes.iconDrawer} classes={classes.colorSecondary} color="secondary" />  :null}
                    </Toolbar>
                </Hidden>
            </div>}

            

            <Divider />

            {menu && dropState
            ?
            <div style={{maxHeight:'calc(100vh - 150px)',overflowX:'hidden'}}>
                <div style={{width:'100%',height:'8px'}}></div>
                {menu?.map((item:any, index:any) => ( 
                    <div key={index} className={classes.listItem} >
                        <div style={{backgroundColor:'rgba(255, 255, 255, 0.15)',borderRadius:'5px'}}>
                            {(item.actionMenu) && (item.actionMenu.length > 0) && 
                                <>  
                                    <ListItem component={Link} onClick={(e:any)=>drop(e)} id={item.id} className={classes.menuButton} to={`/${USER_ROUTES.home}/${userDimention()}/${item.route}`} button key={index}>
                                        <ListItemIcon className={classes.iconDrawer}><Icon className={item.icon} key={index}>{item.icon}</Icon></ListItemIcon>
                                        <ListItemText className={classes.pointerEvents}>
                                                <Typography component={'span'} style={{fontSize:'12px'}} variant="button">
                                                        {item.name}
                                                </Typography>
                                        </ListItemText>
                                        <ListItemIcon className={classes.pointerEvents} style={{justifyContent:'flex-end'}} /* className={classes.iconDrawer} */>
                                            {!dropState[item.id] &&
                                            <KeyboardArrowDownIcon className={classes.pointerEvents}/>
                                            }
                                            {dropState[item.id] &&
                                            <KeyboardArrowUpIcon className={classes.pointerEvents} />
                                            }
                                        </ListItemIcon>
                                        
                                    </ListItem>
                                    {dropState[item.id] && //submenuItems
                                        <>
                                            {item.actionMenu.map((subItem:any,index:number) =>(
                                                    <div key={index} style={{padding:'8px 8px 0 8px'}}>
                                                        <ListItem component={NavLink} activeClassName='Mui-selected' className={classes.menuButton}  to={`/${USER_ROUTES.home}/${userDimention()}/${subItem.route}` } button >
                                                            <ListItemIcon className={classes.iconDrawer}><Icon className={subItem.icon} key={index}>{subItem.icon}</Icon></ListItemIcon>
                                                            <ListItemText  ><Typography style={{fontSize:'12px'}}component={'span'}  variant="button">{subItem.name}</Typography></ListItemText>
                                                        </ListItem> 
                                                    </div>
                                            ))}
                                            <div style={{width:'100%',height:'8px'}}></div>
                                        </>
                                    }
                                </>
                            }
                            {!item.actionMenu &&
                                <ListItem component={NavLink} activeClassName='Mui-selected' className={classes.menuButton} exact to={`/${USER_ROUTES.home}/${userDimention()}/${item.route}` } button key={index}>
                                    <ListItemIcon className={classes.iconDrawer}><Icon className={item.icon} key={index}>{item.icon}</Icon></ListItemIcon>
                                        <ListItemText  ><Typography component={'span'}  style={{fontSize:'12px'}} variant="button">{item.name}</Typography></ListItemText>
                                        <Badge badgeContent={item.type === 'news' ? todayNews?.length : undefined} color='primary'>
                                            <ListItemSecondaryAction>
                                            </ListItemSecondaryAction> 
                                        </Badge>
                                </ListItem>
                            }
                        </div>
                    </div> 
                ))}    
               <SidebarSimpleMenuButton ownerService='TGD' title='Fuera de ruta' allowedUserTypes={['O']} onClick={()=>setModal(!modal)} icon='outOfRouteWhite'    />
               <SidebarSimpleMenuButton ownerService='STI' title='Tiempo real' allowedUserTypes={['C','S']} onClick={()=>history.push(`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.realtimeSensors}`)} icon='wifi'/>
               <SidebarSimpleMenuButton ownerService='STI' title='STI' allowedUserTypes={['S']} onClick={()=>history.push(`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.sensoringManagement}`)} icon='config' />
               <SidebarSimpleMenuButton ownerService='TGD' title='Cliente' allowedUserTypes={['S']} onClick={()=>history.push(`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.clientVisor}/`)} icon='view' />
            </div>
            :null}
            
            <div className={classes.logOut}>
                <LogOutButton title={'salir'}/>
                {/* <div className={classes.flexButton} >
                    <Icon>person</Icon>
                </div> */}
                <Button className={classes.flexButton} component={Link} to={`/${USER_ROUTES.home}/${(user?.type ==='C') ? workspace : dimention}/${USER_ROUTES.config}`}>
                    <Icon style={{color:'#fff'}}>settings</Icon>
                </Button>
                <SupportComponent/>
            </div>


        </Drawer>
    )
}

export default React.memo(Sidebar)