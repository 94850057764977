import React from 'react';
import { useUser } from '../../persons/services/service.person';
import { PlantInterface } from '../../plants/service.plant';
import SelectSimpleEntity from '../../../components/select/SelectSimpleEntity';
import { useLubricantsByPlant } from '../../auxiliar-data/service.aux';
/* import CreateLubricantForm from '../CreateLubricantForm'
 */import { LubricantInterface } from './../services/service.lubricants';
import CULubricantForm from '../CULubricantForm';
import { CrudFormType } from '../../../infrastructure/form/typescript/FormTypes';

interface props{
    tagFP?:string
    plant?:PlantInterface | undefined
    onChange:(value:string) => void
    defaultValue?:string | undefined 
    dimentionType?:string
    color?:'primary'|'secondary'
    error?:string
    lubricantType?:string
}

const SelectLubricant:React.FC<props> = ({ plant, onChange, defaultValue, color, error, tagFP,lubricantType }) => {

    const {data:lubricants} =  useLubricantsByPlant(tagFP || plant?.tagFP)
    const _lubricants = lubricantType 
    ? lubricants?.filter((l:LubricantInterface)=>l.type === lubricantType)
    : lubricants

    const {data:user} = useUser()
    
    return (<>
            <SelectSimpleEntity
                error={error}
                label={ 'Lubricante'}
                onChange={(value:string)=>onChange(value)}
                entityList={_lubricants}
                showTitle={'lubricant'}
                defaultValue={defaultValue}
            >
                {user && (user?.type === 'O' ? null : <CULubricantForm tagFP={plant?.tagFP!} actionType={CrudFormType.create} />)}
            </SelectSimpleEntity>

    </>);

}

export default React.memo(SelectLubricant);
