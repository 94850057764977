import React from 'react';
import CustomButton from '../../../../components/buttons/CustomButton';

import SurveyModal from '../../SurveyModal';
import { useListNavigation } from '../../../../infrastructure/navigation/useListNavigation';
import { useSurveyController } from './controllers/surveyController';
import CreateSurveyForm from './CreateSurveyForm';
import { useModal } from './../../../../utils/hooks/useModal';



interface Props {
    currentItem:any
    items:any[]
    open?:boolean
    handleClose?:()=>void
    outOfRoute:boolean
    exitButton?:React.ReactNode
    handleFinish?:()=>void  
    mode?:'modal' | 'content'

}

const OutfRouteListSurvey:React.FC<Props> = ({mode, currentItem, items, open, handleClose, outOfRoute, exitButton, handleFinish}) => {

    const { current, actions, prevNextValidations } = useListNavigation(currentItem,items,'tagTGD')
    const { modal, handleModal } = useModal()
    const { surveyTasks } = useSurveyController()

    const handleFinishComponent = () => {

    }

    return (
    
            <>
                {  mode === 'content'
                    ? null
                    : <CustomButton icon='surveyPlay' color='secondary' variant='iconButton' action={handleModal} popoverTitle='Relevar fuera de ruta' />
                }
 
                <SurveyModal
                open={open || modal}
                handleClose={handleClose || handleModal}
                currentLubricationPoint={current}
                tasks={surveyTasks}
                outOfRoute={outOfRoute}
                exitButton={exitButton}
                prevNextValidations={prevNextValidations}
                prevNextActions={actions}
                >
                    <CreateSurveyForm
                        item={current} 
                        tasks={surveyTasks} 
                        outOfRoute={outOfRoute}
                        onFinish={handleFinish || handleFinishComponent} 
                    />
                </SurveyModal>
    </>);
}

export default OutfRouteListSurvey;


/* header(<Typography
    variant='body1'
    children={`${current.component} - ${current.element} - ${current.equipment}`}
/>)    */ 