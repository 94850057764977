import { MutationResult, useMutation } from 'react-query';
import  axios,{AxiosRequestConfig} from 'axios';
import { MutationQueryObject } from '../../../../../infrastructure/Interfaces';
import { URL } from '../../../../../infrastructure/appConfig/appConfig';
import { useToken } from '../../../../session/service.session';


/*-----------------------
 Axios
----------------------- */

export const CreateObservation = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateObservation`,
        data
    }
    return axios(config).then((res)=>{return res}).catch((err) => {return err})
}


/*-----------------------
 Mutations
----------------------- */




/*-----------------------
 Mutations
----------------------- */

export const useCreateObservations = ():[(data: any) => Promise<any>,MutationResult<any, unknown>] => {

    const token = useToken()
    const [mutation,data] = useMutation(CreateObservation)
    
    const createObservation = (data:any) => {
        
        return mutation({
            data,
            token
        })
    }

    return [
        createObservation,
        data
    ]
} 
