import React,{memo, useContext, useEffect} from 'react'
//components
import Home from '../../components/client/Home.'

import Reports from '../../components/pbi/Reports'
import PlantSelector from '../../layouts/clients/PlantSelector'
import PageContainer from '../../components/pageContainers/PageContainer';
import NotificationsPage from '../NotificationsPage' 


//router
import { Route,Switch, Redirect, useHistory } from 'react-router-dom'
//services
import { useUser, useDimention,usePlants } from '../../domains/persons/services/service.person'
import { usePbiReportsInGroup,usePbiTilesInDashboard } from '../../domains/powerBi/service.pbi'
import Loading from '../../components/presentational/Loading'
import DashLayout from '../../layouts/components/DashLayout'
import { SessionContext } from '../../store/sessionContext'

import LogOutPage from '../LogOutPage'
import ConfigPage from '../ConfigPage'
import AddDemoUserPage from '../AddPages/User/AddDemoUserPage'
import { USER_ROUTES } from '../../infrastructure/routes/routes';
import RealtimeSensoringPage from '../../domains/sensoring/pages/RealtimeSensoringPage';
import { PlantInterface } from '../../domains/plants/service.plant';
import VersionContainer from '../../components/pageContainers/VersionContainer';

const ClientContent:React.FC = () => {

  const [auth,setAuth] = useContext(SessionContext)
  const {data:plants} = usePlants()
  const {data:user} = useUser()
  const reports = usePbiReportsInGroup()
  usePbiTilesInDashboard()
  const history = useHistory()
  const dimention = useDimention()

  useEffect(()=>{
    if(history.location){
      let plantSelector=`/${USER_ROUTES.home}/${USER_ROUTES.plantSelector}`

      let path = history.location.pathname
      if(path === plantSelector){
        setAuth({...auth,multyPlant:true})
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[history.location])

  useEffect(() => {
    if(plants && plants.length > 1){
      setAuth({...auth,multyPlant:true})
    }
    if(plants && (plants.length === 1)){
      let workspace = `${plants[0].factory} ${plants[0].name}`
      setAuth({...auth,multyPlant:false,workspace,selectedPlant:plants[0]})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plants]);

  console.log(auth)
  const selectWorkspace = (workspace:string,plant:PlantInterface) => {
    setAuth({...auth,multyPlant:false,workspace,plant})
  }

  if(plants && auth.multyPlant){
    return(
      <>  
        <Redirect to={`/${USER_ROUTES.home}/${USER_ROUTES.plantSelector}`} />
        <Switch>
          <Route path={`/${USER_ROUTES.home}/${USER_ROUTES.plantSelector}`}>
            <PlantSelector plants={plants} select={selectWorkspace}/>
          </Route>
          <Route path={`/`}>
            <LogOutPage />
          </Route>
        </Switch>
      </>
    )
  }

  if((plants && !auth.multyPlant && reports))
  
  return (
    <>
    	<Redirect to={`/${USER_ROUTES.home}/${auth.workspace}`}/>
      		<DashLayout>
          {user && (user?.email === 'd.lavallen@tgdsa.com') &&
            <PageContainer>
              <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.demoUsers}`}>
                <AddDemoUserPage />
              </Route>
            </PageContainer>
          }
        	  	<Switch>

        	    <Route  path={`/${USER_ROUTES.home}/${auth.workspace}/${USER_ROUTES.notifications}`}> <NotificationsPage /></Route>
              <Route path={`/${USER_ROUTES.home}/${auth.workspace}/${USER_ROUTES.config}`}>
                <PageContainer>
                    <ConfigPage/>
                </PageContainer>
              </Route>
        	    <Route  path={`/${USER_ROUTES.home}/${auth.workspace}`}>
                <Switch>
                  <Route exact path={`/${USER_ROUTES.home}/${auth.workspace}`}><Home/></Route>
                  <Route path={`/${USER_ROUTES.home}/${auth.workspace}`}><Reports /* url={`/${USER_ROUTES.home}/${auth.workspace}`} */ /></Route>
                </Switch>
              </Route>
              <Route>
                <PageContainer height='100%'>
                  {auth?.selectedPlant?.tagFP && <RealtimeSensoringPage tagFP={auth?.selectedPlant?.tagFP}/>}
                  <VersionContainer company = 'STI'/>
                </PageContainer>
              </Route>
        	  </Switch>
      		</DashLayout>
      </>
    )
    
    return( <Loading/>)
      
}

export default memo(ClientContent);
