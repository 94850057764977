import React, { useEffect } from 'react';
import DefaultFormLayout from '../../../components/forms/DefaultFormLayout';
import FormSelector from '../../form-config/FormSelector';
import { STISensorizedEquipmentInterface } from './services/STISensorizedEquipmentsInterfaces';
import { useSTISensorizedEquipmentForm } from './services/useSTISensorizedEquipmentsForm';
import { useCreateSTISensorizedEquipment } from './services/STISensorizedEquipmentService';
import { ConfigFieldsInterface } from '../../form-config/interfaces';




const CreateSTISensorizedEquipmentForm:React.FC<{tagFP:string}> = ({tagFP}) => {

    const {handleSubmit,watch,errors,form,onChange,reset} = useSTISensorizedEquipmentForm({actionType:'create'},tagFP)

    const {create,data} = useCreateSTISensorizedEquipment()
    
    const submit = (fields:STISensorizedEquipmentInterface) => {
        create(fields).then(()=>reset())
    }

    return (
        <DefaultFormLayout 
        status={data.status}
        message={data.message}
        error={data.error} 
        icon={'add'} 
        buttonTitle={form.name} 
        popoverTitle={form.name} 
        onChange={handleSubmit(submit)} >
           {form.fields?.map(field=>
                <FormSelector
                    key={field.name}
                    selector={field.formSelector}
                    fieldProps={{
                        type:field.type,
                        error:errors && errors[field.name]?.message,
                        label:field.label,
                        value:watch(field.name,field.initialValue),
                        defaultValue:watch(field.name,field.initialValue),
                        onChange:(value)=>onChange(field,value),
                        tagFP,
                        complete:true,
                        name:field.name
                    }}
                />    
            )}
        </DefaultFormLayout>
    );
}

export default React.memo(CreateSTISensorizedEquipmentForm);
