
export type SuperAdminRoleType = 'SuperAdmin' 
export type AdminRoleType = 'Administrador' 
export type accountMannagerRoleType = 'Administrador de Cuentas'
export type OperatorRoleType = 'Operario' 
export type ClientRoleType = 'Cliente'
export type SupervisorRoleType = 'Supervisor'

export type PersonRoleType = SuperAdminRoleType | AdminRoleType | accountMannagerRoleType | OperatorRoleType | ClientRoleType | SupervisorRoleType

interface PersonRoleTypesInterface {
    superAdmin:PersonRoleType
    admin:PersonRoleType
    accountMannager:PersonRoleType
    operator:PersonRoleType
    client:PersonRoleType
    supervisor:PersonRoleType
} 

export const PersonRoleTypes = {
    superAdmin:'SuperAdmin',
    admin:'Administrador',
    accountMannager:'Administrador de Cuentas',
    operator:'Operario',
    client:'Cliente',
    supervisor:'Supervisor'
} 