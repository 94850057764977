import React, { useEffect, useMemo, useState } from 'react';
import FlexContainer from '../../../../components/presentational/FlexContainer';
import ComponentMode from '../../../../components/ComponentMode';
import TgdTextField from '../../../form-config/formComponents/TgdTextField';
import { Typography } from '@material-ui/core';
import CustomButton from './../../../../components/buttons/CustomButton';
import { stringify } from 'querystring';


interface Props{
    error?:string 
    handleDelete:(value:string)=>void
    onChange:(value:any)=>void
    title:string
    defaultValue:{[key:string]:any}
    name?:string
    deletable?:boolean
}


const SetMinMax:React.FC<Props> = ({error, onChange, defaultValue, title, name,handleDelete,deletable}) => {

    const [values,setValues] = useState(defaultValue)
    const keys = useMemo(()=>Object.entries(values),[values])


    const handleValue = (key:string,value:any) => {
        setValues({
            ...values,
            [key]:value
        })
        onChange({
            ...values,
            [key]:parseFloat(value)
        })
    }

    return (
        <FlexContainer align='center'>
            <Typography style ={{marginBottom:'-8px'}} variant='body2'>{title}</Typography>
            <FlexContainer> 
                {keys.map(item => 
                <TgdTextField
                    key={item[0]}
                    type='number'
                    label={`${item[0]}`}
                    value={item[1]}
                    onChange={(value) => handleValue(item[0],value)}
                    error={error}
                />)}
                {deletable && <CustomButton icon={'delete'} variant='iconButton' action={()=>handleDelete(title)}/>}
            </FlexContainer>
        </FlexContainer>
    );
};


export default React.memo(SetMinMax);
