import React,{useContext} from 'react';
//material
import {Button} from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
//Context
import {SessionContext} from '../../store/sessionContext'
import { useHistory } from 'react-router-dom';
//service
import { queryCache } from 'react-query';


const LogOutButton:React.FC<{width?:string,title?:string}> = ({width,title}) => {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [auth,setAuth] = useContext<any>(SessionContext)
    const history = useHistory()


    const handleLogout = ()=>{
        sessionStorage.clear()
        queryCache.setQueryData('token',false)
        queryCache.invalidateQueries('token')
        history.push('')
        setAuth({}) 
    }

    return (
        <Button  onClick={handleLogout} startIcon={<ExitToAppIcon />} color='primary' variant='contained' style={{width: width ? width:'100%',fontSize:'10px'}}>{title}</Button>
    );
}

export default React.memo(LogOutButton)
