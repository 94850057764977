export const arrayItemPossition = (item:{[key:string]:any},array?:{[key:string]:any}[],term?:string) => {

    var possition = 0
    if(item && !!term && array) {   
        array?.forEach((i:any,index:number) => {
            if(i[term] === item[term]){
                possition = index
            }
        })
    }

    return possition
}