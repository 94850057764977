import React,{useEffect} from 'react';
import { useDeleteElementVerification } from './services/service.elements';
import TgdPopover from '../../components/popovers/TgdPopover';
import { CustomIcon } from '../../components/buttons/CustomButton';
import { useStatusProcessor } from '../errors/services/StatusProcessor';
import { useClickPosition } from '../../utils/hooks/useClickPosition';
import { useSnackbar } from '../../utils/hooks/useSnackbar';
import { IconButton } from '@material-ui/core';
import { ElementInterface } from './types/ElementTypes';


interface Props{
    item:ElementInterface    
}

const DeleteElementForm:React.FC<Props> = ({item}) => {

    const {errors,status:deleteStatus,validate,data} = useDeleteElementVerification()
    const { status, message,error } = useStatusProcessor({...data,status:deleteStatus})
    const {position,getClickPosition} = useClickPosition()
    const { setData } = useSnackbar()

    useEffect(()=>{
        if(deleteStatus === 'error'){
            const componentsError = (
                (errors?.equipments?.length 
                    ? (errors.equipments.length > 1 
                        ? `${errors.equipments.length} Equipos` 
                        : `${errors.equipments.length} Equipo`) 
                    : '')
            )
            setData({
                modal:true,
                errors:`Este elemento no se puede eliminar porque está siendo usado en: ${componentsError}` ,
                severity:'warning',
                position:position
            })
        }
        if(deleteStatus === 'success'){
            setData({
                modal:true,
                errors:message,
                severity:'success',
                position
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[deleteStatus,errors])
        
    return (
        <TgdPopover mode='hover' title='Eliminar elemento'>
            <IconButton size='small' onClick={(e)=>getClickPosition(e,()=>validate(item))}>           
                <CustomIcon icon='delete'/>
            </IconButton>
        </TgdPopover>
    );
}

export default DeleteElementForm;
