import axios, { AxiosRequestConfig } from'axios'
import { useEffect, useState } from 'react'
import { queryCache, QueryResult, QueryStatus, useMutation } from 'react-query'
import { URL } from '../../../infrastructure/appConfig/appConfig'
import { useEquipmentsByElement } from '../../equipments/services/service.lubricationPoints'
import { useToken } from '../../session/service.session'
import { useOperatorSupervisorTagFP } from '../../persons/services/service.person';
import { useAllAuxByPlant } from '../../auxiliar-data/service.aux'
import { GridModelInterface, MutationQueryObject } from '../../../infrastructure/Interfaces'
import { useStatusProcessor } from '../../errors/services/StatusProcessor';
import { ElementInterface } from '../types/ElementTypes'

//----------------------------
//interfaces
//---------------------------------------------



export const ElementCRUDDependencies = [
    'PlantAux'
]



export const gridModelElement:GridModelInterface= {
    code:{label:'Código'}, 
    date:{label:'Fecha'},
    element:{label:'Nombre'}, 
    id:{label:'Id'}, 
    tagFP:{label:'Planta'}, 
}

//----------------------------
//repo
//---------------------------------------------


export const AllElements = (_:string,token:string) => {
    axios.defaults.headers.get['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/AllElements`,

    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const CreateElement = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateElement`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}
export const UpdateElement = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateElement`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const DeleteElement = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/DeleteElement`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}


//----------------------------
//reactQuery
//---------------------------------------------

export const usePlantElements = (tagFP?:string) => {
    const SupOpTagFP = useOperatorSupervisorTagFP()
    const query = useAllAuxByPlant(tagFP || SupOpTagFP)
    const elements:QueryResult<ElementInterface[]> = {
        ...query,
        data:query.data?.elements
    }
    return elements
}


//----------------------------
//Mutation
//---------------------------------------------

export const useCreateElement = () => {
    const token = useToken()
    const [mutation,data] = useMutation(CreateElement,{
        onSuccess:() => ElementCRUDDependencies.forEach((dependency)=>{
            queryCache.invalidateQueries(dependency)
        })
    })

    const createElement = (data:ElementInterface) => {
        return mutation({
            data,
            token
        })
    }
    const status = useStatusProcessor(data)
    return {createElement,...data,...status}
}
export const useUpdateElement = () => {
    const token = useToken()
    const [mutation,data] = useMutation(UpdateElement,{
        onSuccess:() => ElementCRUDDependencies.forEach((dependency)=>{
            queryCache.invalidateQueries(dependency)
        })
    })

    const updateElement = (data:ElementInterface) => {
        return mutation({
            data,
            token
        })
    }
    const status = useStatusProcessor(data)
    return {updateElement,...data,...status}
}




//----------------------------
//delete
//---------------------------------------------

export const useDeleteElement = () => {
    return useMutation(DeleteElement,{
        onSuccess:() => ElementCRUDDependencies.forEach((dependency)=>{
            queryCache.invalidateQueries(dependency)
        })
    })
}

interface DeleteElementsErrors {
    equipments?:ElementInterface[] | undefined
}


export const useDeleteElementVerification = () => {
    const token = useToken()
    const [deleteItem,{data}] = useDeleteElement()
    const [status, setStatus] = useState<QueryStatus>(QueryStatus.Idle)
    const [validationElement,setValidationElement] = useState<ElementInterface | {} |undefined >()

    const {data:elementsByEquipments,status:elementsByEquipmentsStatus} = useEquipmentsByElement(validationElement)
    const [errors, setErrors] = useState<DeleteElementsErrors>({})

    const validate = (item:ElementInterface) => {

        setStatus(QueryStatus.Loading)
        if(!elementsByEquipments)setValidationElement(item)
        else handleDelete()
    }

    const handleDelete= () =>{
        if(elementsByEquipmentsStatus === 'success'){
            if((elementsByEquipments && elementsByEquipments.length > 0)) {
                setErrors({equipments:elementsByEquipments})
                setValidationElement(undefined)
                setStatus(QueryStatus.Error)
            }
            else{
                deleteItem({
                    data:validationElement,
                    token
                })
                .then((res)=>{
                    setValidationElement(undefined)
                    setStatus(QueryStatus.Success)
                })
                .catch(err=>console.log(err))
            }
        }
    }

    
    useEffect(() => {
        handleDelete()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[elementsByEquipmentsStatus])

    return  {
        errors,
        status,
        validate,
        data
    }

}