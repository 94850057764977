import { queryCache, QueryConfig, QueryFunction, QueryKey, QueryResult, useQuery } from "react-query"


export const useDefaultQuery = <TResult, TError>(queryKey: QueryKey, queryFn: QueryFunction<TResult>, queryConfig?: QueryConfig<TResult, TError>): QueryResult<TResult, TError> => {
    return useQuery(queryKey,queryFn,{
        staleTime:500,
        refetchOnWindowFocus:false,
        ...queryConfig,
    })
}

