import { FormRegExp } from '../../../../infrastructure/regex/regexForms';
import { Crudfields } from '../../../form-config/interfaces';
import { useFormConfigs } from '../../../form-config/useFormConfigs';
import { useLangLabels } from '../../../language/services/useSetLabel';
import { STISensorsInterface } from './STIsensorsInterfaces';


export const useSTISensorForm = ({actionType,defaultValue}:Crudfields,tagFP:string) => {

    const {lang} = useLangLabels()

    return useFormConfigs<STISensorsInterface>(actionType,{
        singularName:lang.STISensors.title.singular,
        pluralName:lang.STISensors.title.plural,
        fieldFilters:{
            update:['sensorType']
        },
        fields:[
            {
                label:'Id',
                name:'id',
                formSelector:'none',
                type:'number',
                initialValue:0,
            },
            {
                label:lang.STISensors.form.label.sensorType,
                name:'sensorType',
                formSelector:'sensorTypeCompleteSelector',
                type:'string',
                initialValue:'',
                validations:{
                    required:{value:true,message:'requerido'},
                    maxLength:{value:50}
                }
            },
            {
                label:lang.STISensors.form.label.sensorTypeCode,
                name:'sensorTypeCode',
                type:'string',
                formSelector:'none',
                initialValue:'',
                validations:{
                    required:{value:true},
                }
            },
            {
                label:lang.STISensors.form.label.sensorAddress,
                name:'sensorAddress',
                type:'string',
                formSelector:'enterSensorAddress',
                initialValue:'',
                validations:{
                    required:{value:true},
                    pattern:{value:FormRegExp.macAddress,message:lang.validations.messages.macAddress},
                   /*  minLength:{value: 12 },
                    maxLength:{value: 12 }, */
                }
            },
            {
                label:lang.STISensors.form.label.channelQuantity,
                name:'channelQuantity',
                type:'number',
                formSelector:'text',
                initialValue:0,
            },
            {
                label:lang.STISensors.form.label.permanent,
                name:'permanent',
                type:'boolean',
                formSelector:'booleanOptionSelector',
                initialValue:undefined
            },
            {
                label:lang.STISensors.form.label.batteryCapacity,
                name:'batteryCapacity',
                type:'number',
                formSelector:'text',
                initialValue:3.3,
                validations:{
                    pattern:{value:FormRegExp.float},
                    required:{value:true},
                    maxLength:{value:50}
                }
            },
            {
                label:lang.globalentityValues.tagFP,
                name:'tagFP',
                type:'string',
                formSelector:'none',
                initialValue:tagFP,
            }
        ]
    },defaultValue)
}