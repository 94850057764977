import React, { useEffect, useState } from 'react';
import { enumTgdTypes } from '../Types/types';
import { useObservationTypesByTagFP } from './../services/service.types';
import SelectTypeBy from './SelectTypeBy';
/* import SelectType from './SelectType';
 */
interface Props{
    tagFP:string
    error?:string
    onChange:(item?:string)=>void
    defaultValue?:string
    
}

const SelectTabulatedObservation:React.FC<Props> = ({tagFP, error, onChange,defaultValue}) => {

    const [state,setState] = useState<string>('')

    useEffect(()=>{
        if(state){
            onChange(state.toUpperCase())
        }
    },[state])

    return (
        /* <SelectType 
            tagFP={tagFP}
            type='Observacion'
            label='Observación'
            error={error}        
            onChange={setState}
            onInputChange={setState}
            value={state}
        /> */
        <SelectTypeBy
        error={error}
        by={{key:'type',value:enumTgdTypes.observation}}
        tagFP={tagFP}
        onChange={setState}
        extendInput={true}
        defaultValue={defaultValue}
        />
    );
}

export default React.memo(SelectTabulatedObservation);
