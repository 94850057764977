import axios,{ AxiosRequestConfig } from'axios'
//services
import { useMutation, useQuery } from 'react-query';
import { useToken } from '../session/service.session'
import { useUser } from '../persons/services/service.person';
import { getAllResources } from '../../infrastructure/axios/service.tgd.axios';
import { MutationQueryObject } from '../../infrastructure/Interfaces';
import { URL } from '../../infrastructure/appConfig/appConfig';


export interface NotificationInterface{
    id?: number
    title: string
    body: string
    global: boolean
    tagF: string
    tagFP: string
    creationDate: string
    releaseDate: string
    readingLevel: number
    sku: any
}

//AXIOS REPO
//-------------------------



export const  updateNotification = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateNotificationState`,
        data
    }
    let res = axios(config).then(res => {return res.data}).catch(res =>{return res.data})
    return res
}

export const createNotification = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateNotification`,
        data
    }
    let res = axios(config).then(res => {return res.data}).catch(res =>{return res.data})
    return res
}


//react-query HOOkS
//-------------------------

export const useUnReadedNotifications = () => {
    const token = useToken()
    return useQuery(['Notifications',token],getAllResources,{
        //refetchInterval:5000
    })
}

export const useReadedNotifications = () => {
    const token = useToken()
    return useQuery(['NotificationsReaded',token],getAllResources,{
        //refetchInterval:5000
    })
}

export const useAddNotification = () => {
    return useMutation(createNotification)
}


//CUSTOM HJOOKS
//-------------------------

export const useReadUnreadNotification = ()=>{

    const {data:user} = useUser()
    const token = useToken()
    const {data:notifications,refetch:refetchNotifications} = useUnReadedNotifications()
    const {refetch:refetchUnreadedNotif} = useReadedNotifications()

    const refetch = () => {
        refetchNotifications()
        refetchUnreadedNotif()
    }
    const [mutate] = useMutation(updateNotification,{
        onSuccess: () => refetch()
    })

    const readOne = (item:NotificationInterface) => {
        let data = {
            email:user?.email,
            notificationId:item.id,
            state:'Leido'
        }
        mutate({data,token})
    }
    
    const unReadOne = (item:NotificationInterface) =>{
        let data = {
            email:user?.email,
            notificationId:item.id,
            state:'Sin leer'
        }
        mutate({data,token})
    } 

    const readAll = () =>{
        notifications.forEach((item:NotificationInterface) => {
            readOne(item)
        });
    }

    return [readOne, unReadOne, readAll]
    
}