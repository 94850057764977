import { useState,useEffect } from "react";

export const useSearch = (items:any[] | undefined | null,filter:string) => {
    const [filteredItems, setFilteredItems] = useState(items);
    const [search,setSearch] = useState('')

    useEffect(()=>{
        if(items){
            if(!!filter){
                if(search !== ''){setFilteredItems(items.filter(item => item[filter]?.toLowerCase()?.includes(search.trim())))}
                if(search === ''){setFilteredItems(items)}
            }else{
                if(search === ''){setFilteredItems(items)}
            }
        }
    },[search,filter,items])

    const handleSearch = (e:any) =>{
        setSearch(e.target.value.toLowerCase())
    }

    return{search,handleSearch,filteredItems}
}