import axios,{ AxiosRequestConfig } from 'axios'
import { useEffect, useState } from 'react'
import { MutationFunction, MutationResult, queryCache, QueryConfig, QueryResult, QueryStatus, ReactQueryMutationsConfig, useMutation, useQuery } from 'react-query'
import { GridModelInterface, MutationQueryObject } from '../../../infrastructure/Interfaces'
import { LubricationPointInterface, useEquipmentsBySupply } from '../../equipments/services/service.lubricationPoints'
import { useToken } from '../../session/service.session'
import { EquipmentInterface } from '../../equipments/services/service.lubricationPoints'
import { URL } from '../../../infrastructure/appConfig/appConfig'
import { useAllAuxByPlant } from '../../auxiliar-data/service.aux';
import { useOperatorSupervisorTagFP } from '../../persons/services/service.person';
import { QueryState } from 'react-query/types/core/query';
import { useStatusProcessor } from '../../errors/services/StatusProcessor';
import { AssignCancelSupplyInterface, NeededThingsToSurveyType, SupplyInterface, SupplyType } from '../types/SupplyTypes'


export const SupplyCRUDDependencies=[
    'LubricationPointByTagTGD',
    'PlantRoutes',
    'EquipmentsByTagFP',
    'DaoEquipmentsByTagFP',
    'DaoEquipmentsElementsByTagFP',
]

export const gridModelSupply:GridModelInterface = {
    id: {label:'Id'},
    date: {label:'Fecha'},
    description: {label:'Descripción'},
    supply: {label:'Insumo'},
    tagFP: {label:'Planta'},
    type: {label:'Tipo'},
}

export const gridModelTools:GridModelInterface = {
    ...gridModelSupply,
    supply:{label:'Herramienta'}
}
export const gridModelConsumables:GridModelInterface = {
    ...gridModelSupply,
    supply:{label:'Consumible'}
}
export const gridModelProtections:GridModelInterface = {
    ...gridModelSupply,
    supply:{label:'Proteccion'}
}

//---------------------------
//Repo
//---------------------------


export const CreateSupply = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateSupply`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const UpdateSupply = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateSupply`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const DeleteSupply = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/DeleteSupply`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const AssignCancelSupplies = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/AssignCancelSupplies`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

//----------------------------
//QUERIES
//---------------------------------------------

export const useAllSupplies = (tagFP?:string) => {
    const supOpTagFP = useOperatorSupervisorTagFP()
    const query = useAllAuxByPlant(supOpTagFP || tagFP)
    const result:QueryResult<SupplyInterface[]> = {
        ...query,
        data:query.data?.supplies
    }
    return result
}

export const useSuppliesByType = (tagFP:string,supplyType?:SupplyType) => {
    const query = useAllSupplies(tagFP) 
    const allSupplies = query.data
    const result: QueryResult<SupplyInterface[]> ={
        ...query,
        data: supplyType ? allSupplies?.filter((supply)=> supply.type === supplyType) : allSupplies
    }
    return result
}



//----------------------------
//MUTATIONS
//---------------------------------------------

export const useCreateSupply = (queryConfigs?:{
    onSuccess:()=>void
}) => {
    const token = useToken()

    const [mutation,data] = useMutation(CreateSupply,{
        onSuccess:()=>SupplyCRUDDependencies.forEach((dependency)=>{
            queryCache.invalidateQueries(dependency)
            queryConfigs && queryConfigs.onSuccess()
        })
        
    })
    const createSupply = (data:SupplyInterface) => {
        return mutation({
            data,
            token
        })
    }

    const status= useStatusProcessor(data)
    
    return {createSupply,...data,...status}
}

export const useUpdateSupply = () =>{
    return useMutation(UpdateSupply,{
        onSuccess:()=>SupplyCRUDDependencies.forEach((dependency)=>{
            queryCache.invalidateQueries(dependency)
        })
    })
}


export const useDeleteSupply = () => {
    return useMutation(DeleteSupply,{
        onSuccess:()=>SupplyCRUDDependencies.forEach((dependency)=>{
            queryCache.invalidateQueries(dependency)
        })
    })
}

export const useAssignCancelSupplies = () => {
    const token = useToken()
    const [assignCancelSupplies,{data,status,error,reset}] = useMutation(AssignCancelSupplies,{
        onSuccess:()=>SupplyCRUDDependencies.forEach((dependency)=>{
            queryCache.invalidateQueries(dependency)
        })
    })

    const assignMultipleSupplies = (multipleObjects:AssignCancelSupplyInterface[]) => {
        assignCancelSupplies({
            data:multipleObjects,
            token
        })
    }

    const cancelMultipleSupplies = (multipleObjects:AssignCancelSupplyInterface[]) => {
        assignCancelSupplies({
            data:multipleObjects,
            token
        })
    }

    const assign = (equipment:LubricationPointInterface,supply:string) => {
        assignCancelSupplies({
            data:[{
                supply:supply,
                equipment:equipment.tagTGD,
                state:'A',
                tagFP:equipment.tagFP
            }],
            token
        })
    }

    const cancel = (equipment:LubricationPointInterface,supply:string) => {
        assignCancelSupplies({
            data:[{
                supply:supply,
                equipment:equipment.tagTGD,
                state:'C',
                tagFP:equipment.tagFP
            }],
            token
        })
    }
    
    return{
        assign,
        cancel,
        assignMultipleSupplies,
        cancelMultipleSupplies,
        data,
        status,
        error,
        reset
    }
}


interface DeleteSupplyErrors {
    equipments?:SupplyInterface[] | undefined 
}

export const useDeleteSupplyVerification = () => {

 
    const token = useToken()
    const [deleteItem,{data}] = useDeleteSupply()
    const [status, setStatus] = useState<QueryStatus>(QueryStatus.Idle)
    const [validationElement,setValidationElement] = useState<SupplyInterface | {} | undefined >()

    const {data:equipments,status:equipmentStatus} = useEquipmentsBySupply(validationElement)

    const [errors, setErrors] = useState<DeleteSupplyErrors>({})

    const validate = (item:SupplyInterface) => {
        setStatus(QueryStatus.Loading)
        if(!equipments)setValidationElement(item)
        else handleDelete()
    }

    const handleDelete= () =>{
        if((equipmentStatus === 'success')){
            if((equipments.length > 0)){
                setErrors({equipments})
                setStatus(QueryStatus.Error)
                setValidationElement(undefined)
            }
            else{
                deleteItem({
                    data:validationElement,
                    token
                })
                .then(()=>{
                    setStatus(QueryStatus.Success)
                    setValidationElement(undefined)
                })
                .catch((err)=>console.log(err))
            }
        }
    }


    useEffect(() => {
        handleDelete()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[equipmentStatus])

    return  {
        errors,
        status,
        validate,
        data
    }

}

export const useAssignMultipleSuppliesToMultipleEquipments = () => {

    const {assignMultipleSupplies,status,data,reset} = useAssignCancelSupplies()
    const [assignationItems,setAssignationItems] = useState<AssignCancelSupplyInterface[]>([])
  
    const handleAssigCancel = (equipments:EquipmentInterface[],Supplies:SupplyInterface[],state:'A'|'C') => {
        setAssignationItems([])
        equipments.forEach(e => {
            Supplies.forEach(supply => {
                setAssignationItems((assignationItems) =>[
                        ...assignationItems,
                        {
                            supply:supply.supply,
                            equipment:e.tagTGD,
                            state:state,
                            tagFP:e.tagFP
                        }
                ])
            })
        })
    }

    useEffect(() => {
        if(assignationItems.length > 0){
            assignMultipleSupplies(assignationItems)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[assignationItems])
    
    return{
        handleAssigCancel,
        status,
        objectQuery:assignationItems,
        data,
        reset
    }
}