import React from 'react';
import { Icon, Button } from '@material-ui/core';

interface Props{
    variant: 'check' | 'close' 
    title?:string
    action:()=>void
    height?:string
    width?:string
}

const DoNotDoButton:React.FC<Props> = ({variant,title,action,height,width}) => {

    const config = {
        backgroundColor: variant === 'check' ? 'green' : 'red',
        height:height || '100px',
        width:width || '150px',
        color:'#fff'
    }

    return (
        <Button onClick={action} style={config}> 
            <Icon>{variant}</Icon>
            {title && title}
        </Button>
    );
}

export default DoNotDoButton;
