import { Typography } from "@material-ui/core"




type GenericObject<T> = {
    [K in keyof T]: T[K]
}

export const flattenObjAllLevels = (ob:GenericObject<any>):GenericObject<any> => {
    var toReturn:GenericObject<any> = {};
    
    for (var i in ob) {
        if (!ob.hasOwnProperty(i)) continue;
        
        if ((typeof ob[i]) == 'object') {
            const nextObj = ob[i]
            var flatObject = flattenObjAllLevels(nextObj);
            for (var x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue;
                
                toReturn[`${i}.${x}`] = flatObject[x];
            }

        } else {
            toReturn[i] = ob[i];
        }
    }
    return toReturn;
};



export const flattenObj = (item:any,k?:string) =>{
    let flattenKeys:any = {}
    item && Object.entries(item).forEach(([key,value]:[string,any])=>{

        if(Array.isArray(value)){
            return flattenKeys = {
                ...flattenKeys,
                    [key]:value
            }
        }

        if(typeof(value) === 'object'){
            const flatted = flattenObj(value,key)
            flattenKeys={
                ...flattenKeys,
                ...flatted
            }
        }
       

        else{
            return flattenKeys = {
                ...flattenKeys,
                    [`${k ? (k+'.') : ''}${key}`]:value
            }
        }
    })

    return flattenKeys
}

export const groupByKeyOptions = (items:any[]) => {

    let grupedOptions:any = {}
    const flattedKeys = Object.keys(flattenObjAllLevels(items[0]))

    /* console.log(flattedKeys) */
    items[0] && items.forEach((item)=>{
        flattedKeys.forEach((key)=>{
            if(key.includes('.')){

                const levels = key.split('.')

                const level1 = item[levels[0]]
                const level2 = level1 && level1[levels[1]]
                const level3 = level2 && level2[levels[2]]

                if(levels.length>2){
                    grupedOptions = {
                        ...grupedOptions,
                        [key]:[
                            ...new Set([
                                ...(grupedOptions[key] ? grupedOptions[key] : []),
                                level3 ? level3 : ''
                            ])
                        ]
                    }                
                }
                else {
                    grupedOptions = {
                        ...grupedOptions,
                            [key]:[
                                ...new Set([
                                    ...(grupedOptions[key] ? grupedOptions[key] : []),
                                    item[levels[0]][levels[1]]
                                ])
                        ]
                    }
                }

            }else{

                grupedOptions = {
                    ...grupedOptions,
                    [key]:[...new Set([
                        ...(grupedOptions[key] ? grupedOptions[key] : []),
                        item[key]
                    ])]
                }

            }
        })
    })

    return grupedOptions
}

//Search Methods
export const search = (rows:any[],currentSearch:string):any[] => {
    const columns = rows[0] && Object.keys(flattenObj(rows[0]))
    const filterSearch = currentSearch.toString().toLowerCase()

    return rows.filter((row) =>
        columns.some((column:string) =>
            row[column]?.toString().toLowerCase().indexOf(filterSearch) > -1)
        )
}

export const multipleSearch = (rows:any[],filters:any) => {
        
    const filterKeys = filters && Object.keys(filters)

    return rows[0] && rows?.filter((row:any) => {
        const flatRow = flattenObj(row)
        return filterKeys ? filterKeys.every((fk:string) => {
            if(filters[fk] === undefined) return true
            if(flatRow[fk] !== filters[fk]) return false
            if(flatRow[fk] === filters[fk]) return true
        }) : true
    })

}



