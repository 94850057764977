import React, { useEffect } from 'react';
import DefaultFormLayout from '../../../components/forms/DefaultFormLayout';
import { STISensorizedEquipmentInterface } from './services/STISensorizedEquipmentsInterfaces';
import { useUpdateSTISensorizedEquipment } from './services/STISensorizedEquipmentService';
import { useForm } from 'react-hook-form';
import SetParametters from './molecules/SetParametters';
import { FormRegExp } from '../../../infrastructure/regex/regexForms';
import SelectSTIEquipment from '../STIEquipments/molecules/SelectSTIEquipment';
import TgdTextField from '../../form-config/formComponents/TgdTextField';
import SelectIoTDevice from '../iotDevices/molecules/SelectIoTDevice';
import SelectSTISensor from '../STISensors/molecules/SelectSTISensor';
import SelectElement from '../../elements/molecules/SelectElement';
import { useLangLabels } from './../../language/services/useSetLabel';






const registerFields = <T,>(item:T,register:(item:any,validations?:any)=>void) => {
    if(item){

        const keys = Object.entries(item)

        keys.forEach(([name,value])=>{
            switch(name){
                
                case 'element':
                    register({name,value})
                break

                case 'lowReason':
                    register({name,value})
                break

                case 'sensorType':
                    register({name,value},{
                        required:true,
                        maxLength:50
                    })
                break
                
                case 'sensorAddress':
                    register({name,value},{
                        required:true,
                        pattern:FormRegExp.macAddress
                    })
                break

                case 'iotDeviceCode':
                    register({name,value},{
                        required:true,
                        pattern:FormRegExp.macAddress
                    })
                break

                case 'temperature':
                    register({name,value})
                break

                case 'vibration':
                    register({name,value})
                break

                case 'channels':
                    register({name,value})
                break

                case 'pressure':
                    register({name,value})
                break

                default:
                    register({name,value},{required:true})
                break
            }
        })    
    }
}

type entityType<T> = keyof T 

interface entityForm<T>{
    [key:string]:{
        label:string,
        name:string}
    }




const UpdateSTISensorizedEequipmentForm:React.FC<{tagFP:string,item:STISensorizedEquipmentInterface,items:STISensorizedEquipmentInterface[]}> = ({tagFP,item,items}) => {


    const {setValue, errors, register, watch, handleSubmit, reset} = useForm()

    useEffect(()=>{
        registerFields(item,register)
    },[item])
   
    const {update,status,message,error} = useUpdateSTISensorizedEquipment()

    const submit = (fields:STISensorizedEquipmentInterface) => {
        update(fields).then(()=>reset())
    }


    const {lang} = useLangLabels()

    const itemInfo = {
        element:{
            name:'element',
            label:lang.STISensorizedEquipment.form.label.observations
        },
        equipment:{
            name:'equipment',
            label:lang.STISensorizedEquipment.form.label.observations
        },
        sensorType:{
            name:'sensorType',
            label:lang.STISensorizedEquipment.form.label.sensorType
        },
        sensorTypeCode:{
            name:'sensorTypeCode',
            label:lang.STISensorizedEquipment.form.label.sensorTypeCode
        },
        plantTag:{
            name:'plantTag',
            label:lang.STISensorizedEquipment.form.label.plantTag
        },
        tagSTI:{
            name:'tagSTI',
            label:lang.STISensorizedEquipment.form.label.tagSTI
        },
        sector:{
            name:'sector',
            label:lang.STISensorizedEquipment.form.label.sector
        },
        location:{
            name:'location',
            label:lang.STISensorizedEquipment.form.label.location
        },
        criticality:{
            name:'criticality',
            label:lang.STISensorizedEquipment.form.label.criticality
        },
        iotDeviceCode:{
            name:'iotDeviceCode',
            label:lang.STISensorizedEquipment.form.label.iotDeviceCode
        },
        observations:{
            name:'observations',
            label:lang.STISensorizedEquipment.form.label.observations
        },
        temperature:{
            name:'temperature',
            label:lang.STISensorizedEquipment.form.label.temperature
        },
        vibration:{
            name:'vibration',
            label:lang.STISensorizedEquipment.form.label.vibration
        },
        pressure:{
            name:'pressure',
            label:lang.STISensorizedEquipment.form.label.pressure
        },
        channels:{
            name:'channels',
            label:lang.STISensorizedEquipment.form.label.channels
        }
    }


    return(
        /* <ModalList icon='edit'  onChange={setCurrentItem} item={currentItem} items={items} title={'form.name'}> */
            <DefaultFormLayout 
            fullwidth={false}
            key={item.sensorAddress}
            status={status}
            message={message}
            error={error} 
            icon={'edit'}
            variant='iconButton'
            mode='modal'
            buttonTitle={lang.crudActions.update}
            popoverTitle={lang.crudActions.update}
            onChange={handleSubmit(submit)} >

                { <>

                    <SelectSTIEquipment
                        tagFP={tagFP}
                        defaultValue={watch(itemInfo.equipment.name)}
                        //error ={errors?.equipment?.message} 
                        complete ={true}
                        onChange = {(value)=>{
                            setValue(itemInfo.equipment.name,value.equipment)
                            setValue(itemInfo.criticality.name,value.equipment)
                            setValue(itemInfo.sensorTypeCode.name,value.sensorTypeCode)
                            setValue(itemInfo.plantTag.name,value.plantTag)
                            setValue(itemInfo.tagSTI.name,value.tagSTI)
                            setValue(itemInfo.sector.name,value.sector)
                            setValue(itemInfo.location.name,value.location)
                        }}
                        
                    />

                    <SelectElement
                        tagFP={tagFP}
                        defaultValue={watch(itemInfo.element.name)}
                        //error ={errors?.element?.message}
                        onChange = {(value)=>{
                            setValue(itemInfo.element.name,value)
                        }}
                    />

                    <SelectSTISensor
                        tagFP={tagFP}
                        defaultValue={watch(itemInfo.sensorType.name)}
                        //error ={errors?.sensorType?.message} 
                        complete ={true}
                        onChange = {(value)=>{
                            setValue('sensorType',value.sensorType)
                            setValue('sensorAddress',value.sensorAddress)
                        }}
                    />

                    <SelectIoTDevice
                        tagFP={tagFP}
                        defaultValue={watch(itemInfo.iotDeviceCode.name)}
                        //error ={errors?.iotDeviceCode?.message} 
                        complete ={true}
                        onChange = {(value)=>{
                            setValue(itemInfo.iotDeviceCode.name,value?.deviceCode)
                        }}
                    />

                    <TgdTextField
                        label={itemInfo.observations.label}
                        name={itemInfo.observations.name}
                        //error = {errors?.observations?.message}
                        value={watch(itemInfo.observations.name)}
                        onChange = {(value)=>setValue(itemInfo.observations.name,value)}
                    />

                    <SetParametters
                        label={itemInfo.temperature.label}
                        name={itemInfo.temperature.name}
                        defaultValue={item.temperature}
                        onChange={(value)=>setValue(itemInfo.temperature.name,value)}
                    />

                    <SetParametters
                        label={itemInfo.channels.label}
                        name={itemInfo.channels.name}
                        defaultValue={item.channels}
                        onChange={(value)=>setValue(itemInfo.channels.name,value)}
                    />

                    <SetParametters
                        label={itemInfo.pressure.label}
                        name={itemInfo.pressure.name}
                        defaultValue={item.pressure}
                        onChange={(value)=>setValue(itemInfo.pressure.name,value)}
                    />

                    <SetParametters
                        label={itemInfo.vibration.label}
                        name={itemInfo.vibration.name}
                        defaultValue={item.vibration}
                        onChange={(value)=>setValue(itemInfo.vibration.name,value)}
                    />

                </>}

                {/* {fields?.map((field:any)=>
                    <FormSelector
                        key={field.name}
                        selector={field.formSelector}
                        fieldProps={{
                            type:field.type,
                            error:errors && errors[field.name]?.message,
                            label:field.label,
                            value:watch(field.name,field.initialValue),
                            defaultValue:watch(field.name,field.initialValue),
                            onChange:(value)=>onChange(field.name,value),
                            complete:true,
                            tagFP
                        }}
                    /> 
                )} */} 

            </DefaultFormLayout>
        /* </ModalList>   */
    ); 
}

export default UpdateSTISensorizedEequipmentForm
