import React from 'react';
import { PlantInterface } from '../../plants/service.plant';
import { gridModelConsumables, gridModelProtections, gridModelTools, useSuppliesByType } from '../services/service.supplies';
import FlexContainer from '../../../components/presentational/FlexContainer';
import AssignCancelMultipleSupplies from '../molecules/AssignCancelMultipleSupplies';
import DeleteSupplyForm from '../DeleteSupplyForm';
import AnyGrid from '../../../components/grids/AnyGrid';
import { GridModelInterface } from '../../../infrastructure/Interfaces';
import { useLangLabels } from './../../language/services/useSetLabel';
import { CarrousellActionInterface } from './../../../components/grids/AnyGrid';
import { SupplyInterface, SupplyType } from '../types/SupplyTypes';
import CUSupplyForm from '../CUSupplyForm';
import { CrudFormType } from '../../../infrastructure/form/typescript/FormTypes';

interface Props {
    plant:PlantInterface
    type: SupplyType
}


const carrousellActions:CarrousellActionInterface[] = [
    {icon:'edit', component:(item:SupplyInterface)=><CUSupplyForm tagFP={item.tagFP} item={item} actionType={CrudFormType.update} />}
]

const ManageSuppliesPage:React.FC<Props> = ({plant,type}) => {
    
    const {data:supplies} = useSuppliesByType(type)

    const {lang} = useLangLabels()

    const renderOptions = (item:any) => {
        return(
            <FlexContainer>
                <DeleteSupplyForm type={type} item={item} />
            </FlexContainer>
        )
    }

    const gridModel:{[key:string]:GridModelInterface} = {
        'HERRAMIENTA':gridModelTools,
        'CONSUMIBLE':gridModelConsumables,
        'PROTECCION':gridModelProtections
    }
 
    return(
        <AnyGrid
            gridModel={gridModel[type]}
            items={supplies || []}
            loading={supplies ? false : true}
            title={lang.supplies[type].title.plural}
            height='55vh'
            carrousellActions={carrousellActions}
            itemActions={renderOptions}
            headerActions={(item:any)=><>
                <AssignCancelMultipleSupplies type={type} plant={plant}/>
                <CUSupplyForm tagFP={plant.tagFP} actionType={CrudFormType.create} supplyType={type} />
            </>}
        />
    )
}

export default React.memo(ManageSuppliesPage);
