import { FormRegExp } from '../../../../infrastructure/regex/regexForms';
import { ConfigFieldsInterface, Crudfields } from '../../../form-config/interfaces';
import { useFormConfigs } from '../../../form-config/useFormConfigs';
import { STISensorizedEquipmentInterface } from './STISensorizedEquipmentsInterfaces';
import { useCallback } from 'react';
import { useLangLabels } from './../../../language/services/useSetLabel';
import { language } from '../../../language/services/useLanguages';


export const sensorizedFormFields = (lang:language,tagFP:string,defaultValue?:any):ConfigFieldsInterface<STISensorizedEquipmentInterface>[] => ([
            {
                label:'Id',
                name:'id',
                formSelector:'none',
                type:'number',
                initialValue:defaultValue?.id || 0,
            },
            {
                label:lang.STISensorizedEquipment.form.label.equipment,
                name:'equipment',
                type:'string',
                formSelector:'selectSTIEquipment',
                initialValue:defaultValue?.equipment || '',
                validations:{
                    required:{value:true}
                }
            },
            {
                label:lang.STISensorizedEquipment.form.label.element,
                name:'element',
                type:'string',
                formSelector:'selectElement',
                initialValue:defaultValue?.element || '',
            },
            {
                label:lang.STISensorizedEquipment.form.label.sensorType,
                name:'sensorType',
                formSelector:'selectSTISensor',
                type:'string',
                initialValue:defaultValue?.sensorType || '',
                validations:{
                    required:{value:true,message:'requerido'},
                    maxLength:{value:50}
                }
            },
            {
                label:lang.STISensorizedEquipment.form.label.sensorAddress,
                name:'sensorAddress',
                type:'string',
                formSelector:'none',
                initialValue:defaultValue?.sensorAddress || '',
                validations:{
                    required:{value:true},
                }
            },
            {
                label:lang.STISensorizedEquipment.form.label.iotDeviceCode,
                name:'iotDeviceCode',
                type:'string',
                formSelector:'selectIoTDevice',
                initialValue:defaultValue?.iotDeviceCode || '',
                validations:{
                    required:{value:true},
                    pattern:{value:FormRegExp.macAddress,message:lang.validations.messages.macAddress}
                }
            },
            {
                label:lang.STISensorizedEquipment.form.label.plantTag,
                name:'plantTag',
                type:'string',
                formSelector:'none',
                initialValue:defaultValue?.plantTag || '',
                validations:{
                    required:{value:true}
                }
            },
            {
                label:lang.STISensorizedEquipment.form.label.tagSTI,
                name:'tagSTI',
                type:'string',
                formSelector:'none',
                initialValue:defaultValue?.tagSTI || '',
                validations:{
                    required:{value:true},
                }
            },
            {
                label:lang.STISensorizedEquipment.form.label.element,
                name:'element',
                type:'string',
                formSelector:'none',
                initialValue:defaultValue?.element || '',
            },
            {
                label:lang.STISensorizedEquipment.form.label.sector,
                name:'sector',
                type:'string',
                formSelector:'none',
                initialValue:defaultValue?.sector || '',
                validations:{
                    required:{value:true},
                }
            },
            {
                label:lang.STISensorizedEquipment.form.label.location,
                name:'location',
                type:'string',
                formSelector:'none',
                initialValue:defaultValue?.location || '',
                validations:{
                    required:{value:true},
                }
            },
            {
                label:lang.STISensorizedEquipment.form.label.criticality,
                name:'criticality',
                type:'string',
                formSelector:'none',
                initialValue:defaultValue?.riticality || '',
                validations:{
                    required:{value:true},
                }
            },
            {
                label:lang.STISensorizedEquipment.form.label.temperature,
                name:'temperature',
                type:'string',
                formSelector:'setParametters',
                initialValue:defaultValue?.temperature || undefined,
            },
            {
                label:lang.STISensorizedEquipment.form.label.vibration,
                name:'vibration',
                type:'string',
                formSelector:'setParametters',
                initialValue:defaultValue?.vibration || undefined,

            },
            /* {
                label:setLabel('STISensorizedEquipment.form.label.xAxis'),
                name:'xAxis',
                type:'string',
                formSelector:'setParametters',
                initialValue:undefined,
                validations:{
                    required:{value:true},
                }
            },
            {
                label:setLabel('STISensorizedEquipment.form.label.yAxis'),
                name:'yAxis',
                type:'string',
                formSelector:'setParametters',
                initialValue:undefined,
                validations:{
                    required:{value:true},
                }
            },
            {
                label:setLabel('STISensorizedEquipment.form.label.zAxis'),
                name:'zAxis',
                type:'string',
                formSelector:'setParametters',
                initialValue:undefined,
                validations:{
                    required:{value:true},
                }
            }, */
            {
                label:lang.STISensorizedEquipment.form.label.channels,
                name:'channels',
                type:'string',
                formSelector:'setParametters',
                initialValue:defaultValue?.channels || undefined,

            },
            {
                label:lang.STISensorizedEquipment.form.label.pressure,
                name:'pressure',
                type:'string',
                formSelector:'setParametters',
                initialValue:defaultValue?.pressure || undefined,

            },
            {
                label:lang.STISensorizedEquipment.form.label.observations,
                name:'observations',
                type:'string',
                formSelector:'text',
                initialValue:defaultValue?.observations || 'Sin observaciones',
                validations:{
                    pattern:{value:FormRegExp.description},
                    required:{value:true},
                    maxLength:{value:100}
                }
            },
            {
                label:lang.STISensorizedEquipment.form.label.lowReason,
                name:'lowReason',
                type:'string',
                formSelector:'text',
                initialValue:defaultValue?.lowReason || '',
                validations:{
                    maxLength:{value:100}
                }
            },
            {
                label:lang.STISensorizedEquipment.form.label.state,
                name:'state',
                type:'boolean',
                formSelector:'none',
                initialValue:defaultValue?.state || true,
                validations:{
                    required:{value:true},
                }
            },
            {
                label:lang.STISensorizedEquipment.form.label.admissionDate,
                name:'admissionDate',
                type:'string',
                formSelector:'none',
                initialValue:defaultValue?.admissionDate || '',
            },
            {
                label:lang.STISensorizedEquipment.form.label.egressDate,
                name:'egressDate',
                type:'string',
                formSelector:'none',
                initialValue:defaultValue?.egressDate || '',
            },
            {
                label:'Planta',
                name:'tagFP',
                type:'string',
                formSelector:'none',
                initialValue:tagFP,
            }
        ])

export const useSTISensorizedEquipmentForm = ({actionType,defaultValue}:Crudfields,tagFP:string) => {

    const {lang} = useLangLabels()

    const formConfigs = useFormConfigs<STISensorizedEquipmentInterface>(actionType,{
        singularName:lang.STISensorizedEquipment.title.singular,
        pluralName:lang.STISensorizedEquipment.title.plural,
        fieldFilters:{
            create:['lowReason'],
            update:['lowReason']
        },
        fields:sensorizedFormFields(lang,tagFP)
    },defaultValue)



    const onChange = useCallback((field:ConfigFieldsInterface<STISensorizedEquipmentInterface>,value:any) => {
        

        switch (field.name) {
            case 'sensorType':
                formConfigs.setValue(field.name,value?.sensorType)//complete mode
                formConfigs.setValue('sensorAddress',value?.sensorAddress)//complete mode
                break;

            case 'equipment':
                formConfigs.setValue('equipment',value?.equipment)//complete mode
                formConfigs.setValue('criticality',value?.criticality)//complete mode
                formConfigs.setValue('sensorTypeCode',value?.sensorTypeCode)//complete mode
                formConfigs.setValue('plantTag',value?.plantTag)//complete mode
                formConfigs.setValue('tagSTI',value?.tagSTI)//complete mode
                formConfigs.setValue('sector',value?.sector)//complete mode
                formConfigs.setValue('location',value?.location)//complete mode
                break;

            case 'iotDeviceCode':
                /* console.log(value) */
                formConfigs.setValue('iotDeviceCode',value?.deviceCode)//complete mode
                break;

            case 'observations':
                formConfigs.setValue('observations',value)
                break

            case'element':
                formConfigs.setValue('element',value?.element)//complete mode
                break

            case 'lowReason':
                formConfigs.setValue('lowReason',value)
                break
                
            case 'temperature':
                formConfigs.setValue('temperature',value)
                break

            case 'pressure':
                formConfigs.setValue('pressure',value)
                break

            case 'vibration':
                formConfigs.setValue('vibration',value)
                break

            case 'channels':
                formConfigs.setValue('channels',value)
                break
        }
        
    },[formConfigs])

    return { ...formConfigs, onChange, fields:sensorizedFormFields(lang,tagFP) }

}