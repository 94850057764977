import { useEffect, useState } from "react"
import { objectMapper } from './../../utils/objectMapper';



export const useListNavigation = <T,>(item:T, items:T[], uniqueIdentifierSelector:keyof T) => {

    /* console.log('currentItem',currentItem.tagTGD) */
    const current = item[uniqueIdentifierSelector]
    
    /* console.log('point',point) */
    const itemList = items.map((item)=>item[uniqueIdentifierSelector])
    
    /* Default index */
    const [currentItemIndex,setCurrent] = useState<number>(itemList.indexOf(current))

    /* console.log('currentItemIndex',currentItemIndex) */
    const mappedItems = objectMapper(items,'tagTGD')

    /* console.log('pointsMapped',pointsMapped) */
    const CurrentItem = mappedItems[itemList[currentItemIndex]]

    const nextValidation = currentItemIndex > itemList.length - 2  
    const prevValidation = currentItemIndex < 1

    /* update intex on point change */
    useEffect(()=>{
        setCurrent(itemList.indexOf(current))
    },[current])

    const nextItem = () => {
        setCurrent(currentItemIndex + 1)
    }

    const prevItem = () => {
        setCurrent(currentItemIndex - 1)
    }
    
    const finishItem = () => {
        /* delete from item list */
        /* add item to finished list */
        /* reset index iterator to position 0 */
    }

    return{
        current:CurrentItem,
        prevNextValidations:{
            prev:prevValidation,
            next:nextValidation
        },
        actions: {
            prev:prevItem,
            next:nextItem,
        }
    }

}