


export const getUserAgentData = () => {

//    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName  = navigator.appName;
    var fullVersion  = ''+parseFloat(navigator.appVersion); 
    var majorVersion = parseInt(navigator.appVersion,10);
    var nameOffset,verOffset,ix;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset=nAgt.indexOf("Opera"))!==-1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset+6);
    if ((verOffset=nAgt.indexOf("Version"))!==-1) 
    fullVersion = nAgt.substring(verOffset+8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset=nAgt.indexOf("MSIE"))!==-1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.substring(verOffset+5);
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((verOffset=nAgt.indexOf("Chrome"))!==-1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset+7);
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((verOffset=nAgt.indexOf("Safari"))!==-1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset+7);
    if ((verOffset=nAgt.indexOf("Version"))!==-1) 
    fullVersion = nAgt.substring(verOffset+8);
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((verOffset=nAgt.indexOf("Firefox"))!==-1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset+8);
    }
    // In most other browsers, "name/version" is at the end of userAgent 
    else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < 
            (verOffset=nAgt.lastIndexOf('/')) ) 
    {
    browserName = nAgt.substring(nameOffset,verOffset);
    fullVersion = nAgt.substring(verOffset+1);
    if (browserName.toLowerCase()=== browserName.toUpperCase()) {
    browserName = navigator.appName;
    }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix=fullVersion.indexOf(";"))!==-1)
    fullVersion=fullVersion.substring(0,ix);
    if ((ix=fullVersion.indexOf(" "))!==-1)
    fullVersion=fullVersion.substring(0,ix);

    majorVersion = parseInt(''+fullVersion,10);
    if (isNaN(majorVersion)) {
    fullVersion  = ''+parseFloat(navigator.appVersion); 
    majorVersion = parseInt(navigator.appVersion,10);
    }



    var OSName="Unknown OS";
    if (navigator.appVersion.indexOf("Win")!==-1) OSName="Windows";
    if (navigator.appVersion.indexOf("Mac")!==-1) OSName="MacOS";
    if (navigator.appVersion.indexOf("X11")!==-1) OSName="UNIX";
    if (navigator.appVersion.indexOf("Linux")!==-1) OSName="Linux";

    return {
        browserName,
        OSName
    }

}

export function getGeoLocation (){

    let currentLocation:any = []
    
    function showPosition(position:any) {
        let data = {
            latitud : position.coords.latitude, 
            longitud : position.coords.longitude
        }
        currentLocation.push(data)
    }
    navigator.geolocation.getCurrentPosition(showPosition)

    if(currentLocation.length>0)return currentLocation

}

export const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        if(navigator.userAgent.match(/playbook/i)) return 'Tableta :playbook'
        if(navigator.userAgent.match(/(android(?!.*mobi))/i)) return 'Tableta :Android'
        if(navigator.userAgent.match(/iPad/i)) return 'Tableta:iPad'
        if(navigator.userAgent.match(/iPod/i)) return 'Tableta:iPod'
        return "Tableta";
    }
    if (/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
         if(navigator.userAgent.match(/Android/i))  return 'Movil: Android'
        if(navigator.userAgent.match(/webOS/i))  return 'Movil:webOS'
        if(navigator.userAgent.match(/iPhone/i))  return 'Movil:iPhone'
        if(navigator.userAgent.match(/BlackBerry/i))  return 'Movil:BlackBerry'
        if(navigator.userAgent.match(/Windows Phone/i)) return 'Movil:Windows Phone'

        else return "Dispositivo Movil"
    }
    return "Escritorio";
  };


