import React,{useEffect} from 'react';
import { TypeInterface, useDeleteTypeVerification } from './services/service.types';
import CustomButton from '../../components/buttons/CustomButton';
import { useStatusProcessor } from '../errors/services/StatusProcessor';
import { useClickPosition } from '../../utils/hooks/useClickPosition';
import { useSnackbar } from '../../utils/hooks/useSnackbar';

interface Props{
    item:TypeInterface    
}

const DeleteType:React.FC<Props>= ({item}) => {
    const  { status:deleteStatus, validate, errors, data } = useDeleteTypeVerification()
    const { status, message } = useStatusProcessor({...data,status:deleteStatus})

    const {getClickPosition,position} = useClickPosition()
    const {setData}= useSnackbar()

    useEffect(()=>{
        if(deleteStatus === 'error'){

            const equipmentsError = (
                errors.equipments ? 
                (errors.equipments.length 
                    ? (errors.equipments.length > 1 
                        ? `${errors.equipments.length} Equipos` 
                        : `${errors.equipments.length} Equipo`) 
                    : '')
                :''
            )
        
            const lubricantsError = (
                errors.lubricants ? 
                (errors.lubricants.length 
                    ? (errors.lubricants.length > 1 
                        ? ` ${errors.lubricants.length} Lubricantes` 
                        : ` ${errors.lubricants.length} Lubricante`) 
                    : '')
                :''
                )

            setData({
                modal:true,
                errors:`No puedes eliminar este tipo. Está siendo usado en: ${lubricantsError && lubricantsError} ${equipmentsError && equipmentsError}` ,
                severity:'warning',
                position:position
            })
        }

        if(deleteStatus === 'success'){
            setData({
                modal:true,
                errors:message,
                severity:deleteStatus === 'success' ? 'success' : 'warning',
                position:position
            })
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[deleteStatus])

    return (<>
        <CustomButton popoverTitle='Eliminar tipo' icon='delete' variant='iconButton' status={status} action={(e)=>getClickPosition(e,()=>validate(item))} />
    </>);
}

export default React.memo(DeleteType);
