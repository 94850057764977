import React from 'react';
import { CrudFormType } from '../../infrastructure/form/typescript/FormTypes';
import { useCreateUpdate } from '../crud/hooks/useCreateUpdate';
import { useLangLabels } from '../language/services/useSetLabel';
import { CUEntityFormPropsType, uiFormInterface } from '../crud/types/crudTypes';
import DefaultFormLayout from '../../components/forms/DefaultFormLayout';
import FieldSelector from '../../infrastructure/form/components/FieldSelector';

import { useSupplyForm } from './services/useSupplyForm';
import { SupplyInterface, SupplyType } from './types/SupplyTypes';
import { CreateSupply, DeleteSupply, UpdateSupply, SupplyCRUDDependencies } from './services/service.supplies';


type CUSupplyFormProps = CUEntityFormPropsType<SupplyInterface> & {supplyType?:SupplyType}


const CUSupplyForm: React.FC<CUSupplyFormProps> = ({tagFP, item, actionType, supplyType, variant, icon, mode, onSuccess}) => {

    //DB 
    const { action, resetStatus,status, error, message } = useCreateUpdate({
        create: CreateSupply,
        update: UpdateSupply,
        delete: DeleteSupply
    },actionType, SupplyCRUDDependencies)

    //UI
    const { lang } = useLangLabels()

    const { uiModel, handleSubmit, reset } = useSupplyForm(tagFP, supplyType, item)

    const ui = (() => {
        const formName = supplyType ? lang.supplies[supplyType].title.singular : lang.supplies.title.singular
        switch (actionType) {
            case CrudFormType.create:
                const create:uiFormInterface = {
                    icon:icon || 'add',
                    title:`${lang.actionTypes.create} ${formName.toLowerCase()}`,
                    variant: variant || 'button',
                    mode:mode || 'modal'
                }
                return create

            case CrudFormType.update:
                const update:uiFormInterface = {
                    icon:icon || 'edit',
                    title:`${lang.actionTypes.update} ${formName.toLowerCase()}`,
                    variant:variant || 'iconButton',
                    mode:mode || 'content'
                }
                return update
        }
    })()

    //Methods
    const submit = (data:SupplyInterface) => {
        action(data)
    }

    const handleReset = () => {
        resetStatus()
        reset()
        onSuccess && onSuccess()
    }

    return(
        <DefaultFormLayout
            mode={ui.mode}
            icon={ui.icon}    
            variant={ui.variant}
            buttonTitle={ui.title}
            popoverTitle={ui.title}
            {...{status,error,message}} 
            onChange={handleSubmit(submit)}  
            onClose={handleReset} 
        >
            {uiModel.map((props,index)=>
                <FieldSelector key={index} fieldProps={props} />
            )}
        </DefaultFormLayout>
    )
};

export default CUSupplyForm;