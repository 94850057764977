import React from 'react';
import { CrudFormType } from '../../infrastructure/form/typescript/FormTypes';
import { useCreateUpdate } from '../crud/hooks/useCreateUpdate';
import { useLangLabels } from '../language/services/useSetLabel';
import { CUEntityFormPropsType, uiFormInterface } from '../crud/types/crudTypes';
import DefaultFormLayout from '../../components/forms/DefaultFormLayout';
import FieldSelector from '../../infrastructure/form/components/FieldSelector';
import { CreateElement, DeleteElement, ElementCRUDDependencies, UpdateElement } from './services/service.elements';
import { useElementForm } from './services/useElementForm';
import { ElementInterface } from './types/ElementTypes';

type CUElementFormProps = CUEntityFormPropsType<ElementInterface>

const CUElementForm: React.FC<CUElementFormProps> = ({tagFP, item, actionType, icon, variant, mode}) => {

    //DB 
    const { action, resetStatus,status, error, message } = useCreateUpdate({
        create: CreateElement,
        update: UpdateElement,
        delete: DeleteElement
    },actionType, ElementCRUDDependencies)

    //UI
    const { lang } = useLangLabels()

    const { uiModel, handleSubmit, reset } = useElementForm(tagFP, item)

    const ui = (() => {
        const formName = lang.elements.title.singular
        switch (actionType) {
            case CrudFormType.create:
                const create:uiFormInterface = {
                    icon: icon || 'add',
                    title:`${lang.actionTypes.create} ${formName.toLowerCase()}`,
                    variant: variant || 'button',
                    mode: mode || 'modal'
                }
                return create

            case CrudFormType.update:
                const update:uiFormInterface = {
                    icon: icon || 'edit',
                    title:`${lang.actionTypes.update} ${formName.toLowerCase()}`,
                    variant: variant || 'iconButton',
                    mode: mode || 'content'
                }
                return update
        }
    })()

    //Methods
    const submit = (data:ElementInterface) => {
        action(data)
    }

    const handleReset = () => {
        resetStatus()
        reset()
    }

    return(
        <DefaultFormLayout
            mode={ui.mode}
            icon={ui.icon}    
            variant={ui.variant}
            buttonTitle={ui.title}
            popoverTitle={ui.title}
            {...{status,error,message}} 
            onChange={handleSubmit(submit)}  
            onClose={handleReset} 
        >
            {uiModel.map((props,index)=>
                <FieldSelector key={index} fieldProps={props} />
            )}
        </DefaultFormLayout>
    )
};

export default CUElementForm;