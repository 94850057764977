import { queryCache, QueryConfig, useQuery } from "react-query"

export const useQueryState = <T,>(queryKey:string,config?:QueryConfig<T>) => {
    
    const query = useQuery<T>(queryKey,{
        ...config
    })
   
    const setQueryState = (newState:any) => {
        queryCache.setQueryData(queryKey,newState,{
            onSuccess:()=>{
                queryCache.invalidateQueries(queryKey)
            }
        })
    }

    return {...query,setQueryState}
}