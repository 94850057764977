import React, {useState,useCallback, useEffect, ContextType} from 'react';
import { getBrowserLocales } from '../utils/utils';
//routr






export const SessionContext = React.createContext<any>({})

//Making provider component to pass states 
export const SessionContextProvider:React.FC<{children:React.ReactNode}> = React.memo(({children}) => {

	const [auth,setAuth] = useState(()=>{
		const stored = sessionStorage.getItem('auth')
		return stored ? JSON.parse(stored) : {}
		}
	)

	useEffect(() => {
		sessionStorage.setItem('auth',JSON.stringify({
			...auth,
		}))
	}, [auth]);

	
	return(
		<SessionContext.Provider value={[auth,setAuth]} >
			{children}
		</SessionContext.Provider>
	)

})
