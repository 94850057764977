import { GridModelInterface } from "../../../../../infrastructure/Interfaces";
import { useAllLubricationPointsAndInfoByTagFP, useLubricationPointsWithLeakLevel } from "../../../../equipments/services/service.lubricationPoints";
import { useOperatorSupervisorTagFP } from "../../../../persons/services/service.person";



export const useEquipmentswithCompleteInfo = (tagFP?:string) => {
    const opSupTagFP = useOperatorSupervisorTagFP()
    const queryTagFP = tagFP || opSupTagFP

    const {data:equipments} = useAllLubricationPointsAndInfoByTagFP(queryTagFP)
    return useLubricationPointsWithLeakLevel(equipments)


} 
