import axios,{AxiosRequestConfig} from 'axios' 
import { useContext, useMemo } from 'react'
import { useQuery, queryCache, useMutation } from 'react-query'
import { SessionContext } from '../../store/sessionContext'
import { ONLINE_MODE, PBI_URL, URL } from '../../infrastructure/appConfig/appConfig'
import { useUser, useWorkspace } from '../persons/services/service.person'
import { useToken } from '../session/service.session'
import { useStatusProcessor } from './../errors/services/StatusProcessor';


export interface DashboardInterface{
    id: string
    displayName: string
    embedUrl: string
    isReadOnly: boolean
}

export interface PbiGroupInterface{
    id: string
    isReadOnly: boolean
    isOnDedicatedCapacity: boolean
    name: string
}
export interface PbiReportsInterface {
    datasetId: string
    id: string
    name: string
    webUrl: string
    embedUrl: string
}


//--------------------------------------
//Axios PBI querys
//--------------------------------------

//hook create new worspace on power bi group
export const CreatePbiGroup = ({pbiToken, data}:{pbiToken:string,data:{name:string}}) => {

    axios.defaults.headers.post['Authorization'] = 'Bearer ' + pbiToken;
    
    let config:AxiosRequestConfig = {
        method: "post",
        url:'https://api.powerbi.com/v1.0/myorg/groups',
        data
    }
    return axios(config)

}

export const getPbiAccessToken = (_:String,token:string) => {
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;

    let config: AxiosRequestConfig={
        method:'get',
        url:`${URL}/pbi`,
    }
    
    let res = axios(config).then(res => {
        sessionStorage.setItem('aadToken',res.data)
        return res.data
    })
    .catch (res => {return res.data})
    return res
}

export const getPbiInfo = (endpoint:string, token:string) =>{

    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    let config:AxiosRequestConfig = {
        method: "get",
        url: `${PBI_URL}/${endpoint}`,
    }
    return axios(config).then(res=>{return res.data}).catch(res=>{return res})
}

export const getPbiResourceByGroupID = (endpoint:string,groupId:string,token:string) =>{
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    let config:AxiosRequestConfig={
        method:'get',
        url:`${PBI_URL}/groups/${groupId}/${endpoint}`,
    }
    let res = axios(config)
    .then(res=>{
        let response = res.data.value
         return response
        }).catch(res=>{return res})
    return res
}


export const getPbiResourceIngDashboard = (endpoint:string,groupId:string,dashboardId:string,token:String) => {
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    let config:AxiosRequestConfig={
        method:'get',
        url:`${PBI_URL}/groups/${groupId}/dashboards/${dashboardId}/${endpoint}`,
    }
    let res = axios(config)
    .then(res=>{
        let response = res.data.value
         return response
        }).catch(res=>{return res})
    return res

}

export const addUserInPbiGroup = ({groupId,data,token}:{groupId:string,data:any,token:string}) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config:AxiosRequestConfig = {
        method: "post",
        url:`https://api.powerbi.com/v1.0/myorg/groups/${groupId}/users`,
        headers: {}, 
        data
    }
    return axios(config).then(res => {return res}).catch(err =>{return err})
}

//delete PBI GROIUP USER
export const deleteUserInPbiGroup = ({groupId,user,token}:{groupId:string,user:string,token:string}) => {
    axios.defaults.headers.delete['Authorization'] = 'Bearer ' + token;
    let config:AxiosRequestConfig = {
        method: "delete",
        url:`https://api.powerbi.com/v1.0/myorg/groups/${groupId}/users/${user}`,
    }
    return axios(config).then(res => {return res}).catch(err =>{return err})
}

//--------------------------------------
//react-Query HOOKS
//--------------------------------------

export const usePbiAccessToken = () => {
    const token = useToken()
    const query = useQuery(['aadToken',token],getPbiAccessToken,{
        retry:0,
        refetchOnWindowFocus:false,
        enabled:!!token && ONLINE_MODE
    })
    if (query.data && query.data.includes('error')) return {data:null,refetch:query.refetch,status:''}
    else return query
}

export const useAadToken = () => {
    const data = sessionStorage.getItem('aadToken')
    if (data) return data
    else return ''
}

export const useReports = () => {
    const data = queryCache.getQueryData('reports')
    return data
}

export const usePbiGroups = () => {
    const token = useAadToken()
    return useQuery(['groups', token], getPbiInfo,{
        retry:0,
        enabled:!!token && ONLINE_MODE
    })
}

export const usePbiReportsInGroup = () => {
    const groupId = usePbiIdByName()
    const token = useAadToken()
    const {data:reports} =useQuery(['reports', groupId, token], getPbiResourceByGroupID,{
        retry:0,
        enabled:!!groupId && !!token && ONLINE_MODE
    })
    if(reports) return reports
}

export const usePbiDashboardsInGroup = () => {
    const groupId = usePbiIdByName()
    const token = useAadToken()
    const {data:dashboards} = useQuery(['dashboards', groupId, token], getPbiResourceByGroupID,{
        retry:0,
        enabled:!!groupId && !!token && ONLINE_MODE
    })
    if(dashboards) return dashboards
}

export const usePbiHomeDashboard = () => {
    const dashboards = usePbiDashboardsInGroup()
    let homeDashboard = []
    if(dashboards){
        homeDashboard = dashboards.filter((item:DashboardInterface) => item.displayName.toLowerCase() === 'home')
        if (homeDashboard.length > 0){
            return homeDashboard[0].id
        }
    }
}


export const usePbiTilesInDashboard = () => {
    const groupId = usePbiIdByName()
    const dashboardId = usePbiHomeDashboard()
    const token = useAadToken()
    return useQuery(['tiles',groupId,dashboardId,token],getPbiResourceIngDashboard,{
        retry:0,
        enabled:!!dashboardId && !!groupId && !!token && ONLINE_MODE
    })
}

//--------------------------------------
//mutation
//--------------------------------------

export const useAddUserInPbiGroup = () => {
    return useMutation(addUserInPbiGroup)
}
export const useDeleteUserInPbiGroup = () => {
    return useMutation(deleteUserInPbiGroup)
}


//--------------------------------------
//CUSTOM HOOKS
//--------------------------------------

export const useCreatePbiGroup = () => {
    const {data:pbiToken} = usePbiAccessToken()
    const [mutation,query] = useMutation(CreatePbiGroup,{
        onSuccess:()=>{
            queryCache.invalidateQueries('groups')
        }
    })

    const status = useStatusProcessor(query)

    const createPbiGroup = (data:{name:string}) => {
        return mutation({
            pbiToken,
            data
        })
    }

    return{
        createPbiGroup,
        ...query,
        ...status
    }
}


export const usePbiIdByName = () => {
    const [auth,setAuth] = useContext<any>(SessionContext)
    const workspace = useWorkspace()
    const{data:user} = useUser()
    const {data:groups} = usePbiGroups()
    const groupId = useMemo(()=>groups?.value?.find((item:PbiGroupInterface) => item.name === workspace)?.id,[groups,workspace])

    if(user && groups && workspace ){
        switch (user?.type) {
            case 'C':
                if(!groups) return null
                if(!groupId ) {
                    setAuth({...auth,workspace:'',multyPlant:true})
                    alert(`El nombre de planta no ha podido ser encontrado - Probablemente haya un error con el nombre de su planta en nuestro sistema; comuniquese con el equipo de soporte para ver que ha ocurrido en la obtencion de los datos de la planta ${workspace}`)
                }
                return groupId
            
            default :
                return null
        }   
    }
}




