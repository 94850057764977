import React, { useState } from 'react';
import DefaultFormLayout from '../../../components/forms/DefaultFormLayout';
import FormSelector from '../../form-config/FormSelector';
import ModalList from '../../../components/modals/modalList/ModalList';
import { useSTISensorForm } from './services/useSTISensorForm';
import { useUpdateSTISensor } from './services/STIsensorsService';
import { STISensorsInterface } from './services/STIsensorsInterfaces';





const UpdateSTISensorForm:React.FC<{tagFP:string,item:STISensorsInterface,items:STISensorsInterface[]}> = ({tagFP,item,items}) => {

    const [_item,setItem] = useState<STISensorsInterface>()
    const {setValue,handleSubmit,watch,errors,form} = useSTISensorForm({actionType:'update',defaultValue:_item},tagFP)
    const {updateSTISensor,status,message,error} = useUpdateSTISensor()
    
    const submit = (fields:STISensorsInterface) => {
        updateSTISensor(fields)
    }

    return(
        <ModalList icon='edit'  onChange={setItem} item={item} items={items} title={form.name}>
            <DefaultFormLayout 
            status={status}
            message={message}
            error={error} 
            icon={'edit'}
            mode='content'
            buttonTitle={form.name} 
            popoverTitle={form.name} 
            onChange={handleSubmit(submit)} >
                {form.fields?.map(field=>
                    <FormSelector
                        key={field.name}
                        selector={field.formSelector}
                        fieldProps={{
                            type:field.type,
                            error:errors && errors[field.name]?.message,
                            label:field.label,
                            value:watch(field.name,field.initialValue),
                            defaultValue:watch(field.name,field.initialValue),
                            onChange:(value)=>setValue(field.name,value),
                            tagFP
                        }}
                    />    
                )}
            </DefaultFormLayout>
        </ModalList>
    );
}

export default UpdateSTISensorForm
