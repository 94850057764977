/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState} from 'react';
import Logo from '../../assets/img/navBarLogo.png'
import {Route,Switch} from 'react-router-dom'
//material
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import { CircularProgress, InputAdornment } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockIcon from '@material-ui/icons/Lock';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
//templates
import { htmlClient, htmlAdmin } from '../email-with-atachment/emailTemplates/htmlClientDemo'
//services
import { useLogin } from '../session/service.session'
import { useNewDemoRequest } from '../users/services/service.demoUser';

import { getDeviceType, getUserAgentData } from '../../infrastructure/user-agent/service.DetectUserDevice';
import { USER_ROUTES } from '../../infrastructure/routes/routes';
import OnlineOfflineMode from '../../infrastructure/online/offline Mode/OnlineOfflineMode';
import { useLangLabels } from './../language/services/useSetLabel';


//estilos
const useStyles = makeStyles((theme) => ({
    paper: {
        adding: theme.spacing(2),
        height:'min-content',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        display:'flex',
        gap:'20px',
        marginTop:'50px',
        padding:'50px',
        alignItems:'center',
        borderRadius:'10px',
        margin: '0 0 0px 0',
        flexDirection:'column',
        
    },                                    
    loginForm:{
        width:'100%',
        display:'grid',
        /* gridTemplateRows:'repeat(4,50px)', */
        gridAutoRows:'50px',
        gap:'20px',
        minHeight:'150px',
        },
    loginLinks:{
        width:'100%',
        display:'flex',
        justifyContent:'space-around'
    },
    center:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
        },
    img:{
        height:'80px',
        margin:'auto'
    },
    input:{
        border:'green',
        width:'100%'
    },
    tagLine:{
        maxHeight:'80px'
    },
    LoginHeader:{
        marginBottom:'0px',
        fontSize:'28px',
        fontWeight:'900',
        textTransform:'uppercase',
        color:theme.palette.secondary.main,
        textAlign:'left',
    },
    bottomLine:{
        backgroundColor:theme.palette.secondary.gold,
        height:'8px',
        width:'48px',
        
    },
    submitButton:{
        margin:'16px 0',
        padding:'24px'
    }
}));


const RegisterBox = () => {

    const {lang} = useLangLabels()
    const classes = useStyles()
    const [createNewDemo,queryState] = useNewDemoRequest()
    //useragent
    const info = getUserAgentData()
    const deviceType = getDeviceType()

    const [newDemoAplicant,setNewDemoApplicant] = useState({
        applicantsEmail:'',
        applicantsName:'',
        applicantsTelephone:'+54',
        applicantsCompany:'',
        htmlClient:'',
        htmlAdmin:'',
        latitude:'',
        longitude:'',
        operativeSystem:'',
        device:'',
        browser:'',
    })
   

    const handleChange = (e) => {
        setNewDemoApplicant({...newDemoAplicant,[e.target.name]:e.target.value})
    }
    
    function sendData() {
        createNewDemo({
            data:{
                applicantsEmail:newDemoAplicant.applicantsEmail,
                applicantsName:newDemoAplicant.applicantsName,
                applicantsTelephone:newDemoAplicant.applicantsTelephone,
                applicantsCompany:newDemoAplicant.applicantsCompany,
                htmlClient:htmlClient(newDemoAplicant),
                htmlAdmin:htmlAdmin(newDemoAplicant),
                latitude:`${ sessionStorage.getItem('latitud') ? sessionStorage.getItem('latitud') : 'No se aprobó' }`,
                longitude:`${ sessionStorage.getItem('longitud') ? sessionStorage.getItem('longitud') : 'No se aprobó' }`,
                operativeSystem:info ? info.OSName : 'no se ha detectado un sistema operativo',
                device:deviceType,
                browser:info ? info.browserName : 'no se ha detectado un navegador'
            }
        })
    }

    function showPosition(position) {
        let data = {
            latitud : position.coords.latitude, 
            longitud : position.coords.longitude
        }
        sessionStorage.setItem('latitud',data.latitud)
        sessionStorage.setItem('longitud',data.longitud)
        sendData()
    }

    const sendWithLocation = async() => {
        navigator.geolocation.getCurrentPosition(showPosition,()=>sendData())
    }

    useEffect(()=>{
        // custom form rule for have < 10 caracters on 'isPasswordLength'
        if (!ValidatorForm.hasValidationRule('isPhone')) {
            ValidatorForm.addValidationRule('isPhone', (value) => {
                if (value.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/) || (value.length < 10)) {
                    return false;
                }
                return true;
            });
        }
    },[])
    
    if(queryState.status === 'error')return <Alert severity='error'>Error, No Pudimos procesar su solicitud, intentelo de nuevo más tarde</Alert>
    if(queryState.status === 'success')return <Alert severity='success'>Sus datos se han enviado correctamente!</Alert>

    return(
        <ValidatorForm className={classes.loginForm} onSubmit={sendWithLocation} >

            <TextValidator 
            label={`*${lang.demo.form.completeName}`}
            className={classes.input}
            disabled={queryState.isLoading}
            name={'applicantsName'}
            value={newDemoAplicant.applicantsName}
            autoComplete="off"
            margin="normal"
            /* variant="" */
            onChange={handleChange}
            validators={['required']}
            errorMessages={[lang.formMessages.required]}
            />

            <TextValidator    
            label={`*${lang.demo.form.phone}`}
            className={classes.input}
            disabled={queryState.isLoading}       
            name={'applicantsTelephone'}
            type="tel" 
            /* min="0" 
            step="1" */
            value={newDemoAplicant.applicantsTelephone}
            autoComplete="off"
            margin="normal"
            /* variant="" */
            onChange={handleChange}
            validators={['required','isPhone']}
            errorMessages={[lang.formMessages.required,'ej: +54 111 1111111']}
            />

            <TextValidator
            label={`*${lang.demo.form.company}`}
            className={classes.input}
            disabled={queryState.isLoading}
            name={'applicantsCompany'}
            value={newDemoAplicant.applicantsCompany}
            autoComplete="off"
            margin="normal"
            /* variant="" */
            onChange={handleChange}
            validators={['required']}
            errorMessages={[lang.formMessages.required]}
            />

            <TextValidator
            label={`*${lang.demo.form.email}`}
            className={classes.input}
            disabled={queryState.isLoading}
            name={'applicantsEmail'}
            value={newDemoAplicant.applicantsEmail}
            autoComplete="off"
            margin="normal"
            /* variant="" */
            onChange={handleChange}
            validators={['required', 'isEmail']}
            errorMessages={[lang.formMessages.required,lang.formMessages.invalidEmail]}
            />
            <Button 
            type='submit' 
            className={classes.submitButton} 
            variant='contained' 
            color={'primary'}>{queryState.status === 'loading' ? <CircularProgress color={'light'}>
                </CircularProgress> : lang.actionTypes.apply}
            </Button>
{/*             <button type='button' onClick={trial}>prueba</button>*/}
    </ValidatorForm>
    )
}


const LoginForm = ({children}) => {

    const {lang} = useLangLabels()
    const classes = useStyles()
    const {data,login} = useLogin()
    const [form,setForm] = useState({
        email:'',
        password:''
    })

    //IMPUTS
    const handleInputs = (e) =>{
        /* setAuth({...auth,[e.target.name]:e.target.value}) */
        setForm({...form,[e.target.name]:e.target.value})
    }

    //enterTo Log
    const handleKeyPress = (event) => {
        /* if(event.key === 'Enter'){
            login(form)
        } */
    }
    

    return(
        <>
        <Switch>
                    <Route path={`/${USER_ROUTES.demoRequest}`}>
                <OnlineOfflineMode>
                            <Paper className={classes.paper} elevation={8}>
                            <Grid className={classes.center}>
                                <a target="link" href="http://tgdcompany.com"><img className={classes.img} src={Logo}/></a>
                            </Grid>
                                <RegisterBox/>
                            <Grid className={classes.tagLine}>
                                <p >TGD S.A © - 30 AÑOS  1990 - 2020 </p>
                                </Grid>
                                {children}
                            </Paper>
                </OnlineOfflineMode>
                    </Route>

            <Route path={`/`}>
                <Paper className={classes.paper} elevation={8}>
                    <Grid className={classes.center}>
                        <a target="link" href="http://tgdcompany.com"><img className={classes.img} src={Logo}/></a>
                    </Grid>
                    {(data.isError)? <Alert style={{width:'100%'}} severity='error'>Datos Incorrectos</Alert> : null}
                    <ValidatorForm  onSubmit={() =>login(form)} className={classes.loginForm}>
                    

                        <TextValidator
                        
                        disabled={data.isLoading}
                        id="email"
                        label={lang.login.form.label.user}
                        className={classes.input}
                        type="Email"
                        name="email"
                        autoComplete="off"
                        margin="normal"
                        variant="outlined"
                        onChange={(e)=>handleInputs(e)}
                        value={form.email}
                        required
                        color='secondary'
                        autoFocus={true}
                        onKeyUp={(e)=>handleKeyPress(e)}  
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><MailOutlineIcon color='secondary'/></InputAdornment>,
                        }} 
                        validators={['required']}
                        errorMessages={['Este campo es requerido']}
                        />
                        <TextValidator
                            disabled={data.isLoading}
                            id="pass"
                            label={lang.login.form.label.password}
                            className={classes.input}
                            type="password"
                            name="password"
                            autoComplete="off" 
                            margin="normal"
                            variant="outlined"
                            onChange={(e)=>handleInputs(e)}
                            value={form.password}
                            required
                            color='secondary' 
                            /* autoFocus={true} */
                            onKeyUp={(e)=>handleKeyPress(e)}  
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><LockIcon color='secondary'/></InputAdornment>,
                            }} 
                            validators={['required']}
                            errorMessages={['Este campo es requerido']}
                            />
                        
                        <Button  style={{color:'white'}} color='primary' type='submit' style={{margin:'16px 0',padding:'24px'}} variant="contained" to={'/admin'} >{data.isLoading ? <CircularProgress color={'inherit'} size={30} /> : <>{lang.actionTypes.login}</> }</Button>

                    </ValidatorForm>
                    <Grid className={classes.tagLine}>
                    <p >TGD S.A © - 30 {lang.login.legend.years}  1990 - 2020 </p>
                    </Grid>
                    {children}
                </Paper>
            </Route>
        </Switch>
        </>
    )
}


export default LoginForm
