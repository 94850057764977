import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TablePagination } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CheckBox } from '@material-ui/icons';
import AnyFiltering from './filters/AnyFiltering';
import { useSearch } from './../utils/hooks/useSearch';


export interface TgdTableInterface {
    label:string
    atribute: string
    component?:React.ReactNode
}

interface Props{
    items:any
    type:'equipment' | 'element' | 'component' | 'route' | 'supply' | 'new' | 'permission'
    headerAction?:React.ReactNode
    itemActions?:any
    title?:string
    height?: string | undefined | null
    columns:TgdTableInterface[]
    searchFilters?:string[] 
    selectable?:boolean
}

const TgdTable:React.FC<Props> = ({height,items,type,headerAction,itemActions,title,columns,searchFilters,selectable})=> {
    
    const rows = items || []

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(8);
    const {search,handleSearch,filteredItems} = useSearch(items,!!searchFilters ? searchFilters[0] :'')
    const [state,setState] = useState<any>()


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
      };
    
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    return (
    <>
        {/* <TgdListToolbar
        title={title ? title : ''}
        searchValue={search}
        handleSearch={handleSearch}
        actions={headerAction}
        searchBar={true}
        />  */}

        <AnyFiltering
             title={title ? title : ''}
             handleDataFiltered={setState}
             items={items}
             documentExportTitle={title || ''}
        />
        <br/>       
        {(items?.length < 1) ? <><Alert severity='warning'>No se han encontrado {title} en esta planta</Alert><br/></>:
        <TableContainer style={{height:height || '45vh'}} component={Paper}>
            <Table size="small" >
                <TableHead>
                    <TableRow>
                            {selectable &&
                                <TableCell ><CheckBox /></TableCell>
                            }
                            {columns &&
                            columns.map(({label},index:number) =>
                                <TableCell key={label+index+7825}>{label}</TableCell>
                            )} 
                            {type==='component' && <>
                                <TableCell >Rutas</TableCell>
                                <TableCell >Insumos</TableCell>
                            </>}

                            {itemActions && <TableCell align='right' width={160}>Más</TableCell>}
                    
                    </TableRow>
                </TableHead>
                <TableBody>
                    {state?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row:any,index:number) => (
                        <TableRow key={index*654}>
                            {columns.map(({atribute,component},index:number) => 
                            <TableCell key={atribute+index+123}>{component || row[`${atribute}`]}</TableCell>    
                            )}

                            <TableCell align='right'>
                                {itemActions && itemActions(row)}  
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>}
        <TablePagination
        rowsPerPageOptions={[8,25,100,200]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      
    </>);
    }

export default React.memo(TgdTable)

