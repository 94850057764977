import { useEffect, useState } from "react"
import { arrayItemPossition } from "../../../utils/arrayItemPosition"

export const useCarrousell = (item:any,items:any[],byTerm?:string)=>{

    const term =byTerm || (()=>{
        if(item.id) return 'id'
        if(item.tagTGD) return 'tagTGD'
        if(item.tagSTI) return 'tagSTI'
    })()

    const _pos = arrayItemPossition(item,items,term) 
    
    const [pos,setPos] = useState(_pos)
    const current = items[pos]
    
    
    useEffect(()=>{
        setPos(_pos)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },item)

    const possibleIncremet = items && pos < (items.length - 1)
    const possibleDecrement = pos > 0
    

    const handleNext = () =>{
        if(possibleIncremet){
            setPos(pos + 1)
        }
    }

    const handlePrev= () =>{
        if(possibleDecrement){
            setPos(pos - 1)
        }
    }

    const handleReset = () => {
        setPos(_pos)
    }

    return {
        current,
        possibleIncremet,
        possibleDecrement,
        handleNext,
        handlePrev,
        position:pos,
        term,
        handleReset
    }
}
