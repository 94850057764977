import React, {  useEffect, useState } from 'react';
import { Stepper, Step, StepLabel, Typography, Grid, Divider, List, ListItemAvatar, ListItemText, Avatar, AppBar, Toolbar, Drawer, Paper, ListItemSecondaryAction, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Chip } from '@material-ui/core'
import { FactoryInterface, useFactoriesByUser } from './services/service.factory';
import PageContainer from '../../components/pageContainers/PageContainer';
import CustomButton, { CustomIcon } from './../../components/buttons/CustomButton';
import FlexContainer from '../../components/presentational/FlexContainer';
import { useLangLabels } from './../language/services/useSetLabel';
import ListItem from '@material-ui/core/ListItem';
import { useModal } from './../../utils/hooks/useModal';
import { GridModelPlants, usePlantsByTagF } from '../plants/service.plant';
import { PlantInterface } from './../plants/service.plant';
import TgdTabs from '../../components/Tabs/TgdTabs';
import CreatePlantForm from '../plants/CreatePlantForm';
import CreateFactoryPage from './pages/CreateFactoryPage';
import EntityProperties from '../../components/presentational/show';
import { useTgdDrawerStyles } from '../../components/drawer/styles/useTgdDrawerStyles';
import theme from '../../infrastructure/materialUi/themeConfig';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { usePlantClients } from '../persons/services/service.person';
import { PersonInterface } from '../persons/interfaces/userInterfaces';

 
const PlantClients:React.FC<{tagFP:string}> = ({tagFP})=>{

    const {data:clients} = usePlantClients(tagFP)

    return <>
        {clients?.map((c:PersonInterface)=>
            <Chip
            style={{marginRight:'12px'}}
                label={c.email}
                icon={<CustomIcon icon={'user'} />} 
            />
        )}
    </>

}


  interface Props{
      activeStep:number
      steps:string[]
      children:(React.ReactNode)
  }

  const TgdStepper:React.FC<Props> = ({activeStep,steps,children}) => {
    return(<>    
        <Stepper activeStep={activeStep}>
            {steps.map((label, index) => 
                    <Step key={label}>
                        <StepLabel >{label}</StepLabel>
                    </Step>
            )}
        </Stepper>
        {children}
    </>)
  }


  const FactoryConfig:React.FC<{factory?:FactoryInterface,onClose:()=>void}> = ({factory,onClose}) => {

    const {lang} = useLangLabels()
    const classes = useTgdDrawerStyles({
        color:theme.typography.body1,
        drawerWidth:'50vw'
    })

    const {modal,handleModal} = useModal()
    const {data:Plants} = usePlantsByTagF(factory?.tagF)

    useEffect(()=>{
        factory && handleModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[factory])
    
    const handleClose = () => {
        handleModal()
        onClose()
    }


    //accordion
    const [expanded, setExpanded] = React.useState(0);

    const handleChange = (panel:any) => (event:any, isExpanded:any) => {
      setExpanded(isExpanded ? panel : false);
    };



    return(
        <Drawer className={classes.drawer} classes={{paper:classes.drawerPaper}} anchor={'right'} open={modal} onClose={handleClose}>
            <AppBar position='relative'>
                <Toolbar >
                    <Typography variant="h6" >
                        {factory?.name}
                    </Typography>
                </Toolbar>
            </AppBar>

            <PageContainer>
                <FlexContainer justify='space-between' >
                    <ListItem>
                        <ListItemAvatar>
                            <CustomIcon icon='map' />
                        </ListItemAvatar>
                        <ListItemText primary={factory?.location} secondary={lang.factories.location} />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <CustomIcon icon='helmet' />
                        </ListItemAvatar>
                        <ListItemText primary={factory?.referrer} secondary={lang.factories.referrer} />
                    </ListItem>
                    {/* <ListItem>//no se puede identificar al cliente principal
                        <ListItemAvatar>
                            <CustomIcon icon='user' />
                        </ListItemAvatar>
                        <ListItemText primary={'cliente'} secondary={'Usuario/cliente principal'} />
                    </ListItem> */}
                </FlexContainer>
            </PageContainer>
            <Divider/>
            {!factory 
            ? <CircularProgress size='24px' />
            : <TgdTabs
                padding='0px'
                {...{
                    items:Plants?.map((p:PlantInterface)=>({
                        name:p.name,
                        component:<>
                            <Accordion  expanded={expanded === 1} onChange={handleChange(1)} >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                {/*  <ListItem> */}
                                        <ListItemAvatar>
                                            <CustomIcon icon='info' />
                                        </ListItemAvatar>
                                        <ListItemText>
                                            Info
                                        </ListItemText>
                                {/*  </ListItem> */}
                                </AccordionSummary>
                                <Divider/>
                                <AccordionDetails>
                                    <EntityProperties entity={p} gridModel={GridModelPlants} />
                                </AccordionDetails>
                            </Accordion>

                            <Accordion  expanded={expanded === 2} onChange={handleChange(2)} >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                    {/* <ListItem> */}
                                        <ListItemAvatar>
                                            <CustomIcon icon='user' />
                                        </ListItemAvatar>
                                        <ListItemText>
                                            {lang.persons.clients}
                                        </ListItemText>
                                    {/* </ListItem> */}
                                </AccordionSummary>
                                <Divider/>
                                <AccordionDetails>
                                    <PlantClients tagFP={p.tagFP} />
                                </AccordionDetails>
                            </Accordion>

                        </>
                    })) || [],
                    customTabComponent:<CreatePlantForm factory={factory}/>,
                    popoverTitle:`${lang.actionTypes.create} ${lang.plants.singular.toLowerCase()}`,
                    icon:'add',
                    content:(value)=>value.component,
                    labelIterator:'name'
                }}
                />}
        </Drawer>
    )
  }

      

const FactoryList = () => {

    const {lang} = useLangLabels()
    const {data:factories,} = useFactoriesByUser()
    const [currentFactory,setCurrentFactory] = useState<FactoryInterface | undefined>()

    const handleFactoryConfig = (factory:FactoryInterface) => {
        setCurrentFactory(factory)
    }

    const handleFactoryConfigReset = () => {
        setCurrentFactory(undefined)
    }

    return (
        <Paper>
            <PageContainer>
                    <Grid container>
                        <Grid item xs={6} sm={6}>
                            <Typography variant='h6'>
                                Fábricas
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <FlexContainer justify='flex-end'>
                                <CreateFactoryPage/>
                            </FlexContainer>
                        </Grid>
                    </Grid>
                    <Divider style={{width:'calc(100% - 120px)'}}/>
                    <br />
                    <List>
                        {factories?.map((item:FactoryInterface)=>
                            <ListItem dense key={item.name} >
                                <ListItemAvatar>
                                    <Avatar variant='circle' >
                                        <CustomIcon icon='flag' />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={item.name} 
                                    secondary={`${lang.factories.referrer}: ${item.referrer}`} 
                                />
                                <ListItemSecondaryAction>
                                    <CustomButton  
                                        action={()=>handleFactoryConfig(item)}
                                        icon='config' variant='iconButton' 
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        )}
                </List>

            </PageContainer>

            <FactoryConfig 
                factory={currentFactory} 
                onClose={handleFactoryConfigReset} 
            />
        </Paper>
    );
}

export default FactoryList;
