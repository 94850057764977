import React, { useState } from 'react'
import SimpleAutocompleteSelect from '../../../components/autocomplete/SimpleAutocompleteSelect';
import { CrudFormType } from '../../../infrastructure/form/typescript/FormTypes';
import { useUser } from '../../persons/services/service.person'
import CULubricantForm from '../CULubricantForm';
import { useAllLubricantsByPlant, LubricantInterface } from './../services/service.lubricants';
import { useLangLabels } from './../../language/services/useSetLabel';
import { useEffect } from 'react';




type Props = {
    error?:string
    tagFP:string
    complete?:boolean
    onChange:(value:any) =>void
    defaultValue?:string
    disabled?:boolean
    by?:{
        key:string
        value:string
    }
}



const SelectLubricantBy:React.FC<Props> = ({error, tagFP, complete, onChange, defaultValue ,by, disabled}) => {

    const key='lubricant'
    const {lang} = useLangLabels()
    const {data:user} = useUser()
    const {data:lubricants} = useAllLubricantsByPlant(tagFP)
    const lubricantsBy = (by ? lubricants?.filter((l)=>l[by.key as keyof LubricantInterface] === by.value) : lubricants) || []
    const label = lang.lubricantForm.singular

    const handleChange = (value:string) => {
        if(complete){
            const lub = lubricantsBy.find((l)=>l[key] === value) as LubricantInterface
            onChange(lub)
        }else{    
            onChange(value)
        }
    }

    return(
    <SimpleAutocompleteSelect 
    {...{
        error,
        label,
        items:lubricantsBy,
        defaultValue,
        onChange:handleChange,
        showTitle:key,
        disabled
    }} >
        {user?.type !== 'O' && <CULubricantForm {...{tagFP,actionType:CrudFormType.create}} />}
    </SimpleAutocompleteSelect>)
}

export default SelectLubricantBy