import React from 'react'
import { Popover } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from '../../utils/hooks/useSnackbar';


const TgdSnackbar:React.FC= () => {

    const {modal, errors, severity, handleModal,position} = useSnackbar()

    return(
        <>
            <Popover
                open={modal} 
                onClose={handleModal}
                anchorReference="anchorPosition"
                anchorPosition={{ top: position?.positionY, left: position?.positionX }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <Alert onClose={handleModal} severity={severity}>
                    {errors}
                </Alert> 
            </Popover>
        </> 
    );
}

export default TgdSnackbar;
