import React from 'react';
import { Typography, ListItem, ListItemText, ListItemIcon, Divider, Badge } from '@material-ui/core';
import FlexContainer from '../../components/presentational/FlexContainer';
import ModalButton from '../../components/buttons/ModalButton';
import { InfoLubricationPointInterface } from './service/service.info';
import { useOperatorCriticalities } from '../colector/domains/criticalities/services/colector.service.criticalities';
import { LubricationPointInterface } from '../equipments/services/service.lubricationPoints';
import { CustomIcon } from '../../components/buttons/CustomButton';
import theme from '../../infrastructure/materialUi/themeConfig';
import PendingSuppliesToAssign from './PendingSuppliesToAssign';
import TgdPopover from '../../components/popovers/TgdPopover';
import { GridModelInterface } from '../../infrastructure/Interfaces';
import { gridModelCriticality } from '../criticalities/services/service.criticality';
import EntityProperties from '../../components/presentational/show';


interface Props{
    type:'modal' | 'content' | 'resume'
    item: LubricationPointInterface 
}




const EquipmentsInfo:React.FC<Props> = ({type,item}) => {

    
    const {data:criticalities} = useOperatorCriticalities()
    const assetImportance = criticalities?.filter((criticality)=>criticality.description === item.criticality)[0]
    const assetCriticality = criticalities?.filter((criticality)=> criticality.description === item.info?.assetCriticality)[0]
 
    const info:InfoLubricationPointInterface | undefined = item?.info

    const supplyQuantity = info?.supplies.filter((s)=>s !== 'SIN INSUMOS').length
    
 
    const content = (!!info && !!criticalities) && <>
   
        <ListItem>
            <ListItemText secondary={item.tagTGD}>
                <Typography variant='body2'><span>PUNTO:</span></Typography>
                <Typography variant='body1'> {item.component} - {item.element} - <strong>{item.plantTag}</strong> </Typography>
            </ListItemText>

            <ListItemIcon>
                <FlexContainer >

                <TgdPopover 
                strongTitle={true}
                title={'Puntuación general del activo.'}
                mode='hover'
                hoverChildren={<EntityProperties gridModel={gridModelCriticality} entity={assetCriticality} />}    
                children={
                    <FlexContainer 
                    margin='4px'
                    borderRadius='8px' 
                    padding='8px' 
                    height='48px' 
                    width='150px'  
                    background={assetCriticality?.color!} 
                    align='center' 
                    justify='center' 
                    color='white'>
                        <Typography color='inherit' align='left'>{assetCriticality?.description!} </Typography>
                        <Typography color='inherit' align='left' variant='h6'>{assetCriticality?.number!} </Typography>
                    </FlexContainer>
                    }
                />

                <TgdPopover 
                strongTitle={true}
                title={'Importancia del activo'}
                mode='hover'
                hoverChildren={<EntityProperties gridModel={gridModelCriticality} entity={assetImportance} />}    
                children={
                    <FlexContainer 
                    margin='4px'
                    borderRadius='8px' 
                    padding='8px' 
                    height='48px' 
                    width='150px'  
                    background={assetImportance?.color} 
                    align='center' 
                    justify='center' 
                    color='white'>
                        <Typography color='inherit' align='left'>{assetImportance?.description!} </Typography>
                        <Typography color='inherit' align='left' variant='h6'>{assetImportance?.number} </Typography>
                    </FlexContainer>
                }
                />

                </FlexContainer>
            </ListItemIcon>

        </ListItem>
        <Divider/>
        <ListItem>
            <Typography variant='button'><strong>ULTIMO ESTADO DEL EQUIPO</strong></Typography>
        </ListItem>
        <Divider/>
        <FlexContainer >
            <ListItem >
                <ListItemIcon >
                    <CustomIcon style={{color:info.leak ? theme.palette.error.main : theme.palette.success.main}} icon='leak'/>
                </ListItemIcon>
                <ListItemText  primary={info.leak ? 'Con Perdida' : 'Sin pérdida'} />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <div>
                        <Typography style={{paddingLeft:'10px'}} variant='h6'>
                            <strong>
                                {info.lubricantQuantity}
                            </strong>
                        </Typography>
                    </div>
                </ListItemIcon>
                <ListItemText secondary='ULTIMO AGREGADO' primary={item.measureUnit} />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <CustomIcon style={{color: info.pending? theme.palette.error.main : theme.palette.success.main}} icon='pending' />
                </ListItemIcon>
                <ListItemText  primary={info.pending ? 'Pendiente' : 'Al Día'}/>
            </ListItem>
        </FlexContainer>
        <FlexContainer >
            <ListItem>
            <ListItemIcon>
                    <CustomIcon icon={info.updatedToday ? 'check' : 'close' }  />
                </ListItemIcon>
                <ListItemText secondary='SE LO INTERVINO HOY' primary={info.updatedToday ? 'SI' : 'NO'} />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <CustomIcon  icon='calendar' />
                </ListItemIcon>
                <ListItemText secondary='ULTIMA INSPECCION' primary={info.lastInspectionDate.substring(0, 10)} />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <CustomIcon style={{color: item.info?.inaccessible ? theme.palette.error.main : theme.palette.success.main}} icon={item.info?.inaccessible ? 'error' : 'success'} />
                </ListItemIcon>
                <ListItemText primary={item.info?.inaccessible ? 'Inaccesible' : 'Accesible'} />
            </ListItem>
        </FlexContainer>
        <FlexContainer >
            <ListItem>
                <ListItemIcon>
                    <CustomIcon  icon='calendar' />
                </ListItemIcon>
                <ListItemText secondary='ULTIMA LUBRICACION' primary={info.lastLubrication.substring(0, 10)} />
            </ListItem>
            <ListItem>
                <ListItemIcon>      
                    <CustomIcon style={{
                        fill: 'red orange'
                    }} icon='temperature'  />
                </ListItemIcon>
                <ListItemText secondary='ULTIMA TEMPERATURA' primary={`${info.temperature}º`} />
            </ListItem>
            <ListItem>
                <ListItemIcon>      
                   <PendingSuppliesToAssign lubricationPoint={item} />
                </ListItemIcon>
                <ListItemText secondary='FALTANTES' primary={supplyQuantity === 0 ? 'SIN INSUMOS' : 'INSUMOS'} />
            </ListItem>
        </FlexContainer>
        <FlexContainer>
            <ListItem>
                <ListItemIcon>      
                   <CustomIcon icon='capacity' />
                </ListItemIcon>
                <ListItemText secondary='CAPACIDAD' primary={`${item.capacity} ${item.measureUnit}`} />
            </ListItem>
            <ListItem>
               {/* empty */}
            </ListItem>
            <ListItem>
                 {/* empty */}
            </ListItem>
        </FlexContainer>
    </>

    const modal = 
    <ModalButton fullwidth={true} icon='more' variant='iconButton' color='inherit' popoverTitle='Información del Equipo'>
        {content}
    </ModalButton>



    return (
        (!!item && !!criticalities) ?
        <>
            {type === 'content' && content }
            {type === 'modal'  && modal }
                
            {type === 'resume'  && <>
                <Typography component={'div'} color='inherit' style={{width:'100%'}}>
                   <FlexContainer align='center' justify='flex-end'>
                        {type==='resume' && `${item.component} `}
                        {type==='resume' && `| ${item.element} `}
                        {type==='resume' && `| ${item.plantTag} |`}
                        {modal}
                   </FlexContainer>
                </Typography>
            </>}

        </>
        :null
    );
}

export default React.memo(EquipmentsInfo)
