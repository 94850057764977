export const useDate = () => {
    const date = new Date()
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    const nowDate = () => {
        if(month < 10){
            return `${day}-0${month}-${year}`
        }else{
            return `${day}-${month}-${year}`
        }
    }

    return {
        nowDate,
        nowDay:(day < 10) ? `0${day}` : day,
        nowMonth:(month < 10) ? `0${month}` : month,
        nowYear:year,
    }
}