import axios, { AxiosRequestConfig } from "axios"
import { useEffect, useState } from "react"
import { MutationResult, QueryCache, queryCache, QueryResult, QueryStatus, useMutation, useQuery } from "react-query"
import { URL } from "../../../infrastructure/appConfig/appConfig"
import { GridModelInterface, MutationQueryObject } from "../../../infrastructure/Interfaces"
import { EquipmentInterface, LubricationPointInterface, useEquipmentsByRoute } from "../../equipments/services/service.lubricationPoints"
import { useOperatorSupervisorTagFP } from "../../persons/services/service.person"
import { useToken } from "../../session/service.session"
import { objectMapper } from './../../../utils/objectMapper';

//----------------------------
//interfaces
//---------------------------

type RouteStateType =  'ATRASADA' | 'INCUMPLIDA' | 'PROGRAMADA' | 'PROGRAMADA INCUMPLIDA'

export interface RouteInterface{
    id?:number
    operator:string
    periodicity:string
    route:string
    routeName:string
    startDate:string
    tagFP:string
    lubricationPoints?:string
    state?:RouteStateType
}

export interface AssignCancelRouteInterface{
    route:string
    equipment:string
    state: 'C' | 'A'
    tagFP:string
}

export interface LastDelayedRoutesInterface {
    equipments: string
    id?: number
    loaded: boolean
    newScheduledDate: string
    scheduledDate: string
    route: string
    startDate: string
    state: RouteStateType
    special:boolean
    tagFP:string
}

export const gridModelRoutes:GridModelInterface = {
    /* renderOption: {label:'Acciones'}, */
    id: {label:'Id'},
    routeName: {label:'Nombre'},
    route: {label:'Ruta'},
    operator: {label:'Nº operador'},
    periodicity: {label:'Periodicidad'},
    tagFP: {label:'Planta'},
    startDate: {label:'Fecha'},
    state: {label:'Estado'},
    scheduledDate: {label:'Fecha programada'},
    newScheduledDate: {label:'Nueva programación'},
    special:{label:'Ruta especial',options:[{name:'Especial',value:true},{name:'Normal',value:false},{name:'Todas',value:undefined}]}
}

export const RouteCRUDDependencies = [
    'RoutesByTagFPState',
    'LastDelayedRoutes',
    'NextScheduledRoutes',
    'LastScheduledNotDone',
    'RoutesByTagFPState',
    'EquipmentsByTagFP',
    'LubricationPointByTagTGD',
    'DaoEquipmentsByTagFP',
    'DaoEquipmentsElementsByTagFP',
    'PlantRoutes',
    'EquipmentsByRoute',
    'AllLubricationPointsAndInfoByTagFP',
]



//----------------------------
//axios
//---------------------------

export const RoutesByTagFP = (_:string,tagFP:string,token:string) => {
    axios.defaults.headers.get['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.get['tagFP'] = tagFP
    const config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/RoutesByTagFP`,
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const CreateRoute = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`

    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateRoute`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}
export const UpdateRoute = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`

    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateRoute`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const DeleteRoute = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`

    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/DeleteRoute`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const SetNewScheduleDate = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`

    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/SetNewScheduleDate`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const AssignCancelRoute = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`

    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/AssignCancelRoute`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const LastDelayedRoutes = (_:string,tagFP:string,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`

    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/LastDelayedRoutes`,
        data:{
            tagFP:tagFP
        }
        
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}
export const LastScheduledNotDone = (_:string,tagFP:string,token:string) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`

    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/LastScheduledNotDone`,
        data:{
            tagFP:tagFP
        }
        
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}
export const NextScheduledRoutes = (_:string,tagFP:string,token:string) => {
    axios.defaults.headers.get['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.get['tagFP'] = tagFP

    const config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/NextScheduledRoutes`,
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

const RoutesByDay = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/RoutesByDay`,
        data
    }

    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

const NearlyRouteDate = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/NearlyRouteDate`,
        data
    }

    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}



//----------------------------
//USeQUery
//---------------------------
export const useRoutesByTagFP = (tagFP?:string):QueryResult<RouteInterface[]> => {
    const token= useToken()
    return useQuery<RouteInterface[]>(['PlantRoutes',tagFP,token],RoutesByTagFP,{
        enabled:!!tagFP,
        refetchOnWindowFocus:false
    })
}

//PendingRoutes

export const useNearlyRouteDate = (tagFP?:string):[(routeNumber:string)=>Promise<any>,MutationResult<string>] => {
    const SupOpTagFP = useOperatorSupervisorTagFP()
    const token= useToken()
    const queryTagFP = tagFP || SupOpTagFP

    const [mutation,data] = useMutation(NearlyRouteDate)

    const getScheduledDate = (routeNumber:string) => {
        return mutation({
            token,
            data:{
                tagFP:queryTagFP,
                route:routeNumber
            }
        })
    }

    return [getScheduledDate,data]

}

export const useLastDelayedRoutes = (tagFP?:string) => {
    const SupOpTagFP = useOperatorSupervisorTagFP()
    const token= useToken()
    const queryTagFP = tagFP || SupOpTagFP 
    return useQuery<LastDelayedRoutesInterface[]>(['LastDelayedRoutes',queryTagFP,token],LastDelayedRoutes,{
        enabled:!!queryTagFP,
        staleTime:5000,
        refetchOnWindowFocus:false
    })
}
export const useLastScheduledNotDone= (tagFP?:string) => {
    const SupOpTagFP = useOperatorSupervisorTagFP()
    const token= useToken()
    const queryTagFP = tagFP || SupOpTagFP 
    return useQuery<LastDelayedRoutesInterface[]>(['LastScheduledNotDone',queryTagFP,token],LastScheduledNotDone,{
        enabled:!!queryTagFP,
        staleTime:5000,
        refetchOnWindowFocus:false
    })
}
export const useNextScheduledRoutes= (tagFP?:string) => {
    const SupOpTagFP = useOperatorSupervisorTagFP()
    const token= useToken()
    const queryTagFP = tagFP || SupOpTagFP 
    return useQuery<LastDelayedRoutesInterface[]>(['NextScheduledRoutes',queryTagFP,token],NextScheduledRoutes,{
        enabled:!!queryTagFP,
        staleTime:5000,
        refetchOnWindowFocus:false,
    })
}

export const useRoutesByTagFPAndState = (tagFP?:string) => {
    const SupOpTagFP = useOperatorSupervisorTagFP()
    const queryTagFP = tagFP || SupOpTagFP 
    const { data:routesByTagFP } = useRoutesByTagFP(queryTagFP)
    const { data:lastDelayedRoutes } = useLastDelayedRoutes(queryTagFP)
    const { data:lastScheduledNotDone } = useLastScheduledNotDone(queryTagFP)
    const { data:nextScheduledRoutes } = useNextScheduledRoutes(queryTagFP)

    const lastScheduledNotDoneMapp = objectMapper(lastScheduledNotDone,'route')
    const delayedRoutesMapp = objectMapper(lastDelayedRoutes,'route')
    const nextScheduledRoutesmapp = objectMapper(nextScheduledRoutes,'route')
    
    return useQuery('RoutesByTagFPState',()=>{
        return routesByTagFP?.map((item)=>({
            ...item,
            //add lastDelayedRoutes && lastScheduledNotDone && nextScheduledRoutesmapp
            state:delayedRoutesMapp[item.routeName]?.state || lastScheduledNotDoneMapp[item.routeName]?.state || nextScheduledRoutesmapp[item.routeName]?.state  || 'AL DIA' ,
            newScheduledDate: (delayedRoutesMapp[item.routeName]?.newScheduledDate || lastScheduledNotDoneMapp[item.routeName]?.newScheduledDate || nextScheduledRoutesmapp[item.routeName]?.newScheduledDate)?.slice(0,-19) ,
            scheduledDate: (delayedRoutesMapp[item.routeName]?.scheduledDate || lastScheduledNotDoneMapp[item.routeName]?.scheduledDate || nextScheduledRoutesmapp[item.routeName]?.newScheduledDate)?.slice(0,-19) ,
        }))
    },{
        enabled:!!delayedRoutesMapp && !!lastDelayedRoutes && !!lastScheduledNotDoneMapp && !!nextScheduledRoutesmapp
    })
}

//----------------------------
//mutations
//---------------------------



export const useRoutesByDate = (tagFP?:string):[(item:string)=>Promise<any>,MutationResult<RouteInterface[]>] => {

    const supOpTagFP = useOperatorSupervisorTagFP()
    const token  = useToken()
    const [mutation,queryData] = useMutation(RoutesByDay)

    const getRoutes = (date:string):Promise<any> => {
        return mutation({
            token,
            data:{
                date,
                tagFP:tagFP || supOpTagFP
            }
        })
    }

    return [getRoutes,queryData]

}


export const useSetNewScheduleDate = ():[(data:{scheduledDate:string,newScheduledDate:string,route:string,tagFP:string})=>void,MutationResult<any>] => {
    const token = useToken()

    const [mutation,queryData] = useMutation(SetNewScheduleDate,{
        onSuccess:(data)=>{

            //hydrate
            queryCache.setQueryData('RoutesByTagFPState',(old:any)=>{
                return old.map((item:any)=>{
                    if(item.routeName === data.route){
                        return({
                            ...item,
                            ...data
                        })
                    }
                    else return(item)
                })
            })

            //Security petition
            queryCache.invalidateQueries('LastDelayedRoutes')
            .then(()=> queryCache.invalidateQueries('NextScheduledRoutes'))
            .then(()=> queryCache.invalidateQueries('LastScheduledNotDone'))
            .then(()=> queryCache.invalidateQueries('RoutesByTagFPState'))
        }
    })

    const scheduleRoute = (data:{scheduledDate:string,newScheduledDate:string,route:string,tagFP:string}):void => {
        mutation({
            token,
            data
        })
    }
    return [scheduleRoute,queryData]
}

export const useCreateRoute = () => {
    return useMutation(CreateRoute,{
        onSuccess:()=>{
            queryCache.invalidateQueries('PlantRoutes')
        }
    })
}

export const useUpdateRoute = () => {
    return useMutation(UpdateRoute,{
        onSuccess:()=>{
            queryCache.invalidateQueries('PlantRoutes')
        }
    })
}

export const useDeleteRoute = () => {
    return useMutation(DeleteRoute,{
        onSuccess:()=>{
            queryCache.invalidateQueries('PlantRoutes')
        }
    })
}

export const useAssignCancelRoutes = () => {
    const token = useToken()
    const [assignCancelRoute,{data,status,error,reset}] = useMutation(AssignCancelRoute,{
        onSuccess:()=>{
            queryCache.invalidateQueries('EquipmentsByTagFP')
            queryCache.invalidateQueries('LubricationPointByTagTGD')
            queryCache.invalidateQueries('DaoEquipmentsByTagFP')
            queryCache.invalidateQueries('DaoEquipmentsElementsByTagFP')
            queryCache.invalidateQueries('PlantRoutes')
            queryCache.invalidateQueries('EquipmentsByRoute')
            queryCache.invalidateQueries('AllLubricationPointsAndInfoByTagFP')
        }
    })
    const assignMultipleRoutes = (multipleObjects:AssignCancelRouteInterface[]) => {
        assignCancelRoute({
            data:multipleObjects,
            token
        })
    }
    const cancelMultipleRoutes = (multipleObjects:AssignCancelRouteInterface[]) => {
        assignCancelRoute({
            data:multipleObjects,
            token
        })
    }

    const assign = (equipment:LubricationPointInterface,route:string) => {
        assignCancelRoute({
            data:[{
                route:route,
                equipment:equipment.tagTGD,
                state:'A',
                tagFP:equipment.tagFP
            }],
            token
        })
    }

    const cancel = (equipment:LubricationPointInterface,route:string) => {
        assignCancelRoute({
            data:[{
                route:route,
                equipment:equipment.tagTGD,
                state:'C',
                tagFP:equipment.tagFP
            }],
            token
        })
    }
    
    return{
        assign,
        cancel,
        assignMultipleRoutes,
        cancelMultipleRoutes,
        data,
        status,
        error,
        reset
    }

}



interface DeleteRoutesErrors {
    equipments?:EquipmentInterface[] | undefined
}

export const useDeleteRouteVerification = () => {
    const token = useToken()
    const [deleteItem,{status:deleteStatus,data}] = useDeleteRoute()
    const [status, setStatus] = useState<QueryStatus>(QueryStatus.Idle)
    const [validationRoute,setValidationRoute] = useState<RouteInterface | {} |undefined >()

    const {data:equipmentsByRoute,status:equipmentsByRouteStatus} = useEquipmentsByRoute(validationRoute)
    const [errors, setErrors] = useState<DeleteRoutesErrors>({})

    const validate = (item:RouteInterface) => {
        setStatus(QueryStatus.Loading)
        if(!equipmentsByRoute) setValidationRoute(item)
        else handleDelete()
    }

    const handleDelete= () =>{
        if(equipmentsByRouteStatus === 'success'){
            if((equipmentsByRoute && equipmentsByRoute.length > 0)) {
                setErrors({equipments:equipmentsByRoute})
                setStatus(QueryStatus.Error)
                setValidationRoute(undefined)
            }
            else{
                deleteItem({
                    data:validationRoute,
                    token
                })
                .then(()=>{
                    setStatus(QueryStatus.Success)
                    setValidationRoute(undefined)
                })
                .catch(err=>console.log(err))
            }
        }
    }


    
    useEffect(() => {
        handleDelete()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[equipmentsByRouteStatus])

    return  {
        errors,
        status,
        validate,
        data
    }

}

export const useAssignMultipleRoutesToMultipleEquipments = () => {

    const {assignMultipleRoutes,status,data,reset,error} = useAssignCancelRoutes()
    const [assignationItems,setAssignationItems] = useState<AssignCancelRouteInterface[]>([])
  
    const handleAssigCancel = (equipments:EquipmentInterface[],routes:RouteInterface[],state:'A'|'C') => {
        setAssignationItems([])
        equipments.forEach(e => {
            routes.forEach(r => {
                setAssignationItems((assignationItems) =>[
                        ...assignationItems,
                        {
                            route:r.routeName,
                            equipment:e.tagTGD,
                            state:state,
                            tagFP:e.tagFP
                        }
                ])
            })
        })
    }

    useEffect(() => {
        if(assignationItems.length > 0){
            assignMultipleRoutes(assignationItems)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[assignationItems])
    
    return{
        handleAssigCancel,
        error,
        status,
        objectQuery:assignationItems,
        data,
        reset
    }
}