import { useUser } from '../../../domains/persons/services/service.person';




export const useLubricationPointFieldRestrictions = () =>{
    const {data:user} = useUser()

    switch (user?.type) {
        case 'O':
            return [
                'type',
                'criticality',
                'state',
                'egressDate',
                'lowReason',
                'admissionDate',
                'brand',
                'capacity',
                'function',
                'id',
                'location',
                'model',
                'observations',
                'review',
                'routes',
                'tagTGD',
            ]

        default:
            return []
    }
}