import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

const useStyles = makeStyles((theme) => createStyles({
appBar: {
	position: 'relative',
},
title: {
	marginLeft: theme.spacing(2),
	flex: 1,
},

}))

const Transition = React.forwardRef(function Transition(
props: TransitionProps & { children?: React.ReactElement },
ref: React.Ref<unknown>,
) {
return <Slide direction="up" ref={ref} {...props} />;
});



interface Props{
	modal:boolean
	children?:React.ReactNode
}

const FullScreenSurface:React.FC<Props> = ({modal,children}) => {

const classes = useStyles();




return (
	<>
	<Dialog fullScreen open={modal} TransitionComponent={Transition}>
		{children && children}
	</Dialog>
	</>
);
}


export default FullScreenSurface