import React, { useEffect, useMemo } from 'react';
import { QueryStatus } from 'react-query';
import DefaultFormLayout from '../../../components/forms/DefaultFormLayout';
import { TextField } from '@material-ui/core';
import { FieldValues } from 'react-hook-form';
import FormSelector from '../../form-config/FormSelector';
import { useCreateSensorType, useUpdateSensorType } from './services/SensorTypeService';
import { SensorTypeInterface } from './interfaces/SensorTypeInterfaces';
import { useSensorTypeForm } from './services/useSensorTypeForm';
import ModalList from '../../../components/modals/modalList/ModalList';
import { useState } from 'react';




const UpdateSensorTypeForm:React.FC<{tagFP:string,item:SensorTypeInterface,items:SensorTypeInterface[]}> = ({tagFP,item,items}) => {

    const [current,setItem] =useState()
    const {setValue,handleSubmit,watch,errors,form} = useSensorTypeForm({actionType:'update',defaultValue:current},tagFP)
    const {updateSensorType,data} = useUpdateSensorType()

    const submit = (fields:SensorTypeInterface) => {
        updateSensorType(fields)
    }

    return (
        <ModalList icon='edit'  onChange={setItem} item={item} items={items} title={form.name}>
            <DefaultFormLayout 
            status={data.status}
            message={data.message}
            error={data.error} 
            mode='content'
            icon={'edit'}
            variant='iconButton' 
            buttonTitle={form.name} 
            popoverTitle={form.name} 
            onChange={handleSubmit(submit)} >
                {form.fields?.map(field=>
                    <FormSelector
                    key={field.name}
                    selector={field.formSelector}
                    fieldProps={{
                        type:field.type,
                        error:errors && errors[field.name]?.message,
                        label:field.label,
                        value:watch(field.name,field.initialValue),
                        onChange:(value)=>setValue(field.name,value),
                    }}
                    />    
                    )}
            </DefaultFormLayout>
        </ModalList>
    );
}

export default React.memo(UpdateSensorTypeForm);
