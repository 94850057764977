import axios from "axios"
import { useQuery } from "react-query"
import { URL } from "../../../infrastructure/appConfig/appConfig"
import { uiDictionary, uiDictionaryArray,uiDictionaryKeyTranslate } from "../../dictionaries/uiDictionary"
import { useToken } from "../../session/service.session"
import { SensorType } from "../interfaces/interfaces"
import { SensorizedInfo } from "../interfaces/sensorRealtimeInterface"
import { STISensorizedEquipmentInterface } from "../STISensorizedEquipments/services/STISensorizedEquipmentsInterfaces"


export interface surveyDataInterface {
    topic:string,
    sensorType:SensorType,
    limits:{
        [key:string]:{
            max:number,
            min:number
        }
    }
    surveyValues:{
        [key:string]:number
    }
}

export const statusValues = {
    danger:'red',
    warning:'orange',
    normal:'green',
    unset:'gray'
} 

//priority of status danger - warning - normal -unset
const statusPriority = (status:string) => {
    if(status === statusValues.danger) return status
    if(status === statusValues.warning) return status
    if(status === statusValues.normal) return status
    return status
}

//setStatus from range limits
const calculateSensorStatus = (sensorValue?:number,limits?:{max:number,min:number}) =>
sensorValue && limits ? (
    (sensorValue > limits?.max)
        ? statusValues.danger
        : (sensorValue <= limits?.min)
                ? statusValues.danger
                : statusValues.normal
) : statusValues.unset



//dictionary keys of interest from sensors
const sensorizedValueKeys = uiDictionaryArray.reduce((acc,term) => {
    const [key,value] = Object.entries(term)[0]
    if(acc.includes(value[1])) return [...acc]
    return [...acc,value[1]]
},[''])

//sensor key ordered of topic types from dictionary
const sensorsvalueKeys = uiDictionaryArray.reduce((acc,term) => {
    const [key,value] = Object.entries(term)[0]
    if(acc.includes(key)) return [...acc]
    return [...acc,key]
},[''])

export const useSensorStatus = (sensorData:SensorizedInfo) => {
    
    const {sensorSurvey:_sensorSurvey,sensorAddress,sensorizedEquipment,lastTime,cleanAddress} = sensorData
    const sensorSurvey = _sensorSurvey && _sensorSurvey[sensorAddress]
    const sensorType = sensorizedEquipment?.sensorType
    const batteryLevel = sensorSurvey?.battery_level

     //battery array with color status to show Batterytatus
     const batteryStatus:string[] = 
     batteryLevel 
         ? batteryLevel <= 2.7 
             ? batteryLevel <= 2.4 
                 ? [statusValues.danger] 
                 : [statusValues.warning,statusValues.warning] 
             : [statusValues.normal,statusValues.normal,statusValues.normal]
         : [statusValues.unset,statusValues.unset,statusValues.unset]

    //adding data to object data constructed object
    const surveyValues = sensorSurvey &&

    Object.entries(sensorSurvey)

    .filter(([key,value])=>sensorsvalueKeys.includes(key))
    .map(([k,v])=>{
        return {
            name:uiDictionary[k][1],
            key:k,
            value:v
        }
    }).reduce((acc,item)=>{
        if(acc.names?.includes(item.name)){
            return {
                ...acc,
                val:{
                    ...acc.val,
                    [item.name]:{
                        ...acc.val[item.name],
                        [uiDictionaryKeyTranslate(item.key)]:item.value
                    }
                }
            }
        } 
        return {
            names:[...acc.names,item.name],
            val:{
                ...acc.val,
                [item.name]:{
                    [uiDictionaryKeyTranslate(item.key)]:item.value
                }
            }
        }
    },Object.create({names:[],val:[]})).val

    //console.log(surveyValues)
   


    //construct object from survey translation attributes
    const surveyData: surveyDataInterface[] | undefined = surveyValues && sensorizedEquipment &&
    Object.entries(sensorizedEquipment)
    .filter(([key,value])=> (sensorizedValueKeys.includes(key) && !!value))
    .map((item)=>({
        topic:item[0],
        sensorType:sensorType,
        limits:JSON.parse(item[1]),
        surveyValues:surveyValues[item[0]]
    }))
    
    //console.log(surveyData)

    //all data to show status on card- color,and values
    const sensorStatus = surveyData?.map((item)=>{
        const {limits,surveyValues:values} = item

        //console.log(limits)npm 
        const status = values && Object.entries(limits).map(([name,value])=>({
            name,
            status:calculateSensorStatus(values[name],value),
            sensorValue:values[name]
        }))

        return {
            ...item,
            status,
            batteryLevel
        }
    }) 

    //sensor global status from sensor to identify anomaly in some of his topics
    const globalStatus =  sensorStatus?.map((topic) => {
        return topic.status?.reduce((acc,item)=>{
            return statusPriority(item.status)
        },statusValues.unset)
    })?.reduce((acc,item)=>{
        return statusPriority(item)
    },statusValues.unset) 


   
   


    // labels forCharts

    const sensorTypeChartLabels = sensorizedValueKeys.filter((label)=>!!label)

    const surveyChartData:{[key:string]:number[]} | undefined = sensorStatus?.map((item)=>{
        let result = {}
        item.status?.forEach(({name,sensorValue})=>{
            result = {
                ...result,
                [name]:[sensorValue]
            }
        })
        return result
    }).reduce((acc,item)=>{
        return {
            ...acc,
            ...item,
        }
    },{}) 

    return {
        lastTime,
        surveyValues,
        sensorStatus,
        globalStatus,
        batteryStatus,
        sensorSurvey,
        sensorType,
        sensorTypeChartLabels,
        surveyChartData,
        sensorAddress,
        cleanAddress,
        sensorizedEquipment
    }

}