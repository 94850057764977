import { Tabs, Tab, TabsProps, Badge } from '@material-ui/core';
import React, { useState } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import FlexContainer from '../presentational/FlexContainer';


function a11yProps(index: any) { 
	return {
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `scrollable-auto-tabpanel-${index}`,
	};
}

interface TabItemsInterface {
    label:string,
    component:React.ReactNode
    badge?:number
}

interface Props{
    badge?:boolean
    defaultPath?:string
    /* icon?:CustomIconType, */
    popoverTitle?:string,
    items:TabItemsInterface[],
    content:(items?:any,defaultPath?:string)=>React.ReactNode,
/*     labelIterator:string,
 */    /* customTabComponent?:React.ReactNode, */
    props?:TabsProps
    exact?:boolean
}

const TgdRouterTabs:React.FC<Props> = ({items, props, defaultPath, content, badge, exact}) => {

    const _defaultPath = defaultPath || '/'
    const [value, setValue] = useState(0)

    const tabLabel = (labelText:string, badgeNumber?:number) => badge 
    ?   <Badge badgeContent={badgeNumber} color="primary">
            <FlexContainer padding='0px 16px'> {labelText} </FlexContainer> 
        </Badge> 
    :   labelText

    const makePath = (item:TabItemsInterface,index:number) => {
        return index === 0 
        ? _defaultPath 
        : `${defaultPath 
            ? _defaultPath 
            : ''}${item.label.split(' ').join('-')}/`
    }

    return (

        
        <Route
            path={_defaultPath}
            render={({location})=> <>
                <Tabs
                {...props}
                value={value}
                onChange={(e,newValue)=>setValue(newValue)}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                >
                    {/* Tabs */}
                    {items && items.map((item,index) => 
                        <Tab 
                        key={index} 
                        component={Link}
                        to={makePath(item,index)}
                        label={tabLabel(item.label,item.badge)} 
                        {...a11yProps(index)} />
                    )}
                </Tabs>
                <Switch>
                    {items && items.map((item,index) => 
                    <Route key={`${index}`} exact={index === 0 ? true : false} path={makePath(item,index)}>
                        {/* <PageContainer padding='24px 0px'> */}
                            <br />
                            {content(item)}
                        {/* </PageContainer> */}
                    </Route>
                    )}  

                </Switch>
                </>
            }
        />
  
    );
}

export default TgdRouterTabs;
