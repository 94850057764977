import React from 'react';
import { PlantInterface } from '../../plants/service.plant';
import DeleteComponent from '../DeleteComponent';
import {  gridModelComponent } from '../services/service.components';
import AnyGrid from '../../../components/grids/AnyGrid';
import { usePlantComponents } from '../services/service.components';
import { useLangLabels } from '../../language/services/useSetLabel';
import { CarrousellActionInterface } from './../../../components/grids/AnyGrid';
import CUComponentForm from './../CUComponentForm';
import { CrudFormType } from '../../../infrastructure/form/typescript/FormTypes';
import { ComponentInterface } from '../types/ComponentTypes';

export interface Props{
    plant:PlantInterface
}

const carrousellActions :CarrousellActionInterface[] = [
    {icon:'edit',component:(item:ComponentInterface)=><CUComponentForm tagFP={item.tagFP} item={item} actionType={CrudFormType.update} />}
]

const ManageComponentPage:React.FC<Props> = ({plant}) => {

    const {lang} = useLangLabels()
    const {data:components} = usePlantComponents(plant.tagFP)
    return(<>
    
        <AnyGrid 
        gridModel={gridModelComponent}
        title={lang.components.title.plural}
        items={components || []}
        loading={components ? false : true}
        height='55vh'
        carrousellActions={carrousellActions}
        itemActions={(item:any,items:any)=><>
            <DeleteComponent item={item} />
        </>}
        headerActions={(item:any)=><CUComponentForm tagFP={plant.tagFP} actionType={CrudFormType.create} />}
        />

    </>)
}

export default React.memo(ManageComponentPage);
