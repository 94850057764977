import { CircularProgress, IconButton } from '@material-ui/core'
import React from 'react'
import { LubricationPointInterface } from '../../equipments/services/service.lubricationPoints';
import { useAssignCancelSupplies } from '../services/service.supplies';
import { SupplyInterface } from '../types/SupplyTypes';
import { CustomIcon } from './../../../components/buttons/CustomButton';




const AssignCancelSupply:React.FC<{equipment:LubricationPointInterface | any,supply:SupplyInterface}> = ({equipment,supply}) =>{
    
    const {assign,cancel,status} = useAssignCancelSupplies()

    return(
        status === 'loading' 
            ? <CircularProgress size='16px'/>                
            :   equipment.supplies.includes(supply.supply)
                ?   <>
                        <IconButton  size='small' disabled >
                            <CustomIcon icon='check' style={{color:'green'}}/>
                        </IconButton>
                        <IconButton onClick={()=>cancel(equipment,supply.supply)} size='small'>
                            <CustomIcon icon='delete' />
                        </IconButton>
                    </> 
                :   <IconButton onClick={()=>assign(equipment,supply.supply)} size='small'>
                        <CustomIcon icon='add-circle' style={{color:'red'}}/>
                    </IconButton>
    )
}    

export default React.memo(AssignCancelSupply)