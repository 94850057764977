import { InputComponentTypes } from "../../../infrastructure/form/typescript/FormTypes";
import { useForm } from "../../../infrastructure/form/useForm";
import { useLangLabels } from "../../language/services/useSetLabel";
import { SupplyInterface, SupplyType } from "../types/SupplyTypes";




export const useSupplyForm = (tagFP:string, supplyType?:SupplyType, item?:SupplyInterface) => {

    const { lang }= useLangLabels()
    return useForm<SupplyInterface>({fields:{
        id:{
            label:lang.globalentityValues.id,
            placeholder:lang.globalentityValues.id,
            value:item?.id || 0,
            component: InputComponentTypes.none
        },
        supply:{
            label: lang.supplies.title.form.label.supply,
            placeholder: lang.supplies.title.form.label.supply,
            value:item?.supply || '',
            component:InputComponentTypes.text,
            validations:{
                required:{value: true, message: lang.validations.messages.required},
                min:{value: 1, message: lang.validations.messages.minLength},
                max:{value: 50, message: lang.validations.messages.maxLength},
            }
        },
        type:{
            label:lang.supplies.title.form.label.type,
            placeholder:lang.supplies.title.form.label.type,
            value:item?.type || supplyType || '',
            component:(item || supplyType) ? InputComponentTypes.none : InputComponentTypes.supplyType,
            validations:{
                required:{ value:true, message:lang.validations.messages.required}
            }
        },
        description:{
            label:lang.supplies.title.form.label.description,
            placeholder:lang.supplies.title.form.label.description,
            value:item?.description || 'SIN DESCRIPCION',
            rows:4,
            component: InputComponentTypes.description,
        },
        tagFP:{
            label:lang.globalentityValues.tagFP,
            placeholder:lang.globalentityValues.tagFP,
            value:tagFP,
            component: InputComponentTypes.none
        }
    }})
}