import { Drawer, DrawerProps, Modal } from '@material-ui/core'
import React from 'react'
import { useTgdDrawerStyles } from './styles/useTgdDrawerStyles'
import { cssDrawerAtributesInterface, DrawerPropsAnchordTypes } from './types/TgdDrawerTypes'
import { useModal } from './../../utils/hooks/useModal';
import CustomButton, { ButtonColorType, ButtonVariant, CustomIconType } from '../buttons/CustomButton';


type TgdDrawerProps = {
    styles?:cssDrawerAtributesInterface,
    icon?:CustomIconType
    popoverTitle?:string
    variant?:ButtonVariant
    color?:ButtonColorType,
    anchor?:DrawerPropsAnchordTypes,
    disableEscapeKeyDown?:boolean
}

const TgdDrawer:React.FC<TgdDrawerProps> = ({children, styles, variant, color, icon, popoverTitle, anchor, disableEscapeKeyDown}) => {

    const classes = useTgdDrawerStyles({
        ...styles,
        drawerWidth:styles?.drawerWidth || '230px'
    })

    const {modal,handleModal} = useModal()

    return(<>
        <CustomButton {...{
            action:handleModal,
            icon:icon || 'add',
            popoverTitle,
            variant,
            color
        }} />
        <Drawer 
        anchor={anchor} 
        open={modal} 
        onClose={handleModal} 
        className={classes.drawer} 
        classes={{paper:classes.drawerPaper}}
        disableEscapeKeyDown = {disableEscapeKeyDown}
        >
            {children}
        </Drawer>
    </>
    )
}

export default TgdDrawer