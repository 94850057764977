import { AppBar, Box, Button, CircularProgress , createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab, Grid, Icon, IconButton, ListItemText, makeStyles, Paper, Toolbar, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import FullScreenSurface from '../../components/modals/FullscreenSurface';
import EquipmentVerification from './EquipmentVerification';
import { PlantInterface } from '../plants/service.plant';
import PageContainer from '../../components/pageContainers/PageContainer';
import { EquipmentInterface, LubricationPointInterface, useCreateEquipment } from './services/service.lubricationPoints';
import CreateEquipment from './CreateEquipment';
import TgdAuxList from '../../components/lists/TgdAuxList';
import { useToken } from '../session/service.session';
import { Alert } from '@material-ui/lab';
import UpdateEquipmentForm from "./UpdateEquipmentForm"
import CustomButton from '../../components/buttons/CustomButton';
import { useModal } from '../../utils/hooks/useModal';


const useStyles = makeStyles((theme) => createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
	},
	root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
  }))


interface Props{
    plant:PlantInterface
}

const CreateEquipmentPage:React.FC<Props> = ({plant}) => {
   

    const classes = useStyles();
    const token = useToken()
    const {modal:modalSurface,handleModal:handleModalSurface} = useModal()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [activeStep, setActiveStep] = React.useState(0);
    const [newEquipment,setNewEquipment] = useState<EquipmentInterface|undefined|null>()
    const [lubricationPoints,setLubricationPoints] = useState<LubricationPointInterface[] | undefined | null>([])
    const {modal,handleModal} = useModal()
    const {modal:modalLubricationPoints,handleModal:handleLubricationPointModal} = useModal()
    const [createEquipment,{status,error,data}] = useCreateEquipment()
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    
   /*  const editPointLubricants = (item:LubricationPointInterface) => {
        const editItem = lubricationPoints?.find((i) => (i.component === item.component) && (i.element === item.element))
        const stateWithoutItem = lubricationPoints?.filter((i) => (i.component !== item.component) && (i.element !== item.element))
        
        console.log(stateWithoutItem)
    } */

    const handleCreateEquipment = () => {
        if(lubricationPoints?.length) {
            createEquipment({
                data:lubricationPoints,
                token
            })
        } else{
            alert('Debe cargar al menos un componente en el equipo para poder crearlo')
        }
    };

    const handleRemoveItem = (e:any) => {
        const element = e.targetelement
        const component = e.component
  
        setLubricationPoints(lubricationPoints?.filter(item => (item.element !== element) && (item.component !== component)));
    };

    const handleRefresh = () => {
        var action = window.confirm("¿Estás seguro que queres hacer un reset de los datos de la plantilla?")
        if(action){
            setNewEquipment(undefined)
            setLubricationPoints([])
        }
    }

    useEffect(() => {
        if(lubricationPoints && lubricationPoints.length > 0){
            handleLubricationPointModal()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lubricationPoints]);




    useEffect(() => {
        if(!!newEquipment){
            handleModal()
            handleLubricationPointModal()
            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[newEquipment])
   
    
   
  
    return (<>
        <CustomButton icon='add' action={handleModalSurface} popoverTitle='Crear un equipo'/>
        <FullScreenSurface  modal={modalSurface} >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleModalSurface} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Crear y configurar nuevo equipo
                    </Typography>
                    <Button autoFocus color="inherit" onClick={handleCreateEquipment}>
                        Guardar
                    </Button>
                </Toolbar>
            </AppBar>

            
            
            <PageContainer>
                    <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
                        {(status === 'loading') && 
                            <PageContainer>
                                <CircularProgress size='40px' /> 
                            </PageContainer>
                        }
                        
                        {!(status === 'loading') &&
                        
                        <>
                        <Box>
                            {error && <Alert severity='error'>ha ocurrido un error</Alert>}
                            {(status === 'success') && (data?.length > 0) && <Alert severity={'warning'}>Algunos puntos de lubricación no se hn podido crear de manera satisfactoria, revise los errores para terminar de cargarlos.</Alert>}
                            {(status === 'success') && ( data?.length === 0) && <Alert severity='success'>El equipo y todos sus puntos de lubricación han podido cargarse con exito!</Alert>}
                            <br/>
                            <Paper elevation={3} style={{width:'850px',height:'calc(850px * 1.18)'}}>
                                <PageContainer>
                                    <Grid container spacing={3}>
                                        <Grid item md={12}>
                                            <Typography variant='h6'>{newEquipment?.tagFP ? newEquipment?.tagFP.split('|').map((i:string) => `${i} `):''}</Typography>
                                            <Divider/> 
                                        </Grid>
                                        <Grid item md={4}>
                                            <Box style={{
                                                backgroundColor:'#f6f6f6',
                                                maxWidth:'200px',
                                                maxHeight:'200px',
                                                minHeight:'150px',
                                                minWidth:'150px',
                                                width:'auto',
                                                height:'100%',
                                            }}>
                                                    
                                            </Box>
                                        </Grid>
                                        <Grid item md={8} >
                                            <Grid container spacing={3}>
                                                <Grid item md={6}>
                                                    Nombre de equipo:
                                                    <Typography variant='body2'><strong>{newEquipment?.equipment}</strong></Typography>
                                                    <Divider/>
                                                    <br/>
                                                    Tag de equipo:
                                                    <Typography variant='body2'><strong>{newEquipment?.tagTGD}</strong></Typography>
                                                    <Divider/>
                                                </Grid>
                                                <Grid item md={6}>
                                                    Tipo:
                                                    <Typography variant='body2'><strong>{newEquipment?.type}</strong></Typography>
                                                    <Divider/>
                                                    <br/>
                                                    Sector: 
                                                    <Typography variant='body2'><strong>{newEquipment?.sector}</strong></Typography>   
                                                    <Divider/>
                                                </Grid>
                                                <Grid item md={6}>
                                                    Tag de planta:
                                                    <Typography variant='body2'><strong>{newEquipment?.plantTag}</strong> </Typography>
                                                    <Divider/> 
                                                </Grid>
                                                <Grid item md={6}>
                                                    Criticidad del equipo:
                                                    <Typography variant='body2'><strong>{newEquipment?.criticality}</strong></Typography>
                                                    <Divider/> 
                                                </Grid>
                                            </Grid>
                                        </Grid>                       
                                    </Grid>
                                    <br/>
                                    <Divider/>
                                        <TgdAuxList
                                            customButtons={(e:LubricationPointInterface)=> <>
                                                <Icon onClick={()=>handleRemoveItem(e)}>delete</Icon>
                                            </>}
                                            title='Componentes'
                                            searchBar={false}
                                            type='equipment'
                                            alternativeColumn={(item:LubricationPointInterface)=> <ListItemText style={{textAlign:'left'}} primary={`${item.element} ${item.component}`}/>}
                                            items={lubricationPoints}
                                            status={'idle'}
                                            children={(item:LubricationPointInterface)=><>
                                                <UpdateEquipmentForm handleState={setLubricationPoints} defaultState={item} points={lubricationPoints} equipment={newEquipment} />
                                            </>}
                                            toggleDisabled={false}
                                        />
                                </PageContainer>
                            </Paper>
                            </Box>
                        <div style={{padding:'12px',display:'flex',flexDirection:'column'}}>
                            {!lubricationPoints?.length &&<>
                                <Fab color={'primary'}onClick={handleModal}>
                                    <Icon>edit</Icon>
                                </Fab>
                                <br/>
                            </>}
                            
                            {!!newEquipment && <>
                                <CreateEquipment plant={plant} points={lubricationPoints || []} handleLubricationPoint={setLubricationPoints} equipment={newEquipment} />
                                <br/>  
                            </>}
                            
                            <CustomButton 
                            icon='refresh' 
                            variant='fab' 
                            color='secondary' 
                            popoverTitle='Resetear datos'/>
                        </div>

                        </>}
                    </div>
                </PageContainer>

            
           <Dialog open={!modal} onClose={handleModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Configuracion inicial del equipo</DialogTitle>
                <DialogContent>
                    <EquipmentVerification handleNext={handleNext} defaultState={newEquipment} handleState={setNewEquipment} plant={plant}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModal} color="primary">
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>

           {/* <Dialog open={modalLubricationPoints} onClose={handleLubricationPointModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Carga de Punto de Lubricación</DialogTitle>
                <DialogContent> */}
                    <CreateEquipment variant='fab' plant={plant} points={lubricationPoints || []} handleLubricationPoint={setLubricationPoints} equipment={newEquipment} />
                {/* </DialogContent>
                <DialogActions>
                    <Button onClick={handleLubricationPointModal} color="primary">
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog> */}
        
        </FullScreenSurface>
    </>);
}

export default CreateEquipmentPage
