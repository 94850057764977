import React from 'react';
import EaseInOut from '../components/presentational/EaseInOut';
import LogOutButton from '../domains/login/LogOutButton';

const LogOutPage:React.FC = () => {

    return (
            <EaseInOut children={
            <div style={{width:'200px',height:'100vh',display:'flex',margin:'auto',alignItems:'center',}}>
                <LogOutButton/>
            </div>}/>
    );
}

export default LogOutPage;
