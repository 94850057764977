import { useUser } from "../../domains/persons/services/service.person"
import { flattenObjAllLevels } from "../../utils/dataFiltering"
import XLSX  from 'xlsx';
import { saveAs } from 'file-saver';
import { GridModelInterface } from '../Interfaces';
import { useMemo } from "react";


export const useExportXlsx = (items:any[],documentTitle:string,gridModel?:GridModelInterface) => {
   

    const _items = useMemo(()=> gridModel 
        ? items?.map(i=>{
            let result = {}
            Object.entries(flattenObjAllLevels(i)).forEach(([key,value])=>{
                if(gridModel[key]){
                    const label = gridModel[key].label.toUpperCase()
                    const options = gridModel[key].options
                    result = {
                        ...result,
                        [label]: options
                            ? value === true
                                ? options[0]?.name 
                                : options[1]?.name 
                            : value
                    }
                }
                
            })
            return result
        }) || []
        : items
    ,[gridModel, items])


    const {data:user} = useUser()
    const keys:string[] = _items[0] ? Object.keys(flattenObjAllLevels(_items[0])) : ['']
    const data = () => {
        if(_items[0]) return [
            keys,
            ...(_items.map(item=>(
                [
                    ...keys.map((key)=>{
                        if(key.includes('.')){
                            const trueKey = key.split('.')
                            if(trueKey.length === 2){
                                const level1 = item[trueKey[0]]
                                const level2 = level1 && level1[trueKey[1]]
                                return level2 ? level2 : ''
                            }
                            else{
                                const level1 = item[trueKey[0]]
                                const level2 = level1 && level1[trueKey[1]]
                                const level3 = level2 && level2[trueKey[2]]
                                return level3 ? level3 : ''
                            }
                        }
                        else return item[key]
                    })
                ]
            )) || [''])
        ]

        else return [['']]
    }
    
    //create data workbook
    //------------------------
    var wb = XLSX.utils.book_new();


    wb.Props = {
        Title: `${documentTitle}`,
        // Subject: "Test",
        Author: `${user?.email}`,
        CreatedDate: new Date()
    };

    wb.SheetNames.push("Hoja 1");

    var ws_data = data() //[['hello' , 'world']];  //a row with 2 columns

    var ws = XLSX.utils.aoa_to_sheet(ws_data);

    wb.Sheets["Hoja 1"] = ws;

    //exporting
    //------------------------
    var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});

    function s2ab(s:any) { 
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf);  //create uint8array as viewer
        for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
        return buf;    
    }

    const file = new Blob([s2ab(wbout)],{type:"application/octet-stream"})
    const downloadFile = () => {
        saveAs(file, `${documentTitle}.xlsx`);
    }

    return {downloadFile,file,documentTitle}
}