import { useLang } from './useLanguages';
import { useSelectedLanguage } from './useSelectedLanguage';




export const useLangLabels = () => {
    const [,{data:selectedLanguage}] = useSelectedLanguage()
    const lang = useLang(selectedLanguage?.lang)
    
    return { lang }
}