import React, { useEffect, useState } from 'react';
import { PlantInterface } from '../../../plants/service.plant';
import { useUser } from '../../../persons/services/service.person';
import SelectSimpleEntity from '../../../../components/select/SelectSimpleEntity'
import { useIotDevicesByTagFP } from '../services/IotDeviceService';
import { IotDeviceInterface } from '../interfaces/IotDeviceInterfaces';
import CreateIotDeviceForm from './../CreateIotDeviceForm';
import { useLangLabels } from './../../../language/services/useSetLabel';

interface props{
    tagFP?:string
    plant?:PlantInterface | undefined
    onChange:(value:any) => void
    defaultValue?:string | undefined 
    dimentionType?:string
    error?:string
    complete?:true
}

const SelectIoTDevice:React.FC<props> = ({ onChange, defaultValue,error,tagFP,complete}) => {

    const showTitle ='device'
    const { lang } = useLangLabels()
    const {data:IoTDevices} = useIotDevicesByTagFP(tagFP!)
    const {data:user} = useUser()

    const onSelect = (selected:string)=>{
        if(complete){
            const result = IoTDevices && IoTDevices.filter((item:IotDeviceInterface)=> item[showTitle] === selected)[0]
            onChange(result)
        }else{
            onChange(selected)
        }
    }
     
    return (<>
            {<SelectSimpleEntity
                error={error}
                label={lang.iotDevices.title.singular }
                onChange={onSelect}
                entityList={IoTDevices || []}
                showTitle={showTitle}
                defaultValue={defaultValue}
            >
                {user && (user?.type === 'O' ? null : <CreateIotDeviceForm tagFP={tagFP!}/>)}
            </SelectSimpleEntity>}
    </>);

}

export default React.memo(SelectIoTDevice);
