import React, { CSSProperties } from 'react';
import { usePopoverEvent } from '../infrastructure/tgd.config';
import CustomButton, { ButtonColorType, ButtonVariant, CustomIconType } from './buttons/CustomButton';
import ModalButton from './buttons/ModalButton';
import FlexContainer from './presentational/FlexContainer';
import TgdPopover from './popovers/TgdPopover';
import PageContainer from './pageContainers/PageContainer';
import { ComponentUIModes } from './ComponentUIModes';
import TgdDrawer from './drawer/TgdDrawer';
import { cssDrawerAtributesInterface, DrawerPropsAnchordTypes } from './drawer/types/TgdDrawerTypes';
import DefaultFormLayout from './forms/DefaultFormLayout';

 
type ComponentModeInterface = {
    children?:React.ReactNode, 
    mode?:ComponentUIModes, 
    icon?:CustomIconType, 
    title?:string, 
    variant?:ButtonVariant
    popoverTitle?:string
    onClick?:(value?:any)=>void
    buttonStyle?:CSSProperties
    buttonColor?:ButtonColorType
    disabled?:boolean,
    fullwidth?:boolean
    onClose?:()=>void
    width?:string,
    drawerProps?:{
        styles?: cssDrawerAtributesInterface
        anchor?:DrawerPropsAnchordTypes,
        disableEscapeKeyDown?:boolean,
    }
}

const ComponentMode:React.FC<ComponentModeInterface> = ({drawerProps, disabled, buttonColor,buttonStyle,children, mode, icon, title, variant, onClick, onClose, popoverTitle, fullwidth, width}) => {
    
    const componentMode = mode || 'modal'
    const {handleClosePopover,setPopoverTarget,currentTarget,id} = usePopoverEvent()
    
    return(<>

        {componentMode === 'content' && 
            <PageContainer>
                <FlexContainer flexDirection='column'>
                    {children}
                </FlexContainer>
            </PageContainer>
        }

        {componentMode === 'popover' && <>
            <TgdPopover mode='hover' title={title || popoverTitle || ''} width={width} >
                <CustomButton
                title={title || ''}
                color={buttonColor}
                styleProps={{...buttonStyle}}
                icon={icon}
                variant={variant}
                aria-describedby={id} 
                action={(e)=>setPopoverTarget(e)}
                />
            </TgdPopover>
            <TgdPopover 
            width={width}
            handleClose={handleClosePopover}
            currentTargetEvent={currentTarget} 
            title={title || ''}
            children={children}
            typography={{
                variant:'h6'
            }}/>
        </>}

        {componentMode === 'modal' &&
        <ModalButton onClose={onClose} fullwidth={fullwidth} disabled={disabled || false} onClick={onClick} icon={icon || 'add'} title={popoverTitle || title}  variant={variant} color={buttonColor} >
            <FlexContainer flexDirection='column'>
                {children}
            </FlexContainer>
        </ModalButton>}

        {componentMode === 'drawer' && <>
                <TgdDrawer 
                    {...{
                        ...drawerProps,
                        icon,
                        popoverTitle,
                        variant,
                        color:buttonColor,
                        DefaultFormLayout,
                    }}
                >
                    {children}
                </TgdDrawer>
            </>
        }
        
    </>)
}

export default React.memo(ComponentMode)