import React, { useState } from 'react';
import { PlantInterface } from '../../plants/service.plant';

import DeleteCriticalityForm from '../DeleteCriticalityForm';
import { gridModelCriticality } from '../services/service.criticality';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import AnyGrid from '../../../components/grids/AnyGrid';
import CUCriticalityForm from '../CUCriticalityForm';
import { CrudFormType } from '../../../infrastructure/form/typescript/FormTypes';
import { useLangLabels } from './../../language/services/useSetLabel';
import { CriticalityInterface } from '../types/types';
import { CarrousellActionInterface } from './../../../components/grids/AnyGrid';
import { useCriticalitiesByPlant } from '../../auxiliar-data/service.aux';

interface Props {
    plant:PlantInterface
}


const carrousellActions:CarrousellActionInterface[] = [
    {icon:'edit', component:(value:CriticalityInterface,tagFP:string)=><CUCriticalityForm tagFP={tagFP} item={value} actionType={CrudFormType.update} />}
] 

const ManageCriticityPage:React.FC<Props> = ({plant}) => {

    const {lang} = useLangLabels()
    const {data:criticalities} = useCriticalitiesByPlant(plant.tagFP)
    
    const icon = (item:any) => {
        return <FiberManualRecordIcon style={{ color: `${item.row.color}`,marginRight:'16px'}} />
    }

    return(<>
            <AnyGrid 
                gridModel={gridModelCriticality}
                title={lang.criticalities.title.plural}
                renderOptions={icon}
                items={criticalities || [] }
                loading={criticalities ? false : true}
                height='55vh'
                carrousellActions={carrousellActions}
                itemActions={(item:any,items:any)=>item ? <> 
                       {/*  <CarrousellModal {...{item,items,icon:'edit'}}>
                            {(value:CriticalityInterface)=>
                                <CUCriticalityForm 
                                tagFP={item.tagFP} 
                                item={value} 
                                actionType={CrudFormType.update} 
                                />
                            }
                        </CarrousellModal> */}
                        <DeleteCriticalityForm item={item} />
                </> : null}
                headerActions={(item:any)=><CUCriticalityForm tagFP={plant.tagFP} actionType={CrudFormType.create} />
            }
            />
    </>)
}

export default ManageCriticityPage
