import axios,{AxiosRequestConfig} from 'axios'
import { useEffect, useState } from 'react'
import { queryCache, QueryStatus, useMutation } from 'react-query'
import { useEquipmentsByCriticality } from '../../equipments/services/service.lubricationPoints'
import { useToken } from '../../session/service.session'
import { GridModelInterface, MutationQueryObject } from '../../../infrastructure/Interfaces';
import { URL } from '../../../infrastructure/appConfig/appConfig'
import { useOperatorSupervisorTagFP } from '../../persons/services/service.person'
import { useCriticalitiesByPlant } from '../../auxiliar-data/service.aux'
import { useStatusProcessor } from './../../errors/services/StatusProcessor';
import { CriticalityInterface, CriticalityTypes } from '../types/types'

export const gridModelCriticality:GridModelInterface={
    renderOption:{label:'Identificador'},
    id:{label:'Id'},
    days:{label:'Prioridad en días'},
    number:{label:'Nº Relevancia '},
    color:{label:'Color'},
    date:{label:'Fecha'},
    description:{label:'Descripción'},
    tagFP:{label:'TagFP'},
    criticalityType:{label:'Tipo de criticidad'}
}

export const criticalityCRUDDependencies =['PlantAux','CriticalitiesByPlant']


export const CreateCriticality = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateCriticality`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}
export const UpdateCriticality = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateCriticality`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const DeleteCriticality = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/DeleteCriticality`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}


export const useCriticalityByTypeByTagFP = (tagFP:string,criticaityType:CriticalityTypes) => {
    const criticalities = useCriticalitiesByPlant(tagFP)
    const data = criticalities.data?.filter((c:CriticalityInterface)=>
        c.criticalityType === criticaityType
    )
    return{
        ...criticalities,
        data
    }
}



export const useUpdateCriticality = () => {
    const token = useToken()
    const [mutation, query] = useMutation(UpdateCriticality,{
        onSuccess:()=>{
            queryCache.invalidateQueries('PlantAux')
        }
    })

    const {status,message,error,reset} = useStatusProcessor(query)

    const update = (data:CriticalityInterface) => {
        return mutation({
            token,
            data
        })
    }

    return{
        update,
        updateResult:{
            ...query,
            status,
            message,
            error,
            reset   
        }
    }
}

export const useDeleteCriticality = () => {
    return useMutation(DeleteCriticality,{
        onSuccess:()=>criticalityCRUDDependencies.forEach((d)=>{
            queryCache.invalidateQueries(d)
        })
    })
}


interface DeleteComponentErrors {
    equipments?:CriticalityInterface[] | undefined | null
}

export const useDeleteCriticalityVerification = () => {

    const token = useToken()
    const [deleteItem,{data}] = useDeleteCriticality()
    const [status, setStatus] = useState<QueryStatus>(QueryStatus.Idle)
    const [validationElement,setValidationElement] = useState<CriticalityInterface | undefined >()
 
    const {data:equipmentsByComponents,status:equipmentsByComponentStatus} = useEquipmentsByCriticality(validationElement)

    const [errors, setErrors] = useState<DeleteComponentErrors>()

    const validate = (item:CriticalityInterface) => {
        setStatus(QueryStatus.Loading)
        if(!equipmentsByComponents)setValidationElement(item)
        else handleDelete()
    }

    const handleDelete= () =>{
        if((equipmentsByComponentStatus === 'success') ){
            if((equipmentsByComponents?.length > 0)) {
                setErrors({
                    equipments:equipmentsByComponents
                })
                setStatus(QueryStatus.Error)
                setValidationElement(undefined)
            }
            else{
                deleteItem({
                    data:validationElement,
                    token
                })
                .then(()=>{
                    setStatus(QueryStatus.Success)
                    setValidationElement(undefined)
                })
                .catch(err=>console.log(err))
            }
        }
    }

    
    useEffect(() => {
        handleDelete()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[equipmentsByComponentStatus])

    return  {
        errors,
        status,
        validate,
        data
    }
}