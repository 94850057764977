import React from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import TgdSvgIcon from './TgdSvgIcon';

const OutOfRouteIcon:React.FC<any> = (props:SvgIconProps) => {
    return (
        <TgdSvgIcon {...props}  style={{color:!props.color ? '#FFF' : 'inherit', margin:0}}>
            <g id="Capa_2" data-name="Capa 2">
                <g id="Capa_1-2" data-name="Capa 1">
                    {/*  <rect class="cls-1" width="27.33" height="27"/> */}
                    <polygon points="10.71 8.96 13.94 8.96 14.83 10.74 19.26 10.74 19.26 16.06 17.83 16.06 19.61 17.84 21.04 17.84 21.04 8.96 15.71 8.96 14.83 7.19 8.94 7.19 10.71 8.96"/>
                    <polygon points="16.75 16.06 15.71 16.06 14.83 14.29 9.51 14.29 9.51 8.96 9.64 8.96 7.86 7.19 7.73 7.19 7.73 22.27 9.51 22.27 9.51 16.06 13.94 16.06 14.83 17.84 18.53 17.84 16.75 16.06"/>
                    <polygon points="3.98 6.14 5.4 4.73 21.56 20.86 20.14 22.27 3.98 6.14"/>
                </g>
            </g>
        </TgdSvgIcon>
    );
}

export default OutOfRouteIcon