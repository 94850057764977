import React, { useEffect, useState } from 'react';
import { PlantInterface } from '../../../plants/service.plant';
import { useUser } from '../../../persons/services/service.person';
import SelectSimpleEntity from '../../../../components/select/SelectSimpleEntity'
import { useSTISensorsByTagFP } from '../services/STIsensorsService';
import { STISensorsInterface } from '../services/STIsensorsInterfaces';
import CreateSTISensorForm from '../CreateSTISensorForm';
import { useLangLabels } from './../../../language/services/useSetLabel';

interface props{
    tagFP?:string
    plant?:PlantInterface | undefined
    onChange:(value:any) => void
    defaultValue?:string | undefined 
    dimentionType?:string
    error?:string
    complete?:true
}

const SelectSTISensor:React.FC<props> = ({ onChange, defaultValue,error,tagFP,complete}) => {

    const {lang} = useLangLabels()
    const {data:STISensors} = useSTISensorsByTagFP(tagFP!)
    const {data:user} = useUser()

    const onSelect = (selected:string)=>{
        complete 
        ?   onChange(STISensors && STISensors.filter((item:STISensorsInterface)=> item.sensorType === selected)[0])
        :   onChange(selected)
    }
    
    return (<>
            {<SelectSimpleEntity
                error={error}
                label={lang.STISensors.title.singular }
                onChange={onSelect}
                entityList={STISensors || []}
                showTitle={'sensorType'}
                defaultValue={defaultValue}
            >
                {user && (user?.type === 'O' ? null : <CreateSTISensorForm tagFP={tagFP!}/>)}
            </SelectSimpleEntity>}
 
    </>);

}

export default SelectSTISensor
