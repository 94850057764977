import { useUser } from "../../../domains/persons/services/service.person"
import { ONLINE_MODE } from "../../../infrastructure/appConfig/appConfig"


import clientData from './data/CLientData.json'
import adminData from './data/AdminData.json'
import accountManagerData from './data/AccountManager.json'
import operatorData from './data/OperatorData.json'
import engineerData from './data/SupervisorData.json'
import superAdminData from './data/SuperAdminData.json'



//Menus
export const useMenu = () => {
  
    const offlineMenu = (menu:any[])=> {
        if(ONLINE_MODE) return menu
        else return menu.filter((item:any) => item.offline)
    }

    const {data:user} = useUser()
    let dimentionMenu = {}
    const userMenu =  {
        superAdminData:offlineMenu(superAdminData.menu),
        accountManagerData:offlineMenu(accountManagerData.menu),
        adminData:offlineMenu(adminData.menu),
        engineerData:offlineMenu(engineerData.menu),
        clientData:offlineMenu(clientData.menu),
        operatorData:offlineMenu(operatorData.menu)  
    } 

    
    if ((user) && (userMenu != null)){
        switch (user?.type) {
  
            case 'SA':
                dimentionMenu = userMenu.superAdminData
                return dimentionMenu
                
  
            case 'AC':
                dimentionMenu = userMenu.accountManagerData
                return dimentionMenu
                
  
            case 'A':
                dimentionMenu = userMenu.adminData
                return dimentionMenu
                
  
            case 'S':
                dimentionMenu = userMenu.engineerData
                return dimentionMenu
                
  
            case 'C':
                dimentionMenu = userMenu.clientData
                return dimentionMenu
                
  
            case 'O':
                dimentionMenu = userMenu.operatorData
                return dimentionMenu
                

            default: 
                return null
                
        }
    }
}