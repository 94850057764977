import { AppBar, createStyles, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import EquipmentFileTemplate from './EquipmentFileTemplate';
import FullScreenSurface from '../../components/modals/FullscreenSurface';
import CloseIcon from '@material-ui/icons/Close';
import UpdateEquipment from './UpdateEquipment';
import DesactivateEquipment from '../../domains/equipments/UpdateEquipmentState';
import FlexContainer from '../../components/presentational/FlexContainer';
import CustomButton from '../../components/buttons/CustomButton';
import { useEquipmentsTag } from './services/service.lubricationPoints';
import { useModal } from './../../utils/hooks/useModal';


const useStyles = makeStyles((theme) => createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
	},
	root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
  }))

interface Props{
    item:any
    type:'element' | 'equipment'
}
const EditEquipmentComponent:React.FC<Props> = ({item,type}) => {



    const classes = useStyles()
    const {modal,handleModal } = useModal()
/*   const {modal:updateEquipmentModal,handleModal:handleUpdateEquipmentModal } = useModal()
 */  const {getTag}= useEquipmentsTag()
    
    return (
        <>
            <CustomButton action={handleModal} variant='iconButton' icon='view' />

            <FullScreenSurface  modal={modal}  >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleModal} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Editar Equipo
                        </Typography>
                       </Toolbar>
                </AppBar>
                <br/>
                <FlexContainer justify='center' gap='16px' >
                    <EquipmentFileTemplate item={item} type={type}/>
                    <FlexContainer flexDirection='column'>
                        <UpdateEquipment item={item} />
                        <DesactivateEquipment variant='fab' type={'equipment'} tagTGD={getTag(item,'equipment')} item={item}/>
                    </FlexContainer>
                </FlexContainer>
            </FullScreenSurface>
        </>
    );
}

export default React.memo(EditEquipmentComponent);
