import React,{useEffect} from 'react';
import { SectorInterface,  useDeleteSectorVerification } from './services/service.sectors';
import CustomButton from '../../components/buttons/CustomButton';
import { useStatusProcessor } from '../errors/services/StatusProcessor';
import { useClickPosition } from '../../utils/hooks/useClickPosition';
import { useSnackbar } from '../../utils/hooks/useSnackbar';


interface Props{
    item:SectorInterface    
}
const DeleteSectorFrom:React.FC<Props> = ({item}) => {
    
    const { status:deleteStatus, validate, errors, data } = useDeleteSectorVerification()
    const { status, message } = useStatusProcessor({...data,status:deleteStatus})

    const {position,getClickPosition} = useClickPosition()
    const { setData } = useSnackbar()

    useEffect(()=>{
        
        if(deleteStatus === 'error'){
            
            const componentsError = (
                (errors?.equipments?.length 
                    ? (errors.equipments.length > 1 
                        ? `${errors.equipments.length} Equipos` 
                        : `${errors.equipments.length} Equipo`) 
                    : '')
            )

            setData({
                modal:true,
                errors:`Este sector no se puede eliminar porque está siendo usado en: ${componentsError}` ,
                severity:'warning',
                position:position
            })

        }

        if(deleteStatus === 'success'){
            setData({
                modal:true,
                errors:message || '',
                severity:deleteStatus ? 'success' : 'warning',
                position:position
            })
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[deleteStatus,errors])


    return (<>
        <CustomButton 
            status={status}
            icon='delete' 
            variant='iconButton' 
            action={(e)=>getClickPosition(e,()=>validate(item))}
            popoverTitle='Eliminar sector'
        />
    </>);
}

export default React.memo(DeleteSectorFrom);
