import React from 'react'
import { LubricationPointInterface } from '../../../domains/equipments/services/service.lubricationPoints'
import { gridModelObservations, useObservationByIdentifier } from '../../../domains/observations/service/service.observations'
import ModalButton from '../../buttons/ModalButton'
import AnyGrid from '../AnyGrid'
import { CustomIconType } from '../../buttons/CustomButton'
import { GridModelInterface } from '../../../infrastructure/Interfaces'
import { SurveyInterface } from '../../../domains/colector/domains/surveys/services/service.survey';

interface Props{
    mode:'modal' | 'content'
    data:any 
    queryType:'lubricationPoint' | 'survey'
}

const CustomObservationsList:React.FC<Props> = ({mode,data,queryType})=> {

    const  {data:entity} = useObservationByIdentifier(queryType,data)
    
    const itemIdentifier = 
        (queryType === 'survey' && data.id) || 
        (queryType === 'survey' && data.plantTag)

    const title = 
        (queryType === 'survey' && `Observaciones del relevamiento: ${itemIdentifier} `) || 
        (queryType === 'lubricationPoint' && `Observaciones del punto: ${itemIdentifier}`) || ''


    const icon:CustomIconType = 'observations'
    const gridModel:GridModelInterface = gridModelObservations

    const content = 
    <AnyGrid
        loading={entity ? false : true}
        items={entity || []}
        gridModel={gridModel}
        title={title}
        documentExportTitle={`Reporte_Observaciones_${itemIdentifier}`}
        /* renderOptions={item.renderOption} */
    />

    return(<>
        {mode === 'modal' && 
        <ModalButton fullwidth={true} icon={icon} variant='iconButton' popoverTitle={`${title} de este item`} >
            {content}
        </ModalButton>}

        {mode === 'content' &&
            content
        }
    </>)
}

export default React.memo(CustomObservationsList)