import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';


interface Props{
    status:string
    children:React.ReactNode
    errorMessage:string
    successMessage?:string
}

const QueryStatus:React.FC<Props> = ({status,children,errorMessage,successMessage}) => {
    return (
        <>
            {status === 'loading' && <div style={{width:'50px',height:'50px'}}><CircularProgress /></div>}
            {status === 'error' && <Alert severity="error">{errorMessage}</Alert>}
            {successMessage && status === 'success' && <Alert severity="success">{successMessage}</Alert>}
            {((status === 'idle')||(status === 'success')) && children}
        </>
    );
}

export default QueryStatus;
