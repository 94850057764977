import React from 'react';

//material
import { Grid, Icon, ListItem, ListItemIcon, Typography, makeStyles, createStyles, TextField } from '@material-ui/core';
import {  useUpdatePerson } from './services/service.person';
import { useToken } from '../session/service.session';
import { Alert } from '@material-ui/lab';

import ProgressButton from '../../components/buttons/ProgressButton';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import AssignOperatorNumber from './AssignOperatorNumber';
import { PersonInterface } from './interfaces/userInterfaces';
import SelectSimpleEntity from '../../components/select/SelectSimpleEntity';
import SelectCompanyService from '../cluster-companies/selectCompanyService';
import OnlineOfflineMode from '../../infrastructure/online/offline Mode/OnlineOfflineMode';


const useStyles =  makeStyles((theme) => createStyles({
    formGrid:{
        display:'flex',
        flexDirection:'column'
    },
    input: {
        width:'300px,'
    },
}));
interface Props{
    person:PersonInterface
    tagFP?:string
}



const UpdatePersonForm:React.FC<Props> = ({person,tagFP}) =>{

    const token = useToken()
    const classes = useStyles()
    const [mutate,{status:PasswordStatus}] = useUpdatePerson()
    const {register,watch,errors,handleSubmit} = useForm()
    const [showPassword,setShowPassword] = useState(true)

    const submit = () => {
        mutate({
            data:{
                email:person.email,
                password:watch('password')
            },
            token
        })
    }

    return(
    <OnlineOfflineMode>
        <div style={{padding:'24px',marginBottom:'16px', border:'solid 1px #d3d3d3', width: '100%'}}>
            {(PasswordStatus === 'success') ? <><Alert severity="success">Usuario Actualizado Correctamente</Alert><br/></> : null}
        
            <br/>
            <Grid container wrap={'wrap'} spacing={3} style={{width:'100%'}}>
                <Grid item md={6}>
                        <ListItem>
                            <ListItemIcon><Icon color={'secondary'}>person</Icon></ListItemIcon>
                            <Typography >{person && person.name}</Typography>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><Icon color={'secondary'}>email</Icon></ListItemIcon>
                            <Typography variant={'button'}>{person && person.email}</Typography>
                        </ListItem>
                        <br/>
                        <br/>

                        {tagFP && person.role === 'Operario' && <AssignOperatorNumber person={person} tagFP={tagFP}/>}

                        <SelectCompanyService person={person}/>
                        
                </Grid>

                <Grid item md={6}>
                    <ListItem>
                        <form   onSubmit={handleSubmit(submit)}>
                                <TextField
                                    type={showPassword ? 'password' : 'text'}
                                    inputRef={register({
                                        required:{value:true,message:'Requerido'},
                                        min:{value:6, message:'Por lo menos 6 carácteres'}
                                    })}
                                    className={classes.input}
                                    variant='outlined'
                                    size='small'
                                    name="password"
                                    onFocus={()=>sessionStorage.setItem('passwordConfirmation',watch('password'))}
                                    label={errors?.password?.message ||"Nueva Contraseña"}
                                    color={errors?.password ? 'primary': 'secondary'}
                                />
                                <br/>
                                <br/>
                                <TextField
                                    type={showPassword ? 'password' : 'text'}
                                    inputRef={register({
                                        required:{value:true,message:'Requerido'},
                                        validate:{
                                            equal:()=>(watch('newPassword') === watch('password'))
                                        }
                                    })}
                                    className={classes.input}
                                    variant='outlined'
                                    size='small'
                                    label={errors?.newPassword ? 'No coinciden' : 'Repite la Contraseña'}
                                    color={errors?.newPassword ? 'primary': 'secondary'}
                                    name="newPassword"
                                />
                                <br/>
                                <br/>
                                <Checkbox
                                    color='secondary'
                                    checked={showPassword}
                                    onChange={()=>setShowPassword(!showPassword)}
                                />

                                <ProgressButton status={PasswordStatus} type='submit' variant='contained' color='secondary'> Guardar </ProgressButton>
                        </form>
                    </ListItem>
                </Grid>
                
            
            </Grid>
        </div>
    </OnlineOfflineMode>
    )
}

export default UpdatePersonForm
