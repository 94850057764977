import { useEffect } from "react"
import { queryCache, QueryResult, useQuery } from "react-query"
import { getBrowserLocales } from "../../../utils/utils"
import { LangInterface } from "./interfaces"






export const useSelectedLanguage= ():[(lang:string)=>void, QueryResult<LangInterface>] => {

    const key = 'lang'
    const [,short] = getBrowserLocales()
    const prevSelected = sessionStorage.getItem(key)
    const languageQuery = useQuery<LangInterface>(key,{
        initialData:{
            lang:(prevSelected && JSON.parse(prevSelected).lang) || short
        },
        staleTime:10000000
    })

    useEffect(()=>{
        setLang(prevSelected ? JSON.parse(prevSelected).lang : short)
    },[short])


    const setLang = (lang:string) =>{
        sessionStorage.setItem(key,JSON.stringify({lang:lang}))
        queryCache.setQueryData(key,{lang:lang},{
            onSuccess:()=>{
                queryCache.invalidateQueries(key)
            }
        })
    }

    return [setLang,languageQuery]

}