import React from 'react';

//Components
import EaseInOut from '../../components/presentational/EaseInOut'
import LogOutButton from '../../domains/login/LogOutButton';

//material
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FlexContainer from '../../components/presentational/FlexContainer';
import theme from '../../infrastructure/materialUi/themeConfig';
import PointerContainer from '../../components/pageContainers/PointerContainer';



const useStyles = makeStyles((theme) => ({
  plantSelectorContainer: {
    position:'absolute',
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(2),
      width: theme.spacing(35),
      height: '26vh',
      cursor:'pointer',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      backgroundColor: '#fff',
      textTransform:'uppercase',
      '&:hover': {
        boxShadow: '0px 0px 47px -16px rgba(0,0,0,0.75)',
      }
    },
    justifyContent:'center',
    alignContent:'center',
    width:'100vw',
    height:'100vh',
    
    /* backgroundColor:'#EFEBE9', */
  },
  logOut:{
    position:'absolute',
    display:'flex',
    gap:'8px',
    margin:'16px',
	top:'0',
	right:'0',
    width:'218px'
},

}));

const PlantSelector:React.FC<{plants:any, select?:any}> = ({plants,select}) => {
  const classes = useStyles();

  return (
      <>
        <EaseInOut>
			{/* <div className={classes.plantSelectorContainer}> */}
				<FlexContainer flexWrap='wrap' justify='center' overflowY='scroll' align='start' padding={'80px 0px'}  width={'100vw'} height={'100vh'} >
					{plants.map((item:any,index:number) => (
						<PointerContainer key={`${item.factory} ${item.name}`}>
							<Paper elevation={4}  color="secondary" variant='outlined' onClick={()=> select(`${item.factory} ${item.name}`,item)} > 
								<FlexContainer justify='center' maxWidth={`${theme.spacing(36)}px`} padding={`${theme.spacing(3)}px`} height='200px' width={`40vw`} align='center' flexWrap='wrap' >
									{item.factory} {item.name} 
								</FlexContainer>
							</Paper>
						</PointerContainer>
					))} 
		 		</FlexContainer>
			{/* /* </div> */}
		</EaseInOut> 
        <div className={classes.logOut}>
          <LogOutButton title={'salir'}/>
        </div>
      </>
      )  

}

export default React.memo(PlantSelector)