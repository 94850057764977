import React from 'react';
import { TgdTextField } from './formComponents/TgdTextField';
import { FieldProps, FormSelectorTypes } from './interfaces';
import SelectCriticality from '../criticalities/components/SelectCriticality';
import SelectLubricant from '../lubricants/molecules/SelectLubricant';
import SelectSTISensorTypeComplete from '../sensoring/sensorTypes/molecules/SelectSTISensorTypeComplete';
import SelectBooleanOption from '../sensoring/STISensors/atoms/SelectBooleanOption';
import SelectSTIEquipment from '../sensoring/STIEquipments/molecules/SelectSTIEquipment';
import SelectSTISensor from '../sensoring/STISensors/molecules/SelectSTISensor';
import SelectSTISensorType from '../sensoring/sensorTypes/molecules/SelectSTISensorType';
import SelectIoTDevice from '../sensoring/iotDevices/molecules/SelectIoTDevice'
import EnterSensorAddress from '../sensoring/STISensors/molecules/EnterSensorAddress';
import SetParametters from '../sensoring/STISensorizedEquipments/molecules/SetParametters';

type restProps = FieldProps


const FormSelector:React.FC<{selector:FormSelectorTypes, fieldProps:restProps}> = ({selector, fieldProps}):any => {


    switch (selector) {
        case 'none':
            return null
            
        case 'text':
            return <TgdTextField {...fieldProps}/>
            
        case 'selectCriticality':
            return <SelectCriticality tagFP={fieldProps.tagFP!} {...fieldProps}/>

        case 'selectLubricant':
            return <SelectLubricant {...fieldProps}/>

        case 'selectSector':
            return <></>/* <SelectSector {...fieldProps}/> */

        case 'selectEquipment':
            return <></>/* <SelectType {...fieldProps} type='Equipo' /> */

        case 'selectMeasureUnit':
            return <></>/* <SelectType {...fieldProps} type='Unidad' /> */

        case 'selectLubricantType':
            return <></>/* <SelectType {...fieldProps} type='Lubricante' /> */
            
        case 'typeSelector':
            return <></>/* <TypeSelector {...fieldProps} /> */
            
        case 'selectElement':
            return <></>/* <SelectElement {...fieldProps} /> */


        //STI CONFIGS
        case 'sensorTypeCompleteSelector':
            return <SelectSTISensorTypeComplete {...fieldProps} />
            
        case 'enterSensorAddress':
            return <EnterSensorAddress {...fieldProps} />

        case 'sensorTypeSimpleSelector':
            return <SelectSTISensorType {...fieldProps} />
    
        case 'booleanOptionSelector':
            return <SelectBooleanOption {...fieldProps} />

        case 'selectSTIEquipment':
            return <SelectSTIEquipment {...fieldProps} />

        case 'selectSTISensor':
            return <SelectSTISensor {...fieldProps} />

        case 'selectIoTDevice':
            return <SelectIoTDevice {...fieldProps} />

        case 'setParametters':
            return <SetParametters {...fieldProps} />

            default:
                return null

    }

}

export default FormSelector
