import React,{useEffect} from 'react';
import { LubricantInterface,  useDeleteLubricantVerification } from './services/service.lubricants';
import { useStatusProcessor } from '../errors/services/StatusProcessor';
import CustomButton from '../../components/buttons/CustomButton';
import { useClickPosition } from '../../utils/hooks/useClickPosition';
import { useSnackbar } from '../../utils/hooks/useSnackbar';

interface Props{
    item:LubricantInterface    
}
const DeleteLubricantform:React.FC<Props> = ({item}) => {
    const {status:deleteStatus, validate, errors, data } = useDeleteLubricantVerification()
    const {status,message,error} = useStatusProcessor({...data,status:deleteStatus})

    const {position,getClickPosition} = useClickPosition()
    const { setData } = useSnackbar()

    useEffect(()=>{
        if(deleteStatus === 'error'){
            const componentsError = (
                (errors?.equipments?.length 
                    ? (errors.equipments.length > 1 
                        ? `${errors.equipments.length} Equipos` 
                        : `${errors.equipments.length} Equipo`) 
                    : '')
            )
         
            const containersError = (
                (errors?.containers?.length 
                    ? (errors.containers.length > 1 
                        ? `${errors.containers.length} Recipientes` 
                        : `${errors.containers.length} Recipiente`) 
                    : '')
            )
            setData({
                modal:true,
                errors:`Este lubricante no se puede eliminar porque está siendo usado en: ${containersError && containersError} ${componentsError && componentsError}` ,
                severity:'warning',
                position:position
            })
        }
        if(deleteStatus === 'success'){
            setData({
                modal:true,
                errors:message,
                severity:deleteStatus ? 'success' : 'warning',
                position:position
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[deleteStatus])

    return (<>
        <CustomButton 
        popoverTitle='Eliminar lubricante'
        action={(e:any)=>getClickPosition(e,()=>validate(item))}
        status={status} 
        variant='iconButton' 
        icon ='delete' />
    </>);
}

export default React.memo(DeleteLubricantform);
