import { CircularProgress, Icon, IconButton } from '@material-ui/core'
import React from 'react'
import { LubricationPointInterface } from '../../equipments/services/service.lubricationPoints'
import { RouteInterface, useAssignCancelRoutes } from '../services/service.routes'

const AssignCancelRoute:React.FC<{equipment:LubricationPointInterface,route:RouteInterface}> = ({equipment,route}) =>{

    const { assign, cancel, status } = useAssignCancelRoutes()
    
    return(
        (status==='loading') ? <CircularProgress size='24px'/>:
        JSON.parse(equipment?.routes).includes(route.routeName) ? <>
            <IconButton  size='small' disabled ><Icon style={{color:'green'}}>check</Icon></IconButton>
            <IconButton onClick={()=>cancel(equipment,route.routeName)} size='small'><Icon >delete</Icon></IconButton>
        </> : <IconButton onClick={()=>assign(equipment,route.routeName)} size='small'><Icon style={{color:'red'}}>add-circle</Icon></IconButton>
    )
}    

export default React.memo(AssignCancelRoute)