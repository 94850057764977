import { Button, CircularProgress, createStyles, makeStyles, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { PlantInterface } from '../plants/service.plant';
import { EquipmentInterface, useEquipmentVerification } from './services/service.lubricationPoints';
import { useToken } from '../session/service.session';
import SelectCriticality from '../criticalities/components/SelectCriticality';
import SelectSector from '../sectors/atom/SelectSector';
/* import SelectType from '../types/molecules/SelectType';
 */import { errorsLog } from '../../utils/utils';
import { CriticalityTypes } from '../criticalities/types/types';
import SelectTypeBy from './../types/molecules/SelectTypeBy';
import { enumTgdTypes } from '../types/Types/types';


const useStyles =  makeStyles((theme) => createStyles({
    formGrid:{
        display:'flex',
        /* gap: '16px',*/
        flexWrap:'wrap',
        flexDirection:'column'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

interface Props{
    plant:PlantInterface
    handleState?:((data: EquipmentInterface) => void)
    handleNext:()=>void
    defaultState?:EquipmentInterface | null
}


const EquipmentVerification:React.FC<Props> = ({plant,handleNext,handleState,defaultState}) => {

    
    const _tagFP =plant.tagFP
    const classes = useStyles();
    const token = useToken();
    const [verifyEquipmentLoad,{error,status,data}] = useEquipmentVerification()
    const { register, handleSubmit, setValue, watch, errors } = useForm({
        shouldUnregister: false
      });
   
    const onSubmit = (data:EquipmentInterface) =>{
        verifyEquipmentLoad({
            data:{
                sector: data.sector.toUpperCase(),
                plantTag: data.plantTag.toUpperCase(),
                tagFP: data.tagFP.toUpperCase(),
                equipment: data.equipment.toUpperCase(),
                type: data.type.toUpperCase(),
                criticality:data.criticality.toUpperCase()
            },
            token
        });
    };

    useEffect(() => {
        if((status === 'success') && handleState && (data !=="Existe el equipo") && (data !=="Existe el tag de planta")){
            handleState(data)
            handleNext()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[status]);  

     useEffect(() => {
        if(_tagFP){
            register({name:'tagFP'})
            register({name:'criticality',value:''},{required:{value:true,message:'requerida'}})
            register({name:'type',value:''},{required:{value:true,message:'obligatorio'}})
            register({name:'sector',value:''},{required:{value:true,message:'obligatorio'}})
            setValue('tagFP',_tagFP)
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[_tagFP]);  
    
    errorsLog(errors)
   

    if(status === 'loading') return <CircularProgress size='20px' color='primary' />
    return (
            <form className={classes.formGrid} onSubmit={handleSubmit(onSubmit)}>
                {error && <Alert severity='error'>Ha ocurrido un problema. {error} </Alert>}
                {data && (status === 'success') && (data ==="Existe el equipo")  && <><Alert severity='warning'>El nombre del equipo ya existe</Alert><br/></>}
                {data && (status === 'success') && (data ==="Existe el tag de planta")  && <><Alert severity='warning'>El código de planta ya existe</Alert><br/></>}
                {data && (status === 'success') && (data !=="Existe el equipo") && (data !=="Existe el tag de planta") && <><Alert severity='success'>Los datos del equipo han sido aprobados para la carga de puntos de lubricación </Alert><br/></>}


                {/* select sector */}

                <TextField
                    inputRef={register({
                        required:{value:true,message:'requerido'},
                    })} 
                    name="plantTag" 
                    defaultValue={defaultState?.plantTag || ''}
                    variant='outlined'
                    label={errors?.plantTag?.message ? `Codigo de planta ${errors?.plantTag?.message}` :'Codigo de planta' } 
                    color={errors?.plantTag?.message  ? 'primary' : 'secondary'}
                    size="small"
                    />
                <br/>     

                <TextField
                    inputRef={register({
                        required:{value:true,message:'requerido'},
                    })} 
                    name="equipment" 
                    defaultValue={defaultState?.equipment || ''}
                    variant='outlined'
                    label={errors?.equipment?.message ? `Nombre del Equipo ${errors?.equipment?.message}`: 'Nombre del Equipo'} 
                    color={errors?.equipment?.message ? 'primary' : 'secondary'}
                    size="small"
                    />
                <br/>       

                <SelectSector
                error={errors?.sector?.message} 
                tagFP={_tagFP} 
                onChange={(value:string) => setValue('sector',value)}
                defaultValue={watch('sector',defaultState?.sector)}
                />
                <br/>

                <SelectTypeBy
                error={errors?.type?.message}
                by={{key:'type',value:enumTgdTypes.equipment}}
                tagFP={_tagFP}
                onChange={(value)=>setValue('type',value)}
                defaultValue={watch('type')}
                />
        
                
                <br/>
               
                <SelectCriticality
                    by={{key:'criticalityType', value:CriticalityTypes.equipment}}
                    error={errors?.criticality?.message || 'Criticidad'} 
                    tagFP={_tagFP}
                    onChange={(value)=>setValue('criticality',value)}
                    defaultValue={watch('criticality',defaultState?.criticality)}
                />
                <br />
                
                <Button type='submit' variant='outlined' color='secondary'> Configurar </Button>

            </form>

    );
}

export default EquipmentVerification;
