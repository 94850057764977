import React from 'react';
//material
import {Button, Dialog, DialogActions, DialogContent, IconButton, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import PageContainer from '../pageContainers/PageContainer';


const useStyles = makeStyles((theme) => createStyles({
	root: {
		margin: 0,
		padding: theme.spacing(0),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
}))


const DialogTitle:React.FC = (props:any) => {
	const classes = useStyles()
	const { children,onClose, ...other } = props;
	return (
	<MuiDialogTitle disableTypography className={classes.root} {...other}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
		<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
		</IconButton>
		) : null}
	</MuiDialogTitle>
	);
};


interface Props{
	open:boolean
	handleClose?:()=>void | null
	children?:React.ReactNode
	title?:string 
	header?:React.ReactNode
	fullWidth?:boolean
	fullScreen?:boolean
	footerActions?:React.ReactNode
	defaultButton?:React.ReactNode
}

const TgdModal:React.FC<Props> = ({open,handleClose,children,title,fullWidth,fullScreen,footerActions,defaultButton,header}) => {

	return (
			<Dialog fullWidth={!!fullWidth} fullScreen={fullScreen} onClose={handleClose} /* aria-labelledby="customized-dialog-title" */ maxWidth='md' open={open} >
				
				{(title || header) &&
					<DialogTitle >
						{title && <PageContainer> {title}</PageContainer>}
						{ header && header}
					</DialogTitle>
				}

				<DialogContent dividers>
					{children}
				</DialogContent>
				<DialogActions><>
					{defaultButton 
					? 	defaultButton
					:	<Button autoFocus onClick={handleClose} variant="outlined">
						Cerrar
					</Button>}
					{footerActions && footerActions}
					</>
				</DialogActions>
			</Dialog>
	)
}

export default React.memo(TgdModal);
