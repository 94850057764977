import React, { useEffect, useState } from 'react'
import SimpleAutocompleteSelect from '../../../../components/autocomplete/SimpleAutocompleteSelect';
import SelectSimpleEntity from '../../../../components/select/SelectSimpleEntity'
import { PersonInterface } from '../../../persons/interfaces/userInterfaces';
import { usePlantOperators } from '../../../persons/services/service.person';
import { useLangLabels } from './../../../language/services/useSetLabel';


interface Props {
    error?:string
    tagFP:string
    onChange:(item?:any)=>void
    complete?:boolean
    defaultValue?:any
    disabled?:boolean 
}

const SelectOperator:React.FC<Props> = ({tagFP, onChange, complete, defaultValue, error, disabled}) => {

    const showTitle = 'name'
    const { lang } = useLangLabels()
    const {data:operators,} = usePlantOperators(tagFP)
    const filteredValues = operators?.filter((i:PersonInterface)=> i.lubricatorNumber !== 0)
    const label = lang.routes.operator
    const renderOption = (option:PersonInterface)=> <span>{option.lubricatorNumber} | {option.name}</span>
 
    const handleChange = (value:any) => {
        if(complete){
            const result = operators?.find((o)=>o[showTitle] === value) as PersonInterface
            onChange(result)
        }else{
            onChange(value)
        }
    }

    return(
        <SimpleAutocompleteSelect
        {...{
            disabled,
            error,
            items:filteredValues || [],
            defaultValue,
            showTitle,
            label,
            onChange:handleChange,
            renderOption
        }}
        />
    )
}

export default SelectOperator