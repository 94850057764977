
import { features } from 'process';
import { SectorInterface } from '../../sectors/services/service.sectors';
import { LangType } from './interfaces';


export interface language{
    words:{
        and:string,
        of:string,
        thisMasc:string,
        thisfem:string,
        available:string
        availables:string
    },
    globalentityValues:{
        id:string
        tagFP:string
        tagTGD:string
        tagSTI:string
    }
    messages:{
        featureNotAvailableOffline:string
        features:{
            collectorConfig:string,
            supervisorSurvey:string,
            supervisorClientVisor:string
        },
        status:{
            success:string,
            error:string,
            loading:string, 
        }
    }
    grid:{
        noRowsLabel: string,
        errorOverlayDefaultLabel: string,
        toolbarColumns: string,
        toolbarColumnsLabel: string,
        toolbarFilters: string,
        toolbarFiltersLabel: string,
        toolbarFiltersTooltipHide: string,
        toolbarFiltersTooltipShow: string,
        columnsPanelTextFieldLabel: string,
        columnsPanelTextFieldPlaceholder: string,
        columnsPanelDragIconLabel: string,
        columnsPanelShowAllButton: string,
        columnsPanelHideAllButton: string,
        toolbarExport: string,
        toolbarExportLabel: string,
        toolbarExportCSV: string,
    },
    filters:{
        addFilter:string,
        deleteFilters:string,
        add:string,
        searchByTerm:string,
        closeFilters:string,
        downloadData:string,
        filterData:string,
        all:string
    },
    sendEmail:{
        title:string,
        att:string,
        charge:string,
        recipient:string,
        emailBody:string,
    },
    crudActions:{
        update:string
        create:string
        delete:string
        archive:string
        desactivate:string
        activate:string
    },
    inputFields:{
        autocomplete:{
            clearText:string
            closeText:string
            openText:string
            loadingText:string
            email:string
        },
    },
    validations:{
        messages:{
            macAddress:string,
            justNumbers:string,
            justText:string,
            required:string,
            minLength:string,
            maxLength:string,
            allReadyExist:string
        }
    }
    ui:{
        components:{
            viewMode:{
                title:string
            }
            name:string
        }
    }
    pages:{
        realTime:{
            sensors:string,
            equipments:string
        }
    }
    login:{
        form:{
            label:{
                user:string,
                password:string
            }
        }
        navigation:{
            name:string
        }
        legend:{
            years:string
        }
    },
    demo:{
        form:{
            completeName:string
            phone:string,
            company:string,
            email:string
        }
    },
    persons:{
        plural:string
        singular:string,
        name:string
        lastName:string
        adress:string
        email:string
        client:string
        clients:string
        operator:string
        operators:string
        supervisor:string
        supervisors:string
        superAdmin:string
        superAdmins:string
        admin:string
        admins:string
    }
    factories:{
        singular:string,
        plural:string,
        location:string,
        name:string,
        businessName:string,
        referrer:string,
        tagF:string,
    },
    plants:{
        singular:string,
        plural:string,
        factoryId:string,
        location:string,
        name:string,
        plantId:string,
        referrer:string,
        tagFP:string,
        state:string,
    },
    language:{
        label:string,
        spanish:string,
        english:string,
    },
    formMessages:{
        required:string,
        float:string
        invalidEmail:string
    },
    actionTypes:{
        view:string,
        apply:string,
        login:string,
        logout:string,
        create:string,
        deleteItem:string,
        update:string,
        assign:string
        save:string,
    },
    types:{
        plural:string
        singular:string
        form:{
            name:string
            type:string
        }
    }
    observations:{
        singular:string,
        plural:string,
    }
    addedReasons:{
        plural:string,
        singular:string,
        reason:string
    },
    anomalies:{
        singular:string,
        plural:string,
        anomalyName:string
    }
    news:{
        singular:string,
        plural:string,
        sector:string
        area:string,
        equipment:string,
        type:string,
        newsDate:string,
        description:string,
    }
    collectors:{
        plural:string
        singular:string
        form:{
            name:string
            serialNumber:string
            collectorCode:string
            id:string
            tagFP:string
            state:string
            admissionDate:string
            egressDate:string
        }
    }
    criticalities:{
        title:{
            singular:string
            plural:string
        },
        form:{
            descripton:string
            number:string
            periodicity:string
            color:string
            type:string
            days:string
        }
    }
    lubricantForm:{
        singular:string,
        plural:string
        type:string,
        name:string,
        brand:string,
        measureUnit:string,
        requiresObservation:string
    },
    containers:{
        title:{
            singular:string,
            plural:string,
        },
        form:{
            name:string
            label:{
                name:string,
                lubricantType:string,
                lubricant:string,
                capacity:string,
                coefficient:string,
                code:string,
                type:string
            }
        }
    },
    sectors:{
        title:{
            singular:string,
            plural:string,
        },
        form:{
            name:string,
            label:{
                sector: string
                sectorName: string
                code: string
            }
        }

    },
    areas:{
        title:{
            singular:string,
            plural:string,
        },
        form:{
            name:string,
            label:{
                area: string
                email: string
                emailHelper:string
                referrer: string
            }
        }
    },
    components:{
        title:{
            singular:string,
            plural:string,
        },
        form:{
            name:'',
            label:{
                component: string,
                code: string,
            }
        }
    },
    elements:{
        title:{
            singular:string,
            plural:string,
        },
        form:{
            name:'',
            label:{
                element: string,
                code: string,
            }
        }
    },
    supplies:{
        title:{
            singular:string,
            plural:string
            form:{
                name:string,
                label:{
                    type: string,
                    supply: string,
                    description:string
                }
            }
            
        }
        HERRAMIENTA:{
            title:{
                singular:string,
                plural:string,
            },
            form:{
                name:'',
                label:{
                    type: string,
                    supply: string,
                    description:string
                }
            }
        }
        CONSUMIBLE:{
            title:{
                singular:string,
                plural:string,
            },
            form:{
                name:'',
                label:{
                    type: string,
                    supply: string,
                    description:string
                }
            }
        }
        PROTECCION:{
            title:{
                singular:string,
                plural:string,
            },
            form:{
                name:'',
                label:{
                    type: string,
                    supply: string,
                    description:string
                }
            }
        },
        OBSERVACION:{
            title:{
                singular:string,
                plural:string,
            },
            form:{
                name:'',
                label:{
                    type: string,
                    supply: string,
                    description:string
                }
            }
        }
    },
    permissions:{
        plural:string,
        singular:string,
        permissionName:string,
        description:string,
        code:string,
        area:string,
        sector:string,
        sectorHelper:string
        areaHelper:string
    }
    routes:{
        plural:string,
        singular:string,
        operator:string
        periodicity:string
        route:string
        routeName:string
        startDate:string
        state:string
        validations:{
            allRouteExistance:string
            lubricatorRouteExistance:string
            ableToCreateRoute:string
        }
    }
    iotDevices:{
        actions:{
            desactivate:string
            activate:string
        }
        title:{
            singular:string,
            plural:string
        }
        form:{
            label:{
                device:string,
                deviceCode:string,
                admissionDate:string,
                egressDate:string,
                state:string,
                location:string,
                lowReason:string,
                batteryCapacity:string,
                lowBatteryAlarm:string,
            }
        }
    },
    iotSensorTypes:{
        title:{
            singular:string,
            plural:string
        }
        form:{
            label:{
                sensorType:string,
                sensorTypeCode:string,
            }
        }
    },
    STISensors:{
        title:{
            singular:string,
            plural:string
        },
        form:{
            label:{
                sensorType:string,
                sensorTypeCode:string,
                sensorAddress:string,
                channelQuantity:string,
                permanent:string,
                batteryCapacity:string,
            }
        }
        booleanSelector:{
            data:{
                fixed:string,
                mobile:string,
            }
        }
    },
    STIEquipments:{
        title:{
            singular:string,
            plural:string
        },
        form:{
            label:{
                equipment:string,
                labelId:string,
                sector:string,
                plantTag:string,
                tagSTI:string,
                type:string,
                brand:string,
                model:string,
                function:string,
                location:string,
                observations:string,
                admissionDate:string,
                egressDate:string,
                state:string,
                lowReason:string,
                criticality:string,
            }
        },
    },
    STISensorizedEquipment:{
        title:{
            singular:string,
            plural:string,
        },
        form:{
            label:{
                sensorTypeCode:string
                sensorType:string
                sensorAddress:string
                iotDeviceCode:string
                plantTag:string
                equipment:string
                tagSTI:string
                element:string
                sector:string
                location:string
                criticality:string
                observations:string
                state:string
                lowReason:string
                admissionDate:string
                egressDate:string
                temperature:string
                vibration:string
                xAxis:string
                yAxis:string
                zAxis:string
                channels:string
                pressure:string
            }
        }
    }

}

export const useLang = (lang:LangType):language =>{

    const es:language= {
        words:{
            and:'y',
            of:'de',
            thisMasc:'Este',
            thisfem:'Esta',
            available:'disponible',
            availables:'disponibles'
        },
        globalentityValues:{
            id:'Id',
            tagFP:'Planta',
            tagSTI:'Tag STI',
            tagTGD:'Tag TGD',
        },
        messages:{
            featureNotAvailableOffline:'Esta funcion solo se encuentra habilitada en la aplicación de la nube',
            features:{
                collectorConfig:'Pronto podrá sincronizar y configurar el nuevo colector de TGD',
                supervisorSurvey:'Pronto podrá relevar los datos de un lubricador',
                supervisorClientVisor:'Pronto podrá ver el panel de un cliente',
            },
            status:{
                success:'Todo ha ido bien',
                error:'Hubo un error',
                loading:'Cargando', 
            }
        },
        grid:{
            noRowsLabel: 'No hay datos.',
            errorOverlayDefaultLabel: 'Ha ocurrido un error.',
            toolbarColumns: 'Columnas',
            toolbarColumnsLabel: 'Seleccionar columnas',
            toolbarFilters: 'Filtros',
            toolbarFiltersLabel: 'Mostrar filtros',
            toolbarFiltersTooltipHide: 'Esconder filtros',
            toolbarFiltersTooltipShow: 'Mostrar filtros',
            columnsPanelTextFieldLabel: 'Buscar columna',
            columnsPanelTextFieldPlaceholder: 'Titulo de columna',
            columnsPanelDragIconLabel: 'Reordenar columna',
            columnsPanelShowAllButton: 'Mostrar todo',
            columnsPanelHideAllButton: 'Esconder todo',
            toolbarExport: 'Exportar',
            toolbarExportLabel: 'Exportar',
            toolbarExportCSV: 'Descargar as CSV',
        },
        filters:{
            addFilter:'Agregar filtro',
            deleteFilters:'Eliminar filtros',
            add:'Agregar',
            searchByTerm:'Buscar por término',
            closeFilters:'Cerrar filtros',
            downloadData:'Descargar datos como .xlsx ',
            filterData:'Filtrar datos',
            all:'Todos'
        },
        sendEmail:{
            title:'Enviar por email',
            att:'atte.',
            charge:'Area de lubricacion de TGD Company',
            recipient:'Este email está siendo enviado a',
            emailBody:'Aquí tu mensaje...',
        },
        crudActions:{
            update:'Editar',
            create:'Crear',
            delete:'Eliminar',
            archive:'Archivar',
            desactivate:'Desactivar',
            activate:'Activar'
        },
        inputFields:{
            autocomplete:{
                email:'Email inválido',
                clearText:'Limpiar',
                closeText:'Cerrar',
                openText:'Abrir',
                loadingText:'Cargando..'
            },
            
        },
        validations:{
            messages:{
                macAddress:'Mac address inválida',
                justNumbers:'Solo números',
                justText:'Solo texto',
                required:'Requerido',
                minLength:'Demasiado corto',
                maxLength:'Demasiado largo',
                allReadyExist:'Ya existe'

            },
        },
        ui:{
            components:{
                viewMode:{
                    title:'Cambiar vista'
                },
                name:'Nombre'

            }
        },
        pages:{
            realTime:{
                sensors:'Sensores',
                equipments:'Equipos'
            }
        },
        login:{
            form:{
                label:{
                    user:'Usuario',
                    password:'Contraseña'
                }
            },
            navigation:{
                name:'Solicitar demo'
            },
            legend:{
                years:'Años'
            }
        },
        demo:{
            form:{
                completeName:'Nombre completo',
                phone:'Teléfono',
                company:'Compañía',
                email:'Email'
            }
        },
        persons:{
            plural:'Personas',
            singular:'Persona',
            name:'Nombre',
            lastName:'Apellido',
            adress:'Dirección',
            email:'Email',
            client:'Cliente',
            clients:'Clientes',
            operator:'Lubricador',
            operators:'Lubricadores',
            supervisor:'Supervisor',
            supervisors:'Supervisores',
            superAdmin:'Super admin',
            superAdmins:'Super admins',
            admin:'Admin',
            admins:'Admins',
        },
        language:{
            label:'Idioma',
            spanish:'Español',
            english:'Ingles',
        },
        formMessages:{
            required:'Requerido',
            float:'Solo Numeros',
            invalidEmail:'Email no válido'
        },
        actionTypes:{
            view:'Ver',
            apply:'Solicitar',
            login:'Ingresar',
            logout:'Salir',
            create:'Crear',
            deleteItem:'Eliminar',
            update:'Editar',
            assign:'Asignar',
            save:'Guardar'
        },
        types:{
            plural:'Tipo',
            singular:'Tipos',
            form:{
                name:'Nombre',
                type:'Tipo'
            }
        },
        factories:{
            singular:'Fábrica',
            plural:'Fábricas',
            location:'ubicación',
            name:'Nombre fabrica',
            businessName:'Razón social',
            referrer:'referente',
            tagF:'Tag de fábrica'
        },
        plants:{
            singular:'Planta',
            plural:'Plantas',
            factoryId:'Id de fábrica',
            location:'Dirección',
            name:'Nombre',
            plantId:'Id de planta',
            referrer:'Referente',
            tagFP:'Tag de planta Tgd',
            state:'Estado',
        },
        observations:{
            singular:'Observación',
            plural:'Observaciones',
        },
        addedReasons:{
            plural:'Motivos agregados',
            singular:'Motivo agregado',
            reason:'Nombre motivo agregado'
        },
        anomalies:{
            singular:'Anomlía',
            plural:'Anomalias',
            anomalyName:'Nombre de anomalia'
        },
        news:{
            singular:'Novedad',
            plural:'Nodvedades',
            sector:'Sector de la novedad',
            area:'Area',
            equipment:'Equipo',
            type:'Tipo de novedad',
            newsDate:'Fecha de novedad',
            description:'Descripcion',
        },
        criticalities:{
            title:{
                singular:'Criticidad',
                plural:'Criticidades'
            },
            form:{
                descripton:'Nombre',
                number:'Numero de prioridad',
                periodicity:'Periodicidad',
                color:'Color',
                type:'Tipo de criticidad',
                days:'Prioridad en días'
            }
        },
        collectors:{
            plural:'Colectores',
            singular:'Colector',
            form:{
                id:'Id',
                name:'Nombre',
                serialNumber:'Numero de serie',
                collectorCode:'Código de colector',
                tagFP:'Planta',
                state:'Estado',
                admissionDate:'Fecha de ingreso',
                egressDate:'Fecha de Egreso',
            }
        },

        lubricantForm:{
            singular:'Lubricante',
            plural:'Lubricantes',
            type:'Tipo de lubricante',
            name:'Nombre',
            brand:'Marca',
            measureUnit:'Unidad de medida',
            requiresObservation:'Requiere Observacion'
        },
        containers:{
            title:{
                singular:'Recipiente',
                plural:'Recipientes',
            },
            form:{
                name:'',
                label:{
                    name:'Nombre',
                    lubricantType:'Tipo Lubricante',
                    lubricant:'Lubricante',
                    capacity:'Capacidad',
                    coefficient:'Coeficiente',
                    code:'Código',
                    type:'Tipo'
                }
            }
        },
        sectors:{
            title:{
                singular:'sector',
                plural:'sectores',
            },
            form:{
                name:'',
                label:{
                    sector: 'Sector en planta',
                    sectorName: 'Sector',
                    code: 'Código',
                }
            }
    
        },
        areas:{
            title:{
                singular:'Área',
                plural:'Áreas',
            },
            form:{
                name:'',
                label:{
                    area: 'Nombre área',
                    email: 'Emails',
                    emailHelper:'Se pueden asignar varios emails, separados por coma y sin espacios',
                    referrer: 'Nombre referente',
                }
            }
    
        },
        components:{
            title:{
                singular:'componente',
                plural:'componentes',
            },
            form:{
                name:'',
                label:{
                    component: 'Nombre',
                    code: 'Código',
                }
            }
        },
        elements:{
            title:{
                singular:'elemento',
                plural:'elementos',
            },
            form:{
                name:'',
                label:{
                    element: 'Nombre',
                    code: 'Código',
                }
            }
        },
        supplies:{
            title:{
                singular:'Insumo',
                plural:'Insumos',
                form:{
                    name:'',
                    label:{
                        type: 'Tipo',
                        supply: 'Nombre',
                        description:'Descripción'
                    }
                }
            },
            HERRAMIENTA:{
                title:{
                    singular:'herramienta',
                    plural:'herramientas',
                },
                form:{
                    name:'',
                    label:{
                        type: 'Tipo',
                        supply: 'Nombre',
                        description:'Descripción de la herramienta '
                    }
                }
            },
            CONSUMIBLE:{
                title:{
                    singular:'consumible',
                    plural:'consumibles',
                },
                form:{
                    name:'',
                    label:{
                        type: 'Tipo',
                        supply: 'Nombre',
                        description:'Descripción del consumible '
                    }
                }
            },
            PROTECCION:{
                title:{
                    singular:'proteccion',
                    plural:'protecciones',
                },
                form:{
                    name:'',
                    label:{
                        type: 'Tipo',
                        supply: 'Nombre',
                        description:'Descripción de la protección '
                    }
                }
            },
            OBSERVACION:{
                title:{
                    singular:'observacion',
                    plural:'observaciones',
                },
                form:{
                    name:'',
                    label:{
                        type: 'Tipo',
                        supply: 'Nombre',
                        description:'Descripción de la observaciones'
                    }
                }
            }
        },
        permissions:{
            plural:'Permisos',
            singular:'Permiso',
            permissionName:'Nombre del permiso',
            description:'Descripcion',
            code:'codigo',
            area:'area',
            sector:'sector',
            sectorHelper:'Convierte este permiso en un permiso global para el sector seleccionado (todos los equipos del sector requeriran este permiso),de lo contrario al dejar este campo vacio podras usar este permiso de manera aislada',
            areaHelper:'Ésta es el área a la cual se le debera pedir la solicitud o la emision del permiso solicitado'
        },
        routes:{
            singular:'Ruta',
            plural:'Rutas',
            operator:'Operador',
            periodicity:'Periodicidad en días',
            route:'Ruta',
            routeName:'Nombre de ruta',
            startDate:'Fecha de inicio',
            state:'Estado',
            validations:{
                allRouteExistance:'Esta ruta ya existe para todos los operadores de esta planta',
                lubricatorRouteExistance:'Este nombre de ruta ya existe para el lubricador',
                ableToCreateRoute:'Solo podra crearla para lubricadores faltantes',

            }
        },
        iotDevices:{
            actions:{
                desactivate:'Desactivar dispositivo',
                activate:'Activar dispositivo'
            },
            title:{
                singular:'dispositivo',
                plural:'dispositivos'
            },
            form:{
                label:{
                    device:'Nombre dispositivo',
                    deviceCode:'Mac address',
                    admissionDate:'Fecha de admision',
                    egressDate:'Fecha de egreso',
                    state:'Desactivado',
                    location:'Ubicación',
                    lowReason:'Motivo de baja',
                    batteryCapacity:'Capacidad de batería',
                    lowBatteryAlarm:'Alarma de batería baja',
                }
            }
        },
        iotSensorTypes:{
            title:{
                singular:'Tipo de Sensor',
                plural:'Tipos de sensores'
            },
            form:{
                label:{
                    sensorType:'Tipo de sensor',
                    sensorTypeCode:'Código',
                }
            }
        },
        STISensors:{
            title:{
                singular:'Sensor',
                plural:'Sensores'
            },
            form:{
                label:{
                    sensorType:'Tipo de Sensor',
                    sensorTypeCode:'Código del tipo de sensor',
                    sensorAddress:'Mac address',
                    channelQuantity:'Cantidad de canales',
                    permanent:'Tipo de ubicacion',
                    batteryCapacity:'Valor de batería máximo',
                }
            },
            booleanSelector:{
                data:{
                    fixed:'PERMANENTE',
                    mobile:'TEMPORAL',
                }
            }
        },
        STIEquipments:{
            title:{
                singular:'Equipo ',
                plural:'Equipos STI'
            },
            form:{
                label:{
                    equipment:'Equipo',
                    labelId:'Nº de Etiqueta',
                    sector:'Sector',
                    plantTag:'Tag de planta',
                    tagSTI:'Tag STI',
                    type:'Tipo',
                    brand:'Marca',
                    model:'Modelo',
                    function:'Función',
                    location:'Ubicación',
                    observations:'Observaciones',
                    admissionDate:'Fecha de admision',
                    egressDate:'Fecha de egreso',
                    state:'Estado',
                    lowReason:'Motivo de baja',
                    criticality:'Criticidad',
                }
            },
        },
        STISensorizedEquipment:{
            title:{
                singular:'Sensorización',
                plural:'Sensorizaciones',
            },
            form:{
                label:{
                    sensorTypeCode:'Código del typo de sensor',
                    vibration:'Vibración',
                    sensorType:'Tipo de sensor',
                    sensorAddress:'Mac address del sensor',
                    iotDeviceCode:'Codigo Gateway',
                    plantTag:'Tag de planta',
                    equipment:'Equipo',
                    tagSTI:'Tag STI',
                    element:'Elemento',
                    sector:'Sector',
                    location:'Ubicación',
                    criticality:'Criticidad',
                    observations:'Observaciones',
                    state:'Estado',
                    lowReason:'Motivo de baja',
                    admissionDate:'Fecha de admision',
                    egressDate:'Fecha de egreso',
                    temperature:'Temperatura',
                    xAxis:'Eje X',
                    yAxis:'Eje Y',
                    zAxis:'Eje Z',
                    channels:'Canales',
                    pressure:'Presion',
                }
            }
        }
    }

    const en:language ={
        words:{
            and:'and',
            of:'of',
            thisMasc:'this',
            thisfem:'this',
            available:'available',
            availables:'availables'
        },
        globalentityValues:{
            id:'Id',
            tagFP:'Plant',
            tagSTI:'STI Tag',
            tagTGD:'TGD Tag',
        },
        messages:{
            featureNotAvailableOffline:'This feature is an online only feature',
            features:{
                collectorConfig:'We sorry! Soon you will be able to sync and config your new TGD Collector',
                supervisorSurvey:'We sorry! Soon you will be able to survey as Lubricator user',
                supervisorClientVisor:'We sorry! Soon you will be able to se your client dashboard'
            },
            status:{
                success:'Everithing it`s been successfully',
                error:'we found a problem',
                loading:'loading', 
            }
        },
        grid:{
            noRowsLabel: 'No data',
            errorOverlayDefaultLabel: 'An error has occourred',
            toolbarColumns: 'Columns',
            toolbarColumnsLabel: 'Select Columns',
            toolbarFilters: 'Filters',
            toolbarFiltersLabel: 'Show Filters',
            toolbarFiltersTooltipHide: 'Hide filters',
            toolbarFiltersTooltipShow: 'Show Filters',
            columnsPanelTextFieldLabel: 'Search column',
            columnsPanelTextFieldPlaceholder: 'Column title',
            columnsPanelDragIconLabel: 'Reorder Column',
            columnsPanelShowAllButton: 'Show all',
            columnsPanelHideAllButton: 'Hide all',
            toolbarExport: 'Export',
            toolbarExportLabel: 'Export',
            toolbarExportCSV: 'Download Csv',
        },
        filters:{
            addFilter:'Add filter',
            deleteFilters:'Delete filters',
            add:'Add',
            searchByTerm:'Search by term',
            closeFilters:'Close filters',
            downloadData:'Download data as .xlsx ',
            filterData:'Filter data',
            all:'All'
        },
        sendEmail:{
            title:'Send via email',
            att:'att.',
            charge:'Lubrication area, TGD company',
            recipient:'This email will be sent to',
            emailBody:'Your message here...',
        },
        crudActions:{
            update:'Update',
            create:'Create new',
            delete:'Delete',
            archive:'Archive',
            desactivate:'Desactivate',
            activate:'Activate'
        },
        inputFields:{
            autocomplete:{
                email:'invalid email',
                clearText:'Clear',
                closeText:'Close',
                openText:'Open',
                loadingText:'Loading..'
            },
            
        },
        validations:{
            messages:{
                macAddress:'Invalid MAC address',
                justNumbers:'Just numbers',
                justText:'Just text',
                required:'Required',
                minLength:'To short',
                maxLength:'To long',
                allReadyExist:'All ready exist'
            },
        },
        ui:{
            components:{
                viewMode:{
                    title:'ChangeView'
                },
                name:'Name'
            }
        },
        pages:{
            realTime:{
                sensors:'sensors',
                equipments:'equipments'
            }
        },
        login:{
            form:{
                label:{
                    user:'User',
                    password:'Password'
                }
            },
            navigation:{
                name:'Get trial demo'
            },
            legend:{
                years:'years'
            }
        },
        demo:{
            form:{
                completeName:'Complete name',
                phone:'Phone',
                company:'Company',
                email:'Email'
            }
        },
        persons:{
            plural:'Persons',
            singular:'Person',
            name:'Name',
            lastName:'Last name',
            adress:'Adress',
            email:'Email',
            client:'Client',
            clients:'Clients',
            operator:'Lubricator',
            operators:'Lubricators',
            supervisor:'Supervisor',
            supervisors:'Supervisors',
            superAdmin:'Super admin',
            superAdmins:'Super admins',
            admin:'Admin',
            admins:'Admins',
        },
        language:{
            label:'Language',
            spanish:'Spanish',
            english:'English',
        },
        formMessages:{
            required:'Required',
            float:'Only a number',
            invalidEmail:'Invalid email'
        },
        actionTypes:{
            view:'View',
            apply:'Apply',
            login:'Login',
            logout:'Logout',
            create:'Create',
            deleteItem:'Delete',
            update:'Update',
            assign:'Assign',
            save:'save'
        },
        factories:{
            singular:'Factory',
            plural:'Factories',
            location:'Location',
            name:'FactoryName',
            businessName:'Business name',
            referrer:'Referer',
            tagF:'Factory tag'
        },
        plants:{
            singular:'Plant',
            plural:'Plants',
            factoryId:'Factory id',
            location:'Adress',
            name:'Name',
            plantId:'PlantId',
            referrer:'Referrer',
            tagFP:'Tgd plant tag',
            state:'State',
        },
        types:{
            plural:'Type',
            singular:'Types',
            form:{
                name:'Name',
                type:'Type'
            }
        },
        observations:{
            singular:'Observation',
            plural:'Observations',
        },
        addedReasons:{
            plural:'Added reasons',
            singular:'Added reason',
            reason:'Added reason name'
        },
        anomalies:{
            singular:'Anomly',
            plural:'Anomalies',
            anomalyName:'Anomaly name'
        },
        news:{
            singular:'New',
            plural:'News',
            sector:'New Sector',
            area:'Area',
            equipment:'Lubrication point',
            type:'New type',
            newsDate:'New date',
            description:'Description',
        },
        collectors:{
            plural:'Collectors',
            singular:'Collector',
            form:{
                id:'Id',
                name:'name',
                serialNumber:'Serial number',
                collectorCode:'Collector',
                tagFP:'Plant',
                state:'State',
                admissionDate:'Admission date',
                egressDate:'Egress date',
            }
        },
        lubricantForm:{
            singular:'Lubricant',
            plural:'Lubricants',
            type:'Type',
            name:'Name',
            brand:'Brand',
            measureUnit:'Measure unit',
            requiresObservation:'Requires observation'
        },
        criticalities:{
            title:{
                singular:'Criticality',
                plural:'Criticalities'
            },
            form:{
                descripton:'Name',
                number:'Priority number',
                periodicity:'Periodicity',
                color:'Color',
                type:'Criticality Type',
                days:'Priority in days'
            }
        },
        containers:{
            title:{
                singular:'Container',
                plural:'Containers',
            },
            form:{
                name:'',
                label:{
                    name:'Name',
                    lubricantType:'LubricantType',
                    lubricant:'Lubricant',
                    capacity:'Capacity',
                    coefficient:'Coefficient',
                    code:'Code',
                    type:'Type'
                }
            }
        },
        sectors:{
            title:{
                singular:'Sector',
                plural:'Sectors',
            },
            form:{
                name:'',
                label:{
                    sector: 'Plant sector',
                    sectorName: 'Sector',
                    code: 'Code',
                }
            }
    
        },
        areas:{
            title:{
                singular:'Area',
                plural:'Areas',
            },
            form:{
                name:'',
                label:{
                    area: 'Area name',
                    email: 'Email list',
                    emailHelper:'you can write a few email coma separated, to send email to',
                    referrer: 'Referrer name',
                }
            }
    
        },
        components:{
            title:{
                singular:'Compoent',
                plural:'Components',
            },
            form:{
                name:'',
                label:{
                    component: 'Name',
                    code: 'Code',
                }
            }
        },
        elements:{
            title:{
                singular:'Element',
                plural:'Elements',
            },
            form:{
                name:'',
                label:{
                    element: 'Name',
                    code: 'Code',
                }
            }
        },
        supplies:{
            title:{
                singular:'Supply',
                plural:'Supplies',
                form:{
                    name:'',
                    label:{
                        type: 'Type',
                        supply: 'Name',
                        description:'Description'
                    }
                },
            },
            HERRAMIENTA:{
                title:{
                    singular:'Tool',
                    plural:'Tools',
                },
                form:{
                    name:'',
                    label:{
                        type: 'Type',
                        supply: 'Name',
                        description:'Tool description'
                    }
                }
            },
            CONSUMIBLE:{
                title:{
                    singular:'Consumable',
                    plural:'Consumables',
                },
                form:{
                    name:'',
                    label:{
                        type: 'type',
                        supply: 'name',
                        description:'Consumable description'
                    }
                }
            },
            PROTECCION:{
                title:{
                    singular:'Protection',
                    plural:'Protections',
                },
                form:{
                    name:'',
                    label:{
                        type: 'Type',
                        supply: 'Name',
                        description:'Protection description'
                    }
                }
            },
            OBSERVACION:{
                title:{
                    singular:'Observation',
                    plural:'Observations',
                },
                form:{
                    name:'',
                    label:{
                        type: 'Type',
                        supply: 'Name',
                        description:'Observation description'
                    }
                }
            }
        },
        permissions:{
            plural:'Permissions',
            singular:'Permission',
            permissionName:'Permission Name',
            description:'Description',
            code:'Code',
            area:'Area',
            sector:'Sector',
            sectorHelper:'Make this permission sector global (all components with the selected sector gonna require this permission)',
            areaHelper:'This will be the current permission issuance area'

        },
        routes:{
            singular:'Route',
            plural:'Routes',
            operator:'Operator',
            periodicity:'Periodicity',
            route:'route',
            routeName:'Route name',
            startDate:'Start date',
            state:'State',
            validations:{
                allRouteExistance:'You can not create this rioute, because allredy exist for all of operators in the sistem',
                lubricatorRouteExistance:'This route exist for operators',
                ableToCreateRoute:'you can create it to another operators',

            }
        },
        iotDevices:{
            actions:{
                desactivate:'Desactivate device',
                activate:'Activate device'
            },
            title:{
                singular:'Device',
                plural:'Devices'
            },
            form:{
                label:{
                    device:'Device name',
                    deviceCode:'Device code',
                    admissionDate:'Admission date',
                    egressDate:'Egress date',
                    state:'State',
                    location:'Location',
                    lowReason:'Low Reason',
                    batteryCapacity:'Battery capacity',
                    lowBatteryAlarm:' Low battery alarm',
                }
            }
        },
        iotSensorTypes:{
            title:{
                singular:'Sensor type',
                plural:'Sensor types'
            },
            form:{
                label:{
                    sensorType:'Sensor type',
                    sensorTypeCode:'Sensor type code',
                }
            }
        },
        STISensors:{
            title:{
                singular:'Sensor',
                plural:'Sensors'
            },
            form:{
                label:{
                    sensorType:'Sensor tipe',
                    sensorTypeCode:'Type sensor code',
                    sensorAddress:'MAC Address',
                    channelQuantity:'Chanel quantity',
                    permanent:'Location Type',
                    batteryCapacity:'Max battery value',
                }
            },
            booleanSelector:{
                data:{
                    fixed:'PERMANENT',
                    mobile:'TEMPORARY',
                }
            }
        },
        STIEquipments:{
            title:{
                singular:'Equipment',
                plural:'STI Equipments'
            },
            form:{
                label:{
                    equipment:'Equipment',
                    labelId:'Label Id',
                    sector:'Sector',
                    plantTag:'Plant Tag',
                    tagSTI:'Tag STI',
                    type:'Type',
                    brand:'Brand',
                    model:'Model',
                    function:'Function',
                    location:'Location',
                    observations:'Observation',
                    admissionDate:'Admission date',
                    egressDate:'Egress date',
                    state:'State',
                    lowReason:'LowReason',
                    criticality:'Criticidad',
                }
            },
        },
        STISensorizedEquipment:{
            title:{
                singular:'Sensorization',
                plural:'Sensorizations',
            },
            form:{
                label:{
                    sensorTypeCode:'sensorTypeCode',
                    vibration:'Vibration',
                    sensorType:'SensorType',
                    sensorAddress:'Sensor`s MAC Address',
                    iotDeviceCode:'Gateway Code',
                    plantTag:'Plant Tag',
                    equipment:'Equipment',
                    tagSTI:'Tag STI',
                    element:'Element',
                    sector:'Sector',
                    location:'Location',
                    criticality:'Criticality',
                    observations:'Observations',
                    state:'State',
                    lowReason:'Low reason',
                    admissionDate:'Admission date',
                    egressDate:'Egress date',
                    temperature:'Temperature',
                    xAxis:'X axis',
                    yAxis:'Y axis',
                    zAxis:'Z axis',
                    channels:'chanels',
                    pressure:'Pressure',
                }
            }
        }
    }

    switch (lang) {
        case 'es':
            return es

        case 'en':
            return en

        default:
            return es
    }

}