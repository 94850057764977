import React from 'react';
import { Divider, Typography, Paper } from '@material-ui/core';
import CustomSidebarContentCard from '../components/CustomSidebarContentCard';

import PageContainer from '../components/pageContainers/PageContainer';
import CreateCategoryForm from '../domains/categories/CreateCategoryForm';
import { CustomIcon } from '../components/buttons/CustomButton';
import FlexContainer from '../components/presentational/FlexContainer';
import CreateSubCategoryForm from '../domains/sub-categories/CreateSubCategoryForm';
import UpdateCategoryForm from '../domains/categories/UpdateCategoryForm';
import ModalButton from '../components/buttons/ModalButton';
import SubcategoryStockGrid from '../domains/stock/SubcategoryStockGrid';
import StockGrid from '../domains/stock/StockGrid'
import PurchaseRequestsGrid from '../domains/purchase-requests/PurchaseRequestsGrid';
import { useCategoriesAndSubcategoriesByTagFP } from '../domains/categories/service.category';
import TgdTabs from '../components/Tabs/TgdTabs';
import TgdRouterTabs from '../components/Tabs/TgdRouterTabs';

import { useTodayNewPurchaseRequest } from '../domains/stock/services/hooks/useTodayNewPurchaseRequest';
import { ComponentUIModes } from '../components/ComponentUIModes';


interface Props{
    tagFP:string | undefined
    defaultPath:string
}

const StockPageManagment:React.FC<Props> = ({tagFP,defaultPath}) => {

    const {data:categories} = useCategoriesAndSubcategoriesByTagFP(tagFP)
    const { purchaseRequests } = useTodayNewPurchaseRequest()

    const configParentCategory = (category:any,mode:ComponentUIModes) => {
        
        const content=<PageContainer>
            <Typography variant='body2'> Editar </Typography>
            <Typography variant='h6'> {category.category} </Typography>
            <Divider/>
            <UpdateCategoryForm defaultValue={category} mode='content'
            />
            <Typography variant='body2'>Agregar</Typography>
            <Typography variant='h6'>Subcategorias</Typography>
            <Divider/>
            <CreateSubCategoryForm 
                parentCategory={category.category}
                tagFP={tagFP}
                mode='content'
            />
        </PageContainer>

        return(
            <>

        {mode === 'content' &&
            content
        }
            
        {mode === 'modal'  &&
        <ModalButton icon='config'>
            {content}
        </ModalButton>}
    </>
        )
    }

    return(<>
        <Paper>
            <PageContainer>
                        <FlexContainer align='center'>
                            <CustomIcon icon='stock' />
                            <Typography variant='h5'>Stock</Typography>
                        </FlexContainer>
                    </PageContainer>
                    <Divider/>
            <TgdRouterTabs
            badge={true}
            //defaultPath={`/${USER_ROUTES.home}/${_dimention}/${/* USER_ROUTES.stock */ USER_ROUTES.factoryManagement}/`}
            defaultPath={`${defaultPath}/`}
            items={[
                {label:'Solicitudes de compra',component:<PurchaseRequestsGrid tagFP={tagFP!} />,badge:purchaseRequests?.quantity},
                {label:'Categorías',component:
                <CustomSidebarContentCard 
                items={categories || []}
                labelIterator='category'
                icon='add'
                popoverTitle='Nueva categoria padre' 
                customTabComponent={<CreateCategoryForm mode='content' tagFP={tagFP} />}
                content={(category) => <>
                    {(category.subcategories.length > 0)
                    ?   <TgdTabs
                        popoverTitle={`Configurar categoria padre`}
                        icon='config'
                        customTabComponent={ configParentCategory(category,'content') }
                        labelIterator='subcategory'
                        items={category.subcategories}
                        content={(subcategory)=><>
                            <SubcategoryStockGrid subcategory={subcategory} />
                            </>}
                        />
                    :   configParentCategory(category,'content')        
                }</>}
                />},
                {label:'Todo el stock',component:<StockGrid tagFP={tagFP!} />}
            ]}
            content={({component})=>component}
            />
        </Paper>

    </>);
};




export default React.memo(StockPageManagment);
