import React from 'react';
import { CircularProgress, IconButton} from '@material-ui/core';
import { useEffect } from 'react';
import { useDeleteSupplyVerification } from './services/service.supplies';
import TgdPopover from '../../components/popovers/TgdPopover';
import { CustomIcon } from '../../components/buttons/CustomButton';
import { useStatusProcessor } from '../errors/services/StatusProcessor';
import { useClickPosition } from '../../utils/hooks/useClickPosition';
import { useSnackbar } from '../../utils/hooks/useSnackbar';
import { SupplyInterface, SupplyType } from './types/SupplyTypes';

interface Props{
    item:SupplyInterface,
    type:SupplyType
}
const DeleteSupplyForm:React.FC<Props> = ({item,type}) => {

    const {errors,status:deleteStatus,validate,data} = useDeleteSupplyVerification()
    const { status, message } = useStatusProcessor({...data,status:deleteStatus})
    const {position,getClickPosition} = useClickPosition()
    const { setData } = useSnackbar()

    useEffect(()=>{
        if(deleteStatus === 'error'){
            const componentsError = (
                (errors?.equipments?.length 
                    ? (errors.equipments.length > 1 
                        ? `${errors.equipments.length} Equipos` 
                        : `${errors.equipments.length} Equipo`) 
                    : '')
            )
            setData({
                modal:true,
                errors:`Este/a ${type} no se puede eliminar porque está siendo usado/a en: ${componentsError}` ,
                severity:'warning',
                position:position
            })
        }

        if(deleteStatus === 'success'){
            setData({
                modal:true,
                errors:message,
                severity:'success',
                position
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[deleteStatus,errors])
    
    if(status === 'loading')  return <CircularProgress size='20px' />
    return (
        item 
            ? <>
                <TgdPopover mode='hover' title={`Eliminar ${item.type.toLowerCase()}`}>
                    <IconButton size='small' onClick={(e)=>getClickPosition(e,()=>validate(item))}>
                        <CustomIcon icon='delete'/>
                    </IconButton>
                </TgdPopover>
            </>
        : null
    );
}

export default React.memo(DeleteSupplyForm);
