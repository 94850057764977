import { ListItemText, Paper, Typography } from '@material-ui/core';
import React from 'react'
import PageContainer from '../../../../components/pageContainers/PageContainer';
import { LubricationPointInterface } from '../../../equipments/services/service.lubricationPoints';
import ColectorList from '../../ColectorList';
import { ColectorDailyLubricantsInterface } from '../lubricants/interfaces/colectorLubricantInterfaces';
import CheckUncheckItem from './CheckUncheckItem';
import { useCheckListController } from './controllers/useCheckListController';
import { CheckListItemsType } from './interfaces/checkListInterfaces';

const CheckList:React.FC<{checkListType:CheckListItemsType}> = ({checkListType}) =>{

    const {data,list,formModel,setState,action} = useCheckListController()

    const lubricantCuantity = (items:LubricationPointInterface[]) => {
        return items.reduce((a, b) => a + (b.leakLevel?.neededQuantity || 0), 0);
    }

    return(<>
        <Paper>
            <PageContainer>
                <Typography variant='h6'>{formModel[checkListType].name.plural.default}</Typography>
            </PageContainer>
            <ColectorList
                items={data && data[checkListType].value }
                iterator={list[checkListType].iterator}
                primaryText={list[checkListType].primaryText }
                secondaryText={list[checkListType].secondaryText}
                buttonActions={(item)=><>
                    <CheckUncheckItem
                    onClick={(value:any)=>action(value,checkListType)} 
                    item={item} 
                    picked={data ? data[checkListType].picked : []} 
                    iterator={list[checkListType].iterator} />
                </>}
                customItemList={(checkListType === 'LUBRICANTE')
                    ?   (item:ColectorDailyLubricantsInterface)=><>
                            <ListItemText primary={item.lubricationPoints.length} secondary={'Puntos'}></ListItemText>
                            <ListItemText primary={`${lubricantCuantity(item.lubricationPoints)} ${item.lubricant.measureUnit}`} secondary={'Necesarios(Aprox.)'}></ListItemText>
                        </>
                    :   (item:any)=><></>
                }
            />
        </Paper>
    </>)
}
export default React.memo(CheckList)