import React from 'react';
import { useSensorTypesByTagFP } from '../services/SensorTypeService';
import {useSensorTypeForm} from '../services/useSensorTypeForm'
import AnyGrid from '../../../../components/grids/AnyGrid';
import CreateSensorTypeForm from '../CreateSensorTypeForm';
import UpdateSensorTypeForm from '../UpdateSensorTypeForm';

interface Props{
    tagFP:string

}

const SensorTypesPage:React.FC<Props> = ({tagFP}) => {
    const {form} = useSensorTypeForm({actionType:'create'},tagFP)
    const {data:devices,isLoading} = useSensorTypesByTagFP(tagFP)

    return (
        <AnyGrid
            title={form.pluralName}
            loading={isLoading}
            items={devices || []}
            headerActions={()=><CreateSensorTypeForm tagFP={tagFP} />}
            itemActions={(device,items:any)=><>
                <UpdateSensorTypeForm item={device} items={items} tagFP={tagFP} />
            </>}
        />
    );
}

export default React.memo(SensorTypesPage);
