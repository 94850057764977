import axios,{AxiosRequestConfig} from 'axios'
import { useEffect, useState } from 'react'
import { queryCache, QueryResult, QueryStatus, useMutation, useQuery } from 'react-query'
import { EquipmentInterface, useEquipmentsByComponent } from '../../equipments/services/service.lubricationPoints'
import { useToken } from '../../session/service.session'
import {URL} from '../../../infrastructure/appConfig/appConfig'
import { useAllAuxByPlant } from '../../auxiliar-data/service.aux';
import { GridModelInterface, MutationQueryObject } from '../../../infrastructure/Interfaces'
import { useStatusProcessor } from '../../errors/services/StatusProcessor';
import { ComponentInterface } from '../types/ComponentTypes'


export const gridModelComponent:GridModelInterface = {
    id:{label:'Id'}, 
    code:{label:'Código'}, 
    component:{label:'Componente'}, 
    date:{label:'Fecha'}, 
    tagFP:{label:'Planta'}, 
}

export const ComponentsCRUDDependencies = [
    'AllComponents',
    'PlantAux',
    'componentsByPlant',
]

//----------------------------
//repo
//---------------------------------------------


export const AllComponents = (_:string,token:string) => {
    axios.defaults.headers.get['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/AllComponents`,

    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const CreateComponent = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateComponent`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}
export const UpdateComponent = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateComponent`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}

export const DeleteComponent = ({data,token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = `Bearer ${token}`
    const config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/DeleteComponent`,
        data
    }
    return axios(config).then((res)=>{return res.data}).catch((err) => {return err})
}


//----------------------------
// ReactQuery
//---------------------------------------------

export const useAllComponents = () => {
    const token = useToken()
    return useQuery(['AllComponents',token],AllComponents,{
        refetchOnWindowFocus:false
    })
}

export const usePlantComponents = (tagFP:string)=> {
    const query = useAllAuxByPlant(tagFP)
    const result:QueryResult<ComponentInterface[]> = {
        ...query,
        data:query.data?.components
    }
    return result
}


//----------------------------
//Mutations
//---------------------------------------------

export const useCreateComponent = () =>{
    const token = useToken()

    const [mutation, data] = useMutation(CreateComponent,{
        onSuccess:()=> ComponentsCRUDDependencies.forEach((dependency)=>{
            queryCache.invalidateQueries(dependency)
        })
    })

    const createComponent = (data:ComponentInterface) => {
        return mutation({
            data,
            token
        })
    }

    const status = useStatusProcessor(data)
    return {createComponent, ...data,...status} 
}

export const useUpdateComponent = () =>{
    const token = useToken()

    const [mutation, data] = useMutation(UpdateComponent,{
        onSuccess:()=> ComponentsCRUDDependencies.forEach((dependency)=>{
            queryCache.invalidateQueries(dependency)
        })
    })

    const updateComponent = (data:ComponentInterface) => {
        return mutation({
            data,
            token
        })
    }

    const status = useStatusProcessor(data)
    return {updateComponent, ...data,...status} 
}

//----------------------------
//delete
//---------------------------------------------

export const useDeleteComponent = () => {
    return useMutation(DeleteComponent,{
        onSuccess:()=> ComponentsCRUDDependencies.forEach((dependency)=>{
            queryCache.invalidateQueries(dependency)
        })
    })
}





interface DeleteComponentErrors {
    equipments?:EquipmentInterface[] | undefined | null
}

export const useDeleteComponentVerification = () => {

    const token = useToken()
    
    const [deleteItem,{data}] = useDeleteComponent()
    const [status, setStatus] = useState<QueryStatus>(QueryStatus.Idle)
    const [validationElement,setValidationElement] = useState<ComponentInterface | {} |undefined >()
    const {data:equipmentsByComponents,status:equipmentsByComponentStatus} = useEquipmentsByComponent(validationElement)
    const [errors, setErrors] = useState<DeleteComponentErrors>({})

    const validate = (item:ComponentInterface) => {
        setStatus(QueryStatus.Loading)
        if(!equipmentsByComponents)setValidationElement(item)
        else handleDelete()
    }

    const handleDelete= () =>{
        if(equipmentsByComponentStatus === 'success'){
            if((equipmentsByComponents && equipmentsByComponents.length > 0)) {
                setErrors({equipments:equipmentsByComponents})
                setStatus(QueryStatus.Error)
                setValidationElement(undefined)
            }
            else{
                deleteItem({
                    data:validationElement,
                    token
                })
                .then(()=>{
                    setStatus(QueryStatus.Success)
                    setValidationElement(undefined)
                })
                .catch(err=>err)
            }
        }
    }

    
    useEffect(() => {
        handleDelete()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[equipmentsByComponentStatus])

    return  {
        errors,
        status,
        validate,
        data
    }
}