import React, {  useEffect, useMemo, useState } from 'react';
//components

//Router
import { Chip, Paper, Typography } from '@material-ui/core';
import AnyGrid from '../components/grids/AnyGrid';
import FlexContainer from '../components/presentational/FlexContainer';
import  OpacityIcon  from '@material-ui/icons/Opacity';
import theme from '../infrastructure/materialUi/themeConfig';
import { FlagOutlined } from '@material-ui/icons';
import { gridModelRoutes, useRoutesByTagFPAndState } from '../domains/routes/services/service.routes';
import ReScheduleRoute from '../domains/routes/components/ReScheduleRoute'; 
import LocationSearchingIcon from '@material-ui/icons/LocationSearching'; 
import { gridModelObservations, useObservationsByPeriodAndTagFP } from '../domains/observations/service/service.observations';
import CustomButton, { CustomIcon } from './../components/buttons/CustomButton';
import { GridModelSurvey, useSurveysByPeriodAndTagFP } from '../domains/colector/domains/surveys/services/service.survey';
import {  useEquipmentswithCompleteInfo } from '../domains/colector/domains/lubricationPoints/services/dao.lubricationPoints';
import CustomObservationsList from '../components/grids/lubricationPoints/CustomObservationsList';
import EquipmentSurveysList from '../components/grids/lubricationPoints/EquipmentSurveysList';
import { gridModelLubricationPoints } from '../domains/equipments/services/service.lubricationPoints';
import { PlantInterface } from '../domains/plants/service.plant';
import EquipmentsInfo from '../domains/equipmentsInfo/EquipmentsInfo'
import LeakLevelComponent from './../domains/colector/LeakLevelComponent';
import OutfRouteListSurvey from '../domains/colector/domains/surveys/OutfRouteListSurvey';
import { PeriodInterface } from '../components/filters/AnyFiltering';
import PendingSuppliesToAssign from '../domains/equipmentsInfo/PendingSuppliesToAssign';

const ReportsPage:React.FC<{plant?:PlantInterface}> = ({plant}) => {

    const tagFP = plant?.tagFP

    const [type,setType] = useState('lubricationPoints')

    //Period
    const [period, setPeriod] = useState<PeriodInterface | undefined>();

    //data
    const completeLubricationPoints = useEquipmentswithCompleteInfo(tagFP)
    const { data:routesWithState } = useRoutesByTagFPAndState(tagFP)
    const { data:observations } = useObservationsByPeriodAndTagFP(period,tagFP)
    const {data:surveys} = useSurveysByPeriodAndTagFP(period,tagFP)

    const entities = useMemo<any>(()=>([
        {
            type:'lubricationPoints',
            entity:(completeLubricationPoints && completeLubricationPoints?.filter(item => item.state === true)) || [],
            gridModel:{'renderOption':{label:'Acciones'},...gridModelLubricationPoints},
            title:'Puntos de lubricacion',
            icon:<LocationSearchingIcon/>,
            itemActions:(item:any,items:any)=><>
                <CustomObservationsList queryType='lubricationPoint' mode='modal' data={item} />
                <EquipmentSurveysList mode='modal' lubricationPoint ={item}/>
                <EquipmentsInfo type='modal' item={item}/>
                <OutfRouteListSurvey  outOfRoute={true} currentItem={item} items={items} />
                <PendingSuppliesToAssign lubricationPoint={item} />
                {/* {item.info.pending && <IndividualLubricationPointSurvey  outOfRoute={true}  lubricationPoint={item} />} */}
                {!item.info.pending  && <CustomButton icon='checkedCircle' variant='iconButton' styleProps={{color:'green'}} popoverTitle='Equipo al día' /> }
            </>,
            renderOption:(item: any) => item.value.leakLevel && 
            <LeakLevelComponent lastlubricantCuantity={item.getValue('info.lubricantQuantity')} pointCapacity={item.getValue('capacity')}/>
        },
        {
            type:'routes',
            entity:routesWithState,
            gridModel:{...gridModelRoutes,'renderOption':{label:'Acciones'}},
            title:'Rutas',
            icon:<FlagOutlined/>,
            renderOption:(item:any)=>item && <ReScheduleRoute tagFP={tagFP || ''} item={item.value}/>
        },
        {
            type:'observations',
            entity:observations,
            periodChange:(period:any)=>setPeriod(period),
            gridModel:gridModelObservations,
            title:'Observaciones',
            icon:<CustomIcon icon='observations'/>,
        },
        {
            type:'surveys',
            entity:surveys,
            periodChange:(period:any)=>setPeriod(period),
            gridModel:GridModelSurvey,
            title:'Relevamientos',
            itemActions:(item:any)=> <CustomObservationsList queryType='survey' mode='modal' data={item} />,
            icon:<CustomIcon icon='survey'/>,
        },

    ]),[ completeLubricationPoints, routesWithState, observations, surveys  ])

    return(<>
        <FlexContainer width='100%' gap={`${theme.spacing(3)}px`}>
            {entities.map(({title,type,icon}:any)=> 
                <Chip  key={title} label={title} icon={icon} onClick={()=>setType(type)}></Chip> 
            )}
        </FlexContainer>
        <br/>
        <Paper >
            {entities?.map((item:any)=>
                type === item.type 
                ?   <AnyGrid
                    periodChange={item.periodChange}
                    key={item.type}
                    loading={!item.entity ? true : false}
                    items={item.entity || []}
                    gridModel={item?.gridModel}
                    title={item.title }
                    documentExportTitle={`Reporte_${item.title.replaceAll(' ','_')}`}
                    renderOptions={item.renderOption}
                    itemActions={item.itemActions}
                    />
                :   null
            )}
        </Paper>
    </>)
}

export default React.memo(ReportsPage);
