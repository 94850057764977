import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Typography, TextField } from '@material-ui/core';
import CustomButton from '../../components/buttons/CustomButton';
import FlexContainer from '../../components/presentational/FlexContainer';
import theme from '../../infrastructure/materialUi/themeConfig';
import StatusAndMessage from '../errors/StatusAndMessage';
import { CategoryInterface, useCreateCategory } from './service.category';
import { useStatusProcessor } from '../errors/services/StatusProcessor';
import ComponentMode from '../../components/ComponentMode';
import { QueryStatus } from 'react-query';
import { ComponentUIModes } from '../../components/ComponentUIModes';


interface Props{
    tagFP:string | undefined
    mode:ComponentUIModes
}

const CreateCategoryForm:React.FC<Props> = ({tagFP,mode}) => {
    
    const [Createcategory,createResult] = useCreateCategory()
    const { status, message, error,reset:ClearStatus } = useStatusProcessor(createResult)
    const {register,setValue,errors,watch,handleSubmit,reset} = useForm()
    const title='Agregar nueva categoría padre'
    
    useEffect(()=>{
        register({name:'id',type:'number',value:0})
        register({name:'category',value:''},{required:{value:true,message:'Campo requerido'}})
        register({name:'tagFP',value:tagFP})
        register({name:'categoryArea',type:'string',value:'LUBRICACION'})
    })

    useEffect(()=>{
        if(status === QueryStatus.Success){
            setValue('category','')
        }
    },[status])

    const submit = (item:CategoryInterface) => {
        Createcategory(item)
    }
   
    
    return (
        <ComponentMode mode={mode} title={title}>
            <Typography variant='h6'>{mode === 'content' && title}</Typography>
            <StatusAndMessage status={status} message={message} error={error} reset={ClearStatus} />
            <FlexContainer padding={`${theme.spacing(3)}px 0px`} flexDirection={'column'} width='30%'>
                <TextField
                inputRef={register()}
                name='category'
                size='small'
                variant='outlined'
                label={errors?.category?.message || 'Nombre de la categoria'}
                color={errors?.category?.message ? 'primary' : 'secondary'}
                />
                <CustomButton status={status} action={handleSubmit(submit)}>Crear</CustomButton>
            </FlexContainer>
        </ComponentMode>
    );
}

export default React.memo(CreateCategoryForm);
