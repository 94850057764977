import React, { memo } from 'react';
//components
import AddUser from '../AddPages/AddUser';
import PageContainer from '../../components/pageContainers/PageContainer'
//Router
import { Route,Switch } from 'react-router-dom';
import { USER_ROUTES } from '../../infrastructure/routes/routes';
import HomePage from '../HomePage';
import NotificationsPage from '../NotificationsPage';
import FactoryList from '../../domains/factories/FactoryList';
import PlantsList from '../../components/lists/PlantsList';
import ConfigPage from '../ConfigPage';
import DiscoverPage from '../DiscoverPage';



const AccountManagerContent:React.FC<{dimention:string}> = ({dimention}) => {

    return (
        <PageContainer>
        <Switch>
            
            <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.addUser}`}>
                <AddUser />
            </Route>
            
            <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.notifications}`}>
                <NotificationsPage />
            </Route>
            
            <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.discover}`}>
                <DiscoverPage path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.discover}`}/>
            </Route>
            
            <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.config}`}>
                <ConfigPage/>
            </Route>
            
            <Route path={`/${USER_ROUTES.home}/${dimention}`}>
                <HomePage dimention ={dimention}/>
                <NotificationsPage />
            </Route>

        </Switch>
    </PageContainer>
    );
}

export default memo(AccountManagerContent);