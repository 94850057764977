import { useEffect, useContext, useMemo } from 'react';
import axios, { AxiosRequestConfig } from'axios'
//services
import { getAllResources } from '../../../infrastructure/axios/service.tgd.axios'
import { queryCache, useMutation, useQuery, QueryResult, QueryStatus } from 'react-query';
import {useToken} from '../../session/service.session'
import { URL } from '../../../infrastructure/appConfig/appConfig';


import { SessionContext } from '../../../store/sessionContext';
import { MutationQueryObject } from '../../../infrastructure/Interfaces';
import { useState } from 'react';
import { assignPlantToUser } from '../../plants/service.plant';
import { DemoRequestInterface, PersonInterface, UserInterface } from '../interfaces/userInterfaces';
import { PersonRoleTypes } from '../interfaces/PersonRoleTypes';
import { usePlantConfigWorkspace } from '../../../infrastructure/tgd.config';
import { PlantInterface } from './../../plants/service.plant';
import { capitalize } from './../../../utils/utils';
import { useStatusProcessor } from './../../errors/services/StatusProcessor';


//axios repo
//---------------------------------------------
export const updatePerson = ({data,token}:MutationQueryObject) =>{ //get object 'cause mutation query bring it that way
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdatePassword`,
        data
    }
    return axios(config).then(res => {return res.data}).then(res => {return res})
}
export const updateHiddenState = ({data,token}:MutationQueryObject) =>{ //get object 'cause mutation query bring it that way
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateHiddenState`,
        data
    }
    return axios(config).then(res => {return res.data}).then(res => {return res})
}

export const getSubordinates = (endpoint:string,refererId:number,token:string) =>{ //get object 'cause mutation query bring it that way
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    
    let config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/${endpoint}/${refererId}`
    }
    return axios(config).then(res => {return res.data}).then(res => {return res})
}

export const PlantOperators = (endpoint:string,tagFP:string,token:string) => {
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    axios.defaults.headers.get['tagFP'] = tagFP;
    let config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/PlantOperators`,
    }
    return axios(config).then(res => {return res.data}).then(res => {return res})
}

export const PlantSupervisors = (endpoint:string,tagFP:string,token:string) => {
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    axios.defaults.headers.get['tagFP'] = tagFP;
    let config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/PlantSupervisors`,
    }
    return axios(config).then(res => {return res.data}).then(res => {return res})
}
export const PlantClients = (endpoint:string,tagFP:string,token:string) => {
    axios.defaults.headers.get['Authorization'] = 'Bearer ' + token;
    axios.defaults.headers.get['tagFP'] = tagFP;
    let config:AxiosRequestConfig = {
        method: "get",
        url:`${URL}/PlantClients`,
    }
    return axios(config).then(res => {return res.data}).then(res => {return res})
}

export const CreatePerson = ({endpoint, data, token}:{endpoint:string,data:any,token:string}) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/${endpoint}`,
        data
    }
    return axios(config).then(res => {return res.data}).then(res => {return res})
}
export const createDemoClient = ({data, token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/CreateDemoClient`,
        data
    }
    return axios(config).then(res => {return res.data}).then(res => {return res})
}
export const desactivateDemoUser = ({data, token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/UpdateDemoUser`,
        data
    }
    return axios(config).then(res => {return res.data}).then(res => {return res})
}
export const AssignLubricatorNumber = ({data, token}:MutationQueryObject) => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + token;
    let config:AxiosRequestConfig = {
        method: "post",
        url:`${URL}/AssignLubricatorNumber`,
        data
    }
    return axios(config).then(res => {return res.data}).then(res => {return res})
}



//---------------------------------------------
//react-query HOOKS
//---------------------------------------------



export const useAllPersons = () => {
    const token = useToken()
    return useQuery(['AllPerson',token], getAllResources)
}

export const useUser = () => {
    const token = useToken()
    

    const query =  useQuery<UserInterface>(['User',token], getAllResources,{
       refetchOnWindowFocus:false,
       staleTime:Infinity,
       enabled:!!token
    })


    return query
}

export const usePlants = () => {
    const token = useToken()
    const query = useQuery(['Plants',token], getAllResources,{
        refetchOnWindowFocus:false,
        staleTime:500000,
        enabled:!!token
    })
/*     const {setPlantConfigWorkspace} = usePlantConfigWorkspace()

    //setDefaultPlantWorkConfig
    useEffect(()=>{
        if(user?.type ==='S' || user?.type ==='O'){
            if(plants && plants[0]){
                setPlantConfigWorkspace(plants[0])
            }
        }
    },[plants,user]) */

    return { ...query }
}

export const useOperatorSupervisorTagFP = ():string => {
    const {data:plants} = usePlants()
    const tagFP = useMemo<string>(()=>(plants && plants[0]?.tagFP) || '',[plants])
    return tagFP
}

export const usePerson = ():QueryResult<PersonInterface> => {
    const token = useToken()
    return useQuery<PersonInterface>(['Person',token], getAllResources,{
        refetchOnWindowFocus:false,
        staleTime:Infinity,
        enabled:!!token
    })
}

export const usePersonName = () => {
    const {data:person} = usePerson()
    if(person) return person.name
    return null
}

export const useSubordinates = () => {
    const token = useToken()
    const {data:person} = usePerson()
    return useQuery(['Subordinates',person?.id,token], getSubordinates,{
        enabled:!!person?.id && !!token
    })
}

export const useDemoRequests = ( ) => {
    const token = useToken()
    return useQuery(['AllDemoRequests',token],getAllResources,{
    })
}

export const usePlantOperators = (tagFP?:string) => {
    const token = useToken()
    return useQuery<PersonInterface[]>(['PlantOperators',tagFP,token],PlantOperators,{
        enabled:!!tagFP && !!token,
        refetchOnWindowFocus:false,
        staleTime:5000,
    })
}

export const usePlantSupervisors = (tagFP:string) => {
    const token = useToken()
    return useQuery(['PlantSupervisors',tagFP,token],PlantSupervisors,{
        enabled:!!tagFP && !!token
    })
}
export const usePlantClients = (tagFP:string) => {
    const token = useToken()
    return useQuery(['PlantClients',tagFP,token],PlantClients,{
        enabled:!!tagFP && !!token
    })
}

//react-query MUTATIONS
//---------------------------------------------

export const useCreateperson = () => {
    return useMutation(CreatePerson)
}




export const useCreatePersonRefactor = () => {
    const token = useToken()    

    const [mutate,createQuery] = useMutation(CreatePerson,{
        onSuccess:()=>{
            queryCache.invalidateQueries('PlantOperators')
            queryCache.invalidateQueries('PlantSupervisors')
            queryCache.invalidateQueries('PlantClients')
        }
    })

    const [assignPlant] = useMutation(assignPlantToUser,{
        onSuccess:()=>{
            queryCache.invalidateQueries('PlantOperators')
            queryCache.invalidateQueries('PlantSupervisors')
            queryCache.invalidateQueries('PlantClients')
        }
    })

    const status = useStatusProcessor(createQuery)

    const createPerson = (data:PersonInterface & { tagF:undefined | string },tagFP?:string) => {
        switch (data.role) {
            case 'Supervisor' :
                return mutate({
                    data,
                    token,
                    endpoint:'CreateSupervisor',
                }).then(()=>{
                    if(tagFP){
                        assignPlant({
                            data: {
                                tagFP:tagFP,
                                email:data.email,
                                role:'S',
                                state:'A',
                                actualUserEmail:data.referrer
                            },
                            token
                        })
                    }
                }).catch(err=>console.log(err))

            case 'Operario' :
                return mutate({
                    data,
                    token,
                    endpoint:'CreateOperator',
                }).then(()=>{
                    if(tagFP){
                        assignPlant({
                            data: {
                                tagFP:tagFP,
                                email:data.email,
                                role:'O',
                                state:'A',
                                actualUserEmail:data.referrer
                            },
                            token
                        })
                    }
                }).catch(err=>console.log(err))

            case 'Cliente':
                return mutate({
                    token,
                    data,
                    endpoint:'CreateClient',
                }).then(()=>{
                    if(tagFP){
                        assignPlant({
                            data: {
                                tagFP:tagFP,
                                email:data.email,
                                role:'O',
                                state:'A',
                                actualUserEmail:data.referrer
                            },
                            token
                        })
                    }
                }).catch(err=>console.log(err))
            default:
                return mutate()
        }
    }
      
    return {
        createPerson,
        ...status,
    }
}


export const useUpdatePerson = () => {
    return useMutation(updatePerson,{})
}

export const useActivateDemoRequest = (config?:any) => {
    return useMutation(createDemoClient,config)
}

export const useDesactivateDemoUser = (config?:any) => {
    return useMutation(desactivateDemoUser,config)
}

export const useUpdateHiddenState = (config?:any) => {
    return useMutation(updateHiddenState,config)
}
export const useAssignLubricatorNumber = (config?:any) => {
    return useMutation(AssignLubricatorNumber,config)
}

export const useUpdateDemoRequest = (config:any) => {
    const token = useToken()
    const [mutation,query] = useUpdateHiddenState(config)

    const archive = (email:string) => {
        mutation({
            data:{
                applicantsEmail:email,
                hidden:true
            },
            token
        })
    }

    const unArchive = (email:string) => {
        mutation({
            data:{
                applicantsEmail:email,
                hidden:false
            },
            token
        })
    }
    
    return {archive,unArchive,query}
}




//CUSTOM HOOKS
//---------------------------------------------

//hidden(archived)-Showed(pending) user Demos Requests
export const useShowedDemoRequests = () => {
    const demoRequestsQuery = useDemoRequests()
    const pendingDemoRequests = demoRequestsQuery.data ? demoRequestsQuery.data.filter((item:DemoRequestInterface )=> item.hidden === false):[]
    return [pendingDemoRequests,demoRequestsQuery]
}

export const useHiddenDemoRequests = () => {
    const demoRequestsQuery = useDemoRequests()
    const hiddenDemoRequests = demoRequestsQuery.data ? demoRequestsQuery.data.filter((item:DemoRequestInterface ) => item.hidden === true):[]
    return [hiddenDemoRequests,demoRequestsQuery]
}


//custom - Filtered users by Role
export const useClientsByReferer = () => {
    const subordinatesQuery = useSubordinates()
    const personsQuery = useAllPersons()//admins need all persons
    const{data:user} = useUser()
    const userData =  subordinatesQuery.data ? subordinatesQuery.data.filter((item:PersonInterface) => item.role === PersonRoleTypes.client):[]
    const adminData =  personsQuery.data ? personsQuery.data.filter((item:PersonInterface) => item.role === PersonRoleTypes.client):[]
    if((user?.type==='A')||(user?.type === 'SA'))return [adminData,personsQuery]
    else return [userData,subordinatesQuery]
}

export const useOperatorsByReferer = () => {
    const subordinatesQuery = useSubordinates()
    const personsQuery = useAllPersons()//admins need all persons
    const{data:user} = useUser()
    const userData =  subordinatesQuery.data ? subordinatesQuery.data.filter((item:PersonInterface) => item.role === PersonRoleTypes.operator):[]
    const adminData =  personsQuery.data ? personsQuery.data.filter((item:PersonInterface) => item.role === PersonRoleTypes.operator):[]
    if((user?.type==='A')||(user?.type === 'SA'))return [adminData,personsQuery]
    else return [userData,subordinatesQuery]
}

export const useEngineersByReferer = () => {
    const subordinatesQuery = useSubordinates()
    const personsQuery = useAllPersons()//admins need all persons
    const{data:user} = useUser()
    const userData =  subordinatesQuery.data ? subordinatesQuery.data.filter((item:PersonInterface) => item.role === PersonRoleTypes.supervisor):[]
    const adminData =  personsQuery.data ? personsQuery.data.filter((item:PersonInterface) => item.role === PersonRoleTypes.supervisor):[]
    if((user?.type==='A')||(user?.type === 'SA'))return [adminData,personsQuery]
    else return [userData,subordinatesQuery]
}

export const useAccountManagersByReferer = () => {
    const query = useSubordinates()
    const queryPersons = useAllPersons()
    const{data:user} = useUser()
    if(!query.data) return query
    if((user?.type === 'A')||(user?.type === 'SA'))return queryPersons.data.filter((item:PersonInterface) => item.role === PersonRoleTypes.accountMannager)
    else return query.data.filter((item:PersonInterface) => item.role === PersonRoleTypes.accountMannager )
}

export const useAdministators = () => {
    const query = useAllPersons()
    const data = query.data ? query.data.filter((item:PersonInterface) => item.role === PersonRoleTypes.admin):[]
    return [data,query]
}

export const useEmailVerificationExist = (email:string) => { 
    const {data} = useAllPersons()
    useEffect(() => {
        if(email && data){
            let query = data.filter((item:PersonInterface) => item.email === email )
            sessionStorage.setItem('emailVerification',query.length)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[email])
}


export const useDimention = ():string => {
    const {data:person} = usePerson()
    const {data:user} =  useUser()
    
    return useMemo(() => {
        if(user && person){
            switch (user?.type) {
                case 'SA':
                    return person.role.replace(/ /g,'-')
                case 'AC':
                    return person.role.replace(/ /g,'-')
                case 'A':
                    return person.role
                case 'S':
                    return 'Supervisor'
                case 'C':
                    return person.role
                case 'O':
                    return person.role
            }
        }
        return ''
    }, [person,user]);
}

export const useOperatorLubricationNumber = () => {
    const {data:person} = usePerson()
    return useMemo(() => person?.lubricatorNumber ,[person])
}



export const useWorkspace = ():string | undefined => {
    const [auth] = useContext<any>(SessionContext)
    const{data:user} = useUser()
    const {data:plants} = usePlants()


    if(plants && user){
        if((user?.type === 'C')){
            if((plants.length === 1)){
                let factory = capitalize(plants[0].factory)
                let plant = capitalize(plants[0].name)
                return factory + ' ' + plant
            }
            if(plants.length > 1){
                return auth.workspace
            }
        }
        if(user?.type === 'SA'){
            let workspace = plants
            return workspace
        }
        if(user?.type === 'A'){
            let workspace = plants
            return workspace
        }
        if(user?.type === 'AC'){
            let workspace = plants
            return workspace
        }
        if(user?.type === 'S'){
            let workspace = plants[0]
            return workspace
        }
    }

}


