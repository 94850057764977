import React, { useState } from 'react';
import ModalList from '../../components/modals/modalList/ModalList';
import { useLangLabels } from '../language/services/useSetLabel';
import { CollectorInterface, useCreateUpdateCollector } from './services/collectors.service';
import { TgdTextField } from './../form-config/formComponents/TgdTextField';
import { CustomIconType } from '../../components/buttons/CustomButton';
import { errorsLog } from './../../utils/utils';
import DefaultFormLayout from './../../components/forms/DefaultFormLayout';
import { CrudFormType } from '../../infrastructure/form/typescript/FormTypes';


type createUpdateCollectorType ={
    item:CollectorInterface
    tagFP:string
} & (
    {
        type:CrudFormType.create
        items?:CollectorInterface[]
    } |
    {
        type: CrudFormType.update
        items:CollectorInterface[],
    }
)

const CreateUpdateCollectorForm:React.FC<createUpdateCollectorType> = ({tagFP,type, item, items}) => {

    const {lang} = useLangLabels()
    const [current, setCurrent] = useState(item);
    const {handleCreateUpdateCollector,data:{status,message,error},errors,setValue,handleSubmit,form,reset} = useCreateUpdateCollector({tagFP,type,item:current})

    errorsLog(errors)

    const icon:CustomIconType = (type === CrudFormType.create) 
    ?   'add'
    :   'edit'
        

    const content =<> 
        <TgdTextField
            name='name'
            label={lang.collectors.form.name}
            value={form.name}
            defaultValue={form.name}
            error={errors?.name?.message} 
            onChange={(value: any) => setValue('name',value)}                
        />
        <TgdTextField
            name='serialNumber'
            label={lang.collectors.form.serialNumber}
            value={form.serialNumber}
            defaultValue={form.serialNumber}
            error={errors?.serialNumber?.message} 
            onChange={(value: any) => setValue('serialNumber',value)}                
        />
        <TgdTextField
            name='collectorCode'
            label={lang.collectors.form.collectorCode}
            value={form.collectorCode}
            defaultValue={form.collectorCode}
            error={errors?.collectorCode?.message} 
            onChange={(value: any) => setValue('collectorCode',value)}                
        />
    </> 
    return(<>
        {type === CrudFormType.create &&
        <DefaultFormLayout
        onClose={reset}
        fullwidth 
        onChange={handleSubmit(handleCreateUpdateCollector)} 
        buttonTitle={lang.actionTypes[type]}
        popoverTitle={lang.actionTypes[type] + ' ' + lang.collectors.singular} 
        {...{status,message,error,icon}}>
            {content}
        </DefaultFormLayout> }
        
       { type === CrudFormType.update &&
        <ModalList
        title={lang.actionTypes[type]}
        item={current}
        icon={icon}
        onChange={setCurrent}
        items={items}
        content={(current) =>
            <DefaultFormLayout
                    onChange={handleSubmit(handleCreateUpdateCollector)}
                    buttonTitle={lang.actionTypes[type]}
                    popoverTitle={lang.actionTypes[type] + ' ' + lang.collectors.singular} 
                    mode='content'
                    {...{status,error,message}}
                    onClose={reset}
                >
                    {content}
            </DefaultFormLayout>
        }
        />}
    </>);
}

export default CreateUpdateCollectorForm;
