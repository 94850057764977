import { InputComponentTypes } from "../../../infrastructure/form/typescript/FormTypes";
import { useForm } from "../../../infrastructure/form/useForm";
import { useLang } from "../../language/services/useLanguages";
import { SectorInterface } from "./service.sectors";
import { useLangLabels } from './../../language/services/useSetLabel';


export const useSectorForm = (tagFP:string,item?:SectorInterface) =>{

    const {lang} = useLangLabels()
    return useForm<SectorInterface>({fields:{
        id:{
            label:lang.globalentityValues.id,
            placeholder:lang.globalentityValues.id,
            value:item?.id || 0,
            component:InputComponentTypes.none,
        },
        sector:{
            label:lang.sectors.form.label.sector,
            placeholder:lang.sectors.form.label.sector,
            value:item?.sector || '',
            component:InputComponentTypes.text,
            validations:{
                required:{value:true,message:lang.validations.messages.required},
                min:{value:1,message:lang.validations.messages.minLength},
                max:{value:50,message:lang.validations.messages.maxLength}
            }
        },
        sectorName:{
            label:lang.sectors.form.label.sectorName,
            placeholder:lang.sectors.form.label.sectorName,
            value:item?.sectorName || '',
            component:InputComponentTypes.text,
            validations:{
                required:{value:true,message:lang.validations.messages.required},
                min:{value:1,message:lang.validations.messages.minLength},
                max:{value:50,message:lang.validations.messages.maxLength}
            }
        },
        code:{
            label:lang.sectors.form.label.code,
            placeholder:lang.sectors.form.label.code,
            value:item?.code || '',
            component:InputComponentTypes.text,
            validations:{
                required:{value:true,message:lang.validations.messages.required},
                min:{value:1,message:lang.validations.messages.minLength},
                max:{value:50,message:lang.validations.messages.maxLength}
            }
        },
        tagFP:{
            label:lang.globalentityValues.tagFP,
            placeholder:lang.globalentityValues.tagFP,
            value:tagFP,
            component:InputComponentTypes.none,
            validations:{
                required:{value:true,message:''}
            }
        }
    }})
}