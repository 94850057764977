import React from 'react';
import { CrudFormType } from '../../infrastructure/form/typescript/FormTypes';
import { useCreateUpdate } from '../crud/hooks/useCreateUpdate';
import { useLangLabels } from '../language/services/useSetLabel';
import { CUEntityFormPropsType, uiFormInterface } from '../crud/types/crudTypes';
import DefaultFormLayout from '../../components/forms/DefaultFormLayout';
import FieldSelector from '../../infrastructure/form/components/FieldSelector';
import { PermissionInterface } from './types/permissionsTypes';
import { CreatePermission, DeletePermission, PermissionCRUDDependencies, UpdatePermission } from './services/service.permission';
import { usePermissionForm } from './services/usePermissionsForm';


type CUSectorFormProps = CUEntityFormPropsType<PermissionInterface>


const CUPermissionForm: React.FC<CUSectorFormProps> = ({tagFP, item, actionType, icon, variant, mode }) => {

    //DB 
    const { action, resetStatus,status, error, message } = useCreateUpdate({
        create: CreatePermission,
        update: UpdatePermission,
        delete: DeletePermission
    },actionType, PermissionCRUDDependencies)

    //UI
    const { lang } = useLangLabels()

    const { uiModel, fields, handleSubmit, reset } = usePermissionForm(tagFP, item)

    const ui = (() => {
        const formName = lang.permissions.plural 
        switch (actionType) {
            case CrudFormType.create:
                const create:uiFormInterface = {
                    icon:icon || 'add',
                    title:`${lang.actionTypes.create} ${formName.toLowerCase()}`,
                    variant:variant || 'button',
                    mode:mode || 'modal'
                }
                
                return create

            case CrudFormType.update:
                const update:uiFormInterface = {
                    icon: icon || 'edit',
                    title:`${lang.actionTypes.update} ${formName.toLowerCase()}`,
                    variant: variant || 'iconButton',
                    mode: mode || 'content'
                }
                return update
        }
    })()

    //Methods
    const submit = (data:PermissionInterface) => {
        action(data)
    }

    const handleReset = () => {
        resetStatus()
        reset()
    }

    return(
        <DefaultFormLayout
            mode={ui.mode}
            icon={ui.icon}    
            variant={ui.variant}
            buttonTitle={ui.title}
            popoverTitle={ui.title}
            {...{status,error,message}} 
            onChange={handleSubmit(submit)}  
            onClose={handleReset} 
        >
            {uiModel.map((props,index)=>
                <FieldSelector key={index } fieldProps={props} />
            )}
        </DefaultFormLayout>
    )
};

export default CUPermissionForm;