import React from 'react';
import { useLeakLevel } from './domains/lubricationPoints/services/leakLevel';
import { LubricationPointInterface } from '../equipments/services/service.lubricationPoints';
import FlexContainer from '../../components/presentational/FlexContainer';
import { CustomIcon } from '../../components/buttons/CustomButton';
import { Typography } from '@material-ui/core';

interface Props{
    pointCapacity:number
    lastlubricantCuantity:number
}

const LeakLevelComponent:React.FC<Props> = ({pointCapacity, lastlubricantCuantity}) => {

    const { description,leakQuantity } = useLeakLevel({lastlubricantCuantity,pointCapacity})
    
    return(
    <FlexContainer gap={'10px'}>
	<CustomIcon icon='leak' style={{color:description}} />
		<Typography style={{color:description}} variant='body2'>
			% { leakQuantity }
		</Typography>  
	</FlexContainer>
    ); 

}

export default LeakLevelComponent;
