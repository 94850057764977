import React from 'react'
import SimpleAutocompleteSelect from '../../../components/autocomplete/SimpleAutocompleteSelect';
import { useUser } from '../../persons/services/service.person'
import { useLangLabels } from '../../language/services/useSetLabel';
import { useAllContainersByTagFP } from '../services/service.containers';
import { ContainersInterface } from '../types/ContainersInterfaces';

type Props = {
    error?:string
    tagFP:string
    complete?:boolean
    onChange:(value:any) =>void
    defaultValue?:string
    by?:{
        key:string
        value:string
    }
}

const SelectContainerBy:React.FC<Props> = ({error, tagFP, complete, onChange, defaultValue ,by}) => {

    const key='name'
    const {lang} = useLangLabels()
    const {data:user} = useUser()
    const {data:containers} = useAllContainersByTagFP(tagFP)
    const containersBy = (by ? containers?.filter((l)=>l[by.key as keyof ContainersInterface] === by.value) : containers) || []
    const label = lang.containers.title.singular

    const handleChange = (value:string) => {
        if(complete){
            const con = containersBy.find((l)=>l[key] === value) as ContainersInterface
            onChange(con)
        }else{    
            onChange(value)
        }
    }
    return(
    <SimpleAutocompleteSelect 
    {...{
        error,
        label,
        items:containersBy || [],
        defaultValue,
        onChange:handleChange,
        showTitle:key
    }} >
        {/* {user?.type !== 'O' && <CUContainerForm {...{tagFP,actionType:CrudFormType.create}} />}*/}
    </SimpleAutocompleteSelect>)
}

export default SelectContainerBy