import React, { useEffect, useState } from 'react';
import FlexContainer from '../components/presentational/FlexContainer';
import SelectFactory from '../domains/factories/SelectFactory';
import { FactoryInterface } from '../domains/factories/services/service.factory';
import { CircularProgress, Button, ListItemText, ListItem, ListItemIcon, Divider } from '@material-ui/core';
import { PlantInterface, usePlantsByFactory } from '../domains/plants/service.plant';
import CreatePlantForm from '../domains/plants/CreatePlantForm';
import { useFactoryConfigWorkspace, usePlantConfigWorkspace } from '../infrastructure/tgd.config';
import ComponentMode from '../components/ComponentMode';
import { useLocation } from 'react-router';
import CustomButton, { CustomIcon } from '../components/buttons/CustomButton';
import TgdRouterTabs from '../components/Tabs/TgdRouterTabs';
import { USER_ROUTES } from '../infrastructure/routes/routes';
import TgdTabs from '../components/Tabs/TgdTabs';
import { Console } from 'console';
import CustomSidebarContentCard from '../components/CustomSidebarContentCard';
import SelectSimpleEntity from '../components/select/SelectSimpleEntity';
import ReportsPage from './ReportsPage';
import StockPageManagment from './StockPageManagment';
import PlantManagementPage from './PlantManagementPage';
import NewsPage from './NewsPage';
import RealtimeSensoringPage from '../domains/sensoring/pages/RealtimeSensoringPage';
import VersionContainer from '../components/pageContainers/VersionContainer';
import SensorsManagementPage from '../domains/sensoring/pages/SensorsManagementPage';

interface Props{
    children?:(plant:PlantInterface)=>React.ReactNode
    path:string
}

const DiscoverPage:React.FC<Props> = ({children,path}) => {

    const features = [
        {icon:'error',label:'novedades',component:(plant:PlantInterface)=><NewsPage tagFP={plant.tagFP}/>},
        {icon:'search',label:'reportes',component:(plant:PlantInterface)=><ReportsPage plant={plant}/>},
        {icon:'stock',label:'stock',component:(plant:PlantInterface)=><StockPageManagment defaultPath={`${path}/`} tagFP={plant.tagFP} />},
        {icon:'wifi',label:'sensorizado',component:(plant:PlantInterface)=><>
            <RealtimeSensoringPage tagFP={plant.tagFP}/>
            <VersionContainer company = 'STI'/>
        </>},
        {icon:'config',label:'planta',component:(plant:PlantInterface)=><PlantManagementPage plant={plant}/>},
        {icon:'config',label:'sti',component:(plant:PlantInterface)=><SensorsManagementPage tagFP={plant.tagFP} />},
    ]

    const {data:factory,setFactoryConfigWorkspace} = useFactoryConfigWorkspace()
    const [feature,setFeature] = useState(features[0].label)
    const {data:plants,status} = usePlantsByFactory(factory?.tagF)
    const {data:plant,setPlantConfigWorkspace} = usePlantConfigWorkspace()
    const {pathname} = useLocation()

    useEffect(()=>{
        if(plants && plants?.length > 0){
            setPlantConfigWorkspace(plants[0])
        }
    },[factory])

    const activeColor = (p:PlantInterface) =>((p.name === plant?.name) ? 'primary' : 'secondary' ) 

  /*   const tabs = [
        ...(plants ? plants.map((p:PlantInterface)=>({
            label:p.name,
            plant:p,
            component:<>{children && plant && children(plant)}</>
        })) : []),
        {
            label:factory.name,
            component:<CustomSidebarContentCard
                items={[{label:'usuarios'},{label:'General'},]}
                content={(item)=><>{item.label}</>}
                labelIterator='label'
            />
        },
    ] */
    const tabs = [
        /* {
            label:factory?.name,
            component:<>Configuraciones para {factory?.name}</>
        }, */
        ...(plants ? plants.map((p:PlantInterface)=>({
            label:p.name,
            plant:p,
            component:features.find((f)=>f.label === feature)?.component(p)
        })) : []),
    ] 

    



    return (<>
        <FlexContainer justify='space-between'>
            <FlexContainer>
                <SelectFactory value={factory?.name || ''} label='Fábrica' onChange={(item:FactoryInterface)=>setFactoryConfigWorkspace(item)} />
                <SelectSimpleEntity
                    entityList={features}
                    onChange={(value:any)=>setFeature(value)}
                    label='Panel'
                    showTitle='label'
                    renderOption={(f)=><ListItem>
                        <ListItemIcon> <CustomIcon icon={f.icon} /></ListItemIcon>
                        <ListItemText>{f.label}</ListItemText>
                        <Divider/>
                    </ListItem>}
                />
                {status === 'loading' && <CircularProgress size='24px'/>}

            </FlexContainer>
           {/*  <CustomButton icon='config' variant='iconButton' color='inherit' popoverTitle='Gestionar fábrica' /> */}
        </FlexContainer>
        <br/>

 
        
        <TgdTabs
        key={factory?.name}
        items={tabs}
        labelIterator='label'
        content={({component})=>component}
        props={{
            onClick:(item:any)=>item.plant ? setPlantConfigWorkspace(item.plant) : null
        }}
/*         customTabComponent={'Agregar Planta'}
        popoverTitle='Agregarplanta'
        icon='add' */
        />
        
    </>);
}

export default DiscoverPage