import { TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { PlantInterface } from '../plants/service.plant';
import { EquipmentInterface, useEquipmentsTag, useUpdateEquipment } from './services/service.lubricationPoints';
import { useToken } from '../session/service.session';
import SelectCriticality from '../criticalities/components/SelectCriticality';
import SelectSector from '../sectors/atom/SelectSector';
import { useStatusProcessor } from '../errors/services/StatusProcessor';
import DefaultFormLayout from '../../components/forms/DefaultFormLayout';
import { errorsLog } from '../../utils/utils';
import { CriticalityTypes } from '../criticalities/types/types';
import SelectTypeBy from './../types/molecules/SelectTypeBy';
import { enumTgdTypes } from '../types/Types/types';


interface Props{
    item:EquipmentInterface
    plant?:PlantInterface
}



const UpdateEquipment:React.FC<Props> = ({item,plant}) => {

    const tagFP = item.tagFP
    const token = useToken()
    const {register,setValue,handleSubmit,watch,errors,control} = useForm()
    const [updateEquipment,data] = useUpdateEquipment()
    const {status,message,error} = useStatusProcessor(data)
    const {getTag} = useEquipmentsTag()

    const submit = (data:EquipmentInterface) => {
        updateEquipment({
            data:{
                tagFP:item?.tagFP,
                tagTGD:`${getTag(item,'equipment')}`,
                sector: data.sector.toUpperCase(),
                plantTag: data.plantTag.toUpperCase(),
                equipment: data.equipment.toUpperCase(),
                type: data.type.toUpperCase(),
                criticality:data.criticality.toUpperCase()
            },
            token
        })
    }

    useEffect(()=>{
        register({name:'criticality',value:''},{required:{value:true,message:'Criticidad requerida'}})
    },[register])
   
    errorsLog(errors)

    return (<>

            <DefaultFormLayout onChange={handleSubmit(submit)} status={status} message={message} error={error} buttonTitle='editar Equipo' icon='edit' variant='fab' popoverTitle='Editar Equipo'>
                
                <TextField
                inputRef={register({
                    required:{value:true,message:'Campo Obligatorio'},
                })} 
                name="plantTag" 
                placeholder='Codigo de planta' 
                defaultValue={item?.plantTag}
                variant='outlined'
                label={errors?.plantTag?.message || 'Codigo de planta'} 
                color={errors?.plantTag?.message  ? 'primary' : 'secondary'}
                size="small"
                />
                

                <TextField
                inputRef={register({
                    required:{value:true,message:'Campo Obligatorio'},
                })} 
                name="equipment" 
                placeholder='Nombre del Equipo' 
                defaultValue={item?.equipment}
                variant='outlined'
                label={errors?.equipment?.message || 'Nombre del Equipo'} 
                color={errors?.equipment?.message ? 'primary' : 'secondary'}
                size="small"
                />

                <Controller
                as={<SelectSector
                    error={errors?.sector?.message } 
                    tagFP={tagFP} 
                    onChange={(value:string)=>setValue('sector',value)}
                    defaultValue={watch('sector',item?.sector)}
                    />}
                name='sector'
                defaultValue={item?.sector}
                rules={{required:{value:true,message:'Campo Obligatorio'}}}
                control={control}
                />

                <Controller
                as={<SelectTypeBy
                    error={errors?.type?.message}
                    by={{key:'type',value:enumTgdTypes.equipment}}
                    tagFP={tagFP}
                    onChange={(value)=>setValue('type',value)}
                    defaultValue={watch('type')}
                    />}
                name='type'
                defaultValue={item?.type}
                rules={{required:{value:true,message:'Campo Obligatorio'}}}
                control={control}
                />
               
                <SelectCriticality
                by={{key:'criticalityType', value:CriticalityTypes.equipment}}
                error={errors?.criticality?.message} 
                color={errors?.criticality?.message ? 'primary' : 'secondary'}
                tagFP={tagFP}
                onChange={(value)=>setValue('criticality',value)}
                defaultValue={watch('criticality',item?.criticality)}
                />
               
            </DefaultFormLayout>
 </>);
}

export default UpdateEquipment;
