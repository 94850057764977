import React, { useState } from 'react';
import AnyGrid from '../../components/grids/AnyGrid';
import { gridModelStock, StockInterface, useStockAreaCategorySubcategory } from './services/service.stock';
import CreateStockForm from './CreateStockForm';
import { capitalize } from '../../utils/utils';
import UpdateStockForm from './UpdateStockForm';
import UpdateSubCategoryForm from '../sub-categories/UpdateSubCategoryForm';
import NewStockQuantityForm from './NewStockQuantityForm';
import CreatePurchaseRequestForm from '../purchase-requests/CreatePurchaseRequestForm';
import { SubCategoryInterface } from '../sub-categories/service.subcategories';
import HiddenStockForm from './HiddenStockForm';
import CustomButton from '../../components/buttons/CustomButton';

interface Props{
    subcategory:SubCategoryInterface
}
const SubcategoryStockGrid:React.FC<Props> = ({subcategory}) => {

    const [tab,setTab] = useState(false)
    
    const {data:categoryStocks,status,error} = useStockAreaCategorySubcategory({
        categoryArea:subcategory.categoryArea,
        category:subcategory.category,
        subcategory:subcategory.subcategory,
        tagFP:subcategory.tagFP
    })
    
    const subCategoryStocksNotHidden = categoryStocks?.filter((item)=>item.hidden === false) || []
    const subCategoryStocksHidden = categoryStocks?.filter((item)=>item.hidden === true) || []
    

    const ShowArchived = <CustomButton 
        popoverTitle={tab ? 'Ver items' :'Ver archivados' }
        icon={tab ? 'before' : 'delete'}
        action={()=>setTab(!tab)} 
        />

    return (

        <AnyGrid
            gridModel={gridModelStock}
            height='50vh'
            items={tab ? subCategoryStocksHidden : subCategoryStocksNotHidden}
            loading={categoryStocks ? false : true}
            title={ tab ? 'Archivado' :`${capitalize(subcategory.subcategory)} en stock`}
            headerActions={()=><>
                <UpdateSubCategoryForm defaultValue={subcategory} mode='modal'/>
                <CreateStockForm mode='modal' subcategory={subcategory}/>
                {ShowArchived}
            </>}
            itemActions={(item:StockInterface)=><>
                <UpdateStockForm defaultValue={item}/> 
                <NewStockQuantityForm key={item.id} item={item} />
                <CreatePurchaseRequestForm mode='popover' variant='iconButton' stock={item}/>
                <HiddenStockForm item={item} />
            </>}
        />
    );
}

export default React.memo(SubcategoryStockGrid);
