import { useCallback, useState } from "react"

export const useModal = (defaultValue?:boolean) => {
    const [modal,setModal] = useState<boolean>(defaultValue || false)

    const handleModal = useCallback(() =>{
        setModal(!modal)
    },[setModal,modal])

    const handleClose = (invert?:boolean) => {
        setModal(invert ? true : false)
    }

    return {modal,setModal,handleModal,handleClose}
}