import React, { useEffect } from 'react';
import ModalButton from '../../components/buttons/ModalButton';
import { Typography, TextField } from '@material-ui/core';
import CustomButton from '../../components/buttons/CustomButton';
import FlexContainer from '../../components/presentational/FlexContainer';
import theme from '../../infrastructure/materialUi/themeConfig';
import StatusAndMessage from '../errors/StatusAndMessage';
import { useForm } from 'react-hook-form';
import { SubCategoryInterface, useUpdateSubCategory } from './service.subcategories';
import { useStatusProcessor } from '../errors/services/StatusProcessor';
import { ComponentUIModes } from '../../components/ComponentUIModes';


interface Props{
    mode:ComponentUIModes
    defaultValue:SubCategoryInterface
}

const UpdateSubCategoryForm:React.FC<Props> = ({defaultValue,mode}) => {
    
    const [updateSubcategory,updateResult ] = useUpdateSubCategory()
    const { status, message, error } = useStatusProcessor(updateResult)
    const {register,setValue,errors,watch,handleSubmit} = useForm()
    const title=`Editar subcategoria: ${defaultValue.subcategory}`
    
    useEffect(()=>{
        defaultValue &&
            Object.entries(defaultValue).map(([key,value]:[string,any]) => {
                register({
                    name:key,value
                },
                {
                    required:{
                        value:true,
                        message:'Campo requerido'
                    }
                })

            })
        
    },[defaultValue])

    const submit = (item:SubCategoryInterface) => {
        updateSubcategory(item)
    }

    const form = <>
        <TextField
        inputRef={register()}
        name='subcategory'
        size='small'
        variant='outlined'
        value={watch('subcategory')}
        label={errors?.subcategory?.message || 'Subcategoria'}
        color={errors?.subcategory?.message ? 'primary' : 'secondary'}
        />
        <CustomButton status={status} action={handleSubmit(submit)}>Editar</CustomButton>
    </>

    const content=<> 
        <Typography variant='h6'>{mode === 'content' && title}</Typography>
        <StatusAndMessage status={status} message={message} error={error} />
        
        {mode === 'modal' && 
        <FlexContainer flexDirection='column'>
            {form}
        </FlexContainer> }
    
        {mode === 'content' && 
        <FlexContainer padding={`${theme.spacing(3)}px 0px`}>
            {form}
        </FlexContainer>}    
    </>
    
    return (<>

        {mode === 'content' && content}
    
        {mode === 'modal' && <ModalButton icon='edit' color='primary'  title={title} >
            {content}
        </ModalButton>}
    </>);
}

export default React.memo(UpdateSubCategoryForm);
