import React, { useEffect } from 'react';

import { CircularProgress } from '@material-ui/core';

import CustomButton from '../../components/buttons/CustomButton';
import { useEntityMutationType } from '../../infrastructure/Interfaces';
import { useStatusProcessor } from '../errors/services/StatusProcessor';
import { useClickPosition } from '../../utils/hooks/useClickPosition';
import { useSnackbar } from '../../utils/hooks/useSnackbar';


interface Props{
    deleteHookControler:useEntityMutationType
    item:any
    typeLabel:string
}

const DeleteItemForm:React.FC<Props> = ({typeLabel,item,deleteHookControler}) => {

    const type=typeLabel
    const [deleteMethod, deleteResult] = deleteHookControler
    const { status, message,error } = useStatusProcessor(deleteResult)
    const {position,getClickPosition} = useClickPosition()
    const { setData } = useSnackbar()

    useEffect(()=>{
        if(deleteResult.status === 'error'){

            setData({
                modal:true,
                errors:`${error}` ,
                severity:'error',
                position:position
            })
        }

        if(deleteResult.status === 'success'){
            setData({
                modal:true,
                errors:message,
                severity:'success',
                position
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[deleteResult.status,error])

    const submit = (item:any)=>{
        deleteMethod(item)
    }
    
    if(status === 'loading')  return <CircularProgress size='20px' />
    return (<>
            <CustomButton  popoverTitle={`Eliminar ${type}`} variant='iconButton' icon='delete' action={(e)=>getClickPosition(e,()=>submit(item))} />
        
    </>);
}

export default DeleteItemForm;
