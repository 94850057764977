import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
interface Props {
    status:string
    children:string | React.ReactNode
    variant?:'outlined' | 'contained'
    color?:'primary' | 'secondary' 
    type?:'submit'
}

const ProgressButton:React.FC<Props> = ({status,children,variant,color,type}) => {
    return (
        <Button 
            variant={variant}
            color={color}
            type={type}
        >
            {status === 'loading' 
                ?<CircularProgress size='20px' color={'inherit'} />   
                :<>{children}</>
            }
        </Button>
    );
}

export default ProgressButton;

