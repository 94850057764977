import { FormRegExp } from '../../../../infrastructure/regex/regexForms'
import { Crudfields } from '../../../form-config/interfaces'
import { useFormConfigs } from '../../../form-config/useFormConfigs'
import { SensorTypeInterface } from '../interfaces/SensorTypeInterfaces'
import { useLangLabels } from './../../../language/services/useSetLabel';


export const useSensorTypeForm = ({actionType,defaultValue}:Crudfields,tagFP:string) => {
    
    const {lang} = useLangLabels()

    return useFormConfigs<SensorTypeInterface>(actionType,{
        singularName:lang.iotSensorTypes.title.singular,
        pluralName:lang.iotSensorTypes.title.plural,
        fields:[
            {
                label:'Id',
                name:'id',
                formSelector:'none',
                type:'number',
                initialValue:0,
            },
            {
                label:lang.iotSensorTypes.form.label.sensorType,
                name:'sensorType',
                formSelector:'text',
                type:'string',
                initialValue:'',
                validations:{
                    required:{value:true,message:'requerido'},
                    pattern:{value:FormRegExp.name},
                    maxLength:{value:50}
                }
            },
            {
                label:lang.iotSensorTypes.form.label.sensorTypeCode,
                name:'sensorTypeCode',
                type:'string',
                formSelector:'none',
                initialValue:'',
            },
            {
                label:lang.globalentityValues.tagFP,
                name:'tagFP',
                type:'string',
                formSelector:'none',
                initialValue:tagFP,
            }
        ]
    },defaultValue)
}