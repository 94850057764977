import React, { useState } from 'react';

//components
import AddClientRefactor from './User/AddClientRefactor';
import AddAccountManager from './User/AddAccountManager';
import AddAdminPageRefactor from './User/AddAdminPageRefactor';
import AddOperatorRefactor from './User/AddOperatorRefactor';
import AddEngineerRefactor from './User/AddEngineerPageRefactor';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import { ListItemText, ListItem } from '@material-ui/core';


//router
import { Route, NavLink, Switch } from 'react-router-dom';

//service
import { filterAccessTo } from '../../infrastructure/tgd.config'
import { useUser,useDimention } from '../../domains/persons/services/service.person';
import { USER_ROUTES } from '../../infrastructure/routes/routes';



const useStyles = makeStyles((theme) => ({

  tab:{
    width:'auto',
    alignItems:'center',
    justifyContent:'center',
    textDecoration:'none',
    '& > link':{

    }
  },
  activeTab:{
    backgroundColor:'#FFF',
  }

})); 
interface TabMenuInterface{
  id:string
  title:string
  route:string
  access:number
}
const AddUser:React.FC = () => {
  const classes = useStyles();
  const {data:user} = useUser()
  const dimention = useDimention()
  const [tabMenu] = useState<TabMenuInterface[]>([
    {
      id:'1',
      title:'Nuevo Administrador',
      route:'agregar-administrador',
      access:110,
    },
    {
      id:'2',
      title:'Nuevo Gerente Regional ',
      route:'agregar-gerente-regional',
      access:110,
    },
    {
      id:'3',
      title:'Nuevo Cliente',
      route:'agregar-cliente',
      access:160,
    },
    {
      id:'4',
      title:'Nuevo Supervisor',
      route:'agregar-supervisor',
      access:160,
    },
    {
      id:'5',
      title:'Nuevo Operario',
      route:'',
      access:163,
    },
  ])


  return (
    <>
      
      <AppBar elevation={0} color='transparent' position='relative' style={{display:'flex',flexDirection:'row',gap:'25px'}}>
          {
            filterAccessTo(user?.type!,tabMenu).map((item:TabMenuInterface) =>
              <ListItem key={item.title} button exact component={NavLink} to={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.addUser}/${item.route}`}  className={classes.tab} activeClassName={classes.activeTab} >
                <ListItemText> <Typography  variant={'button'} style={{color:'#333'}}>{item.title}</Typography> </ListItemText>
              </ListItem>
            )
          }
      </AppBar>
      
      <Switch>

        {((user?.type === 'SA') || (user?.type === 'A')) &&
          <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.addUser}/agregar-administrador`}>
              <AddAdminPageRefactor />
          </Route>
        }

        {((user?.type === 'SA') || (user?.type === 'A')) &&
          <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.addUser}/agregar-gerente-regional`}>
              <AddAccountManager/>
          </Route >
        }

        {((user?.type === 'SA') || (user?.type === 'A') || (user?.type === 'AC')) &&
          <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.addUser}/agregar-cliente`}>
              <AddClientRefactor/>
          </Route>
        }

        {((user?.type === 'SA') || (user?.type === 'A') || (user?.type === 'AC')) && 
          <Route path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.addUser}/agregar-supervisor`}>
              <AddEngineerRefactor />
          </Route>
        }

        <Route exact path={`/${USER_ROUTES.home}/${dimention}/${USER_ROUTES.addUser}`}>
          <AddOperatorRefactor />
        </Route>
        
      </Switch>

    </>            
  );
}

export default AddUser