import React, { useState } from 'react';
import { PlantInterface } from '../../plants/service.plant';
import AnyGrid from '../../../components/grids/AnyGrid';
import { gridModelObservations, useObservationsByPeriodAndTagFP } from '../service/service.observations'
import { PeriodInterface } from '../../../components/filters/AnyFiltering';
import { CarrousellActionInterface } from '../../../components/grids/AnyGrid';
import DeleteObservation from '../DeleteObservationForm';
import CUObservationForm from '../CUObservationForm';
import { CrudFormType } from '../../../infrastructure/form/typescript/FormTypes';
import { ObservationInterface } from '../types/ObservationTypes';

interface Props{
    plant:PlantInterface
}

const carrousellActions:CarrousellActionInterface[] = [
    {icon:'edit',component:(item:ObservationInterface) => <CUObservationForm tagFP={item.tagFP} item={item} actionType={CrudFormType.update} />/* <UpdateObservationForm tagFP={item.tagFP} item={item}/> */}
]

const ManageObservationsPage:React.FC<Props> = ({plant}) => {
    
    const tagFP = plant.tagFP
    const [period, setPeriod] = useState<PeriodInterface | undefined>();
    const { data:observations } = useObservationsByPeriodAndTagFP(period,tagFP)

    return(
        <AnyGrid 
            periodChange={setPeriod}
            gridModel={gridModelObservations}
            title='Observaciones'
            items={observations || []}
            loading={observations ? false : true}
            height='55vh'
            carrousellActions={carrousellActions}
            itemActions={(item:any)=><>
                    {/* <UpdateObservationForm tagFP={item.tagFP} item={item}/> */}
                    <DeleteObservation observation={item} />
                </>}               
        />
    )
}

export default React.memo(ManageObservationsPage);
