import React from 'react';
import { CrudFormType } from '../../infrastructure/form/typescript/FormTypes';
import { useCreateUpdate } from '../crud/hooks/useCreateUpdate';
import { useLangLabels } from '../language/services/useSetLabel';
import { CUEntityFormPropsType, uiFormInterface } from '../crud/types/crudTypes';
import DefaultFormLayout from '../../components/forms/DefaultFormLayout';
import FieldSelector from '../../infrastructure/form/components/FieldSelector';
import { AnomalyInterface } from './types/anomaliesTypes';
import { AnomalyCRUDDEpendencies, CreateAnomaly, DeleteAnomaly, UpdateAnomaly } from './services/service.anomalies';
import { useAnomalyForm } from './services/useAnomalyForm';

/* type CUAnomalyFormProps = {
    tagFP:string
    actionType:CrudFormType.create
    item?:AnomalyInterface
} | {
    tagFP:string
    actionType:CrudFormType.update
    item:AnomalyInterface
} */

type props = CUEntityFormPropsType<AnomalyInterface>

const CUAnomalyForm: React.FC<props> = ({tagFP, item, actionType}) => {

    //DB 
    const { action, resetStatus,status, error, message } = useCreateUpdate({
        create: CreateAnomaly,
        update: UpdateAnomaly,
        delete: DeleteAnomaly
    },actionType, AnomalyCRUDDEpendencies)

    //UI
    const { lang } = useLangLabels()

    const { uiModel, handleSubmit, reset } = useAnomalyForm(tagFP, item)

    const ui = (() => {
        const formName = lang.anomalies.plural 
        switch (actionType) {
            case CrudFormType.create:
                const create:uiFormInterface = {
                    icon:'add',
                    title:`${lang.actionTypes.create} ${formName.toLowerCase()}`,
                    variant:'button',
                    mode:'modal'
                }
                
                return create

            case CrudFormType.update:
                const update:uiFormInterface = {
                    icon:'edit',
                    title:`${lang.actionTypes.update} ${formName.toLowerCase()}`,
                    variant:'iconButton',
                    mode:'content'
                }
                return update
        }
    })()

    //Methods
    const submit = (data:AnomalyInterface) => {
        action(data)
    }

    const handleReset = () => {
        resetStatus()
        reset()
    }

    return(
        <DefaultFormLayout
            mode={ui.mode}
            icon={ui.icon}    
            variant={ui.variant}
            buttonTitle={ui.title}
            popoverTitle={ui.title}
            {...{status,error,message}} 
            onChange={handleSubmit(submit)}  
            onClose={handleReset} 
        >
            {uiModel.map((props,index)=>
                <FieldSelector key={index} fieldProps={props} />
            )}
        </DefaultFormLayout>
    )
};

export default CUAnomalyForm;