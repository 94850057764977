import { Paper, Typography } from '@material-ui/core'
import React from 'react'
import { Toolbar } from 'react-data-grid-addons'
import ComponentMode from '../components/ComponentMode'
import PageContainer from '../components/pageContainers/PageContainer'
import { usePlants } from '../domains/persons/services/service.person'
import { DefaultSidebarWidth } from '../layouts/components/Sidebar'
import { useLangLabels } from './../domains/language/services/useSetLabel';

interface ViewPlantAsClient{
    tagFP:string
}



const ClientVisorPanel:React.FC<{}> = ({})=>{
    return(
        <ComponentMode 
        mode='drawer'
        variant='iconButton'
        icon='view'
        drawerProps={{
            styles:{
                drawerWidth:`calc(100vw - ${DefaultSidebarWidth}px)`
            },
            anchor:'right',
        }} >

            <Typography variant='h6'>holi</Typography>

        </ComponentMode>
    )
}

const ViewPlantAsClientPage:React.FC<ViewPlantAsClient> = ({tagFP})=> {

    const {lang} = useLangLabels()
    const {data:plants} = usePlants()

    return(<>

       {/*  <Paper>
            <Toolbar>
                <Typography variant='h6'>
                    {lang.plants.plural} {lang.words.available}
                </Typography>
            </Toolbar>
        </Paper>

        {plants 
        
            ? plants.map(()=>
                <ClientVisorPanel/>
            )

            : <PageContainer>
                {lang.messages.status.loading}...
            </PageContainer>
        } */}
    </>
    )
}

export default ViewPlantAsClientPage