import React, { memo } from 'react';

import FlexContainer from '../../../../components/presentational/FlexContainer';
import SurveyModal from '../../SurveyModal';
import CreateSurveyForm from './CreateSurveyForm';
import { useSurveyController } from './controllers/surveyController';
import { CustomIcon } from '../../../../components/buttons/CustomButton';

interface Props{

}

// eslint-disable-next-line no-empty-pattern
const SurveyCarrousell:React.FC<Props> = ({}) => {

    const outOfRoute = false
    const {modal, handleModal, surveyState,nextPrevBacklogStep,finishEquipment} = useSurveyController()
    
    //GLOBAL DATA TO SURVEY BACKLOG
    const currentLubricationPoint = surveyState?.currentPoint
    const todayLubricationPointsBacklog = surveyState?.dailyPoints || []
    const todayLubricationPointBacklogStep = surveyState?.backlogStep || 0
    const tasks = surveyState?.tasks 
    
    return (
        <>   
            <FlexContainer onClick={handleModal} height='80px' width='120px' align={'center'} justify='center' padding='16px'>
                <CustomIcon icon='surveyPlay'/>
            </FlexContainer>
            
            {currentLubricationPoint  &&
                <SurveyModal
                outOfRoute={outOfRoute}
                open={modal}
                handleClose={handleModal}
                currentLubricationPoint={currentLubricationPoint}
                tasks={surveyState?.tasks}
                prevNextValidations={{
                    prev:!(todayLubricationPointBacklogStep > 0),
                    next:!(todayLubricationPointBacklogStep < (todayLubricationPointsBacklog.length -1 ))
                }}
                prevNextActions={{
                    prev:()=>nextPrevBacklogStep('PREV'),
                    next:()=>nextPrevBacklogStep('NEXT')
                }}
            >
                <CreateSurveyForm
                    item={currentLubricationPoint} 
                    tasks={tasks} 
                    outOfRoute={outOfRoute}
                    onFinish={finishEquipment} 
                />
                
            </SurveyModal>}
       </>
    );
}

export default memo(SurveyCarrousell);
