import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import TgdSvgIcon from './TgdSvgIcon';


const InventoryIcon:React.FC<any> = (props:SvgIconProps) => {
    return (
        <TgdSvgIcon {...props} style={{color:!props.color ? '#000' : 'inherit'}}>
            
            <path d="M20,2H4C3,2,2,2.9,2,4v3.01C2,7.73,2.43,8.35,3,8.7V20c0,1.1,1.1,2,2,2h14c0.9,0,2-0.9,2-2V8.7c0.57-0.35,1-0.97,1-1.69V4 C22,2.9,21,2,20,2z M19,20H5V9h14V20z M20,7H4V4h16V7z"/>
        </TgdSvgIcon>
    );
}

export default InventoryIcon