import React, {  memo, useCallback, useMemo } from 'react';
import { GridColumns, GridCellParams, GridToolbarExport} from '@material-ui/data-grid';
import {  isOverflown, DataGrid } from '@material-ui/data-grid';
import FlexContainer from '../../../../components/presentational/FlexContainer';
import Typography  from '@material-ui/core/Typography';
import { IconButton, Paper, Popper, makeStyles, createStyles } from '@material-ui/core';
import { LubricationPointInterface } from '../../../equipments/services/service.lubricationPoints';
import  CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import IndividualLubricationPointSurvey from '../surveys/IndividualLubricationPointSurvey';
import EquipmentsInfo from '../../../equipmentsInfo/EquipmentsInfo';
import LeakLevelComponent from './../../LeakLevelComponent';
import { CustomIcon } from './../../../../components/buttons/CustomButton';
import theme from './../../../../infrastructure/materialUi/themeConfig';



interface GridCellExpandProps {
	value: string;
	width: number;
}

const useStyles = makeStyles(() =>
	createStyles({
	root: {
		alignItems: 'center',
		lineHeight: '24px',
		width: '100%',
		height: '100%',
		position: 'relative',
		display: 'flex',
		'& .cellValue': {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		},
	},
	}),
);

const GridCellExpand = React.memo(function GridCellExpand(
	props: GridCellExpandProps,
) {
	const { width, value } = props;
	const wrapper = React.useRef<HTMLDivElement | null>(null);
	const cellDiv = React.useRef(null);
	const cellValue = React.useRef(null);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const classes = useStyles();
	const [showFullCell, setShowFullCell] = React.useState(false);
	const [showPopper, setShowPopper] = React.useState(false);

	const handleMouseEnter = () => {
	const isCurrentlyOverflown = isOverflown(cellValue.current!);
	setShowPopper(isCurrentlyOverflown);
	setAnchorEl(cellDiv.current);
	setShowFullCell(true);
	};

	const handleMouseLeave = () => {
	setShowFullCell(false);
	};

	React.useEffect(() => {
	if (!showFullCell) {
		return undefined;
	}

	function handleKeyDown(nativeEvent: KeyboardEvent) {
		// IE11, Edge (prior to using Bink?) use 'Esc'
		if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
		setShowFullCell(false);
		}
	}
	document.addEventListener('keydown', handleKeyDown);
	return () => {
		document.removeEventListener('keydown', handleKeyDown);
	};
	}, [setShowFullCell, showFullCell]);

	return (
	<div
		ref={wrapper}
		className={classes.root}
		onMouseEnter={handleMouseEnter}
		onMouseLeave={handleMouseLeave}
	>
		<div
		ref={cellDiv}
		style={{
			height: 1,
			width,
			display: 'block',
			position: 'absolute',
			top: 0,
		}}
		/>
		<div ref={cellValue} className="cellValue">
		{value}
		</div>
		{showPopper && (
		<Popper
			open={showFullCell && anchorEl !== null}
			anchorEl={anchorEl}
			style={{ width, marginLeft: -17 }}
		>
			<Paper
			elevation={1}
			style={{ minHeight: wrapper.current!.offsetHeight - 3 }}
			>
			<Typography variant="body2" style={{ padding: 8 }}>
				{value}
			</Typography>
			</Paper>
		</Popper>
		)}
	</div>
	);
});

const  renderCellExpand = (params: GridCellParams) => {
	return (
	<GridCellExpand
		value={params.value ? params.value.toString() : ''}
		width={params.colDef.width}
	/>
	);
}

const makeEquipmentRows = (items:LubricationPointInterface[]) => {

	return items?.map((item)=> ({ 

		//render data
		leakLevelColor:item.leakLevel?.description,  
		leakLevelText:item.leakLevel?.leakQuantity,  
		pendingEquipment:!!item.info?.pending,  
		inaccessibleEquipment:item.info?.inaccessible ? 'INACCESIBLE' : 'ACCESIBLE', 
		actions:item,
		info:item.info,
		pending:item.info?.pending,
		leak:item.info?.leak ? 'SI' : 'NO',

		//plain Info
		lastInspectionDate:item.info?.lastInspectionDate,
		supplies:item.info?.supplies,
		labelReplacement:item.info?.labelReplacement ? 'SI': 'NO',
		user:item.info?.user ,
		updatedToday:item.info?.updatedToday ? 'SI': 'NO',
		lastLubrication:item.info?.lastLubrication ,
		downloadDate:new Date(),

		tagTGD:item.tagTGD ,
		sector:item.sector ,
		plantTag:item.plantTag ,
		tagFP:item.tagFP ,
		equipment:item.equipment ,
		type:item.type ,
		criticality:item.criticality,
		state:item.state,
		egressDate:item.egressDate,
		lowReason:item.lowReason,
		admissionDate:item.admissionDate,
		brand:item.brand,
		capacity:item.capacity,
		component:item.component,
		element:item.element,
		function:item.function,
		id:item.id,
		labelId:item.labelId,
		location:item.location,
		lubricant:item.lubricant,
		lubricantType:item.lubricantType,
		measureUnit:item.measureUnit,
		model:item.model,
		observations:item.observations,
		oilAnalysis:item.oilAnalysis ? 'SI' : 'NO' ,
		review:item.review,
		routes:item.routes
		
	}))
}


interface Props{
	items:any
	colExcludes:any[]
	enableExport:boolean
	title?:string
}


const LubricationPointsGrid:React.FC<Props> = React.memo(({items,colExcludes,enableExport,title}) => {


	const LeakLevel = useCallback((item: any) => (
		<LeakLevelComponent lastlubricantCuantity={item.getValue('leakLevelText')} pointCapacity={item.getValue('capacity')}/>
	),[])

	const pendingComponentIndicator = useCallback((item:GridCellParams) => <>
			{item.value  
			? 	<>
					{item.getValue('inaccessibleEquipment') === 'INACCESIBLE' ? <CustomIcon icon='cancelOutlined' style={{color:theme.palette.error.main}}/> : null }
					<IndividualLubricationPointSurvey outOfRoute={false} lubricationPoint={item.getValue('actions')} />
				</>
			
			:	<IconButton size={'small'} color={'secondary'}  edge="end" aria-label="Estado del equipo">
					<CheckCircleOutlineIcon style={{color:'green'}}/>
				</IconButton>}
		
		</>,[items])

	const actions = useCallback((item:GridCellParams)=><>
	{item && <EquipmentsInfo type='modal' item={item.value as LubricationPointInterface}/> }
	</>,[])
	

	const columns: GridColumns = useMemo(()=> ([

		//Render columns
		{field: 'actions',renderCell: (item:any) => actions(item),headerName: 'Info',width: 70},
		{field: 'leakLevelColor',renderCell: (item:any) => LeakLevel(item),headerName: 'Nivel de Perdida',width: 110},
		{field: 'pendingEquipment',renderCell: (item:any) => pendingComponentIndicator(item),headerName:'Relevado',width: 100},
		
		//normal Columns

		{renderCell: renderCellExpand, width: 300, field: 'equipment',headerName:'Nombre de equipo'},
		{renderCell: renderCellExpand, width: 300, field: 'element',headerName:'Elemento'},
		{renderCell: renderCellExpand, width: 300, field: 'component',headerName:'Componente'},
		{renderCell: renderCellExpand, width: 150, field: 'plantTag',headerName:'Tag planta'},
		{renderCell: renderCellExpand, width: 180, field: 'criticality',headerName:'Criticidad',},
		{renderCell: renderCellExpand, width: 180, field: 'inaccessibleEquipment',headerName:'Inaccesibles'},
		{renderCell: renderCellExpand, width: 180, field: 'leak',headerName:'Perdida'},
		{renderCell: renderCellExpand, width: 150, field: 'labelId',headerName:'ID de Etiqueta'},
		{renderCell: renderCellExpand, width: 180, field: 'tagTGD',headerName:'Tag TGD'},
		{renderCell: renderCellExpand, width: 160, field: 'sector',headerName:'Sector'},
		{renderCell: renderCellExpand, width: 150, field: 'tagFP',headerName:'Tag de Fabrica'},
		{renderCell: renderCellExpand, width: 150, field: 'lubricant',headerName:'Lubricante'},
		{renderCell: renderCellExpand, width: 150, field: 'lubricantType',headerName:'Tipo de lubricante'},
		{renderCell: renderCellExpand, width: 150, field: 'measureUnit',headerName:'unidad de Medida'},
		{renderCell: renderCellExpand, width: 150, field: 'oilAnalysis',headerName:'Analisis de aceite'},
		{renderCell: renderCellExpand, width: 150, field: 'type',headerName:'Tipo'},
		{renderCell: renderCellExpand, width: 150, field: 'criticality',headerName:'Criticidad'},
		{renderCell: renderCellExpand, width: 150, field: 'state',headerName:'Estado'},
		{renderCell: renderCellExpand, width: 150, field: 'egressDate',headerName:'Fecha de baja'},
		{renderCell: renderCellExpand, width: 150, field: 'lowReason',headerName:'rason de Baja'},
		{renderCell: renderCellExpand, width: 150, field: 'admissionDate',headerName:'Fecha de creación'},
		{renderCell: renderCellExpand, width: 150, field: 'brand',headerName:'Marca'},
		{renderCell: renderCellExpand, width: 150, field: 'capacity',headerName:'Capacidad'},
		{renderCell: renderCellExpand, width: 150, field: 'function',headerName:'Funcion'},
		{renderCell: renderCellExpand, width: 150, field: 'id',headerName:'ID'},
		{renderCell: renderCellExpand, width: 150, field: 'location',headerName:'Ubicación'},
		{renderCell: renderCellExpand, width: 150, field: 'model',headerName:'Modelo'},
		{renderCell: renderCellExpand, width: 150, field: 'observations',headerName:'Observación'},
		{renderCell: renderCellExpand, width: 150, field: 'review',headerName:'Revision'},
		{renderCell: renderCellExpand, width: 150, field: 'routes',headerName:'Rutas'},
		{renderCell: renderCellExpand, width: 180, field: 'downloadDate',headerName:'Fecha de descarga'},
		{renderCell: renderCellExpand, width: 180, field: 'lastInspectionDate',headerName:'Ultima inspección'},
		{renderCell: renderCellExpand, width: 180, field: 'supplies',headerName:'Herramientas'},
		{renderCell: renderCellExpand, width: 180, field: 'labelReplacement',headerName:'Reemplazo de etiqueta'},
		{renderCell: renderCellExpand, width: 180, field: 'user',headerName:'usuario'},
		{renderCell: renderCellExpand, width: 180, field: 'updatedToday',headerName:'Actualizado hoy'},
		{renderCell: renderCellExpand, width: 180, field: 'lastLubrication',headerName:'Última lubricación'},
	].filter((col)=>!colExcludes.includes(col.field))),[colExcludes,actions,LeakLevel,pendingComponentIndicator])

		

	//route addings to survey
	const rows = useMemo(()=>items ? makeEquipmentRows(items) : [],[items])

	return (<>
		
		<div style={{ height: 550, width: '100%' }}>
			<DataGrid 
				disableSelectionOnClick={true}
				pageSize={50}
				rowsPerPageOptions={[50,100,200]}
				rowHeight={32} 
				rows={rows} 
				columns={columns} 
				/* filterModel={filterModel} */
				loading={!!!items}
				localeText={{
					// spanish traductions
					//rootGridLabel: 'grid',
					noRowsLabel: 'No hay datos.',
					errorOverlayDefaultLabel: 'ha ocurrido un error.',

					// Columns selector toolbar button text
					toolbarColumns: 'Columnas',
					toolbarColumnsLabel: 'Select columns',

					//filters
					toolbarFilters: 'Filtros',
					toolbarFiltersLabel: 'Mostrar filtros',
					toolbarFiltersTooltipHide: 'Esconder filtros',
					toolbarFiltersTooltipShow: 'Mostrar filtros',
					
					// Columns panel text
					columnsPanelTextFieldLabel: 'Buscar columna',
					columnsPanelTextFieldPlaceholder: 'Titulo de columna',
					columnsPanelDragIconLabel: 'Reordenar columna',
					columnsPanelShowAllButton: 'Mostrar todo',
					columnsPanelHideAllButton: 'Esconder todo',

					// Export selector toolbar button text
					toolbarExport: 'Exportar',
					toolbarExportLabel: 'Exportar',
					toolbarExportCSV: 'Descargar as CSV',
				
				}}
				/* components={{
					Header:  ()=> CustomToolbar(title,enableExport),
					
				}}  */
			/>
		</div>
	</>);
}) 

const CustomToolbar = (title:string | undefined, enableExport:boolean) => {

	return (<>
		<FlexContainer justify='space-between' padding='24px 24px'>
			{title && <Typography variant='h6'>{title}</Typography>}
			{enableExport && <GridToolbarExport/>}
		</FlexContainer>
	</>);
} 

export default memo(LubricationPointsGrid);
