
import React from 'react';
import SimpleAutocompleteSelect from '../../../components/autocomplete/SimpleAutocompleteSelect';
import { useLangLabels } from '../../language/services/useSetLabel';
import { TgdTypes } from '../Types/types';

interface SelectColorProps{
    onChange:(value:any)=>void, 
    defaultValue?:string,
    error?:string,
    disabled?:boolean

}

const SelectTypes:React.FC<SelectColorProps> = ({ onChange, defaultValue, error, disabled}) => {

    const {lang} = useLangLabels()
    const label = lang.types.form.type

    return (
        <SimpleAutocompleteSelect
        {...{
            items:TgdTypes,
            showTitle:'label',
            onChange,
            defaultValue,
            error,
            label,
            disabled
        }}
        />
    );
}

export default SelectTypes;