import { useEffect, useState } from "react"
import { queryCache, useQuery } from "react-query"
import { clickPositionInterface } from "./useClickPosition"


export interface snackbarInterface{
    modal?:boolean,
    errors?:string,
    severity?:'warning'| 'error' | 'success' | '',
    position?:clickPositionInterface
}

export const useSnackbar = () => {

    const key = 'snackbar'

    const [status,setStatus] = useState('idle')
    const initialState :snackbarInterface = {
        modal:false,
        errors:'',
        severity:'',
        position:{
            positionX:0,
            positionY:0
        }
    }

    useEffect(()=>{
        queryCache.setQueryData(key,initialState)
    },[])

    const {data,status:querystatus}:any = useQuery([key],()=>queryCache.getQueryData(key),{
        initialData:()=>initialState
    })

    const setData = ({modal,errors,severity,position}:snackbarInterface) =>{
        setStatus('loading')
        queryCache.setQueryData(key,{
            modal:modal, 
            errors:errors,
            severity:severity, 
            position:position 
        })
        queryCache.invalidateQueries(key)
        setStatus(querystatus )
    }

    const handleModal = () => {
        setData(initialState)
    }

    return{
        position:data?.position,
        modal:data?.modal,
        errors:data?.errors,
        severity:data?.severity,
        status,
        handleModal,
        setData
    }
}