import { useEffect, useRef, useState } from "react"


export const useCanvas = (callback?:(item?:any)=>void) => {
    const canvas = useRef<HTMLCanvasElement>(null)
    const canvasCtx = useRef<CanvasRenderingContext2D | null>(null)
 
    const [ctxContext2D,setCtx] = useState<CanvasRenderingContext2D | null>(null)

    useEffect(()=>{
        if(canvas.current){
            const _ctx = canvas.current.getContext('2d')
            setCtx(_ctx)
            callback && callback(_ctx)
        }
    },[])

    return {
        canvas,
        ctxContext2D
    }
}