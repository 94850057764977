import React,{useEffect, useMemo} from 'react';
import { PlantInterface } from '../../plants/service.plant';
import { Tabs } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../../components/Tabs/TabPanel';
import TgdAuxList from '../../../components/lists/TgdAuxList';
import { usePlantOperators, usePlantSupervisors, usePlantClients, useUser } from '../../persons/services/service.person';
import UpdatePersonForm from '../../persons/UpdatePersonForm';
import AddPersonForm from '../../persons/AddPersonFormRefactor';
import PageContainer from '../../../components/pageContainers/PageContainer';
import ModalButton from '../../../components/buttons/ModalButton';
import { PersonInterface } from '../../persons/interfaces/userInterfaces';
import { useLangLabels } from '../../language/services/useSetLabel';

const a11yProps = (index: any) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const ManageUsersPage:React.FC<{plant:PlantInterface}> = ({plant}) => {

    const {data:user} = useUser()
    const {lang} =useLangLabels()
    const [value, setValue] = React.useState(0)
    const {data:operators,status:operatorsStatus} = usePlantOperators(plant.tagFP)
    const {data:supervisors,status:supervisorsStatus} = usePlantSupervisors(plant.tagFP) 
    const {data:clients,status:clientsStatus} = usePlantClients(plant.tagFP) 
    
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
	setValue(newValue);
	};

	useEffect(() => {
		setValue(0)
		localStorage.setItem('currentPlantModification',JSON.stringify(plant))
    }, [plant]);
    

    const users = {
        supervisors:supervisors,
        operators:operators,
        clients:clients
    }

    interface PageConfigInterface {
        name:string
        singularName:string
        headerAction:React.ReactNode
        items:any[],
        status:string
        children:(item:PersonInterface)=>React.ReactNode
    }

    const pageConfig:PageConfigInterface[] = useMemo(()=>([
        ...( user?.type !== 'S') ? [{
            name:lang.persons.supervisors,
            singularName:lang.persons.supervisor,
            items:users.supervisors,
            status:supervisorsStatus,
            headerAction:<ModalButton icon='add' variant='button' title={'Crear Supervisor '} children={<AddPersonForm   tagFP={plant.tagFP} role='Supervisor' />}/>,
            children:(item:any) => <UpdatePersonForm tagFP={plant.tagFP} person={item}/>
        }] : [],
        {
            name:lang.persons.operators,
            singularName:lang.persons.operator,
            items:users.operators,
            status:operatorsStatus,
            headerAction:<ModalButton icon='add' variant='button'title={'Crear Operario '} children={<AddPersonForm   tagFP={plant.tagFP} role='Operario' />}/>,
            children:(item) => <UpdatePersonForm  tagFP={plant.tagFP} person={item}/>
        },
        /* {
            name:'Clientes',
            singularName:'Cliente',
            items:users.clients,
            status:clientsStatus,
            headerAction:<ButtonModal title={'Crear Cliente '} children={<AddPersonForm  tagF={plant.factory} userType='C' />}/>,
            children:(item) => <UpdatePersonForm  tagFP={plant.tagFP} person={item}/>
            
        }, */
        
    ]),[operatorsStatus,users,supervisorsStatus,plant,user])

    return (<PageContainer>
        <Tabs 
            style={{marginTop:'0'}}
			value={value}
			onChange={handleChange}
			indicatorColor="secondary"
			textColor="secondary"
			variant="scrollable"
			scrollButtons="auto"
			aria-label="scrollable auto tabs example"
			>
				{pageConfig.map((item,index:number) => 
					<Tab key={index+100} label={item.name} {...a11yProps(index)} />
				)}
		</Tabs>
        {pageConfig.map((item:PageConfigInterface,index:number) =>
            <TabPanel key={index} value={value} index={index}>
                {/* {item.component} */}
                <TgdAuxList
                     type='name' 
                     items={item.items}
                     status='success'
                     displaySubNameHandler={'email'}
                     /* customButtons={} */
                     /* icon?: (item:any) => React.ReactNode */
                     children={item.children} 
                     toolbarActions={item.headerAction}
                     searchBar={true}
                />
            </TabPanel>
        )}
    </PageContainer>);
}

export default React.memo(ManageUsersPage);
