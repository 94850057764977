import { Chart, ChartConfiguration, registerables } from 'chart.js';
import React, { useState } from 'react';
import { useCanvas } from '../useCanvas';


Chart.register(...registerables)

interface LineChartInterface{
    chartValues:{[key:string]:number[] | undefined} 
    xAxis:string[]
    xOperation?:(item:any)=>string
}

const LineChart:React.FC<LineChartInterface> = ({chartValues,xAxis,xOperation})=>{

    const keyValues = chartValues && Object.entries(chartValues)
    const _xAxis = new Set(xAxis)

    const colors = [
        {
            backgroundColor: '#00429d20',
            borderColor: "#00429d",
        },
        {
            backgroundColor: '#4771b220',
            borderColor: "#4771b2",
        },
        {
            backgroundColor: '#73a2c620',
            borderColor: "#73a2c6",
        },
        {
            backgroundColor: '#a5d5d820',
            borderColor: "#a5d5d8",
        },
        {
            backgroundColor: '#FFD70020',
            borderColor: "gold",
        },
        {
            backgroundColor: '#ffbcaf20',
            borderColor: "#ffbcaf",
        },
        {
            backgroundColor: '#f4777f20',
            borderColor: "#f4777f",
        },
        {
            backgroundColor: '#cf375920',
            borderColor: "#cf3759",
        },
        {
            backgroundColor:'#93003a20',
            borderColor: '#93003a',
        },
        {
            backgroundColor:'#ffa50020',
            borderColor: '#ffa500',
        },
        {
            backgroundColor:'#ff000020',
            borderColor: '#ff0000',
        },
        
    ]

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const chartConfig:ChartConfiguration = {
        type: "line",
        data:{
            labels:[..._xAxis],
            datasets: keyValues?.map(([k,v],i) => ({
                label:k,
                data:v || [0],
                fill:true,
                ...colors[i]
            })),
            
        },
        options: {
            scales: {
                y: {
                    beginAtZero: true,
                    stacked: false,
                },
                x: {
                    ticks: {
                        // Include a dollar sign in the ticks
                        callback: (value, index, values) => {
                            //x labels operations 
                            return xOperation ? xOperation(xAxis[index]) : xAxis[index]
                        }
                    }
                }
            },
            plugins:{
                tooltip:{
                   callbacks:{

                        title:(value)=>value.map(item => {
                            return item.dataset.label || ''
                        }),

                        afterTitle:(value)=>value.map(item=>{
                            return `${item.label.slice(0,10)} - ${item.label.slice(11,-10)}`
                        })
                   }
                },
            }
            
        }
    }
    
    const [chart,setChart] = useState<any>(null);
    
    const makeChart = (ctx:any) => {
        const chartInstance = new Chart(ctx,chartConfig)
        setChart(chartInstance)
    }

    const {canvas} = useCanvas(makeChart)

    return(
        <canvas 
            ref={canvas} 
            height={'200px'} 
            width={'700px'}
        />
    )
}

export default LineChart