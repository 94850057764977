import React, { useEffect, useMemo } from 'react';
import SelectSimpleEntity from '../../../../components/select/SelectSimpleEntity'
import { useCallback } from 'react';
import { useLangLabels } from './../../../language/services/useSetLabel';

interface props {
    label?:string
    onChange:(value:boolean)=>void,
    defaultValue?:any,
    error?:string
}
const SelectBooleanOption:React.FC<props> = ({defaultValue,onChange,error,label}) => {
    
    const { lang } = useLangLabels()

    const TRUE = lang.STISensors.booleanSelector.data.fixed
    const FALSE = lang.STISensors.booleanSelector.data.mobile

    const values = useMemo(()=>[{
        label:TRUE,
        value:true
    },
    {
        label:FALSE,
        value:false
    }],[FALSE, TRUE])



    const _defaultValue = defaultValue !== undefined ? (defaultValue ? TRUE : FALSE) : ''

    const _onChange = useCallback((value:any) => {
        value &&
        value === TRUE && onChange(true)
        value === FALSE && onChange(false)
    },[FALSE, TRUE, onChange])

    useEffect(()=>{
        _defaultValue && _onChange(_defaultValue)
    },[_defaultValue])
    

    
    return (<>
        <SelectSimpleEntity
            error={error}
            defaultValue={_defaultValue}
            onChange={_onChange}
            label={error || label!}
            entityList={values}
            showTitle={'label'}
        />
    </>);
}

export default React.memo(SelectBooleanOption);
