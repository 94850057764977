import {  Box, Chip, Divider, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import PageContainer from '../../components/pageContainers/PageContainer';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import TgdSimpleTable from '../../components/TgdEquipmentsTable';
import { useEquipmentsTag, LubricationPointInterface } from './services/service.lubricationPoints';
import ViewLubricationPoint from './ViewLubricationPoint';
import CreateLubricationPoint from './CreateLubricationPoint';
import SimpleChip from '../../components/presentational/SimpleChip';
import AssignCancelRoutePage from '../routes/components/AssignCancelRouteForm';
import AssignCancelSupplyForm from '../supply/molecules/AssignCancelSupplyForm';
import TgdEquipmentsTable from '../../components/TgdEquipmentsTable'
import FlexContainer from '../../components/presentational/FlexContainer';
import AssignCancelPermissionForm from '../permisions/components/AssignCancelPermissionForm';
import { supplyParser } from '../../utils/utils';
import EditEquipmentComponent from './EditEquipmentComponent'
import { useDate } from '../../infrastructure/date/useDate';
import { EnumSupplyTypes } from '../supply/types/SupplyTypes';


interface Props{
	item:any
	type: 'component' | 'equipment' | 'element' 

}

const PointProperty:React.FC<{title:string, value:any}> = ({title,value}) => 
	<FlexContainer flexDirection='column' padding='24px 0px'>
		<Typography >
			{title}:
			<br />
			<strong>
				{value}
			</strong>
			<Divider/>
		</Typography>
	</FlexContainer>


const LubricationPointFileTemplate :React.FC<Props> = ({item,type}) => {

	const {getTag} = useEquipmentsTag()
	const {nowYear}= useDate()


	const supplies = supplyParser(item.supplies)
 	const permissions = item.permissions
 
    return (
				<Paper elevation={3} style={{width:'700px',height:'min-content' /* 'calc(700px * 1.41)' */}}>
					<PageContainer>
						<Grid container spacing={3} >
							<Grid item md={12}>
								<Typography variant='h6'>{item?.tagFP ? item?.tagFP.split('|').map((i:string) => `${i} `):''}</Typography>
								<br/>
								<Divider/> 
							</Grid>
							<Grid item md={4}>
								<Box style={{
									backgroundColor:'#f6f6f6',
									maxWidth:'200px',
									maxHeight:'200px',
									minHeight:'150px',
									minWidth:'150px',
									width:'auto',
									height:'100%',
								}}>
										
								</Box>
							</Grid>
							<Grid item md={8} >
								<Grid container spacing={3}>
									<Grid item md={6}>
										Nombre de equipo:
										<Typography variant='body2'><strong>{item?.equipment}</strong></Typography>
										<Divider/>
										<br/>
										Tag de equipo:
										<Typography variant='body2'><strong>{item?.tagTGD}</strong></Typography>
										<Divider/>
									</Grid>
									<Grid item md={6}>
										Tipo:
										<Typography variant='body2'><strong>{item?.type}</strong></Typography>
										<Divider/>
										<br/>
										Sector: 
										<Typography variant='body2'><strong>{item?.sector}</strong></Typography>   
										<Divider/>
									</Grid>
									<Grid item md={6}>
										Tag de planta:
										<Typography variant='body2'><strong>{item?.plantTag}</strong> </Typography>
										<Divider/> 
									</Grid>
									<Grid item md={6}>
										Criticidad del equipo:
										<Typography variant='body2'><strong>{item?.criticality}</strong></Typography>
										<Divider/> 
									</Grid>
								</Grid>
							</Grid>                       
						</Grid>
						<br/>
						<br/>
						<Divider/>
						<br/>
						
						{(type === 'equipment') && <>
							<TgdSimpleTable 
							height='auto'
							title={`Elementos: ${item?.elements?.length}`}
							items={item?.elements}
							type={'element'} 
							itemActions={(i:any) => <>
								<EditEquipmentComponent item= {i} type={'element'} />
							</>}/>
							<br/>
							<Divider/>
						</>}
						
						
						{((type === 'equipment') || (type==='element')) && <>
							<TgdEquipmentsTable  
								height='auto'
								title={`Componentes: ${item?.lubricationPoints?.length}`}
								items={item?.lubricationPoints}
								type={'component'} 
								headerAction={<CreateLubricationPoint item={item}/>}
								itemActions={(i:LubricationPointInterface) => <ViewLubricationPoint item= {i} /> }
							/>
							<br/>
							<Divider/>
						</>}
						
						

						<PageContainer>	
							{(type === 'component') && <>
								<Grid container spacing={3}>
								<Grid item md={3}>
									{item?.brand && <>
										Marca: 
										<div>{item?.brand}</div>
										<br/>
									</>}
								</Grid>
								<Grid item md={3}>
									{item?.model && <>
										Modelo: 
										<div>{item?.model}</div>
										<br/>
									</>}
								</Grid>
								<Grid item md={3}>
									{item?.location && <>
										Ubicación: 
										<div>{item?.location}</div>
										<br/>
									</>}
								</Grid>
								<Grid item md={3}>
								
									Análisis de aceite: 
									<div>{(item?.oilAnalysis ? 'SI':'NO')}</div>
									<br/>
									
								</Grid>
							</Grid>
							<br/>
							<Divider/>
							<br/>

								<FlexContainer justify='space-between' width='100%'>
										<PointProperty title='Estado' value={
											<FlexContainer align='center'> 
												<FiberManualRecordIcon style={{color:!!item?.state ? 'green' : 'gray', marginRight:'16px'}} />{item?.state ? 'Activo' : 'dado de baja'}
											</FlexContainer>
										}/>
										{item?.admissionDate && <PointProperty title='Ingreso' value={item?.admissionDate.slice(0,10).split('-').reverse().join('-')}/>} 
										{(parseInt(item?.egressDate?.slice(0,4)) <= nowYear) && <PointProperty title='Egreso' value={item?.egressDate.slice(0,10).split('-').reverse().join('-')}/>}
										{item?.review && <PointProperty title='Revisión' value={item.review}/>}
										{item?.labelId && <PointProperty title='Id etiqueta' value={item.labelId}/>}
								</FlexContainer>
									
								

								{item?.lowReason && <PointProperty title='Motivo de baja' value={item.lowReason || 'Nunca dadod e baja'}/>}
						
								<Grid container spacing={0}>
									<Grid item md={9}>
										{!!!item?.function && <PointProperty title='Función' value={item.funtion || 'sin funcion'}/>}
									</Grid>
								</Grid>
							
								<FlexContainer flexWrap='wrap' width='100%' justify='space-between'>
									{item?.element && <PointProperty title='Elemento' value={item.element}/>}
									{item?.component && <PointProperty title='Componente' value={item?.component}/>}
									{item?.lubricant && <PointProperty title='Lubricante' value={item.lubricant}/>}
									{item?.lubricantType && <PointProperty title='Tipo' value={item.lubricantType}/>}
									{item?.lubricant && <PointProperty title='Capacidad' value={`${item.capacity} ${item.measureUnit}`}/>}
								</FlexContainer>

								{!!!item?.observations && <PointProperty title='Observations' value={item.observtions || 'sin observaciones'}/>}
									
								<br/>
								<Divider/>
								<br/>
								{item?.routes && <>
									<FlexContainer align='center' flexWrap='wrap'> 
										{<AssignCancelRoutePage item={item}/>}
										{JSON.parse(item?.routes).map((r:string,index:number) => 
											<SimpleChip key={index} label={r} type={'route'} />
										)}
									</FlexContainer>
								</>}
								<br/>
								<Divider/>
								<br/>
								{item?.supplies && <>
									{/* herramientas */}
									<FlexContainer align='center' flexWrap='wrap'> 											
										<AssignCancelSupplyForm type={EnumSupplyTypes.tool} item={item}/> 
										{supplies.tools.map((t,index)=>
											<SimpleChip key={index} type='supply' label={t} />
										)}											
									</FlexContainer>
									<br/>
									<Divider/>
									<br/>
									{/* consumibles */}
									<FlexContainer align='center' flexWrap='wrap'> 
										<AssignCancelSupplyForm type={EnumSupplyTypes.consumable} item={item}/>
										
										<br />
										{supplies.consumables.map((t,index)=>
											<SimpleChip key={index} type='supply' label={t} />
										)}
									</FlexContainer>
									<br/>
									<Divider/>
									<br/>
									{/* protecciones */}
									<FlexContainer align='center' flexWrap='wrap'> 
										<AssignCancelSupplyForm type={EnumSupplyTypes.protection} item={item}/>
										<br />
										{supplies.securityItems.map((t,index)=>
											<SimpleChip key={index} type='supply' label={t} />
										)}
									</FlexContainer>
									<br/>
									<Divider/>
									<br/>
								</>}
								{item?.permissions && <>
									<FlexContainer align='center' flexWrap='wrap'> 
										<AssignCancelPermissionForm item={item}/>
										{permissions.map((p:string,index:number)=>
											<SimpleChip key={index} type='supply' label={p} />
										)}
									</FlexContainer>
									<br/>
									<Divider/>
									<br/>
								</>}

							
						</>}
						</PageContainer>
					</PageContainer>
				</Paper>
		
    );
}

export default LubricationPointFileTemplate;
