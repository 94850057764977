import React from 'react';
import { PlantInterface } from '../../plants/service.plant';
import { gridModelAddedReason, usePlantAddedReasons } from '../services/service.addedReasons';
import DeleteAddedReasonForm from '../DeleteAddedReasonForm';
import AnyGrid, { CarrousellActionInterface } from '../../../components/grids/AnyGrid';
import { AddedReasonInterface } from '../types/AdedReasonTypes';
import CUAddedReasonForm from './../CUAddedReasonForm';
import { CrudFormType } from '../../../infrastructure/form/typescript/FormTypes';



interface Props{
    plant:PlantInterface
}

const carrousellActions:CarrousellActionInterface[] = [
    {icon:'edit',component:(item:AddedReasonInterface)=><CUAddedReasonForm item={item} tagFP={item.tagFP} actionType={CrudFormType.update} />}
]

const ManageAddedReasonsPage:React.FC<Props> = ({plant}) => {

    const {data:addedReasons,isLoading} = usePlantAddedReasons(plant.tagFP)
    
    return(   
        <AnyGrid
        gridModel={gridModelAddedReason}
            height='55vh' 
            title='Motivo agregado'
            items={addedReasons || []}
            loading={ isLoading}
            carrousellActions={carrousellActions}
            itemActions={(item:any)=><>
                
                <DeleteAddedReasonForm item={item} />
            </>}
            headerActions={(item:any)=><CUAddedReasonForm tagFP={plant.tagFP}  actionType={CrudFormType.create} />}
        />
    )
          
}


export default React.memo(ManageAddedReasonsPage);
