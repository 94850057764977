import React from 'react';
import { useIotForm } from '../services/useIotForm';
import CreateIotDeviceForm from '../CreateIotDeviceForm';
import AnyGrid from '../../../../components/grids/AnyGrid';
import { useIotDevicesByTagFP } from '../services/IotDeviceService';
import DesactivateActivateIoTDeviceForm from '../DesactivateActivateIoTDeviceForm';
import UpdateIotDeviceForm from'../UpdateIotDeviceForm';

interface Props {
    tagFP:string
}

const IotDevicesPage:React.FC<Props> = ({tagFP}) => {

    const {form} = useIotForm({actionType:'create'},tagFP);
    const {data:devices,isLoading} = useIotDevicesByTagFP(tagFP);

    return (
        <AnyGrid
            title={form.pluralName}
            loading={isLoading}
            items={devices || []}
            headerActions={()=><CreateIotDeviceForm tagFP={tagFP} />}
            height='55vh'
            itemActions={(item,devices:any)=><>
                <UpdateIotDeviceForm item={item} tagFP={tagFP} items={devices} />
                <DesactivateActivateIoTDeviceForm item={item} tagFP={tagFP} />
            </>}
        />
    );
}

export default IotDevicesPage;
