import React from 'react';
import { CircularProgress, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { RouteInterface, useDeleteRouteVerification } from './services/service.routes'
import { useEffect } from 'react';
import TgdPopover from '../../components/popovers/TgdPopover';
import { useStatusProcessor } from '../errors/services/StatusProcessor';
import { useClickPosition } from '../../utils/hooks/useClickPosition';
import { useSnackbar } from '../../utils/hooks/useSnackbar';




interface Props{
    item:RouteInterface 
}
const DeleteRoute:React.FC<Props> = ({item}) => {

    const {errors,status:deleteStatus,data,validate } = useDeleteRouteVerification()
    const {status,message} = useStatusProcessor({...data,status:deleteStatus})

    const {position,getClickPosition} = useClickPosition()
    const { setData } = useSnackbar()

    useEffect(()=>{
        if(deleteStatus === 'error'){
            const componentsError = (
                (errors?.equipments?.length 
                    ? (errors.equipments.length > 1 
                        ? `${errors.equipments.length} Equipos` 
                        : `${errors.equipments.length} Equipo`) 
                    : '')
            )
            setData({
                modal:true,
                errors:`Esta Ruta no se puede eliminar porque está siendo usada en: ${componentsError}` ,
                severity:'warning',
                position:position
            })
        }
        if(deleteStatus === 'success'){
            setData({
                modal:true,
                errors:message ,
                severity:'success',
                position:position
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[status,deleteStatus,errors])
    
    if(status === 'loading')  return <CircularProgress size='20px' />

    return (

        <TgdPopover mode='hover' title='Eliminar ruta'>
            <IconButton size='small' onClick={(e)=>getClickPosition(e,()=>validate(item))}>
                <DeleteIcon />
            </IconButton>
        </TgdPopover>
    
    );
}

export default React.memo(DeleteRoute);