import React, { useEffect } from 'react';
import ComponentMode from '../../components/ComponentMode';
import {  useSendEmailWithAttachment } from './services/service.email';
import CustomButton from '../../components/buttons/CustomButton';
import {blobToBase64, errorsLog} from '../../utils/utils'
import StatusAndMessage from '../errors/StatusAndMessage';
import { useForm } from 'react-hook-form';
import { TextField, Typography } from '@material-ui/core';
import { htmlAnyGridArea } from './emailTemplates/htmlAnyGridAttachment';
import FlexContainer from '../../components/presentational/FlexContainer';
import { usePerson } from '../persons/services/service.person';
import { capitalize } from '../../utils/utils';
import xlsxImage from '../../assets/img/xlsx-file-miniature.png'
import { useStatusProcessor } from '../errors/services/StatusProcessor';
import { useLangLabels } from '../language/services/useSetLabel';
import { useExportXlsx } from '../../infrastructure/xlsx/useExportXlsx';
import { QueryStatus } from 'react-query';
import SelectArea from '../areas/molecules/SelectArea';

export const XlsxMiniatureFile:React.FC<{fileTitle:string}> = React.memo(({fileTitle}) => {
    return(
        <FlexContainer align='center'>
        {/* <Paper style={{width:'40px', height:'40px'}} elevation={3}> */}
            <img style={{width:'35px'}} src={xlsxImage} alt='xlsx archive miniature' />
        {/* </Paper> */}
        <Typography variant='body1'>{fileTitle}.xlsx</Typography>
    </FlexContainer>
    )
})


interface Props{
    items:any
    attachmentTitle:string
    tagFP:string
}

const SendEmailWithAttachment:React.FC<Props> = ({items,attachmentTitle,tagFP}) => {

    const {lang} = useLangLabels()
    const {data:person} = usePerson()
    const  [sendEmail,dnEmailResult] = useSendEmailWithAttachment()
    const { register, setValue ,watch, errors,reset } = useForm()
    const {status,message,error,reset:ClearStatus} = useStatusProcessor(dnEmailResult)
    const {file} = useExportXlsx(items,attachmentTitle) 
    const emailFooter = `${lang.sendEmail.att} ${capitalize(person?.name)}. ${lang.sendEmail.charge }`

    const submit = (file:any) => {
        (file !== undefined ) &&
        sendEmail({
            attachment:file,
            htmlEmailBody:htmlAnyGridArea({
                content:watch('body'),
                footer:emailFooter
            }) || '',
            fileName:attachmentTitle,
            emailAddress:watch('emailAddress')
        })
    } 

    const handleSend = ()=> {
        blobToBase64(file,submit)
    }

    useEffect(()=> {
        register({name:'emailAddress',value:''},{required:true})
    },[register])

    useEffect(()=>{
        //Reset Form
        status=== QueryStatus.Success && reset()
    },[status])

    errorsLog(errors)

    return (
        <ComponentMode icon={'email'} variant='button' popoverTitle={lang.sendEmail.title} mode='popover'>
                
                <StatusAndMessage status={status} error={error} message={message} reset={ClearStatus} />
                <br/>
                
                <SelectArea complete onChange={(value:any)=>setValue('emailAddress',value.email)} tagFP={tagFP} />
                
                <Typography variant='body2'>{lang.sendEmail.recipient}: <br/> <strong>{watch('emailAddress')}</strong></Typography>

                <TextField
                    multiline
                    rows={'8'}
                    rowsMax={'12'}
                    variant='outlined'
                    size='small'
                    label={lang.sendEmail.emailBody}
                    name={'body'}
                    inputRef={register()}
                />

                <Typography>{emailFooter}</Typography>

                <XlsxMiniatureFile fileTitle={attachmentTitle}/>

                <CustomButton status={status} action={handleSend} icon='send' />
           
        </ComponentMode>
    );
}

export default React.memo(SendEmailWithAttachment);


