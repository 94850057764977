import { Button, Icon } from '@material-ui/core';
import React from 'react';

interface Props{
    action?:()=>void
    title?:string
    type?:'submit'
}
const AddButton:React.FC<Props> = ({action,title,type,children}) => {
    
    return (<>
        <Button color='secondary' type={type} variant='contained' size='small' onClick={action}> <Icon>add</Icon> {title && title} {children && children} </Button>
    </>);
}

export default AddButton;
