import React from 'react';
import ProgressButton from '../../components/buttons/ProgressButton';
import { TextField, makeStyles, createStyles, Typography } from '@material-ui/core';
import {  useForm } from 'react-hook-form';
import { useCreatePersonRefactor, useEmailVerificationExist, usePerson } from './services/service.person';
import { emailFormRegex } from '../../infrastructure/regex/regexForms';
import { Alert } from '@material-ui/lab';
import { PersonInterface } from './interfaces/userInterfaces';
import { ClientRoleType, OperatorRoleType, SupervisorRoleType } from './interfaces/PersonRoleTypes';
import OnlineOfflineMode from '../../infrastructure/online/offline Mode/OnlineOfflineMode';
import DefaultFormLayout from '../../components/forms/DefaultFormLayout';
import { useLangLabels } from './../language/services/useSetLabel';


const useStyles =  makeStyles((theme) => createStyles({
    formGrid:{
        display:'flex',
        flexDirection:'column'
    },
    form: {
        marginTop: theme.spacing(2),
        width:'300px'
    },
}));

type Props = {
    title?:string
    tagFP?:string
    tagF?:undefined
    role: SupervisorRoleType | OperatorRoleType 
    handlePerson?:(value?:PersonInterface)=>void
} | {
    title?:string
    tagF:string,
    role:ClientRoleType,
    tagFP?:string
    handlePerson?:(value?:PersonInterface)=>void
}

const AddPersonForm:React.FC<Props> = ({title,tagFP,role,tagF,handlePerson}) => {

    const {lang} = useLangLabels()
    const classes= useStyles()
    const {register,watch,errors,handleSubmit,setValue} = useForm()
    const {createPerson,status,error,message,reset} = useCreatePersonRefactor()
    const emailVerification = sessionStorage.getItem('emailVerification')
    const {data:person} = usePerson()

    const submit = (data:PersonInterface ) => {
        createPerson({
            name: `${data.firstName} ${data.lastName}`,
            telephone: data.telephone,
            address: data.address,
            email: data.email,
            file: 0,
            referrer: person?.id || 0,
            password: data.password,
            role:role,
            tagF:tagF
           },tagFP)
           .then(()=>{
               handlePerson && handlePerson(data)
           })
    }

    useEmailVerificationExist(watch('email'))

    const handleReset = () => {
        setValue('firstName','')
        setValue('lastName','')
        setValue('address','')
        setValue('telephone','')
        setValue('email','')
        setValue('password','')
        reset()
    }

    return (
    <OnlineOfflineMode>
        <DefaultFormLayout
            mode='content'
            icon='add'
            buttonTitle={lang.actionTypes.create}
            popoverTitle={lang.actionTypes.create}
            {...{status,error,message}}
            onChange={handleSubmit(submit)}
            onClose={handleReset}
        >

            <TextField
                variant={'outlined'}
                name="firstName"
                inputRef={register({
                    required:{value:true,message:'Requerido'},
                    min:{value:3,message:'Minimo 3 digitos'}
                })}
                size='small'
                label={errors?.firstName?.message || 'Nombre'}
                color={errors?.firstName?.message ? 'primary' : 'secondary'}
            />
            
            <TextField
                variant={'outlined'}
                name="lastName"
                inputRef={register({
                    required:{value:true,message:'Requerido'},
                    min:{value:3,message:'Minimo 3 digitos'}
                })}
                size='small'
                label={errors?.lastName?.message || 'Apellido'}
                color={errors?.lastName?.message ? 'primary' : 'secondary'}
            />
            
    
            <TextField
                size={'small'}
                variant={'outlined'}
                inputRef={register()}
                name="address"
                label="Dirección"
                color={'secondary'}
            />
                <TextField
                variant={'outlined'}
                name="telephone"
                inputRef={register({
                    required:{value:true,message:'Requerido'},
                    min:{value:3,message:'Minimo 3 digitos'},
                })}
                size='small'
                label={errors?.telephone?.message || 'Teléfono'}
                color={errors?.telephone?.message ? 'primary' : 'secondary'}
            />
            
            <TextField
                variant={'outlined'}
                name="email"
                inputRef={register({
                    required:{value:true,message:'Requerido'},
                    pattern:{value:emailFormRegex,message:'Inválido'},
                    validate:{
                        existance: () => !!emailVerification && JSON.parse(emailVerification) === 0
                    }
                })}
                size='small'
                label={errors?.email?.message || 'Email'}
                color={errors?.email ? 'primary' : 'secondary'}
            />
            
            <TextField
                variant={'outlined'}
                name="password"
                inputRef={register({
                    required:{value:true,message:'Requerido'},
                    min:{value:6,message:'Minimo 6 digitos'}
                })}
                size='small'
                label={errors?.password?.message || 'Contraseña'}
                color={errors?.password?.message ? 'primary' : 'secondary'}
            />

                            
            {/* { userType === 'C' &&<><br/>
                <Controller
                    as={<SelectSimpleEntity
                            label={errors?.tagF?.message || 'Fabrica'} 
                            color={errors?.tagF?.message ? 'primary' : 'secondary'}
                            entityList={factoriesByUser}
                            showTitle={'tagF'}
                            onChange={setValue}
                            defaultValue={watch('tagF',tagF)}
                        />}
                    name='tagF'
                    defaultValue={tagF}
                    control={control}
                />
            </>} */}
 
            <TextField
                variant={'outlined'}
                disabled
                inputRef={register()}
                defaultValue={watch('referrer',person?.id)}
                name="referrer"
                size='small'
                label={'Referente a cargo'}
                color={errors?.password?.message ? 'primary' : 'secondary'}
            />
                
        </DefaultFormLayout>
    </OnlineOfflineMode>);
}

export default AddPersonForm
