import React from 'react';
//powerBi
import { Report } from 'powerbi-report-component'
//router
import {Route} from 'react-router-dom'
//context
import { useWorkspace } from '../../domains/persons/services/service.person';
import { useAadToken, usePbiReportsInGroup,PbiReportsInterface } from '../../domains/powerBi/service.pbi';
import Loading from '../presentational/Loading';
import { USER_ROUTES } from '../../infrastructure/routes/routes';


const Reports:React.FC = () => {

    const workspace = useWorkspace()
    const aadToken = useAadToken()
    const pbiReports = usePbiReportsInGroup()

    if(pbiReports){
        return (
            <>
            {pbiReports.map((pbiReports:PbiReportsInterface,index:number)=>
                <Route key={index} exact path={`/${USER_ROUTES.home}/${workspace}/${pbiReports.name}`}>
                    <Report
                        tokenType='Aad'   //"Embed" // "Aad"
                        accessToken={aadToken} // accessToken goes here
                        embedUrl={pbiReports.embedUrl} // embedUrl goes here
                        embedId={pbiReports.id} // report or dashboard Id goes here
                        pageName="" // set as current page of the report
                        reportMode="View" // open report in a particular mode View/Edit/Create
                        datasetId={pbiReports.datasetId} // required for reportMode = "Create" and optional for dynamic databinding in `report` on `View` mode
                        /*  extraSettings={extraSettings} */
                        permissions="All" // View, For "Edit" mode permissions should be "All"
                        style={{
                        height:'100vh',
                        width:'100%',
                        margin:'0px',
                        border:'none',
                        padding:'0px'
                        }}
                        /* onLoad={this.handleReportLoad} */
                        /* onRender={this.handleReportRender} // not allowed in "Create" mode
                        onSelectData={this.handleDataSelected}
                        onPageChange={this.handlePageChange}
                        onTileClicked={this.handleTileClicked}
                        onSave={this.handleReportSave} */ // works for "Edit" and "Create"
                        />
                </Route>
            )} 
            </>
        );
    }
    return(<Loading />)
}

export default Reports;
