import React, { useEffect, useMemo, useState } from 'react';
import DefaultFormLayout from '../../components/forms/DefaultFormLayout';
import { CrudFormType } from '../../infrastructure/form/typescript/FormTypes';
import FieldSelector from '../../infrastructure/form/components/FieldSelector';
import { useLangLabels } from '../language/services/useSetLabel';
import { CUEntityFormPropsType, uiFormInterface } from '../crud/types/crudTypes';
import { useCreateUpdate } from './../crud/hooks/useCreateUpdate';
import { CreateType, deleteType, TypeInterface, updateType } from './services/service.types';
import { useTypeForm } from './services/useTypeForm';
import { enumTgdTypes } from './Types/types';
import { capitalize } from '../../utils/utils';


/* type CreateTypeInterface = {
    tagFP:string
    actionType:CrudFormType.create
    item?:TypeInterface
    defaultType?:enumTgdTypes
} | {
    tagFP:string
    actionType:CrudFormType.update,
    item:TypeInterface,
    defaultType?:enumTgdTypes
} */

type props = CUEntityFormPropsType<TypeInterface> & {
    defaultType?:enumTgdTypes
}


const CUCriticalityForm:React.FC<props> = ({tagFP, actionType, item, defaultType}) => {

    //DB 
    const  { action, resetStatus,status, error, message } = useCreateUpdate({
        create: CreateType,
        update: updateType,
        delete: deleteType
    },actionType, ['PlantAux'])

    //UI
    const { lang } = useLangLabels()
    const { uiModel, handleSubmit, reset } = useTypeForm(tagFP, item || (defaultType && {
        id:0,
        name:'',
        type:defaultType,
        tagFP:tagFP
    }))

    const ui = (() => {
        const formName = lang.types.plural 
        switch (actionType) {
            case CrudFormType.create:
                const create:uiFormInterface = {
                    icon:'add',
                    title:`${lang.actionTypes.create} ${formName.toLowerCase()} ${defaultType ? defaultType.toLowerCase() : ''}`,
                    variant:'button',
                    mode:'modal'
                }
                return create

            case CrudFormType.update:
                const update:uiFormInterface = {
                    icon:'edit',
                    title:`${lang.actionTypes.update} ${formName.toLowerCase()}`,
                    variant:'iconButton',
                    mode:'content'
                }
                return update
        }
    })()
   
  
    //Methods
    const submit = (data:TypeInterface) => {
        action(data)
    }

    const handleReset = () => {
        resetStatus()
        reset()
    }


    return(
        <DefaultFormLayout 
        mode={ui.mode}
        icon={ui.icon}    
        variant={ui.variant}
        buttonTitle={ui.title}
        popoverTitle={ui.title}
        {...{status,error,message}} 
        onChange={handleSubmit(submit)}  
        onClose={handleReset} 
        >
            {uiModel.map((props,index)=>
                <FieldSelector key={index } fieldProps={props} />
                )}
        </DefaultFormLayout>
    )

}

export default  CUCriticalityForm
