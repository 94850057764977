import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { StockInterface, } from '../stock/services/service.stock';
import { Typography, TextField } from '@material-ui/core';
import CustomButton, { ButtonVariant } from '../../components/buttons/CustomButton';
import FlexContainer from '../../components/presentational/FlexContainer';
import theme from '../../infrastructure/materialUi/themeConfig';
import StatusAndMessage from '../errors/StatusAndMessage';
import { useUser, useOperatorSupervisorTagFP } from '../persons/services/service.person';
import SelectCategory from '../categories/SelectCategory';
import SelectSubcategoryByCategory from '../sub-categories/SelectSubcategoryByCategory';
import SelectStockProduct from '../stock/SelectStockProduct';
import { useCreatePurchaseRequest } from './service.purchaseRequests';
import ComponentMode from '../../components/ComponentMode';
import { errorsLog } from '../../utils/utils';
import { useStatusProcessor } from '../errors/services/StatusProcessor';
import { ComponentUIModes } from '../../components/ComponentUIModes';

interface Props{
    mode:ComponentUIModes
    tagFP?:string
    stock?:StockInterface
    variant?:ButtonVariant
}

const CreatePurchaseRequestForm:React.FC<Props> = ({mode,tagFP,stock,variant}) => {

    const {data:user} = useUser()
    const supOpTagFP = useOperatorSupervisorTagFP()
    const componentTagFP = stock?.tagFP || tagFP || supOpTagFP 
    const [createPurchaseRequest,createResult] = useCreatePurchaseRequest()
    const { status, message,error } = useStatusProcessor(createResult)
    const {register,setValue,errors,watch,handleSubmit,control} = useForm()
    const title='Nueva Solicitud de compra'
    
    useEffect(()=>{
        //PresetedValues
        register({name:'id',type:'number',value:0})
        register({name:'tagFP',value:componentTagFP})
        register({name:'categoryArea',type:'string',value:'LUBRICACION'})
        register({name:'state',value:'PENDIENTE'},{required:{value:true,message:'Campo requerido'}})
        register({name:'user',value:user?.email},{required:{value:true,message:'Campo requerido'}})
        register({name:'receivedAmount',type:'number',value:0})

        //inputs
        register({name:'category',value:stock?.category || ''},{required:{value:true,message:'Campo requerido'}})
        register({name:'subcategory',value:stock?.subcategory || ''},{required:{value:true,message:'Campo requerido'}})
        register({name:'brand',type:'string',value:stock?.brand || ''}, {required:{value:true,message:'Campo requerido'}})
        register({name:'measureUnit',type:'string',value:stock?.measureUnit || ''}, {required:{value:true,message:'Campo requerido'}})
        register({name:'product',type:'string',value:stock?.product ||''}, {required:{value:true,message:'Campo requerido'}})
        register({name:'requestedAmount',type:'number',value:0},{required:{value:true,message:'Campo requerido'}})
        register({name:'observations',value:''})
        
        register({name:'providerArea',value:stock?.providerArea || ''},{required:{value:true,message:'Campo requerido'}})
        register({name:'complex',value:stock?.complex || ''})
    
    },[stock])


    const handleProductSelect = (item:StockInterface) =>{
        /* console.log(item) */
        setValue('complex',item.complex)
        setValue('product',item.product)
        setValue('brand',item.brand)
        setValue('measureUnit',item.measureUnit)
        setValue('providerArea',item.providerArea)
    }

    const submit = (item:any) => {
        createPurchaseRequest({
            ...item,
            subcategory:stock?.subcategory || item.subcategory.subcategory
        })
    }

    errorsLog(errors)

    const content=<> 
        <Typography variant='h6'>{mode === 'content' && title}</Typography>
        <StatusAndMessage status={status} message={message} error={error} />
        <FlexContainer padding={`${theme.spacing(3)}px 0px`} flexDirection={'column'}>

               {!stock && <>
                    <SelectCategory
                        handleSelect={(value) => setValue('category',value)}
                        errors={errors.category?.message}
                    />

                    <SelectSubcategoryByCategory
                        parentCategory={watch('category')}
                        errors={errors.subcategory?.message}
                        handleSelect={(value) => setValue('subcategory',value)}
                    />
                
                    <SelectStockProduct 
                        subcategory={watch('subcategory')}
                        error={errors?.product?.message}
                        handleSelect={(value)=>handleProductSelect(value)}
                    />
                </>}

                <TextField
                inputRef={register()}
                name='requestedAmount'
                size='small'
                variant='outlined'
                label={errors?.requestedQuantity?.message || 'Cantidad pedida' }
                color={errors?.requestedQuantity?.message ? 'primary' : 'secondary'}
                />

                <TextField
                inputRef={register()}
                name='observations'
                size='small'
                variant='outlined'
                label={errors?.observations?.message || 'Observación'}
                color={errors?.observations?.message ? 'primary' : 'secondary'}
                />

                <CustomButton status={status} action={handleSubmit(submit)}>Crear</CustomButton>
            
        </FlexContainer>
    </>
    
    return (<>

        <ComponentMode mode={mode} variant={variant || 'button'} icon='add' title={title}>
            {content}
        </ComponentMode>
    </>);
}

export default React.memo(CreatePurchaseRequestForm);
