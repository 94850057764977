import axios from "axios";
import { create } from "domain";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { queryCache, QueryCache, useMutation, useQuery } from "react-query";
import { setOriginalNode } from "typescript";
import { URL } from "../../../infrastructure/appConfig/appConfig";
import { CrudFormType } from "../../../infrastructure/form/typescript/FormTypes";
import { GridModelInterface, MutationQueryObject } from "../../../infrastructure/Interfaces";
import { useLangLabels } from "../../language/services/useSetLabel";
import { useToken } from "../../session/service.session";
import { useStatusProcessor } from './../../errors/services/StatusProcessor';



export interface CollectorInterface {
    id?:number
    name:string
    collectorCode:string
    serialNumber:string
    tagFP:string
    state:boolean
    admissionDate:string
    egressDate:string
}

export const GridModelCollector:GridModelInterface = {
    id:{label:'Id'},
    name:{label:'Nombre'},
    collectorCode:{label:'Codigo'},
    serialNumber:{label:'Numeroserie'},
    tagFP:{label:'Planta'},
    state:{label:'Estado'},
    admissionDate:{label:'Fecha ingreso'},
    egressDate:{label:'Fecha egreso'},
}

const GetCollectorsByTagFP = (_:string,tagFP:string,token:string) => {
    return axios({
        headers:{
            'Authorization':`Bearer ${token}`,
            tagFP
        },
        url:`${URL}/CollectorsByTagFP`,
    }).then((res)=>(res.data))
    .catch((err) => console.log(err))
}


const CreateCollector = ({data,token}:MutationQueryObject) => {
    return axios({
        method:'POST',
        url:`${URL}/CreateCollector`,
        headers:{
            'Authorization': `Bearer ${token}`
        },           
        data
    })
   .then((res)=>res.data)
   .catch((err) => console.log(err))
}

const UpdateCollector = ({data,token}:MutationQueryObject) => {
    return axios({
        method:'POST',
        url:`${URL}/CollectorsByTagFP`,
        headers:{
            'Authorization': `Bearer ${token}`
        },           
        data
    })
   .then((res)=>res.data)
   .catch((err) => console.log(err))
}



/*  -------------------------*/
/* ------------------QUERIES */
/* --------------------------*/

export const useCollectorsByTagFP = (tagFP:string) =>{
    const token = useToken()
    const query = useQuery<CollectorInterface[]>(['CollectorsByTagFP',tagFP,token],GetCollectorsByTagFP,{
        enabled:!!tagFP && !!token,
        staleTime:20000
    })

    const status = useStatusProcessor(query)

    return{
        ...query,
        ...status
    }
}



/*  ---------------------------*/
/* ------------------MUTATIONS */
/* ----------------------------*/

export const useCreateUpdateCollector = ({tagFP,type,item}:{tagFP:string,type:CrudFormType,item?:CollectorInterface}) =>{

    const token = useToken()

    const [create,CreateData] = useMutation(CreateCollector,{
        onSuccess:()=>{
            queryCache.invalidateQueries('CollectorsByTagFP')
            queryCache.invalidateQueries('AllCollectors')
        }
    })

    const [update,UpdateData] = useMutation(UpdateCollector,{
        onSuccess:()=>{
            queryCache.invalidateQueries('CollectorsByTagFP')
            queryCache.invalidateQueries('AllCollectors')
        }
    })

    const data =( type === CrudFormType.create)
    ? CreateData 
    : UpdateData


    const { lang } =  useLangLabels()

    const status = useStatusProcessor(data)

    const {register, setValue, handleSubmit, watch, errors, reset} = useForm()

    useEffect(()=>{
        //registerDefault
        register('id',{required:{value:true,message:lang.validations.messages.required}})
        register('tagFP',{required:{value:true,message:lang.validations.messages.required}})
        register('name',{required:{value:true,message:lang.validations.messages.required}})
        register('serialNumber',{required:{value:true,message:lang.validations.messages.required}})
        register('collectorCode',{required:{value:true,message:lang.validations.messages.required}})
        register('state',{required:{value:true,message:lang.validations.messages.required}})
       

        if(item){
            switch (type) {
                case CrudFormType.update:
                    //register
                    register('admissionDate',{required:{value:true,message:lang.validations.messages.required}})
                    register('egressDate',{required:{value:true,message:lang.validations.messages.required}})
                    //setDefault
                    setValue('id',item.id)
                    setValue('name',item.name)
                    setValue('serialNumber',item.serialNumber)
                    setValue('collectorCode',item.collectorCode)
                    setValue('state',item.state)
                    setValue('admissionDate',item.admissionDate)
                    setValue('egressDate',item.egressDate)
                    break
                }
        }

        //SetDefault
        setValue('id',0)
        setValue('tagFP',tagFP)
        setValue('state',true)

    },[item, type])

    const handleCreateUpdateCollector = (data:CollectorInterface) => {
        switch (type) {
            case CrudFormType.create:
                return create({
                    token,
                    data
            })
            case CrudFormType.update:
                return update({
                    token,
                    data
            })
        } 
    }

const handleReset = () =>{
    status.reset()
    data.reset()
    reset()
}



    return {
        handleCreateUpdateCollector,
        data:{
            ...data,
            ...status
        },
        handleSubmit,
        form:watch(),
        errors,
        setValue,
        reset:handleReset
    }

}