import { useMutation } from "react-query"
import axios from'axios'
import { URL } from "../../../infrastructure/appConfig/appConfig"
//---------------------
//AXIOS REPOSITORY
//---------------------

export const  newDemoRequest = ({data}:{data:any}) => {
    let res = axios.post(`${URL}/DemoRequest`, data).then(res => {return res.data}).catch(res =>{return res.data})
    return res
}

//---------------------
//USE QUERYS
//---------------------




//---------------------
//MUTATIONS
//---------------------

export const useNewDemoRequest = () => {
    return useMutation(newDemoRequest)
}



//---------------------
//CUSTOM HOOOKS
//---------------------